import { ReactNode } from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import styles from './custom-icon.module.scss';

interface CustomIconProps {
	children: ReactNode;
}

export const CustomIcon = ({ children }: CustomIconProps) => {
	return <SvgIcon className={styles.icon}>{children}</SvgIcon>;
};
