import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';
import { getFormattedDate } from '@/app/_common/utils';
import { Dialog, RichTextEditor, ReadOnlyWrapper, getRichTextEditorTranslations } from '@/app/_common/_components';
import { REASON_DIALOG_WIDTH } from '@/app/_common/constants';
import { ReasonDialogActions } from '@/app/_common/_components/query-results/reason-dialog-actions';

import styles from './reason-dialog.module.scss';

interface ReasonDialogProps {
	loading?: boolean;
	addedDate?: string | Date;
	content?: string;
	closeWindow: () => void;
	onCancelEdit: () => void;
	handleUpdate: (updatedContent: string) => void;
	activateEditMode: () => void;
	isEditModeActive: boolean;
	disabled?: boolean;
}

export const ReasonDialog: FC<ReasonDialogProps> = ({
	loading,
	addedDate,
	content,
	closeWindow,
	handleUpdate,
	activateEditMode,
	onCancelEdit,
	isEditModeActive,
	disabled,
}) => {
	const [draftContent, setDraftContent] = useState<string | undefined>(content);

	const { t } = useTranslation(Namespaces.QueryResults);

	const translations = useMemo(() => getRichTextEditorTranslations(Namespaces.QueryResults), []);

	const formattedAddedDate = getFormattedDate(addedDate as string);

	useEffect(() => {
		return () => {
			onCancelEdit();
		};
	}, [onCancelEdit]);

	useEffect(() => {
		setDraftContent(content);
	}, [content]);

	return (
		<Dialog width={REASON_DIALOG_WIDTH} headerDivider={false} footerDivider={false} closeIcon={false} className={styles.dialog} onClose={closeWindow}>
			<ReadOnlyWrapper
				title={t('reason.title')}
				subTitle={`${t('queryResults')}, ${t('added')}: ${formattedAddedDate}`}
				actions={
					<ReasonDialogActions
						closeWindow={closeWindow}
						activateEditMode={activateEditMode}
						isEditModeActive={isEditModeActive}
						disabled={disabled}
					/>
				}
			>
				<RichTextEditor
					className={styles.textEditor}
					editorContent={draftContent}
					onUpdate={handleUpdate}
					onCancel={onCancelEdit}
					isEditModeActive={isEditModeActive}
					highlightEdit={true}
					loading={loading}
					readOnly={true}
					translations={translations}
					allowEmptyContent={true}
					showSaveCancelButtons={true}
					isSaveCancelButtonsFullTextView={true}
					onChange={setDraftContent}
				/>
			</ReadOnlyWrapper>
		</Dialog>
	);
};
