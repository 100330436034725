import { FC } from 'react';
import classNames from 'classnames';

import { DataGridSelectedValue } from '@/app/_common/types';
import { getFormattedDate } from '@/app/_common/utils';
import { Button } from '@/app/_common/_components';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { CopyId, HeaderTitlePrefix } from '@/app/_common/_components/details-view/details-view-header/_components';
import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';

import styles from './header-date-title.module.scss';

interface HeaderDateTitleProps {
	typename: DetailsViewTypename;
	onPreviousItem: () => void;
	onNextItem: () => void;
	currentItemIndex: number;
	selectedItemTitle: string;
	isItemSelected: DataGridSelectedValue;
	totalItemsAmount: number;
	timestamp?: string;
	itemId: string;
}

export const HeaderDateTitle: FC<HeaderDateTitleProps> = ({
	typename,
	onPreviousItem,
	onNextItem,
	currentItemIndex,
	selectedItemTitle,
	isItemSelected,
	totalItemsAmount,
	itemId,
	timestamp,
}) => {
	const showArrows = () => {
		return isItemSelected && totalItemsAmount > 1;
	};

	return (
		<div className={styles.container}>
			<div className={styles.arrowsContainer}>
				{showArrows() && (
					<Button className={classNames(styles.arrowButton, styles.leftArrow)} onClick={onPreviousItem} disabled={currentItemIndex === 1}>
						<ChevronLeft />
					</Button>
				)}
				<h4 className={styles.date} data-testid="date-time">
					{getFormattedDate(timestamp)}
					{showArrows() && <span className={styles.itemCount}>({`${currentItemIndex} of ${totalItemsAmount}`})</span>}
				</h4>
				{showArrows() && (
					<Button className={classNames(styles.arrowButton, styles.rightArrow)} onClick={onNextItem} disabled={currentItemIndex === totalItemsAmount}>
						<ChevronRight />
					</Button>
				)}
			</div>
			<div className={styles.itemDetails}>
				<HeaderTitlePrefix typename={typename} />
				<h4 className={styles.title} data-testid="title">
					{selectedItemTitle}
				</h4>
				<CopyId id={itemId} />
			</div>
		</div>
	);
};
