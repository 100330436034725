import { Schema, TimeRangeFilterOption, FieldFilterGroup } from '@/app/_common/types';
import { getDateTimeColumns, isTimestampFilterInQuery, prepareQuery, removeQueryComments, retrieveQuery } from '@/app/_common/utils';
import { getMakeSeriesQuery, getTablesFromQuery } from '@/app/_common/utils/adx';
import { ADVANCED_QUERY_CHART_TIMESTAMP_COLUMN } from '@/app/_common/constants';

interface PrepareAdvancedQueryPageChartQuery {
	database: string;
	filters?: FieldFilterGroup[];
	interval?: number;
	isStackedBarChartQuery: boolean;
	query: string;
	schema: Schema;
	timeRange: TimeRangeFilterOption;
}

export const prepareAdvancedQueryPageChartQuery = ({
	database,
	filters = [],
	interval,
	isStackedBarChartQuery,
	query,
	schema,
	timeRange,
}: PrepareAdvancedQueryPageChartQuery): string => {
	const clearedQuery = removeQueryComments(query);
	const preparedQuery = prepareQuery({
		query: clearedQuery,
		schema,
		database,
		timeRange,
		filtersList: filters,
		limit: null,
		includeTableName: true,
	});

	const { query: _query } = retrieveQuery(clearedQuery);
	const tableName = getTablesFromQuery(_query);
	const dateTimeColumns = getDateTimeColumns(tableName, schema, database);
	const isTimeRangeInQuery = isTimestampFilterInQuery(clearedQuery, dateTimeColumns);
	const makeSeriesQuery = getMakeSeriesQuery({
		timeRange,
		timestampColumn: ADVANCED_QUERY_CHART_TIMESTAMP_COLUMN,
		step: interval,
		query: isTimeRangeInQuery ? clearedQuery : undefined,
		isStackedBarChartQuery,
	});
	return `${preparedQuery} | ${makeSeriesQuery}`;
};
