import { Themes } from '@/app/_common/types';

/* Advanced Query Chart */
const advancedQueryChartSingleBarStackBgColorLightMode = '#0072bc';
const advancedQueryChartSingleBarStackBgColorDarkMode = '#00abe7';
const advancedQueryChartAxisColorLightMode = '#455565';
const advancedQueryChartAxisColorDarkMode = '#ffffff';
const advancedQueryChartGridStrokeColorLightMode = '#d9d9d9';
const advancedQueryChartGridStrokeColorDarkMode = '#455565';
const advancedQueryChartBrushStrokeColorLightMode = 'rgba(0, 114, 188, 0.5)';
const advancedQueryChartBrushStrokeColorDarkMode = 'rgba(0, 171, 231, 0.5)';
const advancedQueryChartBrushFillColorLightMode = 'rgba(0, 114, 188, 0.08)';
const advancedQueryChartBrushFillColorDarkMode = 'rgba(0, 171, 231, 0.08)';
/* Stacked Chart */
const advancedQueryStackedChartEventsBarBgColorLightMode = '#15A1F0';
const advancedQueryStackedChartEventsBarBgColorDarkMode = '#8BE3FF';
const advancedQueryStackedChartAlertsBarBgColorLightMode = '#001973';
const advancedQueryStackedChartAlertsBarBgColorDarkMode = '#5080FF';
const advancedQueryStackedChartEvidenceBarBgColorLightMode = '#0368CD';
const advancedQueryStackedChartEvidenceBarBgColorDarkMode = '#4FBAFF';

export const THEMES = {
	[Themes.Light]: {
		advancedQueryChartSingleBarStackBgColor: advancedQueryChartSingleBarStackBgColorLightMode,
		advancedQueryChartAxisColor: advancedQueryChartAxisColorLightMode,
		advancedQueryChartGridStrokeColor: advancedQueryChartGridStrokeColorLightMode,
		advancedQueryChartBrushStrokeColor: advancedQueryChartBrushStrokeColorLightMode,
		advancedQueryChartBrushFillColor: advancedQueryChartBrushFillColorLightMode,
		advancedQueryStackedChartEventsBarBgColor: advancedQueryStackedChartEventsBarBgColorLightMode,
		advancedQueryStackedChartAlertsBarBgColor: advancedQueryStackedChartAlertsBarBgColorLightMode,
		advancedQueryStackedChartEvidenceBarBgColor: advancedQueryStackedChartEvidenceBarBgColorLightMode,
	},
	[Themes.Dark]: {
		advancedQueryChartSingleBarStackBgColor: advancedQueryChartSingleBarStackBgColorDarkMode,
		advancedQueryChartAxisColor: advancedQueryChartAxisColorDarkMode,
		advancedQueryChartGridStrokeColor: advancedQueryChartGridStrokeColorDarkMode,
		advancedQueryChartBrushStrokeColor: advancedQueryChartBrushStrokeColorDarkMode,
		advancedQueryChartBrushFillColor: advancedQueryChartBrushFillColorDarkMode,
		advancedQueryStackedChartEventsBarBgColor: advancedQueryStackedChartEventsBarBgColorDarkMode,
		advancedQueryStackedChartAlertsBarBgColor: advancedQueryStackedChartAlertsBarBgColorDarkMode,
		advancedQueryStackedChartEvidenceBarBgColor: advancedQueryStackedChartEvidenceBarBgColorDarkMode,
	},
};
