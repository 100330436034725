import { FC, useState } from 'react';
import classNames from 'classnames';
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpIcon from '@mui/icons-material/ArrowDropUp';

import { Button, ButtonSize } from '@/app/_common/_components';

import styles from './error-details-content.module.scss';

interface ErrorDetailsContentProps {
	buttonTitle: string;
	error?: string;
	className?: string;
}

export const ErrorDetailsContent: FC<ErrorDetailsContentProps> = ({ error, buttonTitle, className }) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	if (!error) {
		return null;
	}

	const toggleDescription = () => setIsExpanded((state: boolean) => !state);

	return (
		<div className={classNames(styles.container, className)}>
			<Button className={styles.button} size={ButtonSize.Small} fillMode="flat" onClick={toggleDescription}>
				{isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
				{buttonTitle}
			</Button>
			{isExpanded && <div className={styles.errorContent}>{error}</div>}
		</div>
	);
};
