import { QUERY_FUNCTION, QUERY_OPERATOR, QUERY_PUNCTUATION, KUSTO_TIME_RANGE_KEYS } from '@/app/_common/constants';
import { ChartStackBarKeys, TimeRangeFilterOption } from '@/app/_common/types';
import { getMakeSeriesStackedBarChartQuery } from './get-make-series-stacked-bar-chart-query';

interface GetMakeSeriesQuery {
	isStackedBarChartQuery?: boolean;
	query?: string;
	step?: number;
	timeRange: TimeRangeFilterOption;
	timestampColumn: string;
}

export const getMakeSeriesQuery = ({ isStackedBarChartQuery, query, step, timeRange, timestampColumn }: GetMakeSeriesQuery): string => {
	try {
		const { NOW, TYPEOF, COUNT, DOUBLE, AGO, TODATETIME, BETWEEN, DATETIME } = QUERY_FUNCTION;
		const { MV_EXPAND, MAKE_SERIES, TO, STEP, FROM, ON } = QUERY_OPERATOR;
		const { PIPELINE, ROUND_BRACKET_OPEN, ROUND_BRACKET_CLOSE, SQUARE_BRACKET_OPEN, SQUARE_BRACKET_CLOSE, ASSIGN, COMMA, DOUBLE_QUOTES } =
			QUERY_PUNCTUATION;
		const defaultTime = `${NOW}${ROUND_BRACKET_OPEN}${ROUND_BRACKET_CLOSE}`;

		let fromTime = defaultTime;
		let toTime = defaultTime;

		// timestamp filters are in draft query string
		if (query) {
			const fromTimeAgoRegExp = new RegExp(`${AGO}\\(([\\d]+[\\D]+)\\)`); // handle ago(2d) cases
			const betweenTimeRegExp = new RegExp(`${BETWEEN}.*\\(.+(\\(.+\\)).+(\\(.+\\))\\)`); // handle between (datetime(2022-11-01) .. datetime(2022-11-02)) cases
			const fromTimeAgoMatch = fromTimeAgoRegExp.exec(query) || [];
			const betweenTimeMatch = betweenTimeRegExp.exec(query) || [];
			const fromTimeAgo = fromTimeAgoMatch[0];
			const betweenTimeMatchFrom = betweenTimeMatch[1];
			const betweenTimeMatchTo = betweenTimeMatch[2];

			// handle patterns like 'ago(2d)'
			if (fromTimeAgo) {
				fromTime = fromTimeAgo;
			} else if (betweenTimeMatchFrom && betweenTimeMatchTo) {
				// handle patterns like 'between (datetime(2022-11-01) .. datetime(2022-11-02))'
				fromTime = `${DATETIME}${betweenTimeMatchFrom}`;
				toTime = `${DATETIME}${betweenTimeMatchTo}`;
			}
		} else if (timeRange.value?.from && timeRange.value?.to) {
			// handle datepicker date time ranges
			fromTime = `${DATETIME}${ROUND_BRACKET_OPEN}${timeRange.value.from}${ROUND_BRACKET_CLOSE}`;
			toTime = `${DATETIME}${ROUND_BRACKET_OPEN}${timeRange.value.to}${ROUND_BRACKET_CLOSE}`;
		}

		if (isStackedBarChartQuery) {
			return getMakeSeriesStackedBarChartQuery({ fromTime, toTime, step });
		}

		return `${MAKE_SERIES} ${ChartStackBarKeys.EventsCount}${ASSIGN}${COUNT}${ROUND_BRACKET_OPEN}${ROUND_BRACKET_CLOSE} ${ON} ${TODATETIME}${ROUND_BRACKET_OPEN}${SQUARE_BRACKET_OPEN}${DOUBLE_QUOTES}${timestampColumn}${DOUBLE_QUOTES}${SQUARE_BRACKET_CLOSE}${ROUND_BRACKET_CLOSE} ${FROM} ${fromTime} ${TO} ${toTime} ${STEP} ${step}${KUSTO_TIME_RANGE_KEYS.SECOND} ${PIPELINE} ${MV_EXPAND} ${ChartStackBarKeys.EventsCount} ${TO} ${TYPEOF}${ROUND_BRACKET_OPEN}${DOUBLE}${ROUND_BRACKET_CLOSE}${COMMA} ${timestampColumn} ${TO} ${TYPEOF}${ROUND_BRACKET_OPEN}${DATETIME}${ROUND_BRACKET_CLOSE}`;
	} catch (error) {
		return '';
	}
};
