import { AlertStateOptions } from '@/app/_common/constants';

export const getAlertStateColor = (state: AlertStateOptions): string => {
	switch (state) {
		case AlertStateOptions.Assigned:
			return 'var(--assignedColor)';
		case AlertStateOptions.Unassigned:
			return 'var(--unassignedColor)';
		case AlertStateOptions.Dismissed:
			return 'var(--dismissedColor)';
		default:
			return 'var(--unassignedColor)';
	}
};
