import _dropRight from 'lodash/dropRight';
import _union from 'lodash/union';

import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

import { ResultItemGridEdge, AlertSpecialFieldNames } from '@/app/_common/types';
import { SortDirection } from '@/app/_common/constants';
import { sortByOrder } from '@/app/_common/utils';
import { SEVERITY_PRIORITY, CONFIDENCE_PRIORITY, MITRE_TACTICS_PRIORITY } from '@/app/_features/alerts/_common/constants';
import { sortArraysByOrder } from '@/app/_common/utils/sort/sort-arrays-by-order';

const emptyValue = null; // to be changed when we change handling of Unknown value

const IMPROVED_SEVERITY_PRIORITY = _union(_dropRight(SEVERITY_PRIORITY), [emptyValue]);
const IMPROVED_CONFIDENCE_PRIORITY = _union(CONFIDENCE_PRIORITY, [emptyValue]);

export const sortResults = (data: ResultItemGridEdge[], sort: SortDescriptor[]) => {
	const sortOptions = sort[0];

	if (sortOptions?.field === AlertSpecialFieldNames.Severity) {
		return sortByOrder(data, AlertSpecialFieldNames.Severity, IMPROVED_SEVERITY_PRIORITY, sortOptions?.dir as SortDirection);
	}

	if (sortOptions?.field === AlertSpecialFieldNames.Confidence) {
		return sortByOrder(data, AlertSpecialFieldNames.Confidence, IMPROVED_CONFIDENCE_PRIORITY, sortOptions?.dir as SortDirection);
	}

	if (sortOptions?.field === AlertSpecialFieldNames.MitreTactics) {
		return sortArraysByOrder(data, AlertSpecialFieldNames.MitreTactics, MITRE_TACTICS_PRIORITY, sortOptions?.dir as SortDirection);
	}

	return orderBy<ResultItemGridEdge>(data, sort);
};
