export const appendSearchParam = (url: string, searchParam: string) => {
	if (!searchParam || searchParam.length === 0) {
		return url;
	}

	if (!url.includes(`?`)) {
		return `${url}?${searchParam}`;
	}

	return `${url}&${searchParam}`;
};
