import { BreadcrumbListItemProps } from '@progress/kendo-react-layout';

import styles from './breadcrumbs.module.scss';

export const BreadcrumbListItem = (props: BreadcrumbListItemProps) => {
	return (
		<li {...props} className={styles.breadcrumbsListItem}>
			{props.children}
		</li>
	);
};
