import { useState, MouseEvent as ReactMouseEvent, KeyboardEvent as ReactKeyboardEvent } from 'react';

import { useOutsideClick } from '@/app/_common/hooks';
import { PopupOffset } from '@/app/_common/types';

const isMouseEvent = (event: ReactMouseEvent | ReactKeyboardEvent): event is ReactMouseEvent => {
	return event.nativeEvent instanceof MouseEvent;
};

export const useContextMenu = () => {
	const [contextMenu, setContextMenu] = useState({
		open: false,
		offset: { left: 0, top: 0 } as PopupOffset,
	});

	const openContextMenu = (event: ReactMouseEvent | ReactKeyboardEvent) => {
		if (isMouseEvent(event)) {
			const offset = { left: event.clientX, top: event.clientY };
			setContextMenu({ open: true, offset });
		} else {
			const targetPosition = (event.currentTarget as HTMLElement).getBoundingClientRect();
			const offset = { left: targetPosition.left, top: targetPosition.top + targetPosition.height };
			setContextMenu({ open: true, offset });
		}
	};

	const closeContextMenu = () => {
		setContextMenu({ open: false, offset: contextMenu.offset });
	};

	const toggleContextMenu = (event: ReactMouseEvent | ReactKeyboardEvent) => {
		if (contextMenu.open) {
			closeContextMenu();
		} else {
			openContextMenu(event);
		}
	};

	const { popupRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(contextMenu.open, closeContextMenu);

	return {
		showContextMenu: contextMenu.open,
		contextMenuOffset: contextMenu.offset,
		openContextMenu,
		closeContextMenu,
		toggleContextMenu,
		contextMenuRef: popupRef,
	};
};
