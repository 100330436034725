import { getLimit } from './get-limit';
import { DEFAULT_RESULTS_LIMIT, QUERY_SEPARATOR, TableColumn } from '@/app/_common/constants';
import {
	getDateTimeFilter,
	getDateTimeColumns,
	getOrderBy,
	getQueryContent,
	getFilters,
	getTimestampColumn,
	retrieveQuery,
	removeQueryComments,
	removeLimitFromQueryString,
	getTablesFromQuery,
} from '@/app/_common/utils';
import { Schema, TimeRangeFilterOption, FieldFilterGroup } from '@/app/_common/types';

interface PrepareQuery {
	database: string;
	filtersList?: FieldFilterGroup[];
	includeTableName?: boolean;
	limit?: number | null;
	query: string;
	schema: Schema;
	timeRange: TimeRangeFilterOption;
}

export const prepareQuery = ({
	database,
	filtersList = [],
	includeTableName = false,
	limit = DEFAULT_RESULTS_LIMIT,
	query,
	schema,
	timeRange,
}: PrepareQuery): string => {
	const clearedQuery = removeQueryComments(query);
	const { query: _query, materializeMatch } = retrieveQuery(clearedQuery);

	const tableNames = getTablesFromQuery(_query);

	const isSearchQuery = tableNames.length === 0 && _query.startsWith('search');

	const tablesQuery = tableNames.length > 1 ? `union withsource=${TableColumn} ${tableNames.join(', ')}` : tableNames[0] ?? '';
	const extendQuery = includeTableName && tableNames.length === 1 ? `extend ${TableColumn}="${tableNames[0]}"` : '';
	const searchQuery = isSearchQuery ? _query.split('|')[0] : '';

	const dateTimeColumns = getDateTimeColumns(tableNames, schema, database);
	const timestampColumn = getTimestampColumn(dateTimeColumns);
	const timeRangeFilter = getDateTimeFilter(_query, dateTimeColumns, timestampColumn, timeRange);
	const orderBy = getOrderBy(_query, timestampColumn);
	const queryContent = getQueryContent(_query);
	const queryContentWithoutLimit = removeLimitFromQueryString(queryContent);
	const queryContentWithProject =
		tableNames.length > 1
			? queryContentWithoutLimit
					.split(QUERY_SEPARATOR)
					.map((querySlice: string) => {
						if (querySlice.trim().startsWith('project')) {
							return `${querySlice}, $table`;
						}
						return querySlice;
					})
					.join(QUERY_SEPARATOR)
			: queryContentWithoutLimit;
	const filters = getFilters(filtersList);
	const limitQuery = getLimit(_query, limit);

	let preparedQuery = [isSearchQuery ? searchQuery : tablesQuery, timeRangeFilter, orderBy, queryContentWithProject, filters, limitQuery, extendQuery]
		.filter((value) => value)
		.join(` ${QUERY_SEPARATOR} `);

	if (materializeMatch && materializeMatch.groups) {
		preparedQuery = `${materializeMatch.groups.start}${preparedQuery}${materializeMatch.groups.end}${materializeMatch.groups.rest}`;
	}

	return preparedQuery;
};
