import { cloneElement, ReactElement, ReactNode } from 'react';
import { GridRowProps } from '@progress/kendo-react-grid';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import dataGridStyles from '@/app/_common/_components/data-grid/data-grid.module.scss';

export interface UseRowRenderProps {
	idGetter: (values: GridRowProps['dataItem']) => string;
	isRowSelected: (id: string) => boolean;
	isRowExpanded?: boolean;
}

export const useRowRender = ({ isRowSelected, idGetter, isRowExpanded = false }: UseRowRenderProps) => {
	const Row = (trElement: ReactElement<HTMLTableRowElement>, props: GridRowProps): ReactNode => {
		// Wrapping with observer ensures proper rerendering!
		const ClonedRow = observer(() => {
			const id = idGetter(props.dataItem);
			const isSelected = isRowSelected(id);
			const className = classNames(dataGridStyles.row, trElement.props.className, {
				[dataGridStyles.selectedRow]: isSelected,
				[dataGridStyles.expandedRow]: isRowExpanded,
			});

			return cloneElement(
				trElement,
				{
					className,
				},
				trElement.props.children as ReactNode,
			);
		});

		ClonedRow.displayName = 'UseRowRenderClonedRow';
		return <ClonedRow />;
	};

	Row.displayName = 'UseRowRenderRow';
	return Row;
};
