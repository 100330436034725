import { FC } from 'react';
import { useInstance } from 'react-ioc';

import { observer } from 'mobx-react-lite';
import { Menu as MenuIcon } from '@mui/icons-material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { DrawerNavigation } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { hasUserRolePermission } from '@/app/_common/utils';
import { UserPermissions } from '@/app/_common/constants';

import { PageLayoutViewStore } from '../page-layout/page-layout.view-store';
import { ClientSidebarNavigationList } from './client-sidebar-navigation-list';
import { FranchiseSidebarNavigationList } from './franchise-sidebar-navigation-list';
import { ThemeStore, AuthStore } from '@/app/_common/stores';
import logoNTTWhite from '@/assets/images/ntt-white-logo.svg';
import logoNTTBlack from '@/assets/images/ntt-black-logo.svg';
import logoNTTVerticalWhite from '@/assets/images/ntt-vertical-white-logo.svg';
import logoNTTVerticalBlack from '@/assets/images/ntt-vertical-black-logo.svg';

import '@/assets/styles/index.scss';
import styles from './sidebar.module.scss';

export const Sidebar: FC = observer(() => {
	const store = useInstance(PageLayoutViewStore);
	const themeStore = useInstance(ThemeStore);
	const authStore = useInstance(AuthStore);
	const open = store.drawerOpened;
	const showClientSidebar = hasUserRolePermission(authStore.userRole, UserPermissions.CLIENT_SIDEBAR);
	const showFranchiseSidebar = hasUserRolePermission(authStore.userRole, UserPermissions.FRANCHISE_SIDEBAR);

	const NTTLogo = themeStore.isDarkTheme ? logoNTTWhite : logoNTTBlack;
	const VerticalNTTLogo = themeStore.isDarkTheme ? logoNTTVerticalWhite : logoNTTVerticalBlack;

	return (
		<DrawerNavigation className={styles.drawer}>
			{showFranchiseSidebar && <FranchiseSidebarNavigationList drawerOpened={open} theme={store.theme} />}
			{showClientSidebar && <ClientSidebarNavigationList drawerOpened={open} theme={store.theme} />}
			<hr className={`${styles.separatorBottom} ${styles.separator}`} />
			<Button fillMode="flat" onClick={open ? store.closeDrawer : store.openDrawer} className={open ? styles.menuIconOpen : styles.menuIconClosed}>
				{open ? <MenuOpenIcon /> : <MenuIcon />}
			</Button>

			{open && <img src={NTTLogo} alt="NTT Logo" className={styles.nttLogoOpen} />}
			{!open && <img src={VerticalNTTLogo} alt="NTT Logo" className={styles.nttLogoClose} />}
		</DrawerNavigation>
	);
});
