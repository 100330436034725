import { gql } from '@apollo/client';
import _uniq from 'lodash/uniq';

import { CoreEndpointFragment } from '../fragments.constants';

export function createGetEndpointsByIdsQuery(ids: string[]) {
	const queries = _uniq(ids).map(
		(id, index) => `getEndpoint${index}: getEndpoint(tenantId: $tenantId, id: "${id}") {
			... on Endpoint {
				id
				action {
					status
					type
				}
				hostname
				networkInformation {
					lastIpAddress
				}
				operatingSystem
				responseIntegration {
					id
					name
					productName
					vendor
				}
				currentStatus
				__typename
			}
		}`,
	);

	const queryContent = 'query GetEndpointsByIds($tenantId: ID!) {' + queries.join('\n') + '}';

	return gql`
		${queryContent}
	`;
}

export const ListEndpoints = gql`
	query ListEndpoints($tenantId: ID!, $input: Paging, $filtersInput: FiltersInput, $sortersInput: SortersInput) {
		listEndpoints(tenantId: $tenantId, input: $input, filtersInput: $filtersInput, sortersInput: $sortersInput) {
			edges {
				node {
					...CoreEndpointFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${CoreEndpointFragment}
`;

export const GetEndpointsTableHeadersCount = gql`
	query GetEndpointsTableHeadersCount($tenantId: ID!, $filtersInput: FiltersInput) {
		getEndpointsTableHeadersCount(tenantId: $tenantId, filtersInput: $filtersInput) {
			... on EndpointsTableHeadersCount {
				hostname
				lastIpAddress
				operatingSystem
				product
				responseIntegration
				currentStatus
				vendor
				total
				__typename
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const GetEndpoint = gql`
	query GetEndpoint($tenantId: ID!, $id: ID!) {
		getEndpoint(tenantId: $tenantId, id: $id) {
			... on Endpoint {
				id
				operatingSystem
				osVendor
				networkInformation {
					ipAddresses {
						ipAddress
						macAddress
						operationalStatus
						type
					}
				}
				responseIntegration {
					id
					vendor
					productName
				}
				statistics {
					firstSeenTimestamp
					lastSeenTimestamp
				}
				last_updated
				externalReference
			}
			... on Error {
				code
				message
				correlationId
			}
		}
	}
`;

export const ListEndpointsTableFilterValues = gql`
	query ListEndpointsTableFilterValues($tenantId: ID!, $column: String!, $filtersInput: FiltersInput) {
		listEndpointsTableFilterValues(tenantId: $tenantId, column: $column, filtersInput: $filtersInput) {
			... on EndpointsTableFilterValues {
				filters {
					count
					value
				}
			}
			... on Error {
				correlationId
				code
				message
			}
		}
	}
`;

export const CreateActionInEndpoints = gql`
	mutation CreateActionInEndpoints($tenantId: ID!, $createActionInEndpointsRequest: CreateActionInEndpointsInput!) {
		createActionInEndpoints(tenantId: $tenantId, createActionInEndpointsRequest: $createActionInEndpointsRequest) {
			... on EndpointActionElements {
				endpoints {
					...CoreEndpointFragment
				}
			}
			... on Error {
				code
				message
				correlationId
			}
		}
	}
	${CoreEndpointFragment}
`;

export const EndpointCreatedSubscription = gql`
	subscription EndpointCreatedSubscription($tenantId: ID!) {
		endpointCreated(tenantId: $tenantId) {
			...CoreEndpointFragment
		}
	}
	${CoreEndpointFragment}
`;

export const EndpointUpdatedSubscription = gql`
	subscription EndpointUpdatedSubscription($tenantId: ID!) {
		endpointUpdated(tenantId: $tenantId) {
			...CoreEndpointFragment
		}
	}
	${CoreEndpointFragment}
`;
