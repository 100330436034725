import { FC, useCallback, useMemo, useState, MouseEvent } from 'react';
import _uniq from 'lodash/uniq';

import { Align } from '@progress/kendo-react-popup';

import { Investigation, FiltersInput, ValueOperator } from '@/generated/graphql';
import { AlertActionProps } from '@/app/_common/types';
import { ContextMenu } from '@/app/_common/_components';
import { createListInvestigationsQuery } from '@/app/_common/graphql/queries';
import { HeaderAlertActionsIcon, HeaderAlertActionsContent } from '@/app/_common/_components/details-view/details-view-header/_components';
import { DetailsViewItemData } from '@/app/_common/_components/details-view/types';

const DROPDOWN_ANCHOR_ALIGN: Align = {
	horizontal: 'right',
	vertical: 'bottom',
};

const DROPDOWN_POPUP_ALIGN: Align = {
	horizontal: 'right',
	vertical: 'top',
};

interface HeaderAlertActionsProps {
	singleActionsTitle?: string;
	bulkActionsTitle?: string;
	selectedItems?: DetailsViewItemData[];
	selectedItem?: DetailsViewItemData;
	singleActions?: AlertActionProps[];
	bulkActions?: AlertActionProps[];
}

interface ContextMenuData {
	data: {
		listInvestigations?: {
			edges: {
				node: Investigation;
			}[];
		};
	};
	loading: boolean;
	close: (event?: MouseEvent) => void;
}

export const HeaderAlertActions: FC<HeaderAlertActionsProps> = ({
	selectedItem,
	selectedItems,
	singleActionsTitle,
	singleActions,
	bulkActions,
	bulkActionsTitle,
}) => {
	const [filtersInput, setFiltersInput] = useState<FiltersInput>();
	const hasActions = (singleActions && singleActions?.length > 0) || (bulkActions && bulkActions?.length > 0);

	const query = useMemo(() => {
		const investigationIds: string[] = _uniq(
			[selectedItem?.investigationSummary?.id, ...(selectedItems?.map((item) => item.investigationSummary?.id) || [])].filter(
				(id): id is string => typeof id === 'string',
			),
		);

		if (investigationIds.length === 0) {
			setFiltersInput(undefined);
			return;
		}

		setFiltersInput({
			valueFilters: [
				{
					field: 'id',
					operator: ValueOperator.IsIn,
					values: investigationIds,
				},
			],
		});

		return createListInvestigationsQuery();
	}, [selectedItem, selectedItems]);

	const ContentRenderer = useCallback(
		({ data, loading, close }: ContextMenuData) => (
			<HeaderAlertActionsContent
				data={data}
				loading={loading}
				close={close}
				singleActions={singleActions}
				singleActionsTitle={singleActionsTitle}
				bulkActions={bulkActions}
				bulkActionsTitle={bulkActionsTitle}
			/>
		),
		[singleActions, singleActionsTitle, bulkActions, bulkActionsTitle],
	);

	if (!hasActions) {
		return null;
	}

	return (
		<ContextMenu
			filtersInput={filtersInput}
			query={query}
			anchorAlign={DROPDOWN_ANCHOR_ALIGN}
			popupAlign={DROPDOWN_POPUP_ALIGN}
			content={ContentRenderer}
		>
			<HeaderAlertActionsIcon />
		</ContextMenu>
	);
};
