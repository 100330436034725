import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { getter } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { GridCellProps } from '@progress/kendo-react-grid';

import { sortWithExceptions } from '@/app/_common/utils/sort-with-exceptions/sort-with-exceptions';
import { WidthsState } from '@/app/_common/types';
import { DEFAULT_COLUMN_WIDTH } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_common/constants';
import { ResultsTablePropertiesPaths } from '@/app/_common/constants/advanced-query-results-table.constants';
import { Column } from '@/app/_common/_components';

import { LARGE_COLUMN_WIDTH } from '@/app/_common/constants';
import { PLACEHOLDER_COLUMN } from '@/app/_common/_components/data-grid/constants';
import { GridNoRecordsContent } from '@/app/_common/_components/data-grid/_components/grid-no-records-content';
import { RESULTS_COLUMNS_ORDER_CONFIG } from './query-results-table.constants';

import {
	QueryResultsTableActionsCell,
	QueryResultsTableConfidenceCell,
	QueryResultsTableDateTimeCell,
	QueryResultsTableExpandedCell,
	QueryResultsTableIconCell,
	QueryResultsTableResultsActionsHeaderCell,
	QueryResultsTableSelectCell,
	QueryResultsTableSeverityCell,
	QueryResultsTableStandardCell,
	QueryResultsTableStandardHeaderCell,
	QueryResultsTableSelectHeaderCell,
} from '@/app/_common/_components/query-results/query-results-table/components/cells';
import { QueryResultsTableList, useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';
import { ContextMenuContentKey } from '@/app/_common/_components/query-results/query-results-table/types';

import styles from './query-results-table.module.scss';

const idGetter = getter(ResultsTablePropertiesPaths.Id);

export interface QueryResultsTableProps {
	totalQueryResultsCount?: number;
	shouldRenderPaginator?: boolean;
	hideExpandedColumn?: boolean;
	hideRowActions?: boolean;
	showSkeletons?: boolean;
	disableLookup?: boolean;
	disableVirtualRows?: boolean;
	contextMenuContentKey?: ContextMenuContentKey;
	dataGridClassName?: string;
	itemDetailsId?: string;
}

export const QueryResultsTable = observer(
	({
		shouldRenderPaginator = true,
		totalQueryResultsCount = 0,
		hideExpandedColumn = false,
		hideRowActions = false,
		showSkeletons = false,
		contextMenuContentKey,
		disableLookup = false,
		disableVirtualRows,
		dataGridClassName,
		itemDetailsId,
	}: QueryResultsTableProps) => {
		const { results, resultsColumnNames, columns, areFiltersActive, resetAllFilters, expandedRows } = useQueryResultsTableStore();

		const initialWidths: WidthsState = useMemo(() => {
			const resultsColumnsInitialWidths = resultsColumnNames.reduce((acc, curr) => ({ ...acc, [curr]: DEFAULT_COLUMN_WIDTH }), {});

			return Object.assign(resultsColumnsInitialWidths, {
				[ResultsTablePropertiesPaths.Selected]: '50px',
				[ResultsTablePropertiesPaths.Actions]: '70px',
				[ResultsTablePropertiesPaths.Expanded]: '50px',
				[ResultsTablePropertiesPaths.Table]: '50px',
			});
		}, [resultsColumnNames]);

		const resultsColumns = useMemo(() => {
			const gridColumns: Column[] = [];

			if (!resultsColumnNames?.length) {
				return gridColumns;
			}

			const customColumns = resultsColumnNames.map((column) => {
				const baseColumnConfig = {
					id: column,
					field: column,
					title: column,
					width: initialWidths[column],
					headerClassName: styles.headerCell,
					minResizableWidth: LARGE_COLUMN_WIDTH,
				};

				if (column === ResultsTablePropertiesPaths.Timestamp) {
					return {
						...baseColumnConfig,
						cell: QueryResultsTableDateTimeCell,
						headerCell: QueryResultsTableStandardHeaderCell,
						show: true,
					};
				}
				if (column === ResultsTablePropertiesPaths.Table) {
					return {
						...baseColumnConfig,
						cell: QueryResultsTableIconCell,
						headerCell: () => <span />,
						resizable: false,
						show: columns[column],
						title: ' ',
					};
				}
				if (column === ResultsTablePropertiesPaths.Confidence) {
					return {
						...baseColumnConfig,
						cell: QueryResultsTableConfidenceCell,
						headerCell: QueryResultsTableStandardHeaderCell,
						resizable: false,
						show: columns[column],
					};
				}
				if (column === ResultsTablePropertiesPaths.Severity) {
					return {
						...baseColumnConfig,
						cell: QueryResultsTableSeverityCell,
						headerCell: QueryResultsTableStandardHeaderCell,
						resizable: false,
						show: columns[column],
					};
				}

				return {
					...baseColumnConfig,
					cell: QueryResultsTableStandardCell,
					headerCell: QueryResultsTableStandardHeaderCell,
					resizable: true,
					show: columns[column],
				};
			});

			return sortWithExceptions(customColumns, {
				exceptions: RESULTS_COLUMNS_ORDER_CONFIG,
				accessor: (item) => item.field,
			});
		}, [resultsColumnNames, initialWidths, columns]);

		const COLUMNS: Column[] = useMemo(
			() => [
				{
					id: ResultsTablePropertiesPaths.Selected,
					width: initialWidths[ResultsTablePropertiesPaths.Selected],
					headerCell: QueryResultsTableSelectHeaderCell,
					resizable: false,
					show: true,
					locked: true,
					cell: QueryResultsTableSelectCell,
				},
				{
					id: ResultsTablePropertiesPaths.Expanded,
					field: ResultsTablePropertiesPaths.Expanded,
					title: ' ',
					width: initialWidths[ResultsTablePropertiesPaths.Expanded],
					cell: QueryResultsTableExpandedCell,
					headerCell: () => <span />,
					resizable: false,
					show: !hideExpandedColumn,
					locked: true,
				},
				...resultsColumns,
				/* A column that shrinks or expands to fill in the remaining space */
				PLACEHOLDER_COLUMN,
				{
					id: ResultsTablePropertiesPaths.Actions,
					cell: (props: GridCellProps) => <QueryResultsTableActionsCell {...props} hideIcon={hideRowActions} />,
					headerCell: QueryResultsTableResultsActionsHeaderCell,
					width: initialWidths[ResultsTablePropertiesPaths.Actions],
					resizable: false,
					locked: true,
					show: true,
					headerClassName: styles.headerCell,
				},
			],
			[initialWidths, hideExpandedColumn, resultsColumns, hideRowActions],
		);

		const data = useMemo(
			() =>
				results.map((item) => ({
					...item,
					[ResultsTablePropertiesPaths.Expanded]: expandedRows[idGetter(item)],
				})),
			[results, expandedRows],
		);

		const onFiltersReset = useCallback(() => resetAllFilters(), [resetAllFilters]);

		const gridNoRecordsContent = areFiltersActive ? <GridNoRecordsContent onFiltersReset={onFiltersReset} /> : null;

		return (
			<section className={styles.resultsTable}>
				<div className={classNames(styles.tableContainer)}>
					{showSkeletons ? (
						<Skeleton shape="rectangle" className={styles.skeleton} />
					) : (
						<QueryResultsTableList
							data={data}
							columns={COLUMNS}
							gridNoRecordsContent={gridNoRecordsContent}
							shouldRenderPaginator={shouldRenderPaginator}
							totalCount={totalQueryResultsCount}
							contextMenuContentKey={contextMenuContentKey}
							disableLookup={disableLookup}
							disableVirtualRows={disableVirtualRows}
							dataGridClassName={dataGridClassName}
							itemDetailsId={itemDetailsId}
						/>
					)}
				</div>
			</section>
		);
	},
);

QueryResultsTable.displayName = 'QueryResultsTable';
