import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { Loader } from '@progress/kendo-react-indicators';

import styles from '../editor-button.module.scss';

interface SendButtonProps {
	icon: ReactNode;
	disabled?: boolean;
	loading?: boolean;
	handleSubmit: () => void;
}

export const SendButton: FC<SendButtonProps> = ({ disabled, icon, loading, handleSubmit }) => {
	return (
		<button
			disabled={disabled || loading}
			className={classNames(styles.blockBtn, { [styles.disabled]: disabled, [styles.loading]: loading })}
			onClick={handleSubmit}
			data-testid="rich-text-editor-send-btn"
		>
			{loading ? <Loader type="pulsing" /> : icon}
		</button>
	);
};
