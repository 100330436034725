export const DEFAULT_PIE_CHART_OPTIONS = {
	showTooltip: false,
	showPercentage: true,
	strokeWidth: 2,
	strokeColor: 'var(--telemetryHealthPieChartStrokeColor)',
};

export const DEFAULT_PIE_CHART_MARGIN = {
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
};

export const MINIMUM_ANGLE_VALUE = 0.2;

export const PIE_CHART_TOOLTIP_ID = 'pieChartTooltip';
