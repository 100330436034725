import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { FC } from 'react';

import { Field } from '@progress/kendo-react-form';

import { EnvironmentVariable, EnvironmentVariableType, VariableRequired } from '@/generated/graphql';
import { FormCheckbox, FormInput, FormSelect } from '@/app/_common/_components/form-components';
import { Namespaces } from '@/translations/namespaces';
import { NameValidationRegEx } from '@/app/_common/constants';

import { displayValidateMessage } from '@/app/_common/_components/integration-details-form/_utils';

import styles from '@/app/_common/_components/integration-details-form/integration-details-form.module.scss';

interface FieldProps {
	keyName: string;
	displayName: string;
	type: EnvironmentVariableType;
	required: VariableRequired;
	t: TFunction<Namespaces.CreateIntegration[]>;
	register: UseFormRegister<FieldValues>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: { [key: string]: any };
	description?: null | string;
	isFormDisabled?: boolean;
	enumValue?: null | string[];
}

const MIN_LENGTH = 2;
const MAX_LENGTH = 128;

const NAME_FIELD = 'Name';

const PasswordField: FC<FieldProps> = ({ keyName, displayName, type, isFormDisabled, required, description, t, register, errors }) => {
	return (
		<div className={styles.field} key={keyName}>
			<Field
				id={displayName}
				label={displayName}
				type={type}
				component={FormInput}
				placeholder={isFormDisabled ? undefined : displayName}
				optional={!required}
				disabled={isFormDisabled}
				tooltipMessage={description}
				message={errors[keyName] ? t('validateMessage', { fieldName: displayName }) : undefined}
				{...register(keyName, {
					required: required.mandatory,
				})}
			/>
		</div>
	);
};

const IntegerField: FC<FieldProps> = ({ keyName, displayName, isFormDisabled, required, description, t, register, errors }) => {
	return (
		<div className={styles.field} key={keyName}>
			<Field
				id={displayName}
				label={displayName}
				type="text"
				component={FormInput}
				placeholder={isFormDisabled ? undefined : displayName}
				optional={!required.mandatory}
				tooltipMessage={description}
				disabled={isFormDisabled}
				message={errors[keyName] ? t('validateMessage', { fieldName: displayName }) : undefined}
				{...register(keyName, { required: required.mandatory })}
			/>
		</div>
	);
};

const BooleanField: FC<FieldProps> = ({ keyName, displayName, isFormDisabled, required, t, register, errors }) => {
	return (
		<div className={styles.field} key={keyName}>
			<Field
				id={displayName}
				type="checkbox"
				component={FormCheckbox}
				data={[{ label: displayName, value: true }]}
				placeholder={isFormDisabled ? undefined : displayName}
				label={displayName}
				optional={!required.mandatory}
				disabled={isFormDisabled}
				message={errors[keyName] ? t('validateMessage', { fieldName: displayName }) : undefined}
				{...register(keyName)}
			/>
		</div>
	);
};

const StringField: FC<FieldProps> = ({ keyName, displayName, type, isFormDisabled, required, description, t, register, errors }) => {
	if (displayName === NAME_FIELD) {
		return (
			<div className={styles.field} key={keyName}>
				<Field
					id={displayName}
					label={displayName}
					type={type}
					component={FormInput}
					touched={true}
					placeholder={isFormDisabled ? undefined : displayName}
					optional={!required.mandatory}
					valid={false}
					tooltipMessage={description}
					disabled={isFormDisabled}
					message={errors[keyName] ? displayValidateMessage(keyName, errors[keyName].type, MAX_LENGTH, MIN_LENGTH) : undefined}
					{...register(keyName, {
						required: required.mandatory,
						minLength: MIN_LENGTH,
						maxLength: MAX_LENGTH,
						pattern: NameValidationRegEx,
					})}
				/>
			</div>
		);
	}
	return (
		<div className={styles.field} key={keyName}>
			<Field
				id={displayName}
				label={displayName}
				type="text"
				component={FormInput}
				placeholder={isFormDisabled ? undefined : displayName}
				optional={!required.mandatory}
				disabled={isFormDisabled}
				tooltipMessage={description}
				message={errors[keyName] ? t('validateMessage', { fieldName: displayName }) : undefined}
				{...register(keyName, { required: required.mandatory })}
			/>
		</div>
	);
};

const EnumField: FC<FieldProps> = ({ enumValue, keyName, displayName, isFormDisabled, description, required, t, register, errors }) => {
	if (!enumValue) {
		return null;
	}
	return (
		<div className={styles.field} key={keyName}>
			<Field
				id={displayName}
				label={displayName}
				name={displayName}
				component={FormSelect}
				optional={!required}
				message={errors[keyName] ? t('validateMessage', { fieldName: displayName }) : undefined}
				defaultValue={enumValue[0]}
				values={enumValue.filter((value) => value !== '-')}
				disabled={isFormDisabled}
				tooltipMessage={description}
				register={register(keyName, { required: required.mandatory })}
			/>
		</div>
	);
};

const DefaultField: FC<FieldProps> = ({ keyName, displayName, type, isFormDisabled, required, description, t, register, errors }) => {
	return (
		<div className={styles.field} key={keyName}>
			<Field
				id={displayName}
				label={displayName}
				type={type}
				component={FormInput}
				placeholder={isFormDisabled ? undefined : displayName}
				optional={required}
				tooltipMessage={description}
				disabled={isFormDisabled}
				message={errors[keyName] ? t('validateMessage', { fieldName: displayName }) : undefined}
				{...register(keyName, { required: required.mandatory })}
			/>
		</div>
	);
};

export const CreateFormFields = (
	fields: Record<string, EnvironmentVariable>[] | undefined,
	register: UseFormRegister<FieldValues>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: { [key: string]: any },
	isFormDisabled?: boolean,
) => {
	const { t } = useTranslation([Namespaces.CreateIntegration]);

	if (!fields) {
		return null;
	}

	return fields
		.map((key) => {
			const keyName = Object.keys(key)[0];
			const { type, displayName: displayName, enumValues: enumValue, required, description } = Object.values(key)[0];

			const fieldProps: FieldProps = {
				keyName,
				displayName,
				type,
				isFormDisabled,
				required,
				t,
				register,
				errors,
				description,
				enumValue,
			};

			switch (type) {
				case EnvironmentVariableType.Password: {
					return <PasswordField {...fieldProps} />;
				}
				case EnvironmentVariableType.Username:
				case EnvironmentVariableType.String: {
					return <StringField {...fieldProps} />;
				}
				case EnvironmentVariableType.Integer: {
					return <IntegerField {...fieldProps} />;
				}
				case EnvironmentVariableType.Boolean: {
					return <BooleanField {...fieldProps} />;
				}
				case EnvironmentVariableType.Enum: {
					if (enumValue) {
						return <EnumField {...fieldProps} />;
					}
					break;
				}

				default: {
					return <DefaultField {...fieldProps} />;
				}
			}
		})
		.filter((value) => value);
};
