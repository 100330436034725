import { ResultsTablePropertiesPaths } from '@/app/_common/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDetailsViewRowId = (item?: any): string => {
	try {
		return item[ResultsTablePropertiesPaths.Id] ?? '';
	} catch (error) {
		return '';
	}
};
