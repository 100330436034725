import { INTERVAL_UNITS } from '@/app/_common/constants';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

export const displayInterval = (seconds: number | undefined, isAuto?: boolean, decimalDigits?: number) => {
	if (!seconds) {
		return;
	}

	let smallestUnit = INTERVAL_UNITS[0];

	for (const unit of INTERVAL_UNITS) {
		if (seconds >= unit.value) {
			smallestUnit = unit;
		}
	}

	const { value, unit, minPredefinedValue } = smallestUnit;
	let interval;

	if (decimalDigits) {
		interval = (seconds / value).toFixed(decimalDigits);
	} else if (!isAuto && minPredefinedValue) {
		interval = minPredefinedValue / value;
	} else {
		interval = Math.floor(seconds / value).toString();
	}

	interval += ` ${unit}`;

	if (isAuto) {
		interval += ` (${i18n.t('tabs.advancedQuery.chart.actionBlock.auto', { ns: Namespaces.AdvancedQuery })})`;
	}

	return interval;
};
