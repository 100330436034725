import { FC } from 'react';

import { AlertsDetailsSessionsPropertiesPaths } from '@/app/_common/constants/alert-details-sessions.constants';
import {
	DefaultOptionGroupItemLabel,
	MitreTacticsOptionGroupItemLabel,
} from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/option-group-item-label/components';
import {
	LabelRenderersMap,
	OptionGroupItemLabelProps,
} from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/option-group-item-label/types';

const labelRenderers: LabelRenderersMap = {
	default: DefaultOptionGroupItemLabel,
	[AlertsDetailsSessionsPropertiesPaths.MitreTactics]: MitreTacticsOptionGroupItemLabel,
};

const renderLabelByFieldName = (props: OptionGroupItemLabelProps) => {
	const { field = '' } = props;
	const Component = labelRenderers[field] || labelRenderers['default'];
	return <Component {...props} />;
};

export const OptionGroupItemLabel: FC<OptionGroupItemLabelProps> = (props: OptionGroupItemLabelProps) => {
	return renderLabelByFieldName(props);
};
