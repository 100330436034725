import { FC, useMemo } from 'react';

import { v4 as uuid } from 'uuid';

import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { AlertSpecialFieldNames } from '@/app/_common/types';
import { ADX_FILTER_OPERATORS } from '@/app/_common/constants';
import { FieldFilterGroup, FieldFilterValue } from '@/app/_common/types/adx';
import { Button, Tooltip } from '@/app/_common/_components';
import { getConfidenceLabel, getSeverityLabel, isFilterByOperator } from '@/app/_common/utils';

import styles from './field-filters-chip.module.scss';

const getSpecialLabelForValue = (field: string, value: FieldFilterValue): FieldFilterValue => {
	if (field === AlertSpecialFieldNames.Confidence) {
		return getConfidenceLabel(value.toString());
	}

	if (field === AlertSpecialFieldNames.Severity) {
		return getSeverityLabel(value.toString());
	}

	return value;
};

interface FieldFiltersChipProps {
	field: string;
	values: FieldFilterValue[];
	operator: ADX_FILTER_OPERATORS;
	onRemove: (filter: FieldFilterGroup) => void;
}

export const FieldFilterChip: FC<FieldFiltersChipProps> = ({ field, values = [], operator, onRemove }) => {
	const handleRemove = () => {
		onRemove({ field, values, operator });
	};

	const tooltipId = useMemo(() => uuid(), []);
	const valuesString = values.map((value) => getSpecialLabelForValue(field, value)).join(', ');

	return (
		<li className={styles.chip}>
			<Button className={styles.removeButton} onClick={handleRemove}>
				<CloseIcon className={styles.removeIcon} />
			</Button>
			<div className={styles.content}>
				{isFilterByOperator(operator) ? (
					<AddCircleIcon className={styles.equalsIcon} data-testid="positive-filter" />
				) : (
					<RemoveCircleIcon className={styles.notEqualsIcon} data-testid="negative-filter" />
				)}
				<div className={styles.field}>{field}:&nbsp;</div>
				<div className={styles.value} data-tip={true} data-for={tooltipId}>
					{valuesString}
				</div>
				<Tooltip tooltipId={tooltipId} tooltipContent={valuesString} place={'bottom'} />
			</div>
		</li>
	);
};
