import { FC, useCallback, useState } from 'react';
import classNames from 'classnames';

import { Popup } from '@progress/kendo-react-popup';
import { Info } from '@mui/icons-material';

import { useOutsideClick } from '@/app/_common/hooks';

import styles from './info-popup.module.scss';

interface InfoPopupProps {
	popupContent: string | JSX.Element;
	className?: string;
}

export const InfoPopup: FC<InfoPopupProps> = ({ popupContent, className = '' }) => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	const { popupRef, anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		showDropdown,
		useCallback(() => setShowDropdown(false), []),
	);
	return (
		<>
			<div ref={anchorRef} className={classNames(styles.infoPopup, className)}>
				<Info onMouseMove={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)} />
			</div>
			<Popup
				animate={false}
				anchor={anchorRef.current}
				popupClass={styles.popup}
				show={showDropdown}
				anchorAlign={{ horizontal: 'right', vertical: 'center' }}
				popupAlign={{ horizontal: 'left', vertical: 'center' }}
				collision={{
					horizontal: 'flip',
					vertical: 'fit',
				}}
			>
				<div className={styles.popupContent} ref={popupRef}>
					{popupContent}
				</div>
			</Popup>
		</>
	);
};
