import { ChipItem } from '@/app/_common/_components';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

export enum ActionHistoryDataGridPropertyPaths {
	Date = 'node.timestamp',
	User = 'node.requesterUser.upn',
	Category = 'node.resourceType',
	Action = 'node.action',
	CorrelationId = 'node.correlationId',
	Description = 'node.message',
	IsFranchiseAction = 'isFranchiseAction',
}

export const ActionHistoryHeadersTranslations = {
	[ActionHistoryDataGridPropertyPaths.Date]: i18n.t('grid.headers.date', { ns: Namespaces.ActionHistory }),
	[ActionHistoryDataGridPropertyPaths.User]: i18n.t('grid.headers.user', { ns: Namespaces.ActionHistory }),
	[ActionHistoryDataGridPropertyPaths.Category]: i18n.t('grid.headers.category', { ns: Namespaces.ActionHistory }),
	[ActionHistoryDataGridPropertyPaths.Action]: i18n.t('grid.headers.action', { ns: Namespaces.ActionHistory }),
	[ActionHistoryDataGridPropertyPaths.CorrelationId]: i18n.t('grid.headers.correlationId', { ns: Namespaces.ActionHistory }),
	[ActionHistoryDataGridPropertyPaths.Description]: i18n.t('grid.headers.description', { ns: Namespaces.ActionHistory }),
};

export enum MultiselectAvailableFilters {
	CLIENT = 'client',
	FRANCHISE = 'franchise',
}

export const ActionHeadersMultiselectFilters: ChipItem[] = [
	{
		value: MultiselectAvailableFilters.CLIENT,
		label: i18n.t('grid.multiselectLabels.client', { ns: Namespaces.ActionHistory }),
	},
	{
		value: MultiselectAvailableFilters.FRANCHISE,
		label: i18n.t('grid.multiselectLabels.franchise', { ns: Namespaces.ActionHistory }),
	},
];
