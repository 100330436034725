import { getConfidenceLabel } from '@/app/_common/utils';
import { getConfidenceColorIndicator } from '@/app/_common/utils/colors';
import { AlertConfidence } from '@/app/_common/constants';
import { FilterValue } from '@/app/_common/types';

export const getConfidenceFilterOptions = (value: FilterValue) => {
	const label = getConfidenceLabel(value as string);
	const bulletsConfig = getConfidenceColorIndicator(value as AlertConfidence);
	const special = value === AlertConfidence.Unknown;

	return {
		label,
		bulletsConfig,
		special,
	};
};
