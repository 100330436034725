import { makeObservable, action } from 'mobx';

import { FetchBaseDataStore } from '@/app/_common/fetch';

interface SignatureResponse {
	userSignature: string;
	tenantSignature: string;
}

export class UserflowSignatureDataStore extends FetchBaseDataStore<SignatureResponse> {
	constructor() {
		super();

		makeObservable(this, {
			read: action,
		});
	}

	read(tenantId: string) {
		const signatureEndpoint = process.env.REACT_APP_USERFLOW_SIGNATURE_ENDPOINT;

		if (!tenantId) {
			// eslint-disable-next-line no-console
			console.error('Lack of tenantId for fetching Userflow signature');

			return;
		}

		if (!signatureEndpoint) {
			// eslint-disable-next-line no-console
			console.error('Lack of endpoint for fetching Userflow signature');

			return;
		}

		this.fetchApiByAxios(signatureEndpoint, {
			method: 'POST',
			data: {
				tenantId,
			},
		});
	}
}
