import { makeAutoObservable } from 'mobx';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AlertsStateActionsDataStore } from './alerts-state-actions.data-store';
import { Investigation, MutationSetAlertsStateArgs, SetAlertsStateAction } from '@/generated/graphql';
import { NotificationsStore } from '@/app/_common/stores';
import { Namespaces } from '@/translations/namespaces';
import i18n from '@/translations/i18n';
import { AlertsDismissListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-dismiss-dialog/alerts-dismiss-list.view-store';
import { InvestigationAlertsDismissListViewStore } from '@/app/investigation-details/_components/investigation-details/investigation-alerts-dismiss-dialog';

interface State {
	investigationId?: Investigation['id'];
	open: boolean;
}

const initialState: State = {
	open: false,
	investigationId: undefined,
};

export class AlertsStateActionsViewStore {
	private alertStateActionDataStore = injectInterface(this, AlertsStateActionsDataStore);
	private alertsDismissListViewStore = injectInterface(this, AlertsDismissListViewStore);
	private investigationAlertsDismissListViewStore = injectInterface(this, InvestigationAlertsDismissListViewStore);
	private notificationsStore = injectInterface(this, NotificationsStore);
	private state: State = initialState;

	constructor() {
		makeAutoObservable(this);
	}

	get loading() {
		return this.alertStateActionDataStore.loading;
	}

	get error() {
		return this.alertStateActionDataStore.error;
	}

	get isOpen() {
		return this.state.open;
	}

	open = (alerts: string[] | string, investigationId?: Investigation['id']) => {
		const alertsArr = Array.isArray(alerts) ? alerts : [alerts];
		if (alertsArr?.length > 0) {
			if (investigationId) {
				this.investigationAlertsDismissListViewStore.setInitialAlertIds(alertsArr);
				this.state.investigationId = investigationId;
			} else {
				this.alertsDismissListViewStore.setInitialAlertIds(alertsArr);
			}
			this.state.open = true;
		}
	};

	close = () => {
		this.state = initialState;
	};

	dismissAlerts = (selectedAlertsId: string[]) => {
		this.updateAlerts(SetAlertsStateAction.Dismiss, selectedAlertsId, this.state.investigationId);
	};

	dismissAlert = (alertsId: string) => {
		this.updateAlerts(SetAlertsStateAction.Dismiss, [alertsId], this.state.investigationId);
	};

	undismissAlerts = (selectedAlertsId: string[]) => {
		this.updateAlerts(SetAlertsStateAction.Undismiss, selectedAlertsId);
	};

	undismissAlert = (alertsId: string) => {
		this.updateAlerts(SetAlertsStateAction.Undismiss, [alertsId]);
	};

	updateAlerts = async (action: SetAlertsStateAction, alertIds: string[], investigationId?: Investigation['id']) => {
		if (!alertIds || this.loading) {
			return;
		}

		const notificationAction = action === SetAlertsStateAction.Undismiss ? 'undismiss' : 'dismiss';

		const args: Omit<MutationSetAlertsStateArgs, 'tenantId'> = {
			action: action,
			alertIds,
		};

		const updateAlert = investigationId
			? this.alertStateActionDataStore.updateInvestigationDetails(args, investigationId)
			: this.alertStateActionDataStore.update(args);

		const response = await updateAlert;

		const responseData = response?.data?.setAlertsState;

		if (responseData?.__typename === 'Alerts') {
			this.notificationsStore.openSuccess({
				title: i18n.t(`alertsStateChange.${notificationAction}.success.title`, { ns: Namespaces.Notifications }),
			});
		} else if (responseData?.__typename === 'Error') {
			this.notificationsStore.openError({
				title: i18n.t(`alertsStateChange.${notificationAction}.error.title`, { ns: Namespaces.Notifications }),
			});
		}
	};
}
