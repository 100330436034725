import { cloneElement, ReactElement, RefObject, useMemo, memo, ReactNode } from 'react';
import { GridRowProps } from '@progress/kendo-react-grid';
import { WidthsState } from '@/app/_common/types';
import { deepEqual } from '@/app/_common/utils';

interface AlertsListRowProps extends GridRowProps {
	isAlertRowSelected: (props: GridRowProps) => boolean;
	trElement: ReactElement<HTMLTableRowElement>;
	selectedRowRef: RefObject<HTMLTableRowElement>;
	selected: boolean;
	showedColumns: string[];
	widths: WidthsState;
	className: string;
	isStatusClose?: boolean;
}

// eslint-disable-next-line react/display-name
export const AlertsListRow = memo(
	(props: AlertsListRowProps) => {
		const { isAlertRowSelected, trElement, selectedRowRef, selected, showedColumns, className, isStatusClose } = props;

		const trProps: { className: string; ref?: RefObject<HTMLTableRowElement> | null } = {
			className,
			ref: isAlertRowSelected(props) ? selectedRowRef : null,
		};

		const currentRow = useMemo(() => {
			return cloneElement(trElement, { ...trProps }, trElement.props.children as ReactNode);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [selected, className, props.dataItem.node, showedColumns, isStatusClose]);

		return currentRow;
	},
	(prev, next) => deepEqual(prev, next, ['selected', 'showedColumns', 'dataItem.node', 'widths', 'className', 'isStatusClose']),
);
