import { memo, ReactElement, useMemo, cloneElement, ReactNode } from 'react';

import { GridRowProps } from '@progress/kendo-react-grid';

import { WidthsState } from '@/app/_common/types';
import { deepEqual } from '@/app/_common/utils';

interface ActionHistoryGridRowProps extends GridRowProps {
	trElement: ReactElement<HTMLTableRowElement>;
	widths: WidthsState;
}

export const ActionHistoryGridRow = memo(
	(props: ActionHistoryGridRowProps) => {
		const { trElement } = props;

		const currentRow = useMemo(() => {
			return cloneElement(trElement, {}, trElement.props.children as ReactNode);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.dataItem, trElement.props.children.length]);

		return currentRow;
	},
	(prev, next) => deepEqual(prev, next, ['dataItem', 'widths']),
);

ActionHistoryGridRow.displayName = 'ActionHistoryGridRow';
