export enum FilterLogic {
	And = 'and',
	Or = 'or',
}

export enum FilterOperators {
	Eq = 'eq',
	Neq = 'neq',
	IsNull = 'isnull',
	IsNotNull = 'isnotnull',
	Lt = 'lt',
	Lte = 'lte',
	Gt = 'gt',
	Gte = 'gte',
	IsIn = 'isin',
	IsNotIn = 'isnotin',
	IsSomeIn = 'issomein',
}

export type ArrayFilterOperators = FilterOperators.IsIn | FilterOperators.IsNotIn;

export enum FilterStringOperators {
	StartsWith = 'startswith',
	EndsWith = 'endswith',
	Contains = 'contains',
	DoesNotContain = 'doesnotcontain',
	IsEmpty = 'isempty',
	IsNotEmpty = 'isnotempty',
}

export enum SortDirection {
	Asc = 'asc',
	Desc = 'desc',
}

export const MIN_COLUMN_WIDTH = 50;
export const LARGE_COLUMN_WIDTH = 100;

export const RootFilterId = 'root';

export enum FilterGroupId {
	header = 'header_filter',
	gridHeader = 'grid_header_filter',
	selected = 'selected_filter',
}
