import { FC } from 'react';

import _get from 'lodash/get';

import { GridCellProps } from '@progress/kendo-react-grid';
import { DataCell } from '@/app/_common/_components/data-grid/_components';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import styles from './expanded-cell.module.scss';
import classNames from 'classnames';

interface ExpandedCellProps extends GridCellProps {
	title?: string;
	tooltip?: string;
	onClick: (dataItem: unknown) => void;
	className?: string;
}

export const ExpandedCell: FC<ExpandedCellProps> = ({ field, dataItem, tooltip, onClick, className, ...rest }) => {
	const value = _get(dataItem, field ?? '');

	const displayExpandIcon = value ? <ExpandLessIcon /> : <ExpandMoreIcon />;
	return (
		<DataCell
			{...rest}
			title={tooltip || undefined}
			className={classNames(styles.expandedCell, className)}
			dataItem={dataItem}
			field={field}
			onClick={() => onClick(dataItem)}
		>
			{displayExpandIcon}
		</DataCell>
	);
};
