import { safeJSONParse } from '@/app/_common/utils';
import { USER_PREFERENCE_PERSISTABLE_STATE_KEY } from '../user-preference.constants';

const SOURCE_KEY = 'ls/advanced-query/available-fields';
const SOURCE_VERSION = 'v2';

/**
 * Function to migrate ls/user-preference store to first (initial) version
 * This migration script is copying the `favoriteFields` property from available-fields.view-store (v2)
 */
export const migrateV0ToV1 = (): void => {
	const source = localStorage.getItem(SOURCE_KEY);
	if (!source) {
		return;
	}
	const json = safeJSONParse(source);
	if (json === null) {
		localStorage.removeItem(SOURCE_KEY);
		return;
	}
	const { favoriteFields, version } = json;
	if (!favoriteFields || version !== SOURCE_VERSION) {
		return;
	}
	localStorage.setItem(
		USER_PREFERENCE_PERSISTABLE_STATE_KEY,
		JSON.stringify({
			version: 'v1',
			favoriteFields,
		}),
	);
};
