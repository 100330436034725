import { FC, useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './show-more.module.scss';

interface ShowMoreProps {
	text: string;
	charactersCount: number;
	isOneLineOnCollapse?: boolean; // renders text as one line in collapsed mode
	onToggle?: (showMore: boolean) => void;
}

export const ShowMore: FC<ShowMoreProps> = ({ text, charactersCount, isOneLineOnCollapse = false, onToggle }) => {
	const { t } = useTranslation();
	const spacerRef = useRef<HTMLSpanElement>(null);

	const [showMore, setShowMore] = useState(false);
	const [isSpacerVisible, setIsSpacerVisible] = useState(false);

	useEffect(() => {
		if (isOneLineOnCollapse) {
			const handleResize = () => {
				checkSpacerVisibility();
			};

			window.addEventListener('resize', handleResize);
			checkSpacerVisibility(); // Initial check
			return () => window.removeEventListener('resize', handleResize);
		}
	}, [isOneLineOnCollapse]);

	const checkSpacerVisibility = () => {
		if (spacerRef?.current?.parentElement) {
			const spacerRect = spacerRef.current.getBoundingClientRect();
			const parentRect = spacerRef.current.parentElement.getBoundingClientRect();

			// Check if the spacer is fully or partially hidden
			const isVisible = spacerRect.left >= parentRect.left && spacerRect.right <= parentRect.right;

			setIsSpacerVisible(isVisible);
		}
	};

	const toggleShowMore = useCallback(() => {
		if (onToggle) {
			onToggle(!showMore);
		} else {
			setShowMore(!showMore);
		}
	}, [showMore, onToggle]);

	if (text.length <= charactersCount) {
		return <p>{text}</p>;
	}

	const showAsOneLineOnCollapse = isOneLineOnCollapse && !showMore;
	const collapsedTextLength = showAsOneLineOnCollapse ? text.length : charactersCount;
	const ellipsis = isOneLineOnCollapse ? null : '...'; // isOneLineOnCollapse adds ellipsis with css
	const isShowMoreBtnVisible = !isOneLineOnCollapse || (isOneLineOnCollapse && !isSpacerVisible);

	return (
		<p className={classNames(styles.container, { [styles.showAsOneLineOnCollapse]: showAsOneLineOnCollapse })}>
			{!showMore ? (
				<>
					<span className={styles.collapsedTextContainer}>
						<span className={styles.collapsedText}>
							{text.slice(0, collapsedTextLength)}
							{ellipsis}
							<span ref={spacerRef}>&nbsp;</span>
						</span>
					</span>
					{isShowMoreBtnVisible && (
						<>
							{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
							<span className={styles.toggleBtn} onClick={toggleShowMore}>
								{t('showMore')}
							</span>
						</>
					)}
				</>
			) : (
				<>
					{text} {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
					<span className={styles.toggleBtn} onClick={toggleShowMore}>
						{t('showLess')}
					</span>
				</>
			)}
		</p>
	);
};
