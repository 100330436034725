export enum ResultsTablePropertiesPaths {
	Timestamp = 'timestamp',
	LogId = 'log_id',
	EvidenceId = 'evidence_id',
	Actions = 'actions',
	Selected = 'selected',
	Expanded = 'expanded',
	Raw = 'raw',
	AlertRaw = 'alert_raw',
	Id = '_id',
	Table = '$table',
	Type = 'type',
	Confidence = 'confidence',
	Severity = 'severity',
	Disabled = 'disabled',
}

export enum AdvancedQueryPageKeyPrefix {
	AdvancedQueryPage = 'tabs.advancedQuery',
}

export const INTERNAL_COLUMN_PREFIX = '$';

export const RESULTS_TABLE_ALERT_ID_DELIMITER = '_alert_id_';
