import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import classNames from 'classnames';

import { Button, ButtonSize } from '@/app/_common/_components/button';
import { Namespaces } from '@/translations/namespaces';

import styles from './details-view-close-button.module.scss';

interface DetailsViewCloseButtonProps {
	handleCloseButtonClick: () => void;
	buttonClassName: string;
}

export const DetailsViewCloseButton = ({ handleCloseButtonClick, buttonClassName }: DetailsViewCloseButtonProps) => {
	const { t } = useTranslation([Namespaces.DetailsView]);
	return (
		<Button size={ButtonSize.Small} fillMode="outline" className={classNames(buttonClassName, styles.closeButton)} onClick={handleCloseButtonClick}>
			<span className={styles.closeButtonText}>{t('close')}</span> <Close />
		</Button>
	);
};
