import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';

import classNames from 'classnames';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Namespaces } from '@/translations/namespaces';
import { AdvancedQueryMainTabKeyPrefix } from '@/app/_common/types';
import { INITIAL_QUERY } from '@/app/_common/constants';

import { AdvancedQueryViewStore, OptionsViewStore } from '@/app/advanced-query/_common/stores';
import { DropdownButton } from '@/app/_common/_components/dropdown-button/dropdown-button';

import styles from './options.module.scss';

interface DropdownItem {
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick: (...args: any[]) => void;
}

interface OptionsProps {
	className?: string;
}

export const Options: FC<OptionsProps> = observer(({ className }) => {
	const queryStore = useInstance(AdvancedQueryViewStore);
	const optionsViewStore = useInstance(OptionsViewStore);

	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryMainTabKeyPrefix.Options });

	const dropdownItems: DropdownItem[] = [
		{
			label: t('listItems.clearFilters'),
			onClick: () => {
				optionsViewStore.clearFilters();
			},
		},
		{
			label: t('listItems.resetQuery'),
			onClick: () => {
				queryStore.setEditorValue(INITIAL_QUERY);
				queryStore.setDraftQuery(INITIAL_QUERY);
			},
		},
		{
			label: optionsViewStore.isAvailableFieldsPanelShown ? t('listItems.availableFields.hide') : t('listItems.availableFields.show'),
			onClick: () => {
				optionsViewStore.toggleIsAvailableFieldsPanelShown();
			},
		},
		{
			label: optionsViewStore.isTipsPanelShown ? t('listItems.tips.hide') : t('listItems.tips.show'),
			onClick: () => {
				optionsViewStore.toggleIsTipsPanelShown();
			},
		},
	];

	return (
		<div className={classNames(styles.options, className)}>
			<DropdownButton
				text={
					<>
						<MoreVertIcon />
						{t('title')}
					</>
				}
				items={dropdownItems}
				itemRender={({ item }: { item: DropdownItem }) => <span>{item.label}</span>}
				onItemClick={(event) => {
					event?.item?.onClick(event.item);
				}}
			/>
		</div>
	);
});
