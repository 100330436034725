import { useMemo } from 'react';
import { useInstance } from 'react-ioc';

import { MenuViewStore } from '@/app/_common/_components/new-context-menu/_common/stores/menu.view-store';

export const useDynamicMenu = () => {
	const store = useInstance(MenuViewStore);

	if (!store) {
		throw new Error(`
			MenuViewStore & MenuDataStore are not provided.
			Component that is using useMenu hook, must be child of ContextMenu or DropdownMenu components.
		`);
	}

	const hookApi = useMemo(
		() => ({
			data: store.data,
			loading: store.loading,
			error: store.error,
			read: store.read,
		}),
		[store.data, store.loading, store.error, store.read],
	);

	return hookApi;
};
