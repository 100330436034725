import { useCallback } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import type { GridCellProps } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-data-query';

import { AlertPropertiesPaths } from '@/app/_common/constants';
import { DashboardAlertDetailsViewStore } from '@/app/_common/_components/details-view/stores';
import { DetectedByCell } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/_components';

const idGetter = getter(AlertPropertiesPaths.DetectedBy);

export const AlertDetectedByCell = observer((props: GridCellProps) => {
	const detailsStore = useInstance(DashboardAlertDetailsViewStore);

	const id = idGetter(props.dataItem);

	const handleCellClick = useCallback(() => {
		detailsStore.setSelectedAlertId(id);
	}, [detailsStore, id]);

	return <DetectedByCell {...props} onClick={handleCellClick} oldContextMenu={false} />;
});

AlertDetectedByCell.displayName = 'AlertDetectedByCell';
