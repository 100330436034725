import { AdxTables } from '@/app/_common/constants';
import { SchemaColumn, SchemaDatabaseTables } from '@/app/_common/types';

export const getDuplicatedTableColumnsDynamicNames = (tables: SchemaDatabaseTables, tableName: AdxTables): string[] => {
	if (!tables?.[tableName]?.OrderedColumns?.length) {
		return [];
	}

	const targetTableColumns = tables[tableName].OrderedColumns ?? [];

	const repeatingColumns: SchemaColumn[] = Array.from(
		new Set(
			targetTableColumns.filter((column) => {
				return Object.entries(tables).some(([name, schema]) => {
					if (name !== tableName) {
						return !!schema.OrderedColumns.find((schemaColumn) => schemaColumn.Name === column.Name && schemaColumn.CslType !== column.CslType);
					}

					return false;
				});
			}),
		),
	);

	return repeatingColumns.map((column) => `${column.Name}_${column.CslType}`);
};
