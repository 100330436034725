import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { User } from '@/generated/graphql';

export const browserHistory = createBrowserHistory({ window });

export const reactPlugin = new ReactPlugin();
let appInsights: ApplicationInsights;

export const createTelemetryService = (upn: User['upn']) => {
	const initialize = (connectionString: string) => {
		appInsights = new ApplicationInsights({
			config: {
				connectionString,
				extensions: [reactPlugin],
				disableFetchTracking: false,
				enableAutoRouteTracking: true,
				enableResponseHeaderTracking: true,
				enableRequestHeaderTracking: true,
				enableCorsCorrelation: true,
				extensionConfig: {
					[reactPlugin.identifier]: { history: browserHistory },
				},
			},
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const telemetryInitializer = (envelope: any) => {
		envelope.tags['ai.cloud.role'] = 'samurai-xdr-fe';
		envelope.tags['ai.cloud.roleInstance'] = 'Samurai';
		envelope.tags['ai.user.id'] = upn;
	};

	const start = () => {
		appInsights.addTelemetryInitializer(telemetryInitializer);
		appInsights.loadAppInsights();
		appInsights.trackPageView();
	};

	return { initialize, start, appInsights };
};

export const getAppInsights = () => appInsights;
