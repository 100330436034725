export enum IntegrationsPropertiesPaths {
	Id = 'node.id',
	CollectorId = 'node.collector.id',
	CollectorType = 'node.collector.type',
	CollectorName = 'node.collector.name',
	Status = 'node.status',
	Vendor = 'node.vendor',
	Product = 'node.productName',
	Name = 'node.name',
	Description = 'node.description',
	SourceType = 'node.sourceType',
	Hostname = 'node.hostname',
	LastUpdated = 'node.last_updated',
	LastEventTimestamp = 'node.lastEventTimestamp',
	CreatedTimestamp = 'node.timestamp',
	StatusReason = 'node.statusReason',
	Type = 'node.type',
	TelemetryIntegrationType = 'node.telemetryIntegrationType', // custom, computed from `type`, `sourceType` and `collectorType`
}

export const IntegrationsListHeadersTranslationKeys = {
	[IntegrationsPropertiesPaths.Vendor]: 'grid.headers.vendor',
	[IntegrationsPropertiesPaths.Product]: 'grid.headers.product',
	[IntegrationsPropertiesPaths.CollectorType]: 'grid.headers.collectorType',
	[IntegrationsPropertiesPaths.Description]: 'grid.headers.description',
	[IntegrationsPropertiesPaths.Name]: 'grid.headers.name',
	[IntegrationsPropertiesPaths.CollectorName]: 'grid.headers.collector',
	[IntegrationsPropertiesPaths.Hostname]: 'grid.headers.hostname',
	[IntegrationsPropertiesPaths.LastEventTimestamp]: 'grid.headers.lastEventSeen',
	[IntegrationsPropertiesPaths.CreatedTimestamp]: 'grid.headers.created',
	[IntegrationsPropertiesPaths.Status]: 'grid.headers.status',
	[IntegrationsPropertiesPaths.TelemetryIntegrationType]: 'grid.headers.telemetryIntegrationType',
};

export enum IntegrationListActions {
	ViewIntegrationConfiguration = 'ViewIntegrationConfiguration',
	DeleteIntegration = 'DeleteIntegration',
}

export const IntegrationsListActionsTranslationKeys = {
	[IntegrationListActions.DeleteIntegration]: 'grid.actions.delete',
	[IntegrationListActions.ViewIntegrationConfiguration]: 'grid.actions.viewConfiguration',
};

export enum IntegrationsStatusTranslationSuffixes {
	HEALTHY = 'healthy',
	NOT_HEALTHY = 'notHealthy',
	NOT_AVAILABLE = 'notAvailable',
	PROVISIONING = 'provisioning',
}

export enum IntegrationsCollectorTypeTranslationSuffixes {
	CLOUD = 'cloud',
	LOCAL = 'local',
	SECURE_SYSLOG = 'secureSyslog',
	UNKNOWN = 'unknown',
}

export const NameValidationRegEx = /^[a-z0-9][a-z0-9_.-]+$/i;

export const LIST_INTEGRATIONS_QUERY_NAME = 'listIntegrations';

export enum IntegrationStaticFields {
	description = 'description',
}

export const REACT_TOOLTIP_IDS = {
	telemetryIcon: 'telemetryIcon',
	responseIcon: 'responseIcon',
};
