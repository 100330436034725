import { FC, PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';

export const Portal: FC<PropsWithChildren> = ({ children }) => {
	const htmlPortalElement = document.getElementById('portal');
	const htmlDivElement = document.createElement('div');

	useEffect(() => {
		if (htmlPortalElement) {
			htmlPortalElement.appendChild(htmlDivElement);

			return () => {
				htmlPortalElement.removeChild(htmlDivElement);
			};
		}
	}, [htmlDivElement, htmlPortalElement]);

	return createPortal(children, htmlDivElement);
};
