import { action, computed, makeObservable } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { createGetInvestigationsByIdsQuery } from '@/app/_common/graphql/queries';

type QueryVariables = {
	tenantId: string;
};

export class InvestigationsDataStore<QUERY_RESULT> extends GraphqlBaseDataStore<QUERY_RESULT, QueryVariables> {
	constructor() {
		super();
		makeObservable(this, {
			investigations: computed,
			read: action,
		});
	}

	get investigations() {
		return this.data || [];
	}

	read(ids?: string[]) {
		if (!ids || ids.length === 0) {
			return;
		}

		const query = createGetInvestigationsByIdsQuery(ids);

		const variables = {
			tenantId: this.authStore.currentTenantId,
		};

		this.query({
			query: query,
			variables,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		});
	}
}
