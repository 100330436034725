import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Hint } from '@progress/kendo-react-labels';

import { TextArea } from '@/app/_common/_components';
import { FormFieldError } from '@/app/_common/_components/form-components/form-field-error';

export const FormTextArea = (fieldRenderProps: FieldRenderProps) => {
	const { validationMessage, touched, id, hint, message, ...others } = fieldRenderProps;

	const showValidationMessage: string | false | null = touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : '';

	return (
		<FieldWrapper>
			<TextArea id={id} {...others} />
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <FormFieldError>{validationMessage}</FormFieldError>}
			{message && <FormFieldError>{message}</FormFieldError>}
		</FieldWrapper>
	);
};
