import { FilterOption } from '@/app/_common/types';

import { OptionItemDetails } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/types';

export const getItemDetails = (item: FilterOption): OptionItemDetails => {
	if (typeof item === 'string') {
		return {
			label: item,
			value: item,
			counter: null,
			bulletsConfig: null,
			icon: null,
		};
	}

	return item;
};
