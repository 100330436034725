import { Children, cloneElement, FC, isValidElement, ReactNode, useCallback, useRef } from 'react';

import { buildURI, normalizeDataForCSVExport } from '@/app/_common/_components/csv-export/utils';
import { CsvExportData, CsvExportHeaders } from '@/app/_common/_components/csv-export/types';

const SEPARATOR = ',';
const ENCLOSING_CHARACTER = '"';

interface CsvExportProps {
	headers: CsvExportHeaders;
	data: CsvExportData;
	fileName: string;
	children: ReactNode;
}

export const CsvExport: FC<CsvExportProps> = ({ children, data, fileName, headers }) => {
	const ref = useRef<HTMLDivElement>(null);

	const handleClick = useCallback(() => {
		const link = document.createElement('a');
		const normalizedData = normalizeDataForCSVExport(data, headers);
		link.href = buildURI(normalizedData, true, headers, SEPARATOR, ENCLOSING_CHARACTER);
		link.download = fileName;
		ref.current?.appendChild(link);
		link.click();
		ref.current?.removeChild(link);
	}, [data, fileName, headers]);

	return (
		<div ref={ref}>
			{Children.map(children, (child) => {
				if (isValidElement(child)) {
					return cloneElement(child, {
						// @ts-ignore
						onClick: handleClick,
					});
				}

				return child;
			})}
		</div>
	);
};
