import { PropsWithChildren, MouseEvent, memo, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { GridCellProps, GridDetailRowProps } from '@progress/kendo-react-grid';

import { ResultsFieldValue } from '@/app/_common/types';
import { QueryResultsTableItemExpandedRow } from '@/app/_common/_components/query-results/query-results-table/components/query-results-table-item-expanded/components';
import { isFieldValueEmpty, isIgnoredColumn } from '@/app/_common/_components/query-results/query-results-table/utils';
import { isInternalColumn } from '@/app/_common/utils/adx';

import styles from './query-results-table-item-expanded.module.scss';

interface ExpandedItemProps {
	onContextMenu?: (event: MouseEvent<HTMLElement>, field: string, dataItem: PropsWithChildren<GridCellProps>) => void;
}

type QueryResultsTableItemExpandedProps = GridDetailRowProps & ExpandedItemProps;

export const QueryResultsTableItemExpanded = memo(({ dataItem, onContextMenu }: QueryResultsTableItemExpandedProps) => {
	const containerId = uuidv4();

	const containerRef = useRef(null);

	useEffect(() => {
		const parentTableRow = document.getElementById(containerId)?.closest(`tr.k-detail-row`)?.previousElementSibling;

		parentTableRow?.scrollIntoView({ block: 'start', behavior: 'smooth' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.container} ref={containerRef} id={containerId}>
			{Object.entries(dataItem)
				.filter(([key, value]) => !(isFieldValueEmpty(value as ResultsFieldValue) || isIgnoredColumn(key) || isInternalColumn(key)))
				.map(([key, value]) => (
					<QueryResultsTableItemExpandedRow
						key={key}
						label={key}
						value={value as ResultsFieldValue}
						dataItem={dataItem}
						onContextMenu={onContextMenu}
					/>
				))}
		</div>
	);
});

QueryResultsTableItemExpanded.displayName = 'QueryResultsTableItemExpanded';
