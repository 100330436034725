import { Evidence } from '@/app/_common/types';

import { AlertEvidence } from '../_components';

interface OktaWorkforceAlertEvidenceProps {
	evidence: Evidence;
}

export const OktaWorkforceAlertEvidence = ({ evidence }: OktaWorkforceAlertEvidenceProps) => {
	return <AlertEvidence evidence={evidence} />;
};
