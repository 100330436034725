import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';

import { Severity } from '@/generated/graphql';
import { sortByOrder } from '@/app/_common/utils';
import { AlertPropertiesPaths, SortDirection } from '@/app/_common/constants';
import { CONFIDENCE_PRIORITY_WITH_UNKNOWN } from '@/app/_features/alerts/_common/constants';

export const sortAlerts = <T>(data: T[], sort: SortDescriptor[]) => {
	const sortOptions = sort[0];

	if (sortOptions?.field === AlertPropertiesPaths.Severity) {
		return sortByOrder<T>(
			data,
			AlertPropertiesPaths.Severity,
			[Severity.Critical, Severity.High, Severity.Medium, Severity.Low, Severity.Unknown],
			sortOptions?.dir as SortDirection,
		);
	} else if (sortOptions?.field === AlertPropertiesPaths.Confidence) {
		return sortByOrder<T>(data, AlertPropertiesPaths.Confidence, CONFIDENCE_PRIORITY_WITH_UNKNOWN, sortOptions?.dir as SortDirection);
	} else if (sortOptions?.field === AlertPropertiesPaths.Source || sortOptions?.field === AlertPropertiesPaths.Destination) {
		return _orderBy(
			data,
			(item) => {
				const value = _get(item, sortOptions?.field);
				if (Array.isArray(value) && value.length === 1) {
					return value[0].toLowerCase();
				}

				// Put "Multiple" cells at the end of the table
				return '';
			},
			sortOptions?.dir,
		);
	}

	return orderBy<T>(data, sort);
};
