import { CSSProperties } from 'react';

import _get from 'lodash/get';
import classNames from 'classnames';

import { GridCellProps } from '@progress/kendo-react-grid';

import { DataCell } from '@/app/_common/_components/data-grid/_components';

import styles from './color-cell.module.scss';

const defaultGetColor = () => '#ffffff';

interface ColorCellProps<T> extends GridCellProps {
	getColor(value?: T): string;
	onClick?: () => void;
	tooltip?: string;
}

export function ColorCell<T>({ field, dataItem, className, tooltip, style, onClick, getColor = defaultGetColor, ...rest }: ColorCellProps<T>) {
	const value = _get(dataItem, field || '');
	const color = getColor(value);

	const styling = { '--color-cell-background': color, ...style } as CSSProperties;

	return (
		<DataCell
			{...rest}
			onClick={onClick}
			style={styling}
			className={classNames(styles.colorCell, className)}
			field={field}
			dataItem={dataItem}
			title={tooltip || ''}
		/>
	);
}
