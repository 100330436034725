import { TimelineItemData } from '../interface/timeline-item-data';

export const setShowDetails = ({
	items,
	additionalSpace,
	itemWidth,
}: {
	items: TimelineItemData[];
	additionalSpace: number;
	itemWidth: number;
}): void => {
	// Find all orders.
	const unsortedOrders = new Set<number>();
	items.forEach((curr) => {
		unsortedOrders.add(curr.order);
	});

	const orders = Array.from(unsortedOrders).sort((a, b) => b - a);
	const previouslySetItems: TimelineItemData[] = [];
	orders.forEach((order) => {
		const itemsToSet = items.filter((item) => item.order === order);
		showDetailsBasingOnDistance({ previouslySetItems, itemsToSet, additionalSpace, itemWidth });
	});
};

const showDetailsBasingOnDistance = ({
	previouslySetItems,
	itemsToSet,
	additionalSpace,
	itemWidth,
}: {
	previouslySetItems: TimelineItemData[];
	itemsToSet: TimelineItemData[];
	additionalSpace: number;
	itemWidth: number;
}): void => {
	itemsToSet.forEach((itemToSet) => {
		// Check if this item's box would cover higher-ordered items, which were already set to show details.
		const isCoveringOther = previouslySetItems.reduce((isCoveringOther, currOldItem) => {
			if (isCoveringOther) {
				return true;
			}

			if (!currOldItem.showDetails) {
				return false;
			}

			if (currOldItem.left <= itemToSet.left) {
				// Check if previous already set item would be covered by this item's box
				return currOldItem.left + itemWidth + additionalSpace > itemToSet.left;
			}
			// Checking if this item's box would cover next item.
			return itemToSet.left + itemWidth + additionalSpace > currOldItem.left;
		}, false);

		itemToSet.showDetails = !isCoveringOther;
		previouslySetItems.push(itemToSet);
	});
};
