import { Editor, Range, Point } from 'slate';

import { EditorType, getMatchNode, CustomEditor } from '@/app/_common/_components/rich-text-editor';
import { TextUnit } from 'slate/dist/interfaces/types';

export const withTable = (editor: CustomEditor) => {
	const { deleteBackward, deleteForward } = editor;

	editor.deleteBackward = (unit: TextUnit) => {
		const { selection } = editor;
		if (selection) {
			const [cell] = getMatchNode(editor, EditorType.TableCell);

			if (cell) {
				const [, cellPath] = cell;
				const start = Editor.start(editor, cellPath);

				if (Point.equals(selection.anchor, start)) {
					return;
				}
			} else {
				const prevNodePath = Editor.before(editor, selection);
				const [tableNode] = getMatchNode(editor, EditorType.TableCell, prevNodePath);

				if (tableNode) {
					return;
				}
			}
		}

		deleteBackward(unit);
	};

	editor.deleteForward = (unit: TextUnit) => {
		const { selection } = editor;

		if (selection && Range.isCollapsed(selection)) {
			const [cell] = getMatchNode(editor, EditorType.TableCell);

			if (cell) {
				const [, cellPath] = cell;
				const end = Editor.end(editor, cellPath);

				if (Point.equals(selection.anchor, end)) {
					return;
				}
			} else {
				const prevNodePath = Editor.after(editor, selection);
				const [tableNode] = getMatchNode(editor, EditorType.TableCell, prevNodePath);

				if (tableNode) {
					return;
				}
			}
		}

		deleteForward(unit);
	};

	return editor;
};
