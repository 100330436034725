import { FC, ReactNode } from 'react';

import { Link, EditorType, TableElement, UnknownObject } from '@/app/_common/_components/rich-text-editor';
import { RichTextEditorTranslations } from '@/app/_common/_components/rich-text-editor/types';

import styles from './element.module.scss';

interface ElementAttributes extends UnknownObject {
	'data-slate-node': 'element';
	'data-slate-inline'?: boolean;
	'data-slate-void'?: boolean;
	dir?: 'rtl';
}

interface ElementProps {
	element: UnknownObject;
	attributes: ElementAttributes;
	translations: RichTextEditorTranslations;
	isReadOnlyEnabled?: boolean;
	children?: ReactNode;
}

export const Element: FC<ElementProps> = ({ attributes, translations, children, isReadOnlyEnabled, element }) => {
	const { type } = element;

	switch (type) {
		case EditorType.Code:
			return (
				<div className={styles.code} {...attributes}>
					{children}
				</div>
			);
		case EditorType.Table:
			return (
				<TableElement
					attributes={attributes}
					element={element}
					isReadOnlyEnabled={isReadOnlyEnabled}
					translations={translations}
					data-testid="rich-text-editor-table-element"
				>
					{children}
				</TableElement>
			);
		case EditorType.TableRow:
			return (
				<tr className={styles.tableRow} {...attributes}>
					{children}
				</tr>
			);
		case EditorType.TableCell:
			return (
				<td className={styles.tableCell} {...attributes}>
					{children}
				</td>
			);
		case EditorType.Link:
			return (
				<Link attributes={attributes} element={element}>
					{children}
				</Link>
			);
		case EditorType.ORDERED_LIST:
			return <ol {...attributes}>{children}</ol>;
		case EditorType.UNORDERED_LIST:
			return <ul {...attributes}>{children}</ul>;
		case EditorType.LIST_ITEM:
			return <li {...attributes}>{children}</li>;
		case EditorType.LIST_ITEM_TEXT:
			return <div {...attributes}>{children}</div>;

		default:
			return <div {...attributes}>{children}</div>;
	}
};
