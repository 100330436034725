import _pickBy from 'lodash/pickBy';

import { Columns } from '@/app/_common/_components/data-grid/types';
import { ResultsDataItem } from '@/app/_common/types';
import { getResultsColumnsForDataGrid } from '../get-results-columns-for-data-grid/get-results-columns-for-data-grid';

export const getColumnsWithoutEmptyValues = (results: ResultsDataItem[], customColumnsToRemove?: string[]): Columns => {
	const resultColumns = results[0] ? Object.keys(results[0]) : [];
	const allColumns = getResultsColumnsForDataGrid(resultColumns);

	if (customColumnsToRemove) {
		return _pickBy(allColumns, (_, key) => !customColumnsToRemove.includes(key)) as unknown as Columns;
	}

	return allColumns;
};
