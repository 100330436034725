import { isSafari, toCSV } from '@/app/_common/_components/csv-export/utils';
import {
	CsvExportData,
	CsvExportDataItemValue,
	CsvExportEnclosingCharacter,
	CsvExportHeaders,
	CsvExportSeparator,
} from '@/app/_common/_components/csv-export/types';

export const buildURI = (
	data: string | CsvExportData | CsvExportDataItemValue[][],
	uFEFF: boolean,
	headers: CsvExportHeaders,
	separator: CsvExportSeparator,
	enclosingCharacter: CsvExportEnclosingCharacter,
) => {
	const csv = toCSV(data, headers, separator, enclosingCharacter);
	const type = isSafari() ? 'application/csv' : 'text/csv';
	const blob = new Blob([uFEFF ? '\uFEFF' : '', csv], { type });
	const dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`;

	const URL = window.URL || window.webkitURL;

	return typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);
};
