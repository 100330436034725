import { AlertState } from '@/generated/graphql';
import { DetailsViewItemData } from '@/app/_common/_components/details-view/types';

export const isAlertAssignable = (alert?: DetailsViewItemData) => {
	if (!alert) {
		return false;
	}

	return alert.state?.alertState !== AlertState.Assigned;
};
