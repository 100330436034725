import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { FormDropdownOption } from '@/app/_common/_components/form-components/form-dropdown';
import { InvestigationPriority, InvestigationStatus } from '@/generated/graphql';
import { ReactComponent as SeverityCriticalIcon } from '@/assets/images/severity-critical.svg';
import { ReactComponent as SeverityHighIcon } from '@/assets/images/severity-high.svg';
import { ReactComponent as SeverityMediumIcon } from '@/assets/images/severity-medium.svg';
import { ReactComponent as SeverityLowIcon } from '@/assets/images/severity-low.svg';

import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

export const statusDropdownOptions: FormDropdownOption[] = [
	{
		label: i18n.t('statusDropdownOptions.open', { ns: Namespaces.InvestigationDetails }),
		value: InvestigationStatus.Open,
		icon: <RemoveCircleOutlineIcon />,
	},
	{
		label: i18n.t('statusDropdownOptions.sleep', { ns: Namespaces.InvestigationDetails }),
		value: InvestigationStatus.Sleep,
		icon: <PauseCircleOutlineIcon />,
	},
	{
		label: i18n.t('statusDropdownOptions.closed', { ns: Namespaces.InvestigationDetails }),
		value: InvestigationStatus.Closed,
		icon: <CheckCircleOutlineIcon />,
	},
];

export const priorityDropdownOptions: FormDropdownOption[] = [
	{ label: InvestigationPriority.Critical, value: InvestigationPriority.Critical, icon: <SeverityCriticalIcon /> },
	{ label: InvestigationPriority.High, value: InvestigationPriority.High, icon: <SeverityHighIcon /> },
	{ label: InvestigationPriority.Medium, value: InvestigationPriority.Medium, icon: <SeverityMediumIcon /> },
	{ label: InvestigationPriority.Low, value: InvestigationPriority.Low, icon: <SeverityLowIcon /> },
];
