import { ReactNode, memo, useCallback } from 'react';

import { AssignToInvestigationTabs } from '@/app/_common/constants';

interface AssignToInvestigationFormTabContentProps {
	id: AssignToInvestigationTabs;
	children: ReactNode;
	className: string;
	onFocus: (id: AssignToInvestigationTabs) => void;
}

export const AssignToInvestigationFormTabContent = memo(({ id, children, className, onFocus }: AssignToInvestigationFormTabContentProps) => {
	const handleFocus = useCallback(() => {
		onFocus(id);
	}, [id, onFocus]);

	return (
		<div onFocus={handleFocus} role="tabpanel" tabIndex={0} className={className}>
			{children}
		</div>
	);
});

AssignToInvestigationFormTabContent.displayName = 'AssignToInvestigationFormTabContent';
