import { useMemo } from 'react';

import _uniqueId from 'lodash/uniqueId';

import { CellProps } from '@progress/kendo-react-data-tools';

import { Tooltip } from '@/app/_common/_components';

import { ActionsItem } from '@/app/_common/_components/data-grid/_components/actions-cell/types';

const OFFSET_TOP = 40;

export const ActionCellItem = ({ item, dataItem }: { item: ActionsItem; dataItem: CellProps['dataItem'] }) => {
	const { renderComponent, label, className } = item;
	const tooltipId = useMemo(() => `action-cell-item-${_uniqueId()}`, []);

	if (renderComponent) {
		return renderComponent({ dataItem });
	}

	return (
		<>
			{item.disabled && item.disabledTooltip && <Tooltip tooltipId={tooltipId} offsetTop={OFFSET_TOP} tooltipContent={item.disabledTooltip} />}
			<span data-tip={true} data-for={tooltipId} className={className}>
				{label}
			</span>
		</>
	);
};
