import { useState, useEffect, useRef } from 'react';

const DEFAULT_INTERVAL_IN_MILLISECONDS = 1000;

export const useIntervalCounter = (endTimestamp: number, interval: number = DEFAULT_INTERVAL_IN_MILLISECONDS) => {
	const nowTimestamp = new Date().getTime();
	const [remainingTime, setRemainingTime] = useState(endTimestamp - nowTimestamp);
	const intervalIdRef = useRef<ReturnType<typeof setInterval>>();

	useEffect(() => {
		if (intervalIdRef.current) {
			clearInterval(interval);
		}

		const intervalId = setInterval(() => {
			setRemainingTime((prev) => prev - interval);
		}, interval);

		intervalIdRef.current = intervalId;

		return () => {
			clearInterval(intervalId);
		};
	}, [interval]);

	useEffect(() => {
		if (remainingTime <= 0 && intervalIdRef.current) {
			clearInterval(intervalIdRef.current);
		}
	}, [remainingTime]);

	useEffect(() => {
		const nowTimestamp = new Date().getTime();
		setRemainingTime(endTimestamp - nowTimestamp);
	}, [endTimestamp]);

	return { remainingTime, intervalId: intervalIdRef.current };
};
