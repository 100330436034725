import { ForwardedRef, ReactElement, cloneElement, forwardRef, MouseEvent } from 'react';

import { buttonize } from '@/app/_common/utils';

type AnchorProps = Partial<ReturnType<typeof buttonize<HTMLDivElement>>> & {
	onContextMenu?: (event: MouseEvent<HTMLDivElement>) => void;
};

interface ContextMenuChild {
	anchorProps: AnchorProps;
	children: ReactElement | null;
	anchorWrapperClassName?: string;
	childAnchor?: boolean;
	active: boolean;
}

export const ContextMenuChild = forwardRef(({ childAnchor, children, anchorWrapperClassName, anchorProps, active }: ContextMenuChild, ref) => {
	if (!children) {
		return null;
	}

	if (!childAnchor) {
		return (
			<div ref={ref as ForwardedRef<HTMLDivElement>} className={anchorWrapperClassName} {...anchorProps}>
				{cloneElement(children, { active })}
			</div>
		);
	}

	return cloneElement(children, { ...anchorProps, active, ref });
});

ContextMenuChild.displayName = 'ContextMenuChild';
