import { provider } from 'react-ioc';
import { AvailableFieldsValuesDataStore, AvailableFieldsValuesViewStore } from '@/app/advanced-query/_common/stores';
import { AvailableFieldsListItemPopup, AvailableFieldsListItemPopupProps } from './available-fields-list-item-popup';
import { observer } from 'mobx-react-lite';

export const AvailableFieldsListItemPopupModule = provider(
	AvailableFieldsValuesDataStore,
	AvailableFieldsValuesViewStore,
)(
	observer((props: AvailableFieldsListItemPopupProps) => {
		return <AvailableFieldsListItemPopup {...props} />;
	}),
);
