import { AssignToInvestigationTabs } from '@/app/_common/constants';

export const handleTabFormErrors = (
	errors: { [key: string]: string },
	tab: AssignToInvestigationTabs,
	activeTab: AssignToInvestigationTabs,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setDisableSubmit: (value: any) => void,
) => {
	const errorValues = Object.values(errors);
	const hasError = errorValues.some((errorValue) => errorValue !== '');
	const isCurrentOpenedTab = tab === activeTab;

	if (hasError && isCurrentOpenedTab) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setDisableSubmit((oldValue: any) => {
			return {
				...oldValue,
				[tab]: true,
			};
		});
	} else {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setDisableSubmit((oldValue: any) => {
			return {
				...oldValue,
				[tab]: false,
			};
		});
	}
};
