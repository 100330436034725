import { STACKED_BARS_KEYS, STACKED_BARS_TOTAL_KEY, BAR_STACK_KEYS } from '@/app/_common/constants';
import { ChartStackBarKeys } from '@/app/_common/types';

export const getChartGraphKeys = (showStackedBars: boolean, useStackedBarsTotalCountKey: boolean): ChartStackBarKeys[] => {
	if (showStackedBars) {
		return STACKED_BARS_KEYS;
	}

	if (useStackedBarsTotalCountKey) {
		return STACKED_BARS_TOTAL_KEY;
	}

	return BAR_STACK_KEYS;
};
