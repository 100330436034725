import { makeAutoObservable, reaction } from 'mobx';
import { clearPersistedStore, makePersistable } from 'mobx-persist-store';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';

import { Page } from '@progress/kendo-react-dropdowns';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import {
	ADX_DEFAULT_PAGE_SIZE,
	DateTimePickerOptionKeys,
	INITIAL_QUERY,
	DEFAULT_RESULTS_LIMIT,
	RELATIVE_DATE_TIME_RANGES_AS_SECONDS,
	KUSTO_QUERY_DATE_TIME_FORMAT,
	AllowedAdxTables,
	ADX_TABLES_ORDER,
	SortDirection,
	URL_STATE_QUERY,
} from '@/app/_common/constants';
import { FieldFilterGroup, TimeRangeFilterOption, TimeRangeFilterOptionValue, TimeRangeHistoryFilterOption } from '@/app/_common/types';

import { AdxSchemaDataStore, AuthStore, RouterStore, UrlStore } from '@/app/_common/stores';
import {
	getDateTimeColumns,
	retrieveQuery,
	prepareQuery,
	containsProperTimeInstruction,
	removeQueryComments,
	prepareTotalCountQuery,
	convertTimeRangeToCustom,
	getTablesFromQuery,
	sortByOrder,
} from '@/app/_common/utils';
import {
	EventsFavoriteFieldsDataStore,
	EventsOtherFieldsDataStore,
	AlertsOtherFieldsDataStore,
	AlertsFavoriteFieldsDataStore,
	EvidenceFavoriteFieldsDataStore,
	EvidenceOtherFieldsDataStore,
	ResultsDataStore,
	TotalCountDataStore,
} from '@/app/advanced-query/_common/stores';
import { RootPaths } from '@/app/_common/navigation';

type ChangeValueMonacoEditorFn = null | ((value: string) => void);

const INITIAL_TIME_RANGE_KEY = DateTimePickerOptionKeys.FIFTEEN_MINUTES;
const ADVANCED_QUERY_PERSISTABLE_KEY = 'ss/advanced-query/query-options';

const INITIAL_STATE: State = {
	draftQuery: '',
	query: '',
	timeRange: {
		key: INITIAL_TIME_RANGE_KEY,
		isTemporal: false,
	},
	previousTimeRange: null,
	persistentTimeRange: null,
	timeRangeHistory: [],
	page: {
		skip: 0,
		take: ADX_DEFAULT_PAGE_SIZE,
	},
	filters: [],
	tables: [],
	activeQueryFilters: {
		timeRange: {
			key: INITIAL_TIME_RANGE_KEY,
			isTemporal: false,
		},
		filters: [],
	},
};

interface ActiveQueryFilters {
	timeRange: TimeRangeFilterOption;
	filters: FieldFilterGroup[];
}

interface State {
	draftQuery?: string;
	query?: string;
	timeRange: TimeRangeFilterOption;
	previousTimeRange: TimeRangeFilterOption | null;
	persistentTimeRange: TimeRangeFilterOption | null; // cache persistent datepicker timeRange
	timeRangeHistory: TimeRangeHistoryFilterOption[];
	page: Page;
	filters: FieldFilterGroup[];
	tables: Array<'alerts' | 'events' | 'evidence'>;
	activeQueryFilters: ActiveQueryFilters;
}

export class AdvancedQueryViewStore {
	private authStore = injectInterface(this, AuthStore);
	private routerStore = injectInterface(this, RouterStore);
	private schemaDataStore = injectInterface(this, AdxSchemaDataStore);
	private eventsFavoriteFieldsDataStore = injectInterface(this, EventsFavoriteFieldsDataStore);
	private eventsOtherFieldsDataStore = injectInterface(this, EventsOtherFieldsDataStore);
	private alertsFavoriteFieldsDataStore = injectInterface(this, AlertsFavoriteFieldsDataStore);
	private alertsOtherFieldsDataStore = injectInterface(this, AlertsOtherFieldsDataStore);
	private evidenceFavoriteFieldsDataStore = injectInterface(this, EvidenceFavoriteFieldsDataStore);
	private evidenceOtherFieldsDataStore = injectInterface(this, EvidenceOtherFieldsDataStore);
	private resultsDataStore = injectInterface(this, ResultsDataStore);
	private totalCountDataStore = injectInterface(this, TotalCountDataStore);
	private urlStore = injectInterface(this, UrlStore);
	private state: State = INITIAL_STATE;
	private changeValueMonacoEditorFn: ChangeValueMonacoEditorFn = null;

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		// This is required to initialize the store with time range from session storage
		// makePersistable is async, but in this case we need to initialize it synchronously
		this.getTimeRangeEarly();

		makePersistable(this.state, {
			name: ADVANCED_QUERY_PERSISTABLE_KEY,
			properties: ['draftQuery', 'timeRange'],
			storage: window.sessionStorage,
		}).then(() => {
			const urlQuery = this.urlStore.getStateVariables(URL_STATE_QUERY);

			if (urlQuery) {
				this.setDraftQuery(urlQuery as string);
				this.setTimeRange(INITIAL_TIME_RANGE_KEY);
				this.runPersistentQuery();
				this.urlStore.clearUrl(URL_STATE_QUERY);
			}

			const isInitialQuery = this.draftQuery === INITIAL_QUERY && this.timeRange?.key === INITIAL_TIME_RANGE_KEY;

			if (!this.draftQuery || isInitialQuery) {
				this.setDraftQuery(INITIAL_QUERY);
				this.state.timeRange = {
					...this.extendTimeRangeValues(this.timeRange),
					isTemporal: false,
				};
				this.runPersistentQuery();
			}
		});
	}

	get draftQuery() {
		return this.state.draftQuery ?? '';
	}

	get query() {
		return this.state.query ?? '';
	}

	get hasSchema() {
		return this.schemaDataStore.schema !== undefined;
	}

	get timeRange(): TimeRangeFilterOption {
		return this.state.timeRange;
	}

	get previousTimeRange(): TimeRangeFilterOption | null {
		return this.state.previousTimeRange;
	}

	get persistentTimeRange(): TimeRangeFilterOption | null {
		return this.state.persistentTimeRange;
	}

	get timeRangeHistory(): TimeRangeHistoryFilterOption[] {
		return this.state.timeRangeHistory;
	}

	get runQueryLoading() {
		return (
			this.resultsDataStore.loading ||
			this.eventsFavoriteFieldsDataStore.loading ||
			this.eventsOtherFieldsDataStore.loading ||
			this.alertsFavoriteFieldsDataStore.loading ||
			this.alertsOtherFieldsDataStore.loading ||
			this.evidenceFavoriteFieldsDataStore.loading ||
			this.evidenceOtherFieldsDataStore.loading
		);
	}

	get loading(): boolean {
		return this.resultsDataStore.loading;
	}

	get filters() {
		return this.state.filters;
	}

	get isTimeSetInQuery(): boolean {
		const draftQuery = removeQueryComments(this.draftQuery);
		const { query: preparedQuery } = retrieveQuery(draftQuery);
		const tableNames = getTablesFromQuery(preparedQuery);
		const dateTimeColumnNames = getDateTimeColumns(tableNames, this.schemaDataStore.schema, this.authStore.currentTenantId);

		return containsProperTimeInstruction(draftQuery, dateTimeColumnNames);
	}

	get tables(): string[] {
		const draftQuery = removeQueryComments(this.draftQuery);
		const { query: preparedQuery } = retrieveQuery(draftQuery);

		const firstQuerySection = preparedQuery.split('|')[0]?.trim() ?? '';
		return getTablesFromQuery(firstQuerySection).filter((table) => AllowedAdxTables.includes(table));
	}

	get isMultipleTablesSelected(): boolean {
		return this.tables.length > 1;
	}

	get isTableSelectorDisabled(): boolean {
		return Boolean(this.draftQuery) && this.tables.length === 0;
	}

	get activeQueryFilters(): ActiveQueryFilters {
		return this.state.activeQueryFilters;
	}

	setActiveQueryFilters = (filters: Partial<ActiveQueryFilters>) => {
		this.state.activeQueryFilters = {
			...this.state.activeQueryFilters,
			...filters,
		};
	};

	setDraftQuery = (value: string) => {
		this.state.draftQuery = value;
	};

	setTableInDraftQuery = (tables: string[]) => {
		const doesDraftQueryHasTables = this.tables.length > 0;
		const querySections = removeQueryComments(this.draftQuery).split('|');
		const sortedTables = sortByOrder(tables, '', ADX_TABLES_ORDER, SortDirection.Desc);
		const tablesQuery = sortedTables.length > 1 ? `union ${sortedTables.join(', ')}` : sortedTables[0] ?? '';

		if (doesDraftQueryHasTables) {
			const [, ...restSections] = querySections;

			const newDraftQuery = [`${tablesQuery} `, ...restSections].filter((section) => Boolean(section.trim())).join('|');
			this.setDraftQuery(newDraftQuery);

			if (this.changeValueMonacoEditorFn) {
				this.changeValueMonacoEditorFn(newDraftQuery);
			}
		} else {
			const newDraftQuery = [`${tablesQuery} `, ...querySections].filter((section) => Boolean(section.trim())).join('|');
			this.setDraftQuery(newDraftQuery);

			if (this.changeValueMonacoEditorFn) {
				this.changeValueMonacoEditorFn(newDraftQuery);
			}
		}
	};

	setChangeValueMonacoEditor = (changeValueFn: ChangeValueMonacoEditorFn) => {
		this.changeValueMonacoEditorFn = changeValueFn;
	};

	setEditorValue = (value: string) => {
		if (typeof this.changeValueMonacoEditorFn === 'function') {
			this.changeValueMonacoEditorFn(value);
		}
	};

	setQuery = (value: string) => {
		this.state.query = value;
	};

	setTimeRange = (key: DateTimePickerOptionKeys, from?: string, to?: string, isTemporal = false) => {
		this.state.timeRange = {
			key,
			value: {
				from,
				to,
			},
			isTemporal,
		};
	};

	private runQuery = () => {
		if (this.hasSchema) {
			this.cancelRequest();

			const resultsQuery = prepareQuery({
				query: this.query,
				schema: this.schemaDataStore.schema,
				database: this.authStore.currentTenantId,
				timeRange: this.timeRange,
				filtersList: this.filters,
				limit: DEFAULT_RESULTS_LIMIT,
				includeTableName: true,
			});

			const totalCountQuery = prepareTotalCountQuery({
				query: this.query,
				schema: this.schemaDataStore.schema,
				database: this.authStore.currentTenantId,
				timeRange: this.timeRange,
				filtersList: this.filters,
			});

			this.totalCountDataStore.fetchTotalCount(totalCountQuery);
			this.resultsDataStore.fetchResults(resultsQuery);

			this.setActiveQueryFilters({
				timeRange: this.timeRange,
				filters: this.filters,
			});
		}
	};

	public runPersistentQuery = () => {
		if (this.persistentTimeRange) {
			const extendedTimeRange = this.extendTimeRangeValues(this.persistentTimeRange);
			this.state.timeRange = { ...extendedTimeRange };
		}
		this.setQuery(this.draftQuery);
		this.runQuery();
		this.state.timeRangeHistory = [];
	};

	public runCustomQuery = (timeRange: TimeRangeFilterOptionValue, key?: DateTimePickerOptionKeys, isTemporal?: boolean) => {
		const { from, to } = timeRange;
		this.setTimeRange(key || DateTimePickerOptionKeys.CUSTOM, from, to, isTemporal ?? true);
		this.runQuery();
	};

	setFilters = (filters: FieldFilterGroup[]) => {
		this.state.filters = filters;
	};

	public addPreviousTimeRangeToHistory = () => {
		if (this.previousTimeRange) {
			this.addTimeRangeToHistory(this.previousTimeRange);
		}
	};

	get runQueryButtonEnabled(): boolean {
		if (!removeQueryComments(this.draftQuery) || !this.hasSchema) {
			return false;
		}

		const { timeRange } = this.state;

		if (timeRange.key === DateTimePickerOptionKeys.CUSTOM) {
			const timeRangeValue = timeRange.value;

			if (!timeRangeValue?.from && !timeRangeValue?.to) {
				return false;
			}
		}

		return true;
	}

	persistentTimeRangeDisposer = reaction(
		() => this.timeRange,
		(timeRange: TimeRangeFilterOption) => {
			if (!timeRange.isTemporal) {
				if (this.state.persistentTimeRange) {
					this.state.previousTimeRange = { ...this.state.persistentTimeRange, key: DateTimePickerOptionKeys.CUSTOM };
				}
				this.state.persistentTimeRange = { ...timeRange };
			}
		},
	);

	private extendTimeRangeValues = (timeRange: TimeRangeFilterOption): TimeRangeFilterOption => {
		if (timeRange.key === DateTimePickerOptionKeys.CUSTOM) {
			return timeRange;
		}

		const toTime = moment.now();
		const fromTime = moment(toTime).subtract(RELATIVE_DATE_TIME_RANGES_AS_SECONDS[timeRange.key], 'second');
		const value: TimeRangeFilterOptionValue = {
			from: moment.utc(fromTime).format(KUSTO_QUERY_DATE_TIME_FORMAT),
			to: moment.utc(toTime).format(KUSTO_QUERY_DATE_TIME_FORMAT),
		};

		return {
			...timeRange,
			value,
		};
	};

	async clearStoredDate() {
		await clearPersistedStore(this.state);
	}

	dispose = () => {
		if (this.routerStore.location.pathname === RootPaths.SIGN_OUT) {
			// eslint-disable-next-line no-console
			this.clearStoredDate().catch(console.error);
		}

		this.cancelRequest();
		this.persistentTimeRangeDisposer();
	};

	public cancelRequest = () => {
		this.resultsDataStore.cancelRequest();
		this.totalCountDataStore.cancelRequest();
		this.eventsFavoriteFieldsDataStore.cancelRequest();
		this.eventsOtherFieldsDataStore.cancelRequest();
		this.alertsFavoriteFieldsDataStore.cancelRequest();
		this.alertsOtherFieldsDataStore.cancelRequest();
		this.evidenceFavoriteFieldsDataStore.cancelRequest();
		this.evidenceOtherFieldsDataStore.cancelRequest();
	};

	private getTimeRangeEarly = () => {
		try {
			const persistedState = window.sessionStorage.getItem(ADVANCED_QUERY_PERSISTABLE_KEY) || '{}';
			const timeRange = JSON.parse(persistedState)?.timeRange;

			if (timeRange) {
				this.state.timeRange = timeRange;
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Could not get time range from session storage', error);
		}
	};

	private addTimeRangeToHistory = (timeRange: TimeRangeFilterOption): void => {
		if (!timeRange.isTemporal) {
			const timeRangeConvertedToCustom = convertTimeRangeToCustom(timeRange);
			const timeRangeHistoryCopy = this.timeRangeHistory.slice();
			const index = timeRangeHistoryCopy.findIndex((item: TimeRangeFilterOption) => {
				return _isEqual(item, timeRangeConvertedToCustom);
			});
			if (index >= 0) {
				timeRangeHistoryCopy.splice(index, 1);
			}
			this.state.timeRangeHistory = [...timeRangeHistoryCopy, timeRangeConvertedToCustom];
		}
	};
}
