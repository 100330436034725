import { ReactNode, memo, useMemo } from 'react';
import { provider, toFactory, useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import {
	AlertsActionsViewStore,
	AlertsDataGridViewStore,
	AssignAlertsDataStore,
	CreateInvestigationAndAssignAlertsDataStore,
	DismissAlertsDataStore,
	UnassignAlertsDataStore,
	UndismissAlertsDataStore,
} from '@/app/_features/alerts-actions/_common';
import { AlertsDismissDialog, AlertsUnassignDialog, AlertsAssignDialog } from '@/app/_features/alerts-actions/_components';
import { AlertActions, MutationsConfig } from '@/app/_features/alerts-actions/_common/types';

interface AlertsActionsModuleProps {
	children: ReactNode;
}

const AlertsActions = observer(({ children }: AlertsActionsModuleProps) => {
	const { action } = useInstance(AlertsActionsViewStore);
	const Dialog = useMemo(() => {
		if (!action) {
			return null;
		}

		return {
			[AlertActions.Unassign]: AlertsUnassignDialog,
			[AlertActions.Dismiss]: AlertsDismissDialog,
			[AlertActions.Assign]: AlertsAssignDialog,
			[AlertActions.Undismiss]: null,
		}[action];
	}, [action]);

	return (
		<>
			{children}
			{Dialog && <Dialog />}
		</>
	);
});

const AlertsActionsModule = memo(({ mutationConfig, children }: { mutationConfig: MutationsConfig; children: ReactNode }) => {
	const storeProvider = useMemo(
		() =>
			provider(
				[AlertsActionsViewStore, toFactory(() => new AlertsActionsViewStore(mutationConfig))],
				[AlertsDataGridViewStore, toFactory(() => new AlertsDataGridViewStore())],
				[AssignAlertsDataStore, toFactory(() => new AssignAlertsDataStore())],
				[CreateInvestigationAndAssignAlertsDataStore, toFactory(() => new CreateInvestigationAndAssignAlertsDataStore())],
				[UnassignAlertsDataStore, toFactory(() => new UnassignAlertsDataStore())],
				[DismissAlertsDataStore, toFactory(() => new DismissAlertsDataStore())],
				[UndismissAlertsDataStore, toFactory(() => new UndismissAlertsDataStore())],
			),
		[mutationConfig],
	);

	const Component = storeProvider(observer(() => <AlertsActions>{children}</AlertsActions>));
	return <Component />;
});

AlertsActionsModule.displayName = 'AlertsActionsModule';

export default AlertsActionsModule;
