import { ReactNode, useState } from 'react';
import classnames from 'classnames';

import { ExpansionPanel, ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Reveal } from '@progress/kendo-react-animation';

import styles from './expansion-panel-wrapper.module.scss';

interface ExpansionPanelWrapperProps {
	children: ReactNode;
	title: string;
	expanded?: boolean;
	fullWidth?: boolean;
	className?: string;
}

export const ExpansionPanelWrapper = ({ children, title, expanded = false, fullWidth = false, className }: ExpansionPanelWrapperProps) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

	return (
		<ExpansionPanel
			title={title}
			expanded={isExpanded}
			onAction={(event) => {
				setIsExpanded(!event.expanded);
			}}
			className={classnames(styles.container, { [styles.fullWidth]: fullWidth }, className)}
		>
			<Reveal>{isExpanded && <ExpansionPanelContent>{children}</ExpansionPanelContent>}</Reveal>
		</ExpansionPanel>
	);
};
