import { observer } from 'mobx-react-lite';
import { provider } from 'react-ioc';

import { MenuDataStore } from '@/app/_common/_components/new-context-menu/_common/stores/menu.data-store';
import { MenuViewStore } from '@/app/_common/_components/new-context-menu/_common/stores/menu.view-store';
import { ContextMenuComponent, ContextMenuProps } from '@/app/_common/_components/new-context-menu/context-menu';

export interface ContextMenuData<T> {
	data: T;
	loading: boolean;
	close: (event?: MouseEvent) => void;
}

const ContextMenuModule = provider(
	MenuDataStore,
	MenuViewStore,
)(
	observer((props: ContextMenuProps) => {
		return <ContextMenuComponent {...props} />;
	}),
);

ContextMenuModule.displayName = 'ContextMenuModule';

export const ContextMenu = ContextMenuModule;
