import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Namespaces } from '@/translations/namespaces';
import { AdvancedQueryPageKeyPrefix } from '@/app/_common/constants';
import { ChartDataItem, ChartStackBarKeys, StackedChartDataItem } from '@/app/_common/types';
import {
	formatXAxisDate,
	getBarDataCounts,
} from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/chart/utils';
import { isStackedChartDataItem } from '@/app/advanced-query/_common/types';

import styles from './chart-tooltip.module.scss';

interface ChartTooltipProps {
	data: ChartDataItem | StackedChartDataItem;
	toDate: string;
	isArrowToRight: boolean;
	isStackedBarChart: boolean;
}

export const ChartTooltip: FC<ChartTooltipProps> = ({ data, toDate, isArrowToRight, isStackedBarChart }) => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });
	const formattedFromDate = formatXAxisDate(data[ChartStackBarKeys.Date]);
	const formattedToDate = formatXAxisDate(toDate);

	const isStackedBarChartItem = isStackedChartDataItem(data);
	const { alertsCount, eventsCount, totalCount, evidenceCount } = getBarDataCounts(data);

	return (
		<>
			<div className={styles.content} id="ChartTooltip">
				<div className={styles.dateRange}>
					<div>{t('chart.tooltip.from')}: </div>
					<div>{formattedFromDate}</div>
					<div>{t('chart.tooltip.to')}: </div>
					<div>{formattedToDate}</div>
				</div>

				{isStackedBarChartItem && Boolean(totalCount) && (
					<div>
						{totalCount?.toLocaleString()} {t('chart.tooltip.results')}
					</div>
				)}

				{isStackedBarChart && Boolean(alertsCount) && (
					<div>
						{alertsCount?.toLocaleString()} {t('chart.tooltip.alertsResults')}
					</div>
				)}

				{isStackedBarChart && Boolean(evidenceCount) && (
					<div>
						{evidenceCount?.toLocaleString()} {t('chart.tooltip.evidenceResults')}
					</div>
				)}

				{isStackedBarChart && Boolean(eventsCount) && (
					<div>
						{eventsCount?.toLocaleString()} {t('chart.tooltip.eventsResults')}
					</div>
				)}

				{!isStackedBarChart && !isStackedBarChartItem && Boolean(eventsCount) && (
					<div>
						{eventsCount?.toLocaleString()} {t('chart.tooltip.results')}
					</div>
				)}
			</div>
			<div className={classnames(styles.arrow, { isArrowToRight })} />
		</>
	);
};
