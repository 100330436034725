import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { MonacoInlineCodeTheme, determineClassNameUponTheme } from './_common';

import styles from './monaco-inline-code.module.scss';

interface MonacoInlineCodeProps {
	theme: MonacoInlineCodeTheme;
	children?: ReactNode;
}

export const MonacoInlineCode: FC<MonacoInlineCodeProps> = ({ theme, children }) => {
	const themeClassName = determineClassNameUponTheme(theme);

	return <div className={classNames(styles.monacoInlineCode, themeClassName)}>{children}</div>;
};
