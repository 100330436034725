import { FC } from 'react';
import classNames from 'classnames';

import { WizardConfiguration } from '@/app/_common/_components/wizard/_common';
import { WizardButtons, WizardStepper, WizardComponents } from './_components';
import { Divider } from '@/app/_common/_components/divider';

import styles from './wizard.module.scss';

interface WizardProps extends WizardConfiguration {
	className?: string;
}

export const Wizard: FC<WizardProps> = ({ className, currentStepNumber, stepperItems, currentComponent, currentButtons, showSteps = true }) => {
	return (
		<div className={classNames(styles.wizard, className)} data-testid="create-integration-wizard">
			{showSteps ? <WizardStepper className={styles.stepper} currentStepNumber={currentStepNumber} items={stepperItems} /> : null}
			<Divider className={styles.divider} />
			<WizardComponents currentStepNumber={currentStepNumber} currentComponent={currentComponent} />
			<Divider className={styles.divider} />
			<WizardButtons {...currentButtons} />
		</div>
	);
};
