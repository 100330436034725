import { isEmpty, isNil } from 'lodash';
import _get from 'lodash/get';
import _lowerFirst from 'lodash/lowerFirst';
import _set from 'lodash/set';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import {
	ApolloQueryResult,
	MutationOptions,
	ObservableQuery,
	WatchQueryOptions,
	FetchMoreQueryOptions,
	FetchMoreOptions,
	SubscribeToMoreOptions,
	OperationVariables,
	ApolloError,
} from '@apollo/client';

import { getMutationName, getQueryName, InsightsTraceBuilder } from '@/app/_common/utils';
import { ResponseStatus } from '@/app/_common/interfaces';
import { AuthStore, RouterStore } from '@/app/_common/stores';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { GraphqlClient } from '@/app/_common/graphql/graphql-client';
import { PageInfo } from '@/generated/graphql';
import { Subscription } from 'zen-observable-ts';

const INITIAL_RESULT = {
	loading: false,
	error: undefined,
	data: undefined,
};

export class GraphqlBaseDataStore<QUERY_RESULT, QUERY_VARIABLES = OperationVariables> {
	private readonly client!: GraphqlClient;
	private routerStore = injectInterface(this, RouterStore);
	protected authStore = injectInterface(this, AuthStore);

	private queryWatcher?: ObservableQuery<QUERY_RESULT, QUERY_VARIABLES>;
	private subscription?: Subscription;

	private retries = 0;
	private closed = false;

	private result: Result<QUERY_RESULT> = INITIAL_RESULT;

	private requestName = '';

	constructor() {
		// Do not show client in Redux DevTools
		Object.defineProperty(this, 'client', {
			value: injectInterface(this, GraphqlClient),
			enumerable: false,
		});

		makeObservable(
			this,
			{
				loading: computed,
				error: computed,
				data: computed,
				// @ts-ignore - for protected/private fields
				result: observable,
				// @ts-ignore - for protected/private fields
				closed: observable,
				close: action,
				query: action,
				mutate: action,
				queryAll: action,
				onSuccess: action,
				onRecursiveSucceed: action,
				onRecursiveFailure: action,
				onFailure: action,
				clearResult: action,
			},
			{ autoBind: true },
		);
	}

	get loading(): boolean {
		return this.result.loading;
	}

	get error() {
		return this.result.error;
	}

	get data() {
		return this.result.data;
	}

	protected query(queryOptions: WatchQueryOptions<QUERY_VARIABLES>, backgroundQuery?: boolean) {
		this.subscription?.unsubscribe();

		this.requestName = getQueryName(queryOptions);

		// Do not show queryWatcher in Redux DevTools
		Object.defineProperty(this, 'queryWatcher', {
			value: this.client.watchQuery(queryOptions),
			enumerable: false,
		});
		assertValue(this.queryWatcher);

		const currentResult = observable(this.queryWatcher.getCurrentResult());

		this.result.loading = currentResult.loading;
		this.result.error = currentResult.error;

		if (!(backgroundQuery && isEmpty(currentResult.data))) {
			this.result.data = isEmpty(currentResult.data) ? undefined : (currentResult.data as QUERY_RESULT);
		}

		// Do not show subscription in Redux DevTools
		Object.defineProperty(this, 'subscription', {
			value: this.queryWatcher.subscribe({
				next: (value) => this.onSuccess(value.data, value.loading, backgroundQuery),
				error: (error) => this.onFailure(error),
			}),
			enumerable: false,
		});
	}

	protected queryAll(queryOptions: WatchQueryOptions<QUERY_VARIABLES>, pageInfoPath: string, afterPath: string) {
		this.subscription?.unsubscribe();
		this.result.data = undefined;

		this.requestName = getQueryName(queryOptions);

		// Do not show queryWatcher in Redux DevTools
		Object.defineProperty(this, 'queryWatcher', {
			value: this.client.watchQuery(queryOptions),
			enumerable: false,
		});

		assertValue(this.queryWatcher);

		this.result.loading = true;

		// Do not show subscription in Redux DevTools
		Object.defineProperty(this, 'subscription', {
			value: this.queryWatcher.subscribe({
				next: (value) => this.onRecursiveSucceed(value.data, queryOptions, pageInfoPath, afterPath),
				error: (error) => this.onRecursiveFailure(error),
			}),
			enumerable: false,
		});
	}

	protected queryInOrder(
		queriesOptions: (WatchQueryOptions<QUERY_VARIABLES> | ((data?: QUERY_RESULT) => WatchQueryOptions<QUERY_VARIABLES> | undefined))[],
		index = 0,
		data?: QUERY_RESULT,
	) {
		this.subscription?.unsubscribe();

		const queryOptions = queriesOptions[index];
		const options = typeof queryOptions === 'function' ? queryOptions(data) : queryOptions;

		if (!options) {
			this.result.data = data;
			this.result.loading = false;
			this.result.error = undefined;

			return;
		}

		this.requestName = getQueryName(options);

		// Do not show queryWatcher in Redux DevTools
		Object.defineProperty(this, 'queryWatcher', {
			value: this.client.watchQuery(options),
			enumerable: false,
		});
		assertValue(this.queryWatcher);

		const currentResult = observable(this.queryWatcher.getCurrentResult());

		this.result.loading = currentResult.loading;
		this.result.error = currentResult.error;

		// Do not show subscription in Redux DevTools
		Object.defineProperty(this, 'subscription', {
			value: this.queryWatcher.subscribe({
				next: (value) => {
					if (value.loading) {
						return;
					}

					const allData = {
						...(data ?? {}),
						...value.data,
					};

					if (index === queriesOptions.length - 1) {
						return this.onSuccess(allData, value.loading);
					}

					index++;

					this.queryInOrder(queriesOptions, index, allData);
				},
				error: (error) => this.onFailure(error),
			}),
			enumerable: false,
		});
	}

	protected fetchMore(
		queryOptions: FetchMoreQueryOptions<QUERY_VARIABLES, QUERY_RESULT> & FetchMoreOptions<QUERY_RESULT, QUERY_VARIABLES>,
	): Promise<ApolloQueryResult<QUERY_RESULT>> | undefined {
		return this.queryWatcher?.fetchMore(queryOptions);
	}

	protected subscribeToMore(subscriptionOptions: SubscribeToMoreOptions) {
		assertValue(this.queryWatcher);

		this.queryWatcher.subscribeToMore(subscriptionOptions);
	}

	protected async mutate<MUTATION_RESULT, MUTATION_VARIABLES>(mutationOptions: MutationOptions<MUTATION_RESULT, MUTATION_VARIABLES>) {
		let response = null;

		this.result.loading = true;

		this.requestName = getMutationName(mutationOptions);

		try {
			response = await this.client.mutate(mutationOptions);

			const responseData = _get(response.data, _lowerFirst(this.requestName));

			if (responseData?.__typename === 'Error') {
				this.handleCreationOfInsightsTrace(responseData);
			}
		} catch (error) {
			runInAction(() => {
				this.result.error = error as ApolloError;
				this.result.data = undefined;
			});
		} finally {
			runInAction(() => {
				this.result.loading = false;
			});
		}

		return response;
	}

	clearResult = () => {
		this.result = INITIAL_RESULT;
	};

	private async onRecursiveSucceed(
		data: QUERY_RESULT,
		initialOptions: WatchQueryOptions<QUERY_VARIABLES>,
		pageInfoPath: string,
		afterPath: string,
	): Promise<void> {
		if (this.closed) {
			return;
		}

		this.retries = 0;

		const pageInfo: PageInfo | undefined = _get(data, pageInfoPath);

		if (pageInfo?.hasNextPage) {
			this.result.loading = true;

			const options: FetchMoreQueryOptions<QUERY_VARIABLES, QUERY_RESULT> & FetchMoreOptions<QUERY_RESULT, QUERY_VARIABLES> = initialOptions ?? {};

			_set(options, afterPath, pageInfo.endCursor);

			try {
				await this.queryWatcher?.fetchMore(options);
			} catch (error) {
				await this.onRecursiveFailure(error as ApolloError);
			}
		} else if (!pageInfo?.hasNextPage) {
			this.result.loading = false;
		}

		this.result.error = undefined;
		this.result.data = data;
	}

	private async onRecursiveFailure(error: ApolloError, maxRetries = 1): Promise<void> {
		if (this.closed) {
			return;
		}

		if (this.retries < maxRetries) {
			this.retries++;

			try {
				await this.queryWatcher?.fetchMore(this.queryWatcher?.options);
			} catch (error) {
				this.handleCreationOfInsightsTrace(error as ApolloError);
				await this.onRecursiveFailure(error as ApolloError);
			}
		} else {
			this.result.error = error;
			this.result.loading = false;
			this.result.data = undefined;
			this.retries = 0;
		}
	}

	private onSuccess(data: QUERY_RESULT, loading: boolean, backgroundQuery?: boolean) {
		this.result.error = undefined;
		this.result.loading = loading;

		if (backgroundQuery && !data) {
			return;
		}

		const responseData = _get(data, _lowerFirst(this.requestName));

		if (responseData?.__typename === 'Error') {
			this.handleCreationOfInsightsTrace(responseData);
			this.result.error = responseData;
		}

		this.result.data = data;
	}

	private onFailure(error: Result<QUERY_RESULT>['error']) {
		this.handleCreationOfInsightsTrace(error);

		this.result.error = error;
		this.result.loading = false;
		this.result.data = undefined;
	}

	private close() {
		this.closed = true;
	}

	private handleCreationOfInsightsTrace(error: Result<QUERY_RESULT>['error']) {
		const isAuthenticationError =
			error?.networkError && 'statusCode' in error.networkError && error.networkError.statusCode === ResponseStatus.Unauthorized;

		if (isAuthenticationError) {
			InsightsTraceBuilder.createTrackTraceWarning(this.requestName, error, this.authStore.userEmail);
		} else {
			InsightsTraceBuilder.createTrackTraceError(this.requestName, error, this.authStore.userEmail);
		}
	}

	routeDisposer = reaction(
		() => this.routerStore.location?.pathname,
		() => {
			this.close();
		},
	);

	dispose() {
		this.routeDisposer();
		this.subscription?.unsubscribe();
	}
}

interface Result<QUERY_RESULT> {
	loading: boolean;
	error?: ApolloQueryResult<QUERY_RESULT>['error'];
	data?: QUERY_RESULT;
}

function assertValue(value: unknown): asserts value {
	if (isNil(value)) throw new Error('Value is invalid');
}
