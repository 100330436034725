import { ReactElement, cloneElement } from 'react';
import { ListItemProps } from '@progress/kendo-react-dropdowns';
import { CUSTOM_OPTION_KEY } from '@/app/_common/constants';

export const datePickerItemRender = (li: ReactElement<HTMLElement>, itemProps: ListItemProps, toggleDatePicker: () => void) => {
	const itemChildren = <span>{itemProps.dataItem.label}</span>;

	if (itemProps.dataItem?.key === CUSTOM_OPTION_KEY) {
		const customLiProps = {
			...li.props,
			onClick: (event: React.MouseEvent<HTMLLIElement>) => {
				//@ts-ignore
				li.props['onClick'](event);
				toggleDatePicker();
			},
		};
		return cloneElement(li, { ...customLiProps }, itemChildren);
	}
	return cloneElement(li, { ...li.props }, itemChildren);
};
