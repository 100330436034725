import { FilterOption } from '@/app/_common/types';

export const getNullishFilterOption = (translatedLabel: string, value: string | null = null, counter?: number): FilterOption => {
	return {
		value: value === 'empty' ? null : value,
		label: translatedLabel,
		special: true,
		counter,
	};
};
