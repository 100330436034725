import { action, makeObservable } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { FiltersInput } from '@/generated/graphql';

import { DocumentNode } from '@apollo/client';

type QueryVariables = {
	tenantId: string;
	filtersInput?: FiltersInput;
};

export class ContextMenuDataStore<QUERY_RESULT> extends GraphqlBaseDataStore<QUERY_RESULT, QueryVariables> {
	constructor() {
		super();
		makeObservable(this, {
			read: action,
		});
	}

	read(query: DocumentNode, filtersInput: FiltersInput | undefined) {
		const variables = {
			tenantId: this.authStore.currentTenantId,
			filtersInput,
		};

		this.query({
			query,
			variables,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		});
	}
}
