import { useMemo } from 'react';

import { Alert, Investigation } from '@/generated/graphql';
import { ALERT_ACTIONS, AlertActionProps } from '@/app/_common/types';
import {
	isAlertAssignable,
	isAlertDismissable,
	isAlertInvestigationClosed,
	isAlertUnassignable,
	isAlertUndismissable,
} from '@/app/dashboards/alerts-dashboard/_common/utils';
import { OpenInvestigationAction } from '@/app/_common/_components/data-grid/_components/alerts-widget-context-menu/_components';
import { ActionLabel } from '@/app/_features/alerts-actions-menu/_components';

interface SingleActionsConfig {
	selectedAlerts: Alert[];
	focusedAlertNode?: Alert;
	onViewDetails: () => void;
	onAssignOrCreateInvestigationClick: () => void;
	onUnassignClick: (alerts: Alert[]) => void;
	onDismissClick: () => void;
	onUndismissClick: () => void;
}
export const useAlertsSingleActions = ({
	selectedAlerts,
	focusedAlertNode,
	onViewDetails,
	onAssignOrCreateInvestigationClick,
	onUnassignClick,
	onDismissClick,
	onUndismissClick,
}: SingleActionsConfig): AlertActionProps[] => {
	return useMemo<AlertActionProps[]>(() => {
		if (selectedAlerts.length > 1) {
			return [];
		}

		const investigationId = focusedAlertNode?.investigationSummary?.id ?? '';

		return [
			{
				id: ALERT_ACTIONS.ViewDetails,
				show: Boolean(focusedAlertNode?.id),
				label: () => <ActionLabel action={ALERT_ACTIONS.ViewDetails} />,
				onClick: onViewDetails,
			},
			{
				id: ALERT_ACTIONS.OpenInvestigation,
				show: (investigations: Investigation[]) => investigations?.find((investigation) => investigation.id === investigationId) !== undefined,
				label: () => <OpenInvestigationAction investigationId={investigationId} />,
			},
			{
				id: ALERT_ACTIONS.AssignOrCreateInvestigation,
				show: isAlertAssignable(focusedAlertNode),
				label: () => <ActionLabel action={ALERT_ACTIONS.AssignOrCreateInvestigation} />,
				onClick: onAssignOrCreateInvestigationClick,
			},
			{
				id: ALERT_ACTIONS.Unassign,
				show: (investigations: Investigation[]) =>
					!isAlertInvestigationClosed(focusedAlertNode, investigations) && isAlertUnassignable(focusedAlertNode, investigations),
				label: () => <ActionLabel action={ALERT_ACTIONS.Unassign} />,
				onClick: onUnassignClick,
			},
			{
				id: ALERT_ACTIONS.Dismiss,
				show: (investigations: Investigation[]) =>
					!isAlertInvestigationClosed(focusedAlertNode, investigations) && isAlertDismissable(focusedAlertNode, investigations),
				label: () => <ActionLabel action={ALERT_ACTIONS.Dismiss} />,
				onClick: onDismissClick,
			},
			{
				id: ALERT_ACTIONS.Undismiss,
				show: isAlertUndismissable(focusedAlertNode),
				label: () => <ActionLabel action={ALERT_ACTIONS.Undismiss} />,
				onClick: onUndismissClick,
			},
		];
	}, [focusedAlertNode, selectedAlerts, onAssignOrCreateInvestigationClick, onUnassignClick, onDismissClick, onUndismissClick, onViewDetails]);
};
