import { action, computed, makeObservable, observable, reaction } from 'mobx';
import _isNil from 'lodash/isNil';

import { FilterOption, Filters, FilterValue } from '@/app/_common/types';
import { AlertPropertiesPaths, FilterGroupId, FilterLogic, SortDirection, FilterOperators } from '@/app/_common/constants';

import { AlertsDataStore } from '@/app/dashboards/alerts-dashboard/_common/stores/alerts.data-store';
import { getNullishFilterOption, sortAlerts } from '@/app/_common/utils';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AlertEdge } from '@/generated/graphql';
import { AlertsUtilsViewStore } from '@/app/_common/stores';
import { mapFilterOption, getSeverityOption, getConfidenceOption, getStateOption } from '@/app/_common/_components/data-grid/utils';

const INITIAL_COLUMNS = {
	[AlertPropertiesPaths.Selected]: true,
	[AlertPropertiesPaths.Timestamp]: true,
	[AlertPropertiesPaths.Severity]: true,
	[AlertPropertiesPaths.Confidence]: true,
	[AlertPropertiesPaths.Title]: true,
	[AlertPropertiesPaths.Source]: true,
	[AlertPropertiesPaths.Destination]: true,
	[AlertPropertiesPaths.Mitre]: true,
	[AlertPropertiesPaths.DetectedBy]: true,
};

const INITIAL_SORT = [{ field: AlertPropertiesPaths.Severity, dir: SortDirection.Desc }];

const EMPTY_FIELD_VALUE = 'empty';

export class AlertsAssignListViewStore extends AlertsUtilsViewStore<AlertEdge> {
	dataStore = injectInterface(this, AlertsDataStore);

	private initialIds: string[] = [];

	constructor() {
		super(AlertPropertiesPaths.Id, INITIAL_COLUMNS, INITIAL_SORT, sortAlerts);
		makeObservable(this, {
			// @ts-ignore - for protected/private fields
			initialIds: observable,
			alertIds: computed,
			setInitialAlertIds: action,
			setSourceData: action,
		});
	}

	get alertIds() {
		const data = this.data;

		return data.reduce<string[]>((result: string[], alert) => {
			const alertNodeId = alert.node.id;

			if (alert.node.investigationSummary?.id || !this.isRowSelected(alertNodeId)) {
				return result;
			}

			return [...result, alertNodeId];
		}, []);
	}

	getFilterOptions = (field: string, isNull?: boolean, emptyFieldName?: string) => {
		const counters = this.getCountedValues(field);
		let options: FilterOption[] = [];

		options = this.getUniqValues(field).map((value) => {
			return mapFilterOption(
				field,
				{ value: value as FilterValue, counter: counters[value as string] },
				{
					[AlertPropertiesPaths.Severity]: getSeverityOption,
					[AlertPropertiesPaths.Confidence]: getConfidenceOption,
					[AlertPropertiesPaths.State]: getStateOption,
				},
			);
		});

		if (isNull && emptyFieldName) {
			options.unshift(getNullishFilterOption(emptyFieldName, EMPTY_FIELD_VALUE, counters[EMPTY_FIELD_VALUE]));
		}

		return options;
	};

	setGridHeaderFilter = (field: string, values: Filters) => {
		const filters = values.map((value) => {
			// eslint-disable-next-line @typescript-eslint/ban-types
			let operator: string | Function = 'eq';

			if (field === AlertPropertiesPaths.Source || field === AlertPropertiesPaths.Destination) {
				operator = FilterOperators.IsSomeIn;
			}

			return { value, field, operator, ignoreCase: false };
		});

		this.setFilter({
			id: FilterGroupId.gridHeader,
			filters,
			logic: FilterLogic.And,
			nestedId: field,
			nestedLogic: FilterLogic.Or,
		});
	};

	isAlertAssigned = (alert: AlertEdge) => {
		if (_isNil(alert.node.investigationSummary?.id)) {
			return false;
		} else if (typeof alert.node.investigationSummary?.id === 'string') {
			return true;
		}
		return !!alert.node.investigationSummary?.id;
	};

	setInitialAlertIds = (ids: string[]) => {
		this.initialIds = ids;
	};

	alertDataDisposer = reaction(
		() => this.dataStore.alerts,
		() => {
			this.setSourceData();
		},
	);

	initialIdsDisposer = reaction(
		() => this.initialIds,
		() => {
			this.setSourceData();
		},
	);

	dispose = () => {
		this.alertDataDisposer();
		this.initialIdsDisposer();
	};

	setSourceData = () => {
		const data = this.dataStore.alerts.filter(({ node }) => this.initialIds.includes(node.id));

		this.sourceData = data;

		const selectedIds: Record<string, boolean> = {};
		const disabledIds: Record<string, boolean> = {};

		data.forEach((alert) => {
			const isAssigned = this.isAlertAssigned(alert);
			selectedIds[alert.node.id] = !isAssigned;
			disabledIds[alert.node.id] = isAssigned;
		});

		this.selectRows(selectedIds);
		this.disableRows(disabledIds);
	};
}
