import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';
import { AdvancedQueryPageKeyPrefix } from '@/app/_common/constants';
import { ExternalLink } from '@/app/_common/_components';

import styles from './query-error-instructions.module.scss';

export const QueryErrorInstructions: FC = () => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });

	return (
		<div className={styles.container}>
			<div>{t('error.instructions.summary')}</div>
			<ul className={styles.list}>
				<li>
					{t('error.instructions.correctQuery.prefix')}
					<ExternalLink
						className={styles.link}
						to="https://learn.microsoft.com/en-us/azure/data-explorer/kusto/query/"
						text={t('error.instructions.correctQuery.link')}
						isButton={false}
						hasIcon={false}
					/>
					{t('error.instructions.correctQuery.postfix')}
				</li>
				<li>
					{t('error.instructions.limitSize.prefix')}
					<ExternalLink
						className={styles.link}
						to="https://learn.microsoft.com/en-us/azure/data-explorer/kusto/query/limitoperator"
						text={t('error.instructions.limitSize.link')}
						isButton={false}
						hasIcon={false}
					/>
					{t('error.instructions.limitSize.postfix')}
				</li>
				<li>{t('error.instructions.selectValues')}</li>
				<li>{t('error.instructions.selectTimePeriod')}</li>
			</ul>
		</div>
	);
};
