import { action, computed, makeObservable, reaction } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { GetAllInvestigations } from '@/app/_common/graphql/queries';

import { AlertsTimeRangeFilterStore } from '@/app/dashboards/alerts-dashboard/_common/stores';
import { QueryListInvestigationsArgs, Query, FiltersInput, TimestampFilterField } from '@/generated/graphql';
import { injectInterface } from '@/app/_common/ioc/inject-interface';

export type ListInvestigations = Pick<Query, 'listInvestigations'>;

export class InvestigationsDataStore extends GraphqlBaseDataStore<ListInvestigations, QueryListInvestigationsArgs> {
	private filtersStore = injectInterface(this, AlertsTimeRangeFilterStore);

	constructor() {
		super();

		makeObservable(this, {
			investigations: computed,
			read: action,
		});

		this.read();
	}

	get investigations() {
		return this.data;
	}

	get investigationsFilters(): FiltersInput {
		const filters: FiltersInput = {
			timestampFilters: [],
		};

		if (this.filtersStore.filters?.timeRange?.from && this.filtersStore.filters?.timeRange?.to) {
			filters.timestampFilters?.push({
				field: TimestampFilterField.LastUpdated,
				range: this.filtersStore.filters.timeRange,
			});
		}

		return filters;
	}

	read() {
		const variables = {
			tenantId: this.authStore.currentTenantId,
			filtersInput: this.investigationsFilters,
		};

		this.query({
			query: GetAllInvestigations,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}

	filtersChangeDisposer = reaction(
		() => this.investigationsFilters,
		() => {
			this.read();
		},
	);

	dispose() {
		this.filtersChangeDisposer();
	}
}
