import { cloneElement, ComponentType, MouseEvent, ReactElement, ReactNode, Ref, useCallback, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentNode } from 'graphql';

import classNames from 'classnames';

import {
	Grid,
	GridProps,
	GridColumn,
	GridNoRecords,
	GridColumnProps,
	GridDetailRowProps,
	GridExpandChangeEvent,
	GridContextMenuEvent,
	GridRowProps,
} from '@progress/kendo-react-grid';
import { PopupProps } from '@progress/kendo-react-popup';

import { FiltersInput } from '@/generated/graphql';
import { ExpandField } from '@/app/_common/types';
import { ContextMenu } from '@/app/_common/_components/new-context-menu';
import { CommonContextMenuProps } from '@/app/_common/_components/new-context-menu/_common/types';

import styles from './data-grid.module.scss';

export interface Column extends GridColumnProps {
	show?: boolean;
	tooltip?: string;
	onExpandChange?: (event: GridExpandChangeEvent) => void;
}

export type GridConfig = {
	/** if scrollable is set as 'scrollable' or 'virtual' we need to set rowHeight too */
	[K in keyof GridProps]: K extends 'rowHeight'
		? Required<GridProps['scrollable']> extends 'scrollable' | 'virtual'
			? Required<GridProps['rowHeight']>
			: GridProps['rowHeight']
		: GridProps[K];
};

export interface ContextMenuConfig<T> extends Omit<PopupProps, 'children' | 'show' | 'offset' | 'onClose' | 'onSelect' | 'onOpen'> {
	onOpen: (field: string, dataItem: T) => void;
	query?: DocumentNode;
	filtersInput?: FiltersInput;
}

interface DataGridProps<T> {
	/** if scrollable is set as 'scrollable' or 'virtual' we need to set rowHeight too */
	gridProps: GridConfig;
	columns: Column[];
	gridRef?: Ref<Grid>;
	measuredRef?: Ref<HTMLDivElement>;
	height?: number;
	expandField?: boolean;
	'data-testid'?: string;
	expandDetailComponent?: ComponentType<GridDetailRowProps>;
	gridNoRecordsContent?: ReactNode;
	className?: string;
	contextMenuProps?: ContextMenuConfig<T>;
	onResetGridPage?: () => void;
	contextMenuContent?: ComponentType<CommonContextMenuProps<T>>;
}

const getDataGridContainerStyles = (height?: number) => (height ? { height: `${height}px` } : {});

const isColumnVisible = (show?: boolean) => {
	if (typeof show === 'boolean') {
		return show;
	}

	return true;
};

export function DataGrid<T>({
	gridProps,
	columns,
	height,
	gridRef,
	measuredRef,
	expandField,
	expandDetailComponent,
	gridNoRecordsContent,
	'data-testid': dataTestId,
	className,
	contextMenuProps,
	contextMenuContent: ContextMenuContent,
	onResetGridPage,
}: Readonly<DataGridProps<T>>) {
	const [show, setShow] = useState(false);
	const [selectedField, setSelectedField] = useState<string>();
	const [selectedDataItem, setSelectedDataItem] = useState<T>();
	const offset = useRef({ left: 0, top: 0 });
	const { t } = useTranslation();
	const containerStyles = useMemo(() => getDataGridContainerStyles(height), [height]);

	const rowRender = useCallback(
		(trElement: ReactElement<HTMLTableRowElement>, props: GridRowProps): ReactNode => {
			const className = classNames(trElement.props.className, { [styles.disabledRow]: Boolean(props.dataItem.disabled) });
			const newTrElement = cloneElement(
				trElement,
				{
					className,
				},
				trElement.props.children as ReactNode,
			);
			return gridProps.rowRender ? gridProps.rowRender(newTrElement, props) : newTrElement;
		},
		[gridProps],
	);

	const { onOpen, ...restContextMenuProps } = contextMenuProps ?? {};

	const handleContextMenuOpen = useCallback((event: MouseEvent) => {
		/*
			This additional check is needed for case when we trigger the kendo build-in onContextMenu callback manually.
			In this case preventDefault is not available.
		*/
		if (typeof event.preventDefault === 'function') event.preventDefault();

		offset.current = { left: event.pageX, top: event.pageY };
		setShow(true);
	}, []);

	const handleContextMenu = useCallback(
		(event: GridContextMenuEvent) => {
			handleContextMenuOpen(event.syntheticEvent);

			const { dataItem, field } = event;

			if (field) {
				setSelectedField(field);
				setSelectedDataItem(dataItem);
				onOpen?.(field, dataItem);
			}
		},
		[handleContextMenuOpen, onOpen],
	);

	const handleCloseMenu = useCallback(() => {
		setShow(false);
	}, []);

	const showContextMenu = contextMenuProps && ContextMenuContent;

	const MemoizedContextMenuContent = useMemo(() => {
		if (!showContextMenu) {
			return null;
		}

		return (
			<ContextMenuContent
				field={selectedField}
				dataItem={selectedDataItem}
				gridRef={gridRef}
				onResetGridPage={onResetGridPage}
				onClose={handleCloseMenu}
			/>
		);
	}, [showContextMenu, ContextMenuContent, selectedField, selectedDataItem, gridRef, onResetGridPage, handleCloseMenu]);

	const MemoizedContextMenu = useMemo(() => {
		if (!showContextMenu) {
			return null;
		}

		return (
			<ContextMenu {...restContextMenuProps} show={show} offset={offset.current} onClose={handleCloseMenu}>
				{MemoizedContextMenuContent}
			</ContextMenu>
		);
	}, [showContextMenu, restContextMenuProps, show, handleCloseMenu, MemoizedContextMenuContent]);

	return (
		<div data-testid={dataTestId} className={styles.dataGridContainer} style={containerStyles} ref={measuredRef}>
			<Grid
				className={classNames(styles.dataGrid, { [styles.resizable]: gridProps.resizable }, className)}
				{...gridProps}
				detail={expandDetailComponent}
				expandField={expandField ? ExpandField.Expanded : ''}
				rowRender={rowRender}
				ref={gridRef}
				onContextMenu={handleContextMenu}
			>
				<GridNoRecords>
					<div className={styles.gridNoRecordsContent}>{gridNoRecordsContent || t('grid.emptyData')}</div>
				</GridNoRecords>
				{columns.map((column) => isColumnVisible(column.show) && <GridColumn key={column.id} {...column} />)}
			</Grid>
			{MemoizedContextMenu}
		</div>
	);
}

DataGrid.displayName = 'DataGrid';
