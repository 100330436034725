import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { makeObservable, action } from 'mobx';
import { FiltersInput, QueryListEndpointsArgs, Query } from '@/generated/graphql';
import { ListEndpointsTableFilterValues } from '@/app/_common/graphql/queries';
import { ValidFilterColumnNames } from '@/app/response/endpoints/_common/constants';

type ListEndpointsTableFilterValuesResult = Pick<Query, 'listEndpointsTableFilterValues'>;

export class EndpointsListColumnFilterDataStore extends GraphqlBaseDataStore<ListEndpointsTableFilterValuesResult, QueryListEndpointsArgs> {
	constructor() {
		super();

		makeObservable(this, {
			read: action,
		});
	}

	read(columnName: ValidFilterColumnNames, filtersInput?: FiltersInput) {
		if (!columnName) {
			return;
		}

		const variables = {
			tenantId: this.authStore.currentTenantId,
			column: columnName,
			filtersInput: filtersInput,
		};

		this.query({
			query: ListEndpointsTableFilterValues,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}
}
