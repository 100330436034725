import { memo } from 'react';

import type { GridCellProps } from '@progress/kendo-react-grid';

import { TrimTextCell } from '@/app/_common/_components/data-grid/_components';

export const AlertTitleCell = memo((props: GridCellProps) => {
	return <TrimTextCell {...props} oldContextMenu={false} />;
});

AlertTitleCell.displayName = 'AlertTitleCell';
