import { AvailablePageSize } from '@/app/advanced-query/_common/types';
import { DateTimePickerOptionKeys } from '@/app/_common/constants';

export const ADX_DEFAULT_PAGE_SIZE: AvailablePageSize = 50;
export const ADX_PAGE_SIZES: AvailablePageSize[] = [15, 30, 50, 100];

export const ADX_DATE_TIME_PERIOD_OPTION_KEYS = [
	DateTimePickerOptionKeys.FIFTEEN_MINUTES,
	DateTimePickerOptionKeys.ONE_HOUR,
	DateTimePickerOptionKeys.ONE_DAY,
	DateTimePickerOptionKeys.ONE_WEEK,
	DateTimePickerOptionKeys.ONE_MONTH,
	DateTimePickerOptionKeys.CUSTOM,
];

export const RESULTS_ALERT_ID_PATH = 'alert_id';
export const RESULTS_ARTIFICIAL_ID_PATH = '_id';
