import { makeAutoObservable, reaction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { ADX_FILTER_OPERATORS, AdxTables, DYNAMIC_COLUMN_TYPE } from '@/app/_common/constants';
import {
	AVAILABLE_FIELDS_BLOCK_NAME,
	AVAILABLE_FIELDS_EXPANDED_STATE,
	AVAILABLE_FIELDS_EXPANDED_STATE_KEY,
	AvailableField,
	FieldFilterGroup,
	ResultsDataItem,
	TableColumn,
} from '@/app/_common/types';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { getFilterGroups, getQueryColumns, migrateToNewestVersion, prepareAvailableFieldsQuery } from '@/app/_common/utils';
import { AdxSchemaDataStore, AuthStore, UserPreferenceStore } from '@/app/_common/stores';

import { toggleFilter } from '@/app/advanced-query/_common/utils';
import {
	AdvancedQueryViewStore,
	AlertsFavoriteFieldsDataStore,
	AlertsOtherFieldsDataStore,
	EventsFavoriteFieldsDataStore,
	EventsOtherFieldsDataStore,
	EvidenceFavoriteFieldsDataStore,
	EvidenceOtherFieldsDataStore,
	ResultsDataStore,
	TotalCountDataStore,
} from '@/app/advanced-query/_common/stores';

const AVAILABLE_FIELDS_PERSISTABLE_STATE_KEY = 'ls/advanced-query/available-fields';
const AVAILABLE_FIELDS_PERSISTABLE_STATE_VERSION = 'v2';

const EVENTS_KEY = AVAILABLE_FIELDS_BLOCK_NAME.EVENTS;
const ALERTS_KEY = AVAILABLE_FIELDS_BLOCK_NAME.ALERTS;
const EVIDENCE_KEY = AVAILABLE_FIELDS_BLOCK_NAME.EVIDENCE;

const INITIAL_STATE: State = {
	fieldsSearch: '',
	selectedField: null,
	selectedBlock: null,
	version: AVAILABLE_FIELDS_PERSISTABLE_STATE_VERSION,
	fieldsExpandedState: {
		[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVENTS_HEADER]: true,
		[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVENTS_OTHER_FIELDS]: false,
		[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.ALERTS_HEADER]: true,
		[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.ALERTS_OTHER_FIELDS]: false,
		[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVIDENCE_HEADER]: true,
		[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVIDENCE_OTHER_FIELDS]: false,
	},
};

interface State {
	fieldsSearch: string;
	selectedField: string | null;
	selectedBlock: AVAILABLE_FIELDS_BLOCK_NAME | null;
	version: string;
	fieldsExpandedState: AVAILABLE_FIELDS_EXPANDED_STATE;
}

interface NewFilterProperties {
	filterField: string | null;
	type?: string;
}

export class AvailableFieldsViewStore {
	private authStore = injectInterface(this, AuthStore);
	private schemaDataStore = injectInterface(this, AdxSchemaDataStore);
	private advancedQueryViewStore = injectInterface(this, AdvancedQueryViewStore);
	private eventsFavoriteFieldsDataStore = injectInterface(this, EventsFavoriteFieldsDataStore);
	private eventsOtherFieldsDataStore = injectInterface(this, EventsOtherFieldsDataStore);
	private alertsFavoriteFieldsDataStore = injectInterface(this, AlertsFavoriteFieldsDataStore);
	private alertsOtherFieldsDataStore = injectInterface(this, AlertsOtherFieldsDataStore);
	private evidenceFavoriteFieldsDataStore = injectInterface(this, EvidenceFavoriteFieldsDataStore);
	private evidenceOtherFieldsDataStore = injectInterface(this, EvidenceOtherFieldsDataStore);
	private resultsDataStore = injectInterface(this, ResultsDataStore);
	private totalCountDataStore = injectInterface(this, TotalCountDataStore);
	private userPreferenceStore = injectInterface(this, UserPreferenceStore);

	private state: State = INITIAL_STATE;

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		migrateToNewestVersion(AVAILABLE_FIELDS_PERSISTABLE_STATE_KEY, AVAILABLE_FIELDS_PERSISTABLE_STATE_VERSION);

		makePersistable(this.state, {
			name: AVAILABLE_FIELDS_PERSISTABLE_STATE_KEY,
			properties: ['version', 'fieldsExpandedState'],
			storage: window.localStorage,
		});
	}

	get fieldsSearch() {
		return this.state.fieldsSearch;
	}

	get allEventsFields() {
		const eventsFavoriteFields = this.eventsFavoriteFieldsDataStore.fields ?? [];
		const eventsOtherFields = this.eventsOtherFieldsDataStore.fields ?? [];

		return [...eventsFavoriteFields, ...eventsOtherFields];
	}

	get allAlertsFields() {
		const alertsFavoriteFields = this.alertsFavoriteFieldsDataStore.fields ?? [];
		const alertsOtherFields = this.alertsOtherFieldsDataStore.fields ?? [];

		return [...alertsFavoriteFields, ...alertsOtherFields];
	}

	get allEvidenceFields() {
		const evidenceFavoriteFields = this.evidenceFavoriteFieldsDataStore.fields ?? [];
		const evidenceOtherFields = this.evidenceOtherFieldsDataStore.fields ?? [];

		return [...evidenceFavoriteFields, ...evidenceOtherFields];
	}

	get eventsAvailableFields() {
		return this.allEventsFields;
	}

	get alertsAvailableFields() {
		return this.allAlertsFields;
	}

	get evidenceAvailableFields() {
		return this.allEvidenceFields;
	}

	get eventsAvailableFieldsList() {
		return this.eventsAvailableFields.filter(({ count, value }) => count > 0 && value.toLowerCase().includes(this.fieldsSearch.toLowerCase()));
	}

	get alertsAvailableFieldsList() {
		return this.alertsAvailableFields.filter(({ count, value }) => count > 0 && value.toLowerCase().includes(this.fieldsSearch.toLowerCase()));
	}

	get evidenceAvailableFieldsList() {
		return this.evidenceAvailableFields.filter(({ count, value }) => count > 0 && value.toLowerCase().includes(this.fieldsSearch.toLowerCase()));
	}

	get totalCount() {
		return this.totalCountDataStore.totalCount;
	}

	get eventsFavoriteFieldsEmpty() {
		return this.eventsFavoriteFieldsDataStore.fields === undefined || this.eventsFavoriteFieldsDataStore.fields.length === 0;
	}

	get eventsOtherFieldsEmpty() {
		return this.eventsOtherFieldsDataStore.fields === undefined || this.eventsOtherFieldsDataStore.fields.length === 0;
	}

	get alertsFavoriteFieldsEmpty() {
		return this.alertsFavoriteFieldsDataStore.fields === undefined || this.alertsFavoriteFieldsDataStore.fields.length === 0;
	}

	get alertsOtherFieldsEmpty() {
		return this.alertsOtherFieldsDataStore.fields === undefined || this.alertsOtherFieldsDataStore.fields.length === 0;
	}

	get evidenceFavoriteFieldsEmpty() {
		return this.evidenceFavoriteFieldsDataStore.fields === undefined || this.evidenceFavoriteFieldsDataStore.fields.length === 0;
	}

	get evidenceOtherFieldsEmpty() {
		return this.evidenceOtherFieldsDataStore.fields === undefined || this.evidenceOtherFieldsDataStore.fields.length === 0;
	}

	get eventsFavoriteFieldsLoading() {
		return this.eventsFavoriteFieldsDataStore.loading;
	}

	get alertsFavoriteFieldsLoading() {
		return this.alertsFavoriteFieldsDataStore.loading;
	}

	get evidenceFavoriteFieldsLoading() {
		return this.evidenceFavoriteFieldsDataStore.loading;
	}

	get eventsOtherFieldsLoading() {
		return this.eventsOtherFieldsDataStore.loading;
	}

	get alertsOtherFieldsLoading() {
		return this.alertsOtherFieldsDataStore.loading;
	}

	get evidenceOtherFieldsLoading() {
		return this.evidenceOtherFieldsDataStore.loading;
	}

	get eventsFavoriteFieldsError() {
		return this.eventsFavoriteFieldsDataStore.error;
	}

	get alertsFavoriteFieldsError() {
		return this.alertsFavoriteFieldsDataStore.error;
	}

	get evidenceFavoriteFieldsError() {
		return this.evidenceFavoriteFieldsDataStore.error;
	}

	get eventsOtherFieldsError() {
		return this.eventsOtherFieldsDataStore.error;
	}

	get alertsOtherFieldsError() {
		return this.alertsOtherFieldsDataStore.error;
	}

	get evidenceOtherFieldsError() {
		return this.evidenceOtherFieldsDataStore.error;
	}

	get eventsEmpty() {
		return this.eventsFavoriteFieldsEmpty && this.eventsOtherFieldsEmpty;
	}

	get alertsEmpty() {
		return this.alertsFavoriteFieldsEmpty && this.alertsOtherFieldsEmpty;
	}

	get evidenceEmpty() {
		return this.evidenceFavoriteFieldsEmpty && this.evidenceOtherFieldsEmpty;
	}

	get savedFavoriteFields() {
		return this.userPreferenceStore.favoriteFields;
	}

	get eventsFavoriteFields(): AvailableField[] {
		return this.eventsAvailableFieldsList.filter((field: AvailableField) => this.savedFavoriteFields[EVENTS_KEY].includes(String(field.value)));
	}

	get alertsFavoriteFields(): AvailableField[] {
		return this.alertsAvailableFieldsList.filter((field: AvailableField) => this.savedFavoriteFields[ALERTS_KEY].includes(String(field.value)));
	}

	get evidenceFavoriteFields(): AvailableField[] {
		return this.evidenceAvailableFieldsList.filter((field: AvailableField) => this.savedFavoriteFields[EVIDENCE_KEY].includes(String(field.value)));
	}

	get eventsOtherFields(): AvailableField[] {
		return this.eventsAvailableFieldsList.filter((field: AvailableField) => !this.savedFavoriteFields[EVENTS_KEY].includes(String(field.value)));
	}

	get alertsOtherFields(): AvailableField[] {
		return this.alertsAvailableFieldsList.filter((field: AvailableField) => !this.savedFavoriteFields[ALERTS_KEY].includes(String(field.value)));
	}

	get evidenceOtherFields(): AvailableField[] {
		return this.evidenceAvailableFieldsList.filter((field: AvailableField) => !this.savedFavoriteFields[EVIDENCE_KEY].includes(String(field.value)));
	}

	get selectedField() {
		return this.state.selectedField;
	}

	get filters() {
		return this.advancedQueryViewStore.filters;
	}

	get fieldFilterGroups() {
		return getFilterGroups(this.filters);
	}

	get fieldsExpandedState(): AVAILABLE_FIELDS_EXPANDED_STATE {
		return this.state.fieldsExpandedState;
	}

	toggleFieldExpandedState = (field: AVAILABLE_FIELDS_EXPANDED_STATE_KEY): void => {
		this.state.fieldsExpandedState[field] = !this.state.fieldsExpandedState[field];
	};

	setFieldsSearch = (value: string) => {
		this.state.fieldsSearch = value;
	};

	setSelectedField = (value: string | null, selectedBlock: AVAILABLE_FIELDS_BLOCK_NAME | null) => {
		this.state.selectedField = value;
		this.state.selectedBlock = selectedBlock;
	};

	toggleFilter = (table: string, field: string | null, value: string | number, operator: ADX_FILTER_OPERATORS) => {
		if (!field) {
			return;
		}

		this.advancedQueryViewStore.setFilters(
			toggleFilter(
				{
					field,
					operator,
					value,
				},
				this.advancedQueryViewStore.filters,
			),
		);
	};

	toggleEqualsFilter = (table: string, value: string | number) => {
		const { filterField, type } = this.getNewFilterProperties(table);
		const isMultipleTablesSelected = this.advancedQueryViewStore.isMultipleTablesSelected;
		const duplicatedDynamicNames = isMultipleTablesSelected
			? this.schemaDataStore.getDuplicatedTableColumnsDynamicNames(table as AdxTables, this.advancedQueryViewStore.tables)
			: [];
		/*
		 * When in two schema tables (e.g. 'alerts' and 'events') same field name (e.g. 'dest') is present,
		 * Advanced Query results table fetches it as two different fields: (e.g. 'dest_dynamic' and 'dest_string').
		 * These are also 'dynamic' fields, though the type is not always 'dynamic' there. To handle this case we calculate 'duplicatedDynamicNames',
		 * and also compare 'filterField' against that array.
		 * */
		const isFieldDynamic = type === DYNAMIC_COLUMN_TYPE || duplicatedDynamicNames.includes(filterField ?? '');
		const operator = isFieldDynamic ? ADX_FILTER_OPERATORS.HAS_ANY : ADX_FILTER_OPERATORS.IN;
		this.toggleFilter(table, filterField, value, operator);
	};

	toggleNotEqualsFilter = (table: string, value: string | number) => {
		const { filterField, type } = this.getNewFilterProperties(table);
		const isMultipleTablesSelected = this.advancedQueryViewStore.isMultipleTablesSelected;
		const duplicatedDynamicNames = isMultipleTablesSelected
			? this.schemaDataStore.getDuplicatedTableColumnsDynamicNames(table as AdxTables, this.advancedQueryViewStore.tables)
			: [];
		/*
		 * When in two schema tables (e.g. 'alerts' and 'events') same field name (e.g. 'dest') is present,
		 * Advanced Query results table fetches it as two different fields: (e.g. 'dest_dynamic' and 'dest_string').
		 * These are also 'dynamic' fields, though the type is not always 'dynamic' there. To handle this case we calculate 'duplicatedDynamicNames',
		 * and also compare 'filterField' against that array.
		 * */
		const isFieldDynamic = type === DYNAMIC_COLUMN_TYPE || duplicatedDynamicNames.includes(filterField ?? '');
		const operator = isFieldDynamic ? ADX_FILTER_OPERATORS.NOT_HAS_ANY : ADX_FILTER_OPERATORS.NOT_IN;
		this.toggleFilter(table, filterField, value, operator);
	};

	removeFilter = ({ field, operator }: FieldFilterGroup) => {
		this.advancedQueryViewStore.setFilters(
			this.advancedQueryViewStore.filters.filter((filter) => !(filter.field === field && filter.operator === operator)),
		);
	};

	removeAllFilters = () => {
		this.advancedQueryViewStore.setFilters([]);
	};

	toggleFavoriteField = (blockName: AVAILABLE_FIELDS_BLOCK_NAME, field: string) => {
		this.userPreferenceStore.toggleFavoriteField(blockName, field);
	};

	clearSelectedField = () => {
		this.setSelectedField(null, null);
	};

	public isFieldPopupOpen = (field: string, blockName: AVAILABLE_FIELDS_BLOCK_NAME) => {
		return this.state.selectedField === field && this.state.selectedBlock === blockName;
	};

	private getSchemaColumns(table: AdxTables): string[] {
		return this.schemaDataStore.getTableSchema(table)?.OrderedColumns?.map(({ Name }) => Name) ?? [];
	}

	private areUIFiltersValid(table: AdxTables): boolean {
		const schemaColumns = this.getSchemaColumns(table);
		const hasInvalidFilter = this.advancedQueryViewStore.filters.some(
			(filter: FieldFilterGroup) => filter.operator === ADX_FILTER_OPERATORS.EQUAL && !schemaColumns.includes(filter.field),
		);
		return !hasInvalidFilter;
	}

	private getColumnsBySchema = (table: AdxTables): TableColumn[] => {
		const areTablesEmpty = this.advancedQueryViewStore.tables.length === 0;
		const isTableSelected: boolean = areTablesEmpty || this.advancedQueryViewStore.tables.includes(table);
		const areFiltersValid: boolean = this.areUIFiltersValid(table);

		if (!isTableSelected || !areFiltersValid) {
			return [];
		}

		const responseColumns = getQueryColumns(this.schemaDataStore.schema, this.authStore.currentTenantId, table, this.resultsDataStore.results);

		if (!responseColumns.length) {
			return [];
		}

		const schemaColumns = this.getSchemaColumns(table);
		const repeatingColumnNames = this.schemaDataStore
			.getDuplicatedTableColumnsDynamicNames(table, this.advancedQueryViewStore.tables)
			.filter((name: string) => {
				return responseColumns.some((column: TableColumn) => column.ColumnName === name);
			});
		const validColumnNames = [...schemaColumns, ...repeatingColumnNames];
		return responseColumns?.filter((column) => validColumnNames.includes(column.ColumnName)) ?? [];
	};

	private getNewFilterProperties = (table: string): NewFilterProperties => {
		const field = this.selectedField;
		const columns = this.schemaDataStore.getTableSchema(table)?.OrderedColumns ?? [];

		const type = columns.find(({ Name }) => Name === field)?.CslType;

		const hasSameFieldInOtherTables = Object.entries(this.schemaDataStore.tables).reduce((result, [tableName, tableObject]) => {
			if (tableName !== table && this.advancedQueryViewStore.tables.includes(tableName)) {
				return tableObject.OrderedColumns.some(({ Name, CslType }) => Name === field && CslType !== type);
			}

			return result;
		}, false);

		const filterField = hasSameFieldInOtherTables ? `${field}_${type}` : field;

		return {
			filterField,
			type,
		};
	};

	resultsLoadingDisposer = reaction(
		() => this.resultsDataStore.loading,
		(loading) => {
			if (loading) {
				this.eventsFavoriteFieldsDataStore.setLoading(true);
				this.eventsOtherFieldsDataStore.setLoading(true);
				this.alertsFavoriteFieldsDataStore.setLoading(true);
				this.alertsOtherFieldsDataStore.setLoading(true);
				this.evidenceFavoriteFieldsDataStore.setLoading(true);
				this.evidenceOtherFieldsDataStore.setLoading(true);
			}
		},
	);

	resultsDataDisposer = reaction(
		() => this.resultsDataStore.data,
		(resultsData: ResultsDataItem[] | undefined) => {
			if (!resultsData?.length) {
				this.eventsFavoriteFieldsDataStore.setEmptyData();
				this.eventsFavoriteFieldsDataStore.setLoading(false);
				this.eventsOtherFieldsDataStore.setEmptyData();
				this.eventsOtherFieldsDataStore.setLoading(false);
				this.alertsFavoriteFieldsDataStore.setEmptyData();
				this.alertsFavoriteFieldsDataStore.setLoading(false);
				this.alertsOtherFieldsDataStore.setEmptyData();
				this.alertsOtherFieldsDataStore.setLoading(false);
				this.evidenceFavoriteFieldsDataStore.setEmptyData();
				this.evidenceFavoriteFieldsDataStore.setLoading(false);
				this.evidenceOtherFieldsDataStore.setEmptyData();
				this.evidenceOtherFieldsDataStore.setLoading(false);
				return;
			}

			const eventsColumnsBySchema = this.getColumnsBySchema(AdxTables.Events);
			const alertsColumnsBySchema = this.getColumnsBySchema(AdxTables.Alerts);
			const evidenceColumnsBySchema = this.getColumnsBySchema(AdxTables.Evidence);

			const hasEventsColumns = Boolean(eventsColumnsBySchema?.length);
			const hasAlertsColumns = Boolean(alertsColumnsBySchema?.length);
			const hasEvidenceColumns = Boolean(evidenceColumnsBySchema?.length);

			const eventsFavoriteFieldsQuery =
				hasEventsColumns &&
				prepareAvailableFieldsQuery({
					table: AdxTables.Events,
					query: this.advancedQueryViewStore.query,
					columns: eventsColumnsBySchema.filter((column) => this.savedFavoriteFields[EVENTS_KEY].includes(column.ColumnName)),
					schema: this.schemaDataStore.schema,
					database: this.authStore.currentTenantId,
					timeRange: this.advancedQueryViewStore.timeRange,
					filtersList: this.advancedQueryViewStore.filters,
				});

			const eventsOtherFieldsQuery =
				hasEventsColumns &&
				prepareAvailableFieldsQuery({
					table: AdxTables.Events,
					query: this.advancedQueryViewStore.query,
					columns: eventsColumnsBySchema.filter((column) => !this.savedFavoriteFields[EVENTS_KEY].includes(column.ColumnName)),
					schema: this.schemaDataStore.schema,
					database: this.authStore.currentTenantId,
					timeRange: this.advancedQueryViewStore.timeRange,
					filtersList: this.advancedQueryViewStore.filters,
				});

			const alertsFavoriteFieldsQuery =
				hasAlertsColumns &&
				prepareAvailableFieldsQuery({
					table: AdxTables.Alerts,
					query: this.advancedQueryViewStore.query,
					columns: alertsColumnsBySchema.filter((column) => this.savedFavoriteFields[ALERTS_KEY].includes(column.ColumnName)),
					schema: this.schemaDataStore.schema,
					database: this.authStore.currentTenantId,
					timeRange: this.advancedQueryViewStore.timeRange,
					filtersList: this.advancedQueryViewStore.filters,
				});

			const alertsOtherFieldsQuery =
				hasAlertsColumns &&
				prepareAvailableFieldsQuery({
					table: AdxTables.Alerts,
					query: this.advancedQueryViewStore.query,
					columns: alertsColumnsBySchema.filter((column) => !this.savedFavoriteFields[ALERTS_KEY].includes(column.ColumnName)),
					schema: this.schemaDataStore.schema,
					database: this.authStore.currentTenantId,
					timeRange: this.advancedQueryViewStore.timeRange,
					filtersList: this.advancedQueryViewStore.filters,
				});

			const evidenceFavoriteFieldsQuery =
				hasEvidenceColumns &&
				prepareAvailableFieldsQuery({
					table: AdxTables.Evidence,
					query: this.advancedQueryViewStore.query,
					columns: evidenceColumnsBySchema.filter((column) => this.savedFavoriteFields[EVIDENCE_KEY].includes(column.ColumnName)),
					schema: this.schemaDataStore.schema,
					database: this.authStore.currentTenantId,
					timeRange: this.advancedQueryViewStore.timeRange,
					filtersList: this.advancedQueryViewStore.filters,
				});

			const evidenceOtherFieldsQuery =
				hasEvidenceColumns &&
				prepareAvailableFieldsQuery({
					table: AdxTables.Evidence,
					query: this.advancedQueryViewStore.query,
					columns: evidenceColumnsBySchema.filter((column) => !this.savedFavoriteFields[EVIDENCE_KEY].includes(column.ColumnName)),
					schema: this.schemaDataStore.schema,
					database: this.authStore.currentTenantId,
					timeRange: this.advancedQueryViewStore.timeRange,
					filtersList: this.advancedQueryViewStore.filters,
				});

			if (eventsFavoriteFieldsQuery) {
				this.eventsFavoriteFieldsDataStore.fetch(eventsFavoriteFieldsQuery, 'events_favorite_fields');
			} else {
				this.eventsFavoriteFieldsDataStore.setEmptyData();
				this.eventsFavoriteFieldsDataStore.setLoading(false);
			}

			if (eventsOtherFieldsQuery) {
				this.eventsOtherFieldsDataStore.fetch(eventsOtherFieldsQuery, 'events_other_fields');
			} else {
				this.eventsOtherFieldsDataStore.setEmptyData();
				this.eventsOtherFieldsDataStore.setLoading(false);
			}

			if (alertsFavoriteFieldsQuery) {
				this.alertsFavoriteFieldsDataStore.fetch(alertsFavoriteFieldsQuery, 'alerts_favorite_fields');
			} else {
				this.alertsFavoriteFieldsDataStore.setEmptyData();
				this.alertsFavoriteFieldsDataStore.setLoading(false);
			}

			if (alertsOtherFieldsQuery) {
				this.alertsOtherFieldsDataStore.fetch(alertsOtherFieldsQuery, 'alerts_other_fields');
			} else {
				this.alertsOtherFieldsDataStore.setEmptyData();
				this.alertsOtherFieldsDataStore.setLoading(false);
			}

			if (evidenceFavoriteFieldsQuery) {
				this.evidenceFavoriteFieldsDataStore.fetch(evidenceFavoriteFieldsQuery, 'evidence_favorite_fields');
			} else {
				this.evidenceFavoriteFieldsDataStore.setEmptyData();
				this.evidenceFavoriteFieldsDataStore.setLoading(false);
			}

			if (evidenceOtherFieldsQuery) {
				this.evidenceOtherFieldsDataStore.fetch(evidenceOtherFieldsQuery, 'evidence_other_fields');
			} else {
				this.evidenceOtherFieldsDataStore.setEmptyData();
				this.evidenceOtherFieldsDataStore.setLoading(false);
			}
		},
	);
}
