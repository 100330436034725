import { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import _isNil from 'lodash/isNil';

import classNames from 'classnames';

import { FieldRenderProps } from '@progress/kendo-react-form';
import { DropDownList, DropDownListProps, DropDownListFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from '@progress/kendo-data-query';

import { FormFieldError } from '@/app/_common/_components/form-components/form-field-error';
import { ThemeStore } from '@/app/_common/stores';

import styles from './form-dropdown.module.scss';

export interface FormDropdownOption {
	value: string | number | null;
	label: string;
	icon?: ReactNode;
}

export interface FormDropdownProps
	extends FieldRenderProps,
		Pick<DropDownListProps, 'dataItemKey' | 'textField' | 'filterable' | 'loading' | 'itemRender' | 'valueRender' | 'popupSettings'> {
	options: FormDropdownOption[] | string[];
	className?: string;
}

export const FormDropdown: FC<FormDropdownProps> = observer(
	({
		validationMessage,
		touched,
		valid,
		value,
		onChange,
		label,
		name,
		options,
		defaultItem,
		opened,
		dataItemKey,
		textField,
		filterable,
		disabled,
		loading,
		required,
		itemRender,
		valueRender,
		popupSettings,
		className,
	}) => {
		const [filter, setFilter] = useState<FilterDescriptor | CompositeFilterDescriptor | undefined>();

		const themeStore = useInstance(ThemeStore);

		const data = useMemo(() => {
			if (filter) {
				return filterBy<FormDropdownOption | string>(options.slice(), filter);
			}

			return options;
		}, [filter, options]);

		const handleFilterChange = useCallback((event: DropDownListFilterChangeEvent) => {
			setFilter(event.filter);
		}, []);

		const showValidationMessage: string | false | null = touched && validationMessage;

		return (
			<div className={classNames(styles.dropdownContainer, className)}>
				{label && (
					<label className={classNames(styles.label, { [styles.labelDisabled]: disabled })} id={name} htmlFor={name}>
						{label}
					</label>
				)}
				<DropDownList
					className={classNames(styles.dropdown, { [styles.empty]: _isNil(value) })}
					opened={opened}
					id={name}
					value={value}
					name={name}
					data={data}
					valid={valid}
					required={required}
					popupSettings={{ ...popupSettings, className: `${styles.popup}`, popupClass: themeStore.theme }}
					defaultItem={defaultItem}
					dataItemKey={dataItemKey}
					textField={textField}
					filterable={filterable}
					onFilterChange={handleFilterChange}
					disabled={disabled}
					loading={loading}
					onChange={onChange}
					itemRender={itemRender}
					valueRender={valueRender}
				/>
				{showValidationMessage && <FormFieldError>{validationMessage}</FormFieldError>}
			</div>
		);
	},
);
