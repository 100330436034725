import { FC, MouseEvent, useCallback, useMemo } from 'react';
import { getter } from '@progress/kendo-react-common';

import { GridCellProps } from '@progress/kendo-react-grid';

import { Checkbox } from '@/app/_common/_components/checkbox';
import { DataCell } from '@/app/_common/_components/data-grid/_components/data-cell';
import { ContextMenuComponentProps } from '@/app/_common/types';

export interface AlertSelectedCellProps extends GridCellProps {
	showTime?: boolean;
	tooltip?: string;
	className?: string;
	onClick?: () => void;
	searchValue?: string;
	ContextMenuComponent?: React.FC<ContextMenuComponentProps>;
	oldContextMenu?: boolean;
}

interface AlertSelectCellProps extends AlertSelectedCellProps {
	idPropertyPath: string;
	isRowSelected: (id: string) => boolean;
	toggleSelectedRow: (id: string) => void;
}

export const AlertSelectCell: FC<AlertSelectCellProps> = ({
	dataItem,
	field,
	onClick,
	ContextMenuComponent,
	isRowSelected,
	toggleSelectedRow,
	idPropertyPath,
	...rest
}) => {
	const idGetter = useMemo(() => getter(idPropertyPath), [idPropertyPath]);
	const id = idGetter(dataItem);

	const disableRightClick = (e: MouseEvent<HTMLSpanElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const checked = isRowSelected(id);

	const onSelectionChange = useCallback(() => {
		toggleSelectedRow(id);
	}, [id, toggleSelectedRow]);

	return (
		<DataCell {...rest} onClick={onClick} dataItem={dataItem} field={field} empty={false} ContextMenuComponent={ContextMenuComponent}>
			<span onContextMenu={disableRightClick}>
				<Checkbox checked={checked} disabled={Boolean(dataItem.disabled)} onChange={onSelectionChange} />
			</span>
		</DataCell>
	);
};

AlertSelectCell.displayName = 'AlertSelectCell';
