import { makeAutoObservable } from 'mobx';
import _orderBy from 'lodash/orderBy';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { SortDirection } from '@/app/_common/constants';
import { InvestigationSelectDataStore } from '@/app/_common/_components/forms/assign-to-investigation-form/_common/stores';

export class InvestigationSelectViewStore {
	private alertsInvestigationDataStore = injectInterface(this, InvestigationSelectDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get data() {
		const data =
			this.alertsInvestigationDataStore.investigations?.map(({ node }) => ({
				date: node.timestamp,
				value: node.id,
				label: node.name,
			})) || [];

		return _orderBy(data, ['date'], [SortDirection.Desc]);
	}

	get loading() {
		return this.alertsInvestigationDataStore.loading;
	}
}
