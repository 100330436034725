import { MitreAttackBadge } from '@/app/_common/_components/mitre-attack-badge';
import { getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';
import { ItemComponentProps } from '@/app/_common/_components/query-results/query-results-table/components/query-results-table-item-expanded/types';

export const QueryResultsTableItemExpandedMitre = ({ value }: ItemComponentProps) => {
	if (!value) {
		return null;
	}

	const mitreText = getMitreAttackBadgeText(String(value));

	return <MitreAttackBadge text={mitreText} />;
};
