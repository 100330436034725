import i18n from '@/translations/i18n';
import { AlertConfidence, AlertConfidenceLabels } from '@/app/_common/constants';

export const getConfidenceLabel = (value: string | null | undefined): AlertConfidenceLabels => {
	switch (value) {
		case AlertConfidence.Maximum:
			return i18n.t('confidenceLevels.maximum');
		case AlertConfidence.High:
			return i18n.t('confidenceLevels.high');
		case AlertConfidence.Medium:
			return i18n.t('confidenceLevels.medium');
		case AlertConfidence.Low:
			return i18n.t('confidenceLevels.low');
		default:
			return i18n.t('confidenceLevels.unknown');
	}
};
