import { FC } from 'react';

import { Evidence } from '@/app/_common/types';

import { AlertEvidence } from '../_components';

interface FortinetFortigateAlertEvidenceProps {
	evidence: Evidence;
}

export const FortinetFortigateAlertEvidence: FC<FortinetFortigateAlertEvidenceProps> = ({ evidence }) => {
	return <AlertEvidence evidence={evidence} />;
};
