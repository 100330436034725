import { memo } from 'react';

import { Alert, Investigation } from '@/generated/graphql';
import { invokeConditionally } from '@/app/_common/utils';
import { ContextMenuItem, ContextMenuSection } from '@/app/_common/_components/new-context-menu/_components';
import { ContextMenuSection as ContextMenuSectionType } from '@/app/_common/_components/new-context-menu/_common/types';

import styles from './context-menu-sections.module.scss';

interface ContextMenuSectionsProps {
	sections: ContextMenuSectionType[];
	investigations: Investigation[];
	loading: boolean;
	onClose?: () => void;
}

export const ContextMenuSections = memo(({ sections, investigations, loading, onClose }: ContextMenuSectionsProps) => {
	return (
		<div className={styles.content}>
			{sections.map(({ title, subtitle, actions, showLoading }) => {
				const filteredActions = actions?.filter(({ show }) => invokeConditionally(show, investigations));

				if (!filteredActions?.length) {
					return null;
				}

				return (
					<ContextMenuSection key={title} subtitle={subtitle} className={styles.section} title={title} loading={showLoading && loading}>
						{filteredActions?.map(({ id, label, disabled, disabledTooltip, alerts, className, onClick }) => {
							const actionAlerts: Alert[] = invokeConditionally(alerts, investigations) || [];
							const LabelRenderer = typeof label === 'function' ? label(actionAlerts?.length || 0, onClose) : label;

							return (
								<ContextMenuItem
									key={id}
									className={className}
									disabled={invokeConditionally(disabled, investigations)}
									disabledTooltip={disabledTooltip}
									onClick={() => {
										if (onClick) {
											onClick(actionAlerts);

											onClose?.();
										}
									}}
								>
									{LabelRenderer}
								</ContextMenuItem>
							);
						})}
					</ContextMenuSection>
				);
			})}
		</div>
	);
});

ContextMenuSections.displayName = 'ContextMenuSections';
