import { getter } from '@progress/kendo-react-common';
import { GridCellProps } from '@progress/kendo-react-grid';

import { CloudCollectorIntegrationPropertiesPaths } from '@/app/_common/constants';
import { TrimTextCell } from '@/app/_common/_components/data-grid/_components';
import { getTelemetryIntegrationTypeLabel, getTelemetryIntegrationTypeIcon } from '@/app/_features/integration/_common/utils';

const TelemetryIntegrationTypeGetter = getter(CloudCollectorIntegrationPropertiesPaths.TelemetryIntegrationType);

export const TelemetryIntegrationTypeCell = (props: GridCellProps) => {
	const telemetryIntegrationType = TelemetryIntegrationTypeGetter(props.dataItem);

	const title = getTelemetryIntegrationTypeLabel(telemetryIntegrationType);
	const Icon = getTelemetryIntegrationTypeIcon(telemetryIntegrationType);

	return <TrimTextCell {...props} Icon={Icon && <Icon />} title={title} />;
};
