import { MitreAttackTactic } from '@/app/_common/_components/mitre-attack-tree/types/MitreAttackTactic';

export function prepareMitreAttackTreeTactic(mitreTactics: string[] = [], mitreTechniques: string[] = []): MitreAttackTactic | undefined {
	if (!Array.isArray(mitreTactics) || !mitreTactics.length) {
		return undefined;
	}

	const mitreTechniquesList = Array.isArray(mitreTechniques) ? mitreTechniques : [];

	return {
		text: mitreTactics[0],
		expanded: true,
		count: 1,
		items: mitreTechniquesList.map((technique: string) => ({ text: technique, count: 1 })),
	};
}
