import _groupBy from 'lodash/groupBy';
import _isNil from 'lodash/isNil';

import { FilterOption, Filters, FilterValue } from '@/app/_common/types';

export function getValue(option: FilterOption) {
	return typeof option === 'string' ? option : option.value;
}

export function hasValue(filter: Filters, value: FilterValue): boolean {
	if (!filter || filter.length === 0) {
		return false;
	}

	// @ts-expect-error
	if (Array.isArray(filter[0]?.value)) {
		// @ts-expect-error
		return filter[0]?.value.some((filterValue) => filterValue === value);
	}

	return filter.includes(value);
}

export function isSpecial(option: FilterOption) {
	return typeof option === 'string' ? false : option.special;
}

export function hasCount(option: FilterOption) {
	const counter = typeof option === 'string' ? null : option.counter;

	return !_isNil(counter) && counter > 0;
}

export function sortFilters(
	options: FilterOption[],
	filter: Filters,
	field: string,
	sort?: (data: FilterOption[], path: string, field: string) => FilterOption[],
) {
	const path = 'label';

	const groupedByActive = _groupBy(options, (option) => hasValue(filter, getValue(option)));

	const { true: active = [], false: notActive = [] } = groupedByActive;

	const groupedBySpecial = _groupBy(notActive, isSpecial);

	const { true: withSpecial = [] } = groupedBySpecial;

	const groupedByCount = _groupBy(
		notActive.filter((item) => !isSpecial(item)),
		hasCount,
	);

	const { true: withCounter = [], false: withoutCounter = [] } = groupedByCount;

	if (sort) {
		return [sort(active, path, field), sort(withSpecial, path, field), sort(withCounter, path, field), sort(withoutCounter, path, field)];
	}

	return [active, withSpecial, withCounter, withoutCounter];
}
