import { FC } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import _truncate from 'lodash/truncate';

import { Tooltip } from '@/app/_common/_components';
import { getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';
import { getMitreAttackColor } from '@/app/_common/_components/mitre-attack-badge/get-mitre-attack-color';
import { HighlightedText } from '@/app/_common/_components/data-grid/_components';
import { MitreAttackIconType } from './_constants/mitre-attack-icon-type';
import { MitreBadgeIcon } from './mitre-badge-icon';

import styles from '@/app/_common/_components/mitre-attack-badge/mitre-attack-badge.module.scss';

interface MitreAttackBadgeContentProps {
	text: string;
	count?: number;
	searchValue?: string;
	hideTooltip?: boolean;
	tactic?: string;
}

const MAX_LENGTH_OF_STRING = 40;
const TOOLTIP_OFFSET_TOP = 5;

export const MitreAttackBadgeContent: FC<MitreAttackBadgeContentProps> = ({ text, count, searchValue, hideTooltip, tactic }) => {
	const truncatedText = _truncate(text, {
		length: MAX_LENGTH_OF_STRING,
	});
	const formattedText = truncatedText.replaceAll(/(?<![\s.])\.(?=[^\s.])/g, ' ');
	const tooltipId = uuidv4();
	const hasTooltip = !hideTooltip && text?.length > MAX_LENGTH_OF_STRING;

	const isTechnique = Boolean(tactic);

	return (
		<>
			<div className={classNames(styles.content, 'mitreAttackBadgeContent')} data-tip={text} data-for={tooltipId}>
				{isTechnique && tactic !== undefined ? (
					<MitreBadgeIcon icon={MitreAttackIconType.TECHNIQUE} color={getMitreAttackColor(getMitreAttackBadgeText(tactic ?? ''))} />
				) : (
					<MitreBadgeIcon icon={MitreAttackIconType.TACTIC} color={getMitreAttackColor(getMitreAttackBadgeText(text))} />
				)}
				<div className={styles.text}>
					{searchValue ? <HighlightedText value={formattedText} searchLine={searchValue} /> : formattedText}
					{count && count > 1 ? <span> ({count})</span> : null}
				</div>
			</div>
			{hasTooltip && <Tooltip tooltipId={tooltipId} tooltipContent={text} offsetTop={TOOLTIP_OFFSET_TOP} />}
		</>
	);
};
