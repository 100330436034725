import { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import classNames from 'classnames';

import { Signature } from '@/app/_common/constants';
import { InsertChartOutlined } from '@mui/icons-material';
import { buttonize } from '@/app/_common/utils';
import { AlertsDetailsNavigationViewStore, getTimelineDetails } from '@/app/_common/_components/details-view/alerts-details-view-navigation';
import { SingleAlertContent } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/single-alert-content/single-alert-content';
import { AlertsDetailsButton } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alerts-details-button/alerts-details-button';
import { getBoostAlertType } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/alerts-details-timeline/_common/utils';
import { ThreatIntelligenceDescription, ThreatIntelligenceMatches } from '@/app/_common/types';

import styles from './boost-alert.module.scss';

interface BoostedAlertProps extends Signature {
	enumerate: number;
	activeBoostedAlert: string;
	threatIntelligenceMatches: ThreatIntelligenceMatches;
	threatIntelligenceDescriptions: ThreatIntelligenceDescription[];
	id?: string;
}

export const BoostedAlert: FC<BoostedAlertProps> = observer(
	({
		silenced,
		alert_ref,
		boost_score,
		severity,
		confidence,
		name,
		enumerate,
		activeBoostedAlert,
		shortdesc,
		important_fields,
		sessions,
		id = '',
		threatIntelligenceMatches,
		threatIntelligenceDescriptions,
	}) => {
		const store = useInstance(AlertsDetailsNavigationViewStore);
		const alertRef = useRef<HTMLDivElement | null>(null);

		const { icon } = getTimelineDetails(boost_score, silenced);

		useEffect(() => {
			if (id === activeBoostedAlert && alertRef.current) {
				alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}, [activeBoostedAlert, id, alertRef]);

		const showDetailsButton = !(!severity && !confidence && !shortdesc && !alert_ref);

		const handleSetActiveAlert = () => {
			store.setActiveBoostedAlert(id);
		};

		return (
			<div
				ref={alertRef}
				{...buttonize(handleSetActiveAlert)}
				className={classNames(styles.alertCardContainer, { [styles.active]: id === activeBoostedAlert })}
			>
				<div className={styles.alertDetailsLeftSide}>
					<span className={styles.enumerate}>{enumerate}.</span>
					<div className={styles.imageBox}>
						<img className={styles.image} src={icon} alt="Alert Icon" />
					</div>
					<span className={styles.name}>
						{getBoostAlertType(boost_score, silenced)} | {name}
					</span>
				</div>
				<div className={styles.alertDetailsRightSide}>
					<div className={styles.cardHeader}>
						{showDetailsButton && <AlertsDetailsButton description={shortdesc} severity={severity} confidence={confidence} link={alert_ref} />}
						<p className={classNames(styles.score, { [styles.noMargin]: !showDetailsButton })}>
							<InsertChartOutlined htmlColor="var(--brandDanger)" />
							<span>Score: {boost_score}</span>
						</p>
						{/* <MitreAttackBadge text={MitreAttackBadgeText.COLLECTION} /> */}
					</div>
					<SingleAlertContent
						sessions={sessions}
						importantColumns={important_fields}
						alertDetailsId={id || ''}
						threatIntelligenceMatches={threatIntelligenceMatches}
						threatIntelligenceDescriptions={threatIntelligenceDescriptions}
					/>
				</div>
			</div>
		);
	},
);
