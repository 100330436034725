import { ReactElement, cloneElement, useState, useRef } from 'react';

import classNames from 'classnames';

import {
	MultiSelect as KendoMultiSelect,
	MultiSelectProps as KendoMultiSelectProps,
	ListItemProps,
	MultiSelectOpenEvent,
	TagData,
} from '@progress/kendo-react-dropdowns';

import { AdxIcon, Checkbox } from '@/app/_common/_components';
import { buttonize } from '@/app/_common//utils';

import styles from './multiselect.module.scss';

export type MultiSelectProps = KendoMultiSelectProps;

export const MultiSelect = ({ className, popupSettings, onOpen, onClose, ...rest }: MultiSelectProps) => {
	const [isOpened, setIsOpened] = useState(false);
	const multiselectRef = useRef<KendoMultiSelect | null>();

	const itemRender = (li: ReactElement, itemProps: ListItemProps) => {
		const itemChildren = (
			<div className={styles.item}>
				<Checkbox className={styles.checkbox} checked={itemProps.selected} /> <AdxIcon type={itemProps.dataItem.value} />
				<span>{li.props.children}</span>
			</div>
		);
		return cloneElement(li, li.props, itemChildren);
	};

	const handleTagClick = () => {
		multiselectRef?.current?.focus();

		if (isOpened) {
			closeDropdown();
		} else {
			openDropdown();
		}
	};

	const tagRender = (tagData: TagData, li: ReactElement) => {
		const itemChildren = [
			<span key={tagData.text.toLowerCase()} className={styles.chipText} {...buttonize(handleTagClick)}>
				{tagData.text}
			</span>,
			li.props.children,
		];

		return cloneElement(li, li.props, itemChildren);
	};

	const openDropdown = () => {
		setIsOpened(true);
	};

	const closeDropdown = () => {
		setIsOpened(false);
	};

	const handleOpen = (event: MultiSelectOpenEvent) => {
		if (typeof onOpen === 'function') {
			onOpen(event);
		}

		openDropdown();
	};

	const handleClose = (event: MultiSelectOpenEvent) => {
		if (typeof onClose === 'function') {
			onClose(event);
		}

		closeDropdown();
	};

	return (
		<KendoMultiSelect
			opened={isOpened}
			autoClose={false}
			itemRender={itemRender}
			tagRender={tagRender}
			onOpen={handleOpen}
			onClose={handleClose}
			{...rest}
			className={classNames(styles.multiselect, className, { [styles.opened]: isOpened })}
			popupSettings={{
				...popupSettings,
				popupClass: classNames(styles.popup, popupSettings?.popupClass),
			}}
			ref={multiselectRef}
		/>
	);
};
