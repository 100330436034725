export enum Namespaces {
	ActionHistory = 'actionHistory',
	AdvancedQuery = 'advancedQuery',
	DetailsView = 'detailsView',
	AlertsActionDialog = 'alertsActionDialog',
	AlertsDashboard = 'alertsDashboard',
	AssignAlertToInvestigation = 'assignAlertToInvestigation',
	Breadcrumbs = 'breadcrumbs',
	FranchiseClients = 'clients',
	CreateFranchiseClient = 'createFranchiseClient',
	ClosedInvestigations = 'closedInvestigations',
	Collectors = 'collectors',
	Common = 'common',
	CreateIntegration = 'createIntegration',
	CreateInvestigationDialog = 'createInvestigationDialog',
	CreateResponseIntegration = 'createResponseIntegration',
	DeleteCard = 'deleteCard',
	EditInvestigationDialog = 'editInvestigationDialog',
	Endpoints = 'endpoints',
	ErrorDetails = 'errorDetails',
	IntegrationDetails = 'integrationDetails',
	Integrations = 'integrations',
	InvestigationDetails = 'investigationDetails',
	ListHeader = 'listHeader',
	MainDashboard = 'mainDashboard',
	NavbarMenu = 'navbarMenu',
	Notes = 'notes',
	Notifications = 'notifications',
	OpenInvestigations = 'openInvestigations',
	PageNotAccess = 'pageNotAccess',
	PageNotFount = 'pageNotFound',
	ResponseIntegrationsPage = 'responseIntegrationsPage',
	ResponseMainPage = 'responseMainPage',
	Sidebar = 'sidebar',
	TelemetryDashboard = 'telemetryDashboard',
	TelemetryMainPage = 'telemetryMainPage',
	TenantManagement = 'tenantManagement',
	TimeRange = 'timeRange',
	Users = 'users',
	Wizard = 'wizard',
	IntegrationDelete = 'integrationDelete',
	QueryResults = 'queryResults',
	AlertsActions = 'alertsActions',
}
