import { ResizeTriggerAreas } from '@visx/brush/lib/types';
import { defaultStyles } from '@visx/tooltip';

import { TIMESTAMP_COLUMN } from '@/app/_common/constants/adx.constants';
import { advancedQueryTooltipBgColor, advancedQueryTooltipColor, advancedQueryTooltipZIndex } from '@/app/_common/constants/style-variables';
import { ChartStackBarKeys } from '@/app/_common/types';

export const ADVANCED_QUERY_CHART_TIMESTAMP_COLUMN = TIMESTAMP_COLUMN;

/* Axis */
export const AXIS_FONT_SIZE = 14;
export const Y_AXIS_RIGHT_MARGIN = 12;
export const X_AXIS_HEIGHT = 20;
export const X_AXIS_COLUMN_WIDTH = 240; // approximate width of Y-axis tick label cell
export const ONE_DIGIT_WIDTH = 8; // approximate width of one number character

/* Chart */
export const ACTION_BLOCK_MARGIN = 12;
export const DEFAULT_CHART_MARGIN = { top: 16, right: 16, bottom: 16, left: 16 };
export const CHART_VERTICAL_MARGINS = DEFAULT_CHART_MARGIN.bottom + DEFAULT_CHART_MARGIN.top;
export const CHART_BORDER_WIDTH = 1;

/* Bar Stack */
export const BAR_STACK_KEYS = [ChartStackBarKeys.EventsCount];
export const BAR_STACK_INNER_PADDING = 0.1;

/*
	Stacked Bars
	Order matters! From bottom to top like for colors.
 */
export const STACKED_BARS_KEYS = [ChartStackBarKeys.EventsCount, ChartStackBarKeys.EvidenceCount, ChartStackBarKeys.AlertsCount];
export const STACKED_BARS_TOTAL_KEY = [ChartStackBarKeys.TotalCount];

/* Grid */
export const GRID_COLUMN_LINE_STYLE = {
	display: 'none',
};
export const GRID_ROW_HEIGHT = 30;

/* Tooltip */
const TOOLTIP_WIDTH = 200;
const TOOLTIP_HEIGHT = 58;
export const TOOLTIP_BOTTOM_OFFSET = 1;
export const TOOLTIP_STYLES = {
	...defaultStyles,
	width: TOOLTIP_WIDTH,
	minHeight: TOOLTIP_HEIGHT,
	backgroundColor: advancedQueryTooltipBgColor,
	boxShadow: 'none',
	color: advancedQueryTooltipColor,
	zIndex: advancedQueryTooltipZIndex,
	border: 'none',
	borderRadius: 0,
	padding: '6px 12px',
	fontWeight: 300,
	fontSize: '14px',
	lineHeight: '110%',
};

/* Brush */
export const BRUSH_RESIZE_TRIGGER_AREAS = ['left', 'right'] as ResizeTriggerAreas[];
export const BRUSH_EXTRA_HEIGHT = X_AXIS_HEIGHT + DEFAULT_CHART_MARGIN.bottom - CHART_BORDER_WIDTH;
