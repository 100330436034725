import { observe } from 'mobx';
import { History, Listener } from 'history';
import { RouterStore } from '@/app/_common/stores';

export interface SynchronizedHistory extends History {
	subscribe?: (listener: Listener) => void;
	unsubscribe?: () => void;
}

export const syncHistoryWithStore = (history: History, store: RouterStore): SynchronizedHistory => {
	const _history: SynchronizedHistory = history;

	const subscribe = (listener: Listener) => {
		const onStoreChange = () => {
			const rawLocation = { ...store.location };
			listener({ location: rawLocation, action: history.action });
		};

		const unsubscribeFromHistory = observe(store, 'location', onStoreChange);

		listener({ location: store.location, action: history.action });

		return unsubscribeFromHistory;
	};

	_history.subscribe = subscribe;

	return _history;
};
