import { reaction, makeObservable, computed, observable, action } from 'mobx';
import _isEmpty from 'lodash/isEmpty';

import { DateTimePickerOptionKeys } from '@/app/_common/constants';
import { getTimeRange, momentToUtcString } from '@/app/_common/utils';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { RouterStore, UrlStore } from '@/app/_common/stores';

import { TimestampRange } from '@/generated/graphql';

const INITIAL_STATE = {
	inDocument: false,
	lastReload: new Date(),
	liveMode: true,
	filters: {
		timeRange: {
			key: DateTimePickerOptionKeys.THREE_DAYS,
		},
	},
};

interface QueryParams {
	tkey?: DateTimePickerOptionKeys;
	from?: string;
	to?: string;
}

export interface TimeRangeFilters {
	timeRange: {
		key: DateTimePickerOptionKeys;
		value?: TimestampRange;
	};
}

export interface State {
	liveMode: boolean;
	lastReload: Date;
	inDocument: boolean;
	filters: TimeRangeFilters;
}

export class TimeRangeFilterStore {
	private routerStore = injectInterface(this, RouterStore);
	private urlStore = injectInterface(this, UrlStore);

	protected state!: State;

	constructor(private FILTERS_SESSION_STORAGE_KEY: string, private STATE_OBJECT_NAME: string) {
		makeObservable(
			this,
			{
				//@ts-ignore
				state: observable,
				timeRangeValue: computed,
				filters: computed,
				setTimeRangeKey: action,
				setTimeRangeValue: action,
				setInDocument: action,
				assignRemoteState: action,
				setCustomTimeRange: action,
				setTimeRange: action,
			},
			{ autoBind: true },
		);

		this.initState();
	}

	get timeRangeValue() {
		return {
			key: this.state.filters.timeRange.key,
			value:
				this.state.filters.timeRange.key === DateTimePickerOptionKeys.CUSTOM
					? {
							start: this.state.filters.timeRange.value?.from,
							end: this.state.filters.timeRange.value?.to,
					  }
					: {},
		};
	}

	get filters() {
		/* The below console.log forces recalculation of filters when last reload date changes.
		 * This supports 'Live Mode' and 'Refresh' functionalities of DateTimeRangePicker.
		 * Tried to move 'this.state.lastReload = new Date();' to a separate method, and it had Mobx recalculation cons.
		 * The below approach seems to be a good candidate for a technical debt task to be reworked with good profiling in mind.
		 */
		// eslint-disable-next-line no-console
		console.log('Last Dashboard Reload Date:', this.state.lastReload);

		const filters = {
			timeRange: this.state.filters.timeRange.value,
		};

		if (this.state.filters.timeRange.key !== DateTimePickerOptionKeys.CUSTOM) {
			const rangeFromKey = getTimeRange(this.state.filters.timeRange.key, momentToUtcString);

			if (rangeFromKey?.start && rangeFromKey?.end) {
				filters.timeRange = {
					from: rangeFromKey.start,
					to: rangeFromKey.end,
				};
			}
		}

		return filters;
	}

	get liveMode() {
		return this.state.liveMode;
	}

	setTimeRangeKey = (key: DateTimePickerOptionKeys) => {
		this.state.filters.timeRange.key = key;

		if (key === DateTimePickerOptionKeys.CUSTOM) {
			this.state.liveMode = false;
		}
	};

	setTimeRangeValue = (from: string, to: string) => {
		this.state.filters.timeRange.value = {
			from,
			to,
		};
	};

	setCustomTimeRange = (key: DateTimePickerOptionKeys, from?: string, to?: string) => {
		if (!from || !to) {
			return;
		}

		this.state.filters.timeRange = {
			key,
			value: {
				from,
				to,
			},
		};
	};

	setTimeRange = (key: DateTimePickerOptionKeys, from?: string, to?: string) => {
		if (key === DateTimePickerOptionKeys.CUSTOM) {
			this.setCustomTimeRange(key, from, to);

			return;
		}

		this.setTimeRangeKey(key);
	};

	reload = () => {
		this.state.lastReload = new Date();
	};

	toggleLiveMode = () => {
		if (!this.state.liveMode) {
			this.state.lastReload = new Date();
		}

		this.state.liveMode = !this.state.liveMode;
	};

	setInDocument = (value: boolean) => {
		this.state.inDocument = value;
	};

	private assignRemoteState = (initialState: State, remoteState: QueryParams) => {
		const state = { ...initialState };

		if (remoteState.tkey) {
			state.filters.timeRange.key = remoteState.tkey;
		}

		if (typeof remoteState.from === 'string' && typeof remoteState.to === 'string' && remoteState.tkey) {
			state.liveMode = false;
			state.filters.timeRange = {
				key: remoteState.tkey,
				value: {
					from: remoteState.from,
					to: remoteState.to,
				},
			};
		}

		this.state = state;
	};

	private readFiltersFromSessionStorage = () => {
		let sessionState = {};

		try {
			sessionState = JSON.parse(sessionStorage.getItem(this.FILTERS_SESSION_STORAGE_KEY) ?? '{}');
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Failed to read filters from session storage. Error:', error);
		}

		return sessionState;
	};

	private setFiltersInSessionStorage = (params: QueryParams) => {
		try {
			sessionStorage.setItem(this.FILTERS_SESSION_STORAGE_KEY, JSON.stringify(params));
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Failed to save filters in session storage. Error:', error);
		}
	};

	private setFiltersInUrl = (params: QueryParams) => {
		this.urlStore.updateStore({ [this.STATE_OBJECT_NAME]: params });
	};

	private initState = () => {
		const state = this.urlStore.getStateVariables(this.STATE_OBJECT_NAME) as QueryParams;

		if (state && !_isEmpty(state)) {
			this.assignRemoteState(INITIAL_STATE, state);
			this.setFiltersInSessionStorage(state);
		} else {
			const sessionState = this.readFiltersFromSessionStorage();
			this.assignRemoteState(INITIAL_STATE, sessionState);
			this.setFiltersInUrl(sessionState);
		}

		this.syncState(this.state.filters);
	};

	private syncState = (filters: TimeRangeFilters) => {
		const params: QueryParams = { tkey: filters.timeRange.key };

		if (filters.timeRange.key !== DateTimePickerOptionKeys.CUSTOM) {
			if (this.liveMode) {
				params.tkey = filters.timeRange.key;
			} else {
				const range = getTimeRange(this.state.filters.timeRange.key, momentToUtcString);

				params.from = range?.start;
				params.to = range?.end;
			}
		}

		if (filters.timeRange.key === DateTimePickerOptionKeys.CUSTOM) {
			params.from = filters.timeRange.value?.from;
			params.to = filters.timeRange.value?.to;
		}

		this.setFiltersInSessionStorage(params);
		this.setFiltersInUrl(params);
	};

	timeRangeKeyDisposer = reaction(
		() => this.state.filters.timeRange.key,
		() => {
			if (this.state.inDocument) {
				this.syncState(this.state.filters);
			}
		},
	);

	timeRangeValueDisposer = reaction(
		() => this.state.filters.timeRange.value,
		() => {
			if (this.state.inDocument) {
				this.syncState(this.state.filters);
			}
		},
	);

	liveModeDisposer = reaction(
		() => this.state.liveMode,
		() => {
			if (this.state.inDocument) {
				this.syncState(this.state.filters);
			}
		},
	);

	dispose = () => {
		this.timeRangeKeyDisposer();
		this.timeRangeValueDisposer();
		this.liveModeDisposer();
	};
}
