import classNames from 'classnames';

import styles from './summary-section-item-title.module.scss';

interface SummarySectionItemTitleProps {
	title: string;
	className?: string;
}

export const SummarySectionItemTitle = ({ title, className }: SummarySectionItemTitleProps) => {
	if (!title) {
		return null;
	}

	return <div className={classNames(styles.title, className)}>{title}</div>;
};
