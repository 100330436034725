import { action, makeObservable } from 'mobx';

import { Query, QueryGetInvestigationArgs } from '@/generated/graphql';

import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { GetInvestigation, GetInvestigationName } from '@/app/_common/graphql/queries';
import { RootPaths } from '@/app/_common/navigation';

export type GetInvestigation = Pick<Query, 'getInvestigation'>;

export class AlertDetailAssignedAlertInvestigationDataStore extends GraphqlBaseDataStore<GetInvestigation, QueryGetInvestigationArgs> {
	constructor() {
		super();

		makeObservable(this, {
			read: action,
		});

		this.read();
	}

	read(investigationId?: string) {
		const id = this.getInvestigationId(investigationId);

		if (!id) {
			return;
		}

		const variables = {
			id,
			tenantId: this.authStore.currentTenantId,
		};

		this.query({
			query: GetInvestigationName,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}

	private getInvestigationId = (id?: string) => {
		if (id) {
			return id;
		}

		return window.location.pathname.includes(RootPaths.INVESTIGATIONS) ? window.location.pathname.split('/').pop() : undefined;
	};
}
