import { FC } from 'react';

import { Evidence } from '@/app/_common/types';

import { AlertEvidence } from '../_components';

interface MicrosoftDefenderAlertEvidenceProps {
	evidence: Evidence;
}

export const MicrosoftDefenderAlertEvidence: FC<MicrosoftDefenderAlertEvidenceProps> = ({ evidence }) => {
	return <AlertEvidence evidence={evidence} />;
};
