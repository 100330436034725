import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Namespaces } from '@/translations/namespaces';
import { ErrorDetailsContent, ErrorDetailsIcon, ErrorDetailsTitle } from './_components';

import styles from './error-details.module.scss';

interface ErrorDetailsProps {
	title?: string;
	buttonTitle?: string;
	error?: string;
	className?: string;
}

export const ErrorDetails: FC<ErrorDetailsProps> = ({ error, title, buttonTitle, className }) => {
	const { t } = useTranslation([Namespaces.ErrorDetails]);
	const defaultTitle = t('title');
	const defaultButtonTitle = t('expandButtonTitle');

	if (!error) {
		return null;
	}

	return (
		<div className={classNames(styles.content, className)}>
			<ErrorDetailsIcon />
			<ErrorDetailsTitle title={title || defaultTitle} />
			<ErrorDetailsContent error={error} buttonTitle={buttonTitle || defaultButtonTitle} />
		</div>
	);
};
