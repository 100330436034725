import { InvestigationTypename } from '@/app/_common/constants';
import { SetAlertsOnInvestigationUpdateCacheFn } from '@/app/_features/alerts-actions/_common/stores/_common/types';
import { updateCommonAssignAlertsDashboard } from '@/app/dashboards/alerts-dashboard/_common/utils/cache-updates';

export const updateCacheAssignAlertsDashboard: SetAlertsOnInvestigationUpdateCacheFn = (user, cache, { data }, { variables }) => {
	if (!data?.setAlertsOnInvestigation || data?.setAlertsOnInvestigation.__typename !== InvestigationTypename.Investigation) {
		return;
	}

	const investigationId = variables?.investigationId;
	const investigation = data.setAlertsOnInvestigation;
	const alerts = investigation.alerts ?? [];
	const alertsIds = variables?.alertIds ?? [];

	if (!investigationId || !alertsIds.length) {
		return;
	}

	updateCommonAssignAlertsDashboard(cache, alerts, investigationId, user, variables);
};
