import { FC, useCallback, useMemo, useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useInstance } from 'react-ioc';

import classNames from 'classnames';
import { PanelBar, PanelBarItem, PanelBarSelectEventArguments } from '@progress/kendo-react-layout';
import { AppConfigurationViewStore } from '@/app/_common/stores/app-configuration-store/app-configuration.view-store';
import { RootPaths } from '@/app/_common/navigation/root-paths';
import { getSelectedSidebarMenuItem } from '@/app/_common/utils';
import { APP_ENTRY_SIDEBAR_EXPANDED_ITEM } from '@/app/_common/constants/app.constants';

import { MenuItemComponent } from './menu-item-component';
import { MenuItem } from '../interfaces';

import styles from './panel-bar-component.module.scss';

export interface PanelBarComponentProps {
	menuItems: MenuItem[];
	drawerOpened: boolean;
	selectedStyles?: string;
	selectedBorderStyles?: string;
	disabled?: boolean;
}

export const PanelBarComponent: FC<PanelBarComponentProps> = ({
	menuItems,
	drawerOpened,
	selectedStyles = '',
	selectedBorderStyles = '',
	disabled = false,
}) => {
	const store = useInstance(AppConfigurationViewStore);

	const { pathname, search } = useLocation();
	const availableMenuItems = menuItems.filter(
		(menuItem) => (menuItem.display === undefined || menuItem.display) && store.userHasAccess(menuItem.feature),
	);

	const selectedItem = useMemo(() => getSelectedSidebarMenuItem(pathname, availableMenuItems), [availableMenuItems, pathname]);
	const [expandedItem, setExpandedItem] = useState(APP_ENTRY_SIDEBAR_EXPANDED_ITEM);

	useLayoutEffect(() => {
		setExpandedItem((prevState) => {
			if (!prevState.includes(pathname as RootPaths)) {
				return [...prevState, pathname] as RootPaths[];
			}

			return prevState;
		});
	}, [pathname]);

	const handleOnSelect = useCallback(
		(event: PanelBarSelectEventArguments) => {
			const newExpandedItems = Array.from(new Set(event.expandedItems));
			setExpandedItem(newExpandedItems as RootPaths[]);
		},
		[setExpandedItem],
	);

	return (
		<div className={styles.sidebarWrapper}>
			<PanelBar
				selected={selectedItem?.route ?? ''}
				isControlled={true}
				onSelect={handleOnSelect}
				expanded={expandedItem}
				expandMode="multiple"
				className={styles.panelbarContainer}
			>
				{availableMenuItems.map((menuItem) => {
					const availableSubRoutes = menuItem.subitems?.filter(({ display, feature }) => display && store.userHasAccess(feature));

					return (
						<PanelBarItem
							disabled={disabled}
							id={menuItem.route}
							route={menuItem.route}
							expanded={pathname.startsWith(menuItem.route) && Boolean(availableSubRoutes?.length)}
							key={menuItem.text}
							headerClassName={classNames(
								styles.menuItemWrapper,
								{
									[styles.drawerCollapsed]: !drawerOpened,
									[styles.inactive]: menuItem.inactive,
								},
								selectedBorderStyles,
							)}
							title={
								<MenuItemComponent
									{...menuItem}
									route={menuItem.route + search}
									drawerOpened={drawerOpened}
									selectedRoute={selectedItem?.route}
									selectedStyles={selectedStyles}
								/>
							}
						>
							{availableSubRoutes?.length &&
								availableSubRoutes.map(({ display = true, feature, ...subitem }) => {
									if (!display || !store.userHasAccess(feature)) {
										return null;
									}

									return (
										<PanelBarItem
											id={subitem.route}
											route={subitem.route}
											key={subitem.text}
											className={classNames(styles.subitemWrapper, { [styles.inactive]: subitem.inactive })}
											title={
												<MenuItemComponent
													{...subitem}
													route={subitem.route + search}
													drawerOpened={drawerOpened}
													selectedRoute={selectedItem?.route}
													selectedStyles={selectedStyles}
												/>
											}
										/>
									);
								})}
						</PanelBarItem>
					);
				})}
			</PanelBar>
		</div>
	);
};
