import { FC, ReactNode } from 'react';

interface WizardComponentsProps {
	currentStepNumber: number;
	currentComponent: ReactNode;
}

export const WizardComponents: FC<WizardComponentsProps> = ({ currentComponent }) => {
	return <>{currentComponent}</>;
};
