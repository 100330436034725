import moment from 'moment';

import { KUSTO_QUERY_DATE_TIME_FORMAT } from '@/app/_common/constants';
import { ChartDataItem, ChartStackBarKeys, TimeRangeFilterOptionValue } from '@/app/_common/types';

export const prepareTimeRangeForQuery = (
	values: string[],
	chartData: ChartDataItem[],
	persistentTimeRangeToValue: string,
): Required<TimeRangeFilterOptionValue> => {
	const format = KUSTO_QUERY_DATE_TIME_FORMAT;
	const fromDate = values[0];
	const fromValue = moment.utc(fromDate).format(format);

	const toDate = values[values.length - 1];
	const toDateBarIndex = chartData.findIndex((item: ChartDataItem) => item[ChartStackBarKeys.Date] === toDate);

	// Take next bar to calculate date time range from it
	const nextBar = chartData[toDateBarIndex + 1];
	const toDateValue = nextBar ? nextBar[ChartStackBarKeys.Date] : persistentTimeRangeToValue || toDate;
	const toValue = moment.utc(toDateValue).format(format);

	return {
		from: fromValue,
		to: toValue,
	};
};
