import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CopyButton } from '@/app/_common/_components';

import { Namespaces } from '@/translations/namespaces';

import styles from './copy-value-action.module.scss';

interface CopyValueActionProps {
	className?: string;
	closeContextMenu?: () => void;
	value: string | number | boolean | null | undefined | string[] | number[];
}

export const CopyValueAction: FC<CopyValueActionProps> = ({ className = '', closeContextMenu = () => undefined, value = '' }) => {
	const { t } = useTranslation(Namespaces.AlertsActions, { keyPrefix: 'menu.actions.value.items.copy' });

	return (
		<div className={classNames(styles.container, className)}>
			<CopyButton value={value} label={t('label')} className={styles.container} onCopyValue={closeContextMenu} />
		</div>
	);
};
