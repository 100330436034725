import { PropsWithChildren } from 'react';
import { observer } from 'mobx-react-lite';
import { provider } from 'react-ioc';

import { AlertsActionDialogComponent, AlertsActionDialogProps } from '@/app/_common/_components/alerts-action-dialog/alerts-action-dialog';
import {
	AlertsActionDialogViewStore,
	AlertsDataStore,
	InvestigationsDataStore,
	AlertsDataGridViewStore,
	AlertsActionDataStore,
} from '@/app/_common/_components/alerts-action-dialog/_common';

const AlertsActionDialogModule = provider(
	AlertsDataGridViewStore,
	AlertsActionDialogViewStore,
	AlertsActionDataStore,
	AlertsDataStore,
	InvestigationsDataStore,
)(
	observer((props: PropsWithChildren<AlertsActionDialogProps>) => {
		return <AlertsActionDialogComponent {...props} />;
	}),
);

AlertsActionDialogModule.displayName = 'AlertsActionDialogModule';

export const AlertsActionDialog = AlertsActionDialogModule;
