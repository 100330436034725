import { gql } from '@apollo/client';

import { CoreAlertFragment, CoreInvestigationFragment } from '@/app/_common/graphql';

export const GetAlerts = gql`
	query ListAlerts($tenantId: ID!, $filtersInput: FiltersInput, $input: Paging) {
		listAlerts(tenantId: $tenantId, filtersInput: $filtersInput, input: $input) {
			edges {
				node {
					...CoreAlertFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${CoreAlertFragment}
`;

export const GetAlert = gql`
	query GetAlert($tenantId: ID!, $getAlertId: ID!) {
		getAlert(tenantId: $tenantId, id: $getAlertId) {
			... on Alert {
				data_objects {
					data_path {
						container
						key
					}
					object_type
				}
				id
				externalReferenceUrl
				event_source
				summary
			}
		}
	}
`;

export const GetAssignees = gql`
	query GetAssignees($tenantId: ID!) {
		listUsers(tenantId: $tenantId) {
			edges {
				node {
					id
					displayName
				}
			}
		}
	}
`;

export const ListAlertsByIds = gql`
	query ListAlertsByIds($tenantId: ID!, $filtersInput: FiltersInput) {
		listAlerts(tenantId: $tenantId, filtersInput: $filtersInput) {
			edges {
				node {
					id
					timestamp
					severity
					title
					data_objects {
						data_path {
							container
							key
						}
						object_type
					}
					event_details {
						confidence
						mitre_category
						src
						dst
					}
					investigationSummary {
						id
					}
					event_source
					state {
						alertState
						requesterUser {
							upn
							id
						}
						timestamp
					}
				}
			}
		}
	}
`;

export const ListResultsAlertsByIds = gql`
	query ListResultsAlertsByIds($tenantId: ID!, $filtersInput: FiltersInput) {
		listAlerts(tenantId: $tenantId, filtersInput: $filtersInput) {
			edges {
				node {
					id
					timestamp
					investigationSummary {
						id
					}
					state {
						alertState
					}
					severity
					event_details {
						confidence
						src
						dst
						mitre_category
					}
					title
					event_source
				}
			}
		}
	}
`;

export const ListAlertsStateByIds = gql`
	query ListAlertsStateByIds($tenantId: ID!, $filtersInput: FiltersInput) {
		listAlerts(tenantId: $tenantId, filtersInput: $filtersInput) {
			edges {
				node {
					id
					state {
						alertState
					}
				}
			}
		}
	}
`;

export function createListInvestigationsQuery() {
	const query = gql`
		query ListInvestigations($tenantId: ID!, $filtersInput: FiltersInput) {
			listInvestigations(tenantId: $tenantId, filtersInput: $filtersInput) {
				edges {
					node {
						id
						name
						status
						alerts {
							id
						}
					}
				}
			}
		}
	`;

	return query;
}

export const CreateInvestigationWithAlerts = gql`
	mutation CreateInvestigation(
		$tenantId: ID!
		$name: String!
		$priority: InvestigationPriority!
		$type: InvestigationType!
		$assigneeId: ID
		$alertIds: [ID!]
	) {
		createInvestigation(tenantId: $tenantId, name: $name, priority: $priority, type: $type, assigneeId: $assigneeId, alertIds: $alertIds) {
			...CoreInvestigationFragment
			... on Error {
				message
				code
				correlationId
			}
		}
	}
	${CoreInvestigationFragment}
`;

export const AssignAlertsToInvestigation = gql`
	mutation SetAlertsOnInvestigation($tenantId: ID!, $investigationId: ID!, $alertIds: [ID!]!) {
		setAlertsOnInvestigation(tenantId: $tenantId, investigationId: $investigationId, alertIds: $alertIds) {
			... on Investigation {
				id
				name
				timestamp
				last_updated
				alerts {
					id
				}
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const GetAlertCount = gql`
	query GetAlertCount($tenantId: ID!, $from: String!, $to: String!) {
		getAlertCount(tenantId: $tenantId, from: $from, to: $to) {
			... on AlertCount {
				assignment {
					assigned
					unassigned
					dismissed
				}
				severity {
					critical
					high
					medium
					low
					unknown
				}
				detectedBy {
					aiEngine
					vendor
				}
				total
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const AlertCreatedSubscription = gql`
	subscription AlertCreatedSubscription($tenantId: ID!) {
		alertCreated(tenantId: $tenantId) {
			...CoreAlertFragment
		}
	}
	${CoreAlertFragment}
`;

export const AlertUpdatedSubscription = gql`
	subscription AlertUpdatedSubscription($tenantId: ID!) {
		alertUpdated(tenantId: $tenantId) {
			...CoreAlertFragment
		}
	}
	${CoreAlertFragment}
`;

export const SetAlertsState = gql`
	mutation SetAlertsState($tenantId: ID!, $alertIds: [String!]!, $action: SetAlertsStateAction!) {
		setAlertsState(tenantId: $tenantId, alertIds: $alertIds, action: $action) {
			... on Alerts {
				alerts {
					id
					investigationSummary {
						id
					}
					state {
						alertState
						requesterUser {
							upn
							id
						}
						timestamp
					}
				}
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;
