import { DescendantChildren } from '@/app/_common/_components/rich-text-editor';

export const countList = (child: DescendantChildren) => {
	let counter = 0;
	if (child.text) {
		counter += child.text.length;
	}
	if (child.children) {
		child.children.forEach((child) => {
			counter += countList(child);
		});
	}

	return counter;
};
