import { AdxTables, QUERY_SEPARATOR } from '@/app/_common/constants';
import { FieldFilterGroup, Schema, TimeRangeFilterOption } from '@/app/_common/types';
import { getDateTimeColumns, getDateTimeFilter, getFilters, getTimestampColumn, removeQueryComments, retrieveQuery } from '@/app/_common/utils';

export const getAvailableFieldsBaseQuery = (
	table: AdxTables,
	query: string,
	schema: Schema,
	database: string,
	timeRange: TimeRangeFilterOption,
	filtersList: FieldFilterGroup[] = [],
): string => {
	const clearedQuery = removeQueryComments(query);

	const [, ...restQuery] = clearedQuery.split('|');
	const tableQuery = [table, ...restQuery].join('|');

	const { query: _query } = retrieveQuery(removeQueryComments(tableQuery));
	const dateTimeColumns = getDateTimeColumns([table], schema, database);
	const timestampColumn = getTimestampColumn(dateTimeColumns);
	const timeRangeFilter = getDateTimeFilter(_query, dateTimeColumns, timestampColumn, timeRange);
	const filters = getFilters(filtersList);

	const preparedQuery = [table, timeRangeFilter, filters, restQuery.join(QUERY_SEPARATOR).trim()]
		.filter((value) => value)
		.join(` ${QUERY_SEPARATOR} `);

	return preparedQuery;
};
