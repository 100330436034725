// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getHeaderValue = (property: string, obj: any) => {
	const foundValue = property
		.replace(/\[([^[\]]+)]/g, '.$1')
		.split('.')
		.reduce((acc, key, i, arr) => {
			const value = acc[key];

			if (value === undefined || value === null) {
				arr.splice(1);
			} else {
				return value;
			}
		}, obj);

	if (foundValue === undefined) {
		return property in obj ? obj[property] : '';
	}

	return foundValue;
};
