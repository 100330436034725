export const filterByIsNotInOperator = (value: string, filterValues: string[], ignoreCase: boolean): boolean => {
	if (!Array.isArray(filterValues) || !filterValues?.length) {
		return true;
	}

	return !filterValues.some((filterValue) => {
		if (ignoreCase) {
			return filterValue.toLowerCase() === value.toLowerCase();
		}

		return filterValue === value;
	});
};
