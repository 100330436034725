import { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import classNames from 'classnames';

import { buttonize } from '@/app/_common/utils';
import { AvailableFieldsViewStore } from '@/app/advanced-query/_common/stores';
import { useOutsideClick } from '@/app/_common/hooks';

import { FIELD_FILTER_GROUPS } from '@/app/_common/constants';
import { AVAILABLE_FIELDS_BLOCK_NAME } from '@/app/_common/types';

import { AvailableFieldsListItemPopupContent } from '../available-fields-list-item-popup-content';

import styles from './available-fields-list-item.module.scss';
import { AvailableFieldsListItemPopupModule } from '../available-fields-list-item-popup';

export interface AvailableFieldsListItemProps {
	blockName: AVAILABLE_FIELDS_BLOCK_NAME;
	value: string;
	count: number;
}

export const AvailableFieldsListItem: FC<AvailableFieldsListItemProps> = observer(({ blockName, value, count }) => {
	const store = useInstance(AvailableFieldsViewStore);
	const isOpen = store.isFieldPopupOpen(value, blockName);

	const { anchorRef, popupRef } = useOutsideClick<HTMLLIElement, HTMLDivElement>(
		isOpen,
		useCallback(() => store.clearSelectedField(), [store]),
	);

	const [anchor, setAnchor] = useState(anchorRef.current);

	useEffect(() => {
		setAnchor(anchorRef.current);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [store.selectedField]);

	const handleClick = () => {
		if (value !== store.selectedField) {
			store.setSelectedField(value, blockName);
		} else {
			store.clearSelectedField();
		}
	};

	const valueClassNames = classNames(styles.listItem, {
		[styles.selected]: value === store.selectedField && !store.fieldFilterGroups[value],
		[styles.equals]: store.fieldFilterGroups[value] === FIELD_FILTER_GROUPS.EQUALS,
		[styles.notEquals]: store.fieldFilterGroups[value] === FIELD_FILTER_GROUPS.NOT_EQUALS,
		[styles.mixed]: store.fieldFilterGroups[value] === FIELD_FILTER_GROUPS.MIXED,
	});

	return (
		<>
			<li className={valueClassNames} {...buttonize<HTMLLIElement>(handleClick)} ref={anchorRef}>
				<div className={styles.columnName}>{value}</div>
				<div className={styles.count}>{count}</div>
			</li>
			<AvailableFieldsListItemPopupModule anchor={anchor} isOpen={isOpen}>
				<AvailableFieldsListItemPopupContent blockName={blockName} value={value} popupRef={popupRef} />
			</AvailableFieldsListItemPopupModule>
		</>
	);
});
