const createRegexForTimestampFilter = (dateTimeColumns: string[]) =>
	new RegExp(`\\|\\s*(?:where|filter)[^|]*?\\[?['"]?(?:${dateTimeColumns.join('|')})['"]?\\]?`);

export const isTimestampFilterInQuery = (query: string, dateTimeColumns: string[]) => {
	if (dateTimeColumns.length === 0) {
		return false;
	}

	return createRegexForTimestampFilter(dateTimeColumns).test(query);
};
