import { DYNAMIC_COLUMN_TYPE, QUERY_SEPARATOR } from '@/app/_common/constants';
import { SchemaColumn } from '@/app/_common/types';
import {
	DATA_VARIABLE_NAME,
	BASE_DATA_VARIABLE_NAME,
	ALLOWED_EMPTY_COLUMNS,
} from '@/app/_common/utils/adx/prepare-available-fields-values-query/prepare-available-fields-values-query.constants';

export const getBaseDataQuery = (column: SchemaColumn): string => {
	const baseSection = `let ${BASE_DATA_VARIABLE_NAME} = ${DATA_VARIABLE_NAME}`;
	const filterNullSection = column.CslType === DYNAMIC_COLUMN_TYPE ? `where isnotnull(${column.Name})` : '';
	const filterEmptySection = ALLOWED_EMPTY_COLUMNS.includes(column.Name) ? '' : `where isnotempty(${column.Name})`;
	const mvExpandSection = column.CslType === DYNAMIC_COLUMN_TYPE ? `mv-expand ${column.Name} to typeof(string)` : '';
	const summarizeSection = `summarize ${column.Name}_count = count() by ${column.Name}`;

	return [baseSection, filterNullSection, mvExpandSection, filterEmptySection, summarizeSection].filter(Boolean).join(` ${QUERY_SEPARATOR} `) + ';';
};
