import { ThreatIntelligenceHit } from '@/app/_common/types';

export const getHitsReferences = (hits: ThreatIntelligenceHit[]) => {
	if (!Array.isArray(hits)) {
		return [];
	}

	return hits.reduce((acc, curr) => {
		if (!curr) {
			return [...acc];
		}

		const currentReferences = curr.saas_references?.filter((reference) => reference) || [];

		if (currentReferences.length) {
			return [...acc, ...currentReferences];
		} else {
			return [...acc];
		}
	}, [] as string[]);
};
