import React, { lazy, Suspense, useCallback, useMemo, LazyExoticComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { AlertConfidence } from '@/app/_common/constants';
import { Severity } from '@/generated/graphql';
import { DetailsViewHeader } from './details-view-header';
import { LoadingSpinner } from '@/app/_common/_components';
import {
	AlertActionProps,
	AlertStateInfoDetails,
	DataGridSelectedValue,
	ThreatIntelligenceDescription,
	ThreatIntelligenceMatches,
} from '@/app/_common/types';
import { Namespaces } from '@/translations/namespaces';
import { MitreAttackBadgeText } from '@/app/_common/_components/mitre-attack-badge/_constants/mitre-attack-badge-text';
import { DetailsViewItemData, DetailsViewNavigationProps, DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { extractResultsTableItemId } from '@/app/_common/utils';

import styles from './details-view.module.scss';

export interface DetailsViewProps {
	typename: DetailsViewTypename;
	onCloseButtonClick?: () => void;
	// AlertDetailsViewStore props
	selectedItem: DetailsViewItemData;
	selectedItemTitle: string;
	clearSelectedItemId?: () => void;
	onNextItem: () => void;
	onPreviousItem: () => void;
	selectedItems?: DetailsViewItemData[];
	loading: boolean;
	isItemSelected: DataGridSelectedValue;
	totalItemsAmount: number;
	currentItemIndex: number;
	selectedItemTimestamp: string;
	selectedItemId?: string;
	selectedItemSeverity: Severity;
	selectedItemConfidence: AlertConfidence;
	selectedItemDescription: string;
	selectedItemRecommendation?: string | null;
	loadingAssignedInvestigation: boolean;
	selectedMitreCategory?: MitreAttackBadgeText;
	selectedItemState: AlertStateInfoDetails;
	// Assign alert dialog props
	handleAssignOrCreateButtonClick?: () => void;
	singleActions?: AlertActionProps[];
	bulkActions?: AlertActionProps[];
	// AlertsDetailsLoadingIndicator props
	hasError: boolean;
	retryRead: () => void;
	// SingleAlert specific props
	threatIntelligenceMatches: ThreatIntelligenceMatches;
	threatIntelligenceDescriptions: ThreatIntelligenceDescription[];
}

const AlertsDetailsNavigation = lazy(
	() => import('@/app/_common/_components/details-view/alerts-details-view-navigation/alerts-details-view-navigation'),
);
const EvidencesDetailsViewNavigation = lazy(
	() => import('@/app/_common/_components/details-view/evidences-details-view-navigation/evidences-details-view-navigation'),
);
const EventsDetailsViewNavigation = lazy(
	() => import('@/app/_common/_components/details-view/events-details-view-navigation/events-details-view-navigation'),
);

const layoutMapping: Record<DetailsViewTypename, LazyExoticComponent<React.ComponentType<DetailsViewNavigationProps>> | null> = {
	[DetailsViewTypename.ALERT]: AlertsDetailsNavigation,
	[DetailsViewTypename.INVESTIGATION_ALERT_SUMMARY]: AlertsDetailsNavigation,
	[DetailsViewTypename.EVENT]: EventsDetailsViewNavigation,
	[DetailsViewTypename.EVIDENCE]: EvidencesDetailsViewNavigation,
	[DetailsViewTypename.UNKNOWN]: null,
};

export const DetailsView = ({
	typename,
	onCloseButtonClick,
	// AlertDetailsViewStore props
	selectedItem,
	clearSelectedItemId,
	selectedItemTitle,
	onNextItem,
	onPreviousItem,
	selectedItems,
	loading,
	isItemSelected,
	totalItemsAmount,
	currentItemIndex,
	selectedItemTimestamp,
	selectedItemId,
	selectedItemSeverity,
	selectedItemConfidence,
	selectedItemDescription,
	selectedItemRecommendation,
	loadingAssignedInvestigation,
	selectedMitreCategory,
	selectedItemState,
	// Assign alert dialog props
	handleAssignOrCreateButtonClick,
	singleActions,
	bulkActions,
	// AlertsDetailsLoadingIndicator props
	hasError,
	retryRead,
	// SingleAlert specific props
	threatIntelligenceMatches,
	threatIntelligenceDescriptions,
}: DetailsViewProps) => {
	const { t } = useTranslation([Namespaces.DetailsView]);

	const handleCloseButtonClick = useCallback(() => {
		clearSelectedItemId?.();
		onCloseButtonClick?.();
	}, [clearSelectedItemId, onCloseButtonClick]);

	const itemId = extractResultsTableItemId(selectedItemId);

	const navigationProps: DetailsViewNavigationProps = useMemo(
		() => ({
			selectedItemSeverity,
			selectedItemConfidence,
			selectedItemDescription,
			selectedItemRecommendation,
			loadingAssignedInvestigation,
			mitreCategory: selectedMitreCategory,
			itemStateInfoDetails: selectedItemState,
			loading,
			hasError,
			retryRead,
			hasSelectedItem: Boolean(selectedItem),
			threatIntelligenceMatches,
			threatIntelligenceDescriptions,
			selectedItem,
			itemId,
		}),
		[
			itemId,
			hasError,
			loading,
			loadingAssignedInvestigation,
			retryRead,
			selectedItem,
			selectedItemConfidence,
			selectedItemDescription,
			selectedItemRecommendation,
			selectedItemSeverity,
			selectedItemState,
			selectedMitreCategory,
			threatIntelligenceDescriptions,
			threatIntelligenceMatches,
		],
	);

	if (loading) {
		return <LoadingSpinner />;
	}

	const NavigationComponent = layoutMapping[typename] || layoutMapping[DetailsViewTypename.UNKNOWN];

	return (
		<div className={!selectedItem ? styles.detailsViewHidden : styles.detailsViewVisible} data-testid="alert-details">
			<DetailsViewHeader
				typename={typename}
				isItemSelected={isItemSelected}
				totalItemsAmount={totalItemsAmount}
				onPreviousItem={onPreviousItem}
				onNextItem={onNextItem}
				currentItemIndex={currentItemIndex}
				selectedItemTitle={selectedItemTitle}
				handleCloseButtonClick={handleCloseButtonClick}
				assignOrCreateInvestigation={handleAssignOrCreateButtonClick}
				currentItemTimestamp={selectedItemTimestamp}
				itemId={itemId}
				selectedItems={selectedItems}
				selectedItem={selectedItem}
				singleActions={singleActions}
				singleActionsTitle={t('actions.single.title')}
				bulkActions={bulkActions}
				bulkActionsTitle={t('actions.bulk.title')}
			/>

			<Suspense fallback={<LoadingSpinner />}>{NavigationComponent && <NavigationComponent {...navigationProps} />}</Suspense>
		</div>
	);
};
