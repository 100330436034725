import { makeObservable, action } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { GetEndpoint } from '@/app/_common/graphql/queries';
import { QueryGetEndpointArgs, Query } from '@/generated/graphql';

type GetEndpointQueryResult = Pick<Query, 'getEndpoint'>;

export class EndpointRowDetailsDataStore extends GraphqlBaseDataStore<GetEndpointQueryResult, QueryGetEndpointArgs> {
	constructor() {
		super();

		makeObservable(this, {
			read: action,
		});
	}

	read(endpointId: string) {
		if (!endpointId) {
			return;
		}

		const variables = {
			tenantId: this.authStore.currentTenantId,
			id: endpointId,
		};

		this.query({
			query: GetEndpoint,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}
}
