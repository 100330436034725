import { FC } from 'react';

import { MitreAttackBadgeColor } from './_constants/mitre-attack-badge-color';
import { ReactComponent as MitreTactic } from '@/assets/images/mitre-tactic.svg';
import { ReactComponent as MitreTechnique } from '@/assets/images/mitre-technique.svg';

import { MitreAttackIconType } from './_constants/mitre-attack-icon-type';

interface MitreBadgeIconProps {
	color: MitreAttackBadgeColor;
	icon: MitreAttackIconType;
}

export const MitreBadgeIcon: FC<MitreBadgeIconProps> = ({ color, icon }) => {
	return icon === MitreAttackIconType.TACTIC ? <MitreTactic style={{ fill: color }} /> : <MitreTechnique style={{ fill: color }} />;
};
