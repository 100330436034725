import { useCallback, useMemo } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { Alert, AlertEdge } from '@/generated/graphql';
import { CommonContextMenuProps } from '@/app/_common/_components/new-context-menu/_common/types';
import { DashboardAlertDetailsViewStore } from '@/app/_common/_components/details-view/stores';
import { useAlertsActions } from '@/app/_features/alerts-actions/_common/hooks/use-alerts-actions';
import { AlertsContextMenuContent } from '@/app/_features/alerts-actions-menu';
import { AlertsListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-list';

interface AlertsDashboardContextMenuContentProps extends CommonContextMenuProps<AlertEdge> {
	onResetGridPage?: () => void;
}

export const AlertsDashboardContextMenuContent = observer((props: AlertsDashboardContextMenuContentProps) => {
	const alertListStore = useInstance(AlertsListViewStore);
	const detailsStore = useInstance(DashboardAlertDetailsViewStore);
	const actions = useAlertsActions();

	const selectedAlerts = useMemo(() => {
		return alertListStore.data.reduce<Alert[]>((result, alertEdge) => {
			if (alertListStore.isRowSelected(alertEdge.node.id)) {
				result.push(alertEdge.node);
			}

			return result;
		}, []);
	}, [alertListStore]);

	const handleViewDetailsClick = useCallback(
		(alert: Alert) => {
			detailsStore.setSelectedAlertId(alert.id);
		},
		[detailsStore],
	);

	return (
		<AlertsContextMenuContent
			{...props}
			actions={actions}
			filters={alertListStore.activeEqualFilters}
			alerts={selectedAlerts}
			dataItemPath="node"
			onSetFilter={alertListStore.setGridHeaderFilter}
			onViewDetailsClick={handleViewDetailsClick}
		/>
	);
});
