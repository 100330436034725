import { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { AssignToInvestigationTabs } from '@/app/_common/constants';

import styles from './form-tabs.module.scss';

interface FormTab {
	id: AssignToInvestigationTabs;
	title: string;
	content: ReactNode;
}

interface FormTabsProps {
	tabs: FormTab[];
	active: AssignToInvestigationTabs;
	onTabButtonClick(id: AssignToInvestigationTabs): void;
	onTabContentFocus(id: AssignToInvestigationTabs): void;
}

export const FormTabs: FC<FormTabsProps> = observer(({ tabs, active, onTabButtonClick, onTabContentFocus }) => (
	<div className={styles.tabs}>
		{tabs.map(({ id, title, content }) => (
			<div key={id} className={classNames(styles.tab, { [styles.tabActive]: active === id })}>
				<div onClick={() => onTabButtonClick(id)} onKeyUp={() => onTabButtonClick(id)} role="button" tabIndex={0} className={styles.tabTitle}>
					{title}
				</div>
				<div onFocus={() => onTabContentFocus(id)} role="tabpanel" tabIndex={0} className={styles.tabContent}>
					{content}
				</div>
			</div>
		))}
	</div>
));
