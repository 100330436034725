import { FC } from 'react';

import _get from 'lodash/get';
import { GridCellProps } from '@progress/kendo-react-grid';

import { InvestigationType } from '@/app/_common/_components/forms/_common/investigation-dropdown-options';

import styles from './investigation-type-cell.module.scss';

export const investigationTypeTranslation = new Map<string, string>([
	['Alert', InvestigationType.Alert],
	['Threat', InvestigationType.Threat],
	['Incident', InvestigationType.Incident],
]);

export const InvestigationTypeCell: FC<GridCellProps> = ({ field, dataItem }) => {
	const value = _get(dataItem, field || '');

	if (value) {
		return (
			<td title={investigationTypeTranslation.get(value)} className={styles.investigationTypeCell}>
				{investigationTypeTranslation.get(value)}
			</td>
		);
	}

	return <td className={styles.empty}>-</td>;
};
