import { FC, useCallback, MouseEvent } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Tooltip } from '@/app/_common/_components';
import { MenuItem } from '@/app/_common/_components/sidebar/_common/interfaces';
import { removeQueryParamsFromRoute } from '@/app/_common/utils';

import styles from './menu-item-component.module.scss';

const OFFSET_RIGHT = 20;

export const MenuItemComponent: FC<MenuItem> = ({
	text,
	Icon,
	drawerOpened,
	route,
	selectedRoute = '',
	selectedStyles = '',
	tooltipText = '',
	inactive = false,
}) => {
	const handleClick = useCallback(
		(event: MouseEvent) => {
			if (inactive) {
				event.preventDefault();
			}
		},
		[inactive],
	);

	const isSelected = removeQueryParamsFromRoute(route) === selectedRoute;

	return (
		<NavLink to={route} key={text} className={styles.menuItemContainer} onClick={handleClick}>
			{drawerOpened ? <Tooltip tooltipId={route} tooltipContent={text} place="right" offsetRight={OFFSET_RIGHT} /> : null}
			<div
				className={classNames(styles.menuItemContent, {
					[styles.drawerCollapsed]: !drawerOpened,
				})}
				title={tooltipText}
				data-tip={text}
				data-for={route}
			>
				<div className={styles.navigationIcon}>
					<Icon className={classNames({ [selectedStyles]: isSelected })} />
				</div>
				<h6
					className={classNames(styles.menuText, {
						[styles.drawerCollapsed]: !drawerOpened,
						[selectedStyles]: isSelected,
					})}
				>
					{text}
				</h6>
			</div>
		</NavLink>
	);
};
