import { action, computed, makeObservable, reaction, runInAction } from 'mobx';

import { FranchiseTenantBasicInfo } from '@/app/_common/interfaces';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { ListTenantsBasicInfo } from '@/app/_common/graphql/queries';
import { FiltersInput, Query } from '@/generated/graphql';

type QueryVariables = {
	franchiseId: string;
	filtersInput?: FiltersInput;
};

type QueryResult = Pick<Query, 'listTenants'>;

export class FranchiseTenantsDataStore extends GraphqlBaseDataStore<QueryResult, QueryVariables> {
	constructor() {
		super();
		makeObservable(this, {
			tenants: computed,
			read: action,
		});
	}

	get tenants(): FranchiseTenantBasicInfo[] {
		return this.data?.listTenants?.edges.map((curr) => curr.node) ?? [];
	}

	read(filtersInput?: FiltersInput) {
		const variables = {
			franchiseId: this.authStore.franchiseId,
			filtersInput,
		};

		this.query({
			query: ListTenantsBasicInfo,
			variables,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		});
	}

	franchiseIdDataDisposer = reaction(
		() => this.authStore.franchiseId,
		(franchiseId: string | undefined) => {
			if (!this.authStore.isFranchiseUser) {
				return;
			}

			// At the very beginning "franchiseId" is not set.
			if (!franchiseId) {
				runInAction(() => {
					this.clearResult();
				});
				return;
			}

			this.read();
		},
	);

	tenantsDataDisposer = reaction(
		() => this.tenants,
		(tenants) => {
			this.authStore.setFranchiseTenants(tenants);
		},
	);

	dispose = () => {
		this.franchiseIdDataDisposer();
		this.tenantsDataDisposer();
	};
}
