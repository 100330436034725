import { Ref } from 'react';
import { observer } from 'mobx-react-lite';
import { provider } from 'react-ioc';

import { Form } from '@progress/kendo-react-form';

import { AssignToInvestigationForm, AssignToInvestigationFormProps } from '@/app/_common/_components/forms';
import {
	InvestigationSelectViewStore,
	InvestigationSelectDataStore,
} from '@/app/_common/_components/forms/assign-to-investigation-form/_common/stores';

interface AssignToInvestigationFormModuleProps extends AssignToInvestigationFormProps {
	formRef: Ref<Form>;
}

const AssignToInvestigationFormModule = provider(
	InvestigationSelectDataStore,
	InvestigationSelectViewStore,
)(observer<AssignToInvestigationFormModuleProps>(({ formRef, ...props }) => <AssignToInvestigationForm {...props} ref={formRef} />));

AssignToInvestigationFormModule.displayName = 'AssignToInvestigationFormModule';

export default AssignToInvestigationFormModule;
