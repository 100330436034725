import i18n from '@/translations/i18n';
import { observer } from 'mobx-react-lite';

import { getter } from '@progress/kendo-react-common';
import { SortDescriptor } from '@progress/kendo-data-query';

import { FilterOption, Filters, ThreatIntelligenceDescription, ThreatIntelligenceMatches, WidthsState } from '@/app/_common/types';
import { Column } from '@/app/_common/_components/data-grid/data-grid';
import { DateTimeCell } from '@/app/_common/_components/data-grid/_components/date-time-cell/date-time-cell';
import { DataHeaderCell } from '@/app/_common/_components/data-grid/_components/data-header-cell';
import { TrimTextCell } from '@/app/_common/_components/data-grid/_components/trim-text-cell/trim-text-cell';
import { LARGE_COLUMN_WIDTH } from '@/app/_common/constants';
import { getTitle } from './get-title';
import { DATA_TIME_HEADER_KEY, DATE_TIME_HEADER_NAME } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_common/constants';
import { Namespaces } from '@/translations/namespaces';
import { moveColumnToFront } from '@/app/_common/utils/move-column-to-front';
import { SingleAlertContentViewStore } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/single-alert-content/single-alert-content.view-store';
import { ThreatIntelligenceCell } from '@/app/_common/_components/data-grid/_components';

import styles from './create-session-columns.module.scss';

interface StoreUtilsFunctions {
	getCounter: (field: string) => number;
	sort: SortDescriptor[];
	setSort: (sort: SortDescriptor[]) => void;
	setGridHeaderFilter: (field: string, values: Filters) => void;
	getGridHeaderFilterValues: (field: string) => string[];
	getFilterOptions: (field: string) => {
		value: string;
		label: string;
		counter: number;
	}[];
	resetGridHeaderFilter: (field: string) => void;
}
interface CreateColumnsProps {
	columnsNames: string[];
	widths: WidthsState;
	sortFilters: (data: FilterOption[], path: string) => FilterOption[];
	storeUtilsFunctions: StoreUtilsFunctions;
	dateTimePropertyName: string;
	singleAlertStore: SingleAlertContentViewStore;
	tableId: string;
	threatIntelligenceMatches: ThreatIntelligenceMatches;
	threatIntelligenceDescriptions: ThreatIntelligenceDescription[];
}

export function createSessionColumns({
	columnsNames,
	widths,
	storeUtilsFunctions,
	sortFilters,
	dateTimePropertyName,
	singleAlertStore,
	tableId,
	threatIntelligenceMatches,
	threatIntelligenceDescriptions,
}: CreateColumnsProps): Column[] {
	const gridColumns: Column[] = [];
	if (!columnsNames) {
		return gridColumns;
	}
	const { getCounter, sort, setSort, setGridHeaderFilter, getGridHeaderFilterValues, getFilterOptions, resetGridHeaderFilter } = storeUtilsFunctions;

	for (const key of columnsNames) {
		const title = getTitle(key);

		switch (key) {
			case dateTimePropertyName:
			case DATA_TIME_HEADER_KEY:
			case DATE_TIME_HEADER_NAME:
				gridColumns.push({
					id: key,
					field: key,
					title: i18n.t('grid.headers.dateTime', { ns: Namespaces.Common }),
					cell: DateTimeCell,
					width: widths[key],
					headerClassName: styles.headerCell,
					headerCell: observer((props) => <DataHeaderCell {...props} sortable={true} sort={sort} onSort={setSort} />),
					minResizableWidth: LARGE_COLUMN_WIDTH,
					show: true,
				});
				break;
			default:
				gridColumns.push({
					id: key,
					field: key,
					title: title.join(' '),
					cell: observer((props) => {
						const cellValueGetter = getter(key);
						const cellValue = cellValueGetter(props.dataItem);
						const hasThreatIntelligenceHit = Boolean(threatIntelligenceMatches[cellValue]);
						const threatIntelligenceCellId = `ti-cell-${cellValue}-${tableId}`;

						if (hasThreatIntelligenceHit) {
							return (
								<ThreatIntelligenceCell
									{...props}
									id={threatIntelligenceCellId}
									value={cellValue}
									matches={threatIntelligenceMatches}
									descriptions={threatIntelligenceDescriptions}
									currentThreatIntelligenceIconIndex={singleAlertStore.currentThreatIntelligenceIconIndex}
									onThreatIntelligenceIconIndexChange={singleAlertStore.setCurrentThreatIntelligenceIconIndex}
									//Preconfigured function to expand Alert Detail's table
									expandTable={singleAlertStore.setRenderOnlyImportantColumns.bind(null, false)}
								/>
							);
						}

						return <TrimTextCell {...props} />;
					}),
					headerCell: observer((props) => (
						<DataHeaderCell
							{...props}
							counter={getCounter(key)}
							sortable={true}
							sort={sort}
							onSort={setSort}
							onFilter={setGridHeaderFilter}
							filterable={true}
							filter={getGridHeaderFilterValues(key)}
							filterOptions={getFilterOptions(key)}
							clearFilter={resetGridHeaderFilter}
							className={styles.columnHeader}
							sortFilterOptions={sortFilters}
						/>
					)),
					headerClassName: styles.headerCell,
					resizable: true,
					width: widths[key],
					minResizableWidth: LARGE_COLUMN_WIDTH,
					show: true,
				});
		}
	}

	return moveColumnToFront(gridColumns, dateTimePropertyName);
}
