import { FC, MouseEvent, KeyboardEvent } from 'react';

import classNames from 'classnames';

import { FilterOption, FilterValue } from '@/app/_common/types';
import { buttonize } from '@/app/_common/utils';
import { ArrayFilterOperators, FilterOperators } from '@/app/_common/constants';

import { SelectMode } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/constants';

import { OptionGroupItemColorIndicator } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/option-group-item-color-indicator';
import { OptionGroupItemLabel } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/option-group-item-label';
import { getItemDetails } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/utils';
import { OptionGroupItemButtons } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/option-group-item-buttons';
import { OptionGroupItemIcon } from './option-group-item-icon';
import { OptionGroupItemCounter } from './option-group-item-counter';

import styles from './option-group-item.module.scss';

interface OptionGroupItemProps {
	item: FilterOption;
	selected: boolean;
	onClick: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, values: FilterValue[]) => void;
	onButtonClick?: (value: FilterValue, operator: ArrayFilterOperators) => void;
	selectMode?: SelectMode;
	selectedOperator?: FilterOperators;
}

export const OptionGroupItem: FC<OptionGroupItemProps> = ({
	item,
	selected,
	onClick,
	selectMode = SelectMode.Basic,
	onButtonClick,
	selectedOperator,
}) => {
	const { label, value, counter, bulletsConfig, icon, field } = getItemDetails(item);

	const handleClick = (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
		if (selectMode === SelectMode.Basic) {
			onClick(event, [value]);
		}
	};

	const handleButtonClick = (operator: ArrayFilterOperators) => {
		if (!onButtonClick) {
			return;
		}

		onButtonClick(value, operator);
	};

	return (
		<li
			title={label}
			className={classNames(styles.optionGroupItem, 'k-item', {
				['k-state-selected']: selected,
				[styles.equal]: selectedOperator === FilterOperators.IsIn,
				[styles.notEqual]: selectedOperator === FilterOperators.IsNotIn,
			})}
			{...buttonize<HTMLElement>(handleClick)}
		>
			<OptionGroupItemIcon icon={icon} />
			<OptionGroupItemColorIndicator config={bulletsConfig} />
			<OptionGroupItemLabel value={label} field={field} />
			<OptionGroupItemCounter value={counter} />
			{selectMode === SelectMode.Advanced && <OptionGroupItemButtons onClick={handleButtonClick} selectedOperator={selectedOperator} />}
		</li>
	);
};
