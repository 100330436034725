import Group from '@visx/group/lib/Group';
import Pie from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { useCallback } from 'react';

import { Tooltip } from '@/app/_common/_components/tooltip';
import { DEFAULT_PIE_CHART_MARGIN, DEFAULT_PIE_CHART_OPTIONS, MINIMUM_ANGLE_VALUE, PIE_CHART_TOOLTIP_ID } from './pie-chart.constants';

type PieChartRecord = {
	label: string;
	percentage: number;
};

export type PieChartDataRecord = {
	label: string;
	color: string;
	percentage: number;
};

export type PieChartOptions = {
	showPercentage?: boolean;
	showTooltip?: boolean;
	strokeWidth?: number;
	strokeColor?: string;
	tooltipClassName?: string;
};

export type PieChartProps = {
	width: number;
	height: number;
	data: PieChartDataRecord[];
	options?: PieChartOptions;
	margin?: { top?: number; right?: number; bottom?: number; left?: number };
};

export const PieChart = ({ width, height, data, margin, options }: PieChartProps) => {
	const chartMargins = { ...DEFAULT_PIE_CHART_MARGIN, ...margin };
	const chartOptions = { ...DEFAULT_PIE_CHART_OPTIONS, ...options };
	const { showPercentage, showTooltip, strokeWidth, strokeColor, tooltipClassName } = chartOptions;
	const innerWidth = width - chartMargins.left - chartMargins.right;
	const innerHeight = height - chartMargins.top - chartMargins.bottom;

	const centerY = innerHeight / 2;
	const centerX = innerWidth / 2;
	const top = centerY + chartMargins.top;
	const left = centerX + chartMargins.left;

	const radius = Math.min(innerWidth, innerHeight) / 2;

	const getRecordPercentage = (pieChartRecord: PieChartRecord) => pieChartRecord.percentage;
	const getStatusColor = scaleOrdinal({
		domain: data.map(({ label }) => label),
		range: data.map(({ color }) => color),
	});
	const getTooltipContent = useCallback((datatip: string) => datatip, []);

	return (
		<svg width={width} height={height}>
			<Group top={top} left={left}>
				<Pie data={data} pieValue={getRecordPercentage} outerRadius={radius}>
					{(pie) => {
						return pie.arcs.map((arc) => {
							const { label } = arc.data;
							const [centroidX, centroidY] = pie.path.centroid(arc);
							const arcPath = pie.path(arc);
							const arcFillColor = getStatusColor(label);
							const hasSpaceForLabel = arc.endAngle - arc.startAngle >= MINIMUM_ANGLE_VALUE;

							return (
								<g key={`arc-${label}`}>
									<path
										d={arcPath || undefined}
										fill={arcFillColor}
										stroke={strokeColor}
										strokeWidth={strokeWidth}
										data-tip={label}
										data-for={PIE_CHART_TOOLTIP_ID}
									/>
									{hasSpaceForLabel && showPercentage && (
										<text
											x={centroidX}
											y={centroidY}
											width={50}
											fill="var(--textColor)"
											fontSize="1rem"
											textAnchor="middle"
											pointerEvents="none"
											fontWeight={900}
										>
											{arc.data.percentage}%
										</text>
									)}
								</g>
							);
						});
					}}
				</Pie>
				{showTooltip && <Tooltip tooltipId={PIE_CHART_TOOLTIP_ID} tooltipContent={getTooltipContent} className={tooltipClassName} />}
			</Group>
		</svg>
	);
};
