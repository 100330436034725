import { MitreAttackTactic, MitreAttackTechnique } from '@/app/_common/_components/mitre-attack-tree/types';

export const getMergedTacticsAndTechniques = (notMergedTactics: MitreAttackTactic[]): MitreAttackTactic[] => {
	const tactics: MitreAttackTactic[] = [];
	for (const newTactic of notMergedTactics) {
		let found = false;

		tactics.forEach((tactic) => {
			if (tactic.text === newTactic.text) {
				found = true;
				tactic.count += 1;

				newTactic.items.forEach((newTechnique) => {
					const techniqueFound = hasMatchingTechnique(tactic, newTechnique);

					if (!techniqueFound) {
						tactic.items.push({ ...newTechnique });
					}
				});
			}
		});

		if (!found) {
			tactics.push({ ...newTactic });
		}
	}

	return tactics;
};

const hasMatchingTechnique = (tactic: MitreAttackTactic, newTechnique: MitreAttackTechnique) => {
	let techniqueFound = false;

	tactic.items.forEach((technique) => {
		if (technique.text === newTechnique.text) {
			technique.count += 1;
			techniqueFound = true;
		}
	});

	return techniqueFound;
};
