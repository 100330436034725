export class EmailDomainsValidator {
	private static getEmailDomain = (email: string): string => {
		const parts = email.split('@');

		return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : '';
	};

	static readonly isBlackListedEmail = (email: string, blacklistedEmailDomainsList: string[] = []): boolean => {
		const emailDomain = EmailDomainsValidator.getEmailDomain(email);

		return blacklistedEmailDomainsList.includes(emailDomain);
	};
}
