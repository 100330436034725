import { ReactNode, useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { Button } from '@/app/_common/_components';

import styles from './chip.module.scss';

interface ChipProps {
	children: ReactNode;
	value: string;
	onRemoveClick: (value: string) => void;
	disabled?: boolean;
}

export const Chip = ({ children, value, onRemoveClick, disabled }: ChipProps) => {
	const onRemove = useCallback(() => {
		onRemoveClick(value);
	}, [onRemoveClick, value]);

	return (
		<li className={styles.chip}>
			{!disabled && (
				<Button className={styles.removeButton} onClick={onRemove} disabled={disabled}>
					<CloseIcon className={styles.removeIcon} />
				</Button>
			)}
			<div className={styles.content}>{children}</div>
		</li>
	);
};
