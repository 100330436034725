import { useMemo } from 'react';

import { Alert, Investigation } from '@/generated/graphql';
import { ALERT_ACTIONS, AlertActionProps } from '@/app/_common/types';
import {
	areAlertsAssignable,
	areAlertsDismissable,
	areAlertsUnassignable,
	areAlertsUndismissable,
	isAlertAssignable,
	isAlertDismissable,
	isAlertUnassignable,
	isAlertUndismissable,
} from '@/app/dashboards/alerts-dashboard/_common/utils';
import { ActionLabel } from '@/app/_features/alerts-actions-menu/_components';

interface BulkActionsConfig {
	selectedAlerts: Alert[];
	focusedAlertNode?: Alert;
	onViewDetails: () => void;
	onAssignOrCreateSelectedInvestigationClick: (alerts: Alert[]) => void;
	onUnassignSelectedClick: (alerts: Alert[]) => void;
	onDismissSelectedClick: (alerts: Alert[]) => void;
	onUndismissSelectedClick: (alerts: Alert[]) => void;
}

export const useAlertsBulkActions = ({
	selectedAlerts,
	focusedAlertNode,
	onViewDetails,
	onAssignOrCreateSelectedInvestigationClick,
	onUnassignSelectedClick,
	onDismissSelectedClick,
	onUndismissSelectedClick,
}: BulkActionsConfig): AlertActionProps[] => {
	return useMemo<AlertActionProps[]>(() => {
		const assignableAlerts = selectedAlerts.filter((alert) => isAlertAssignable(alert));
		const undismissableAlerts = selectedAlerts.filter((alert) => isAlertUndismissable(alert));

		if (selectedAlerts.length <= 1) {
			return [];
		}

		return [
			{
				id: ALERT_ACTIONS.ViewDetails,
				show: Boolean(focusedAlertNode?.id),
				label: () => <ActionLabel action={ALERT_ACTIONS.ViewDetails} count={selectedAlerts.length} />,
				onClick: onViewDetails,
			},
			{
				id: ALERT_ACTIONS.AssignOrCreateInvestigation,
				show: areAlertsAssignable(focusedAlertNode, selectedAlerts),
				label: (count: number) => <ActionLabel action={ALERT_ACTIONS.AssignOrCreateInvestigation} count={count} />,
				alerts: assignableAlerts,
				onClick: onAssignOrCreateSelectedInvestigationClick,
			},
			{
				id: ALERT_ACTIONS.Unassign,
				show: (investigations: Investigation[]) => areAlertsUnassignable(focusedAlertNode, selectedAlerts, investigations),
				label: (count: number) => <ActionLabel action={ALERT_ACTIONS.Unassign} count={count} />,
				alerts: (investigations: Investigation[]) => selectedAlerts.filter((alert) => isAlertUnassignable(alert, investigations)),
				onClick: onUnassignSelectedClick,
			},
			{
				id: ALERT_ACTIONS.Dismiss,
				show: (investigations: Investigation[]) => areAlertsDismissable(focusedAlertNode, selectedAlerts, investigations),
				label: (count: number) => <ActionLabel action={ALERT_ACTIONS.Dismiss} count={count} />,
				alerts: (investigations: Investigation[]) => selectedAlerts.filter((alert) => isAlertDismissable(alert, investigations)),
				onClick: onDismissSelectedClick,
			},
			{
				id: ALERT_ACTIONS.Undismiss,
				show: areAlertsUndismissable(focusedAlertNode, selectedAlerts),
				label: () => <ActionLabel action={ALERT_ACTIONS.Undismiss} count={undismissableAlerts.length} />,
				alerts: undismissableAlerts,
				onClick: onUndismissSelectedClick,
			},
		];
	}, [
		focusedAlertNode,
		selectedAlerts,
		onAssignOrCreateSelectedInvestigationClick,
		onUnassignSelectedClick,
		onDismissSelectedClick,
		onUndismissSelectedClick,
		onViewDetails,
	]);
};
