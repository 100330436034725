import { TimelineItemData } from '../interface';
import { getTimelineDetails } from './get-timeline-details';
import { Signature } from '@/app/_common/constants/alert-details.constants';

export const prepareTimelineItems = (signatures: Signature[]): TimelineItemData[] => {
	return signatures
		.map(({ sessions, name, boost_score, silenced, key }, index) => {
			const { icon, order } = getTimelineDetails(boost_score, silenced);
			return {
				index,
				time: sessions[0].datetime,
				showDetails: false,
				icon: icon,
				name: name,
				left: 0,
				id: key,
				order: order,
			};
		})
		.sort((a, b) => Date.parse(a.time) - Date.parse(b.time));
};
