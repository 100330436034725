import { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { Warning } from '@mui/icons-material';
import { Popup } from '@progress/kendo-react-popup';

import { buttonize } from '@/app/_common/utils';
import { Divider } from '@/app/_common/_components';
import { useOutsideClick } from '@/app/_common/hooks';
import { ThreatIntelligenceDescription, ThreatIntelligenceMatches } from '@/app/_common/types';
import { ThreatIntelligencePanelHeader, ThreatIntelligencePanelContent } from './_components';

import styles from './threat-intelligence-icon.module.scss';

interface ThreatIntelligenceIconProps {
	matches: ThreatIntelligenceMatches;
	descriptions: ThreatIntelligenceDescription[];
	currentThreatIntelligenceIconIndex: number | null;
	cellId: string;
	cellValue: string;
	onThreatIntelligenceIconIndexChange: (newIndex: number | null) => void;
	expandTable: () => void;
}

export const ThreatIntelligenceIcon: FC<ThreatIntelligenceIconProps> = ({
	matches,
	descriptions,
	cellId,
	cellValue,
	currentThreatIntelligenceIconIndex,
	onThreatIntelligenceIconIndexChange,
	expandTable,
}) => {
	const matchesValues = Object.keys(matches);
	const totalMatches = matchesValues.length;

	const [showPanel, setShowPanel] = useState(false);

	const currentMatchIndex = matchesValues.indexOf(cellValue);
	const currentMatch = matches[cellValue];

	const isTheLastHitElement = currentMatchIndex + 1 === totalMatches;
	const isTheFirstHitElement = currentMatchIndex === 0;

	const scrollToThreatItelligenceCell = useCallback(() => {
		document.getElementById(cellId)?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
	}, [cellId]);

	const handleGoToNextMatch = () => {
		if (isTheLastHitElement) {
			return;
		}

		setShowPanel(false);
		expandTable();

		if (currentThreatIntelligenceIconIndex) {
			return onThreatIntelligenceIconIndexChange(currentThreatIntelligenceIconIndex + 1);
		}

		onThreatIntelligenceIconIndexChange(1);
	};

	const handleGoToPreviousHit = () => {
		if (isTheFirstHitElement) {
			return;
		}
		setShowPanel(false);
		expandTable();

		if (currentThreatIntelligenceIconIndex) {
			return onThreatIntelligenceIconIndexChange(currentThreatIntelligenceIconIndex - 1);
		}
		onThreatIntelligenceIconIndexChange(0);
	};

	const { popupRef, anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		showPanel,
		useCallback(() => setShowPanel(false), []),
	);

	const handleIconClick = () => {
		setShowPanel((showPanel) => !showPanel);
		scrollToThreatItelligenceCell();
	};

	const handleHidePopup = () => {
		setShowPanel(false);
	};

	useEffect(() => {
		if (currentThreatIntelligenceIconIndex === currentMatchIndex) {
			setShowPanel(true);
			scrollToThreatItelligenceCell();
		}
		return () => {
			onThreatIntelligenceIconIndexChange(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentThreatIntelligenceIconIndex, currentMatchIndex]);

	return (
		<div className={styles.container}>
			<div className={styles.value}>
				<span>{cellValue}</span>
				<div className={classNames(styles.iconButton, { [styles.active]: showPanel })} ref={anchorRef} {...buttonize(handleIconClick)}>
					<Warning htmlColor="var(--complementaryPurple)" />
				</div>
			</div>

			<Popup
				className={classNames(styles.popup)}
				show={showPanel}
				anchor={document.getElementById(cellId)}
				popupAlign={{ vertical: 'center', horizontal: 'left' }}
				anchorAlign={{ vertical: 'center', horizontal: 'right' }}
				collision={{ horizontal: 'flip', vertical: 'flip' }}
				animate={false}
			>
				<div className={styles.panelContainer} ref={popupRef}>
					<ThreatIntelligencePanelHeader
						handleGoToNextHit={handleGoToNextMatch}
						handleGoToPreviousHit={handleGoToPreviousHit}
						handleHidePopup={handleHidePopup}
						isTheFirstHitElement={isTheFirstHitElement}
						isTheLastHitElement={isTheLastHitElement}
						currentHitIndex={currentMatchIndex}
						totalMatches={totalMatches}
					/>
					<Divider />
					<ThreatIntelligencePanelContent hits={currentMatch} availableDescriptions={descriptions} />
				</div>
			</Popup>
		</div>
	);
};
