import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { UpdateNote } from '@/app/_common/graphql/queries/notes.queries';
import { Mutation, Query, MutationUpdateNoteArgs } from '@/generated/graphql';
import { action, makeObservable } from 'mobx';

type ListNotes = Pick<Query, 'listNotes'>;

export class InvestigationNotesUpdateDataStore extends GraphqlBaseDataStore<ListNotes> {
	constructor() {
		super();
		makeObservable(this, {
			update: action,
		});
	}

	async update(args: Omit<MutationUpdateNoteArgs, 'tenantId'>) {
		const variables = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationUpdateNoteArgs>({
			mutation: UpdateNote,
			variables,
		});

		return response;
	}
}
