import _isNil from 'lodash/isNil';

import { CsvExportData, CsvExportDataItemValue, CsvExportHeaders } from '@/app/_common/_components/csv-export/types';

const DOUBLE_QUOTES = '"';
const DOUBLE_DOUBLE_QUOTES = '""';

export const normalizeDataForCSVExport = (data: CsvExportData, headers: CsvExportHeaders) => {
	return data.reduce<CsvExportDataItemValue[][]>((acc, item) => {
		if (_isNil(item)) {
			return acc;
		}

		const row = headers.map((key) => {
			if (typeof item === 'string') {
				return `${item}`.replaceAll(DOUBLE_QUOTES, DOUBLE_DOUBLE_QUOTES);
			}

			if (typeof item[key] === 'string') {
				return `${item[key]}`.replaceAll(DOUBLE_QUOTES, DOUBLE_DOUBLE_QUOTES);
			}

			return item[key];
		});

		acc.push(row);

		return acc;
	}, []);
};
