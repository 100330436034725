import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Namespaces } from '@/translations/namespaces';
import { filterActionsByShow } from '@/app/_common/utils';
import { AlertState } from '@/generated/graphql';

import { AlertsListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-list';
import { AlertsStateActionsViewStore } from '@/app/dashboards/alerts-dashboard/_common/stores';
import { AlertsAssignDialogViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/alerts-assign-dialog.view-store';

import { ActionsCell, ActionsItem } from '@/app/_common/_components/data-grid/_components';

import type { GridCellProps } from '@progress/kendo-react-grid';
import type { CellProps } from '@progress/kendo-react-data-tools';

enum ACTIONS {
	AssignOrCreateInvestigation = 'assignOrCreateInvestigation',
	Dismiss = 'dismiss',
	Undismiss = 'undismiss',
}

export const AlertActionCell = observer((props: GridCellProps) => {
	const { t } = useTranslation([Namespaces.AlertsDashboard]);

	const store = useInstance(AlertsListViewStore);
	const alertStateActionStore = useInstance(AlertsStateActionsViewStore);
	const alertsAssignDialogStore = useInstance(AlertsAssignDialogViewStore);

	const rowActionItems: ActionsItem[] = [
		{
			id: ACTIONS.Dismiss,
			label: t(`singleAlert.${ACTIONS.Dismiss}`),
			show: (dataItem: CellProps['dataItem']) => {
				return dataItem.node.state?.alertState !== AlertState.Dismissed;
			},
			onClick: (dataItem: CellProps['dataItem']) => {
				const alertId = dataItem.node.id;

				if (store.selectedDismissedAlertsAssigned(dataItem)) {
					alertStateActionStore.open(alertId);
				} else {
					alertStateActionStore.dismissAlert(alertId);
				}
			},
		},
		{
			id: ACTIONS.Undismiss,
			label: t(`singleAlert.${ACTIONS.Undismiss}`),
			show: (dataItem: CellProps['dataItem']) => {
				return dataItem.node.state?.alertState === AlertState.Dismissed;
			},
			onClick: (dataItem: CellProps['dataItem']) => {
				alertStateActionStore.undismissAlert(dataItem.node.id);
			},
		},
		{
			id: ACTIONS.AssignOrCreateInvestigation,
			label: t(`singleAlert.${ACTIONS.AssignOrCreateInvestigation}`),
			show: (dataItem: CellProps['dataItem']) => {
				return dataItem.node.state?.alertState !== AlertState.Assigned;
			},
			onClick: (dataItem: CellProps['dataItem']) => {
				alertsAssignDialogStore.open([dataItem.node.id]);
			},
		},
	];

	const items = rowActionItems.filter((item) => filterActionsByShow(item, props.dataItem));

	return <ActionsCell {...props} items={items} oldContextMenu={false} />;
});
