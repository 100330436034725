import { FC, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { AVAILABLE_FIELDS_BLOCK_NAME, AvailableField } from '@/app/_common/types';
import { AvailableFieldsListItems } from './available-fields-list-items';
import { SkeletonListItem } from './skeleton-list-item';

import styles from './fields-list.module.scss';

interface FieldsListProps {
	blockName: AVAILABLE_FIELDS_BLOCK_NAME;
	loading: boolean;
	fields: AvailableField[];
	loaders: number;
}

export const FieldsList: FC<FieldsListProps> = ({ blockName, loading, fields, loaders }) => {
	const loadersIds = useMemo(() => [...Array(loaders)].map(() => uuid()), [loaders]);

	return (
		<ul className={styles.list}>
			{loading && (
				<>
					{loadersIds.map((id) => (
						<SkeletonListItem key={id} />
					))}
				</>
			)}

			{!loading && <AvailableFieldsListItems fields={fields} blockName={blockName} />}
		</ul>
	);
};
