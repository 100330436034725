import { makeAutoObservable } from 'mobx';
import { ThemeStore } from '@/app/_common/stores';
import { injectInterface } from '@/app/_common/ioc/inject-interface';

export class PageLayoutViewStore {
	private themeStore = injectInterface(this, ThemeStore);

	private state: State = {
		drawerOpened: true,
	};

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get drawerOpened() {
		return this.state.drawerOpened;
	}

	get theme() {
		return this.themeStore.theme;
	}

	openDrawer() {
		this.state.drawerOpened = true;
	}

	closeDrawer() {
		this.state.drawerOpened = false;
	}

	toggleTheme() {
		this.themeStore.toggle();
	}
}

interface State {
	drawerOpened: boolean;
}
