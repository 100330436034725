import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Namespaces } from '@/translations/namespaces';
import { SummarySectionItemTitle } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-section-item-title';
import { MitreAttackTree } from '@/app/_common/_components/mitre-attack-tree';
import { MitreAttackTactic } from '@/app/_common/_components/mitre-attack-tree/types';
import { TreeStyle } from '@/app/_common/_components/mitre-attack-tree/constants';

import styles from './mitre-attack-tree-box.module.scss';

interface MitreAttackTreeBoxProps {
	tactics: MitreAttackTactic[];
	treeStyle?: TreeStyle;
	className?: string;
}

export const MitreAttackTreeBox = ({ tactics, treeStyle = TreeStyle.REGULAR, className }: MitreAttackTreeBoxProps) => {
	const { t } = useTranslation(Namespaces.DetailsView);

	if (!Array.isArray(tactics) || !tactics.length) {
		return null;
	}

	return (
		<div className={classNames(styles.container, className)}>
			<SummarySectionItemTitle className={styles.title} title={t('summary.mitreAttackBox')} />
			<MitreAttackTree tactics={tactics} treeStyle={treeStyle} />
		</div>
	);
};
