import { ADX_FILTER_OPERATORS } from '@/app/_common/constants';
import { FieldFilterGroup } from '@/app/_common/types';

export const isFilterActive = (
	value: string | number,
	activeFilters: FieldFilterGroup[],
	operatorCheckCallback: (operator: ADX_FILTER_OPERATORS) => boolean,
): boolean => {
	const group = activeFilters.find((group) => operatorCheckCallback(group.operator));

	if (!group) {
		return false;
	}

	return group.values.some((v) => v === value);
};
