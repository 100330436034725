import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './simple-table.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SimpleTableItem = Record<string, any>;

export interface SimpleTableColumn<T, V> {
	prop: T;
	header: ReactNode;
	render?: (value: V) => ReactNode;
	minWidth?: boolean;
}

type ConvertItemTypeToColumn<T extends SimpleTableItem> = {
	[k in keyof T]: SimpleTableColumn<k, T[k]>;
}[keyof T];

export interface SimpleTableProps<T extends SimpleTableItem> {
	columns: ConvertItemTypeToColumn<T>[];
	items: T[];
	idProp: keyof T;
}

export const SimpleTable = <T extends SimpleTableItem>({ items, columns, idProp }: SimpleTableProps<T>) => {
	return (
		<table className={styles.simpleTable}>
			<thead>
				<tr>
					{columns.map(({ header, prop, minWidth }) => (
						<th key={prop as string} className={classNames({ [styles.minWidth]: minWidth })}>
							{header}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{items.map((item) => (
					<tr key={item[idProp] as string}>
						{columns.map(({ prop, minWidth, render }) => (
							<td key={prop as string} className={classNames({ [styles.minWidth]: minWidth })}>
								{render?.(item[prop] as T[string]) ?? (item[prop] as ReactNode)}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};
