import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';

import { SortDescriptor } from '@progress/kendo-data-query';

import { DataGridWithAPIViewStore } from '@/app/_common/_components/data-grid/data-grid-with-api.view-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { ArrayFilterOperators, EndpointsPropertiesPaths, FilterOperators, GetEndpointsTableHeadersCountTypename } from '@/app/_common/constants';
import { KendoServerSortAdapter, migrateToNewestVersion } from '@/app/_common/utils';
import { EndpointEdge, FieldSort, FieldValue, FiltersInput } from '@/generated/graphql';

import { EndpointsListDataStore } from '@/app/response/endpoints/_common/stores/endpoints-list.data-store';
import { EndpointsColumnCountersDataStore } from '@/app/response/endpoints/_common/stores/endpoints-column-counters.data-store';
import { ActiveType } from '@/app/_common/_components/data-grid/_components/data-header-cell/_common/constants';

const ENDPOINTS_LIST_PERSISTABLE_KEY = 'ls/response/endpoints';
const ENDPOINTS_LIST_VERSION = 'v3';
const ENDPOINTS_LIST_SESSION_KEY = 'ss/response/endpoints';

const INITIAL_COLUMNS = {
	[EndpointsPropertiesPaths.CurrentStatus]: true,
	[EndpointsPropertiesPaths.Hostname]: true,
	[EndpointsPropertiesPaths.IpAddress]: true,
	[EndpointsPropertiesPaths.OperatingSystem]: true,
	[EndpointsPropertiesPaths.EdrVendor]: true,
	[EndpointsPropertiesPaths.EdrProduct]: true,
	[EndpointsPropertiesPaths.ResponseIntegrationName]: true,
};

const INITIAL_SORT: SortDescriptor[] = [];

const DIALOG_INITIAL_STATE: DialogState = {
	isOpen: false,
	ids: [],
};

const INITIAL_STATE: State = {
	isolateDialog: DIALOG_INITIAL_STATE,
	deisolateDialog: DIALOG_INITIAL_STATE,
};

interface DialogState {
	isOpen: boolean;
	ids: string[];
}

interface State {
	isolateDialog: DialogState;
	deisolateDialog: DialogState;
}

export class EndpointsListViewStore extends DataGridWithAPIViewStore<EndpointEdge> {
	private dataStore = injectInterface(this, EndpointsListDataStore);
	private countersDataStore = injectInterface(this, EndpointsColumnCountersDataStore);

	private state: State = INITIAL_STATE;

	constructor() {
		super(EndpointsPropertiesPaths.Id, INITIAL_COLUMNS, INITIAL_SORT, undefined, true, ENDPOINTS_LIST_VERSION, true);

		migrateToNewestVersion(ENDPOINTS_LIST_PERSISTABLE_KEY, ENDPOINTS_LIST_VERSION);

		makeObservable(this, {
			// @ts-ignore - for protected/private fields
			state: observable,
			counters: computed,
			loading: computed,
			error: computed,
			isolateDialogIsOpen: computed,
			isolateDialogIds: computed,
			deisolateDialogIsOpen: computed,
			deisolateDialogIds: computed,
			readMore: action,
			openIsolateDialog: action,
			closeIsolateDialog: action,
			openDeisolateDialog: action,
			closeDeisolateDialog: action,
			setColumnFilter: action,
			clearFilters: action,
			hasNextPage: computed,
		});

		this.initializeSessionStorage(ENDPOINTS_LIST_SESSION_KEY)?.then(() => {
			this.read();
			this.countersDataStore.read(this.queryValueFilters);
		});

		makePersistable(this.gridState, {
			name: ENDPOINTS_LIST_PERSISTABLE_KEY,
			properties: ['columns', 'version'],
			storage: window.localStorage,
		});
	}

	get loading(): boolean {
		return this.dataStore.loading;
	}

	get error() {
		return this.dataStore.error;
	}

	get hasNextPage() {
		return this.dataStore.pageInfo?.hasNextPage;
	}

	get counters() {
		if (this.countersDataStore.counters?.__typename === GetEndpointsTableHeadersCountTypename.EndpointsTableHeadersCount) {
			return this.countersDataStore.counters;
		}

		return undefined;
	}

	get isolateDialogIsOpen() {
		return this.state.isolateDialog.isOpen;
	}

	get isolateDialogIds() {
		return this.state.isolateDialog.ids || [];
	}

	get deisolateDialogIsOpen() {
		return this.state.deisolateDialog.isOpen;
	}

	get deisolateDialogIds() {
		return this.state.deisolateDialog.ids || [];
	}

	get queryFieldSorters() {
		return this.sort.map((sorter) => KendoServerSortAdapter.mapFieldSort(sorter)).filter((sorter): sorter is FieldSort => sorter !== null);
	}

	getFieldActiveType = (field: FieldValue['field']): ActiveType | undefined => {
		const fieldFilters = this.getGridHeaderFiltersByField(this.filter.filters, field);

		if (fieldFilters.length === 0) {
			return undefined;
		}

		if (fieldFilters.every((filter) => filter.operator === FilterOperators.IsIn)) {
			return ActiveType.onlyIsIn;
		}

		if (fieldFilters.every((filter) => filter.operator === FilterOperators.IsNotIn)) {
			return ActiveType.onlyIsNotIn;
		}

		return ActiveType.mixed;
	};

	read = () => {
		const filtersInput: FiltersInput = {
			valueFilters: this.queryValueFilters,
		};

		const sortersInput = {
			fieldSorters: this.queryFieldSorters,
		};

		this.dataStore.read(filtersInput, sortersInput);
	};

	readMore = async (): Promise<void> => {
		const filtersInput: FiltersInput = {
			valueFilters: this.queryValueFilters,
		};

		const sortersInput = {
			fieldSorters: this.queryFieldSorters,
		};

		await this.dataStore.readMore(filtersInput, sortersInput);
	};

	openIsolateDialog = (ids: string[]) => {
		this.state.isolateDialog = {
			isOpen: true,
			ids,
		};
	};

	closeIsolateDialog = () => {
		this.state.isolateDialog = DIALOG_INITIAL_STATE;
	};

	openDeisolateDialog = (ids: string[]) => {
		this.state.deisolateDialog = {
			isOpen: true,
			ids,
		};
	};

	closeDeisolateDialog = () => {
		this.state.deisolateDialog = DIALOG_INITIAL_STATE;
	};

	setColumnFilter = (field: string, operator: ArrayFilterOperators, value: string) => {
		this.toggleGridHeaderArrayFilterValue({
			field,
			operator,
			value,
		});
	};

	clearFilters = (): void => {
		this.resetAllFilters();
		this.setQueryValueFilters([]);
	};

	filtersChangeDisposer = reaction(
		() => this.queryValueFilters,
		(valueFilters) => {
			this.resetPage();
			this.read();
			this.countersDataStore.read(valueFilters);
		},
	);

	fieldSortersChangeDisposer = reaction(
		() => this.queryFieldSorters,
		() => {
			this.resetPage();
			this.read();
		},
	);

	endpointsDisposer = reaction(
		() => this.dataStore.endpoints,
		(endpoints) => {
			this.sourceData = endpoints?.listEndpoints?.edges || [];
		},
	);

	dispose = () => {
		this.endpointsDisposer();
		this.filtersChangeDisposer();
		this.fieldSortersChangeDisposer();
		stopPersisting(this.gridState);
	};
}
