import { v4 as uuidv4 } from 'uuid';

import { getDetailsViewItemIdKeyByTypename, getDetailsViewTypename } from '@/app/_common/_components/details-view/utils';
import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { getResultsTableAlertId } from '@/app/_common/utils/get-results-table-alert-id';

export const generateItemId = <T>(result: Partial<T>): string => {
	const typename = getDetailsViewTypename(result);
	const isAlert = typename === DetailsViewTypename.ALERT;
	const idKey = getDetailsViewItemIdKeyByTypename(typename);
	const itemId = result[idKey as keyof T] ?? '';
	const alertId = getResultsTableAlertId(String(itemId));
	return isAlert ? String(alertId) : String(itemId || uuidv4());
};
