import { __rest } from 'tslib';

import { PageInfo } from '@/generated/graphql';

import { FieldPolicy, Reference } from '@apollo/client';
import { mergeDeep } from '@apollo/client/utilities';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KeyArgs = FieldPolicy<any>['keyArgs'];

type TRelayEdge<TNode> =
	| {
			cursor?: string;
			node: TNode;
	  }
	| (Reference & { cursor?: string });

type TExistingRelay<TNode> = Readonly<{
	edges: TRelayEdge<TNode>[];
	pageInfo: PageInfo;
}>;

type TIncomingRelay<TNode> = {
	edges?: TRelayEdge<TNode>[];
	pageInfo?: PageInfo;
};

type RelayFieldPolicy<TNode> = FieldPolicy<TExistingRelay<TNode> | null, TIncomingRelay<TNode> | null, TIncomingRelay<TNode> | null>;

type PrefixAndSuffix<TNode> = {
	prefix: TRelayEdge<TNode>[];
	suffix: TRelayEdge<TNode>[];
};

export function customRelayStylePagination<TNode = Reference>(keyArgs: KeyArgs = false): RelayFieldPolicy<TNode> {
	return {
		keyArgs,

		read(existing, { canRead, readField }) {
			if (!existing) return existing;

			const edges: TRelayEdge<TNode>[] = [];
			let firstEdgeCursor = '';
			let lastEdgeCursor = '';

			existing.edges.forEach((edge) => {
				if (canRead(readField('node', edge as Reference))) {
					edges.push(edge);

					if (edge.cursor) {
						firstEdgeCursor = firstEdgeCursor || edge.cursor || '';
						lastEdgeCursor = edge.cursor || lastEdgeCursor;
					}
				}
			});

			const { startCursor, endCursor } = existing.pageInfo || {};

			return {
				...getExtras(existing),
				edges,
				pageInfo: {
					...existing.pageInfo,
					startCursor: startCursor || firstEdgeCursor,
					endCursor: endCursor || lastEdgeCursor,
				},
			};
		},

		merge(existing, incoming, { args, isReference, readField }) {
			if (!existing) {
				existing = makeEmptyData();
			}

			if (!incoming) {
				return existing;
			}

			const incomingEdges = incoming.edges
				? incoming.edges.map((edge) => {
						edge = { ...edge };
						if (isReference(edge)) {
							edge.cursor = readField<string>('cursor', edge);
						}
						return edge;
				  })
				: [];

			if (incoming.pageInfo) {
				setCursors(incoming, incomingEdges);
			}

			const { prefix, suffix }: PrefixAndSuffix<TNode> = getPrefixAndSuffix(incoming, existing, args);

			const edges = prefix.concat(incomingEdges, suffix);

			const pageInfo: PageInfo = getPageInfo(incoming, existing, prefix, suffix);

			return {
				...getExtras(existing),
				...getExtras(incoming),
				edges,
				pageInfo,
			};
		},
	};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getExtras = (obj: Record<string, any>) => __rest(obj, notExtras);
const notExtras = ['edges', 'pageInfo'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function makeEmptyData(): TExistingRelay<any> {
	return {
		edges: [],
		pageInfo: {
			hasPreviousPage: false,
			hasNextPage: true,
			startCursor: '',
			endCursor: '',
		},
	};
}

function setCursors<TNode>(incoming: TIncomingRelay<TNode>, incomingEdges: TRelayEdge<TNode>[]): TIncomingRelay<TNode> {
	if (!incoming.pageInfo) {
		return incoming;
	}
	const { pageInfo } = incoming;
	const { startCursor, endCursor } = pageInfo;
	const firstEdge = incomingEdges[0];
	const lastEdge = incomingEdges[incomingEdges.length - 1];

	if (firstEdge && startCursor) {
		firstEdge.cursor = startCursor;
	}
	if (lastEdge && endCursor) {
		lastEdge.cursor = endCursor;
	}

	const firstCursor = firstEdge && firstEdge.cursor;
	if (firstCursor && !startCursor) {
		incoming = mergeDeep(incoming, {
			pageInfo: {
				startCursor: firstCursor,
			},
		});
	}
	const lastCursor = lastEdge && lastEdge.cursor;
	if (lastCursor && !endCursor) {
		incoming = mergeDeep(incoming, {
			pageInfo: {
				endCursor: lastCursor,
			},
		});
	}
	return incoming;
}

function getPrefixAndSuffix<TNode>(
	incoming: TIncomingRelay<TNode>,
	existing: TExistingRelay<TNode>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	args: Record<string, any> | null,
): PrefixAndSuffix<TNode> {
	let prefix = existing.edges;
	let suffix: typeof prefix = [];

	if (args?.input?.after) {
		const index = prefix.findIndex((edge) => edge.cursor === args.input.after);

		if (index >= 0) {
			prefix = prefix.slice(0, index + 1);
		}
	} else if (args?.input?.before) {
		const index = prefix.findIndex((edge) => edge.cursor === args.input.before);
		suffix = index < 0 ? prefix : prefix.slice(index);
		prefix = [];
	} else if (incoming.edges) {
		prefix = [];
	}

	return {
		prefix,
		suffix,
	};
}

function getPageInfo<TNode>(
	incoming: TIncomingRelay<TNode>,
	existing: TExistingRelay<TNode>,
	prefix: TRelayEdge<TNode>[],
	suffix: TRelayEdge<TNode>[],
): PageInfo {
	const pageInfo: PageInfo = {
		...incoming.pageInfo,
		...existing.pageInfo,
	};
	if (!incoming.pageInfo) {
		return pageInfo;
	}

	const { hasPreviousPage, hasNextPage, startCursor, endCursor, ...extras } = incoming.pageInfo;

	Object.assign(pageInfo, extras);

	if (!prefix.length) {
		if (undefined !== hasPreviousPage) pageInfo.hasPreviousPage = hasPreviousPage;
		if (undefined !== startCursor) pageInfo.startCursor = startCursor;
	}
	if (!suffix.length) {
		if (undefined !== hasNextPage) pageInfo.hasNextPage = hasNextPage;
		if (undefined !== endCursor) pageInfo.endCursor = endCursor;
	}
	return pageInfo;
}
