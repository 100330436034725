import { FC, ReactNode, useCallback, useMemo } from 'react';

import classNames from 'classnames';
import _uniqueId from 'lodash/uniqueId';

import { Skeleton } from '@progress/kendo-react-indicators';

import { buttonize } from '@/app/_common/utils';
import { Tooltip } from '@/app/_common/_components';

import styles from './context-menu-item.module.scss';

interface ContextMenuItemProps {
	children?: ReactNode;
	disabled?: boolean;
	loading?: boolean;
	disabledTooltip?: string;
	onClick?: () => void;
}

export const ContextMenuItem: FC<ContextMenuItemProps> = ({ children, disabled, disabledTooltip, loading, onClick }) => {
	const tooltipId = useMemo(() => _uniqueId(), []);
	const handleClick = useCallback(() => {
		if (!disabled && onClick) {
			onClick();
		}
	}, [disabled, onClick]);

	const className = classNames(styles.contextMenuItem, { [styles.disabled]: disabled, [styles.loading]: loading });

	return (
		<>
			{disabled && <Tooltip tooltipId={tooltipId} tooltipContent={disabledTooltip} />}
			<li data-tip={true} data-for={tooltipId} className={className} {...buttonize<HTMLLIElement>(handleClick)}>
				{loading ? <Skeleton className={styles.loadingSkeleton} shape="text" /> : children}
			</li>
		</>
	);
};
