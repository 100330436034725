import { JSX, ReactNode } from 'react';

import { GridCellProps } from '@progress/kendo-react-grid';

import { ContextMenuComponentProps } from '@/app/_common/types';
import { DataCell } from '@/app/_common/_components/data-grid/_components';

interface BaseCellProps extends GridCellProps {
	hasValue: boolean;
	displayValues: string | number | JSX.Element | JSX.Element[];
	cellTitle: string;
	defaultTitle: string;
	defaultMessage?: string;
	customClassnames?: string;
	className?: string;
	onClick?: () => void;
	ContextMenuComponent?: React.FC<ContextMenuComponentProps>;
	Icon?: ReactNode;
	iconClassName?: string;
	oldContextMenu?: boolean;
}

export const BaseCell = ({
	hasValue,
	cellTitle,
	defaultTitle,
	displayValues,
	dataItem,
	defaultMessage,
	customClassnames,
	className,
	onClick,
	ContextMenuComponent,
	Icon,
	iconClassName,
	...rest
}: BaseCellProps) => {
	if (hasValue) {
		return (
			<DataCell
				{...rest}
				onClick={onClick}
				title={cellTitle}
				className={customClassnames}
				dataItem={dataItem}
				ContextMenuComponent={ContextMenuComponent}
			>
				{Icon && <span className={iconClassName}>{Icon} </span>}
				{displayValues}
			</DataCell>
		);
	}

	if (defaultMessage) {
		return (
			<DataCell
				{...rest}
				onClick={onClick}
				dataItem={dataItem}
				title={defaultTitle}
				className={className}
				ContextMenuComponent={ContextMenuComponent}
			>
				{defaultMessage}
			</DataCell>
		);
	}

	return (
		<DataCell
			{...rest}
			onClick={onClick}
			dataItem={dataItem}
			title={defaultTitle}
			ContextMenuComponent={ContextMenuComponent}
			className={className}
			empty={true}
		/>
	);
};
