import { Descendant } from 'slate';
import _get from 'lodash/get';

import { EditorType, countList, ChildrenProps } from '@/app/_common/_components/rich-text-editor';

const countArrayChildrenCharacters = (children: ChildrenProps[]): number => {
	let count = 0;
	children.forEach((child) => {
		if (child.children) {
			child.children.forEach((content) => {
				count += countList(content);
			});
			return;
		}

		if (child.text) {
			count += child.text.length;
		}
	});
	return count;
};

const countTextLength = (text: string | undefined): number => (text ? text.length : 0);

const processChildrenTextLength = (children: ChildrenProps[] | undefined): number => {
	return children ? children.reduce((acc, content) => acc + countTextLength(content?.text), 0) : 0;
};

const processContentChildren = (children: ChildrenProps[]): number => {
	let count = 0;

	children.forEach(({ text, type, children: contentChildren }) => {
		if ([EditorType.Link, EditorType.LIST_ITEM].includes(type as EditorType)) {
			count += processChildrenTextLength(contentChildren);
		}

		if (contentChildren) {
			count += countTextLength(contentChildren[0]?.text);
		}

		count += countTextLength(text);
	});

	return count;
};

const countTableChildrenCharacters = (children: ChildrenProps[]): number => {
	let count = 0;

	children.forEach((row) => {
		row.children.forEach((cell) => {
			cell.children.forEach((content) => {
				const { children: contentChildren = [], text: contentText = '' } = content;
				count += processContentChildren(contentChildren);
				count += countTextLength(contentText);
			});
		});
	});

	return count;
};

export const countCharacters = (value: Descendant[]) => {
	let count = 0;

	value.forEach((state) => {
		const children = _get(state, 'children') as ChildrenProps[];

		const type = _get(state, 'type');

		if (type === EditorType.Table) {
			const tableChildrenCount = countTableChildrenCharacters(children);
			count += tableChildrenCount;
			return;
		}

		if (Array.isArray(children)) {
			const arrayChildrenCount = countArrayChildrenCharacters(children);
			count += arrayChildrenCount;
		}
	});

	return count;
};
