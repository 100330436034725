import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import _get from 'lodash/get';

import { GridRowProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';

import { MIN_COLUMN_WIDTH } from '@/app/_common/constants';
import { ACTIONS_COLUMN_WIDTH, useDataGridHeight, useDataGridInfiniteScroll, useDataGridWidth } from '@/app/_common/hooks';
import { Filters, WidthsState } from '@/app/_common/types';
import { Column, DataGrid, WidgetContainer } from '@/app/_common/_components';
import {
	ActionsHeaderCell,
	ActionsHeaderItem,
	DataHeaderCell,
	DateTimeCell,
	TrimTextCell,
	MultiLineCell,
} from '@/app/_common/_components/data-grid/_components';
import { ActionHistoryDataGridPropertyPaths, ActionHistoryHeadersTranslations } from '@/app/_features/action-history/_common/constants';
import { ActionHistoryGridRow } from './action-history-data-grid-row';
import { PLACEHOLDER_COLUMN } from '@/app/_common/_components/data-grid/constants';
import { ActionHistoryActions, ActionHistoryCategory } from '@/app/_features/action-history/_common/types';
import { getActionHistoryCategoryLabel, getActionHistoryActionLabel } from '@/app/_features/action-history/_common/utils';
import { filterActionsByShow, createStorageDetails } from '@/app/_common/utils';
import { ActionHistoryDataGridViewStore, ActionHistorySystemActionsSwitchStore } from '@/app/_features/action-history/_common/stores';
import { ActionHistoryDataGridHeader } from './_components';
import { ActionHistoryEdge } from '@/generated/graphql';
import { getResetFiltersMenuItem, setDefaultWidthToVisibleColumns } from '@/app/_common/_components/data-grid/utils';
import { AuthStore } from '@/app/_common/stores';

const COLUMNS_LOCAL_STORAGE_KEY = 'maintenance/action-history-data-grid/columns-width';
const ACTIONS_COLUMN_NAME = 'actions';

const INITIAL_COLUMNS_WIDTHS_VERSION = 'v0'; // change it if you change the content of `INITIAL_WIDTHS` constant
const INITIAL_WIDTHS = {
	[ACTIONS_COLUMN_NAME]: '70px',
};

const ROW_HEIGHT = 32;

interface ActionHistoryDataGridProps {
	isFranchise: boolean;
}

export const ActionHistoryDataGrid = observer(({ isFranchise }: ActionHistoryDataGridProps) => {
	const authStore = useInstance(AuthStore);
	const store = useInstance(ActionHistoryDataGridViewStore);
	const systemActionsSwitchStore = useInstance(ActionHistorySystemActionsSwitchStore);
	const { showSystemActions } = systemActionsSwitchStore;

	const { height } = useDataGridHeight();

	const { widths, setWidths, ref, measuredRef } = useDataGridWidth<WidthsState>(
		{ ...createStorageDetails('localStorage', COLUMNS_LOCAL_STORAGE_KEY, INITIAL_COLUMNS_WIDTHS_VERSION), nestedObjectKey: 'columns-width' },
		setDefaultWidthToVisibleColumns(INITIAL_WIDTHS, store.columns),
		store.columns,
	);

	const rowRender = (trElement: ReactElement<HTMLTableRowElement>, props: GridRowProps): ReactNode => {
		return <ActionHistoryGridRow trElement={trElement} {...props} widths={widths} />;
	};

	const handleSetHeaderFilter = useCallback(
		(field: string, values: Filters) => {
			store.setGridHeaderFilter(field, values);
			store.submitQueryValueFilters();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[store.setGridHeaderFilter, store.submitQueryValueFilters],
	);

	const handleClearFilter = useCallback(
		(field: string): void => {
			store.resetGridHeaderFilter(field);
			store.submitQueryValueFilters();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[store.resetGridHeaderFilter, store.submitQueryValueFilters],
	);

	const onSortChange = useCallback(
		(event: GridSortChangeEvent) => {
			store.setSort(event.sort);
		},

		[store],
	);

	const headerActionItems: ActionsHeaderItem[] = [
		{
			...getResetFiltersMenuItem({
				disabled: !store.areFiltersActive,
				resetAllFilters: store.clearFilters,
			}),
			show: true,
		},
	];

	/*
	 *	Action history grid data is also manipulated with Show System Actions switch.
	 *	There can be cases when user filters some grid data and then hides them by switch.
	 * 	The header cell filter is not clickable then and filter cannot be reset. Thus resetting filters.
	 *  */
	useEffect(() => {
		store.resetAllFilters();
		store.submitQueryValueFilters();
	}, [showSystemActions, store]);

	const { paginatedData, handlePageChange, dataLength } = useDataGridInfiniteScroll<ActionHistoryEdge>({
		isLoading: store.isLoading,
		hasNextPage: store.hasNextPage,
		pageSize: store.pageSize,
		readMore: store.readMore,
		data: store.data,
		setPage: store.setPage,
		pageSkip: store.pageSkip,
	});

	const COLUMNS: Column[] = [
		{
			id: ActionHistoryDataGridPropertyPaths.Date,
			field: ActionHistoryDataGridPropertyPaths.Date,
			title: ActionHistoryHeadersTranslations[ActionHistoryDataGridPropertyPaths.Date],
			width: widths[ActionHistoryDataGridPropertyPaths.Date],
			minResizableWidth: MIN_COLUMN_WIDTH,
			headerCell: observer((props) => <DataHeaderCell {...props} sortable={true} sort={store.sort} onSort={store.setSort} />),
			cell: (props) => <DateTimeCell {...props} />,
		},
		{
			id: ActionHistoryDataGridPropertyPaths.User,
			field: ActionHistoryDataGridPropertyPaths.User,
			title: ActionHistoryHeadersTranslations[ActionHistoryDataGridPropertyPaths.User],
			width: widths[ActionHistoryDataGridPropertyPaths.User],
			minResizableWidth: MIN_COLUMN_WIDTH,
			headerCell: observer((props) => (
				<DataHeaderCell
					{...props}
					counter={store.getCounter(ActionHistoryDataGridPropertyPaths.User) || undefined}
					sortable={true}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleSetHeaderFilter}
					filter={store.getGridHeaderFilterValues(ActionHistoryDataGridPropertyPaths.User)}
					filterOptions={store.getFilterOptions(ActionHistoryDataGridPropertyPaths.User)}
					clearFilter={handleClearFilter}
					filterable={false}
				/>
			)),
			cell: (props) => {
				const requesterUserGetter = getter(ActionHistoryDataGridPropertyPaths.User);
				const cellTitle = requesterUserGetter(props.dataItem) ?? authStore.franchiseName;

				return <TrimTextCell {...props} title={cellTitle} />;
			},
		},
		{
			id: ActionHistoryDataGridPropertyPaths.Category,
			field: ActionHistoryDataGridPropertyPaths.Category,
			title: ActionHistoryHeadersTranslations[ActionHistoryDataGridPropertyPaths.Category],
			width: widths[ActionHistoryDataGridPropertyPaths.Category],
			minResizableWidth: MIN_COLUMN_WIDTH,
			headerCell: observer((props) => (
				<DataHeaderCell
					{...props}
					counter={store.getCounter(ActionHistoryDataGridPropertyPaths.Category)}
					sortable={true}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleSetHeaderFilter}
					filter={store.getGridHeaderFilterValues(ActionHistoryDataGridPropertyPaths.Category)}
					filterOptions={store.getFilterOptions(ActionHistoryDataGridPropertyPaths.Category)}
					clearFilter={handleClearFilter}
				/>
			)),
			cell: (props) => {
				const { dataItem } = props;
				const category: ActionHistoryCategory = _get(dataItem.node, 'resourceType');
				const categoryLabel = getActionHistoryCategoryLabel(category);

				return <TrimTextCell {...props} title={categoryLabel} />;
			},
		},
		{
			id: ActionHistoryDataGridPropertyPaths.Action,
			field: ActionHistoryDataGridPropertyPaths.Action,
			title: ActionHistoryHeadersTranslations[ActionHistoryDataGridPropertyPaths.Action],
			width: widths[ActionHistoryDataGridPropertyPaths.Action],
			minResizableWidth: MIN_COLUMN_WIDTH,
			headerCell: observer((props) => (
				<DataHeaderCell
					{...props}
					counter={store.getCounter(ActionHistoryDataGridPropertyPaths.Action)}
					sortable={true}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleSetHeaderFilter}
					filter={store.getGridHeaderFilterValues(ActionHistoryDataGridPropertyPaths.Action)}
					filterOptions={store.getFilterOptions(ActionHistoryDataGridPropertyPaths.Action)}
					clearFilter={handleClearFilter}
				/>
			)),
			cell: (props) => {
				const { dataItem } = props;
				const action: ActionHistoryActions = _get(dataItem.node, 'action');
				const actionLabel = getActionHistoryActionLabel(action);

				return <TrimTextCell {...props} title={actionLabel} />;
			},
		},
		{
			id: ActionHistoryDataGridPropertyPaths.CorrelationId,
			field: ActionHistoryDataGridPropertyPaths.CorrelationId,
			title: ActionHistoryHeadersTranslations[ActionHistoryDataGridPropertyPaths.CorrelationId],
			width: widths[ActionHistoryDataGridPropertyPaths.CorrelationId],
			minResizableWidth: MIN_COLUMN_WIDTH,
			headerCell: observer((props) => (
				<DataHeaderCell
					{...props}
					counter={store.getCounter(ActionHistoryDataGridPropertyPaths.CorrelationId)}
					sortable={false}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleSetHeaderFilter}
					filter={store.getGridHeaderFilterValues(ActionHistoryDataGridPropertyPaths.CorrelationId)}
					filterOptions={store.getFilterOptions(ActionHistoryDataGridPropertyPaths.CorrelationId)}
					clearFilter={handleClearFilter}
				/>
			)),
			cell: (props) => <TrimTextCell {...props} />,
		},
		{
			id: ActionHistoryDataGridPropertyPaths.Description,
			field: ActionHistoryDataGridPropertyPaths.Description,
			title: ActionHistoryHeadersTranslations[ActionHistoryDataGridPropertyPaths.Description],
			width: widths[ActionHistoryDataGridPropertyPaths.Description],
			minResizableWidth: MIN_COLUMN_WIDTH,
			headerCell: observer((props) => <DataHeaderCell {...props} sortable={true} sort={store.sort} onSort={store.setSort} />),
			cell: (props) => <MultiLineCell {...props} />,
		},
		/* A column that shrinks or expands to fill in the remaining space */
		PLACEHOLDER_COLUMN,
		{
			id: ACTIONS_COLUMN_NAME,
			width: widths[ACTIONS_COLUMN_WIDTH],
			cell: () => <td />,
			headerCell: observer((props) => {
				const items = headerActionItems.filter((item: ActionsHeaderItem) => filterActionsByShow(item));

				return <ActionsHeaderCell {...props} items={items} />;
			}),
			resizable: false,
			locked: true,
		},
	];

	return (
		<>
			<ActionHistoryDataGridHeader showActionSourceSelect={!isFranchise} />
			<WidgetContainer loading={store.isLoading}>
				<DataGrid
					data-testid="action-history-grid"
					columns={COLUMNS}
					gridProps={{
						data: paginatedData,
						fixedScroll: true,
						sort: store.sort,
						sortable: true,
						rowRender: rowRender,
						resizable: true,
						onSortChange,
						onColumnResize: setWidths,
						onPageChange: handlePageChange,
						skip: store.pageSkip,
						total: dataLength,
						pageSize: store.pageSize,
						style: { height },
						rowHeight: ROW_HEIGHT,
						scrollable: 'virtual',
					}}
					gridRef={ref}
					measuredRef={measuredRef}
					height={height}
				/>
			</WidgetContainer>
		</>
	);
});
