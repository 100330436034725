import { Themes } from '@/app/_common/types';
import { THEMES } from '@/app/_common/constants/theme';

export const getChartGraphStackColors = ({
	showStackedBars,
	themeVariables,
	useStackedBarsTotalCountKey,
}: {
	showStackedBars: boolean;
	themeVariables: Record<keyof (typeof THEMES)[Themes.Light], string>;
	useStackedBarsTotalCountKey: boolean;
}): string[] => {
	const singleBarStackColor = [themeVariables.advancedQueryChartSingleBarStackBgColor];

	if (showStackedBars) {
		// Order matters! From bottom to top like for keys.
		return [
			themeVariables.advancedQueryStackedChartEventsBarBgColor,
			themeVariables.advancedQueryStackedChartEvidenceBarBgColor,
			themeVariables.advancedQueryStackedChartAlertsBarBgColor,
		];
	}

	if (useStackedBarsTotalCountKey) {
		return singleBarStackColor;
	}

	return singleBarStackColor;
};
