import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { ExpansionPanelWrapper } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components';
import { Namespaces } from '@/translations/namespaces';
import { DetailsViewItemData, DetailsViewItemDataPropertyPaths } from '@/app/_common/_components/details-view/types';
import { formatDataForSyntaxHighlighter } from '@/app/_common/utils';
import { DetailsViewNavigationFields, RawField } from '@/app/_common/_components/details-view/components';

import styles from './base-details-view-navigation-content.module.scss';

interface BaseDetailsViewNavigationContentProps {
	selectedItem: DetailsViewItemData;
	itemId: string;
}

export const BaseDetailsViewNavigationContent = ({ selectedItem, itemId }: BaseDetailsViewNavigationContentProps) => {
	const { t } = useTranslation([Namespaces.DetailsView], { keyPrefix: 'expansionPanel' });

	const parsedValue = useMemo(() => {
		if (!selectedItem) {
			return '';
		}

		const rawFieldValue = DetailsViewItemDataPropertyPaths.Raw in selectedItem ? selectedItem[DetailsViewItemDataPropertyPaths.Raw] : null;
		const alertRawFieldValue =
			DetailsViewItemDataPropertyPaths.AlertRaw in selectedItem ? selectedItem[DetailsViewItemDataPropertyPaths.AlertRaw] : null;

		return formatDataForSyntaxHighlighter(rawFieldValue || alertRawFieldValue);
	}, [selectedItem]);

	if (!selectedItem) {
		return null;
	}

	return (
		<div className={styles.container}>
			<ExpansionPanelWrapper title={t('details')} expanded={true} fullWidth className={styles.details}>
				<DetailsViewNavigationFields itemData={selectedItem} />
			</ExpansionPanelWrapper>

			<ExpansionPanelWrapper title={t('raw')} expanded={false} fullWidth className={styles.raw}>
				<RawField itemId={itemId} value={parsedValue} />
			</ExpansionPanelWrapper>
		</div>
	);
};

export default BaseDetailsViewNavigationContent;
