import { ApolloError } from '@apollo/client';
import { ICustomProperties, ITraceTelemetry, SeverityLevel } from '@microsoft/applicationinsights-web';

import { User } from '@/generated/graphql';
import { getAppInsights } from '@/app-insights/app-insights';

export class InsightsTraceBuilder {
	public static createTrackTraceWarning(action: string, warning?: Error | ApolloError, upn?: User['upn']): void {
		const trackTraceWarning = {
			message: `${action} / Warning message: ${warning?.message}`,
			severityLevel: SeverityLevel.Warning,
		};

		InsightsTraceBuilder.createTrackTrace(trackTraceWarning, { ...warning, upn });
	}

	public static createTrackTraceError(action: string, error?: Error | ApolloError, upn?: User['upn']): void {
		const trackTraceError = {
			message: `${action} / Error message: ${error?.message}`,
			severityLevel: SeverityLevel.Error,
		};

		InsightsTraceBuilder.createTrackTrace(trackTraceError, { ...error, upn });
	}

	private static createTrackTrace(trace: ITraceTelemetry, customProperties?: ICustomProperties): void {
		const insights = getAppInsights();

		insights?.trackTrace(trace, customProperties);
	}
}
