import { makeAutoObservable, reaction } from 'mobx';

import userflow from 'userflow.js';

import { injectInterface } from '@/app/_common/ioc/inject-interface';

import { AuthStore, ThemeStore, UserflowSignatureViewStore } from '@/app/_common/stores';

const USERFLOW_TOKEN = process.env.REACT_APP_USERFLOW_TOKEN as string;

interface State {
	initialized: boolean;
	isUserIdentified: boolean;
}

const INITIAL_APP_STATE: State = {
	initialized: false,
	isUserIdentified: false,
};

export class UserflowStore {
	private state: State = INITIAL_APP_STATE;

	private authStore = injectInterface(this, AuthStore);
	private userflowSignatureStore = injectInterface(this, UserflowSignatureViewStore);
	private themeStore = injectInterface(this, ThemeStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		this.initialize();
	}

	get isUserIdentified() {
		return this.state.isUserIdentified;
	}

	initialize = () => {
		if (this.state.initialized) {
			return;
		}

		userflow.init(USERFLOW_TOKEN);

		this.state.initialized = true;
	};

	identifyUser = () => {
		if (this.isUserIdentified) {
			return;
		}

		const { id, upn, displayName, firstName, lastName } = this.authStore.user;

		userflow
			.identify(
				id,
				{
					name: displayName || upn,
					firstName: firstName,
					lastName: lastName,
					email: upn,
					signed_up_at: new Date().toISOString(),
					theme: this.themeStore.theme,
				},
				{
					signature: this.userflowSignatureStore.userSignature,
				},
			)
			.then(() => {
				this.state.isUserIdentified = true;

				this.identifyGroup();
			});
	};

	identifyGroup = () => {
		const { name } = this.authStore.currentTenant;

		userflow.group(
			this.authStore.currentTenantId,
			{
				name,
			},
			{
				signature: this.userflowSignatureStore.tenantSignature,
			},
		);
	};

	stop = () => {
		userflow.reset();
		this.state.isUserIdentified = false;
	};

	authTokenDisposer = reaction(
		() => this.authStore.token,
		(token) => {
			if (token) {
				this.userflowSignatureStore.readSignature();
			}
		},
	);

	userSignatureDisposer = reaction(
		() => this.userflowSignatureStore.userSignature,
		(signature) => {
			if (signature) {
				this.identifyUser();
			}
		},
	);

	tenantSignatureDisposer = reaction(
		() => this.userflowSignatureStore.tenantSignature,
		(signature, oldSignature) => {
			if (signature && oldSignature) {
				this.identifyUser();
			}
		},
	);

	logoutDisposer = reaction(
		() => this.authStore.logOut,
		(logout) => {
			if (logout) {
				this.stop();
			}
		},
	);

	tenantDisposer = reaction(
		() => this.authStore.currentTenantId,
		(tenant, oldTenant) => {
			if (oldTenant) {
				this.stop();
				this.userflowSignatureStore.readSignature();
			}
		},
	);

	themeDisposer = reaction(
		() => this.themeStore.theme,
		(theme, oldTheme) => {
			if (oldTheme && this.isUserIdentified) {
				this.stop();
				this.identifyUser();
			}
		},
	);
}
