import { makeAutoObservable } from 'mobx';

interface State {
	isOpen: boolean;
	openingBannerInProgress: boolean;
	onClick: () => void;
}

export interface BannerConfig {
	onClick: () => void;
}

export class NotificationStickyBannerStore {
	private state: State = {
		isOpen: false,
		openingBannerInProgress: false,
		onClick: () => {
			return;
		},
	};

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get isOpen() {
		return this.state.isOpen;
	}

	get openingBannerInProgress() {
		return this.state.openingBannerInProgress;
	}

	showBanner = (bannerConfig: BannerConfig, delay: number) => {
		if (this.state.isOpen || this.state.openingBannerInProgress) {
			return;
		}
		this.state.openingBannerInProgress = true;
		setTimeout(() => {
			if (this.state.openingBannerInProgress === false) {
				return;
			}
			this.state.onClick = bannerConfig.onClick;
			this.state.isOpen = true;
			this.state.openingBannerInProgress = false;
		}, delay);
	};

	stopOpeningBanner = () => {
		this.state.openingBannerInProgress = false;
	};

	hideBanner() {
		this.state.isOpen = false;
	}

	handleClick() {
		this.state.onClick();
	}
}
