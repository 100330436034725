import { Severity } from '@/generated/graphql';

import { AvailableField, AlertSpecialFieldNames } from '@/app/_common/types';
import { ColorIndicator, ColorIndicatorWithValueWrapper } from '@/app/_common/_components';
import { getConfidenceColorIndicator, getSeverityColorIndicator } from '@/app/_common/utils/colors';
import { AlertConfidence } from '@/app/_common/constants';
import { getConfidenceLabel, getSeverityLabel } from '@/app/_common/utils';
import { getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';
import { MitreAttackBadge } from '@/app/_common/_components/mitre-attack-badge';

export const FilterPanelListItemRenderer = (item: AvailableField, fieldName?: string): JSX.Element => {
	const fieldNameToCompare = fieldName?.toLowerCase();

	if (fieldNameToCompare === AlertSpecialFieldNames.Confidence) {
		return (
			<ColorIndicatorWithValueWrapper>
				<ColorIndicator bulletsConfig={getConfidenceColorIndicator(item.value as AlertConfidence)} /> <span>{getConfidenceLabel(item.value)}</span>
			</ColorIndicatorWithValueWrapper>
		);
	}

	if (fieldNameToCompare === AlertSpecialFieldNames.Severity) {
		return (
			<ColorIndicatorWithValueWrapper>
				<ColorIndicator bulletsConfig={getSeverityColorIndicator(item.value as Severity)} />
				<span>{getSeverityLabel(item.value)}</span>
			</ColorIndicatorWithValueWrapper>
		);
	}

	if (fieldNameToCompare === AlertSpecialFieldNames.MitreTactics) {
		const { value } = item;
		const mitreText = getMitreAttackBadgeText(String(value));
		return <MitreAttackBadge text={mitreText} hideTooltip />;
	}

	if (fieldNameToCompare === AlertSpecialFieldNames.MitreTechniques) {
		const { value } = item;
		const mitreText = getMitreAttackBadgeText(String(value));
		return <span>{mitreText}</span>;
	}

	return <span>{item.value}</span>;
};
