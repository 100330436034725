import { FieldFilterGroup, FieldFilterValue } from '@/app/_common/types';

export const removeFilterValue = (value: FieldFilterValue, index: number, groups: FieldFilterGroup[]) => {
	const group = groups[index];
	const newValues = group.values.filter((v) => v !== value);

	if (newValues.length === 0) {
		return groups.filter((_, i) => i !== index);
	} else {
		const newGroup = {
			...group,
			values: newValues,
		};

		return [...groups.slice(0, index), newGroup, ...groups.slice(index + 1)];
	}
};
