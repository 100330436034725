import { ClipboardEvent } from 'react';
import { CustomEditor } from '@/app/_common/_components/rich-text-editor';

export const clipboardPasteAction = (e: ClipboardEvent<HTMLDivElement>, editor: CustomEditor) => {
	const { selection, insertText } = editor;

	if (!selection) {
		return;
	}

	e.preventDefault();
	const pasteData = e.clipboardData.getData('text');
	insertText(pasteData);
};
