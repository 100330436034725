import { DateTimePickerOptionKeys } from '@/app/_common/constants';
import moment from 'moment';

export interface DateRangePickerValue {
	start?: string;
	end?: string;
}

export const getTimeRange = (key: DateTimePickerOptionKeys, formatter: (date: moment.Moment) => string): DateRangePickerValue | null => {
	let subtractValue: moment.DurationInputArg1 | undefined;
	let subtractUnit: moment.unitOfTime.DurationConstructor | undefined;

	switch (key) {
		case DateTimePickerOptionKeys.ONE_DAY:
			subtractValue = 1;
			subtractUnit = 'days';
			break;
		case DateTimePickerOptionKeys.THREE_DAYS:
			subtractValue = 3;
			subtractUnit = 'days';
			break;
		case DateTimePickerOptionKeys.ONE_WEEK:
			subtractValue = 7;
			subtractUnit = 'days';
			break;
		case DateTimePickerOptionKeys.ONE_MONTH:
			subtractValue = 30;
			subtractUnit = 'days';
			break;
		case DateTimePickerOptionKeys.THREE_MONTHS:
			subtractValue = 90;
			subtractUnit = 'days';
			break;
	}

	if (!subtractValue || !subtractUnit) {
		return null;
	}

	// We have to use the same Moment instance to ensure we are subtracting the value to the correct date.
	const now = moment();

	const date = {
		start: formatter(now.clone().subtract(subtractValue, subtractUnit)),
		end: formatter(now.clone()),
	};

	return date;
};
