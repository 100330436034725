import { FC } from 'react';

import { AVAILABLE_FIELDS_BLOCK_NAME, AvailableField } from '@/app/_common/types';
import { AvailableFieldsListItem } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/available-fields-list-item';

interface AvailableFieldsListItemsProps {
	blockName: AVAILABLE_FIELDS_BLOCK_NAME;
	fields: AvailableField[];
}

export const AvailableFieldsListItems: FC<AvailableFieldsListItemsProps> = ({ blockName, fields }) => {
	if (fields.length === 0) {
		return null;
	}

	return (
		<>
			{fields.map(({ value, count }) => (
				<AvailableFieldsListItem key={`${blockName}-${value}-${count}`} value={value} count={count} blockName={blockName} />
			))}
		</>
	);
};
