import { KeyboardEvent, MouseEvent, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import _isNil from 'lodash/isNil';
import classNames from 'classnames';

import { FilterList } from '@mui/icons-material';
import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { buttonize } from '@/app/_common/utils';
import { FilterDescriptorWithId, FilterOption, Filters, FilterValue } from '@/app/_common/types';
import { ArrayFilterOperators, dataHeaderCellZIndex, SortDirection } from '@/app/_common/constants';

import { HeaderCell } from '@/app/_common/_components/data-grid/_components/header-cell';
import { DataHeaderSubgroupFilter, HeaderCellSubgroup } from './data-header-subgroup';
import { sortFilters } from '@/app/_common/_components/data-grid/utils';
import { DataHeaderCounter } from '@/app/_common/_components/data-grid/_components/data-header-cell/_components';
import { ActiveType } from '@/app/_common/_components/data-grid/_components/data-header-cell/_common/constants';
import { SelectMode } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/constants';
import { DataHeaderCellFilter } from './_components/data-header-cell-filter';

import styles from './data-header-cell.module.scss';

export const isFieldValueFilter = (filter?: Filters | FilterDescriptorWithId[]): filter is FilterDescriptorWithId[] => {
	if (!filter || filter.length === 0) {
		return false;
	}

	return (filter[0] as FilterDescriptorWithId)?.field !== undefined;
};

const getHeaderCellClassNames = (isActive: boolean, type?: ActiveType) =>
	classNames(styles.header, {
		[styles.cellActive]: isActive,
		[styles.onlyEquals]: isActive && type === ActiveType.onlyIsIn,
		[styles.onlyNotEquals]: isActive && type === ActiveType.onlyIsNotIn,
		[styles.mixedEquality]: isActive && type === ActiveType.mixed,
	});

const getTooltipText = (title?: string, counter?: number) => {
	return title && !_isNil(counter) ? `${title} (${counter})` : title;
};

const getNewFilter = (filter: Filters, values: FilterValue[]) => {
	const newFilter = [...(filter || [])];

	values?.forEach((value) => {
		if (!filter?.includes(value)) {
			newFilter.push(value);
		} else {
			newFilter.splice(newFilter.indexOf(value), 1);
		}
	});

	return newFilter;
};

export interface DataHeaderCellProps extends GridHeaderCellProps {
	className?: string;
	counter?: number;
	sortable?: boolean;
	sortDirection?: SortDirection;
	filter?: Filters | FilterDescriptorWithId[];
	filterable?: boolean;
	sort?: SortDescriptor[];
	filterOptions?: FilterOption[];
	onSort?: (sort: SortDescriptor[]) => void;
	onFilter?: (field: string, values: Filters) => void;
	clearFilter?: (field: string) => void;
	sortFilterOptions?(data: FilterOption[], path: string, field: string): FilterOption[];
	tooltip?: boolean;
	filterOptionsSubgroups?: DataHeaderSubgroupFilter[];
	selectMode?: SelectMode;
	onButtonClick?: (value: FilterValue, operator: ArrayFilterOperators) => void;
	onPopupVisibilityChange?: (opened: boolean) => void;
	activeType?: ActiveType;
	onHeaderCellClick?: (callback?: () => void) => void;
	isFilterDataLoading?: boolean;
}

export const DataHeaderCell = observer<DataHeaderCellProps>(
	({
		field = '',
		counter,
		title,
		className,
		sortable,
		onSort,
		filter = [],
		onFilter,
		filterOptions = [],
		sort = [],
		clearFilter,
		sortFilterOptions,
		tooltip = true,
		filterOptionsSubgroups = [],
		selectMode,
		onButtonClick,
		onPopupVisibilityChange,
		activeType,
		onHeaderCellClick,
		isFilterDataLoading,
	}) => {
		const sortOptions = sort.find((options) => options.field === field);
		const isSortActive = !_isNil(sortOptions);
		const isCellActive = filter?.length > 0;

		const handleSortChange = useCallback(
			(event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
				event.stopPropagation();

				if (isFieldValueFilter(filter) || typeof onSort !== 'function' || !field) {
					return;
				}

				const nextSort: SortDescriptor[] = [];

				if (!isSortActive) {
					nextSort.push({
						field,
						dir: SortDirection.Desc,
					});
				} else if (sortOptions.dir === SortDirection.Desc) {
					nextSort.push({
						field,
						dir: SortDirection.Asc,
					});
				}

				onSort(nextSort);
			},
			[onSort, field, sortOptions, isSortActive, filter],
		);

		const handleFilterChange = useCallback(
			(event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, values: FilterValue[]) => {
				event.stopPropagation();

				if (isFieldValueFilter(filter) || typeof onFilter !== 'function' || !field) {
					return;
				}

				const newFilter = getNewFilter(filter, values);

				onFilter(field, newFilter);
			},
			[onFilter, field, filter],
		);

		const handleClearFilterClick = useCallback(() => {
			if (typeof clearFilter === 'function' && field) {
				clearFilter(field);
			}
		}, [clearFilter, field]);

		const cellClassName = classNames(styles.cell, className);
		//@ts-expect-error
		const optionGroups = sortFilters(filterOptions, filter, field, sortFilterOptions);

		const renderFilterSubgroups = filterOptionsSubgroups.map((subgroup) => (
			<HeaderCellSubgroup key={subgroup.name} {...subgroup} handleFilterChange={handleFilterChange} filter={filter} />
		));

		const tooltipText = getTooltipText(title, counter);
		const areFilterSubgroupsVisible = !isFilterDataLoading && Boolean(filterOptionsSubgroups?.some((subgroup) => subgroup.options.length));

		return (
			<HeaderCell
				className={cellClassName}
				active={isCellActive}
				activeType={activeType}
				headerClassName={getHeaderCellClassNames(isCellActive, activeType)}
				popupClassName={styles.popup}
				hasPopup={Boolean(filterOptions?.length) || Boolean(filterOptionsSubgroups?.length) || Boolean(isFilterDataLoading)}
				header={
					<>
						<div className={styles.title}>{title}</div>
						{sortable && (
							<>
								<div className={classNames(styles.sort, { [styles.sortActive]: isSortActive })} {...buttonize<HTMLDivElement>(handleSortChange)}>
									{sortOptions?.dir === SortDirection.Asc ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
								</div>
							</>
						)}
						<DataHeaderCounter count={counter} />
						{isCellActive && <FilterList />}
					</>
				}
				tooltip={tooltip}
				tooltipText={tooltipText}
				onPopupVisibilityChange={onPopupVisibilityChange}
				onClick={onHeaderCellClick}
				zIndex={dataHeaderCellZIndex}
			>
				<DataHeaderCellFilter
					filter={filter}
					optionGroups={optionGroups}
					renderFilterSubgroups={renderFilterSubgroups}
					areSubgroupsVisible={areFilterSubgroupsVisible}
					selectMode={selectMode}
					loading={isFilterDataLoading}
					options={filterOptions}
					onClearFilter={handleClearFilterClick}
					onFilterChange={handleFilterChange}
					onButtonClick={onButtonClick}
				/>
			</HeaderCell>
		);
	},
);

DataHeaderCell.displayName = 'DataHeaderCell';
