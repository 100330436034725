import _isObject from 'lodash/isObject';
import _isArray from 'lodash/isArray';

type StorageInfo = {
	type: string;
	key: string;
};

interface ObjectWithVersion {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
	version?: string;
}

type ParsedItem = ObjectWithVersion | null | boolean | number | string | (string | number | boolean)[];

export const migrateToVersioned = (storageInfo: StorageInfo, firstVersion: string, nestedObjectKey: string) => {
	const { type, key } = storageInfo;

	if (!key) {
		throw new Error('Provide the non-empty storage key');
	}

	try {
		const storage = type === 'localStorage' ? localStorage : sessionStorage;
		const oldItem = storage.getItem(key);

		if (oldItem === null) {
			return;
		}

		const parsedOldItem: ParsedItem = JSON.parse(oldItem);
		const isOldItemInObjectVersionedFormat = _isObject(parsedOldItem) && !_isArray(parsedOldItem) && parsedOldItem.version;

		if (isOldItemInObjectVersionedFormat) {
			return;
		}

		const newItem = {
			[nestedObjectKey]: parsedOldItem,
			version: firstVersion,
		};

		localStorage.setItem(storageInfo.key, JSON.stringify(newItem));
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Storage migration failed:', error);
	}
};
