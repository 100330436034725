import { FC, useCallback, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenExit from '@mui/icons-material/FullscreenExit';

import { Namespaces } from '@/translations/namespaces';
import { IconButton } from '@/app/_common/_components/rich-text-editor/_components';
import { Dialog, RichTextEditor, NoteDraft, ConfirmDialog, Button, Tooltip, getRichTextEditorTranslations } from '@/app/_common/_components';
import { NOTE_DIALOG_WIDTH } from '@/app/_common/constants';

import styles from './note-edit-dialog.module.scss';

const DIALOG_WRAPPER_SELECTOR = '.k-dialog-wrapper';

interface NoteEditDialogProps {
	title: string;
	loading?: boolean;
	noteContent?: string;
	confirmTitle: string;
	confirmMessage: string;
	onSubmit?: (noteContent: string) => void;
	onUpdate?: (noteContent: string) => void;
	onCancel: () => void;
	onMinimize: () => void;
	onChange?: (noteContent?: string) => void;
}

export const NoteEditDialog: FC<NoteEditDialogProps> = ({
	title,
	noteContent,
	loading,
	confirmTitle,
	confirmMessage,
	onSubmit,
	onUpdate,
	onMinimize,
	onCancel,
	onChange,
}) => {
	const translations = useMemo(() => getRichTextEditorTranslations(Namespaces.Notes), []);

	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

	const setFocusOnDialog = () => {
		const dialogWrapperElement = document.querySelector(DIALOG_WRAPPER_SELECTOR) as HTMLElement;

		if (dialogWrapperElement) {
			dialogWrapperElement.focus();
		}
	};

	const handleCancel = useCallback(() => {
		setIsConfirmDialogOpen(!isConfirmDialogOpen);

		//After closing Confirm Dialog, the Note Dialog loses the focus and we have to set it again
		setFocusOnDialog();
	}, [isConfirmDialogOpen]);

	const handleConfirmCancel = useCallback(() => {
		setIsConfirmDialogOpen(false);
	}, []);

	const handleConfirmSubmit = useCallback(() => {
		onCancel();
	}, [onCancel]);

	const { minimizeIcon, cancel } = translations;

	return (
		<Dialog
			width={NOTE_DIALOG_WIDTH}
			headerDivider={false}
			footerDivider={false}
			closeIcon={false}
			onSubmit={onMinimize}
			onClose={handleCancel}
			className={styles.dialog}
		>
			{isConfirmDialogOpen && (
				<ConfirmDialog onSubmit={handleConfirmSubmit} onCancel={handleConfirmCancel} title={confirmTitle}>
					{confirmMessage}
				</ConfirmDialog>
			)}
			<NoteDraft
				title={title}
				actions={
					<div className={styles.actions}>
						<Tooltip tooltipId={minimizeIcon} tooltipContent={minimizeIcon} />
						<span data-tip={minimizeIcon} data-for={minimizeIcon} id={minimizeIcon}>
							<IconButton onClick={onMinimize}>
								<FullscreenExit className={styles.minimize} />
							</IconButton>
						</span>
						<Button fillMode="outline" onClick={handleCancel}>
							{cancel} <CloseIcon />
						</Button>
					</div>
				}
			>
				<RichTextEditor
					editorContent={noteContent}
					isEditModeActive={true}
					loading={loading}
					onSubmit={onSubmit}
					onUpdate={onUpdate}
					onChange={onChange}
					translations={translations}
				/>
			</NoteDraft>
		</Dialog>
	);
};
