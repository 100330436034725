import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { DetailsViewNavigationField, DetailsViewNavigationFieldContextMenuType } from '@/app/_common/_components/details-view/types';
import { Namespaces } from '@/translations/namespaces';
import { CopyButton } from '@/app/_common/_components';
import { AddToFavoritesAction } from '@/app/_common/_components/details-view/components/details-view-navigation-fields/components/details-view-navigation-field-row-context-menu/components';
import { AVAILABLE_FIELDS_BLOCK_NAME } from '@/app/_common/types';

import styles from './details-view-navigation-field-row-context-menu.module.scss';

interface DetailsViewNavigationFieldRowContextMenuProps {
	fieldData: DetailsViewNavigationField;
	menuType: DetailsViewNavigationFieldContextMenuType;
	closeContextMenu: () => void;
	blockName: AVAILABLE_FIELDS_BLOCK_NAME | null;
}

export const DetailsViewNavigationFieldRowContextMenu = ({
	blockName,
	fieldData,
	menuType,
	closeContextMenu,
}: DetailsViewNavigationFieldRowContextMenuProps) => {
	const { t } = useTranslation([Namespaces.DetailsView], { keyPrefix: 'expansionPanel' });

	const { label, value } = fieldData;
	const copyValue = menuType === DetailsViewNavigationFieldContextMenuType.Label ? label : value;
	const titleKey = menuType === DetailsViewNavigationFieldContextMenuType.Label ? 'label' : 'value';

	return (
		<div className={styles.container} data-testid="context-menu">
			<div className={classNames(styles.actionContainer, styles.title)}>{t(`contextMenu.${titleKey}`)}</div>
			<CopyButton value={copyValue} label={t('contextMenu.copy')} onCopyValue={closeContextMenu} className={styles.actionContainer} />
			{menuType === DetailsViewNavigationFieldContextMenuType.Label && (
				<AddToFavoritesAction closeContextMenu={closeContextMenu} fieldName={label} className={styles.actionContainer} blockName={blockName} />
			)}
		</div>
	);
};
