import { ReactElement, ReactNode, RefObject, cloneElement, useCallback, useMemo, useRef, useState, MouseEvent, KeyboardEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { DocumentNode } from 'graphql';
import { FiltersInput } from '@/generated/graphql';

import classNames from 'classnames';

import { Popup, PopupProps } from '@progress/kendo-react-popup';

import { buttonize } from '@/app/_common/utils';
import { useOutsideClick } from '@/app/_common/hooks';
import { PopupOffset } from '@/app/_common/types';

import { ContextMenuViewStore } from './_common';

import styles from './context-menu.module.scss';

export interface ContextMenuProps extends PopupProps {
	query?: DocumentNode;
	filtersInput?: FiltersInput;
	showOnRightClick?: boolean;
	showInClickPosition?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	content: ({ data, loading, close }: { data: Record<string, any>; loading: boolean; close: (event?: MouseEvent) => void }) => ReactNode;
	children: ReactElement;
	className?: string;
}

export const ContextMenuComponent = observer<ContextMenuProps>(
	({ query, filtersInput, children, className, content, showOnRightClick, showInClickPosition, ...popupProps }) => {
		const store = useInstance(ContextMenuViewStore);

		const anchorRef = useRef(null) as RefObject<HTMLDivElement>;

		const [offset, setOffset] = useState<PopupOffset | undefined>(showInClickPosition ? { left: 0, top: 0 } : undefined);
		const [show, setShow] = useState(false);

		const handleCloseContextMenu = useCallback((event: MouseEvent<HTMLDivElement>) => {
			if (!anchorRef?.current?.contains(event?.target as Node)) {
				setShow(false);
			}
		}, []);

		const handleOpenContextMenu = useCallback(
			(event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
				event.preventDefault();

				if (!show) {
					if (query) {
						store.read(query, filtersInput);
					}

					setShow(true);
				}

				if (showInClickPosition && 'clientX' in event) {
					setOffset({
						left: event.clientX,
						top: event.clientY,
					});
				}
			},
			[store, show, query, showInClickPosition, filtersInput],
		);

		const toggleContextMenu = useCallback(
			(event: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
				show ? setShow(false) : handleOpenContextMenu(event);
			},
			[handleOpenContextMenu, show],
		);

		//@ts-ignore
		const { popupRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(show, handleCloseContextMenu);

		const anchorProps = useMemo(() => {
			if (showOnRightClick) {
				return {
					onContextMenu: toggleContextMenu,
				};
			}

			return {
				...buttonize<HTMLDivElement>(toggleContextMenu),
			};
		}, [toggleContextMenu, showOnRightClick]);

		const anchor = showInClickPosition ? undefined : anchorRef.current;

		return (
			<>
				<div ref={anchorRef} className={styles.anchor} {...anchorProps}>
					{cloneElement(children, { active: show })}
				</div>
				<Popup className={classNames(styles.contextMenu, className)} animate={false} show={show} anchor={anchor} offset={offset} {...popupProps}>
					<div data-testid="context-menu">
						{/* @ts-ignore */}
						<div ref={popupRef}>{content({ data: store.data, loading: store.loading, close: handleCloseContextMenu })}</div>
					</div>
				</Popup>
			</>
		);
	},
);

ContextMenuComponent.displayName = 'ContextMenuComponent';
