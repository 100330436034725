/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { Loader } from '@progress/kendo-react-indicators';

import moment from 'moment';

import { AuthStore } from '@/app/_common/stores';
import { RootPaths } from '@/app/_common/navigation/root-paths';
import { GraphqlClient } from '@/app/_common/graphql';
import { useIntervalCounter } from '@/app/_common/hooks';
import { useShowExpiryBar } from '@/app/_common/_components/token-expiration-bar/use-show-expiry-bar';
import { useTokenExpiry } from '@/app/_common/_components/token-expiration-bar/use-token-expiry';
import accessTime from '@/assets/images/access-time-white.svg';

import styles from './token-expiration-bar.module.scss';

interface TokenExpirationBarType {
	onChange: Dispatch<SetStateAction<boolean>>;
}

export const TokenExpirationBar: FC<TokenExpirationBarType> = observer(({ onChange }) => {
	const navigate = useNavigate();
	const client = useInstance(GraphqlClient);
	const store = useInstance(AuthStore);

	const { t } = useTranslation();

	const { remainingTime } = useIntervalCounter(moment.duration(store.expiresOn, 'seconds').asMilliseconds());
	const { showExpiryBar, timeToExpire } = useShowExpiryBar(remainingTime);

	const handleRefreshToken = useCallback(async () => {
		await store.replaceTokens();
		client.reconnectWS();
	}, [store, client]);

	const handleTokenExpiry = useCallback(() => {
		store.expireToken();
		navigate(RootPaths.EXPIRED_TOKEN);
	}, [store, history]);

	useTokenExpiry(remainingTime, handleRefreshToken, handleTokenExpiry);

	useEffect(() => {
		onChange(showExpiryBar);
	}, [showExpiryBar]);

	if (!showExpiryBar) return null;

	return (
		<div className={styles.bar}>
			<div className={styles.info}>
				<img src={accessTime} alt="access-time" className={styles.image} />
				<span>{t('tokenExpiration.info', { time: timeToExpire })}</span>
				{store.pending && <Loader type="pulsing" />}
			</div>
			<button className={styles.btn}>
				<NavLink to={RootPaths.SIGN_OUT} className={styles.link}>
					{t('tokenExpiration.logOut')}
				</NavLink>
			</button>
		</div>
	);
});
