import { DocumentNode } from 'graphql';
import { makeAutoObservable } from 'mobx';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { BreadcrumbsDataStore, GetBreadcrumbName, QueryIDs as QueryIDs } from './breadcrumbs.data-store';
import { RouteBreadcrumb } from '@/app/_common/navigation/Routes';
import { getter } from '@progress/kendo-react-common';

export class BreadcrumbsViewStore {
	private breadcrumbsDataStore = injectInterface(this, BreadcrumbsDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get data() {
		return this.breadcrumbsDataStore.data;
	}

	private getPrefixedBreadcrumb = (breadcrumb: string, prefix?: string): string => {
		if (!prefix) {
			return breadcrumb;
		}

		return `${prefix} ${breadcrumb}`;
	};

	private getSuffixedBreadcrumb = (breadcrumb: string, suffix?: string): string => {
		if (!suffix) {
			return breadcrumb;
		}

		return `${breadcrumb} ${suffix}`;
	};

	public getBreadcrumbName = (routeBreadcrumb: RouteBreadcrumb): string => {
		const { queryParameterName, optionalQueryParameter, breadcrumbSuffix, queryType, breadcrumbPrefix, computeBreadcrumb } = routeBreadcrumb;
		type BreadcrumbQueryTypes = keyof GetBreadcrumbName;
		const queryTypeIndex = queryType as BreadcrumbQueryTypes;

		if (!this.data || !this.data[queryTypeIndex]) {
			return '';
		}

		if (computeBreadcrumb) {
			return this.getSuffixedBreadcrumb(this.getPrefixedBreadcrumb(computeBreadcrumb(this.data[queryTypeIndex]), breadcrumbPrefix), breadcrumbSuffix);
		}

		if (optionalQueryParameter) {
			const parameterGetter = getter(optionalQueryParameter.requirement);
			const displayValueGetter = getter(optionalQueryParameter.queryParameterName);

			const displayValue = displayValueGetter(this.data[queryTypeIndex]);
			const optionalParameter = parameterGetter(this.data[queryTypeIndex]);
			const isValid = optionalParameter === optionalQueryParameter.valid;

			if (isValid) {
				return `${breadcrumbPrefix} ${displayValue}`;
			}
		}

		//@ts-ignore
		const breadcrumbName = this.data[queryTypeIndex][queryParameterName];

		return this.getSuffixedBreadcrumb(this.getPrefixedBreadcrumb(breadcrumbName, breadcrumbPrefix), breadcrumbSuffix);
	};

	get loading() {
		return this.breadcrumbsDataStore.loading;
	}

	read = (query: DocumentNode, queryIDs: QueryIDs) => {
		this.breadcrumbsDataStore.read(query, queryIDs);
	};
}
