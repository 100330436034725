import { ChangeEvent, FC } from 'react';

import styles from './range-input.module.scss';

interface RangeInputProps {
	min: number;
	max: number;
	step: number;
	value: number;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const RangeInput: FC<RangeInputProps> = ({ min, max, step, value, onChange }) => {
	return <input type="range" className={styles.rangeInput} min={min} max={max} step={step} value={value} onChange={onChange} />;
};
