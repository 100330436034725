import { computed, makeObservable, reaction } from 'mobx';

import { QueryResultsTableViewStore } from '@/app/_common/_components/query-results/query-results-table/stores';
import { DataGridDisabled, DataGridSelected, ResultsDataItem } from '@/app/_common/types';
import { ResultsTablePropertiesPaths } from '@/app/_common/constants';
import { getDetailsViewTypename } from '@/app/_common/_components/details-view/utils';
import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AssignQueryResultsToInvestigationDialogAlertsDataStore } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/assign-query-results-to-investigation-dialog/stores/assign-query-results-to-investigation-dialog-alerts.data-store';
import { Alert, AlertState } from '@/generated/graphql';
import { extractResultsTableItemId } from '@/app/_common/utils';

export class AssignQueryResultsToInvestigationDialogResultsTableViewStore extends QueryResultsTableViewStore {
	private alertsDataStore = injectInterface(this, AssignQueryResultsToInvestigationDialogAlertsDataStore);

	constructor() {
		super();

		makeObservable(this, { alertsLoading: computed });
	}

	get alertsLoading(): boolean {
		return this.alertsDataStore.loading;
	}

	/**
	 * Table rows should be set to either selected or unselected disabled state based on data type and state.
	 * Thus assigned alerts should be unselected and disabled. Events and Evidence data are to be selected.
	 * Initially all rows are unselected in the table. Thus, calculate which ones to set as selected and check the rows.
	 */
	sourceDataDisposer = reaction(
		() => this.sourceData,
		(data: ResultsDataItem[] | undefined) => {
			if (!data) {
				return;
			}

			// Reset the selected state
			this.selectRows({});
			this.disableRows({});

			const selectedRows: DataGridSelected = {};
			const alertIds: string[] = [];

			for (const item of data) {
				const id = item[ResultsTablePropertiesPaths.Id];
				const typename = getDetailsViewTypename(item);
				const isAlert = typename === DetailsViewTypename.ALERT;

				if (id) {
					if (isAlert) {
						/**
						 * For data of type 'Alert' information on alert 'status' is missing, thus it has to be fetched
						 * to define whether it is 'assigned' and should be disabled, or unassigned and should be selected (checked).
						 */
						const alertId = extractResultsTableItemId(id); // Decode 'alert_id' from item._id
						if (alertId) {
							alertIds.push(alertId);
						} else {
							// E.g. when user chooses not to project 'alert_id'
							selectedRows[id] = true;
						}
					} else {
						selectedRows[id] = true;
					}
				}
			}

			if (!alertIds.length) {
				// No need to wait to calculate alerts' status
				this.selectRows(selectedRows);
			} else {
				// First fetch Alerts' state and then calculate and set selected in alertsStatusDisposer
				this.readSelectedAlerts(alertIds);
			}
		},
	);

	/**
	 * React to alerts data fetched and calculate and set selected rows.
	 */
	alertsStatusDisposer = reaction(
		() => this.alertsDataStore.alerts,
		(alerts: Alert[]) => {
			if (!(alerts?.length && this.sourceData)) {
				return;
			}

			// Create an alerts' 'id' to 'is alert assigned' mapping
			const alertStates: Record<string, boolean> = alerts.reduce((acc: Record<string, boolean>, item: Alert) => {
				const { id, state } = item;
				acc[id] = Boolean(state?.alertState === AlertState.Assigned);
				return acc;
			}, {});

			const selectedRows: DataGridSelected = {};
			const disabledRows: DataGridDisabled = {};

			for (const item of this.sourceData) {
				const id = item[ResultsTablePropertiesPaths.Id];
				const typename = getDetailsViewTypename(item);
				const isAlert = typename === DetailsViewTypename.ALERT;

				if (id) {
					if (isAlert) {
						const alertId = extractResultsTableItemId(id); // Decode 'alert_id' from item._id
						const isAssigned = alertStates[alertId];

						if (!isAssigned) {
							selectedRows[id] = true;
						} else {
							disabledRows[id] = true;
						}
					} else {
						selectedRows[id] = true;
					}
				}
			}

			this.selectRows(selectedRows);
			this.disableRows(disabledRows);
		},
	);

	dispose() {
		super.dispose();
		this.sourceDataDisposer();
		this.alertsStatusDisposer();
	}

	private readSelectedAlerts(alertIds: string[]) {
		this.alertsDataStore.read(alertIds);
	}
}
