import { FC, memo } from 'react';

import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import { deepEqual, isSearchStringEqual } from '@/app/_common/utils';
import { BaseCell } from '@/app/_common/_components/data-grid/_components';
import { HighlightedText } from '@/app/_common/_components/data-grid/_components/highlighted-text';
import { ResultsCellProps } from '@/app/advanced-query/_common/types';

import styles from './trim-text-cell.module.scss';

export const TrimTextCell: FC<ResultsCellProps> = memo(
	({ title, field, dataItem, tooltip, defaultMessage, className, Icon, onClick, searchValue = '', ContextMenuComponent, ...rest }) => {
		const value = title || _get(dataItem, field || '');
		const hasValue = !_isEmpty(value) || _isNumber(value);
		const cellTitle = tooltip || value?.toString();
		const defaultTitle = tooltip || '';
		const highlightedValue = searchValue ? <HighlightedText value={value} searchLine={searchValue} /> : value;
		const isCellHighlighted = searchValue && String(value).toLowerCase().includes(searchValue.toLowerCase());

		return (
			<BaseCell
				{...rest}
				field={field}
				hasValue={hasValue}
				displayValues={highlightedValue}
				cellTitle={cellTitle}
				defaultTitle={defaultTitle}
				customClassnames={classNames(styles.trimTextCell, { isCellHighlighted }, className)}
				onClick={onClick}
				dataItem={dataItem}
				ContextMenuComponent={ContextMenuComponent}
				defaultMessage={defaultMessage}
				Icon={Icon}
				iconClassName={styles.icon}
			/>
		);
	},
	function (prev, next) {
		const isItemDataEqual = deepEqual(prev, next, ['field', `dataItem[${prev.field}]`]);
		const isSearchValueEqual = isSearchStringEqual(prev.searchValue, next.searchValue);
		return isItemDataEqual && isSearchValueEqual;
	},
);

TrimTextCell.displayName = 'TrimTextCell';
