import { Schema } from '@/app/_common/types';
import { CSL_TYPES } from '@/app/_common/constants';
import { getUniqColumnsByType } from '@/app/_common/utils';

export const getDateTimeColumns = (tableNames: string[], schema?: Schema, database?: string) => {
	if (!schema) {
		return [];
	}

	const db = schema.Databases[database ?? Object.keys(schema.Databases)[0]];

	if (!db) {
		return [];
	}

	if (tableNames.length === 0) {
		return Object.values(db.Tables).reduce<string[]>((result, table) => {
			return getUniqColumnsByType(table, CSL_TYPES.DATETIME, result);
		}, []);
	}

	return tableNames.reduce<string[]>((result, tableName) => {
		const table = db.Tables[tableName] ?? db.MaterializedViews[tableName];

		if (!table) {
			return result;
		}

		return getUniqColumnsByType(table, CSL_TYPES.DATETIME, result);
	}, []);
};
