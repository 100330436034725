import { FC } from 'react';
import classNames from 'classnames';
import { Error, ErrorProps } from '@progress/kendo-react-labels';

import styles from './form-field-error.module.scss';

interface FormFieldErrorProps extends ErrorProps {
	className?: string;
}

export const FormFieldError: FC<FormFieldErrorProps> = ({ className, ...rest }) => {
	return <Error className={classNames(styles.error, className)} {...rest} />;
};
