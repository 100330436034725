import { SortDirection } from '@/generated/graphql';

type ExtendedSortDirection = SortDirection | undefined;

const sortOrder = new Map<ExtendedSortDirection, ExtendedSortDirection>([
	[undefined, SortDirection.Asc],
	[SortDirection.Asc, SortDirection.Desc],
	[SortDirection.Desc, undefined],
]);

export const switchSortOrder = (currentOrder: ExtendedSortDirection): ExtendedSortDirection => {
	return sortOrder.get(currentOrder);
};
