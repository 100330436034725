import classNames from 'classnames';

import styles from './details-view-navigation-container.module.scss';

interface DetailsViewNavigationContainerProps {
	children: JSX.Element;
	loading?: boolean;
}
export const DetailsViewNavigationContainer = ({ loading = false, children }: DetailsViewNavigationContainerProps) => {
	return <div className={classNames(styles.container, { [styles.loading]: loading })}>{children}</div>;
};
