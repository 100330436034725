import { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import ReactSwitch from 'react-switch';
import classNames from 'classnames';

import '@/assets/styles/index.scss';
import { ThemeStore } from '@/app/_common/stores';

import { SWITCH_ON_COLOR, SWITCH_ON_COLOR_DARK_MODE, SWITCH_ON_HANDLE_COLOR } from './constants';
import styles from './switch.module.scss';

interface SwitchProps {
	disabled?: boolean;
	checked: boolean;
	onChange?: (checked: boolean) => void;
	className?: string;
	textClassName?: string;
	text?: string;
	onColor?: string;
	onHandleColor?: string;
}

export const Switch: FC<SwitchProps> = observer(
	({ checked, onChange, disabled, className, textClassName, text, onColor = SWITCH_ON_COLOR, onHandleColor = SWITCH_ON_HANDLE_COLOR }) => {
		const themeStore = useInstance(ThemeStore);

		const switchClassNames = classNames(styles.switch, className);
		const textClassNames = classNames(styles.switchText, textClassName);

		const switchOnColorProps = themeStore.isDarkTheme ? SWITCH_ON_COLOR_DARK_MODE : { onColor, onHandleColor };

		const handleChange = useCallback(
			(value: boolean) => {
				if (typeof onChange === 'function') {
					onChange(!value);
				}
			},
			[onChange],
		);

		return (
			<label className={switchClassNames}>
				<ReactSwitch
					disabled={disabled}
					checked={checked}
					onChange={handleChange}
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14) "
					activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
					height={14}
					width={34}
					className="react-switch"
					{...switchOnColorProps}
				/>
				{text && <span className={textClassNames}>{text}</span>}
			</label>
		);
	},
);
