import { jsonsHeaders, getHeaderValue } from '@/app/_common/_components/csv-export/utils';
import { CsvExportData, CsvExportHeaders } from '@/app/_common/_components/csv-export/types';

export const jsons2arrays = (jsons: CsvExportData, headers?: CsvExportHeaders) => {
	const headersKeys: CsvExportHeaders = headers || jsonsHeaders(jsons);

	const data = jsons.map((object) => headersKeys.map((header) => getHeaderValue(header, object)));

	return [headersKeys, ...data];
};
