import { prepareQuery, removeSortOperator } from '@/app/_common/utils';
import { QUERY_FUNCTION, QUERY_OPERATOR, TableColumn } from '@/app/_common/constants';
import { FieldFilterGroup, Schema, TimeRangeFilterOption } from '@/app/_common/types';

interface PrepareTotalCountQuery {
	database: string;
	filtersList?: FieldFilterGroup[];
	query: string;
	schema: Schema;
	timeRange: TimeRangeFilterOption;
}

export const prepareTotalCountQuery = ({ database, filtersList = [], query, schema, timeRange }: PrepareTotalCountQuery) => {
	const _query = removeSortOperator(prepareQuery({ query, schema, database, timeRange, filtersList, limit: null, includeTableName: true }) || '');
	return `${_query} | ${QUERY_OPERATOR.SUMMARIZE} ${QUERY_FUNCTION.COUNT}() by ${TableColumn}`;
};
