import { ActionHistoryDataGrid } from '@/app/_features/action-history/_components';

import styles from './action-history-page.module.scss';

interface ActionHistoryPageProps {
	isFranchise: boolean;
}

export const ActionHistoryPage = ({ isFranchise }: ActionHistoryPageProps) => {
	return (
		<div className={styles.gridContainer}>
			<ActionHistoryDataGrid isFranchise={isFranchise} />
		</div>
	);
};
