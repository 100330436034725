import { FC } from 'react';
import { Button, Dialog } from '@/app/_common/_components';
import { OpenInNew } from '@mui/icons-material';
import { RichTextEditorTranslations } from '@/app/_common/_components/rich-text-editor/types';

import styles from './external-link-dialog.module.scss';

const DIALOG_WIDTH = 440;

interface ExternalLinkDialogProps {
	link: string;
	handleCloseDialog: () => void;
	translations: RichTextEditorTranslations;
}

export const ExternalLinkDialog: FC<ExternalLinkDialogProps> = ({ link, handleCloseDialog, translations }) => {
	const handleClickExternalLink = () => {
		window.open(link, '_blank', 'noopener,noreferrer');
	};

	return (
		<Dialog width={DIALOG_WIDTH} className={styles.container} onSubmit={handleClickExternalLink}>
			<h3>{translations.externalLinkTitle}</h3>
			<div className={styles.content}>
				<p>{translations.externalLinkSubtitle}</p>
				<p className={styles.link}>{link}</p>
				<div className={styles.description}>
					<p>{translations.externalLinkDescFirstParagraph}</p>
					<p>{translations.externalLinkDescSecondParagraph}</p>
				</div>
			</div>
			<div className={styles.actions}>
				<Button fillMode="flat" onClick={handleCloseDialog}>
					{translations.cancel}
				</Button>
				<Button onClick={handleClickExternalLink} fillMode="flat">
					{translations.continueButton} <OpenInNew />
				</Button>
			</div>
		</Dialog>
	);
};
