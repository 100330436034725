import moment from 'moment';

// It translates date directly to utc, without considering time zone
export const dateToUtcMoment = (date: Date): moment.Moment => {
	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const seconds = date.getSeconds();

	const utcDate = new Date(Date.UTC(year, month, day, hours, minutes, seconds));

	return moment(utcDate.getTime());
};
