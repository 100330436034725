import { FC } from 'react';
import _get from 'lodash/get';

import { GridCellProps } from '@progress/kendo-react-grid';

import { ContextMenuComponentProps } from '@/app/_common/types';
import { DataCell } from '@/app/_common/_components/data-grid/_components';
import { MitreAttackBadgeContent } from '@/app/_common/_components/mitre-attack-badge';
import { MitreAttackBadgeText } from '@/app/_common/_components/mitre-attack-badge/_constants/mitre-attack-badge-text';

interface MitreAttackCellProps extends GridCellProps {
	ContextMenuComponent?: FC<ContextMenuComponentProps>;
	oldContextMenu?: boolean;
}

export const MitreAttackCell: FC<MitreAttackCellProps> = (props) => {
	const { field, dataItem } = props;
	const value: MitreAttackBadgeText = _get(dataItem, field ?? '');

	if (value) {
		return (
			<DataCell {...props} title={value}>
				<MitreAttackBadgeContent text={value} />
			</DataCell>
		);
	}

	return <DataCell {...props} empty={true} />;
};
