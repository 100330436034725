import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import { App } from './app/App';

import i18next from './translations/i18n';

import './index.css';

const container = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(
	<I18nextProvider i18n={i18next}>
		<App />
	</I18nextProvider>,
);
