import { FC, RefObject, useCallback, useRef, useState } from 'react';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Form, FormProps } from '@progress/kendo-react-form';

import { Namespaces } from '@/translations/namespaces';

import { AlertsAssignDialogViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/alerts-assign-dialog.view-store';
import { AlertsAssignList } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/alerts-assign-list';
import { AlertsAssignListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/alerts-assign-list.view-store';
import { Divider, Button, Dialog } from '@/app/_common/_components';
import CreateInvestigationFormModule from '@/app/_common/_components/forms/create-investigation-form/create-investigation-form-module';
import AssignToInvestigationFormModule from '@/app/_common/_components/forms/assign-to-investigation-form/assign-to-investigation-form-module';
import { AssignToInvestigationFormTabs } from '@/app/_common/_components/forms';
import { AlertsKeyPrefix, AssignToInvestigationTabs } from '@/app/_common/constants';
import { handleTabFormErrors } from '@/app/_common/utils/handle-tab-form-errors';

import styles from './alerts-assign-dialog.module.scss';

const DIALOG_WIDTH = 1256;

const changeFormTab = (
	tab: AssignToInvestigationTabs,
	store: AlertsAssignDialogViewStore,
	assignToInvestigationFormRef: RefObject<Form>,
	createInvestigationFormRef: RefObject<Form>,
): void => {
	if (tab !== store.activeTab) {
		if (tab === AssignToInvestigationTabs.createInvestigation) {
			store.setAssignToInvestigationFormValues(assignToInvestigationFormRef?.current?.values);
			assignToInvestigationFormRef?.current?.onReset();

			if (store.createInvestigationFormValues) {
				Object.keys(store.createInvestigationFormValues).forEach((key) => {
					// @ts-ignore
					createInvestigationFormRef?.current?.valueSetter(key, store.createInvestigationFormValues[key]);
				});
			}
		} else if (tab === AssignToInvestigationTabs.assignToInvestigation) {
			store.setCreateInvestigationFormValues(createInvestigationFormRef?.current?.values);
			createInvestigationFormRef?.current?.onReset();

			if (store.assignToInvestigationFormValues) {
				Object.keys(store.assignToInvestigationFormValues).forEach((key) => {
					// @ts-ignore
					assignToInvestigationFormRef?.current?.valueSetter(key, store.assignToInvestigationFormValues[key]);
				});
			}
		}

		store.setActiveTab(tab);
	}
};

const AlertsAssignDialog: FC = observer(() => {
	const store = useInstance(AlertsAssignDialogViewStore);
	const alertsAssignListStore = useInstance(AlertsAssignListViewStore);

	const { t } = useTranslation([Namespaces.AlertsDashboard], { keyPrefix: AlertsKeyPrefix.AssignAlertDialog });

	const [submitButtonsDisabled, setSubmitButtonsDisabled] = useState<Record<AssignToInvestigationTabs, boolean>>({
		[AssignToInvestigationTabs.assignToInvestigation]: true,
		[AssignToInvestigationTabs.createInvestigation]: true,
	});

	const createInvestigationFormRef = useRef<Form>(null);
	const assignToInvestigationFormRef = useRef<Form>(null);

	const handleDialogClose = useCallback(() => {
		store.close();
	}, [store]);

	const handleSubmitButtonClick = useCallback(() => {
		if (store.activeTab === AssignToInvestigationTabs.createInvestigation) {
			handleCreateInvestigationFormSubmit(createInvestigationFormRef.current?.values);
		} else if (store.activeTab === AssignToInvestigationTabs.assignToInvestigation) {
			handleAssignToInvestigationFormSubmit(assignToInvestigationFormRef.current?.values);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [store.activeTab]);

	const handleCreateInvestigationFormSubmit = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(data: any) => {
			if (createInvestigationFormRef?.current?.isValid()) {
				store.submitCreateInvestigationForm({
					...data,
					assigneeId: data.assigneeId.value,
					type: data.type.value,
				});
			}
		},
		[store],
	);

	const handleAssignToInvestigationFormSubmit = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(data: any) => {
			if (assignToInvestigationFormRef?.current?.isValid()) {
				store.submitAssignToInvestigationForm({
					investigationId: data.investigation?.value?.id,
				});
			}
		},
		[store],
	);

	const handleFormTabChange = useCallback(
		(tab: AssignToInvestigationTabs) => {
			changeFormTab(tab, store, assignToInvestigationFormRef, createInvestigationFormRef);
		},
		[store],
	);

	const handleFormErrors = useCallback(
		(errors: { [key: string]: string }, tab: AssignToInvestigationTabs) => {
			handleTabFormErrors(errors, tab, store.activeTab, setSubmitButtonsDisabled);
		},
		[store.activeTab, setSubmitButtonsDisabled],
	);

	if (!store.isOpen) {
		return null;
	}

	return (
		<Dialog
			className={styles.dialog}
			title={t('title')}
			onClose={handleDialogClose}
			onSubmit={handleSubmitButtonClick}
			width={DIALOG_WIDTH}
			headerDivider={false}
			footerDivider={false}
			errors={store.errors}
			actions={
				<>
					<Button className={styles.button} onClick={handleDialogClose} fillMode="outline">
						{t('buttons.cancel')}
					</Button>
					<Button
						className={styles.button}
						onClick={handleSubmitButtonClick}
						disabled={
							alertsAssignListStore.alertIds.length === 0 ||
							submitButtonsDisabled[AssignToInvestigationTabs.assignToInvestigation] ||
							submitButtonsDisabled[AssignToInvestigationTabs.createInvestigation]
						}
						loading={store.loading}
					>
						{store.activeTab !== AssignToInvestigationTabs.createInvestigation ? t('buttons.assign') : t('buttons.create')}
					</Button>
				</>
			}
		>
			<AlertsAssignList store={alertsAssignListStore} />
			<Divider />
			<AssignToInvestigationFormTabs
				active={store.activeTab}
				tabs={[
					{
						id: AssignToInvestigationTabs.createInvestigation,
						title: t('tabs.create.title'),
						content: (
							<CreateInvestigationFormModule
								formRef={createInvestigationFormRef}
								onSubmit={handleCreateInvestigationFormSubmit as FormProps['onSubmit']}
								disabled={store.activeTab !== AssignToInvestigationTabs.createInvestigation}
								onErrorsChange={handleFormErrors}
							/>
						),
					},
					{
						id: AssignToInvestigationTabs.assignToInvestigation,
						title: t('tabs.assign.title'),
						content: (
							<AssignToInvestigationFormModule
								formRef={assignToInvestigationFormRef}
								onSubmit={handleAssignToInvestigationFormSubmit as FormProps['onSubmit']}
								disabled={store.activeTab !== AssignToInvestigationTabs.assignToInvestigation}
								onErrorsChange={handleFormErrors}
							/>
						),
					},
				]}
				onTabButtonClick={handleFormTabChange}
				onTabContentFocus={handleFormTabChange}
			/>
		</Dialog>
	);
});

AlertsAssignDialog.displayName = 'AlertsAssignDialog';
export { AlertsAssignDialog };
