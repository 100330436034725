import _intersection from 'lodash/intersection';

import { DEFAULT_SORT_COLUMNS } from '@/app/_common/constants';

export const getTimestampColumn = (dateTimeColumns: string[]) => {
	if (!dateTimeColumns || dateTimeColumns.length === 0) {
		return '';
	}

	const defaultSortColumn = _intersection(dateTimeColumns, DEFAULT_SORT_COLUMNS)[0];

	if (defaultSortColumn) {
		return defaultSortColumn;
	}

	return dateTimeColumns[0];
};
