import { FC, useCallback, useId, useMemo } from 'react';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import CancelIcon from '@mui/icons-material/Cancel';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import WarningIcon from '@mui/icons-material/Warning';

import {
	prepareTimeRangeForQuery,
	getZoomOutTimeRange,
	getTimeRangeString,
	displayInterval,
} from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/chart/utils';
import { Button, ButtonSize, Tooltip } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';
import { AdvancedQueryPageKeyPrefix, DateTimePickerOptionKeys } from '@/app/_common/constants';
import { AdvancedQueryChartViewStore, AdvancedQueryViewStore } from '@/app/advanced-query/_common/stores';
import { ChartStackBarKeys } from '@/app/_common/types';
import { ChartHistory } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/chart/_components/chart-action-block/_components';
import { isStackedChartDataItemArray } from '@/app/advanced-query/_common/types';

import { ChartSettings } from './_components/chart-settings/chart-settings';

import styles from './chart-action-block.module.scss';

const DECIMAL_DIGITS_NUMBER = 2;

interface ChartActionBlockProps {
	selectedBrushRange: string[];
	resetBrushArea: () => void;
	actionBlockRef: (node: HTMLElement | null) => void;
}

export const ChartActionBlock: FC<ChartActionBlockProps> = observer(({ actionBlockRef, selectedBrushRange, resetBrushArea }) => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });
	const { timeRange, addPreviousTimeRangeToHistory, runCustomQuery, persistentTimeRange } = useInstance(AdvancedQueryViewStore);
	const { interval, isAutoInterval, isIntervalError, chartData, fetchChartDataWithAutoInterval, totalEvents } =
		useInstance(AdvancedQueryChartViewStore);

	const persistentTimeRangeToValue = persistentTimeRange?.value?.to || '';
	const tooltipId = useId();

	const selectedTimeRangeValues = useMemo(() => {
		return prepareTimeRangeForQuery(selectedBrushRange, chartData, persistentTimeRangeToValue);
	}, [chartData, selectedBrushRange, persistentTimeRangeToValue]);

	const hasSelection = Boolean(selectedBrushRange.length);

	const selectedEvents = useMemo(() => {
		if (!isStackedChartDataItemArray(chartData)) {
			return chartData.reduce((total: number, item) => {
				if (selectedBrushRange.includes(item[ChartStackBarKeys.Date])) {
					const itemEventsCount = Number(item[ChartStackBarKeys.EventsCount]);
					return total + itemEventsCount;
				}

				return total;
			}, 0);
		}

		return chartData.reduce((total: number, item) => {
			if (selectedBrushRange.includes(item[ChartStackBarKeys.Date])) {
				const itemEventsCount = Number(item[ChartStackBarKeys.TotalCount]);
				return total + itemEventsCount;
			}

			return total;
		}, 0);
	}, [chartData, selectedBrushRange]);

	const resetSelection = useCallback(() => {
		if (persistentTimeRange) {
			const timeRange = {
				from: persistentTimeRange.value?.from,
				to: persistentTimeRange.value?.to,
			};
			runCustomQuery(timeRange, persistentTimeRange.key, false);
		}
		resetBrushArea();
	}, [persistentTimeRange, resetBrushArea, runCustomQuery]);

	const zoomToSelection = useCallback(() => {
		if (hasSelection) {
			runCustomQuery(selectedTimeRangeValues, DateTimePickerOptionKeys.CUSTOM, false);
			addPreviousTimeRangeToHistory();
			fetchChartDataWithAutoInterval();
		}
	}, [hasSelection, runCustomQuery, selectedTimeRangeValues, addPreviousTimeRangeToHistory, fetchChartDataWithAutoInterval]);

	const zoomOut = useCallback(() => {
		if (!persistentTimeRange) {
			return;
		}
		const zoomOutTimeRange = getZoomOutTimeRange(persistentTimeRange);
		runCustomQuery(zoomOutTimeRange, DateTimePickerOptionKeys.CUSTOM, false);
		addPreviousTimeRangeToHistory();

		fetchChartDataWithAutoInterval();
	}, [persistentTimeRange, runCustomQuery, addPreviousTimeRangeToHistory, fetchChartDataWithAutoInterval]);

	return (
		<div className={styles.actionBlock} ref={actionBlockRef}>
			<div className={styles.leftBlock}>
				<ChartHistory />

				<span className={styles.selectedResults}>
					{Boolean(selectedEvents) && t('chart.actionBlock.selectedEvents', { selectedEvents: selectedEvents.toLocaleString() })}
					{t('chart.actionBlock.totalEvents', { totalEvents: totalEvents.toLocaleString() })}
				</span>

				{!hasSelection && persistentTimeRange && (
					<Button fillMode="flat" size={ButtonSize.Small} onClick={zoomOut}>
						<ZoomOutIcon /> {t('chart.actionBlock.zoomOut')}
					</Button>
				)}

				{hasSelection && <span className={styles.selectionRange}>({getTimeRangeString(selectedTimeRangeValues)})</span>}

				<div className={styles.selectionButtons}>
					{hasSelection && (
						<Button size={ButtonSize.Small} onClick={zoomToSelection}>
							<ZoomInIcon /> {t('chart.actionBlock.zoomToSelection')}
						</Button>
					)}

					{timeRange.isTemporal && (
						<Button size={ButtonSize.Small} fillMode="outline" onClick={resetSelection}>
							<CancelIcon /> {t('chart.actionBlock.resetSelection')}
						</Button>
					)}
				</div>
			</div>

			<div className={styles.rightBlock} data-testid="right-action-block">
				<div className={styles.interval}>
					{!hasSelection && (
						<span className={styles.intervalHeader}>
							{t('chart.actionBlock.interval')}:{' '}
							{isIntervalError && (
								<>
									<WarningIcon data-for={tooltipId} data-tip />
									<Tooltip
										tooltipId={tooltipId}
										effect="solid"
										tooltipContent={t('chart.actionBlock.intervalError', {
											interval: displayInterval(interval, false, isIntervalError ? DECIMAL_DIGITS_NUMBER : undefined),
										})}
										className={styles.tooltip}
										place={'top'}
										offsetTop={6}
									/>
								</>
							)}
							{displayInterval(interval, isAutoInterval, isIntervalError ? DECIMAL_DIGITS_NUMBER : undefined)}
						</span>
					)}
					<ChartSettings />
				</div>
			</div>
		</div>
	);
});

ChartActionBlock.displayName = 'ChartActionBlock';
