import { MouseEvent, KeyboardEvent } from 'react';
import { observer } from 'mobx-react-lite';

import { FilterDescriptorWithId, FilterOption, FilterValue, Filters } from '@/app/_common/types';

import { OptionGroupItem } from './option-group-item';
import { getItemDetails } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/utils';
import { FieldValue } from '@/generated/graphql';
import { isFieldValueFilter } from '@/app/_common/_components/data-grid/_components';
import { OptionItemDetails } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/types';
import { SelectMode } from './_common/constants';
import { ArrayFilterOperators, FilterOperators } from '@/app/_common/constants';

import styles from './option-group.module.scss';

const filterItemSelected = (
	filter: Filters | FilterDescriptorWithId[] | undefined,
	value: OptionItemDetails['value'],
): FieldValue | FilterDescriptorWithId | undefined => {
	if (isFieldValueFilter(filter)) {
		return filter.find((valueFilter) => {
			if (Array.isArray(valueFilter.value)) {
				return valueFilter.value.includes(value);
			}

			return valueFilter.value === value;
		});
	}
};
const isFilterItemSelected = (
	filter: Filters | FilterDescriptorWithId[] | undefined,
	value: OptionItemDetails['value'],
	groupSelected?: boolean,
): boolean => {
	if (isFieldValueFilter(filter)) {
		return filterItemSelected(filter, value) !== undefined;
	}

	return filter?.includes(value) || groupSelected || false;
};

interface OptionGroupProps {
	group: FilterOption[];
	onFilterChange: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, value: FilterValue[]) => void;
	filter?: Filters | FilterDescriptorWithId[];
	groupSelected?: boolean;
	selectMode?: SelectMode;
	onButtonClick?: (value: FilterValue, operator: ArrayFilterOperators) => void;
}

export const OptionGroup = observer<OptionGroupProps>(({ group, onFilterChange, filter, groupSelected, selectMode, onButtonClick }) => {
	return (
		<div className={styles.container}>
			<ul className="k-list k-reset" data-testid="options-list">
				{group.map((optionItem) => {
					const { value, label } = getItemDetails(optionItem);
					const isItemSelected = isFilterItemSelected(filter, value, groupSelected);
					const selectedOperator = filterItemSelected(filter, value)?.operator;

					return (
						<OptionGroupItem
							key={`${value}_${label}`}
							item={optionItem}
							selected={isItemSelected}
							onClick={onFilterChange}
							selectMode={selectMode}
							onButtonClick={onButtonClick}
							selectedOperator={selectedOperator as FilterOperators}
						/>
					);
				})}
			</ul>
		</div>
	);
});

OptionGroup.displayName = 'OptionGroup';
