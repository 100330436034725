import { FC, useCallback } from 'react';

import { AlertsHeaderFilter } from '@/app/dashboards/alerts-dashboard/_common/constants/alerts.constant';

import { Toggle, ToggleThemeColor } from '@/app/_common/_components/toggle';
import classNames from 'classnames';
import styles from './alerts-list-filter.module.scss';

interface AlertsListFilterProps {
	label: string;
	value: AlertsHeaderFilter;
	counter: number;
	checked: boolean;
	onToggle: (filter: AlertsHeaderFilter) => void;
}

const mapAlertFilterToToggleColor = (alertType: AlertsHeaderFilter): ToggleThemeColor => {
	const alertToToggleColorMapper = {
		[AlertsHeaderFilter.Selected]: ToggleThemeColor.Blue,
		[AlertsHeaderFilter.Unassigned]: ToggleThemeColor.Red,
		[AlertsHeaderFilter.Assigned]: ToggleThemeColor.Purple,
		[AlertsHeaderFilter.Dismissed]: ToggleThemeColor.AltBlue,
	};

	return alertToToggleColorMapper[alertType];
};

export const AlertsListFilter: FC<AlertsListFilterProps> = ({ checked, label, counter, onToggle, value }) => {
	const handleChange = useCallback(() => {
		onToggle(value);
	}, [onToggle, value]);

	const counterString = counter ? `(${counter})` : '';

	return (
		<div className={classNames(styles.alertsListFilter)}>
			<Toggle onChange={handleChange} label={`${label} ${counterString}`} value={checked} type={mapAlertFilterToToggleColor(value)} />
		</div>
	);
};
