import { QueryResultsTableViewStore } from '@/app/_common/_components/query-results/query-results-table/stores';
import { reaction } from 'mobx';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { ResultsDataStore } from '@/app/advanced-query/_common/stores';

export class AdvancedQueryResultsTableViewStore extends QueryResultsTableViewStore {
	private resultsDataStore = injectInterface(this, ResultsDataStore);

	queryResultsDisposer = reaction(
		() => this.resultsDataStore.data,
		(data) => {
			if (data) {
				this.setQueryResults(data);
			}
		},
	);

	dispose() {
		super.dispose();
		this.queryResultsDisposer();
	}
}
