import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CsvExport } from '@/app/_common/_components';
import { CsvExportData } from '@/app/_common/_components/csv-export/types';
import { AdvancedQueryPageKeyPrefix } from '@/app/_common/constants';
import { getFileNameWithDateTime } from '@/app/_common/utils';
import { Namespaces } from '@/translations/namespaces';

import styles from './query-results-table-item-export-csv-button.module.scss';

interface ResultItemExportToCSVButtonProps {
	data: CsvExportData;
	headers: string[];
}

export const QueryResultsTableItemExportToCSVButton: FC<ResultItemExportToCSVButtonProps> = ({ data = [], headers }) => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });
	const fileName = getFileNameWithDateTime(t('exportToCSVFileName'));

	return (
		<CsvExport data={data} headers={headers} fileName={fileName}>
			<span className={styles.link}>{t('results.exportToCSV')}</span>
		</CsvExport>
	);
};
