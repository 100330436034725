import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';

import { InvestigationPriority } from '@/generated/graphql';

import { InvestigationPropertiesPaths, SortDirection } from '@/app/_common/constants';
import { sortByOrder } from '@/app/_common/utils';
import { FilterOption } from '@/app/_common/types';

export const sortInvestigationFilters = (data: FilterOption[], path: string, field: string) => {
	if (field === InvestigationPropertiesPaths.Priority) {
		return sortByOrder<FilterOption>(
			data,
			path,
			[InvestigationPriority.Critical, InvestigationPriority.High, InvestigationPriority.Medium, InvestigationPriority.Low],
			SortDirection.Desc,
		);
	}

	return _orderBy<FilterOption>(
		data,
		[
			(option) => {
				const value = typeof path === 'string' ? _get(option, path) : option;
				return value.toLowerCase();
			},
		],
		[SortDirection.Asc],
	);
};
