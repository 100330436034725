import { QUERY_SEPARATOR, QUOTED_STRING_REGEX, SEPARATOR_WHITESPACE_REGEX } from '@/app/_common/constants';

export const getQueryContent = (query: string) => {
	let _query = query.trim();

	if (_query.length === 0) {
		return '';
	}

	if (_query.match(QUOTED_STRING_REGEX)) {
		_query = `search ${_query}`;
	} else {
		_query = _query
			.split(QUERY_SEPARATOR)
			.slice(1)
			.filter((value) => value && value.length > 0)
			.join(QUERY_SEPARATOR);
	}

	return _query.replace(SEPARATOR_WHITESPACE_REGEX, '').trim();
};
