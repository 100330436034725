import { FC, ReactNode, useState } from 'react';
import { useSlate } from 'slate-react';

import { IsCurrentFormatActive, AddLinkDialog } from '@/app/_common/_components/rich-text-editor';

import classNames from 'classnames';
import styles from '../editor-button.module.scss';
import { RichTextEditorTranslations } from '@/app/_common/_components/rich-text-editor/types';

interface LinkButtonProps {
	icon: ReactNode;
	format: string;
	translations: RichTextEditorTranslations;
	disabled?: boolean;
}

export const LinkButton: FC<LinkButtonProps> = ({ icon, disabled, format, translations }) => {
	const [openDialog, setOpenDialog] = useState(false);
	const [currentUrl, setCurrentUrl] = useState('');
	const editor = useSlate();

	const handleToggleDialog = () => {
		const { selection } = editor;

		if (selection !== null && selection.anchor !== null) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const href = editor.children[selection.anchor.path[0]] as any;
			if (href) {
				setCurrentUrl(href.children[1]?.href || '');
			}
		}
		if (!IsCurrentFormatActive(editor, format)) {
			setCurrentUrl('');
		}
		setOpenDialog((prev) => !prev);
	};

	return (
		<>
			<button
				className={classNames(styles.blockBtn, {
					[styles.active]: IsCurrentFormatActive(editor, format),
					[styles.disabled]: disabled,
				})}
				disabled={disabled}
				onClick={handleToggleDialog}
			>
				{icon}
			</button>
			<AddLinkDialog open={openDialog} handleToggleDialog={handleToggleDialog} currentUrl={currentUrl} translations={translations} />
		</>
	);
};
