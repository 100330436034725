import { InvestigationStatus } from '@/generated/graphql';

export const showStatus = (status: InvestigationStatus | undefined): boolean => {
	if (status === undefined) {
		return false;
	}

	switch (status) {
		case InvestigationStatus.Closed:
		case InvestigationStatus.Sleep:
			return true;
		case InvestigationStatus.Open:
			return false;
		default:
			return false;
	}
};
