import { AlertConfidence } from '@/app/_common/constants';

export const getConfidenceColorIndicator = (confidence: AlertConfidence) => {
	switch (confidence) {
		case AlertConfidence.Maximum:
			return ['var(--confidenceMax)', 'var(--confidenceMax)', 'var(--confidenceMax)', 'var(--confidenceMax)'];
		case AlertConfidence.High:
			return ['var(--confidenceHigh)', 'var(--confidenceHigh)', 'var(--confidenceHigh)'];
		case AlertConfidence.Medium:
			return ['var(--confidenceMedium)', 'var(--confidenceMedium)'];
		case AlertConfidence.Low:
			return ['var(--confidenceLow)'];
		case AlertConfidence.Unknown:
			return [];
		default:
			return [];
	}
};
