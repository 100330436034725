import { forwardRef, ReactNode, MouseEvent, KeyboardEvent, memo, FC, useMemo, useCallback } from 'react';

import classNames from 'classnames';
import _uniqueId from 'lodash/uniqueId';

import { GridCellProps } from '@progress/kendo-react-grid';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { Skeleton } from '@progress/kendo-react-indicators';

import { ContextMenuComponentProps } from '@/app/_common/types';
import { useContextMenu } from '@/app/_common/hooks';
import { AlertsWidgetContextMenu, ContextMenuContainer } from '@/app/_common/_components/data-grid/_components';
import { buttonize } from '@/app/_common/utils';
import { Tooltip } from '@/app/_common/_components';

import styles from './data-cell.module.scss';

const OFFSET_TOP = 5;

type TDEvent = MouseEvent<HTMLTableCellElement> | KeyboardEvent<HTMLTableCellElement>;

interface DataCellProps extends GridCellProps {
	children?: ReactNode;
	title?: string;
	empty?: boolean;
	onClick?: (event: TDEvent) => void;
	ContextMenuComponent?: FC<ContextMenuComponentProps>;
	tooltip?: string;
	oldContextMenu?: boolean;
}

export const DataCell = memo(
	forwardRef<HTMLTableCellElement, DataCellProps>(
		(
			{
				id,
				field,
				children,
				className,
				title,
				onClick,
				onContextMenu,
				style,
				colSpan,
				ariaColumnIndex,
				isSelected,
				dataItem,
				empty = false,
				ContextMenuComponent,
				tooltip,
				oldContextMenu = true,
			},
			ref,
		) => {
			const navigationAttributes = useTableKeyboardNavigation(id);

			const { showContextMenu, contextMenuOffset, openContextMenu, closeContextMenu, contextMenuRef } = useContextMenu();

			const tooltipId = useMemo(() => `data-cell-${_uniqueId()}`, []);
			const handleContextMenu = useCallback(
				(event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
					if (oldContextMenu) {
						event.preventDefault();

						if (!ContextMenuComponent) {
							return;
						}

						openContextMenu(event);
					}

					onContextMenu?.(event, dataItem, field);
				},
				[dataItem, field, oldContextMenu, ContextMenuComponent, onContextMenu, openContextMenu],
			);

			const handleClick = (event: TDEvent) => {
				if (typeof onClick === 'function') {
					onClick(event);
				}
			};

			if (dataItem?.loading) {
				return (
					<td id={id}>
						<Skeleton
							shape={'text'}
							style={{
								width: '100%',
							}}
						/>
					</td>
				);
			}

			return (
				<>
					{oldContextMenu && showContextMenu && ContextMenuComponent && (
						<ContextMenuContainer
							containerRef={contextMenuRef}
							show={showContextMenu}
							offset={contextMenuOffset}
							className={styles.contextMenuContainer}
						>
							<ContextMenuComponent closeContextMenu={closeContextMenu} />
						</ContextMenuContainer>
					)}
					<Tooltip tooltipId={tooltipId} tooltipContent={tooltip} offsetTop={OFFSET_TOP} />
					<td
						id={id}
						ref={ref}
						title={title}
						className={classNames(className, styles.cell, {
							[styles.empty]: empty,
							[styles.showContextMenu]: oldContextMenu && showContextMenu,
							[styles.contextMenuAvailable]: Boolean(AlertsWidgetContextMenu) && ContextMenuComponent,
						})}
						style={style}
						colSpan={colSpan}
						aria-colindex={ariaColumnIndex}
						aria-selected={isSelected}
						onContextMenu={handleContextMenu}
						data-for={tooltipId}
						data-tip={true}
						{...navigationAttributes}
						{...buttonize<HTMLTableCellElement>(handleClick)}
					>
						{empty ? '-' : children}
					</td>
				</>
			);
		},
	),
);

DataCell.displayName = 'DataCell';
