export const DEFAULT_QUERY_RESULT_PAGE_SIZE = 2;
export const READ_MORE_QUERY_RESULT_PAGE_SIZE = 1;
export const READ_MORE_THRESHOLD = 1;

export enum QueryResultTypename {
	QueryResult = 'QueryResult',
	QueryResultEdge = 'QueryResultEdge',
	QueryResultConnection = 'QueryResultConnection',
	Error = 'Error',
}

export const REASON_DIALOG_WIDTH = 'min(90%, 900px)';

export enum ReasonKeyPrefix {
	Reason = 'reason',
}
