import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDetailsKeyPrefix } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';

import styles from './threat-intelligence-panel-content-comment-section.module.scss';

interface ThreatIntelligencePanelContentCommentSectionProps {
	comments: string[];
	className?: string;
}

export const ThreatIntelligencePanelContentCommentSection: FC<ThreatIntelligencePanelContentCommentSectionProps> = ({ comments, className = '' }) => {
	const { t } = useTranslation(Namespaces.DetailsView, { keyPrefix: AlertDetailsKeyPrefix.ThreatIntelligencePanel });

	if (!comments.length) {
		return null;
	}

	return (
		<div className={className}>
			<h4>{t('analystComment')}</h4>
			<ul className={styles.commentList}>
				{comments.map((comment) => (
					<p key={comment}>{comment}</p>
				))}
			</ul>
		</div>
	);
};
