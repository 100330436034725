import { getPathParam } from './get-path-param';

export const getCorrespondingSubpath = (routePath: string, path: string): string => {
	if (!routePath || !path) {
		return '';
	}

	const splitRoutePath = routePath.split('/');
	const splitPath = path.split('/');
	let resultPath = '';

	splitRoutePath.forEach((routePart, index, array) => {
		const pathPart = splitPath[index];

		resultPath += getPathParam(routePart, pathPart);

		if (index < array.length - 1) {
			resultPath += '/';
		}
	});

	return resultPath;
};
