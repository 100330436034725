import { FC } from 'react';
import { Button } from '@/app/_common/_components';
import { ChevronLeft, ChevronRight, Close } from '@mui/icons-material';
import { buttonize } from '@/app/_common/utils';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '@/translations/namespaces';
import { AlertDetailsKeyPrefix } from '@/app/_common/constants';

import styles from './threat-intelligence-panel-header.module.scss';

interface ThreatIntelligencePanelHeaderProps {
	handleGoToPreviousHit: () => void;
	handleGoToNextHit: () => void;
	handleHidePopup: () => void;
	isTheFirstHitElement: boolean;
	isTheLastHitElement: boolean;
	currentHitIndex: number;
	totalMatches: number;
}

export const ThreatIntelligencePanelHeader: FC<ThreatIntelligencePanelHeaderProps> = ({
	handleGoToPreviousHit,
	handleGoToNextHit,
	handleHidePopup,
	isTheFirstHitElement,
	isTheLastHitElement,
	currentHitIndex,
	totalMatches,
}) => {
	const { t } = useTranslation(Namespaces.DetailsView, { keyPrefix: AlertDetailsKeyPrefix.ThreatIntelligencePanel });

	const showSliderButton = totalMatches > 1;

	return (
		<div className={styles.header}>
			<div className={styles.slider}>
				{showSliderButton && (
					<Button className={styles.arrowButton} onClick={handleGoToPreviousHit} disabled={isTheFirstHitElement}>
						<ChevronLeft />
					</Button>
				)}
				<p className={styles.title}>
					{t('threatIntelligenceHit')} {showSliderButton && <span className={styles.counter}>({`${currentHitIndex + 1} of ${totalMatches}`})</span>}
				</p>
				{showSliderButton && (
					<Button className={styles.arrowButton} onClick={handleGoToNextHit} disabled={isTheLastHitElement}>
						<ChevronRight />
					</Button>
				)}
			</div>
			<p className={styles.close}>
				<span className={styles.closeButton} {...buttonize(handleHidePopup)}>
					{t('close')}
				</span>
				<Close onClick={handleHidePopup} />
			</p>
		</div>
	);
};
