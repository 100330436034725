import classNames from 'classnames';

import { DataCell } from '@/app/_common/_components/data-grid/_components';
import { SingleItemCellProps } from '../../types';
import { MitreAttackBadge } from '@/app/_common/_components/mitre-attack-badge';
import { getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';

import styles from './mitre-tactic-single-item-cell.module.scss';

export const MitreTacticSingleItemCell = (props: SingleItemCellProps) => {
	const { value, searchValue, isCellHighlighted } = props;
	const mitreText = getMitreAttackBadgeText(String(value));

	return (
		<DataCell {...props} title={mitreText} className={classNames(styles.mitreSingleItemCell, { isCellHighlighted })}>
			<MitreAttackBadge text={mitreText} searchValue={searchValue} className={styles.mitreAttackBadge} />
		</DataCell>
	);
};
