import _sortBy from 'lodash/sortBy';
import { AdxTables, SortDirection } from '../../constants';
import { sortByOrder } from '../sort/sort-by-order';

export const getSupportedTables = (tables: string[], supportedTableNames: AdxTables[], label: (key: string) => string, customOrder?: AdxTables[]) => {
	const unordered = tables
		.filter((table) => supportedTableNames.includes(table as AdxTables))
		.map((table) => ({
			value: table,
			label: label(table),
		}));

	if (customOrder) {
		return sortByOrder(unordered, 'value', customOrder, SortDirection.Desc);
	}

	return _sortBy(unordered, ['label']);
};
