import { FC, useCallback } from 'react';

import classNames from 'classnames';
import _get from 'lodash/get';

import { GridCellProps } from '@progress/kendo-react-grid';

import { AlertEdge, InvestigationAlertSummary } from '@/generated/graphql';

import { ColorIndicator, ColorIndicatorWithValueWrapper } from '@/app/_common/_components';
import { DataCell, HighlightedText } from '@/app/_common/_components/data-grid/_components';
import { getConfidenceColorIndicator } from '@/app/_common/utils/colors';
import { getConfidenceLabel } from '@/app/_common/utils';
import { ContextMenuComponentProps } from '@/app/_common/types';

export interface ConfidenceCellProps extends GridCellProps {
	onClick?: (dataItem: AlertEdge | InvestigationAlertSummary) => void;
	ContextMenuComponent?: FC<ContextMenuComponentProps>;
	searchValue?: string;
	showLabel?: boolean;
	ignoreUnknownValue?: boolean;
	oldContextMenu?: boolean;
}

export const ConfidenceCell: FC<ConfidenceCellProps> = ({
	field,
	dataItem,
	onClick = () => null,
	ContextMenuComponent,
	showLabel,
	searchValue,
	ignoreUnknownValue = false,
	...rest
}) => {
	const value = _get(dataItem, field || '');
	const label = getConfidenceLabel(value);
	const isCellHighlighted = searchValue && String(label).toLowerCase().includes(searchValue.toLowerCase());
	const highlightedValue = searchValue ? <HighlightedText value={label} searchLine={searchValue} /> : label;

	const handleCellClick = useCallback(() => {
		if (typeof onClick === 'function') {
			onClick(dataItem);
		}
	}, [dataItem, onClick]);

	if (ignoreUnknownValue && value === null) {
		return <DataCell empty={true} dataItem={dataItem} ContextMenuComponent={ContextMenuComponent} {...rest} />;
	}

	return (
		<DataCell
			title={label}
			field={field}
			onClick={handleCellClick}
			dataItem={dataItem}
			ContextMenuComponent={ContextMenuComponent}
			{...rest}
			className={classNames({ isCellHighlighted })}
		>
			<ColorIndicatorWithValueWrapper>
				<ColorIndicator bulletsConfig={getConfidenceColorIndicator(value)} /> <span>{showLabel ? highlightedValue : ''}</span>
			</ColorIndicatorWithValueWrapper>
		</DataCell>
	);
};
