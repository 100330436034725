import _get from 'lodash/get';

import { SortDirection } from '@/app/_common/constants';

export function sortArraysByOrder<T, O>(data: T[], path: string, order: O[], direction?: SortDirection): T[] {
	if (!direction) {
		return data;
	}

	const priorityToCompare: number[] = order.map((item: O, index: number) => index);
	const isDesc: boolean = direction === SortDirection.Desc;

	return data.slice().sort((row1: T, row2: T): number => {
		const value1 = _get(row1, path) ?? [];
		const value2 = _get(row2, path) ?? [];
		const priorities1 = value1.map((val: O) => order.indexOf(val)).sort((a: number, b: number) => a - b);
		const priorities2 = value2.map((val: O) => order.indexOf(val)).sort((a: number, b: number) => a - b);

		for (let i = 0; i < Math.min(priorities1.length, priorities2.length); i++) {
			const index1: number = priorityToCompare.indexOf(priorities1[i]);
			const index2: number = priorityToCompare.indexOf(priorities2[i]);

			if (index1 !== index2) {
				return isDesc ? index1 - index2 : index2 - index1;
			}
		}

		return isDesc ? priorities2.length - priorities1.length : priorities1.length - priorities2.length;
	});
}
