import { FC } from 'react';
import { Namespaces } from '@/translations/namespaces';

import { useTranslation } from 'react-i18next';
import { ExplanationItem } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/alerts-details-timeline';

import styles from './timeline-explanation-item.module.scss';

export const TimelineExplanationItem: FC<ExplanationItem> = ({ title, icon, silenced, boostScore, description }) => {
	const { t } = useTranslation([Namespaces.DetailsView]);

	return (
		<div className={styles.explanationItem}>
			<header className={styles.header}>
				<img src={icon} alt={title} className={styles.icon} />
				<h3 className={styles.title}>{title}</h3>
			</header>
			<div className={styles.explanationInfo}>
				{boostScore && (
					<span>
						{t('timeLine.boostScore')} {boostScore}
					</span>
				)}
				{silenced && (
					<span>
						{t('timeLine.silenced')} {silenced}
					</span>
				)}
				{description && <span>{description}</span>}
			</div>
		</div>
	);
};
