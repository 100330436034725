import { action, computed, makeObservable, reaction } from 'mobx';

import { AlertsTimeRangeFilterStore } from '@/app/dashboards/alerts-dashboard/_common/stores';
import { getQueryInputVariables } from '@/app/_common/utils';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { FieldTimestampRange, FieldValue, Paging, Query, QueryListAlertsArgs, TimestampFilterField } from '@/generated/graphql';

import { GetAlerts } from '@/app/_common/graphql/queries';

export type ListAlertsQuery = Pick<Query, 'listAlerts'>;

export class AlertsDataStore extends GraphqlBaseDataStore<ListAlertsQuery, QueryListAlertsArgs> {
	private filtersStore = injectInterface(this, AlertsTimeRangeFilterStore);

	constructor() {
		super();
		makeObservable(this, {
			alerts: computed,
			pageInfo: computed,
			hasPreviousPage: computed,
			hasNextPage: computed,
			alertsTimestampFilters: computed,
			read: action,
			readMore: action,
		});
	}

	get alerts() {
		const alerts = this.data?.listAlerts?.edges;

		return alerts || [];
	}

	get pageInfo() {
		return this.data?.listAlerts?.pageInfo;
	}

	get hasPreviousPage() {
		return this.pageInfo?.hasPreviousPage;
	}

	get hasNextPage() {
		return this.pageInfo?.hasNextPage;
	}

	get alertsTimestampFilters(): FieldTimestampRange[] {
		const timestampFilters: FieldTimestampRange[] = [];

		if (this.filtersStore.filters?.timeRange?.from && this.filtersStore.filters?.timeRange?.to) {
			timestampFilters.push({
				field: TimestampFilterField.Timestamp,
				range: this.filtersStore.filters.timeRange,
			});
		}

		return timestampFilters;
	}

	readMore(filters: FieldValue[] = []) {
		const { hasNextPage, endCursor } = this.pageInfo || {};

		if (hasNextPage) {
			const variables = {
				tenantId: this.authStore.currentTenantId,
				input: getQueryInputVariables({ after: endCursor }),
				filtersInput: {
					timestampFilters: this.alertsTimestampFilters,
					valueFilters: filters,
				},
			};

			return this.fetchMore({
				query: GetAlerts,
				variables,
			});
		}
	}

	read(valueFilters: FieldValue[] = [], timestampFilters?: FieldTimestampRange[], pagingInput?: Paging) {
		const variables = {
			tenantId: this.authStore.currentTenantId,
			input: pagingInput ?? getQueryInputVariables(),
			filtersInput: {
				timestampFilters: timestampFilters ?? this.alertsTimestampFilters,
				valueFilters: valueFilters,
			},
		};

		this.query({
			query: GetAlerts,
			variables,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		});
	}

	filtersChangeDisposer = reaction(
		() => this.alertsTimestampFilters,
		() => {
			this.read();
		},
	);

	dispose() {
		this.filtersChangeDisposer();
	}
}
