import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';

import { ExpansionPanelWrapper } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components';
import { Evidence } from '@/app/_common/types';
import { RawField } from '@/app/_common/_components/details-view/components';
import { Namespaces } from '@/translations/namespaces';
import { formatDataForSyntaxHighlighter } from '@/app/_common/utils';

import styles from './alert-evidence.module.scss';

interface AlertEvidenceProps {
	evidence: Evidence;
}

export const AlertEvidence: FC<AlertEvidenceProps> = ({ evidence }) => {
	const { t } = useTranslation(Namespaces.DetailsView);

	const { alert_id, alert_raw } = evidence;

	const parsedValue = useMemo(() => {
		return formatDataForSyntaxHighlighter(alert_raw);
	}, [alert_raw]);

	if (alert_raw === undefined) {
		return null;
	}

	return (
		<div className={styles.container}>
			<div className={styles.alertRawContainer}>
				<ExpansionPanelWrapper title={t('rawField.expansionPanel.title')} expanded={true} fullWidth>
					<RawField itemId={alert_id} value={parsedValue} />
				</ExpansionPanelWrapper>
			</div>
		</div>
	);
};
