import { useCallback, useState, MouseEvent, KeyboardEvent, useEffect, useRef, MutableRefObject, memo } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import classNames from 'classnames';

import { Popup } from '@progress/kendo-react-popup';
import { useTranslation } from 'react-i18next';

import { PopupOffset } from '@/app/_common/types';
import { ContextMenuContainer, DataCell, TrimTextCell } from '@/app/_common/_components/data-grid/_components';
import { buttonize } from '@/app/_common/utils';
import { useOutsideClick } from '@/app/_common/hooks';
import { Namespaces } from '@/translations/namespaces';
import {
	MultiValueCellProps,
	SingleItemCellProps,
	SingleItemCellRendererMap,
	MultiItemsCellContentProps,
	MultiItemsCellContentRendererMap,
	DropdownItemComponentProps,
	DropdownItemRendererMap,
} from './types';
import {
	DefaultSingleItemCell,
	DefaultCellContent,
	DefaultDropdownItem,
	MitreTacticCellContent,
	DropdownMitreTacticsItem,
	MitreTacticSingleItemCell,
} from './components';
import { AlertsDetailsSessionsPropertiesPaths } from '@/app/_common/constants/alert-details-sessions.constants';
import { sortArrayFieldValue } from '@/app/_common/_components/data-grid/utils';

import styles from './multi-value-cell.module.scss';
import { MitreTechniqueSingleItemCell } from './components/mitre-technique-single-item-cell';
import { DropdownMitreTechniquesItem } from './components/dropdown-mitre-techniques-item';

const singleItemCellRenderers: SingleItemCellRendererMap = {
	default: DefaultSingleItemCell,
	[AlertsDetailsSessionsPropertiesPaths.MitreTactics]: MitreTacticSingleItemCell,
	[AlertsDetailsSessionsPropertiesPaths.MitreTechniques]: MitreTechniqueSingleItemCell,
};

const renderSingleItemCellByFieldName = (props: SingleItemCellProps, field = '') => {
	const Component = singleItemCellRenderers[field] || singleItemCellRenderers['default'];
	return <Component {...props} />;
};

const multiItemsCellContentRenderers: MultiItemsCellContentRendererMap = {
	default: DefaultCellContent,
	[AlertsDetailsSessionsPropertiesPaths.MitreTactics]: MitreTacticCellContent,
};

const renderMultiItemCellContentByFieldName = (props: MultiItemsCellContentProps, field = '') => {
	const Component = multiItemsCellContentRenderers[field] || multiItemsCellContentRenderers['default'];
	return <Component {...props} />;
};

const dropdownItemRenderers: DropdownItemRendererMap = {
	default: DefaultDropdownItem,
	[AlertsDetailsSessionsPropertiesPaths.MitreTactics]: DropdownMitreTacticsItem,
	[AlertsDetailsSessionsPropertiesPaths.MitreTechniques]: DropdownMitreTechniquesItem,
};

const renderDropdownItemComponentByFieldName = (props: DropdownItemComponentProps, field = '') => {
	const Component = dropdownItemRenderers[field] || dropdownItemRenderers['default'];
	return <Component {...props} />;
};

export const MultiValueCell = memo((props: MultiValueCellProps) => {
	const { t } = useTranslation([Namespaces.Common], { keyPrefix: 'grid' });
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [nestedContextMenuId, setNestedContextMenuId] = useState('');
	const [nestedContextMenuOffset, setNestedContextMenuOffset] = useState<PopupOffset>({ left: 0, top: 0 });
	const prevNestedContextMenuState: MutableRefObject<boolean> = useRef(Boolean(nestedContextMenuId));

	const { anchorRef, popupRef, nestedPopupRef } = useOutsideClick<HTMLTableCellElement, HTMLDivElement>(
		isDropdownOpen,
		useCallback(() => !nestedContextMenuId && setIsDropdownOpen(false), [nestedContextMenuId]),
		Boolean(nestedContextMenuId),
		useCallback(() => {
			setNestedContextMenuId('');
			return setIsDropdownOpen(false);
		}, []),
	);

	const { dataItem, ContextMenuComponent, searchValue, oldContextMenu = true, ...rest } = props;
	const { onContextMenu, field } = rest;
	const value = _get(dataItem, field || '');
	const hasValue = (typeof value === 'object' && !_isEmpty(value)) || !_isNil(value);

	const isCellHighlighted = searchValue && String(value).toLowerCase().includes(searchValue.toLowerCase());

	/* Nested context menu handlers */
	const handleShowNestedContextMenu = (id: string) => (event: MouseEvent) => {
		event.preventDefault();
		if (!ContextMenuComponent) {
			return;
		}
		const offset = { left: event.clientX, top: event.clientY };
		setNestedContextMenuOffset(offset);
		setNestedContextMenuId(id);
	};

	const handleCloseNestedContextMenu = () => {
		setNestedContextMenuId('');
	};
	/* End of nested context menu handlers */

	/* Handle dropdown close only after nested context menu close, as otherwise no action is triggered */
	const prevNestedContextMenuStateCurrent = prevNestedContextMenuState.current;

	// Save previous open state of nested context menu
	useEffect(() => {
		prevNestedContextMenuState.current = Boolean(nestedContextMenuId);
	}, [nestedContextMenuId]);

	// Close dropdown only after the nested context menu is closed
	useEffect(() => {
		if (prevNestedContextMenuStateCurrent && !nestedContextMenuId) {
			setIsDropdownOpen(false);
		}
	}, [nestedContextMenuId, prevNestedContextMenuStateCurrent]);
	/* End of dropdown close effect block */

	const handleCellClick = useCallback((event: MouseEvent<HTMLTableCellElement> | KeyboardEvent<HTMLTableCellElement>) => {
		event.stopPropagation();
		setIsDropdownOpen((open) => !open);
	}, []);

	const handleContextMenu = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			if (!field || !dataItem) {
				return;
			}

			if (event?.target instanceof HTMLElement) {
				const valueElement = event?.target?.closest('[data-value]');

				if (valueElement instanceof HTMLElement) {
					const value = valueElement?.dataset.value;

					onContextMenu?.(
						event,
						{
							...dataItem,
							[field]: value,
						},
						field,
					);
				}
			}
		},
		[onContextMenu, dataItem, field],
	);

	if (!hasValue) {
		return <DataCell {...rest} dataItem={dataItem} field={field} empty={true} oldContextMenu={oldContextMenu} />;
	}

	if (Array.isArray(value) && value.length === 1) {
		const itemValue = value[0];
		const isCellHighlighted = searchValue && String(itemValue).toLowerCase().includes(searchValue.toLowerCase());
		const singleItemCellProps: SingleItemCellProps = {
			...props,
			value: itemValue,
			isCellHighlighted: Boolean(isCellHighlighted),
		};
		return renderSingleItemCellByFieldName(singleItemCellProps, field);
	}

	if (Array.isArray(value) && value.length > 1) {
		const sortedValues = sortArrayFieldValue(value, field);
		const multiItemsCellContentProps: MultiItemsCellContentProps = { value: sortedValues };
		const CellContent = renderMultiItemCellContentByFieldName(multiItemsCellContentProps, field);

		return (
			<>
				<DataCell
					{...rest}
					title={`${t('multiple')} (${sortedValues.length})`}
					ref={anchorRef}
					dataItem={dataItem}
					className={classNames({ isCellHighlighted })}
					ContextMenuComponent={ContextMenuComponent}
					oldContextMenu={oldContextMenu}
					onContextMenu={onContextMenu}
					{...buttonize<HTMLTableCellElement>(handleCellClick)}
				>
					<div className={classNames(styles.cell, { [styles.open]: isDropdownOpen, isCellHighlighted })}>{CellContent}</div>
				</DataCell>

				{/* Prevents extra calculations, as data might be very heavy */}
				{isDropdownOpen && (
					<Popup anchor={anchorRef.current} show={isDropdownOpen} popupClass={styles.popup}>
						<div ref={popupRef} className={styles.dropdownContainer}>
							{sortedValues.map((item: string | number, index: number) => {
								const key = `${rest.id}-${field}-${index}`;
								const isNestedContextMenuOpen = oldContextMenu ? ContextMenuComponent && nestedContextMenuId === key : nestedContextMenuId === key;
								const isItemHighlighted = searchValue && String(item).toLowerCase().includes(searchValue.toLowerCase());
								const dropdownItemComponentProps: DropdownItemComponentProps = {
									value: item,
									searchValue,
									isCellHighlighted: Boolean(isItemHighlighted),
									isContextMenuOpen: Boolean(isNestedContextMenuOpen),
								};

								if (oldContextMenu) {
									return (
										<div key={key} onContextMenu={handleShowNestedContextMenu(key)} {...buttonize<HTMLDivElement>(handleCloseNestedContextMenu)}>
											{renderDropdownItemComponentByFieldName(dropdownItemComponentProps, field)}
											{isNestedContextMenuOpen && ContextMenuComponent && (
												<ContextMenuContainer containerRef={nestedPopupRef} show={isNestedContextMenuOpen} offset={nestedContextMenuOffset}>
													<ContextMenuComponent {...props} closeContextMenu={handleCloseNestedContextMenu} customValue={item} />
												</ContextMenuContainer>
											)}
										</div>
									);
								}

								return (
									<div key={key} onContextMenu={handleContextMenu}>
										{renderDropdownItemComponentByFieldName(dropdownItemComponentProps, field)}
									</div>
								);
							})}
						</div>
					</Popup>
				)}
			</>
		);
	}

	return (
		<TrimTextCell {...props} oldContextMenu={oldContextMenu} ContextMenuComponent={ContextMenuComponent} title={value} searchValue={searchValue} />
	);
});

MultiValueCell.displayName = 'MultiValueCell';
