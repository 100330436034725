import { QUERY_SEPARATOR } from '@/app/_common/constants';
import { TableColumn } from '@/app/_common/types';

export const getUnionQuery = (columns: TableColumn[]): string => {
	const columnsSummarizedList = columns.map(({ ColumnName }) => `summarized_${ColumnName}`).join(', ');

	const baseSection = `union ${columnsSummarizedList}`;
	const sortSection = `sort by ColumnName asc`;

	return [baseSection, sortSection].join(` ${QUERY_SEPARATOR} `);
};
