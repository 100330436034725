import classNames from 'classnames';

import styles from './default-dropdown-item-container.module.scss';

interface DefaultDropdownItemContainerProps {
	children: JSX.Element;
	isCellHighlighted?: boolean;
	isContextMenuOpen?: boolean;
	value: string | number;
}

export const DefaultDropdownItemContainer = ({ value, isCellHighlighted, isContextMenuOpen, children }: DefaultDropdownItemContainerProps) => {
	return (
		<div data-value={value} className={classNames(styles.dropdownItemContainer, { isCellHighlighted, isContextMenuOpen })}>
			{children}
		</div>
	);
};
