import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import { ContentCopy } from '@mui/icons-material';

import { Namespaces } from '@/translations/namespaces';
import { Tooltip } from '@/app/_common/_components';

import styles from './copy-button.module.scss';

interface CopyButtonProps {
	value: string | number | boolean | null | undefined | string[] | number[];
	label?: string;
	className?: string;
	onCopyValue?: () => void;
}

export const CopyButton: FC<CopyButtonProps> = ({ value, label, className, onCopyValue }) => {
	const { t } = useTranslation([Namespaces.Common], { keyPrefix: 'copyButton.tooltip' });
	const tooltipId = useMemo(() => uuid(), []);

	const handleCopy = async () => {
		if (!value) {
			await navigator.clipboard.writeText('');
		} else {
			await navigator.clipboard.writeText(String(value));
		}

		if (onCopyValue) {
			onCopyValue();
		}
	};

	return (
		<div
			className={classNames(styles.copyButton, className)}
			data-tip="tooltip"
			data-event="click"
			data-event-off="click"
			data-delay-hide="1500"
			data-for={tooltipId}
		>
			<ContentCopy />
			{label && <span>{label}</span>}
			<Tooltip tooltipId={tooltipId} effect="solid" afterShow={handleCopy} tooltipContent={t('success')} offsetTop={5} />
		</div>
	);
};
