import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';
import { computedFn } from 'mobx-utils';

import { filterBy } from '@progress/kendo-data-query';

import { AlertsHeaderFilter } from '@/app/dashboards/alerts-dashboard/_common/constants/alerts.constant';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AlertEdge, AlertState } from '@/generated/graphql';

import { FilterDescriptorWithId, FilterOption, Filters, FilterValue } from '@/app/_common/types';
import { AlertPropertiesPaths, AlertStateOptions, FilterGroupId, FilterLogic, FilterOperators, SortDirection } from '@/app/_common/constants';
import { getNullishFilterOption, migrateToNewestVersion, sortAlerts } from '@/app/_common/utils';

import { AlertsDataStore } from '@/app/dashboards/alerts-dashboard/_common/stores/alerts.data-store';
import { AlertsUtilsViewStore, AuthStore } from '@/app/_common/stores';
import { SwitchTenantStore } from '@/app/_common/stores/switch-tenant.store';
import {
	getQualifiedAlertNodes,
	isAlertDismissable,
	isAlertUndismissable,
	isAlertUnassignable,
	isAlertAssignable,
} from '@/app/dashboards/alerts-dashboard/_common/utils';
import { AlertsCountDataStore } from '../../_common/stores/alerts-count.data-store';
import { mapFilterOption, getSeverityOption, getConfidenceOption, getStateOption } from '@/app/_common/_components/data-grid/utils';
import { getDetailsViewItemData } from '@/app/_common/_components/details-view/utils';

const INITIAL_COLUMNS = {
	[AlertPropertiesPaths.Timestamp]: true,
	[AlertPropertiesPaths.State]: true,
	[AlertPropertiesPaths.Severity]: true,
	[AlertPropertiesPaths.Confidence]: true,
	[AlertPropertiesPaths.Title]: true,
	[AlertPropertiesPaths.Source]: true,
	[AlertPropertiesPaths.Destination]: true,
	[AlertPropertiesPaths.Mitre]: true,
	[AlertPropertiesPaths.DetectedBy]: true,
};

interface State {
	activeEqualFilters: Record<string, Filters>;
}

const INITIAL_STATE: State = {
	activeEqualFilters: {},
};

const INITIAL_SORT = [{ field: AlertPropertiesPaths.Timestamp, dir: SortDirection.Desc }];

const EMPTY_FIELD_VALUE = 'empty';

const MIN_ALERTS_NUMBER = 50;
const MAX_ALERTS_NUMBER = 1000;

const ALERTS_LIST_PERSISTABLE_KEY = 'ls/dashboards/alerts/widget';
const ALERTS_LIST_VERSION = 'v2'; // change it when you're changing columns
const ALERTS_LIST_SESSION_STORAGE_PERSISTABLE_KEY = 'ss/dashboards/alerts/widget';

export class AlertsListViewStore extends AlertsUtilsViewStore<AlertEdge> {
	private dataStore = injectInterface(this, AlertsDataStore);
	private dataTotalStore = injectInterface(this, AlertsCountDataStore);
	private authStore = injectInterface(this, AuthStore);
	private switchTenantStore = new SwitchTenantStore('AlertsListViewStore');
	private state = INITIAL_STATE;
	private readMoreRetries = 0;

	constructor() {
		super(AlertPropertiesPaths.Id, INITIAL_COLUMNS, INITIAL_SORT, sortAlerts, false, ALERTS_LIST_VERSION);

		migrateToNewestVersion(ALERTS_LIST_PERSISTABLE_KEY, ALERTS_LIST_VERSION);

		makeObservable(this, {
			//@ts-ignore
			state: observable,
			activeEqualFilters: computed,
			unassignedCount: computed,
			assignedCount: computed,
			dismissedCount: computed,
			loading: computed,
			headerFilter: computed,
			gridHeaderFilter: computed,
			headerFilterValues: computed,
			dataCount: computed,
			alerts: computed,
			totalCount: computed,
			totalLoading: computed,
			shouldAutoFetchMore: computed,
			selectedAssignableAlertIds: computed,
			selectedDismissableAlertIds: computed,
			selectedUndismissableAlertIds: computed,
			selectedUnassignableAlertIds: computed,
			selectedAssignedAlertsCount: computed,
			selectedUnassignedAlertsCount: computed,
			selectedDismissedAlertsCount: computed,
			selectedUndismissedAlertsCount: computed,
			error: computed,
			isReadMoreDisabledByFilters: computed,
			refresh: action,
			read: action,
			readMore: action,
			setHeaderFilter: action,
			setGridHeaderFilter: action,
			resetGridHeaderFilter: action,
			dispose: action,
		});

		const alertsListPersistableStateString = window.sessionStorage.getItem(
			`${this.authStore.currentTenantId}/${ALERTS_LIST_SESSION_STORAGE_PERSISTABLE_KEY}`,
		);
		const alertsListPersistableState = JSON.parse(alertsListPersistableStateString ?? '{}');

		this.initializeSessionStorage()?.then(() => {
			if (!alertsListPersistableState?.filter?.filters) {
				this.setDefaultFilter();
			}

			this.read();
		});

		makePersistable(this.gridState, {
			name: ALERTS_LIST_PERSISTABLE_KEY,
			properties: ['columns', 'version'],
			storage: window.localStorage,
		});
	}

	get alerts() {
		return this.data;
	}

	get dataCount(): number {
		return this.data.length;
	}

	get unassignedCount(): number {
		return this.rawData.filter(({ node: { state } }) => state?.alertState !== AlertState.Assigned && state?.alertState !== AlertState.Dismissed)
			.length;
	}

	get assignedCount(): number {
		return this.rawData.filter(
			({ node: { investigationSummary, state } }) => investigationSummary !== null && state?.alertState === AlertState.Assigned,
		).length;
	}

	get dismissedCount(): number {
		return this.rawData.filter(
			({ node: { investigationSummary, state } }) => investigationSummary === null && state?.alertState === AlertState.Dismissed,
		).length;
	}

	get loading() {
		return this.dataStore.loading;
	}

	get error() {
		return this.dataStore.error;
	}

	get hasNextPage() {
		return Boolean(this.dataStore.hasNextPage);
	}

	get hasPreviousPage() {
		return Boolean(this.dataStore.hasPreviousPage);
	}

	get totalCount() {
		return this.dataTotalStore.counts.total ?? 0;
	}

	get totalCountWithHeaderFilters() {
		let total = 0;

		if (this.headerFilterValues.includes(AlertsHeaderFilter.Assigned)) {
			total += this.dataTotalStore.counts.assignment.assigned;
		}

		if (this.headerFilterValues.includes(AlertsHeaderFilter.Unassigned)) {
			total += this.dataTotalStore.counts.assignment.unassigned;
		}

		if (this.headerFilterValues.includes(AlertsHeaderFilter.Dismissed)) {
			total += this.dataTotalStore.counts.assignment.dismissed;
		}

		if (this.headerFilterValues.length === 0) {
			total += this.dataTotalStore.counts.total;
		}

		return total;
	}

	get totalLoading() {
		return !this.loading && this.dataTotalStore.loading;
	}

	get headerFilter() {
		const filter = this.getFilter(FilterGroupId.header);

		if (!filter?.filters) {
			return [];
		}

		return filter.filters;
	}

	get gridHeaderFilter() {
		const filter = this.getFilter(FilterGroupId.gridHeader);

		if (!filter?.filters) {
			return [];
		}

		return filter.filters;
	}

	get headerFilterValues(): AlertsHeaderFilter[] {
		const filter = this.getFilter(FilterGroupId.header);

		if (!filter?.filters) {
			return [];
		}

		return filter.filters.reduce<AlertsHeaderFilter[]>((result, filter) => {
			if ('id' in filter && filter.id) {
				result.push(filter.id as AlertsHeaderFilter);
			}

			return result;
		}, []);
	}

	get activeEqualFilters() {
		return this.state.activeEqualFilters;
	}

	get selectedAlertIds(): string[] {
		return this.selectedElements.reduce((acc, curr) => [...acc, curr.node.id], [] as string[]);
	}

	get selectedAssignableAlertIds(): string[] {
		const alertIds = this.selectedElements.reduce((acc, curr) => {
			if (isAlertAssignable(getDetailsViewItemData(curr.node))) {
				acc = [...acc, curr.node.id];
			}
			return acc;
		}, [] as string[]);

		return alertIds;
	}

	get selectedUnassignableAlertIds(): string[] {
		const alertIds = this.selectedElements.reduce((acc, curr) => {
			if (isAlertUnassignable(getDetailsViewItemData(curr.node))) {
				acc = [...acc, curr.node.id];
			}
			return acc;
		}, [] as string[]);

		return alertIds;
	}

	get selectedDismissableAlertIds(): string[] {
		const alertIds = this.selectedElements.reduce((acc, curr) => {
			if (isAlertDismissable(getDetailsViewItemData(curr.node))) {
				acc = [...acc, curr.node.id];
			}
			return acc;
		}, [] as string[]);

		return alertIds;
	}

	get selectedUndismissableAlertIds(): string[] {
		const alertIds = this.selectedElements.reduce((acc, curr) => {
			if (isAlertUndismissable(getDetailsViewItemData(curr.node))) {
				acc = [...acc, curr.node.id];
			}
			return acc;
		}, [] as string[]);

		return alertIds;
	}

	get selectedAssignedAlertsCount(): number {
		return this.selectedUnassignableAlertIds.length;
	}

	get selectedUnassignedAlertsCount(): number {
		return this.selectedAssignableAlertIds.length;
	}

	get selectedDismissedAlertsCount(): number {
		return this.selectedDismissableAlertIds.length;
	}

	get selectedUndismissedAlertsCount(): number {
		return this.selectedUndismissableAlertIds.length;
	}

	get shouldAutoFetchMore() {
		const hasNotEnoughDataForPagination = this.data.length < MIN_ALERTS_NUMBER;
		const hasLessDataThanMaximum = this.rawData.length < MAX_ALERTS_NUMBER;

		return !this.areFiltersActive && hasNotEnoughDataForPagination && hasLessDataThanMaximum;
	}

	get isReadMoreDisabledByFilters() {
		return this.gridHeaderFilter.length > 0 || this.isSelectedRowsFilterActive;
	}

	selectedDismissedAlertsAssigned(alert?: AlertEdge): boolean {
		if (alert) {
			return alert.node.state?.alertState === AlertState.Assigned;
		} else {
			return this.selectedElements.some(({ node }) => node.state?.alertState === AlertState.Assigned);
		}
	}

	setActiveFilter = (field: string, data: FilterDescriptorWithId[]) => {
		const filters = data.map((item) => item?.value);

		this.state.activeEqualFilters = {
			...this.state.activeEqualFilters,
			[field]: filters,
		};
	};

	getFilterOptions = (field: string, isNull?: boolean, emptyFieldName?: string) => {
		const counters = this.getCountedValues(field);
		let options: FilterOption[] = [];

		options = this.getUniqValues(field).map((value) => {
			return mapFilterOption(
				field,
				{ counter: counters[value as string], value: value as FilterValue },
				{
					[AlertPropertiesPaths.State]: getStateOption,
					[AlertPropertiesPaths.Severity]: getSeverityOption,
					[AlertPropertiesPaths.Confidence]: getConfidenceOption,
				},
			);
		});

		if (isNull && emptyFieldName) {
			options.unshift(getNullishFilterOption(emptyFieldName, EMPTY_FIELD_VALUE, counters[EMPTY_FIELD_VALUE]));
		}

		return options;
	};

	refresh = () => {
		this.read();
		this.resetAllFilters();
		this.initializeSessionStorage();
	};

	read = () => {
		this.readMoreRetries = 0;
		this.dataStore.read();
	};

	readMore = async () => {
		const results = await this.dataStore.readMore();
		const headerFilters = this.filter.filters.filter((filterDescriptor) => filterDescriptor.id !== FilterGroupId.gridHeader);

		// Those additional fetching is an workaround for the issue where we receive next page that after filtering by header filters is empty
		if (headerFilters.length > 0) {
			const data = results?.data?.listAlerts?.edges || [];
			const filter = {
				...this.filter,
				filters: headerFilters,
			};
			const filteredData = filterBy(data, filter);

			if (filteredData.length === 0 && this.readMoreRetries < 3) {
				this.readMore();
				this.readMoreRetries++;
			} else {
				this.readMoreRetries = 0;
			}
		}
	};

	setHeaderFilter = (value: AlertsHeaderFilter) => {
		const filters = [...this.headerFilter];
		const filterDescriptors: {
			[key: string]: FilterDescriptorWithId;
		} = {
			[AlertsHeaderFilter.Assigned]: {
				id: AlertsHeaderFilter.Assigned,
				field: AlertPropertiesPaths.State,
				operator: FilterOperators.Eq,
				value: AlertState.Assigned,
			},
			[AlertsHeaderFilter.Unassigned]: {
				id: AlertsHeaderFilter.Unassigned,
				field: AlertPropertiesPaths.State,
				operator: FilterOperators.Eq,
				value: 'Unassigned',
			},
			[AlertsHeaderFilter.Dismissed]: {
				id: AlertsHeaderFilter.Dismissed,
				field: AlertPropertiesPaths.State,
				operator: FilterOperators.Eq,
				value: AlertState.Dismissed,
			},
		};

		const index = filters.findIndex((filter) => {
			return 'id' in filter && filter.id === value;
		});

		if (index > -1) {
			filters.splice(index, 1);
		} else {
			filters.push(filterDescriptors[value]);
		}

		this.setFilter({ id: FilterGroupId.header, filters: filters as FilterDescriptorWithId[], logic: FilterLogic.Or, nested: false });
	};

	setGridHeaderFilter = (field: string, values: Filters) => {
		const filters = values.map((value) => {
			// eslint-disable-next-line @typescript-eslint/ban-types
			let operator: string | Function = FilterOperators.Eq;

			if (field === AlertPropertiesPaths.Source || field === AlertPropertiesPaths.Destination) {
				operator = FilterOperators.IsSomeIn;
			}

			return { value, field, operator, ignoreCase: false };
		});

		this.setActiveFilter(field, filters);

		this.setFilter({
			id: FilterGroupId.gridHeader,
			filters,
			logic: FilterLogic.And,
			nestedId: field,
			nestedLogic: FilterLogic.Or,
		});
	};

	resetGridHeaderFilter = (field: string) => {
		this.state.activeEqualFilters = { ...this.state.activeEqualFilters, [field]: [] };
		this.resetFilter(FilterGroupId.gridHeader, field);
	};

	getAlertById = computedFn((id?: string) => {
		if (!id) {
			return undefined;
		}

		return this.alerts.find((alert) => alert.node.id === id);
	});

	private initializeSessionStorage = () => {
		return this.switchTenantStore.init(this.gridState, {
			name: ALERTS_LIST_SESSION_STORAGE_PERSISTABLE_KEY,
			properties: ['sort', 'filter', 'selected'],
			storage: window.sessionStorage,
		});
	};

	private setDefaultFilter = () => {
		const currentFilter = this.getFilter(FilterGroupId.header);

		if (!currentFilter) {
			this.setFilter({
				id: FilterGroupId.header,
				logic: FilterLogic.Or,
				filters: [
					{
						id: AlertsHeaderFilter.Assigned,
						field: AlertPropertiesPaths.State,
						operator: FilterOperators.Eq,
						value: AlertState.Assigned,
					},
					{
						id: AlertsHeaderFilter.Unassigned,
						field: AlertPropertiesPaths.State,
						operator: FilterOperators.Eq,
						value: AlertStateOptions.Unassigned,
					},
				],
			});
		}
	};

	alertsDisposer = reaction(
		() => this.dataStore.alerts,
		(alerts) => {
			this.sourceData = getQualifiedAlertNodes(alerts);
		},
	);

	tenantChangeDisposer = reaction(() => this.authStore.currentTenantId, this.refresh);

	// Those two auto-fetch reactions are added as an workaround for the issue where we have empty view when header filters are enabled
	alertsAutoFetchDisposer = reaction(
		() => this.rawData,
		() => {
			if (this.shouldAutoFetchMore) {
				this.readMore();
			}
		},
	);

	headerFiltersDisposer = reaction(
		() => this.headerFilterValues,
		() => {
			if (this.shouldAutoFetchMore) {
				this.readMore();
			}
		},
	);

	dispose = () => {
		this.alertsDisposer();
		this.alertsAutoFetchDisposer();
		this.headerFiltersDisposer();
		this.tenantChangeDisposer();
		stopPersisting(this.gridState);
	};
}

export type AlertsRow = AlertsListViewStore['data'][0];
