import { Themes } from '@/app/_common/types';
import { MonacoInlineCodeTheme } from '@/app/_common/_components/monaco-inline-code/_common';

export const determineMonacoThemeUponAppTheme = (theme: Themes): MonacoInlineCodeTheme => {
	switch (theme) {
		case Themes.Light:
			return MonacoInlineCodeTheme.Light;
		case Themes.Dark:
			return MonacoInlineCodeTheme.Dark;
		default:
			return MonacoInlineCodeTheme.Light;
	}
};
