/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useState, useRef, MouseEvent, useCallback, KeyboardEvent } from 'react';
import classNames from 'classnames';

import { TimelineItemData } from '@/app/_common/_components/details-view/alerts-details-view-navigation';
import { buttonize } from '@/app/_common/utils';

import styles from './timeline-item.module.scss';

export interface TimelineItemProps extends TimelineItemData {
	active?: boolean;
	triggered?: boolean;
	handleSetActive: (id: string, left: number, width: number) => void;
	zindexForOverlay: number;
	isClickEnabled: boolean;
}

const MAX_LENGTH = 55;
const TRIGGERED_MAX_LENGTH = 65;
const DOTS_AFTER_CONTENT = '...';
const REPLACE_STRING = '(UTC)';

export const TimelineItem: FC<TimelineItemProps> = ({
	time,
	icon,
	name,
	id,
	active,
	left = 0,
	showDetails = false,
	handleSetActive,
	className = '',
	triggered,
	zindex,
	zindexForOverlay,
	isClickEnabled,
}) => {
	const [hover, setHover] = useState(false);
	const [zindexForButton, setZindexForButton] = useState(zindex);

	const itemRef = useRef<HTMLDivElement | null>(null);

	const setActiveItem = useCallback(
		(e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
			e.stopPropagation();
			if (isClickEnabled && itemRef.current && id) {
				const width = itemRef.current.offsetWidth / 2;
				handleSetActive(id, left, width);
			}
		},
		[handleSetActive, id, left, isClickEnabled],
	);

	const handleSetHover = (show: boolean) => {
		const valueToSet = active ? false : show;
		setHover(valueToSet);
		setZindexForButton(valueToSet ? zindexForOverlay : zindex);
	};

	const zindexForActive = zindexForOverlay;
	const zindexForHover = zindexForOverlay + 1;
	let zindexForBox: number | undefined;
	if (hover) {
		zindexForBox = zindexForHover;
	} else {
		zindexForBox = active ? zindexForActive : undefined;
	}

	const truncatedNameLength = triggered ? TRIGGERED_MAX_LENGTH : MAX_LENGTH;

	const displayName = name.length > truncatedNameLength && !triggered ? name.substring(0, truncatedNameLength) + DOTS_AFTER_CONTENT : name;

	const displayTime = time.replace(REPLACE_STRING, '');

	return (
		<div
			className={classNames(styles.timelineItem, {
				[styles.active]: active,
				[styles.hover]: hover,
				[styles.show]: showDetails,
				[className]: className,
			})}
			style={{ left: `${left}px`, zIndex: zindexForBox }}
			ref={itemRef}
		>
			<span className={styles.title}>{displayTime}</span>
			<div
				className={styles.imageBox}
				onMouseEnter={() => handleSetHover(true)}
				onMouseLeave={() => handleSetHover(false)}
				{...buttonize<HTMLDivElement>(setActiveItem)}
			>
				<img className={styles.image} src={icon} alt="Alert Icon" style={{ zIndex: zindexForButton }} />
			</div>
			<div className={styles.description}>{displayName}</div>
		</div>
	);
};
