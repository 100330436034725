export const AUTH_BROADCAST_CHANNEL_NAME = 'broadcast_auth';

export enum AUTH_BROADCAST_CHANNEL_MESSAGE {
	LogoutMessage = 'broadcast_logout',
	ReplaceTokens = 'broadcast_replace_token',
	ExpiredToken = 'broadcast_expired_token ',
}

export enum AuthActions {
	Login = 'LOGIN',
}

export const AUTH_DETAILS_VERSION = 'v2';
