import { AlertVendorKey } from '@/app/_common/_components/details-view/types/alert-vendor-key';

import {
	VendorLinkCrowdStrikeLightIcon,
	VendorLinkCrowdStrikeDarkIcon,
	VendorLinkGoogleWorkspaceLightIcon,
	VendorLinkGoogleWorkspaceDarkIcon,
	WindowsDefenderDarkIcon,
	WindowsDefenderLightIcon,
	VendorLinkCiscoMerakiLightIcon,
	VendorLinkCiscoMerakiDarkIcon,
	VendorLinkFortinetFortigateLightIcon,
	VendorLinkFortinetFortigateDarkIcon,
	VendorLinkPaloAltoNgfwLightIcon,
	VendorLinkPaloAltoNgfwDarkIcon,
} from '@/assets/icons';

export const DARK_THEME_POSTFIX = '_dark';

export const VENDOR_LINK_KEYS: string[] = [
	AlertVendorKey.CROWDSTRIKE_FALCON,
	AlertVendorKey.GOOGLE_WORKSPACE,
	AlertVendorKey.MICROSOFT_DEFENDER,
	AlertVendorKey.CISCO_MERAKI,
	AlertVendorKey.FORTINET_FORTIGATE,
	AlertVendorKey.PALO_ALTO_NGFW,
	AlertVendorKey.OKTA_WORKFORCE,
	AlertVendorKey.SECIOSS_SECIOSSLINK,
];

export const VENDOR_LINK_ICONS: Record<string, JSX.Element> = {
	[`${AlertVendorKey.CROWDSTRIKE_FALCON}`]: <VendorLinkCrowdStrikeLightIcon />,
	[`${AlertVendorKey.CROWDSTRIKE_FALCON}${DARK_THEME_POSTFIX}`]: <VendorLinkCrowdStrikeDarkIcon />,
	[`${AlertVendorKey.GOOGLE_WORKSPACE}`]: <VendorLinkGoogleWorkspaceLightIcon />,
	[`${AlertVendorKey.GOOGLE_WORKSPACE}${DARK_THEME_POSTFIX}`]: <VendorLinkGoogleWorkspaceDarkIcon />,
	[`${AlertVendorKey.MICROSOFT_DEFENDER}`]: <WindowsDefenderLightIcon />,
	[`${AlertVendorKey.MICROSOFT_DEFENDER}${DARK_THEME_POSTFIX}`]: <WindowsDefenderDarkIcon />,
	[`${AlertVendorKey.CISCO_MERAKI}`]: <VendorLinkCiscoMerakiLightIcon />,
	[`${AlertVendorKey.CISCO_MERAKI}${DARK_THEME_POSTFIX}`]: <VendorLinkCiscoMerakiDarkIcon />,
	[`${AlertVendorKey.FORTINET_FORTIGATE}`]: <VendorLinkFortinetFortigateLightIcon />,
	[`${AlertVendorKey.FORTINET_FORTIGATE}${DARK_THEME_POSTFIX}`]: <VendorLinkFortinetFortigateDarkIcon />,
	[`${AlertVendorKey.PALO_ALTO_NGFW}`]: <VendorLinkPaloAltoNgfwLightIcon />,
	[`${AlertVendorKey.PALO_ALTO_NGFW}${DARK_THEME_POSTFIX}`]: <VendorLinkPaloAltoNgfwDarkIcon />,
};
