export enum AlertPropertiesPaths {
	Id = 'id',
	Title = 'title',
	Severity = 'severity',
	Confidence = 'event_details.confidence',
	Source = 'event_details.src',
	Destination = 'event_details.dst',
	Timestamp = 'timestamp',
	InvestigationSummary = 'investigationSummary',
	InvestigationSummaryId = 'investigationSummary.id',
	InvestigationSummaryName = 'investigationSummary.name',
	Mitre = 'event_details.mitre_category',
	Selected = 'selected',
	DetectedBy = 'event_source',
	State = 'state.alertState',
	StateData = 'state',
}
