import classNames from 'classnames';
import { Checkbox, CheckboxProps as KendoCheckboxProps } from '@progress/kendo-react-inputs';

import styles from './toggle.module.scss';

const getToggleClass = (toggleType: ToggleThemeColor) => {
	const toggleMapper = {
		[ToggleThemeColor.Blue]: styles.toggleBlue,
		[ToggleThemeColor.Red]: styles.toggleRed,
		[ToggleThemeColor.Purple]: styles.togglePurple,
		[ToggleThemeColor.AltBlue]: styles.toggleAltBlue,
		[ToggleThemeColor.Green]: styles.toggleGreen,
	};

	return toggleMapper[toggleType] || toggleMapper[ToggleThemeColor.Blue];
};

const getCheckedClass = (checked: boolean) => {
	if (!checked) {
		return;
	}

	return styles.filterChecked;
};

export enum ToggleThemeColor {
	Blue = 'Blue',
	Red = 'Red',
	Purple = 'Purple',
	AltBlue = 'AltBlue',
	Green = 'Green',
}

export interface ToggleProps extends Pick<KendoCheckboxProps, 'value' | 'onChange' | 'label'> {
	type?: ToggleThemeColor;
}

export const Toggle = ({ type = ToggleThemeColor.Blue, value = false, onChange = () => undefined, label }: ToggleProps) => {
	return (
		<div className={classNames(styles.toggle, getToggleClass(type), getCheckedClass(Boolean(value)))}>
			<Checkbox value={value} onChange={onChange} label={label} />
		</div>
	);
};
