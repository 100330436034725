import { FC } from 'react';

import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { ThemeStore } from '@/app/_common/stores';
import { Switch, SWITCH_ON_COLOR_DARK_MODE } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';
import '@/assets/styles/index.scss';

import styles from './user-tile.module.scss';

export const SwitchMode: FC = observer(() => {
	const themeStore = useInstance(ThemeStore);
	const { t } = useTranslation([Namespaces.Common]);
	const switchText = t(`theme.${themeStore.theme}`);

	const switchOnColorProps = themeStore.isDarkTheme ? SWITCH_ON_COLOR_DARK_MODE : {};

	return (
		<Switch
			className={styles.paddedItem}
			checked={themeStore.isDarkTheme}
			onChange={() => themeStore.toggle()}
			text={switchText}
			{...switchOnColorProps}
		/>
	);
});
