import { LabelValueRowValue } from '@/app/_common/_components/label-value-row/_common/types';

import { ValueNormal } from './value-normal';

import styles from './value-unordered-list.module.scss';

interface ValueUnorderedListProps {
	value?: LabelValueRowValue;
}

export const ValueUnorderedList = ({ value }: ValueUnorderedListProps) => {
	if (!Array.isArray(value)) {
		return <ValueNormal value={value} />;
	}

	return (
		<ul className={styles.valueUnorderedList} data-testid="value">
			{value.map((item: string) => (
				<li key={item}>{item}</li>
			))}
		</ul>
	);
};
