import _isObject from 'lodash/isObject';
import { gql } from '@apollo/client';

import { Investigation } from '@/generated/graphql';
import { InvestigationTypename } from '@/app/_common/constants';
import { readInvestigationFragmentById } from '@/app/_common/utils';
import { UnassignAlertsDashboardUpdateCacheFn } from '@/app/_features/alerts-actions/_common/stores/_common/types';
import { cacheUpdateHelperUnassignDashboard } from '@/app/dashboards/alerts-dashboard/_common/utils/cache-updates';

export const updateCacheUnassignAlertsDashboard: UnassignAlertsDashboardUpdateCacheFn = (cache, { data }) => {
	const unassignedAlertIds = [];

	const investigations = Object.values(data || {}).filter(
		(item): item is Investigation => _isObject(item) && item.__typename === InvestigationTypename.Investigation,
	);

	if (investigations.length === 0) {
		return;
	}

	for (const investigation of investigations) {
		const oldInvestigation = readInvestigationFragmentById(
			cache,
			investigation.id,
			gql`
				fragment Investigation on Investigation {
					id
					alerts {
						id
					}
				}
			`,
		);

		const investigationAssignedAlertIds = new Set(investigation.alerts?.map((item) => item.id) ?? []);
		const oldInvestigationAlertIds = oldInvestigation?.alerts?.map(({ id }) => id);
		const diffAlertIds = oldInvestigationAlertIds?.filter((id) => !investigationAssignedAlertIds.has(id)) ?? [];

		unassignedAlertIds.push(...diffAlertIds);
	}

	cacheUpdateHelperUnassignDashboard(cache, unassignedAlertIds, investigations);
};
