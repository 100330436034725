import { ADX_FILTER_OPERATORS } from '@/app/_common/constants';

export interface SchemaColumn {
	Name: string;
	Type: string;
	CslType: string;
}

export interface SchemaTable {
	Name: string;
	OrderedColumns: SchemaColumn[];
}

export type SchemaDatabaseTables = Record<string, SchemaTable>;

export interface SchemaDatabase {
	Name: string;
	Tables: SchemaDatabaseTables;
	MajorVersion: number;
	MinorVersion: number;
	Functions: Record<string, unknown>;
	DatabaseAccessMode: string;
	EntityGroups: Record<string, unknown>;
	ExternalTables: Record<string, unknown>;
	MaterializedViews: Record<string, SchemaTable>;
}

export interface Schema {
	Databases: Record<string, SchemaDatabase>;
}

export interface TableColumn {
	ColumnName: string;
	ColumnType: string;
}

export type FieldFilterValue = string | number;

export interface FieldFilter {
	field: string;
	operator: ADX_FILTER_OPERATORS;
	value: FieldFilterValue;
}

export interface FieldFilterGroup {
	field: string;
	values: FieldFilterValue[];
	operator: ADX_FILTER_OPERATORS;
}

export type ResultsFieldValue = string | number | boolean | null | undefined | string[] | number[];

export interface ResultsDataItem {
	_id?: string;
	[key: string]: ResultsFieldValue;
}

export interface TotalCountResultsDataItem {
	$table?: string;
	count_: number;
}

export interface AvailableFieldsResultsDataItem {
	value: string;
	count: number;
}

export interface AvailableField {
	value: string;
	count: number;
}

interface AdxContext {
	timestamp: Date;
	serviceAlias: string;
	machineName: string;
	processName: string;
	processId: number;
	threadId: number;
	appDomainName: string;
	clientRequestId: string;
	activityId: string;
	subActivityId: string;
	activityType: string;
	parentActivityId: string;
	activityStack: string;
}

interface AdxInnererror {
	code: string;
	message: string;
	'@type': string;
	'@message': string;
	'@context': AdxContext;
	'@permanent': boolean;
	'@line': string;
	'@pos': string;
	'@errorCode': string;
	'@errorMessage': string;
	'@token': string;
}

interface AdxError {
	code: string;
	message: string;
	'@type': string;
	'@message': string;
	'@context': AdxContext;
	'@permanent': boolean;
	'@text'?: string;
	'@database'?: string;
	'@ClientRequestLogger'?: string;
	innererror?: AdxInnererror;
}

interface AdxErrorResult {
	error: AdxError;
}

export enum FrameType {
	DataSetHeader = 'DataSetHeader',
	DataTable = 'DataTable',
	DataSetCompletion = 'DataSetCompletion',
	TableHeader = 'TableHeader',
	TableFragment = 'TableFragment',
	TableProgress = 'TableProgress',
	TableCompletion = 'TableCompletion',
}

export type TableRow = ResultsFieldValue[];

export interface DataTable {
	FrameType: FrameType.DataTable;
	TableId: number;
	TableKind: string;
	TableName: string;
	Columns: TableColumn[];
	Rows: TableRow[];
}

interface DataSetCompletion {
	FrameType: FrameType.DataSetCompletion;
	HasErrors: boolean;
	Cancelled: boolean;
	OneApiErrors?: AdxErrorResult[];
}

export interface KustoResultTable {
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any[];
}

export interface KustoResponseDataSet {
	tables: KustoResultTable[];
	tableNames: string[];
	primaryResults: KustoResultTable[];
	statusTable?: KustoResultTable;
	dataSetCompletion: DataSetCompletion | null;
}

export enum AlertSpecialFieldNames {
	Confidence = 'confidence',
	Severity = 'severity',
	MitreTactics = 'mitre_tactics',
	MitreTechniques = 'mitre_techniques',
}
