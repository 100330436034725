import _uniq from 'lodash/uniq';

import { SchemaTable } from '@/app/_common/types';
import { CSL_TYPES } from '@/app/_common/constants';

export const getUniqColumnsByType = (table: SchemaTable, type: CSL_TYPES, baseColumnNames: string[] = []) => {
	const columns = table.OrderedColumns.reduce<string[]>((result, column) => {
		if (column.CslType === type) {
			result.push(column.Name);
		}

		return result;
	}, []);

	return _uniq([...baseColumnNames, ...columns]);
};
