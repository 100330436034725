import { ReactNode, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Popup } from '@progress/kendo-react-popup';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useOutsideClick } from '@/app/_common/hooks';
import { buttonize } from '@/app/_common/utils';

import styles from './popup-menu.module.scss';

export interface PopupMenuItem {
	icon: ReactNode;
	label: string;
	action: () => void;
}

export interface PopupMenuProps {
	children?: ReactNode;
	disabled?: boolean;
	items: PopupMenuItem[];
}

const POPUP_MENU_ANCHOR_ALIGN = { horizontal: 'right', vertical: 'bottom' } as const;
const POPUP_MENU_ALIGN = { horizontal: 'right', vertical: 'top' } as const;

export const PopupMenu = ({ disabled, items, children }: PopupMenuProps) => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	const { popupRef, anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		showDropdown,
		useCallback(() => setShowDropdown(false), []),
	);

	const toggleDropdown = () => {
		setShowDropdown((showDropdown) => !showDropdown);
	};

	const itemsWithClosePopupAction = useMemo(
		() =>
			items.map(({ action, ...props }) => ({
				...props,
				action: () => {
					setShowDropdown(false);
					action();
				},
			})),
		[items],
	);

	return (
		<>
			<div
				className={classNames(styles.moreButton, { [styles.moreActive]: showDropdown, [styles.disabled]: disabled })}
				ref={anchorRef}
				{...buttonize(toggleDropdown)}
			>
				{children ?? <MoreVertIcon />}
			</div>

			<Popup
				anchor={anchorRef.current}
				anchorAlign={POPUP_MENU_ANCHOR_ALIGN}
				popupAlign={POPUP_MENU_ALIGN}
				show={showDropdown}
				popupClass={styles.actionsClass}
			>
				<div className={styles.actions} ref={popupRef}>
					{itemsWithClosePopupAction.map(({ action, icon, label }) => (
						<div key={label} className={styles.actionButton} {...buttonize(action)}>
							{icon}
							<span>{label}</span>
						</div>
					))}
				</div>
			</Popup>
		</>
	);
};
