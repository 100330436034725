import { memo } from 'react';
import _get from 'lodash/get';

import { AdxIcon } from '@/app/_common/_components';
import { DataCell } from '@/app/_common/_components/data-grid/_components';
import { QueryResultsTableCellWithStoreProps } from '@/app/_common/_components/query-results/query-results-table/types';

export const QueryResultsTableIconCell = memo((props: QueryResultsTableCellWithStoreProps) => {
	const { dataItem, field } = props;
	const value = _get(dataItem, field ?? '');

	return (
		<DataCell {...props} oldContextMenu={false}>
			<AdxIcon type={value} />
		</DataCell>
	);
});

QueryResultsTableIconCell.displayName = 'QueryResultsTableIconCell';
