import _get from 'lodash/get';

import { ADX_FILTERED_COLUMNS, ADX_IGNORED_COLUMNS, CSL_TYPES } from '@/app/_common/constants';
import { Schema, SchemaColumn, TableColumn, KustoResultTable } from '@/app/_common/types';

export const getQueryColumns = (schema: Schema, database: string, tableName: string, resultsData: KustoResultTable['data']) => {
	const schemaColumns: SchemaColumn[] = _get(schema, `Databases.${database}.Tables.${tableName}.OrderedColumns`) || [];
	const queryColumns = Object.keys(resultsData[0] || []).filter((column) => !ADX_FILTERED_COLUMNS.includes(column));

	const columns =
		queryColumns.length === 0
			? schemaColumns
			: schemaColumns.filter((column) => queryColumns.find((c) => c === column.Name || c === `${column.Name}_${column.CslType}`));

	const columnData = columns.reduce((result: TableColumn[], column) => {
		const isValidColumn = !ADX_IGNORED_COLUMNS.includes(column.Name) && !column.Name.startsWith('$') && column?.CslType !== CSL_TYPES.DATETIME;

		if (column?.CslType && isValidColumn) {
			result.push({
				ColumnName: column.Name,
				ColumnType: column.CslType,
			});
		}

		return result;
	}, []);

	if (queryColumns.length === 0) {
		return columnData;
	}

	return queryColumns.map((queryColumn) => {
		const type = columnData.find((column) => column.ColumnName === queryColumn || `${column.ColumnName}_${column.ColumnType}` === queryColumn);

		return {
			ColumnName: queryColumn,
			ColumnType: type ? type.ColumnType : 'string',
		};
	});
};
