import axios from 'axios';

import { AuthDetails, ResponseStatus } from '@/app/_common/interfaces';
import { getAuthDetails, getEncodedLogoutRedirectURL } from '@/app/_common/utils/auth';
import { AuthActions } from '@/app/_common/constants';

export const initialAuthData: AuthDetails = {
	token: '',
	user: {
		id: '',
		upn: '',
		tenants: [],
		displayName: '',
		firstName: '',
		lastName: '',
		isTenantUser: true,
		franchise: { name: '', id: '', isOwner: false },
	},
};

export const verifyToken = async (token: string | null): Promise<AuthDetails> => {
	const requestUrl = process.env.REACT_APP_VERIFY_TOKEN_API as string;
	const requestConfig = {
		headers: {
			Authorization: `${token}`,
			'Ocp-Apim-Subscription-Key': process.env.REACT_APP_SUBSCRIPTION_TOKEN as string,
		},
	};

	const requestPayload = {
		action: AuthActions.Login,
	};

	try {
		const response = await axios.post(requestUrl, requestPayload, requestConfig);

		if (response.status !== ResponseStatus.OK) {
			window.location.href = getEncodedLogoutRedirectURL(true);
			return initialAuthData;
		}

		return getAuthDetails(token, response);
	} catch (error) {
		window.location.href = getEncodedLogoutRedirectURL(true);

		return initialAuthData;
	}
};
