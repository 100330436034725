import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorOutline } from '@mui/icons-material';

import { Button } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';
import { AlertDetailsKeyPrefix } from '@/app/_common/constants';

import styles from './alert-details-error.module.scss';

interface AlertDetailsErrorProps {
	handleOnClick: () => void;
}

export const AlertDetailsError: FC<AlertDetailsErrorProps> = ({ handleOnClick }) => {
	const { t } = useTranslation(Namespaces.DetailsView, { keyPrefix: AlertDetailsKeyPrefix.Error });

	return (
		<div className={styles.container}>
			<ErrorOutline htmlColor="var(--brandDanger)" />
			<p className={styles.message}>{t('errorMessage')}</p>
			<Button onClick={handleOnClick} fillMode="outline">
				{t('buttonText')}
			</Button>
		</div>
	);
};
