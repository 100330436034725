import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { AlertSelectCell, AlertSelectedCellProps } from '@/app/_common/_components/data-grid/_components/alert-select-cell';
import { AlertsListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-list';
import { AlertPropertiesPaths } from '@/app/_common/constants';

export const AlertSelectedCell = observer((props: AlertSelectedCellProps) => {
	const store = useInstance(AlertsListViewStore);

	return (
		<AlertSelectCell
			{...props}
			oldContextMenu={false}
			isRowSelected={store.isRowSelected}
			toggleSelectedRow={store.toggleSelectedRow}
			idPropertyPath={AlertPropertiesPaths.Id}
		/>
	);
});

AlertSelectedCell.displayName = 'AlertsListAlertSelectedCell';
