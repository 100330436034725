import { memo } from 'react';

import type { GridCellProps } from '@progress/kendo-react-grid';

import { MitreAttackCell } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/_components';

export const AlertMitreCell = memo((props: GridCellProps) => {
	return <MitreAttackCell {...props} oldContextMenu={false} />;
});

AlertMitreCell.displayName = 'AlertMitreCell';
