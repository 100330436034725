export interface CountBucket {
	[key: string]: number;
}

type FieldGetterRetVal = string | number | undefined;

const INITIAL_COUNT_VALUE = 1;

export const getCountBucket = <T>(
	array: T[],
	fieldGetter: (item: T) => FieldGetterRetVal = (item) => item as unknown as FieldGetterRetVal,
): CountBucket => {
	return array.reduce((bucket: CountBucket, item: T) => {
		const itemKeyValue = fieldGetter(item);

		if (itemKeyValue === undefined) {
			return bucket;
		}

		bucket[itemKeyValue] = bucket[itemKeyValue] === undefined ? INITIAL_COUNT_VALUE : bucket[itemKeyValue] + 1;

		return bucket;
	}, {});
};
