import { FC, useCallback } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import _get from 'lodash/get';

import { Tooltip } from '@progress/kendo-react-tooltip';
import { GridCellProps } from '@progress/kendo-react-grid';

import { DATE_TIME_FORMAT, AlertStateOptions } from '@/app/_common/constants';
import { DataCell } from '@/app/_common/_components/data-grid/_components';
import { AlertStateTooltipContent } from '@/app/_common/_components/tooltips';
import { getAlertStateColor } from '@/app/_common/utils/colors';
import { capitalizeFirstLetter } from '@/app/_common/utils';
import { Namespaces } from '@/translations/namespaces';
import { useTranslation } from 'react-i18next';
import { ContextMenuComponentProps } from '@/app/_common/types';

import styles from './alert-state-cell.module.scss';

const ASSIGNED_ALERT_TRANSLATION_PATH = 'assignedAlert';
const DISMISSED_ALERT_TRANSLATION_PATH = 'dismissedAlert';

interface AlertStateCellProps extends GridCellProps {
	alertState: string;
	className?: string;
	statePropertyPath: string;
	onClick?: () => void;
	ContextMenuComponent?: FC<ContextMenuComponentProps>;
	oldContextMenu?: boolean;
}

export const AlertStateCell: FC<AlertStateCellProps> = ({
	alertState = '',
	className,
	onClick,
	dataItem,
	statePropertyPath,
	ContextMenuComponent,
	...rest
}) => {
	const { t } = useTranslation(Namespaces.AlertsDashboard, { keyPrefix: 'alertsGrid.tooltips' });

	const showInfoTooltip = alertState !== AlertStateOptions.Unassigned;

	const capitalizedAlertState = capitalizeFirstLetter(alertState);

	const cellColor = getAlertStateColor(alertState as AlertStateOptions).toLowerCase();

	const tooltipContent = useCallback(() => {
		const { requesterUser, timestamp } = _get(dataItem, statePropertyPath, {});
		const { upn: userEmail } = requesterUser;

		const translationPath = alertState === AlertStateOptions.Assigned ? ASSIGNED_ALERT_TRANSLATION_PATH : DISMISSED_ALERT_TRANSLATION_PATH;

		const date = moment(timestamp).parseZone().format(DATE_TIME_FORMAT);
		const tooltipMessage = t(translationPath, { userEmail, date });

		return <AlertStateTooltipContent tooltipMessage={tooltipMessage} />;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [alertState, dataItem]);

	return (
		<DataCell
			{...rest}
			className={classNames(className, { [styles[alertState.toLowerCase()]]: cellColor })}
			onClick={onClick}
			dataItem={dataItem}
			title={capitalizedAlertState}
			ContextMenuComponent={ContextMenuComponent}
		>
			<Tooltip anchorElement="target" position="top" parentTitle={showInfoTooltip} content={tooltipContent}>
				{capitalizedAlertState}
			</Tooltip>
		</DataCell>
	);
};
