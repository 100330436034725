import { KeyValue } from '@progress/kendo-react-form';

export enum NameField {
	Name = 'name',
	Type = 'text',
	Label = 'createIntegrationFields.name',
	Placeholder = 'createIntegrationFields.name',
}

export enum DescriptionField {
	Name = 'description',
	Type = 'text',
	Label = 'createIntegrationFields.description',
	Placeholder = 'createIntegrationFields.description',
}

export interface IntegrationDetailsFormData {
	[NameField.Name]: string | undefined;
	[DescriptionField.Name]: string | undefined;
}

export type IntegrationDetailsFormValidator = (data: IntegrationDetailsFormData) => KeyValue<string> | undefined;
