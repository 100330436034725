import { action, computed, makeObservable, observable, reaction } from 'mobx';

import _isEqual from 'lodash/isEqual';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { AlertState, DataObject, Investigation } from '@/generated/graphql';
import {
	AlertDetailsInvestigationSummary,
	AlertStateInfoDetails,
	ThreatIntelligenceDescription,
	ThreatIntelligenceMatches,
} from '@/app/_common/types';
import {
	extractResultsTableItemId,
	getAssignedAlertStateInfo,
	getDismissedAlertStateInfo,
	getUnassignedAlertStateInfoDetails,
	getUnknownAlertStateInfoDetails,
} from '@/app/_common/utils';
import { AlertStateOptions } from '@/app/_common/constants';

import { AlertsDetailsNavigationViewStore } from '@/app/_common/_components/details-view/stores/alerts-details-navigation.view-store';
import { MitreAttackBadgeText } from '@/app/_common/_components/mitre-attack-badge/_constants/mitre-attack-badge-text';
import { AlertDetailsIocEventsDataStore } from '@/app/_common/_components/details-view/stores';
import { EVIDENCE_CONTAINER } from '@/app/_common/_components/details-view/constants';
import { DetailsViewItem, DetailsViewItemData, DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { getDetailsViewItemData } from '@/app/_common/_components/details-view/utils';
import { getThreatIntelligenceDescriptions, getThreatIntelligenceMatches } from '@/app/investigation-details/_common/utils';
import { AlertDetailAssignedAlertInvestigationDataStore } from '@/app/_common/_components/details-view/stores/alert-detail-assigned-alert-investigation.data-store';

interface State {
	storedDataObject?: DataObject;
	storedIocDataObjects?: DataObject[];
	selectedAlertId?: string;
	selectedItemTypename: DetailsViewTypename;
}

/**
 * This store is extended by 3 other stores: DashboardAlertDetailsViewStore, AdvancedQueryAlertDetailsViewStore, InvestigationAlertDetailsViewStore;
 * They all have some common logic, as well as some custom logic based on their own dependencies (stores).
 * Unification of that logic is considered to be further improvements, but it requires global rework.
 */
export class BaseAlertsDetailsViewStore {
	private authStore = injectInterface(this, AuthStore);
	private alertsDetailsNavigationViewStore = injectInterface(this, AlertsDetailsNavigationViewStore);
	private investigationDetailsDataStore = injectInterface(this, AlertDetailAssignedAlertInvestigationDataStore);
	private alertDetailsIocEventsDataStore = injectInterface(this, AlertDetailsIocEventsDataStore);

	public state: State = {
		storedIocDataObjects: [],
		storedDataObject: undefined,
		selectedAlertId: undefined,
		selectedItemTypename: DetailsViewTypename.UNKNOWN,
	};

	constructor() {
		makeObservable(this, {
			//@ts-ignore
			state: observable,
			isLoading: computed,
			loadingAssignedInvestigation: computed,
			selectedAlert: computed,
			selectedAlertData: computed,
			selectedItems: computed,
			selectedItemTypename: computed,
			selectedAlertId: computed,
			selectedMitreCategory: computed,
			selectedAlertConfidence: computed,
			selectedAlertDescription: computed,
			selectedAlertInvestigation: computed,
			selectedAlertInvestigationSummary: computed,
			selectedAlertState: computed,
			selectedAlertRecommendation: computed,
			threatIntelligenceDescriptions: computed,
			threatIntelligenceMatches: computed,
			setSelectedAlertId: action,
			setSelectedItemTypename: action,
			clearSelectedAlertId: action,
			clearIocEventsDataObjects: action,
			setIocEventDataObjects: action,
			setStoredDataObject: action,
			clearDataObject: action,
			dispose: action,
		});
	}

	get isLoading(): boolean {
		return this.alertsDetailsNavigationViewStore.loading;
	}

	get loadingAssignedInvestigation(): boolean {
		return this.investigationDetailsDataStore.loading;
	}

	// placeholder to be overwritten. Each extending store has custom logic. Subject for further unification!
	get selectedAlert(): DetailsViewItem | undefined {
		return undefined;
	}

	// placeholder to be overwritten. Each extending store has custom logic. Subject for further unification!
	get selectedAlerts(): DetailsViewItem[] {
		return [];
	}

	get selectedAlertData(): DetailsViewItemData | undefined {
		return getDetailsViewItemData(this.selectedAlert);
	}

	get selectedItems(): Array<DetailsViewItemData> {
		const processedItems = this.selectedAlerts.map((item: DetailsViewItem) => getDetailsViewItemData(item));
		return processedItems.filter((item): item is DetailsViewItemData => item !== null);
	}

	get selectedItemTypename(): DetailsViewTypename {
		return this.state.selectedItemTypename;
	}

	get investigationSummary() {
		return this.selectedAlertData?.investigationSummary;
	}

	get selectedAlertId(): string | undefined {
		return this.state.selectedAlertId;
	}

	get selectedMitreCategory(): MitreAttackBadgeText | undefined {
		return (this.selectedAlertData?.event_details?.mitre_category ?? this.selectedAlertData?.mitre_category) as MitreAttackBadgeText | undefined;
	}

	get selectedAlertConfidence(): string {
		return this.selectedAlertData?.event_details?.confidence ?? this.selectedAlertData?.confidence ?? '';
	}

	get selectedAlertDescription(): string {
		return this.selectedAlertData?.summary ?? '';
	}

	get selectedAlertInvestigation(): Investigation | undefined {
		if (this.investigationDetailsDataStore.data?.getInvestigation.__typename === 'Investigation') {
			return this.investigationDetailsDataStore.data?.getInvestigation;
		}

		return undefined;
	}

	get selectedAlertInvestigationSummary(): AlertDetailsInvestigationSummary | undefined {
		if (this.selectedAlertInvestigation) {
			return { ...this.selectedAlertInvestigation, clickable: true };
		}

		return undefined;
	}

	get selectedAlertState(): AlertStateInfoDetails {
		const selectedAlertData = this.selectedAlertData;

		if (!selectedAlertData) {
			return getUnknownAlertStateInfoDetails();
		}

		const { state, investigationSummary } = selectedAlertData;

		if (investigationSummary === null && (state === null || (state?.alertState as unknown as AlertStateOptions) === AlertStateOptions.Unassigned)) {
			return getUnassignedAlertStateInfoDetails();
		}

		if (investigationSummary === null && state?.alertState === AlertState.Dismissed) {
			return getDismissedAlertStateInfo(state);
		}

		if (investigationSummary !== null && state?.alertState === AlertState.Assigned) {
			return getAssignedAlertStateInfo(state, this.selectedAlertInvestigationSummary?.name, investigationSummary?.id);
		}

		return getUnknownAlertStateInfoDetails();
	}

	get selectedAlertRecommendation(): string | undefined | null {
		return this.selectedAlertData?.recommendation;
	}

	setSelectedAlertId(id?: string): void {
		this.state.selectedAlertId = id;
	}

	setSelectedItemTypename(typename: DetailsViewTypename): void {
		this.state.selectedItemTypename = typename;
	}

	clearSelectedAlertId = (): void => {
		this.state.selectedAlertId = undefined;
	};

	setIocEventDataObjects = (dataObjects: DataObject[] | undefined): void => {
		this.state.storedIocDataObjects = dataObjects;
	};

	clearIocEventsDataObjects = (): void => {
		this.state.storedIocDataObjects = [];
	};

	setStoredDataObject = (dataObject: DataObject | undefined): void => {
		this.state.storedDataObject = dataObject;
	};

	clearDataObject = (): void => {
		this.state.storedDataObject = undefined;
	};

	// placeholder to be overwritten
	// 'retryRead' is currently used only on Investigations page.
	retryRead = (): void => {
		return;
	};

	private get threatIntelligenceData() {
		return this.alertDetailsIocEventsDataStore.iocData;
	}

	get threatIntelligenceDescriptions(): ThreatIntelligenceDescription[] {
		if (!this.threatIntelligenceData) {
			return [];
		}
		return getThreatIntelligenceDescriptions(this.threatIntelligenceData);
	}

	get threatIntelligenceMatches(): ThreatIntelligenceMatches {
		if (!this.threatIntelligenceData) {
			return {};
		}

		return getThreatIntelligenceMatches(this.threatIntelligenceData);
	}

	public isAlert = (typename: DetailsViewTypename): boolean => {
		return [DetailsViewTypename.ALERT, DetailsViewTypename.INVESTIGATION_ALERT_SUMMARY].includes(typename);
	};

	alertDetailsEvidenceDisposer = reaction(
		() => this.selectedAlertId,
		(selectedAlertId) => {
			if (this.isAlert(this.selectedItemTypename) && selectedAlertId && !this.selectedAlertData?.data_objects) {
				const alertId = extractResultsTableItemId(selectedAlertId);
				this.alertsDetailsNavigationViewStore.readEvidenceApi(EVIDENCE_CONTAINER, this.getEvidenceDataKey(alertId));
			}
		},
	);

	alertDetailsNavigationDisposer = reaction(
		() => this.selectedAlertData?.data_objects?.find((dataObject) => dataObject.object_type === 'bounceralert'),
		(dataObject) => {
			if (!this.isAlert(this.selectedItemTypename) || _isEqual(dataObject, this.state.storedDataObject)) {
				return;
			}

			if (dataObject?.data_path) {
				const { container, key } = dataObject?.data_path || {};
				if (container && key) {
					this.alertsDetailsNavigationViewStore.readFileApi(container, key);
					this.setStoredDataObject(dataObject);
				}
			}
		},
	);

	queriedAlertIocEventDisposer = reaction(
		() => this.selectedAlertData?.data_objects?.filter((dataObject) => dataObject.object_type === 'ioc'),
		(dataObjects) => {
			if (!this.isAlert(this.selectedItemTypename) || _isEqual(dataObjects, this.state.storedIocDataObjects) || !dataObjects) {
				return;
			}

			this.alertDetailsIocEventsDataStore.read(dataObjects);
			this.setIocEventDataObjects(dataObjects);
		},
	);

	tenantChangeDisposer = reaction(
		() => this.authStore.currentTenantId,
		() => {
			this.clearSelectedAlertId();
		},
	);

	alertInvestigationDisposer = reaction(
		() => this.investigationSummary?.id,
		(id) => {
			this.investigationDetailsDataStore.read(id);
		},
	);

	private getEvidenceDataKey(id?: string): string {
		if (!id) {
			return '';
		}

		return `${this.authStore.currentTenantId}/${this.authStore.currentTenantId}/${id}/evidence.json`;
	}

	dispose() {
		this.alertDetailsNavigationDisposer();
		this.alertDetailsEvidenceDisposer();
		this.tenantChangeDisposer();
		this.alertInvestigationDisposer();
		this.queriedAlertIocEventDisposer();
	}
}
