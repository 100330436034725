import { useEffect } from 'react';
import moment from 'moment';

import { useIdle } from '@/app/_common/hooks';

export const EXPIRY_BAR_LIFETIME_IN_MILLISECONDS = moment.duration(5, 'minutes').asMilliseconds();
export const REFRESH_TOKEN_PERIOD_IN_MILLISECONDS = moment.duration(10, 'minutes').asMilliseconds();

export const useTokenExpiry = (remainingTimeInMilliseconds: number, onRefreshToken: () => void, onTokenExpiry: () => void) => {
	const { active } = useIdle();

	const refreshTokenActive = active && remainingTimeInMilliseconds > 0 && remainingTimeInMilliseconds <= REFRESH_TOKEN_PERIOD_IN_MILLISECONDS;

	useEffect(() => {
		if (refreshTokenActive) {
			onRefreshToken();
		}
	}, [refreshTokenActive, onRefreshToken]);

	const tokenExpired = remainingTimeInMilliseconds <= 0;

	useEffect(() => {
		if (tokenExpired) {
			onTokenExpiry();
		}
	}, [tokenExpired, onTokenExpiry]);
};
