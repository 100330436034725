import { FC } from 'react';

import styles from './rich-text-header.module.scss';

interface RichTextHeaderProps {
	active: boolean;
	title: string;
}

export const RichTextHeader: FC<RichTextHeaderProps> = ({ active, title }) => {
	if (!active) {
		return null;
	}

	return (
		<header className={styles.richTextHeader}>
			<h3 className={styles.title}>{title}</h3>
		</header>
	);
};
