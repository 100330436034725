export const getAvatarInitials = (name: string): string => {
	const nameArray = name.split(' ');
	let initials = '';

	if (nameArray.length > 0) {
		initials = nameArray[0][0]?.toUpperCase() || '';
		if (nameArray.length > 1) {
			initials += nameArray[nameArray.length - 1][0]?.toUpperCase() || '';
		}
	}

	return initials;
};
