import { makeAutoObservable, reaction } from 'mobx';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import { AuthStore, RouterStore } from '@/app/_common/stores';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { removeEmptyVariables, mapDecodedUrl, mapEncodedUrl } from '@/app/_common/utils';

interface State {
	[key: string]: unknown;
}

const INITIAL_STATE: State = {
	tenantId: undefined,
};

export class UrlStore {
	private authStore = injectInterface(this, AuthStore);
	private routerStore = injectInterface(this, RouterStore);
	public state: State = INITIAL_STATE;

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
		this.initState();
	}

	get tenantId() {
		return this.state.tenantId;
	}

	initState = () => {
		const queryState = this.routerStore.queryParams;
		if (queryState && !_isEmpty(queryState)) {
			const encodedState = mapEncodedUrl(queryState);
			this.state = { ...encodedState };
			this.authStore.setCurrentTenantId(queryState.tenantId);
		} else {
			this.setInitialStateInUrl();
		}
	};

	getStateVariables = (variables: string) => {
		return this.state[variables];
	};

	getEncodedURLWithNewState(params: Record<string, unknown>, customURL?: string): string {
		const currentState = { ...this.state, ...params };
		const noEmptyVariables = removeEmptyVariables(currentState);
		const decodedState = mapDecodedUrl(noEmptyVariables);

		let url = location.origin + location.pathname;
		if (customURL) {
			url = customURL;
		}

		if (!decodedState) {
			return '';
		}

		return this.routerStore.getEncoded(decodedState, url);
	}

	updateStore = (params: Record<string, unknown>) => {
		const updatedState = removeEmptyVariables({ ...this.state, ...params });
		this.state = updatedState;

		this.setUpdatedStateInUrl(updatedState);
	};

	setUpdatedStateInUrl = (updatedState: Record<string, unknown>) => {
		const decodedState = mapDecodedUrl(updatedState);

		if (decodedState) {
			this.routerStore.pushEncoded(decodedState);
		}
	};

	setInitialStateInUrl = () => {
		const decodedState = mapDecodedUrl(this.state);
		const queryState = this.routerStore.queryParams;
		if (!_isEqual(decodedState, queryState) && decodedState) {
			this.routerStore.pushEncoded(decodedState);
		}
	};

	clearUrl = (state: string) => {
		this.updateStore({ [state]: undefined });
	};

	locationDisposer = reaction(
		() => this.routerStore.location,
		(nextLocation, prevLocation) => {
			const hasQueryParamsChanged = nextLocation?.search !== prevLocation?.search;

			if (!this.state.inDocument && hasQueryParamsChanged) {
				const queryState = this.routerStore.queryParams;
				const encodedState = mapEncodedUrl(queryState);
				this.state = { ...encodedState };
			}
		},
	);

	dispose = () => {
		this.locationDisposer();
	};
}
