import { investigationTypeTranslation } from '@/app/_common/_components/data-grid/_components/investigation-type-cell/investigation-type-cell';
import { FormDropdownOption } from '@/app/_common/_components/form-components';
import { priorityDropdownOptions, statusDropdownOptions } from '@/app/investigation-details/_common/constants/dropdown-options';
import _isNull from 'lodash/isNull';
import { makeAutoObservable } from 'mobx';
import i18n from '@/translations/i18n';

import { injectInterface } from '@/app/_common/ioc/inject-interface';

import {
	Assignee,
	ClosedDetails,
	ErrorCode,
	Investigation,
	InvestigationAlertSummary,
	InvestigationStatus,
	Maybe,
	SleepingDetails,
	UpdateInvestigation,
} from '@/generated/graphql';

import { getFormattedDate } from '@/app/_common/utils';

import { InvestigationDetailsDataStore } from './investigation-details.data-store';
import { UsersDataStore } from '@/app/_common/stores/users-store/users.data-store';
import { DetectedByTypes, InvestigationTypename } from '@/app/_common/constants';
import { NotificationsStore } from '@/app/_common/stores';
import { Namespaces } from '@/translations/namespaces';
import { getGraphQLError } from '@/app/_common/graphql/graphql-error-handler';
import { InvestigationsEditDataStore } from '@/app/investigations/_common/stores/investigations-edit/investigations-edit.data-store';
import { InvestigationNotesListDataStore } from './notes';

interface State {
	selectedTab: number;
}

const INITIAL_STATE = {
	selectedTab: -1,
};

export class InvestigationDetailsViewStore {
	private investigationDetailsDataStore = injectInterface(this, InvestigationDetailsDataStore);
	private assigneeDataStore = injectInterface(this, UsersDataStore);
	private notificationsStore = injectInterface(this, NotificationsStore);
	private investigationDetailsEditStore = injectInterface(this, InvestigationsEditDataStore);
	private investigationNotesListDataStore = injectInterface(this, InvestigationNotesListDataStore);
	private state: State = INITIAL_STATE;

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
		this.investigationDetailsDataStore.read();
		this.assigneeDataStore.read();
		this.investigationNotesListDataStore.read();
	}

	get selectedTab(): number {
		if (this.state.selectedTab === -1) {
			return [this.isAlertsTabDisabled, this.isQueryResultsTabDisabled].findIndex((disabled) => !disabled);
		}

		return this.state.selectedTab;
	}

	get loading(): boolean {
		return this.investigationDetailsDataStore.loading;
	}

	get error() {
		return this.investigationDetailsDataStore.error;
	}

	get data() {
		return this.investigationDetailsDataStore.data;
	}

	get pageNotFound(): boolean {
		return (
			this.investigationDetailsDataStore.data?.getInvestigation.__typename === 'Error' &&
			this.investigationDetailsDataStore.data?.getInvestigation.code === ErrorCode.NotFound
		);
	}

	get investigationDetails(): Investigation | undefined {
		return this.investigationDetailsDataStore.data?.getInvestigation as Investigation;
	}

	get investigationId(): Investigation['id'] | undefined {
		return this.investigationDetails?.id;
	}

	get investigationName(): Investigation['name'] | undefined {
		return this.investigationDetails?.name;
	}

	get investigationType(): Investigation['type'] | undefined {
		return this.investigationDetails?.type;
	}

	get investigationAssignedId(): Assignee['id'] | undefined {
		return this.investigationDetails?.assignee?.id;
	}

	get investigationAlerts(): Maybe<InvestigationAlertSummary[]> | undefined {
		return this.investigationDetails?.alerts;
	}

	get investigationAlertsCount() {
		return this.investigationAlerts?.length ?? 0;
	}

	get investigationQueryResultsCount() {
		return this.investigationDetails?.queryResults?.length ?? 0;
	}

	get investigationQueryResultsAlertsCount() {
		return this.investigationDetails?.queryResults?.reduce((acc, currentQueryResult) => acc + currentQueryResult.alertCount, 0) ?? 0;
	}

	get investigationQueryResultsEvidenceCount() {
		return this.investigationDetails?.queryResults?.reduce((acc, currentQueryResult) => acc + currentQueryResult.evidenceCount, 0) ?? 0;
	}

	get investigationQueryResultsEventsCount() {
		return this.investigationDetails?.queryResults?.reduce((acc, currentQueryResult) => acc + currentQueryResult.eventCount, 0) ?? 0;
	}

	get isAlertsTabDisabled(): boolean {
		return !!this.investigationDetails && (this.investigationDetails.alerts?.length === 0 || this.investigationDetails.alerts === null);
	}

	get isQueryResultsTabDisabled(): boolean {
		return !!this.investigationDetails && (this.investigationDetails.queryResults?.length === 0 || this.investigationDetails.queryResults === null);
	}

	get investigationAlertsDetectedByAIEngineCount() {
		const alerts = this.investigationAlerts || [];
		const aiEngineAlarms = alerts.filter(({ event_source }) => event_source === DetectedByTypes.AIEngine || event_source === DetectedByTypes.RTCE_AI);
		return aiEngineAlarms.length;
	}

	get investigationAlertsDetectedByVendorCount() {
		const alerts = this.investigationAlerts || [];
		const vendorAlarms = alerts.filter(
			({ event_source }) =>
				event_source !== DetectedByTypes.AIEngine && event_source !== DetectedByTypes.RTCE_AI && event_source !== DetectedByTypes.BatchEngine,
		);
		return vendorAlarms.length;
	}

	get initialType(): string | undefined {
		return investigationTypeTranslation.get(this.investigationDetails?.type as string);
	}

	get initialStatus(): FormDropdownOption | undefined {
		return statusDropdownOptions.find((option: FormDropdownOption) => option.value === this.investigationDetails?.status);
	}

	get isStatusClosed(): boolean {
		return this.initialStatus?.value === InvestigationStatus.Closed;
	}

	get initialAssignee(): FormDropdownOption | undefined {
		const investigationAssignee = this.investigationDetails?.assignee;

		if (investigationAssignee?.id) {
			return { value: investigationAssignee?.id, label: investigationAssignee?.displayName };
		}

		if (_isNull(investigationAssignee)) {
			return { value: null, label: 'Unassigned' };
		}

		return undefined;
	}

	get initialPriority(): FormDropdownOption | undefined {
		return priorityDropdownOptions.find((option: FormDropdownOption) => option.value === this.investigationDetails?.priority);
	}

	get timestamp() {
		return getFormattedDate(this.investigationDetails?.timestamp);
	}

	get lastUpdated() {
		return getFormattedDate(this.investigationDetails?.last_updated);
	}

	get status(): InvestigationStatus | undefined {
		return this.investigationDetails?.status;
	}

	get closedDetails(): Maybe<ClosedDetails> | undefined {
		return this.investigationDetails?.closedDetails;
	}

	get sleepingDetails(): Maybe<SleepingDetails> | undefined {
		return this.investigationDetails?.sleepingDetails;
	}

	get statusDetails(): Maybe<SleepingDetails & { reason: string }> | Maybe<ClosedDetails> | Record<string, never> {
		const status = this.status;

		if (status === undefined || status === null) {
			return {};
		}
		if (status === InvestigationStatus.Sleep) {
			const sleepingDetails = this.sleepingDetails;

			if (sleepingDetails === undefined || sleepingDetails === null) {
				return {};
			}

			return {
				...sleepingDetails,
				reason: '',
			};
		}
		if (status === InvestigationStatus.Closed) {
			const closedDetails = this.closedDetails;

			if (closedDetails === undefined || closedDetails === null) {
				return {};
			}

			return closedDetails;
		}

		return {};
	}

	get closedDetailsTimestamp(): string {
		return getFormattedDate(this.investigationDetails?.closedDetails?.timestamp);
	}

	setSelectedTab = (tab: number) => {
		this.state.selectedTab = tab;
	};

	handleTabChangeAfterUnassign = (currentTab: number) => {
		// no alerts and no queryResults
		if (this.investigationQueryResultsCount === 0 && this.investigationAlertsCount === 0) {
			this.setSelectedTab(-1);
		}
		// remove last alert switch to queryResults
		else if (currentTab === 0 && this.investigationAlertsCount === 0 && this.investigationQueryResultsCount > 0) {
			this.setSelectedTab(1);
		}
		// remove last queryResult switch to alerts
		else if (currentTab === 1 && this.investigationQueryResultsCount === 0 && this.investigationAlertsCount > 0) {
			this.setSelectedTab(0);
		}
	};

	submitEditInvestigationForm = async (data: UpdateInvestigation) => {
		if (!this.investigationId) return;
		const response = await this.investigationDetailsEditStore.update({
			updateInvestigation: data,
		});

		const responseData = response?.data?.updateInvestigation;
		if (responseData?.__typename === InvestigationTypename.Investigation) {
			this.notificationsStore.openSuccess({
				title: i18n.t('updateInvestigation.updateName.success.title', { ns: Namespaces.Notifications, name: responseData?.name }),
			});
			return true;
		} else if (responseData?.__typename === InvestigationTypename.Error) {
			const error = getGraphQLError(i18n.t('updateInvestigation.updateName.error.title', { ns: Namespaces.Notifications }), responseData);
			this.notificationsStore.openError(error);
			return false;
		}
	};
	read = () => {
		this.investigationDetailsDataStore.read();
	};
}
