import { FC, useMemo, MouseEvent } from 'react';
import { AlertActionProps } from '@/app/_common/types';
import { ContextMenuItem, ContextMenuSection } from '@/app/_common/_components/context-menu';
import { invokeConditionally } from '@/app/_common/utils';
import { Investigation } from '@/generated/graphql';

import styles from './header-alert-actions-content.module.scss';
import { DetailsViewItemData } from '@/app/_common/_components/details-view/types';

interface HeaderAlertActionsContentProps {
	data: {
		listInvestigations?: {
			edges: {
				node: Investigation;
			}[];
		};
	};
	loading: boolean;
	close: (event?: MouseEvent) => void;
	singleActionsTitle?: string;
	bulkActionsTitle?: string;
	singleActions?: AlertActionProps[];
	bulkActions?: AlertActionProps[];
}

export const HeaderAlertActionsContent: FC<HeaderAlertActionsContentProps> = ({
	data,
	loading,
	close,
	singleActionsTitle,
	singleActions,
	bulkActions,
	bulkActionsTitle,
}) => {
	const investigations = data?.listInvestigations?.edges?.map((item) => item.node) || [];

	const sections = useMemo(() => {
		return [
			{
				title: singleActionsTitle,
				actions: singleActions,
				showLoading: true,
			},
			{
				title: bulkActionsTitle,
				actions: bulkActions,
				showLoading: true,
			},
		];
	}, [singleActionsTitle, singleActions, bulkActionsTitle, bulkActions]);

	return (
		<div className={styles.content} data-testid="alert-details-actions-menu">
			{sections.map(({ title, actions, showLoading }) => {
				const filteredActions = actions?.filter(({ show }) => invokeConditionally(show, investigations));

				if (!filteredActions || filteredActions.length === 0) {
					return null;
				}

				return (
					<ContextMenuSection key={title} className={styles.section} title={title} loading={showLoading && loading}>
						{filteredActions?.map(({ id, label, disabled, disabledTooltip, alerts, onClick }) => {
							const actionAlerts: DetailsViewItemData[] = invokeConditionally(alerts, investigations) || [];
							const actionLabel = invokeConditionally(label, actionAlerts?.length || 0);

							return (
								<ContextMenuItem
									key={`${title?.toLowerCase()}_${id}`}
									disabled={invokeConditionally(disabled, investigations)}
									disabledTooltip={disabledTooltip}
									onClick={() => {
										// @ts-ignore TODO: Temporary solution, will be improved with general improvement for types for details, menu and table
										onClick?.(actionAlerts);
										close();
									}}
								>
									{actionLabel}
								</ContextMenuItem>
							);
						})}
					</ContextMenuSection>
				);
			})}
		</div>
	);
};
