import { forwardRef, ReactElement, PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './read-only-wrapper.module.scss';

interface ReadOnlyWrapperProps {
	id?: string;
	title?: string;
	subTitle?: string;
	active?: boolean;
	actions?: ReactElement;
	className?: string;
}

export const ReadOnlyWrapper = forwardRef<HTMLDivElement | null, PropsWithChildren<ReadOnlyWrapperProps>>(
	({ id, active, title, subTitle, actions, children, className }, ref) => {
		return (
			<div className={classNames(styles.readOnlyWrapper, className, { [styles.active]: active })} id={id} ref={ref}>
				<div className={styles.header}>
					<div className={styles.titles}>
						{title && (
							<span className={styles.title} data-testid="read-only-wrapper-title">
								{title}
							</span>
						)}
						{subTitle && <span className={styles.subTitle}>{subTitle}</span>}
					</div>
					{actions && <div className={styles.actions}>{actions}</div>}
				</div>
				<div className={styles.content} data-testid="read-only-wrapper-content">
					{children}
				</div>
			</div>
		);
	},
);

ReadOnlyWrapper.displayName = 'ReadOnlyWrapper';
