import { LabelValueRowValue } from '@/app/_common/_components/label-value-row/_common/types';

import { ValueNormal } from './value-normal';

interface ValueJoinedListProps {
	value?: LabelValueRowValue;
	formatSeparator?: string;
}

export const ValueJoinedList = ({ value, formatSeparator }: ValueJoinedListProps) => {
	if (!Array.isArray(value)) {
		return <ValueNormal value={value} />;
	}

	return <span data-testid="value">{value.join(formatSeparator)} </span>;
};
