import { ChartData, ChartDataItem, ChartStackBarKeys, ContextMenuComponentProps, StackedChartDataItem } from '@/app/_common/types';
import { ReactNode } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

export type AvailablePageSize = 15 | 30 | 50 | 100 | 500;

export interface ResultsCellProps extends GridCellProps {
	title?: string;
	tooltip?: string;
	defaultMessage?: string;
	className?: string;
	onClick?: () => void;
	searchValue?: string;
	ContextMenuComponent?: React.FC<ContextMenuComponentProps>;
	Icon?: ReactNode;
	oldContextMenu?: boolean;
}

export const isStackedChartDataItemArray = (data: ChartData): data is StackedChartDataItem[] => {
	return data.some((item) => ChartStackBarKeys.AlertsCount in item && ChartStackBarKeys.TotalCount in item);
};

export const isStackedChartDataItem = (item: ChartDataItem | StackedChartDataItem): item is StackedChartDataItem => {
	return ChartStackBarKeys.AlertsCount in item && ChartStackBarKeys.TotalCount in item;
};
