import { ReactNode, useCallback, useEffect, useRef, RefObject } from 'react';
import classNames from 'classnames';

import { Popup, PopupProps } from '@progress/kendo-react-popup';

import { useOutsideClick } from '@/app/_common/hooks';
import { QueryInOrderDefinitionArgument } from '@/app/_common/types';
import { useDynamicMenu } from '@/app/_common/_components/new-context-menu/_common/hooks/use-dynamic-menu';

import styles from './context-menu.module.scss';

interface PopupEvent {
	target: Popup;
}

export interface ContextMenuProps extends Omit<PopupProps, 'children'> {
	children: ReactNode;
	queryDefinitions?: QueryInOrderDefinitionArgument[];
}

export const ContextMenuComponent = ({ children, className, queryDefinitions = [], show, offset, onClose, ...popupProps }: ContextMenuProps) => {
	const anchorRef = useRef(null) as RefObject<HTMLDivElement>;

	const { read } = useDynamicMenu();

	const handleCloseContextMenu = useCallback(
		(event: MouseEvent) => {
			onClose?.(event as unknown as PopupEvent);
		},
		[onClose],
	);

	useEffect(() => {
		if (show && queryDefinitions.length > 0) {
			read(queryDefinitions);
		}
	}, [read, show, queryDefinitions]);

	const { popupRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(Boolean(show), handleCloseContextMenu);

	return (
		<Popup
			className={classNames(styles.contextMenu, className)}
			anchor={anchorRef.current}
			animate={false}
			show={show}
			offset={offset}
			popupClass={'k-menu-popup'}
			{...popupProps}
		>
			<div data-testid="context-menu" ref={popupRef} role="menu">
				{children}
			</div>
		</Popup>
	);
};
