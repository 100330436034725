import { action, computed, makeObservable, observable, reaction } from 'mobx';
import _isNil from 'lodash/isNil';

import { Filters, FilterValue } from '@/app/_common/types';

import { AlertsDataStore } from '@/app/dashboards/alerts-dashboard/_common/stores/alerts.data-store';
import { sortAlerts } from '@/app/_common/utils';
import { AlertPropertiesPaths, FilterOperators, FilterGroupId, FilterLogic, SortDirection } from '@/app/_common/constants';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AlertEdge, AlertState } from '@/generated/graphql';
import _get from 'lodash/get';
import { computedFn } from 'mobx-utils';
import { AlertsUtilsViewStore } from '@/app/_common/stores';
import { mapFilterOption, getConfidenceOption, getSeverityOption } from '@/app/_common/_components/data-grid/utils';

const INITIAL_COLUMNS = {
	[AlertPropertiesPaths.Selected]: true,
	[AlertPropertiesPaths.Timestamp]: true,
	[AlertPropertiesPaths.InvestigationSummaryId]: true,
	[AlertPropertiesPaths.Severity]: true,
	[AlertPropertiesPaths.Confidence]: true,
	[AlertPropertiesPaths.Title]: true,
	[AlertPropertiesPaths.Source]: true,
	[AlertPropertiesPaths.Destination]: true,
	[AlertPropertiesPaths.Mitre]: true,
	[AlertPropertiesPaths.DetectedBy]: true,
};

const INITIAL_SORT = [{ field: AlertPropertiesPaths.Severity, dir: SortDirection.Desc }];

export class AlertsDismissListViewStore extends AlertsUtilsViewStore<AlertEdge> {
	private dataStore = injectInterface(this, AlertsDataStore);

	getFilterOptions = computedFn((field: string) => {
		const counters = this.getCountedValues(field);
		return this.getUniqValues(field).map((value) => {
			return mapFilterOption(
				field,
				{
					value: value as FilterValue,
					counter: counters[value as string],
				},
				{
					[AlertPropertiesPaths.Confidence]: getConfidenceOption,
					[AlertPropertiesPaths.Severity]: getSeverityOption,
				},
			);
		});
	});

	private initialIds: string[] = [];

	constructor() {
		super(AlertPropertiesPaths.Id, INITIAL_COLUMNS, INITIAL_SORT, sortAlerts);
		makeObservable(this, {
			// @ts-ignore - for protected/private fields
			initialIds: observable,
			alertIds: computed,
			setInitialAlertIds: action,
			setSourceData: action,
		});
	}

	get alertIds() {
		const data = this.data;

		return data.reduce<string[]>((result: string[], alert) => {
			const alertNodeId = alert.node.id;

			if (alert.node.state?.alertState === AlertState.Dismissed || !this.isRowSelected(alertNodeId)) {
				return result;
			}

			return [...result, alertNodeId];
		}, []);
	}

	get selectedAssignCount() {
		return this.data
			.filter((alert) => alert.node.state?.alertState === AlertState.Assigned)
			.filter((item) => {
				const id = _get(item, this.getIdPath);

				return this.selectedRows[id];
			}).length;
	}

	setGridHeaderFilter = (field: string, values: Filters) => {
		const filters = values.map((value) => {
			// eslint-disable-next-line @typescript-eslint/ban-types
			let operator: string | Function = 'eq';

			if (field === AlertPropertiesPaths.Source || field === AlertPropertiesPaths.Destination) {
				operator = FilterOperators.IsSomeIn;
			}

			return { value, field, operator, ignoreCase: false };
		});

		this.setFilter({
			id: FilterGroupId.gridHeader,
			filters,
			logic: FilterLogic.And,
			nestedId: field,
			nestedLogic: FilterLogic.Or,
		});
	};

	isStateDismissed = (alert: AlertEdge) => {
		if (_isNil(alert.node.state)) {
			return false;
		}
		return alert.node.state?.alertState === AlertState.Dismissed;
	};

	setInitialAlertIds = (ids: string[]) => {
		this.initialIds = ids;
	};

	dispose = () => {
		this.alertDataDisposer();
		this.initialIdsDisposer();
	};

	setSourceData = () => {
		const data = this.dataStore.alerts.filter(({ node }) => this.initialIds.includes(node.id));

		this.sourceData = data;

		this.selectRows(
			data.reduce<{ [key: string]: boolean }>((result, alert) => {
				result[alert.node.id] = !this.isStateDismissed(alert);
				return result;
			}, {}),
		);
	};

	alertDataDisposer = reaction(
		() => this.dataStore.alerts,
		() => {
			this.setSourceData();
		},
	);
	initialIdsDisposer = reaction(
		() => this.initialIds,
		() => {
			this.setSourceData();
		},
	);
}
