import { FieldValue, ValueOperator } from '@/generated/graphql';
import { ChipItem } from '@/app/_common/_components';
import { ActionHistoryDataGridPropertyPaths, MultiselectAvailableFilters } from '@/app/_features/action-history/_common/constants';

export const prepareMultiselectFilters = (selectedValues: ChipItem[]): FieldValue[] => {
	const filters: FieldValue[] = [];
	const values = selectedValues.map((value) => value.value);

	const shouldFetchOnlyFranchiseActions = values.length === 1 && values[0] === MultiselectAvailableFilters.FRANCHISE;
	const shouldFetchOnlyClientActions = values.length === 1 && values[0] === MultiselectAvailableFilters.CLIENT;

	if (shouldFetchOnlyFranchiseActions) {
		filters.push({ field: ActionHistoryDataGridPropertyPaths.IsFranchiseAction, operator: ValueOperator.Is, value: 'true' });
	} else if (shouldFetchOnlyClientActions) {
		filters.push({ field: ActionHistoryDataGridPropertyPaths.IsFranchiseAction, operator: ValueOperator.IsNot, value: 'true' });
	}

	return filters;
};
