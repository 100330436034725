import { Alert, InvestigationStatus, InvestigationSummary, Maybe } from '@/generated/graphql';

export enum AlertActions {
	Assign = 'assign',
	Unassign = 'unassign',
	Dismiss = 'dismiss',
	Undismiss = 'undismiss',
}

export interface InvestigationSummaryExtended extends InvestigationSummary {
	name?: string;
	status?: InvestigationStatus;
}

export interface AlertExtended extends Alert {
	investigationSummary?: Maybe<InvestigationSummaryExtended>;
}
