import { padTo2Digits } from '@/app/_common/_components/date-picker/utils';

export const formatDateTimePickerInputDate = (date?: Date | null) => {
	if (!date) {
		return '';
	}

	return (
		[date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') +
		', ' +
		[padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
	);
};
