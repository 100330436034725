import { createRef, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { getter } from '@progress/kendo-react-common';

import classNames from 'classnames';

import { GridPageChangeEvent, GridRowClickEvent, GridRowProps } from '@progress/kendo-react-grid';

import { AlertEdge } from '@/generated/graphql';
import { Namespaces } from '@/translations/namespaces';
import {
	ACTIONS_COLUMN_NAME,
	AlertHeadersTranslationKeys,
	AlertPropertiesPaths,
	AlertsKeyPrefix,
	INITIAL_PAGE,
	MIN_COLUMN_WIDTH,
	PAGE_SIZE,
} from '@/app/_common/constants';
import { useDataGridWidth, useResizeObservation } from '@/app/_common/hooks';
import { Filters, WidthsState } from '@/app/_common/types';
import { getDataGridInfiniteScrollData, sortAlertFilters, getLabelWithCounter, filterActionsByShow, createStorageDetails } from '@/app/_common/utils';
import { getResetFiltersMenuItem, setDefaultWidthToVisibleColumns } from '@/app/_common/_components/data-grid/utils';
import { Column, DataGrid, ListHeader, WidgetContainer, GridConfig } from '@/app/_common/_components';
import { ActionsHeaderCell, ActionsHeaderItem, DataHeaderCell } from '@/app/_common/_components/data-grid/_components';
import { PLACEHOLDER_COLUMN } from '@/app/_common/_components/data-grid/constants';
import { PageLayoutViewStore } from '@/app/_common/_components/page-layout/page-layout.view-store';
import { AlertsAssignDialogViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/alerts-assign-dialog.view-store';
import { AlertsDetails } from '@/app/_common/_components/details-view/alerts-details';
import { DashboardAlertDetailsViewStore } from '@/app/_common/_components/details-view/stores';
import { HeaderSelectCell } from '@/app/_common/_components/data-grid/_components/header-select-cell/header-select-cell';

import { AlertsTimeRangeFilterStore, AlertsStateActionsViewStore } from '@/app/dashboards/alerts-dashboard/_common/stores';
import { AlertsHeaderFilter } from '@/app/dashboards/alerts-dashboard/_common/constants/alerts.constant';
import { AlertGridEdge } from '@/app/dashboards/alerts-dashboard/_common/types/types';
import { AlertsDashboardContextMenuContent, AlertsGridNoRecordsContent } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/_components';
import { AlertsListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-list';
import { AlertsAssignDialog } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/alerts-assign-dialog';
import { AlertsDismissDialog } from '@/app/dashboards/alerts-dashboard/_components/alerts-dismiss-dialog/alerts-dismiss-dialog';
import { DashboardPageViewStore } from '@/app/dashboards/alerts-dashboard/_components/dashboards-page/dashboard-page.view-store';
import { AlertsListRow } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/alerts-list-row';
import { isPaginationEnabled } from '@/app/dashboards/alerts-dashboard/_common/utils';
import {
	AlertSelectedCell,
	AlertTimestampCell,
	AlertActionCell,
	AlertStateCell,
	AlertSeverityCell,
	AlertConfidenceCell,
	AlertTitleCell,
	AlertSourceCell,
	AlertDestinationCell,
	AlertMitreCell,
	AlertDetectedByCell,
	AlertPlaceholderCell,
} from '@/app/dashboards/alerts-dashboard/_components/alerts-list/cell-components';
import { useAlertsContextMenuConfig } from '@/app/_features/alerts-actions-menu/_common/hooks';

import styles from './alerts-list.module.scss';
import dataGridStyles from '@/app/_common/_components/data-grid/data-grid.module.scss';

enum HEADER_CELL_ACTIONS {
	AssignOrCreateInvestigation = 'assignOrCreateInvestigation',
	Dismiss = 'dismiss',
	Undismiss = 'undismiss',
}

const COLUMNS_LOCAL_STORAGE_KEY = 'dashboards/alerts/widget/columns-width';
const INITIAL_COLUMNS_WIDTHS_VERSION = 'v0'; // change it if you change the content of `INITIAL_WIDTHS` constant
const INITIAL_WIDTHS = {
	[AlertPropertiesPaths.Selected]: '50px',
	[AlertPropertiesPaths.State]: '110px',
	[AlertPropertiesPaths.Severity]: '90px',
	[AlertPropertiesPaths.Timestamp]: '160px',
	[AlertPropertiesPaths.Confidence]: '80px',
	[AlertPropertiesPaths.Source]: '130px',
	[AlertPropertiesPaths.Mitre]: '110px',
	[AlertPropertiesPaths.DetectedBy]: '70px',
	[ACTIONS_COLUMN_NAME]: '70px',
};
const DATA_GRID_WITH_DETAIL_HEIGHT = 300;
const ALERTS_DETAILS_INITIAL_VALUE = 100;
const ALERTS_LIST_ROW_HIGHT = 33;
const ALERTS_DETAILS_MAX_HEIGHT = 360;
const pageSize = PAGE_SIZE;

const DATA_ITEM_KEY = AlertPropertiesPaths.Id;
const SELECTED_FIELD = AlertPropertiesPaths.Selected;
const idGetter = getter(AlertPropertiesPaths.Id);

const requestIfNeeded = (skip: number, store: AlertsListViewStore) => {
	if (store.loading || store.alerts.length === store.totalCount) {
		return;
	}

	for (let i = skip; i < skip + pageSize; i++) {
		if (store.alerts[i + 1]?.node?.id === undefined) {
			store.readMore();
			return;
		}
	}
};

const onHandleRowClick = (event: GridRowClickEvent, detailsStore: DashboardAlertDetailsViewStore): void => {
	if (!event.dataItem.loading) {
		detailsStore.setSelectedAlertId((event.dataItem as AlertEdge).node.id);
	}
};

export const AlertsList = observer(() => {
	const store = useInstance(AlertsListViewStore);
	const pageStore = useInstance(PageLayoutViewStore);
	const detailsStore = useInstance(DashboardAlertDetailsViewStore);
	const dashBoardStore = useInstance(DashboardPageViewStore);
	const alertsAssignDialogStore = useInstance(AlertsAssignDialogViewStore);
	const filtersStore = useInstance(AlertsTimeRangeFilterStore);
	const alertStateActionStore = useInstance(AlertsStateActionsViewStore);

	const [page, setPage] = useState(INITIAL_PAGE);

	const handlePageChange = useCallback(
		(event: GridPageChangeEvent) => {
			const paginationEnabled = isPaginationEnabled(
				store.alerts,
				store.headerFilterValues,
				store.totalCountWithHeaderFilters,
				store.isReadMoreDisabledByFilters,
				store.hasNextPage,
			);

			if (paginationEnabled) {
				requestIfNeeded(event.page.skip, store);
			}

			setPage(event.page);
		},
		[store],
	);

	useEffect(() => {
		setPage(INITIAL_PAGE);
	}, [filtersStore.filters.timeRange?.from, filtersStore.filters.timeRange?.to]);

	const { widths, setWidths, resetWidths, fitWidths, ref, measuredRef } = useDataGridWidth<WidthsState>(
		{ ...createStorageDetails('localStorage', COLUMNS_LOCAL_STORAGE_KEY, INITIAL_COLUMNS_WIDTHS_VERSION), nestedObjectKey: 'columns-width' },
		setDefaultWidthToVisibleColumns(INITIAL_WIDTHS, store.columns),
		store.columns,
	);

	const [setRef, { height }] = useResizeObservation();

	const selectedRowRef = createRef<HTMLTableRowElement>();

	const { t } = useTranslation([Namespaces.AlertsDashboard]);

	useEffect(() => {
		setTimeout(() => {
			selectedRowRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}, 300);
	}, [selectedRowRef]);

	const handleRowClick = useCallback(
		(event: GridRowClickEvent) => {
			onHandleRowClick(event, detailsStore);
		},
		[detailsStore],
	);

	const isAlertRowSelected = useCallback(
		(props: GridRowProps) => {
			return (props.dataItem as AlertGridEdge)?.node?.id === detailsStore.selectedAlert?.id;
		},
		[detailsStore.selectedAlert],
	);

	const handleResetColumns = useCallback(() => {
		store.resetColumns();
		resetWidths();
	}, [resetWidths, store]);

	const handleToggleColumn = (field: string) => {
		store.toggleColumn(field);
		fitWidths();
	};

	const resetGridPosition = () => {
		// @ts-ignore - workaround that uses private properties of the gird
		const vs = ref?.current?.vs;
		if (vs) {
			vs.fixedScroll = false;
			vs.reset();
		}
		setPage(INITIAL_PAGE);
	};

	useEffect(() => {
		resetGridPosition();
		const dataGridElement = ref?.current?.element;
		if (dataGridElement) {
			const dataGridContentElement = dataGridElement.querySelector('.k-grid-content');
			if (dataGridContentElement) {
				dataGridContentElement.scrollTop = 0;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [store.activeEqualFilters]);

	const handleFilterClick = (field: string, values: Filters) => {
		store.setGridHeaderFilter(field, values);
	};

	const handleFilterChange = (filter: AlertsHeaderFilter) => {
		if (filter === AlertsHeaderFilter.Selected) {
			store.toggleIsSelectedRowsFilterActive();
		} else {
			store.setHeaderFilter(filter);
		}

		resetGridPosition();
	};

	const handleClearFilter = (field: string) => {
		store.resetGridHeaderFilter(field);
		resetGridPosition();
	};

	const showedColumns = Object.keys(store.columns).filter((key) => store.columns[key]);

	const rowRender = useCallback(
		(trElement: ReactElement<HTMLTableRowElement>, props: GridRowProps): ReactNode => {
			const id = idGetter(props.dataItem);
			const isSelected = store.isRowSelected(id);
			const className = classNames(dataGridStyles.row, trElement.props.className, {
				[dataGridStyles.selectedRow]: isSelected,
				[dataGridStyles.expandedRow]: isAlertRowSelected(props),
			});

			return (
				<AlertsListRow
					trElement={trElement}
					{...props}
					isAlertRowSelected={isAlertRowSelected}
					selected={isSelected}
					selectedRowRef={selectedRowRef}
					className={className}
					showedColumns={showedColumns}
					widths={widths}
				/>
			);
		},
		[isAlertRowSelected, store, selectedRowRef, showedColumns, widths],
	);

	const onHandleNextAlert = useCallback(
		(currId?: string): void => {
			if (currId) {
				const newSelectedState: { [id: string]: boolean | number[] } = {};
				for (const item of store.data) {
					const alertId = item.node.id;

					if (alertId === currId) {
						newSelectedState[alertId] = false;
					} else {
						newSelectedState[alertId] = store.isRowSelected(alertId);
					}
				}
				store.selectRows(newSelectedState);
			}
		},
		[store],
	);

	const headerActionItems: ActionsHeaderItem[] = [
		{
			...getResetFiltersMenuItem({
				disabled: !store.areFiltersActive,
				resetAllFilters: store.resetAllFilters,
			}),
			show: true,
		},
		{
			id: HEADER_CELL_ACTIONS.AssignOrCreateInvestigation,
			label: getLabelWithCounter(t(HEADER_CELL_ACTIONS.AssignOrCreateInvestigation), store.selectedUnassignedAlertsCount),
			show: () => store.selectedElements.length > 0,
			onClick: () => {
				alertsAssignDialogStore.open(store.selectedElementsIds);
			},
		},
		{
			id: HEADER_CELL_ACTIONS.Dismiss,
			label: getLabelWithCounter(t(HEADER_CELL_ACTIONS.Dismiss), store.selectedDismissedAlertsCount),
			show: () => store.selectedDismissedAlertsCount > 0,
			onClick: () => {
				if (store.selectedDismissedAlertsAssigned()) {
					alertStateActionStore.open(store.selectedDismissableAlertIds);
				} else {
					alertStateActionStore.dismissAlerts(store.selectedDismissableAlertIds);
				}
			},
		},
		{
			id: HEADER_CELL_ACTIONS.Undismiss,
			label: getLabelWithCounter(t(HEADER_CELL_ACTIONS.Undismiss), store.selectedUndismissedAlertsCount),
			show: () => store.selectedUndismissedAlertsCount > 0,
			onClick: () => {
				alertStateActionStore.undismissAlerts(store.selectedUndismissableAlertIds);
			},
		},
	];

	const COLUMNS: Column[] = [
		{
			id: SELECTED_FIELD,
			width: widths[SELECTED_FIELD],
			// Wrapping with observer ensures proper rerendering!
			headerCell: observer((props) => <HeaderSelectCell mode={store.selectionHeaderMode} onChange={props.selectionChange} {...props} />),
			resizable: false,
			locked: true,
			cell: AlertSelectedCell,
		},
		{
			id: AlertPropertiesPaths.Timestamp,
			field: AlertPropertiesPaths.Timestamp,
			title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Timestamp]),
			cell: AlertTimestampCell,
			width: widths[AlertPropertiesPaths.Timestamp],
			minResizableWidth: MIN_COLUMN_WIDTH,
			headerCell: (props) => <DataHeaderCell {...props} sortable={true} sort={store.sort} onSort={store.setSort} />,
			headerClassName: styles.headerCell,
			show: store.getFieldColumnState(AlertPropertiesPaths.Timestamp),
		},
		{
			id: AlertPropertiesPaths.State,
			field: AlertPropertiesPaths.State,
			title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.State]),
			cell: AlertStateCell,
			width: widths[AlertPropertiesPaths.State],
			minResizableWidth: MIN_COLUMN_WIDTH,
			headerCell: (props) => (
				<DataHeaderCell
					{...props}
					sortable={true}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleFilterClick}
					filterOptions={store.getFilterOptions(AlertPropertiesPaths.State)}
					clearFilter={handleClearFilter}
					sortFilterOptions={sortAlertFilters}
					counter={store.getCounter(AlertPropertiesPaths.State)}
					filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.State)}
				/>
			),
			headerClassName: styles.headerCell,
			show: store.getFieldColumnState(AlertPropertiesPaths.State),
		},
		{
			id: AlertPropertiesPaths.Severity,
			field: AlertPropertiesPaths.Severity,
			width: widths[AlertPropertiesPaths.Severity],
			minResizableWidth: MIN_COLUMN_WIDTH,
			title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Severity]),
			cell: AlertSeverityCell,
			headerCell: observer((props) => {
				return (
					<DataHeaderCell
						{...props}
						counter={store.getCounter(AlertPropertiesPaths.Severity)}
						sortable={true}
						sort={store.sort}
						onSort={store.setSort}
						onFilter={handleFilterClick}
						filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Severity)}
						filterOptions={store.getFilterOptions(AlertPropertiesPaths.Severity)}
						clearFilter={handleClearFilter}
						sortFilterOptions={sortAlertFilters}
					/>
				);
			}),
			headerClassName: styles.headerCell,
			show: store.getFieldColumnState(AlertPropertiesPaths.Severity),
		},
		{
			id: AlertPropertiesPaths.Confidence,
			field: AlertPropertiesPaths.Confidence,
			width: widths[AlertPropertiesPaths.Confidence],
			minResizableWidth: MIN_COLUMN_WIDTH,
			title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Confidence]),
			cell: AlertConfidenceCell,
			headerCell: observer((props) => (
				<DataHeaderCell
					{...props}
					counter={store.getCounter(AlertPropertiesPaths.Confidence)}
					sortable={true}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleFilterClick}
					filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Confidence)}
					filterOptions={store.getFilterOptions(AlertPropertiesPaths.Confidence)}
					clearFilter={handleClearFilter}
					sortFilterOptions={sortAlertFilters}
				/>
			)),
			headerClassName: styles.headerCell,
			show: store.getFieldColumnState(AlertPropertiesPaths.Confidence),
		},
		{
			id: AlertPropertiesPaths.Title,
			field: AlertPropertiesPaths.Title,
			width: widths[AlertPropertiesPaths.Title],
			minResizableWidth: MIN_COLUMN_WIDTH,
			title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Title]),
			cell: AlertTitleCell,
			headerCell: observer((props) => (
				<DataHeaderCell
					{...props}
					counter={store.getCounter(AlertPropertiesPaths.Title)}
					sortable={true}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleFilterClick}
					filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Title)}
					filterOptions={store.getFilterOptions(AlertPropertiesPaths.Title)}
					clearFilter={handleClearFilter}
					sortFilterOptions={sortAlertFilters}
				/>
			)),
			headerClassName: styles.headerCell,
			show: store.getFieldColumnState(AlertPropertiesPaths.Title),
		},
		{
			id: AlertPropertiesPaths.Source,
			field: AlertPropertiesPaths.Source,
			width: widths[AlertPropertiesPaths.Source],
			minResizableWidth: MIN_COLUMN_WIDTH,
			title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Source]),
			cell: AlertSourceCell,
			headerCell: observer((props) => (
				<DataHeaderCell
					{...props}
					counter={store.getCounter(AlertPropertiesPaths.Source)}
					sortable={true}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleFilterClick}
					filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Source)}
					filterOptions={store.getFilterOptions(AlertPropertiesPaths.Source)}
					clearFilter={handleClearFilter}
					sortFilterOptions={sortAlertFilters}
				/>
			)),
			headerClassName: styles.headerCell,
			show: store.getFieldColumnState(AlertPropertiesPaths.Source),
		},
		{
			id: AlertPropertiesPaths.Destination,
			field: AlertPropertiesPaths.Destination,
			width: widths[AlertPropertiesPaths.Destination],
			minResizableWidth: MIN_COLUMN_WIDTH,
			title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Destination]),
			cell: AlertDestinationCell,
			headerCell: observer((props) => (
				<DataHeaderCell
					{...props}
					counter={store.getCounter(AlertPropertiesPaths.Destination)}
					sortable={true}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleFilterClick}
					filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Destination)}
					filterOptions={store.getFilterOptions(AlertPropertiesPaths.Destination)}
					clearFilter={handleClearFilter}
					sortFilterOptions={sortAlertFilters}
				/>
			)),
			headerClassName: styles.headerCell,
			show: store.getFieldColumnState(AlertPropertiesPaths.Destination),
		},
		{
			id: AlertPropertiesPaths.Mitre,
			field: AlertPropertiesPaths.Mitre,
			width: widths[AlertPropertiesPaths.Mitre],
			minResizableWidth: MIN_COLUMN_WIDTH,
			title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Mitre]),
			cell: AlertMitreCell,
			headerCell: observer((props) => (
				<DataHeaderCell
					{...props}
					counter={store.getCounter(AlertPropertiesPaths.Mitre)}
					sortable={true}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleFilterClick}
					filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Mitre)}
					filterOptions={store.getFilterOptions(AlertPropertiesPaths.Mitre, true, t('alertsGrid.empty'))}
					clearFilter={handleClearFilter}
					sortFilterOptions={sortAlertFilters}
				/>
			)),
			headerClassName: styles.headerCell,
			show: store.getFieldColumnState(AlertPropertiesPaths.Mitre),
		},
		{
			id: AlertPropertiesPaths.DetectedBy,
			field: AlertPropertiesPaths.DetectedBy,
			width: widths[AlertPropertiesPaths.DetectedBy],
			minResizableWidth: MIN_COLUMN_WIDTH,
			title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.DetectedBy]),
			cell: AlertDetectedByCell,
			headerCell: observer((props) => (
				<DataHeaderCell
					{...props}
					counter={store.getCounter(AlertPropertiesPaths.DetectedBy)}
					sortable={true}
					sort={store.sort}
					onSort={store.setSort}
					onFilter={handleFilterClick}
					filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.DetectedBy)}
					filterOptions={store.getAlertsDetectedByMainFilters(AlertPropertiesPaths.DetectedBy)}
					filterOptionsSubgroups={[store.getDetectedByVendorFilters(AlertPropertiesPaths.DetectedBy)].filter((subgroup) => subgroup.options)}
					clearFilter={handleClearFilter}
					sortFilterOptions={sortAlertFilters}
				/>
			)),
			headerClassName: styles.headerCell,
			show: store.getFieldColumnState(AlertPropertiesPaths.DetectedBy),
		},
		/* A column that shrinks or expands to fill in the remaining space */
		{
			...PLACEHOLDER_COLUMN,
			cell: AlertPlaceholderCell,
		},
		{
			id: ACTIONS_COLUMN_NAME,
			field: ACTIONS_COLUMN_NAME,
			width: widths[ACTIONS_COLUMN_NAME],
			cell: AlertActionCell,
			headerCell: observer((props) => {
				const items = headerActionItems.filter((item: ActionsHeaderItem) => filterActionsByShow(item));

				return (
					<ActionsHeaderCell
						{...props}
						items={items}
						columnOptions={Object.keys(store.columns).map((key: string) => ({
							id: key,
							// @ts-ignore
							label: t(AlertHeadersTranslationKeys[key]),
							checked: store.columns[key],
						}))}
						hideColumnsDisabled={store.hideColumnsDisabled}
						onColumnsReset={handleResetColumns}
						onColumnOptionsChange={handleToggleColumn}
					/>
				);
			}),
			headerClassName: styles.headerCell,
			resizable: false,
			locked: true,
		},
	];

	const alertsDetailsHeight =
		ALERTS_DETAILS_INITIAL_VALUE + store.dataCount * ALERTS_LIST_ROW_HIGHT < ALERTS_DETAILS_MAX_HEIGHT
			? ALERTS_DETAILS_INITIAL_VALUE + store.dataCount * ALERTS_LIST_ROW_HIGHT
			: ALERTS_DETAILS_MAX_HEIGHT;

	const data: AlertGridEdge[] = useMemo(() => {
		const showSkeleton = isPaginationEnabled(
			store.alerts,
			store.headerFilterValues,
			store.totalCountWithHeaderFilters,
			store.isReadMoreDisabledByFilters,
			store.hasNextPage,
		);

		return getDataGridInfiniteScrollData<AlertGridEdge>(
			store.alerts,
			{ selected: false, loading: true, __typename: 'AlertEdge', cursor: '' },
			store.totalCountWithHeaderFilters,
			showSkeleton,
		);
	}, [store.alerts, store.headerFilterValues, store.totalCountWithHeaderFilters, store.isReadMoreDisabledByFilters, store.hasNextPage]);

	const gridProps = useMemo<GridConfig>(
		() => ({
			data: data.slice(page.skip, page.skip + PAGE_SIZE),
			dataItemKey: DATA_ITEM_KEY,
			fixedScroll: true,
			sort: store.sort,
			rowRender: rowRender,
			resizable: true,
			skip: page.skip,
			total: data.length,
			pageSize: pageSize,
			rowHeight: ALERTS_LIST_ROW_HIGHT,
			scrollable: 'virtual',
			style: {
				height: detailsStore.selectedAlert ? DATA_GRID_WITH_DETAIL_HEIGHT : height,
			},
			onPageChange: handlePageChange,
			onHeaderSelectionChange: store.toggleSelectAllRows,
			onRowClick: handleRowClick,
			onColumnResize: setWidths,
		}),
		[
			data,
			height,
			page.skip,
			store.sort,
			detailsStore.selectedAlert,
			rowRender,
			setWidths,
			handlePageChange,
			handleRowClick,
			store.toggleSelectAllRows,
		],
	);

	const convertedAlerts = useMemo(() => store.data.map(({ selected, node }) => ({ selected, ...node })), [store.data]);
	const contextMenuOptions = useAlertsContextMenuConfig(convertedAlerts, store.selectedAlertIds, store.selectRows, 'node');

	return (
		<>
			<WidgetContainer
				ref={setRef}
				overlay={dashBoardStore.editable}
				header={
					<ListHeader
						data-testid="alerts-card-header"
						countLabel={t(`${AlertsKeyPrefix.AlertsGrid}.countLabel`, { count: store.alerts.length, total: store.totalCount })}
						selectedCount={store.selectedCount}
						newCount={store.unassignedCount}
						openCount={store.assignedCount}
						dismissedCount={store.dismissedCount}
						appliedFilters={store.headerFilterValues}
						availableFilters={[AlertsHeaderFilter.Selected, AlertsHeaderFilter.Unassigned, AlertsHeaderFilter.Assigned, AlertsHeaderFilter.Dismissed]}
						onFilterChange={handleFilterChange}
						isSelectedRowsFilterActive={store.isSelectedRowsFilterActive}
						title={t('alertsGrid.title')}
					/>
				}
				loading={store.loading}
				fullHeight={true}
			>
				<DataGrid
					data-testid="alerts-grid"
					gridProps={gridProps}
					height={detailsStore.selectedAlert ? DATA_GRID_WITH_DETAIL_HEIGHT : height}
					columns={COLUMNS}
					gridRef={ref}
					measuredRef={measuredRef}
					gridNoRecordsContent={<AlertsGridNoRecordsContent />}
					contextMenuProps={contextMenuOptions}
					contextMenuContent={AlertsDashboardContextMenuContent}
				/>
			</WidgetContainer>
			<div
				className={classNames(!detailsStore.selectedAlert ? styles.alertDetailsHidden : styles.alertDetailsVisible, {
					[styles.alertDetailsVisibleDrawer]: !pageStore.drawerOpened,
				})}
				style={{ top: `${alertsDetailsHeight}px` }}
			>
				<AlertsDetails alertDetailsViewStore={detailsStore} onHandleNextAlert={onHandleNextAlert} />
			</div>
			<AlertsAssignDialog />
			<AlertsDismissDialog />
		</>
	);
});
