import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { ListNotes } from '@/app/_common/graphql/queries/notes.queries';
import { ArtifactType, FieldValue, Query, ValueOperator } from '@/generated/graphql';
import { action, makeObservable } from 'mobx';

export type ListNotes = Pick<Query, 'listNotes'>;

export class InvestigationNotesListDataStore extends GraphqlBaseDataStore<ListNotes> {
	constructor() {
		super();
		makeObservable(this, {
			read: action,
		});
	}

	read(investigationId?: string) {
		const id = investigationId ? investigationId : window.location.pathname.split('/').pop() || '';

		const variables = {
			tenantId: this.authStore.currentTenantId,
			filtersInput: {
				valueFilters: this.getValueFilters(id),
			},
		};

		this.query({
			query: ListNotes,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}

	private getValueFilters = (investigationId: string): FieldValue[] => {
		return [
			{
				value: ArtifactType.Investigation,
				operator: ValueOperator.Is,
				field: 'artifactType',
			},
			{
				value: investigationId,
				operator: ValueOperator.Is,
				field: 'artifactId',
			},
		];
	};
}
