import React, { FC } from 'react';

import styles from './error-details-title.module.scss';

interface ErrorDetailsTitleProps {
	title?: string;
}

export const ErrorDetailsTitle: FC<ErrorDetailsTitleProps> = ({ title }) => {
	if (!title) {
		return null;
	}
	return <div className={styles.title}>{title}</div>;
};
