import { safeJSONParse } from '../../safe-json-parse';
import type { StorageVersion } from '@/app/_common/types';

type MigrateInOrderConfig = {
	key: string;
	latestVersion: StorageVersion;
	migrationScripts: {
		v0: () => void;
		[key: StorageVersion]: () => void;
	};
};

const DEFAULT_INITIAL_STORAGE_VERSION = 'v0' satisfies StorageVersion;

export const migrateInOrder = ({ key, latestVersion, migrationScripts }: MigrateInOrderConfig) => {
	const data = localStorage.getItem(key);
	const parsedData = data ? safeJSONParse(data) : null;
	if (parsedData === null) {
		localStorage.removeItem(key);
	}
	const currentVersion: StorageVersion = parsedData?.version || DEFAULT_INITIAL_STORAGE_VERSION;
	if (currentVersion === latestVersion) {
		return;
	}
	const migrationsScriptsEntriesInOrder = Object.entries(migrationScripts).sort((a, b) => Number(a[0].slice(1)) - Number(b[0].slice(1)));
	const migrationsScriptsEntriesIndex = migrationsScriptsEntriesInOrder.findIndex(([version]) => version === currentVersion);
	if (migrationsScriptsEntriesIndex === -1) {
		localStorage.removeItem(key);
		return;
	}
	const migrationsScriptsToRun = migrationsScriptsEntriesInOrder.slice(migrationsScriptsEntriesIndex).map(([, fn]) => fn);
	migrationsScriptsToRun.forEach((fn) => fn());
};
