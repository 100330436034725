import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './data-header-cell-filter-wrapper.module.scss';

interface DataHeaderCellFilterWrapperProps {
	children: ReactNode;
}

export const DataHeaderCellFilterWrapper: FC<DataHeaderCellFilterWrapperProps> = ({ children }) => {
	return (
		<div className={classNames(styles.dataHeaderCellFilterWrapper)} data-testid="column-filter">
			{children}
		</div>
	);
};
