import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { PageLogoViewStore } from './page-logo.view-store';

export const PageLogo = observer(() => {
	const franchiseLogoViewStore = useInstance(PageLogoViewStore);

	return (
		<>
			{franchiseLogoViewStore.imageSrc && franchiseLogoViewStore.isBlobURLValid && (
				<img src={franchiseLogoViewStore.imageSrc} height={24} alt="Samurai Logo" />
			)}
		</>
	);
});
