import { ReactNode, createContext, memo, useContext, useRef } from 'react';

import { QueryResultsTableViewStore } from '@/app/_common/_components/query-results/query-results-table/stores';

export const QueryResultsTableStoreContext = createContext<QueryResultsTableViewStore | null>(null);

interface QueryResultsTableStoreProviderProps {
	store: QueryResultsTableViewStore;
	children: ReactNode;
}

export const QueryResultsTableStoreProvider = memo(({ store, children }: QueryResultsTableStoreProviderProps) => {
	const storeRef = useRef(store);
	return <QueryResultsTableStoreContext.Provider value={storeRef.current}>{children}</QueryResultsTableStoreContext.Provider>;
});

QueryResultsTableStoreProvider.displayName = 'QueryResultsTableStoreProvider';

export const useQueryResultsTableStore = () => {
	const store = useContext(QueryResultsTableStoreContext);
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return store!;
};
