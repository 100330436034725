import { MouseEvent, KeyboardEvent } from 'react';

export function buttonize<T>(handlerFn: (event: MouseEvent<T> | KeyboardEvent<T>) => void | Promise<void>) {
	return {
		role: 'button',
		onClick: handlerFn,
		onKeyDown: (event: KeyboardEvent<T>) => {
			const key = event.key;

			if (key === 'Enter') {
				handlerFn(event);
			}
		},
	};
}
