import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { Namespaces } from '@/translations/namespaces';
import { MaximizeIcon, JsonSyntaxHighlighter } from '@/app/_common/_components';
import { ThemeStore } from '@/app/_common/stores';
import { RawFieldDialog } from './_components';

import styles from './raw-field.module.scss';

interface RawFieldProps {
	itemId: string;
	itemName?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value?: any;
}

export const RawField = observer(({ value, itemName, itemId }: RawFieldProps) => {
	const { t } = useTranslation(Namespaces.DetailsView, { keyPrefix: 'rawField' });
	const themeStore = useInstance(ThemeStore);

	const [isMaximized, setIsMaximized] = useState<boolean>(false);

	const handleMaximizeIconClick = () => {
		setIsMaximized(true);
	};

	const handleMinimizeDialogClick = () => {
		setIsMaximized(false);
	};

	if (!value) {
		return null;
	}

	return (
		<>
			{isMaximized && <RawFieldDialog title={itemName} onMinimize={handleMinimizeDialogClick} rawContent={value} />}
			<div className={styles.container}>
				<MaximizeIcon
					tooltipId={`RawFieldTooltip-${itemId}`}
					tooltipContent={t('maximizeIcon.tooltipContent')}
					onClick={handleMaximizeIconClick}
					className={styles.maximizeIcon}
				/>
				<div className={styles.syntaxHighlighterContainer}>
					<JsonSyntaxHighlighter value={value} isDarkTheme={themeStore.isDarkTheme} className={styles.syntaxHighlighter} />
				</div>
			</div>
		</>
	);
});
