import { MultiItemsCellContentProps } from '../types';
import { MitreAttackBadge } from '@/app/_common/_components/mitre-attack-badge';
import { MultiValueCellCounter } from '@/app/_common/_components/data-grid/_components/multi-value-cell/components/multi-value-cell-counter';
import { getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';

export const MitreTacticCellContent = ({ value }: MultiItemsCellContentProps) => {
	const highestPriorityValue = value[0];
	const mitreText = getMitreAttackBadgeText(String(highestPriorityValue));

	return (
		<>
			<MitreAttackBadge text={mitreText} />
			<MultiValueCellCounter count={`+${value.length - 1}`} />
		</>
	);
};
