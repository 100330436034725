import { makeAutoObservable } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { migrateToNewestVersion } from '@/app/_common/utils';

import { AvailableFieldsViewStore } from './available-fields.view-store';

type ClearMonacoEditorFn = null | (() => void);

const OPTIONS_VERSION = 'v3';
const OPTIONS_PERSISTABLE_KEY = 'ls/advanced-query/options';

const INITIAL_STATE: State = {
	isTipsShown: true,
	isAvailableFieldsShown: true,
	tipsPanelSize: '40%',
	availableFieldsPanelSize: '20%',
	graphPanelSize: '',
	resultsPanelSize: '50%',
	version: OPTIONS_VERSION,
};

interface State {
	isTipsShown: boolean;
	isAvailableFieldsShown: boolean;
	tipsPanelSize: string;
	availableFieldsPanelSize: string;
	graphPanelSize: string;
	resultsPanelSize: string;
	version?: string;
}

export class OptionsViewStore {
	private availableFieldsStore = injectInterface(this, AvailableFieldsViewStore);
	private clearMonacoEditorFn: ClearMonacoEditorFn = null;

	private state: State = INITIAL_STATE;

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		migrateToNewestVersion(OPTIONS_PERSISTABLE_KEY, OPTIONS_VERSION);

		makePersistable(this.state, {
			name: OPTIONS_PERSISTABLE_KEY,
			properties: [
				'isAvailableFieldsShown',
				'isTipsShown',
				'tipsPanelSize',
				'availableFieldsPanelSize',
				'graphPanelSize',
				'resultsPanelSize',
				'version',
			],
			storage: window.localStorage,
		});
	}

	setClearMonacoEditor = (clearFn: ClearMonacoEditorFn) => {
		this.clearMonacoEditorFn = clearFn;
	};

	clearMonacoEditor = () => {
		if (this.clearMonacoEditorFn) {
			this.clearMonacoEditorFn();
		}
	};

	clearFilters = () => {
		this.availableFieldsStore.removeAllFilters();
	};

	toggleIsTipsPanelShown = () => {
		this.state.isTipsShown = !this.state.isTipsShown;
	};

	get isTipsPanelShown(): boolean {
		return this.state.isTipsShown;
	}

	toggleIsAvailableFieldsPanelShown = () => {
		this.state.isAvailableFieldsShown = !this.state.isAvailableFieldsShown;
	};

	setIsAvailableFieldsPanelShown = (shown: boolean) => {
		this.state.isAvailableFieldsShown = shown;
	};

	get isAvailableFieldsPanelShown(): boolean {
		return this.state.isAvailableFieldsShown;
	}

	setTipsPanelSize = (size?: string) => {
		if (!size) {
			return;
		}

		this.state.tipsPanelSize = size;
	};

	get tipsPanelSize(): string {
		return this.state.tipsPanelSize;
	}

	setAvailableFieldsPanelSize = (size?: string) => {
		if (!size) {
			return;
		}

		this.state.availableFieldsPanelSize = size;
	};

	get availableFieldsPanelSize(): string {
		return this.state.availableFieldsPanelSize;
	}

	setGraphPanelSize = (size?: string) => {
		if (!size) {
			return;
		}

		this.state.graphPanelSize = size;
	};

	get graphPanelSize(): string {
		return this.state.graphPanelSize;
	}

	setResultsPanelSize = (size?: string) => {
		if (!size) {
			return;
		}

		this.state.resultsPanelSize = size;
	};

	get resultsPanelSize(): string {
		return this.state.resultsPanelSize;
	}

	dispose = () => {
		stopPersisting(this.state);
	};
}
