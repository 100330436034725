import { FC } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

import { Tooltip } from '@/app/_common/_components';
import { getAvatarInitials } from '@/app/_common/utils/get-avatar-initials/get-avatar-initials';
import { BaseCell } from '@/app/_common/_components/data-grid/_components';

import styles from './avatar-cell.module.scss';

interface AvatarCellProps extends GridCellProps {
	text: string;
	cellTitle?: string;
	defaultTitle?: string;
	getInitials?: (name: string) => string;
}

export const AvatarCell: FC<AvatarCellProps> = ({ text, cellTitle = '', defaultTitle = '', getInitials = getAvatarInitials, ...rest }) => {
	if (!text) {
		return null;
	}

	const displayValues = (
		<>
			<div data-tip="name" data-for={text} className={styles.avatar}>
				{getInitials(text)}
			</div>
			<Tooltip tooltipId={text} tooltipContent={text} />
		</>
	);

	return <BaseCell {...rest} hasValue={true} cellTitle={cellTitle} defaultTitle={defaultTitle} displayValues={displayValues} />;
};
