import { AriaRole } from 'react';

type TagName = Uppercase<keyof JSX.IntrinsicElements>;

const FORM_TAG_NAMES: TagName[] = [
	'LABEL',
	'INPUT',
	'TEXTAREA',
	'SELECT',
	'OPTION',
	'BUTTON',
	'FIELDSET',
	'LEGEND',
	'DATALIST',
	'OUTPUT',
	'OPTGROUP',
];
const FORM_ROLES: AriaRole[] = [
	'combobox',
	'button',
	'checkbox',
	'option',
	'radio',
	'radiogroup',
	'search',
	'searchbox',
	'switch',
	'textbox',
	'menuitemcheckbox',
	'menuitemradio',
];

export const isFormElement = (target: Element) => {
	return FORM_TAG_NAMES.includes(target.tagName as TagName) || FORM_ROLES.includes(target.getAttribute('role') ?? '');
};
