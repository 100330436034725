import { FC, MouseEvent, PropsWithChildren, useCallback } from 'react';
import classNames from 'classnames';

import { GridCellProps } from '@progress/kendo-react-grid';

import { ResultsTablePropertiesPaths } from '@/app/_common/constants';
import { ResultsFieldValue } from '@/app/_common/types';
import {
	QueryResultsTableItemExpandedDefault,
	QueryResultsTableItemExpandedMitre,
} from '@/app/_common/_components/query-results/query-results-table/components/query-results-table-item-expanded/components';
import {
	ItemComponentProps,
	ItemRendererMap,
} from '@/app/_common/_components/query-results/query-results-table/components/query-results-table-item-expanded/types';
import { AlertsDetailsSessionsPropertiesPaths } from '@/app/_common/constants/alert-details-sessions.constants';
import { sortArrayFieldValue } from '@/app/_common/_components/data-grid/utils';

import styles from './query-results-table-item-expanded-row.module.scss';

interface ResultItemDetailRowProps {
	dataItem: PropsWithChildren<GridCellProps>;
	label: string;
	value: ResultsFieldValue;
	onContextMenu?: (event: MouseEvent<HTMLElement>, field: string, dataItem: PropsWithChildren<GridCellProps>) => void;
}

const itemRenderers: ItemRendererMap = {
	default: QueryResultsTableItemExpandedDefault,
	[AlertsDetailsSessionsPropertiesPaths.MitreTactics]: QueryResultsTableItemExpandedMitre,
};

const itemSeparatorMap: Record<string, string> = {
	default: ', ',
	[AlertsDetailsSessionsPropertiesPaths.MitreTactics]: ' ',
	/* Add other custom separators here, e.g.: */
};

const renderItemComponentByFieldName = (props: ItemComponentProps, field = '') => {
	const { value } = props;
	const isArrayField = Array.isArray(value);
	const Component = itemRenderers[field] || itemRenderers['default'];
	if (isArrayField) {
		const separator = itemSeparatorMap[field] || itemSeparatorMap['default'];
		const sortedValues = sortArrayFieldValue<string | number>(value, field);
		return sortedValues.map((val: ResultsFieldValue, index: number) => {
			const isLastItem = index === sortedValues.length - 1;
			const key = `details-${field}-${index}`;
			return (
				<span key={key}>
					<Component {...props} value={val} />
					{!isLastItem && separator}
				</span>
			);
		});
	}
	return <Component {...props} />;
};

export const QueryResultsTableItemExpandedRow: FC<ResultItemDetailRowProps> = ({ label, value, dataItem, onContextMenu }) => {
	const isRawValue = [ResultsTablePropertiesPaths.Raw, ResultsTablePropertiesPaths.AlertRaw].includes(label as ResultsTablePropertiesPaths);

	const handleContextMenu = useCallback(
		(event: MouseEvent<HTMLElement>) => {
			onContextMenu?.(event, label, dataItem);
		},
		[label, dataItem, onContextMenu],
	);

	return (
		<p className={styles.detailRow} onContextMenu={handleContextMenu}>
			<span className={styles.label}>{label}</span>
			<span className={classNames(styles.value, { [styles.raw]: isRawValue })}>{renderItemComponentByFieldName({ value }, label)}</span>
		</p>
	);
};
