import { FC } from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as HeartPulseSvg } from '@/assets/images/heart-pulse-icon.svg';

interface HeartPulseIconProps {
	className?: string;
}

export const HeartPulseIcon: FC<HeartPulseIconProps> = ({ className = '' }) => (
	<SvgIcon style={{ scale: '0.85 ' }} className={className}>
		<HeartPulseSvg />
	</SvgIcon>
);
