import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';

import { SortDirection } from '@/app/_common/constants';
import { ActionHeadersMultiselectFilters, ActionHistoryDataGridPropertyPaths } from '@/app/_features/action-history/_common/constants';

import { ActionHistoryDataGridDataStore } from './action-history-data-grid.data-store';
import { ActionHistoryEdge, FieldSort, FieldValue, FiltersInput } from '@/generated/graphql';
import { FilterOption } from '@/app/_common/types';
import { ActionHistoryTimeRangeFilterStore } from './action-history-time-range-store';
import { ActionHistorySystemActionsSwitchStore } from './action-history-system-actions-switch-store';
import { DataGridWithAPIViewStore } from '@/app/_common/_components/data-grid/data-grid-with-api.view-store';
import { capitalizeFirstLetter, KendoServerSortAdapter } from '@/app/_common/utils';
import { ChipItem } from '@/app/_common/_components';
import { prepareMultiselectFilters } from '../utils';

const INITIAL_COLUMNS = {
	[ActionHistoryDataGridPropertyPaths.Date]: true,
	[ActionHistoryDataGridPropertyPaths.User]: true,
	[ActionHistoryDataGridPropertyPaths.Category]: true,
	[ActionHistoryDataGridPropertyPaths.Action]: true,
	[ActionHistoryDataGridPropertyPaths.CorrelationId]: true,
	[ActionHistoryDataGridPropertyPaths.Description]: true,
};

const COLUMNS_LOCAL_STORAGE_KEY = 'ls/maintenance/action-history/columns';
const INITIAL_SORT = [{ field: ActionHistoryDataGridPropertyPaths.Date, dir: SortDirection.Desc }];

const ACTION_HISTORY_TABLE_VERSION = 'v0';

interface State {
	multiselectSelectedValues: ChipItem[];
}

const INITIAL_STATE: State = { multiselectSelectedValues: ActionHeadersMultiselectFilters };

export class ActionHistoryDataGridViewStore extends DataGridWithAPIViewStore<ActionHistoryEdge> {
	private dataStore = injectInterface(this, ActionHistoryDataGridDataStore);
	private filtersStore = injectInterface(this, ActionHistoryTimeRangeFilterStore);
	private systemActionsSwitchStore = injectInterface(this, ActionHistorySystemActionsSwitchStore);
	private state: State = INITIAL_STATE;

	constructor() {
		super(ActionHistoryDataGridPropertyPaths.Date, INITIAL_COLUMNS, INITIAL_SORT, undefined, true, ACTION_HISTORY_TABLE_VERSION, true);

		makeObservable(this, {
			//@ts-ignore
			state: observable,
			isLoading: computed,
			actionHistoryRows: computed,
			hasNextPage: computed,
			queryFieldSorters: computed,
			readMore: action,
			read: action,
			clearFilters: action,
		});

		makePersistable(this.gridState, {
			name: COLUMNS_LOCAL_STORAGE_KEY,
			properties: ['columns'],
			storage: window.localStorage,
		});

		this.read();
	}

	get isLoading() {
		return this.dataStore.loading;
	}

	get queryFieldSorters() {
		return this.sort.map((sorter) => KendoServerSortAdapter.mapFieldSort(sorter)).filter((sorter): sorter is FieldSort => sorter !== null);
	}

	get actionHistoryRows() {
		return this.data;
	}

	get hasNextPage() {
		return Boolean(this.dataStore.hasNextPage);
	}

	get activeMultiselectFilters() {
		return this.state.multiselectSelectedValues;
	}

	setActiveMultiselectFilters = (newMultiselectSelectedValues: ChipItem[]) => {
		this.state.multiselectSelectedValues = newMultiselectSelectedValues;
	};

	private prepareFilters(): { filtersInput: FiltersInput; sortersInput: { fieldSorters: FieldSort[] } } {
		const multiselectFilters: FieldValue[] = prepareMultiselectFilters(this.activeMultiselectFilters);
		const systemActionsFilter = this.systemActionsSwitchStore.filter;

		const valueFilters: FieldValue[] = [...this.queryValueFilters, ...multiselectFilters, ...(systemActionsFilter ? [systemActionsFilter] : [])];

		const filtersInput: FiltersInput = {
			valueFilters,
		};

		const sortersInput = {
			fieldSorters: this.queryFieldSorters,
		};

		return {
			filtersInput,
			sortersInput,
		};
	}

	readMore = () => {
		const { filtersInput, sortersInput } = this.prepareFilters();
		this.dataStore.readMore(filtersInput, sortersInput);
	};

	read = () => {
		const { filtersInput, sortersInput } = this.prepareFilters();
		this.dataStore.read(filtersInput, sortersInput);
	};

	getFilterOptions = (field: string) => {
		const counters = this.getCountedValues(field);
		const shouldCapitalizeLabel = field === ActionHistoryDataGridPropertyPaths.Action || field === ActionHistoryDataGridPropertyPaths.Category;

		const options: FilterOption[] = [
			...this.getUniqValues(field).map((value) => ({
				value: value as string,
				label: shouldCapitalizeLabel ? capitalizeFirstLetter(value as string) : (value as string),
				counter: counters[value as string],
			})),
		];

		return options;
	};

	clearFilters = (): void => {
		if (this.areFiltersActive) {
			this.resetAllFilters();
			this.setQueryValueFilters([]);
		}
	};

	actionRowsDisposer = reaction(
		() => this.dataStore.actions?.edges,
		(actions) => {
			this.sourceData = actions;
		},
	);

	private refreshGridData() {
		this.resetPage();
		this.read();
	}

	queryFiltersChangeDisposer = reaction(
		() => this.queryValueFilters,
		() => this.refreshGridData(),
	);

	fieldSortersChangeDisposer = reaction(
		() => this.queryFieldSorters,
		() => this.refreshGridData(),
	);

	timeRangeFiltersDisposer = reaction(
		() => this.filtersStore.filters,
		() => this.refreshGridData(),
	);

	systemActionsSwitchFilterDisposer = reaction(
		() => this.systemActionsSwitchStore.filter,
		() => this.refreshGridData(),
	);

	multiselectSelectedValuesDisposer = reaction(
		() => this.activeMultiselectFilters,
		() => this.refreshGridData(),
	);

	dispose = () => {
		this.actionRowsDisposer();
		this.queryFiltersChangeDisposer();
		this.timeRangeFiltersDisposer();
		this.fieldSortersChangeDisposer();
		this.systemActionsSwitchFilterDisposer();
		this.multiselectSelectedValuesDisposer();
	};
}
