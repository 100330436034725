import { Evidence } from '@/app/_common/types';

import { AlertEvidence } from '../_components';

interface SeciossSeciosLinkAlertEvidenceProps {
	evidence: Evidence;
}

export const SeciossSeciosLinkAlertEvidence = ({ evidence }: SeciossSeciosLinkAlertEvidenceProps) => {
	return <AlertEvidence evidence={evidence} />;
};
