import React from 'react';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import classNames from 'classnames';
import { InputChangeEvent } from '@progress/kendo-react-inputs';

import { Namespaces } from '@/translations/namespaces';
import { Divider, FieldSearch } from '@/app/_common/_components';
import { AvailableFieldsViewStore } from '@/app/advanced-query/_common/stores';
import { AvailableFieldsEmpty } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/available-fields/_components';
import {
	AvailableFieldsBlock,
	AvailableFieldsContainer,
} from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components';
import { AVAILABLE_FIELDS_BLOCK_NAME, AVAILABLE_FIELDS_EXPANDED_STATE_KEY } from '@/app/_common/types';

import styles from './available-fields.module.scss';

const STICKY_CLASS_NAME_PREFIX = 'bottom';

export const AvailableFields = observer(() => {
	const availableFieldsViewStore = useInstance(AvailableFieldsViewStore);
	const { fieldsExpandedState, toggleFieldExpandedState } = availableFieldsViewStore;

	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: 'tabs.advancedQuery' });

	const handleSearchChange = (event: InputChangeEvent) => {
		availableFieldsViewStore.setFieldsSearch(event.value);
	};

	const getExpandCollapseHandler = (stateKey: AVAILABLE_FIELDS_EXPANDED_STATE_KEY) => () => toggleFieldExpandedState(stateKey);

	const {
		fieldsSearch,
		eventsOtherFieldsLoading,
		eventsFavoriteFieldsLoading,
		eventsFavoriteFieldsError,
		eventsOtherFieldsError,
		eventsEmpty,
		eventsFavoriteFields,
		eventsOtherFields,
		alertsOtherFieldsLoading,
		alertsFavoriteFieldsLoading,
		alertsFavoriteFieldsError,
		alertsOtherFieldsError,
		alertsEmpty,
		alertsFavoriteFields,
		alertsOtherFields,
		evidenceFavoriteFieldsLoading,
		evidenceFavoriteFieldsError,
		evidenceFavoriteFields,
		evidenceOtherFieldsLoading,
		evidenceOtherFieldsError,
		evidenceOtherFields,
		evidenceEmpty,
	} = availableFieldsViewStore;

	const allEventsFailed = Boolean(eventsFavoriteFieldsError && eventsOtherFieldsError);
	const anyEventsFailed = Boolean(eventsFavoriteFieldsError || eventsOtherFieldsError);
	const anyEventsLoading = eventsOtherFieldsLoading || eventsFavoriteFieldsLoading;
	const isEventsEmpty = eventsEmpty && !anyEventsLoading && !anyEventsFailed;

	const allAlertsFailed = Boolean(alertsFavoriteFieldsError && alertsOtherFieldsError);
	const anyAlertsFailed = Boolean(alertsFavoriteFieldsError || alertsOtherFieldsError);
	const anyAlertsLoading = alertsOtherFieldsLoading || alertsFavoriteFieldsLoading;
	const isAlertsEmpty = alertsEmpty && !anyAlertsLoading && !anyAlertsFailed;

	const allEvidenceFailed = Boolean(evidenceFavoriteFieldsError && evidenceOtherFieldsError);
	const anyEvidenceFailed = Boolean(evidenceFavoriteFieldsError || evidenceOtherFieldsError);
	const anyEvidenceLoading = evidenceFavoriteFieldsLoading || evidenceOtherFieldsLoading;
	const isEvidenceEmpty = evidenceEmpty && !anyEvidenceLoading && !anyEvidenceFailed;

	const allFailed = allEventsFailed && allAlertsFailed && allEvidenceFailed;
	const anyFailed = anyEventsFailed && anyAlertsFailed && anyEvidenceFailed;
	const isAllEmpty = isEventsEmpty && isAlertsEmpty && isEvidenceEmpty;

	if (isAllEmpty && !anyFailed) {
		return (
			<div className={classNames(styles.availableFields, styles.empty)} data-testid="available-fields">
				<AvailableFieldsEmpty />
			</div>
		);
	}

	return (
		<div className={classNames(styles.availableFields, { [styles.error]: allFailed })} data-testid="available-fields">
			{!allFailed && (
				<>
					<div className={styles.searchContainer}>
						<FieldSearch
							id="availableFieldsSearch"
							className={styles.search}
							value={fieldsSearch}
							onChange={handleSearchChange}
							showLabel={false}
							placeholder={t('availableFields.search.placeholder')}
						/>
					</div>
					<Divider className={styles.divider} />
				</>
			)}
			<AvailableFieldsContainer>
				{!isAlertsEmpty && (
					<AvailableFieldsBlock
						blockName={AVAILABLE_FIELDS_BLOCK_NAME.ALERTS}
						favoriteFields={alertsFavoriteFields}
						otherFields={alertsOtherFields}
						favoriteFieldsLoading={alertsFavoriteFieldsLoading}
						otherFieldsLoading={alertsOtherFieldsLoading}
						favoriteFieldsError={alertsFavoriteFieldsError}
						otherFieldsError={alertsOtherFieldsError}
						isHeaderExpanded={fieldsExpandedState[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.ALERTS_HEADER]}
						isOtherFieldsExpanded={fieldsExpandedState[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.ALERTS_OTHER_FIELDS]}
						toggleHeaderExpandedState={getExpandCollapseHandler(AVAILABLE_FIELDS_EXPANDED_STATE_KEY.ALERTS_HEADER)}
						toggleOtherFieldsExpandedState={getExpandCollapseHandler(AVAILABLE_FIELDS_EXPANDED_STATE_KEY.ALERTS_OTHER_FIELDS)}
					/>
				)}

				{!isEvidenceEmpty && (
					<AvailableFieldsBlock
						blockName={AVAILABLE_FIELDS_BLOCK_NAME.EVIDENCE}
						favoriteFields={evidenceFavoriteFields}
						otherFields={evidenceOtherFields}
						favoriteFieldsLoading={evidenceFavoriteFieldsLoading}
						otherFieldsLoading={evidenceOtherFieldsLoading}
						favoriteFieldsError={evidenceFavoriteFieldsError}
						otherFieldsError={evidenceOtherFieldsError}
						isHeaderExpanded={fieldsExpandedState[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVIDENCE_HEADER]}
						isOtherFieldsExpanded={fieldsExpandedState[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVIDENCE_OTHER_FIELDS]}
						toggleHeaderExpandedState={getExpandCollapseHandler(AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVIDENCE_HEADER)}
						toggleOtherFieldsExpandedState={getExpandCollapseHandler(AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVIDENCE_OTHER_FIELDS)}
						stickyClassName={`${STICKY_CLASS_NAME_PREFIX}${isEventsEmpty ? 0 : 1}`}
					/>
				)}

				{!isEventsEmpty && (
					<AvailableFieldsBlock
						blockName={AVAILABLE_FIELDS_BLOCK_NAME.EVENTS}
						favoriteFields={eventsFavoriteFields}
						otherFields={eventsOtherFields}
						favoriteFieldsLoading={eventsFavoriteFieldsLoading}
						otherFieldsLoading={eventsOtherFieldsLoading}
						favoriteFieldsError={eventsFavoriteFieldsError}
						otherFieldsError={eventsOtherFieldsError}
						isHeaderExpanded={fieldsExpandedState[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVENTS_HEADER]}
						isOtherFieldsExpanded={fieldsExpandedState[AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVENTS_OTHER_FIELDS]}
						toggleHeaderExpandedState={getExpandCollapseHandler(AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVENTS_HEADER)}
						toggleOtherFieldsExpandedState={getExpandCollapseHandler(AVAILABLE_FIELDS_EXPANDED_STATE_KEY.EVENTS_OTHER_FIELDS)}
						stickyClassName={`${STICKY_CLASS_NAME_PREFIX}0`}
					/>
				)}
			</AvailableFieldsContainer>
		</div>
	);
});
