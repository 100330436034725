import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import { Switch } from '@/app/_common/_components';
import { AdvancedQueryPageKeyPrefix } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';

import styles from './stacked-bar-chart-switch.module.scss';
import panelBarStyles from '../chart-settings.module.scss';

interface StackedBarChartSwitchProps {
	onChange: () => void;
	checked: boolean;
}

export const StackedBarChartSwitch: FC<StackedBarChartSwitchProps> = ({ checked, onChange }) => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });

	return (
		<PanelBar className={panelBarStyles.popupContent} onSelect={onChange}>
			<PanelBarItem
				title={
					<div className={styles.switchFlexWrapper}>
						<p>{t('chart.actionBlock.stackedBars')}</p>
						<Switch checked={checked} className={styles.switch} />
					</div>
				}
			/>
		</PanelBar>
	);
};
