import { Step } from '@progress/kendo-react-layout';
import { ComponentType } from 'react';
import { Check } from '@mui/icons-material';

import styles from './wizard-step.module.scss';
import classNames from 'classnames';
import { WizardStepProps } from '@/app/_common/_components/wizard/_common';

export const WizardStep: ComponentType<WizardStepProps> = ({
	title,
	index = 0,
	completed,
	description,
	touched,
	stepUndefined,
	showStepDescriptionIfTouched,
}) => {
	const getStepValue = () => {
		if (stepUndefined) {
			return undefined;
		}

		return completed ? <Check /> : index + 1;
	};

	return (
		<Step>
			<div className={styles.container}>
				<div className={styles.titleContainer}>
					<div className={classNames(styles.circle, { [styles.untouched]: !touched })}>{getStepValue()}</div>
					<div>{title}</div>
				</div>
				{((!showStepDescriptionIfTouched && completed) || (showStepDescriptionIfTouched && touched)) && description && (
					<div className={styles.description}>{description}</div>
				)}
			</div>
		</Step>
	);
};
