import { FC, MouseEvent, useCallback } from 'react';

import { Editable, ReactEditor, RenderElementProps, RenderLeafProps } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import { ListsEditor } from '@prezly/slate-lists';

import classNames from 'classnames';

import { useExternalLink } from '@/app/_common/hooks';
import { Tooltip } from '@/app/_common/_components';

import { Leaf, Element, ExternalLinkDialog, OpenInModalButton } from '@/app/_common/_components/rich-text-editor/_components';
import { keyDownAction, clipboardPasteAction } from '@/app/_common/_components/rich-text-editor/_common/utils';
import { RichTextEditorTranslations } from '@/app/_common/_components/rich-text-editor/types';

import styles from './rich-text-content.module.scss';

const TOOLTIP_ID = 'maximizeIconTooltip';

interface RichTextContentProps {
	translations: RichTextEditorTranslations;
	className?: string;
	maxHeight: number | 'none';
	handleSetFocused: () => void;
	editor: ReactEditor & HistoryEditor & ListsEditor;
	isRenderingEditorContent: boolean;
	isEditModeActive: boolean;
	isReadOnlyEnabled: boolean;
	collapsed?: boolean;
	onMaximize?: () => void;
	createMode?: boolean;
}

export const RichTextContent: FC<RichTextContentProps> = ({
	translations,
	maxHeight,
	handleSetFocused,
	editor,
	className,
	isReadOnlyEnabled,
	collapsed = false,
	onMaximize,
	createMode = false,
}) => {
	const renderElement = useCallback(
		(props: RenderElementProps) => <Element {...props} isReadOnlyEnabled={isReadOnlyEnabled} translations={translations} />,
		[isReadOnlyEnabled, translations],
	);
	const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);

	const { externalLink, updateExternalLink } = useExternalLink();

	const handleClick = (event: MouseEvent) => {
		updateExternalLink(event);

		handleSetFocused();
	};

	const closeExternalLinkDialog = () => {
		updateExternalLink();
	};

	const showMaximizeIcon = createMode && onMaximize;

	return (
		<>
			{showMaximizeIcon && (
				<>
					<span data-tip={TOOLTIP_ID} data-for={TOOLTIP_ID}>
						<OpenInModalButton onClick={onMaximize} className={styles.maximizeIcon} />
					</span>
					<Tooltip tooltipId={TOOLTIP_ID} tooltipContent={translations.maximizeIcon} />
				</>
			)}
			<div style={{ maxHeight }} className={classNames(styles.content, className, { [styles.collapsed]: collapsed })}>
				<Editable
					data-testid="rich-text-editor-textbox"
					style={{ maxHeight }}
					className={styles.editable}
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					placeholder={translations.placeholder}
					spellCheck
					readOnly={isReadOnlyEnabled}
					onClick={handleClick}
					onKeyDown={(e) => keyDownAction(e, editor)}
					onKeyUpCapture={(e) => e.stopPropagation()}
					onPaste={(e) => clipboardPasteAction(e, editor)}
				/>
			</div>
			{externalLink && <ExternalLinkDialog link={externalLink} handleCloseDialog={closeExternalLinkDialog} translations={translations} />}
		</>
	);
};
