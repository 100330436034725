export enum ExplanationItems {
	Enrichment = 'enrichment',
	Suspicious = 'suspicious',
	Threat = 'threat',
	BoostAlert = 'boostAlert',
}

export interface ExplanationItem {
	title: string;
	icon: string;
	silenced?: string;
	boostScore?: string;
	description?: string;
}

export interface ExplanationItemWithId extends ExplanationItem {
	id: ExplanationItems;
}
