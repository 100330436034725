import { ARBITRARY_STRING, QUERY_FUNCTION, QUERY_LITERAL, QUERY_PUNCTUATION, WHITE_SPACES, DATETIME_FILTER_OPERATOR } from '@/app/_common/constants';

const DATETIME = `${QUERY_FUNCTION.DATETIME}\\${QUERY_PUNCTUATION.ROUND_BRACKET_OPEN}${QUERY_LITERAL.STRING_OPEN}?${ARBITRARY_STRING}${QUERY_LITERAL.STRING_CLOSE}?\\${QUERY_PUNCTUATION.ROUND_BRACKET_CLOSE}`;

// checks case: where <COLUMNNAME> between (datetime(...) .. datetime(...))
export const containsTimeRangeInstruction = (query: string, dateTimeColumnNames: string[]): boolean => {
	for (const columnName of dateTimeColumnNames) {
		const timeRangeRegex = new RegExp(
			`${WHITE_SPACES}${DATETIME_FILTER_OPERATOR}${WHITE_SPACES}${columnName}${WHITE_SPACES}${QUERY_FUNCTION.BETWEEN}${WHITE_SPACES}\\${QUERY_PUNCTUATION.ROUND_BRACKET_OPEN}${WHITE_SPACES}${DATETIME}${WHITE_SPACES}${QUERY_PUNCTUATION.RANGE}${WHITE_SPACES}${DATETIME}${WHITE_SPACES}\\${QUERY_PUNCTUATION.ROUND_BRACKET_CLOSE}`,
			'g',
		);

		if (timeRangeRegex.test(query)) {
			return true;
		}
	}

	return false;
};
