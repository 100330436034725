import { QUERY_OPERATOR } from '@/app/_common/constants';

export const getLimit = (query: string, defaultLimit: number | null): string => {
	const limitRegex = /limit\s+(\d+)/i;
	const limitMatches = query.match(limitRegex);

	if (!limitMatches && !defaultLimit) {
		return '';
	}
	let queryLimit = limitMatches && parseInt(limitMatches[1]);
	if (defaultLimit && queryLimit && queryLimit > defaultLimit) {
		queryLimit = defaultLimit;
	}

	return `${QUERY_OPERATOR.LIMIT} ${queryLimit || defaultLimit}`;
};
