/* eslint-disable */
import { action, computed, makeObservable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';
import { makePersistable, stopPersisting } from 'mobx-persist-store';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { Investigation, InvestigationAlertSummary } from '@/generated/graphql';
import { InvestigationDetailsDataStore } from './investigation-details.data-store';
import { AlertsHeaderFilter } from '@/app/dashboards/alerts-dashboard/_common/constants/alerts.constant';
import {
	FilterDescriptorWithId,
	FilterOption,
	Filters,
	FilterValue,
} from '@/app/_common/types/types';
import { AssignedAlertPropertiesPaths, FilterOperators, FilterGroupId, FilterLogic, SortDirection } from '@/app/_common/constants';
import { sortAssignedAlerts } from '@/app/investigation-details/_common/utils/sort-assigned-alerts';
import { getFormattedDate, getNullishFilterOption } from '@/app/_common/utils';
import { SwitchTenantStore } from '@/app/_common/stores/switch-tenant.store';
import { getQualifiedAlertNodes } from '../utils/get-qualified-alert-nodes/get-qualified-alert-nodes';
import { AlertsUtilsViewStore } from '@/app/_common/stores/alerts-utils.view-store';
import { mapFilterOption, getSeverityOption, getConfidenceOption, getStateOption } from '@/app/_common/_components/data-grid/utils';

const INITIAL_COLUMNS = {
	[AssignedAlertPropertiesPaths.Timestamp]: true,
	[AssignedAlertPropertiesPaths.Severity]: true,
	[AssignedAlertPropertiesPaths.Confidence]: true,
	[AssignedAlertPropertiesPaths.Title]: true,
	[AssignedAlertPropertiesPaths.Source]: true,
	[AssignedAlertPropertiesPaths.Destination]: true,
	[AssignedAlertPropertiesPaths.Mitre]: true,
	[AssignedAlertPropertiesPaths.DetectedBy]: true,
};

const EMPTY_FIELD_VALUE = 'empty';
interface State {
	activeEqualFilters: Record<string, Filters>;
}

const INITIAL_STATE: State = {
	activeEqualFilters: {},
};

const INITIAL_SORT = [{ field: AssignedAlertPropertiesPaths.Timestamp, dir: SortDirection.Desc }];

export class AssignedAlertsListViewStore extends AlertsUtilsViewStore<InvestigationAlertSummary> {
	private investigationDetailsDataStore = injectInterface(this, InvestigationDetailsDataStore);
	private switchTenantStore = new SwitchTenantStore('AssignedAlertsListViewStore');
	private state = INITIAL_STATE;

	constructor() {
		super(AssignedAlertPropertiesPaths.Id, INITIAL_COLUMNS, INITIAL_SORT, sortAssignedAlerts);
		makeObservable(this, {
			allAlerts: computed,
			assignedAlerts: computed,
			selectedAlerts: computed,
			alertsCount: computed,
			loading: computed,
			refresh: action,
			setGridHeaderFilter: action,
			resetGridHeaderFilter: action,
		});

		this.switchTenantStore.init(this.gridState, {
			name: 'ss/investigations/detail/alerts',
			properties: ['sort', 'filter', 'selected'],
			storage: window.sessionStorage,
		});

		makePersistable(this.gridState, {
			name: 'ls/investigations/detail/alerts',
			properties: ['columns'],
			storage: window.localStorage,
		});
	}

	get allAlerts() {
		return this.rawData;
	}

	get assignedAlerts() {
		return this.data;
	}

	get selectedAlerts() {
		return this.allAlerts?.filter((item: InvestigationAlertSummary) => {
			return this.isRowSelected(item[AssignedAlertPropertiesPaths.Id]);
		});
	}

	get alertsCount() {
		return this.totalCount;
	}

	get selectedAlertIds(): string[] {
		return this.selectedElements.map(({ id }) => id);
	}

	get activeEqualFilters() {
		return this.state.activeEqualFilters;
	}

	get selectedAlertsCount(): number {
		return this.selectedAlertIds.length;
	}

	get firstSeenAlertTimestamp() {
		if (this.assignedAlerts.length === 0) return '';

		const oldestAlert = this.assignedAlerts?.reduce((acc, curr) => {
			return Date.parse(acc.timestamp) > Date.parse(curr.timestamp) ? curr : acc;
		});

		return getFormattedDate(oldestAlert.timestamp);
	}

	get lastSeenAlertTimestamp() {
		if (this.assignedAlerts.length === 0) return '';

		const latestAlert = this.assignedAlerts?.reduce((acc, curr) => {
			return Date.parse(acc.timestamp) < Date.parse(curr.timestamp) ? curr : acc;
		});

		return getFormattedDate(latestAlert.timestamp);
	}

	get loading(): boolean {
		return this.investigationDetailsDataStore.loading;
	}

	get headerFilterValues() {
		const filter = this.getFilter(FilterGroupId.header);

		if (!filter || !filter.filters) {
			return [];
		}

		return filter.filters.reduce<AlertsHeaderFilter[]>((result, filter) => {
			if ('id' in filter && filter.id) {
				result.push(filter.id as AlertsHeaderFilter);
			}

			return result;
		}, []);
	}

	setActiveFilter = (field: string, data: FilterDescriptorWithId[]) => {
		const filters = data.map((item) => item?.value);

		this.state.activeEqualFilters = {
			...this.state.activeEqualFilters,
			[field]: filters,
		};
	};

	getAlertById = computedFn((id?: string) => {
		if (!id) {
			return undefined;
		}

		return this.allAlerts.find((alert) => alert.id === id);
	});

	getFilterOptions = computedFn((field: string, isNull?: boolean, emptyFieldName?: string) => {
		const counters = this.getCountedValues(field);
		let options: FilterOption[] = [];

		options = this.getUniqValues(field).map((value) => {
			return mapFilterOption(
				field,
				{
					value: value as FilterValue,
					counter: counters[value as string],
				},
				{
					[AssignedAlertPropertiesPaths.Severity]: getSeverityOption,
					[AssignedAlertPropertiesPaths.Confidence]: getConfidenceOption,
					[AssignedAlertPropertiesPaths.State]: getStateOption,
				},
			);
		});

		if (isNull && emptyFieldName) {
			options.unshift(getNullishFilterOption(emptyFieldName, EMPTY_FIELD_VALUE, counters[EMPTY_FIELD_VALUE]));
		}

		return options;
	});

	setGridHeaderFilter = (field: string, values: Filters) => {
		const filters = values.map((value) => {
			// eslint-disable-next-line @typescript-eslint/ban-types
			let operator: string | Function = FilterOperators.Eq;

			if (field === AssignedAlertPropertiesPaths.Source || field === AssignedAlertPropertiesPaths.Destination) {
				operator = FilterOperators.IsSomeIn;
			}

			return { value, field, operator, ignoreCase: false };
		});

		this.setActiveFilter(field, filters);

		this.setFilter({
			id: FilterGroupId.gridHeader,
			filters,
			logic: FilterLogic.And,
			nestedId: field,
			nestedLogic: FilterLogic.Or,
		});
	};

	resetGridHeaderFilter = (field: string) => {
		this.state.activeEqualFilters = { ...this.state.activeEqualFilters, [field]: [] };
		this.resetFilter(FilterGroupId.gridHeader, field);
	};

	refresh = () => {
		this.investigationDetailsDataStore.read();
	};

	alertsDisposer = reaction(
		() => (this.investigationDetailsDataStore.data?.getInvestigation as Investigation)?.alerts,
		(alerts) => {
			if (alerts) {
				this.sourceData = getQualifiedAlertNodes(alerts);
			}
		},
	);

	dispose = () => {
		stopPersisting(this.gridState);
		this.alertsDisposer();
	};
}
