import { FC } from 'react';
import { LoadingSpinner } from '../loading-spinner/loading-spinner';

import styles from './loading-page-layout.module.scss';

export const LoadingPageLayout: FC = () => {
	return (
		<div className={styles.overlay}>
			<LoadingSpinner />
		</div>
	);
};
