/* istanbul ignore file */

import { loader, Monaco } from '@monaco-editor/react';

declare global {
	interface Window {
		monaco?: Monaco;
	}
}

/**
 * when monaco is loaded, it also loads a UMD module loader. the following script tag will load monaco kusto using that loader.
 */
const loadMonacoKusto = () =>
	new Promise((resolve) => {
		const script = document.createElement('script');
		script.innerHTML = `require(['vs/language/kusto/monaco.contribution'], function() {
    	document.dispatchEvent(new Event('kusto_init'));
  	});`;
		document.body.appendChild(script);
		document.addEventListener('kusto_init', function monacoKustoInit() {
			document.removeEventListener('kust_init', monacoKustoInit);
			resolve(window.monaco as Monaco);
		});
	});

/**
 * Configuring monaco's UMD loader to load all the rest of monaco (and monaco-kusto) from the following location (that's why we're copying everything to public folder).
 */
loader.config({
	paths: {
		vs: `${process.env.PUBLIC_URL}/monaco-editor/min/vs`,
	},
});

const initMonacoWithKusto = async () => {
	await loader.init();
	await loadMonacoKusto();
	return window.monaco as Monaco;
};

let monacoLoadPromise: Promise<Monaco> | null = null;

/**
 * import monaco and monaco-kusto.
 * @returns a promise that will be resolved once all dependencies are loaded.
 */
export const loadMonaco = () => {
	if (monacoLoadPromise === null) {
		monacoLoadPromise = initMonacoWithKusto();
	}
	return monacoLoadPromise;
};
