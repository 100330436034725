import { FC } from 'react';

import classNames from 'classnames';

import { Skeleton } from '@progress/kendo-react-indicators';

import styles from './fields-list.module.scss';

export const SkeletonListItem: FC = () => {
	return (
		<li className={classNames(styles.listItem, styles.loadingListItem)}>
			<Skeleton
				shape={'text'}
				style={{
					width: '100%',
				}}
			/>
		</li>
	);
};
