import { DetailsViewItem, DetailsViewItemData } from '@/app/_common/_components/details-view/types';
import { Severity } from '@/generated/graphql';
import { ResultsTablePropertiesPaths } from '@/app/_common/constants';
import { getDetailsViewItemTitle } from '@/app/_common/_components/details-view/utils/get-details-view-item-title';
import { DetailsViewItemPropertiesPaths } from '@/app/_common/_components/details-view/types/details-view-item-properties-paths';

const initialItemData: DetailsViewItemData = {
	id: '',
	event_source: '',
	severity: Severity.Unknown,
	state: undefined,
	timestamp: '',
	title: '-',
	data_objects: undefined,
	device_id: undefined,
	event_details: undefined,
	externalReferenceUrl: undefined,
	investigationSummary: undefined,
	last_updated: '',
	recommendation: undefined,
	summary: undefined,
	tenant_id: '',
	addedTimestamp: '',
	confidence: undefined,
	dst: undefined,
	mitre_category: undefined,
	src: undefined,
	type: undefined,
};

const TITLE_FIELD = DetailsViewItemPropertiesPaths.Title;
const ID_FIELD = DetailsViewItemPropertiesPaths.Id;
const DB_ID_FIELD = ResultsTablePropertiesPaths.Id;

export const getDetailsViewItemData = (item: DetailsViewItem): DetailsViewItemData | undefined => {
	if (!item) {
		return undefined;
	}

	try {
		const mergedData = {
			...initialItemData,
			...item,
		};

		// We have to dynamically calculate below fields
		mergedData[TITLE_FIELD] = getDetailsViewItemTitle(item);

		if (DB_ID_FIELD in item) {
			mergedData[ID_FIELD] = item[DB_ID_FIELD];
		}

		return mergedData;
	} catch (error) {
		return undefined;
	}
};
