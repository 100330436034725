import { useCallback, useRef } from 'react';
import { useInstance } from 'react-ioc';
import { Reference, useSubscription } from '@apollo/client';
import _debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';

import { CoreIntegrationFragment, GraphqlClient } from '@/app/_common/graphql';
import { AuthStore } from '@/app/_common/stores';
import {
	Integration,
	IntegrationsConnection,
	IntegrationType,
	Subscription,
	SubscriptionIntegrationCreatedArgs,
	SubscriptionIntegrationUpdatedArgs,
} from '@/generated/graphql';
import { IntegrationTypename } from '@/app/_common/constants';
import { IntegrationCreatedSubscription, IntegrationUpdatedSubscription } from '@/app/_common/graphql/queries';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

const INTEGRATION_SUBSCRIPTION_DEBOUNCE_TIME = 1000;

interface IntegrationEdgeRef {
	node?: Reference;
	__typename: IntegrationTypename;
	cursor: string;
}

export const listIntegrationsUpdater = (
	data: Integration[],
	client: GraphqlClient,
	existingIntegrationsRefs: IntegrationsConnection,
	readField: ReadFieldFunction,
) => {
	const newEdges = data.reduce((result: IntegrationEdgeRef[], integration: Integration) => {
		const node = client.cache.writeFragment({
			fragment: CoreIntegrationFragment,
			data: integration,
		});
		const exists = existingIntegrationsRefs?.edges?.some(
			(ref) => integration.__typename === IntegrationTypename.Integration && readField('id', ref.node) === integration.id,
		);

		if (!node || exists) {
			return result;
		}

		const newEdge = {
			node,
			cursor: uuidv4(),
			__typename: IntegrationTypename.IntegrationEdge,
		};

		return [...result, newEdge];
	}, []);

	return {
		...(existingIntegrationsRefs || {}),
		edges: [...(existingIntegrationsRefs.edges || []), ...newEdges],
	};
};

export const mergeCreateIntegrationRequestedWithCache = _debounce((client: GraphqlClient, data: Integration[], callback: () => void) => {
	if (data.length > 0) {
		client.cache.modify({
			fields: {
				listIntegrations(existingIntegrationsRefs: IntegrationsConnection, { readField }) {
					return listIntegrationsUpdater(data, client, existingIntegrationsRefs, readField);
				},
			},
		});
	}

	callback();
}, INTEGRATION_SUBSCRIPTION_DEBOUNCE_TIME);

export const mergeUpdateCollectorIntegrationSubscription = _debounce((client: GraphqlClient, data: Integration[], callback: () => void) => {
	if (data.length > 0) {
		data.forEach((integration) => {
			const identifier = client.cache.identify(integration);

			client.cache.modify({
				id: identifier,
				fields: {
					name() {
						return integration.name;
					},
					id() {
						return integration.id;
					},
					tenantId() {
						return integration.tenantId;
					},
					description() {
						return integration.description;
					},
					collector() {
						return integration.collector;
					},
					components() {
						return integration.components;
					},
					last_updated() {
						return integration.last_updated;
					},
					statusReason() {
						return integration.statusReason;
					},
					status() {
						return integration.status;
					},
					timestamp() {
						return integration.timestamp;
					},
					completedTimestamp() {
						return integration.completedTimestamp;
					},
					lastEventTimestamp() {
						return integration.lastEventTimestamp;
					},
					type() {
						return integration.type;
					},
					sourceType() {
						return integration.sourceType;
					},
				},
			});
		});
	}
	callback();
}, INTEGRATION_SUBSCRIPTION_DEBOUNCE_TIME);

export const mergeUpdateResponseIntegrationSubscription = _debounce((client: GraphqlClient, data: Integration[], callback: () => void) => {
	if (data.length > 0) {
		data.forEach((integration) => {
			const identifier = client.cache.identify(integration);

			client.cache.modify({
				id: identifier,
				fields: {
					id() {
						return integration.id;
					},
					statusReason() {
						return integration.statusReason;
					},
					status() {
						return integration.status;
					},
					vendor() {
						return integration.vendor;
					},
					productName() {
						return integration.productName;
					},
					name() {
						return integration.name;
					},
					description() {
						return integration.description;
					},
					type() {
						return integration.type;
					},
					sourceType() {
						return integration.sourceType;
					},
				},
			});
		});
	}
	callback();
}, INTEGRATION_SUBSCRIPTION_DEBOUNCE_TIME);

const useCreateIntegrationSubscription = (filterType?: IntegrationType) => {
	const authStore = useInstance(AuthStore);
	const graphqlClient = useInstance(GraphqlClient);

	const createSubscriptionBuffer = useRef<Subscription['integrationCreated'][]>([]);

	const pushCreateToBuffer = useCallback((value?: Subscription['integrationCreated']) => {
		if (Array.isArray(createSubscriptionBuffer?.current) && value) {
			createSubscriptionBuffer.current.push(value);
		}
	}, []);

	const clearCreateBuffer = useCallback(() => {
		createSubscriptionBuffer.current = [];
	}, []);

	useSubscription<Subscription, SubscriptionIntegrationCreatedArgs>(IntegrationCreatedSubscription, {
		client: graphqlClient,
		variables: {
			tenantId: authStore.currentTenantId,
		},
		onSubscriptionData: ({ subscriptionData }) => {
			const integrationCreated = subscriptionData?.data?.integrationCreated;

			if (filterType && integrationCreated?.type !== filterType) {
				return;
			}

			pushCreateToBuffer(integrationCreated);
			mergeCreateIntegrationRequestedWithCache(graphqlClient, createSubscriptionBuffer.current, clearCreateBuffer);
		},
	});
};

const useUpdateIntegrationSubscription = (
	mergeUpdateIntegrationSubscription: typeof mergeUpdateCollectorIntegrationSubscription | typeof mergeUpdateResponseIntegrationSubscription,
	filterType?: IntegrationType,
) => {
	const authStore = useInstance(AuthStore);
	const graphqlClient = useInstance(GraphqlClient);

	const updateSubscriptionBuffer = useRef<Subscription['integrationUpdated'][]>([]);

	const pushUpdateToBuffer = useCallback((value?: Subscription['integrationUpdated']) => {
		if (Array.isArray(updateSubscriptionBuffer?.current) && value) {
			updateSubscriptionBuffer.current.push(value);
		}
	}, []);

	const clearUpdateBuffer = useCallback(() => {
		updateSubscriptionBuffer.current = [];
	}, []);

	useSubscription<Subscription, SubscriptionIntegrationUpdatedArgs>(IntegrationUpdatedSubscription, {
		client: graphqlClient,
		variables: {
			tenantId: authStore.currentTenantId,
		},
		onSubscriptionData: ({ subscriptionData }) => {
			const integrationUpdated = subscriptionData?.data?.integrationUpdated;

			if (filterType && integrationUpdated?.type !== filterType) {
				return;
			}

			pushUpdateToBuffer(integrationUpdated);
			mergeUpdateIntegrationSubscription(graphqlClient, updateSubscriptionBuffer.current, clearUpdateBuffer);
		},
	});
};

const useIntegrationSubscription = (
	mergeUpdateIntegrationSubscription: typeof mergeUpdateCollectorIntegrationSubscription | typeof mergeUpdateResponseIntegrationSubscription,
	filterType?: IntegrationType,
) => {
	useCreateIntegrationSubscription(filterType);
	useUpdateIntegrationSubscription(mergeUpdateIntegrationSubscription, filterType);
};

export const useCollectorIntegrationSubscription = () => {
	useIntegrationSubscription(mergeUpdateCollectorIntegrationSubscription, IntegrationType.Telemetry);
};

export const useResponseIntegrationSubscription = () => {
	useIntegrationSubscription(mergeUpdateResponseIntegrationSubscription, IntegrationType.Response);
};

export const useIntegrationUpdatedSubscription = (filterType?: IntegrationType) => {
	useUpdateIntegrationSubscription(mergeUpdateCollectorIntegrationSubscription, filterType);
};
