export const mapEncodedUrl = (state: { [key: string]: boolean | string }): { [key: string]: string | boolean } => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return Object.keys(state).reduce((acc: { [key: string]: any }, curr) => {
		if (!curr.includes('_')) {
			return { ...acc, [curr]: state[curr] };
		}

		const splitObject = curr.split('_');
		const ObjectName = splitObject[0];
		const objectVariable = splitObject[1];

		if (acc[ObjectName]) {
			return {
				...acc,
				[ObjectName]: {
					...acc[ObjectName],
					[objectVariable]: state[curr],
				},
			};
		}
		return {
			...acc,
			[ObjectName]: {
				[objectVariable]: state[curr],
			},
		};
	}, {});
};
