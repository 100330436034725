import React, { FC, useMemo } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { AdvancedQueryChartViewStore, AdvancedQueryViewStore } from '@/app/advanced-query/_common/stores';
import { ChartDataItem, ChartStackBarKeys } from '@/app/_common/types';
import { X_AXIS_COLUMN_WIDTH } from '@/app/_common/constants';
import { formatXAxisDate } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/chart/utils';

import styles from './chart-x-axis.module.scss';

interface ChartXAxisProps {
	xMax: number;
	barGroupLeft: number;
}

export const ChartXAxis: FC<ChartXAxisProps> = observer(({ xMax, barGroupLeft }) => {
	const { chartData } = useInstance(AdvancedQueryChartViewStore);
	const { persistentTimeRange } = useInstance(AdvancedQueryViewStore);

	const xAxisTicksNumber = Math.floor(xMax / X_AXIS_COLUMN_WIDTH);

	const isSingleBar = chartData.length === 1;

	const containerStyle = useMemo(() => {
		return { left: barGroupLeft, width: xMax, justifyContent: isSingleBar ? 'center' : 'space-between' };
	}, [barGroupLeft, xMax, isSingleBar]);

	if (!chartData.length) return null;

	const filteredChartData = chartData.filter((_, index, arr) => {
		return arr.length <= xAxisTicksNumber || index % Math.round(arr.length / xAxisTicksNumber) === 0;
	});

	const filteredChartDataLabels = filteredChartData.map((item: ChartDataItem) => item[ChartStackBarKeys.Date]);
	const toValue = persistentTimeRange?.value?.to || chartData[chartData.length - 1][ChartStackBarKeys.Date];
	const lastLabelDate = isSingleBar ? [] : [toValue];
	const labelsWithToTimeRange = [...filteredChartDataLabels, ...lastLabelDate];
	const formattedLabels = labelsWithToTimeRange.map((item: string) => formatXAxisDate(item));

	return (
		<div className={styles.xAxisLabel} style={containerStyle}>
			{formattedLabels.map((label) => {
				return (
					<div className={styles.xAxisLabelItem} key={label}>
						{label}
					</div>
				);
			})}
		</div>
	);
});
