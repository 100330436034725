import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import RouterIcon from '@mui/icons-material/Router';

import { Namespaces } from '@/translations/namespaces';
import { BoxContainer } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alert-host-user-box/_components/box-container';
import { BlockItem } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alert-host-user-box/_components/block-item';

import styles from './alert-network-box.module.scss';

interface AlertNetworkBoxProps {
	externalIP: string;
	internalIP: string;
}

export const AlertNetworkBox: FC<AlertNetworkBoxProps> = ({ externalIP, internalIP }) => {
	const { t } = useTranslation(Namespaces.DetailsView);
	const hasExternalIP = Boolean(externalIP);
	const hasInternalIP = Boolean(internalIP);
	const hasNoData = !hasExternalIP && !hasInternalIP;

	if (hasNoData) {
		return null;
	}

	return (
		<BoxContainer containerClassName={styles.container} contentClassName={styles.content} icon={<RouterIcon />}>
			{hasExternalIP && <BlockItem title={t('network.externalIp')} text={externalIP} />}
			{hasInternalIP && <BlockItem title={t('network.internalIp')} text={internalIP} />}
		</BoxContainer>
	);
};
