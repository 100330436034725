import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

import { TelemetryIntegrationType } from '@/app/_features/integration/_common/types';

const TRANSLATION_OPTIONS = {
	ns: Namespaces.Integrations,
};

export const getTelemetryIntegrationTypeLabel = (telemetryIntegrationType?: TelemetryIntegrationType): string => {
	switch (telemetryIntegrationType) {
		case TelemetryIntegrationType.Cloud:
			return i18n.t('telemetryIntegrationType.cloud', TRANSLATION_OPTIONS);
		case TelemetryIntegrationType.SecureSyslog:
			return i18n.t('telemetryIntegrationType.secureSyslog', TRANSLATION_OPTIONS);
		case TelemetryIntegrationType.Unknown:
		default:
			return i18n.t('telemetryIntegrationType.unknown', TRANSLATION_OPTIONS);
	}
};
