import { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import { ContextMenuComponentProps } from '@/app/_common/types';
import { GridCellProps } from '@progress/kendo-react-grid';

import { DataCell } from '@/app/_common/_components/data-grid/_components';
import { RootPaths } from '@/app/_common/navigation/root-paths';

import styles from './link-cell.module.scss';

export interface LinkCellProps extends GridCellProps {
	title?: string;
	titleField?: string;
	path: RootPaths | string;
	from?: string;
	prefixIcon?: ReactNode;
	defaultMessage?: string;
	ContextMenuComponent?: React.FC<ContextMenuComponentProps>;
}

export const LinkCell: FC<LinkCellProps> = ({
	dataItem,
	title,
	titleField,
	path,
	field,
	from,
	defaultMessage = '-',
	prefixIcon,
	ContextMenuComponent,
	...rest
}) => {
	const titleValue = title || _get(dataItem, titleField || field || '');
	const value = _isNil(titleValue) ? _get(dataItem, field || '') : titleValue;
	const { search } = useLocation();
	if (!value) {
		return (
			<DataCell {...rest} dataItem={dataItem} ContextMenuComponent={ContextMenuComponent}>
				{defaultMessage}
			</DataCell>
		);
	}

	return (
		<DataCell {...rest} className={styles.linkCell} dataItem={dataItem} ContextMenuComponent={ContextMenuComponent}>
			<Link to={{ pathname: path, search }} state={{ from }}>
				{prefixIcon}
				{value}
			</Link>
		</DataCell>
	);
};
