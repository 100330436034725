import moment from 'moment';

export const CUSTOM_OPTION_KEY = 'Custom';

export const EMPTY_CUSTOM_OPTION_VALUE = '';

export const DATE_TIME_PICKER_FORMAT = 'yMMMdEHmSs';

export enum DateTimePickerOptionKeys {
	FIFTEEN_MINUTES = 'fifteenMinutes',
	ONE_HOUR = 'oneHour',
	ONE_DAY = 'oneDay',
	THREE_DAYS = 'threeDays',
	ONE_WEEK = 'oneWeek',
	ONE_MONTH = 'oneMonth',
	THREE_MONTHS = 'threeMonths',
	INDEFINITELY = 'indefinitely',
	CUSTOM = 'custom',
}

export const DASHBOARD_DATE_TIME_PERIOD_OPTION_KEYS = [
	DateTimePickerOptionKeys.ONE_DAY,
	DateTimePickerOptionKeys.THREE_DAYS,
	DateTimePickerOptionKeys.ONE_WEEK,
	DateTimePickerOptionKeys.ONE_MONTH,
	DateTimePickerOptionKeys.CUSTOM,
];

export enum AdvancedQueryTimePeriodOptionKeys {
	ONE_DAY = '1 day',
	THREE_DAYS = '3 days',
	ONE_WEEK = '1 week',
	ONE_MONTH = '1 month',
}

export enum InvestigationSleepTimePeriodOptionKeys {
	SEVEN_DAYS = '7 days',
	ONE_MONTH = '1 month',
	THREE_MONTHS = '3 months',
	INDEFINITELY = 'Indefinitely',
}

export enum EarliestCustomDateStart {
	ONE_YEAR_AGO = 'oneYearAgo',
}

export const EarliestCustomDateStartValues = {
	[EarliestCustomDateStart.ONE_YEAR_AGO]: moment().utc().subtract(1, 'year').toDate(),
};
