import { FC } from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import i18n from '@/translations/i18n';

import { Namespaces } from '@/translations/namespaces';
import { MenuHeader, PanelBarComponent, SidebarNavigationListProps } from './_common';
import { franchiseMenuItems } from './_common/franchise-menu-items';

import styles from './franchise-sidebar-navigation-list.module.scss';

export const FranchiseSidebarNavigationList: FC<SidebarNavigationListProps> = ({ drawerOpened }) => {
	return (
		<>
			<MenuHeader
				drawerOpened={drawerOpened}
				title={i18n.t('franchiseSidebar.title', { ns: Namespaces.Sidebar })}
				Icon={BusinessIcon}
				iconStyle={styles.franchiseIcon}
				style={styles.franchiseHeader}
			/>
			<PanelBarComponent
				menuItems={franchiseMenuItems}
				drawerOpened={drawerOpened}
				selectedStyles={styles.selected}
				selectedBorderStyles={styles.selectedBorder}
			/>
		</>
	);
};
