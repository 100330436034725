import { FC } from 'react';
import classNames from 'classnames';
import SettingsIcon from '@mui/icons-material/Settings';

import styles from './chat-settings-icon.module.scss';

interface ChatSettingsIconProps {
	className?: string;
	active?: boolean;
}

export const ChatSettingsIcon: FC<ChatSettingsIconProps> = ({ active, className }) => {
	return <SettingsIcon className={classNames(styles.icon, className, { [styles.active]: active })} />;
};
