import { useMemo } from 'react';
import _get from 'lodash/get';

import { Alert, Investigation } from '@/generated/graphql';
import { ALERT_ACTIONS, AlertActionProps, ResultsDataItem } from '@/app/_common/types';
import {
	isAlertAssignable,
	isAlertDismissable,
	isAlertInvestigationClosed,
	isAlertUnassignable,
	isAlertUndismissable,
} from '@/app/dashboards/alerts-dashboard/_common/utils';
import { OpenInvestigationAction } from '@/app/_common/_components/data-grid/_components/alerts-widget-context-menu/_components';
import { ActionLabel } from '@/app/_features/alerts-actions-menu/_components';
import { isResultItem } from '@/app/_common/utils';
import { AdxTables, RESULTS_ALERT_ID_PATH, RESULTS_ARTIFICIAL_ID_PATH, TableColumn } from '@/app/_common/constants';

interface SingleActionsConfig {
	selectedResults: ResultsDataItem[];
	focusedNode?: Alert | ResultsDataItem;
	focusedNodeArtificialIdPath?: string;
	onViewDetails: () => void;
	onAssignOrCreateInvestigationClick: () => void;
	onUnassignClick: (alerts: Alert[]) => void;
	onDismissClick: () => void;
	onUndismissClick: () => void;
}

export const useResultsSingleActions = ({
	selectedResults,
	focusedNode,
	focusedNodeArtificialIdPath = '_id',
	onViewDetails,
	onAssignOrCreateInvestigationClick,
	onUnassignClick,
	onDismissClick,
	onUndismissClick,
}: SingleActionsConfig): AlertActionProps[] => {
	return useMemo<AlertActionProps[]>(() => {
		if (selectedResults.length > 1) {
			return [];
		}

		if (isResultItem(focusedNode)) {
			const idPath = focusedNode[TableColumn] === AdxTables.Alerts ? RESULTS_ALERT_ID_PATH : RESULTS_ARTIFICIAL_ID_PATH;
			const showViewDetails = Boolean(_get(focusedNode, idPath));

			return [
				{
					id: ALERT_ACTIONS.ViewDetails,
					show: showViewDetails,
					label: () => <ActionLabel action={ALERT_ACTIONS.ViewDetails} />,
					onClick: onViewDetails,
				},
				{
					id: ALERT_ACTIONS.AssignOrCreateInvestigation,
					show: Boolean(_get(focusedNode, focusedNodeArtificialIdPath)),
					label: () => <ActionLabel action={ALERT_ACTIONS.AssignOrCreateInvestigation} />,
					onClick: onAssignOrCreateInvestigationClick,
				},
			];
		}

		const investigationId = focusedNode?.investigationSummary?.id ?? '';

		return [
			{
				id: ALERT_ACTIONS.ViewDetails,
				show: Boolean(focusedNode?.id),
				label: () => <ActionLabel action={ALERT_ACTIONS.ViewDetails} />,
				onClick: onViewDetails,
			},
			{
				id: ALERT_ACTIONS.OpenInvestigation,
				show: (investigations: Investigation[]) => investigations?.find((investigation) => investigation.id === investigationId) !== undefined,
				label: () => <OpenInvestigationAction investigationId={investigationId} />,
			},
			{
				id: ALERT_ACTIONS.AssignOrCreateInvestigation,
				show: isAlertAssignable(focusedNode),
				label: () => <ActionLabel action={ALERT_ACTIONS.AssignOrCreateInvestigation} />,
				onClick: onAssignOrCreateInvestigationClick,
			},
			{
				id: ALERT_ACTIONS.Unassign,
				show: (investigations: Investigation[]) =>
					!isAlertInvestigationClosed(focusedNode, investigations) && isAlertUnassignable(focusedNode, investigations),
				label: () => <ActionLabel action={ALERT_ACTIONS.Unassign} />,
				onClick: onUnassignClick,
			},
			{
				id: ALERT_ACTIONS.Dismiss,
				show: (investigations: Investigation[]) =>
					!isAlertInvestigationClosed(focusedNode, investigations) && isAlertDismissable(focusedNode, investigations),
				label: () => <ActionLabel action={ALERT_ACTIONS.Dismiss} />,
				onClick: onDismissClick,
			},
			{
				id: ALERT_ACTIONS.Undismiss,
				show: isAlertUndismissable(focusedNode),
				label: () => <ActionLabel action={ALERT_ACTIONS.Undismiss} />,
				onClick: onUndismissClick,
			},
		];
	}, [
		focusedNode,
		selectedResults,
		focusedNodeArtificialIdPath,
		onAssignOrCreateInvestigationClick,
		onUnassignClick,
		onDismissClick,
		onUndismissClick,
		onViewDetails,
	]);
};
