import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';

import { HeaderActions, HeaderDateTitle } from './_components';
import { DetailsViewHeaderProps } from '@/app/_common/_components/details-view/types';

import styles from './details-view-header.module.scss';

export const DetailsViewHeader = ({
	typename,
	isItemSelected,
	totalItemsAmount,
	onPreviousItem,
	onNextItem,
	currentItemIndex,
	selectedItemTitle,
	handleCloseButtonClick,
	assignOrCreateInvestigation,
	currentItemTimestamp,
	itemId,
	selectedItems,
	selectedItem,
	singleActions,
	singleActionsTitle,
	bulkActions,
	bulkActionsTitle,
}: DetailsViewHeaderProps) => {
	const { t } = useTranslation([Namespaces.DetailsView]);

	return (
		<header className={styles.detailsViewHeader}>
			<HeaderDateTitle
				typename={typename}
				isItemSelected={isItemSelected}
				totalItemsAmount={totalItemsAmount}
				onPreviousItem={onPreviousItem}
				onNextItem={onNextItem}
				currentItemIndex={currentItemIndex}
				selectedItemTitle={selectedItemTitle}
				timestamp={currentItemTimestamp}
				itemId={itemId}
			/>
			<HeaderActions
				t={t}
				typename={typename}
				assignOrCreateInvestigation={assignOrCreateInvestigation}
				handleCloseButtonClick={handleCloseButtonClick}
				selectedItems={selectedItems}
				selectedItem={selectedItem}
				singleActions={singleActions}
				singleActionsTitle={singleActionsTitle}
				bulkActions={bulkActions}
				bulkActionsTitle={bulkActionsTitle}
			/>
		</header>
	);
};
