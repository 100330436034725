interface SortWithExceptionsOptions<T, K extends string | number> {
	exceptions: Record<K, number>;
	accessor: (item: T) => K;
	defaultSort?: (a: K, b: K) => number;
}

export const sortWithExceptions = <T, K extends string | number>(
	arr: T[],
	{ exceptions, accessor, defaultSort = (a: K, b: K) => a.toString().localeCompare(b.toString()) }: SortWithExceptionsOptions<T, K>,
) => {
	return arr.sort((a, b) => {
		const aValue = accessor(a);
		const bValue = accessor(b);
		if (exceptions[aValue] && exceptions[bValue]) {
			return exceptions[aValue] - exceptions[bValue];
		} else if (exceptions[aValue]) {
			return -1;
		} else if (exceptions[bValue]) {
			return 1;
		}
		return defaultSort(aValue, bValue);
	});
};
