import { makeAutoObservable } from 'mobx';

import { EndpointsTableFilterValue, EndpointsTableFilterValues } from '@/generated/graphql';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { FilterOptionItem } from '@/app/_common/types';

import { ValidFilterColumnNames } from '@/app/response/endpoints/_common/constants';
import { EndpointsListColumnFilterDataStore, EndpointsListViewStore } from '@/app/response/endpoints/_common/stores';

interface State {
	columnHeaderFilter: string;
	columnName: ValidFilterColumnNames | '';
}

const INITIAL_STATE: State = {
	columnHeaderFilter: '',
	columnName: '',
};

export class EndpointsListColumnFilterViewStore {
	private state = INITIAL_STATE;
	private dataStore = injectInterface(this, EndpointsListColumnFilterDataStore);
	private endpointsListViewStore = injectInterface(this, EndpointsListViewStore);

	constructor() {
		makeAutoObservable(this);
	}

	get loading(): boolean {
		return this.dataStore.loading;
	}

	get error() {
		return this.dataStore.error;
	}

	get data() {
		return this.dataStore.data;
	}

	get filters(): EndpointsTableFilterValue[] | undefined {
		return (this.data?.listEndpointsTableFilterValues as EndpointsTableFilterValues)?.filters || [];
	}

	get filterOptions(): FilterOptionItem[] {
		return (
			this.filters?.map((filter) => {
				return {
					label: filter.value,
					value: filter.value,
					counter: filter.count,
				};
			}) || []
		);
	}

	get columnName() {
		return this.state.columnName;
	}

	setColumnName = (columnName: ValidFilterColumnNames): void => {
		this.state.columnName = columnName;
	};

	read() {
		if (!this.columnName) {
			return;
		}

		const valueFilters = this.endpointsListViewStore.queryValueFilters;

		this.dataStore.read(this.columnName, { valueFilters });
	}
}
