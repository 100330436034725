import { FC, ReactNode, memo } from 'react';
import classNames from 'classnames';

import { AssignToInvestigationTabs } from '@/app/_common/constants';
import {
	AssignToInvestigationFormTabContent,
	AssignToInvestigationFormTabTitle,
} from '@/app/_common/_components/forms/assign-to-investigation-form-tabs/_components';

import styles from './assign-to-investigation-form-tabs.module.scss';

interface AssignToInvestigationFormTab {
	id: AssignToInvestigationTabs;
	title: string;
	content: ReactNode;
}

interface AssignToInvestigationFormTabsProps {
	tabs: AssignToInvestigationFormTab[];
	active: AssignToInvestigationTabs;
	onTabButtonClick(id: AssignToInvestigationTabs): void;
	onTabContentFocus(id: AssignToInvestigationTabs): void;
}

export const AssignToInvestigationFormTabs: FC<AssignToInvestigationFormTabsProps> = memo(({ tabs, active, onTabButtonClick, onTabContentFocus }) => (
	<div className={styles.tabs}>
		{tabs.map(({ id, title, content }) => (
			<div key={id} className={classNames(styles.tab, { [styles.tabActive]: active === id })}>
				<AssignToInvestigationFormTabTitle id={id} className={styles.tabTitle} onClick={onTabButtonClick}>
					{title}
				</AssignToInvestigationFormTabTitle>
				<AssignToInvestigationFormTabContent id={id} className={styles.tabContent} onFocus={onTabContentFocus}>
					{content}
				</AssignToInvestigationFormTabContent>
			</div>
		))}
	</div>
));

AssignToInvestigationFormTabs.displayName = 'AssignToInvestigationFormTabs';
