import { AlertSpecialFieldNames } from '@/app/_common/types';
import { DateTimePickerOptionKeys } from '@/app/_common/constants';
import { QUERY_OPERATOR } from './adx-kusto-tokens.constants';

export const TIMESTAMP_COLUMN = 'timestamp';
export const DYNAMIC_COLUMN_TYPE = 'dynamic';
export const DEFAULT_SORT_COLUMNS = [TIMESTAMP_COLUMN];
export const MATERIALIZE_REGEX = /(?<start>^[\S\s]+=\s+materialize\()(?<query>[\S\s]*?)(?<end>\)\s*;)(?<rest>[\S\s]*)/;
export const QUOTED_STRING_REGEX = /^['"]/;
export const ESCAPE_CHARACTERS_REGEX = /\\[nrtbfv]/g;
export const FACET_BY_REGEX = new RegExp(/\s*(?:^|\|)\s*facet\s+by/);
export const ORDER_BY_REGEX = new RegExp(/\s*(?:^|\|)\s*(?:order|sort)\s+by/);
export const SEPARATOR_WHITESPACE_REGEX = /^(\s+)?\|(\s+)?/;
export const COMMA_OUTSIDE_PARENTHESES_REGEX = /,(?![^(\]]*\))/;
export const QUERY_SEPARATOR = '|';
export const WHITE_SPACES = '\\s*';
export const ARBITRARY_STRING = '\\w+';
export const DATETIME_FILTER_OPERATOR = `(${QUERY_OPERATOR.WHERE}|${QUERY_OPERATOR.FILTER})`;

export enum KUSTO_LOGICAL_OPERATORS {
	AND = 'and',
	OR = 'or',
}

export enum KUSTO_TIME_RANGE_KEYS {
	SECOND = 's',
	MINUTE = 'm',
	HOUR = 'h',
	DAY = 'd',
}

export const RELATIVE_DATE_TIME_RANGES: Record<string, string> = {
	[DateTimePickerOptionKeys.FIFTEEN_MINUTES]: '15m',
	[DateTimePickerOptionKeys.ONE_HOUR]: '1h',
	[DateTimePickerOptionKeys.ONE_DAY]: '24h',
	[DateTimePickerOptionKeys.ONE_WEEK]: '7d',
	[DateTimePickerOptionKeys.ONE_MONTH]: '30d',
};
export const RELATIVE_DATE_TIME_RANGES_AS_SECONDS: Record<string, number> = {
	[DateTimePickerOptionKeys.FIFTEEN_MINUTES]: 15 * 60, // 15 min
	[DateTimePickerOptionKeys.ONE_HOUR]: 60 * 60, // 1 hour
	[DateTimePickerOptionKeys.ONE_DAY]: 24 * 60 * 60, // 24 hours
	[DateTimePickerOptionKeys.ONE_WEEK]: 7 * 24 * 60 * 60, // 7 days
	[DateTimePickerOptionKeys.ONE_MONTH]: 30 * 24 * 60 * 60, // 30 days
};
export const KUSTO_TIME_RANGE_AS_SECONDS: Record<string, number> = {
	[KUSTO_TIME_RANGE_KEYS.SECOND]: 1,
	[KUSTO_TIME_RANGE_KEYS.MINUTE]: 60,
	[KUSTO_TIME_RANGE_KEYS.HOUR]: 60 * 60,
	[KUSTO_TIME_RANGE_KEYS.DAY]: 24 * 60 * 60,
};
export const ADX_IGNORED_COLUMNS = ['log_id', 'raw', 'session_id', 'url_raw', 'alert_json'];
export const ADX_IGNORED_VALUES = ['N/A', 'NULL', 'UNKNOWN', 'MULTI', '0'];
export const ADX_FILTERED_COLUMNS = [
	'log_id',
	'event_id',
	'alert_id',
	'alert_raw',
	'external_alert_id',
	'session_id',
	'tenant_id',
	'logapiTimeStamp',
	'originTimeStamp',
	'indexedTimeStamp',
	'timestamp',
	'timestamp_vendor',
	'bytes_in',
	'bytes_out',
	'raw',
	'url_raw',
	'evidence_id',
	'evidence_parent_id',
	'external_device_id',
];
export enum ADX_FILTER_OPERATORS {
	EQUAL = '==',
	NOT_EQUAL = '!=',
	HAS_ANY = 'has_any',
	NOT_HAS_ANY = 'not_has_any', // abstract filter operator -> not(field has_any ("value"))
	IN = 'in',
	NOT_IN = '!in',
}

export const ADX_FILTER_BY_OPERATORS = [ADX_FILTER_OPERATORS.EQUAL, ADX_FILTER_OPERATORS.HAS_ANY, ADX_FILTER_OPERATORS.IN];

export const ADX_FILTER_OUT_OPERATORS = [ADX_FILTER_OPERATORS.NOT_EQUAL, ADX_FILTER_OPERATORS.NOT_HAS_ANY, ADX_FILTER_OPERATORS.NOT_IN];

export enum FIELD_FILTER_GROUPS {
	EQUALS = 'equals',
	NOT_EQUALS = 'not-equals',
	MIXED = 'mixed',
}
export const ADX_EVENTS_DEFAULT_FAVORITE_FIELDS = [
	'action',
	'actual_action',
	'dest_ip',
	'dest_port',
	'dest',
	'http_method',
	'http_user_agent',
	'protocol',
	'signature',
	'sourcetype',
	'src_ip',
	'src_port',
	'src',
	'status',
	'type',
	'url',
	'vendor',
];

export const ADX_ALERTS_DEFAULT_FAVORITE_FIELDS = [
	'confidence',
	'filename_uniq',
	'mitre_tactics',
	'mitre_techniques',
	'origin',
	'product',
	'severity',
	'signature',
	'signature_category',
	'signature_details',
	'signature_id',
	'src',
	'dest',
	'src_port',
	'dest_port',
	'signature_recommendation',
	'threat_family',
	'type',
	'users',
	'vendor',
	'action',
	'action_raw',
];

export const ADX_EVIDENCE_DEFAULT_FAVORITE_FIELDS = [
	'evidence_category',
	'hostname',
	'hosttype',
	'os',
	'os_version',
	'external_ip',
	'local_ip',
	'users',
	'signature',
	'signature_details',
	'signature_category',
	'severity',
	'mitre_tactics_id',
	'mitre_tactics',
	'mitre_techniques_id',
	'mitre_techniques',
	'action',
];

// confirm with our schema
export enum CSL_TYPES {
	BOOL = 'bool',
	DATETIME = 'datetime',
	DECIMAL = 'decimal',
	DYNAMIC = 'dynamic',
	GUID = 'guid',
	INT = 'int',
	LONG = 'long',
	REAL = 'real',
	STRING = 'string',
	TIMESPAN = 'timespan',
}

export const TIMESTAMP_STRING = 'timestamp';
export const DEFAULT_RESULTS_LIMIT = 2000;

export enum AdxEndpoints {
	QueryV2 = 'queryV2',
	NamedQueryV2 = 'namedQueryV2',
	ShowSchemaV2 = 'showSchemaV2',
}

export enum AdxNamedQueries {
	ThirtyDayAverageHealthStatus = 'thirty_day_log_avg_health_status',
}

export enum AdxTables {
	Alerts = 'alerts',
	Evidence = 'evidence',
	Events = 'events',
}

export const ADX_TABLES_ORDER = [AdxTables.Alerts, AdxTables.Evidence, AdxTables.Events];

export const AllowedAdxTables = Object.values(AdxTables);

export const TableColumn = '$table';

export const INITIAL_QUERY = 'union alerts, evidence, events';

export const ALLOWED_EMPTY_FIELDS = [AlertSpecialFieldNames.Confidence, AlertSpecialFieldNames.Severity];

export const AVAILABLE_FIELDS_VALUES_TOP_TABLE = 'Top';
export const AVAILABLE_FIELDS_VALUES_RARE_TABLE = 'Rare';
export const AVAILABLE_FIELDS_VALUES_UNIQUE_COUNT_TABLE = 'UniqValuesCount';
export const AVAILABLE_FIELDS_VALUES_COUNT_TABLE = 'ValuesCount';
export const URL_STATE_QUERY = 'query';
