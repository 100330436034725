import { useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';

import { AlertConfidence } from '@/app/_common/constants';
import { AlertState, Investigation } from '@/generated/graphql';
import { AlertsAssignDialogViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/alerts-assign-dialog.view-store';
import { AlertsStateActionsViewStore } from '@/app/dashboards/alerts-dashboard/_common/stores';
import { AlertActions, AlertsActionDialog, DetailsView } from '@/app/_common/_components';
import {
	areAlertsAssignable,
	areAlertsDismissable,
	areAlertsUnassignable,
	areAlertsUndismissable,
	isAlertAssignable,
	isAlertDismissable,
	isAlertInvestigationClosed,
	isAlertUnassignable,
	isAlertUndismissable,
} from '@/app/dashboards/alerts-dashboard/_common/utils';
import { ALERT_ACTIONS, AlertActionProps, ResultsDataItem } from '@/app/_common/types';
import { Namespaces } from '@/translations/namespaces';
import {
	AdvancedQueryAlertDetailsViewStore,
	DashboardAlertDetailsViewStore,
	InvestigationAlertDetailsViewStore,
	QueryResultsDetailsViewStore,
} from '@/app/_common/_components/details-view/stores';
import { DetailsViewItemData } from '@/app/_common/_components/details-view/types';

type AlertsDetailsPropsAlertDetailsViewStore =
	| DashboardAlertDetailsViewStore
	| AdvancedQueryAlertDetailsViewStore
	| InvestigationAlertDetailsViewStore
	| QueryResultsDetailsViewStore;

export interface AlertsDetailsProps {
	alertDetailsViewStore: AlertsDetailsPropsAlertDetailsViewStore;
	onHandleNextAlert?: (id?: string) => void;
	onCloseButtonClick?: () => void;
	onAssignOrCreateClick?: (items?: ResultsDataItem) => void;
}

const onHandleDismissClick = (
	alertStateActionStore: AlertsStateActionsViewStore,
	store: AlertsDetailsPropsAlertDetailsViewStore,
	nextAlert: () => void,
): void => {
	if (store.selectedAlert?.id) {
		if (store.selectedAlert?.state?.alertState === AlertState.Assigned) {
			alertStateActionStore.open(store.selectedAlert.id);
		} else {
			alertStateActionStore.dismissAlert(store.selectedAlert.id);
			nextAlert();
		}
	}
};

const onHandleDismissSelectedClick = (alerts: DetailsViewItemData[], alertStateActionStore: AlertsStateActionsViewStore): void => {
	if (alerts?.length > 0) {
		const ids = alerts.map((alert) => alert.id);

		if (alerts.some((alert) => alert.investigationSummary)) {
			alertStateActionStore.open(ids);
		} else {
			alertStateActionStore.dismissAlerts(ids);
		}
	}
};

const onHandleAssignOrCreateButtonClick = (
	selectedAlertsIds: string[],
	selectedItems: DetailsViewItemData[],
	handleAssignOrCreateInvestigationClick: () => void,
	handleAssignOrCreateSelectedInvestigationClick: (alerts: DetailsViewItemData[]) => void,
	storeSelectedAlertId?: string,
): void => {
	if (storeSelectedAlertId && !selectedAlertsIds.includes(storeSelectedAlertId)) {
		handleAssignOrCreateInvestigationClick();
	} else if (selectedItems?.length > 0) {
		handleAssignOrCreateSelectedInvestigationClick(selectedItems);
	}
};

const onHandleUnassignSelectedClick = (
	alerts: DetailsViewItemData[],
	setAlertsToUnassign: (alerts: DetailsViewItemData[]) => void,
	handleUnassignDialogOpen: () => void,
): void => {
	if (alerts?.length > 0) {
		setAlertsToUnassign(alerts);
		handleUnassignDialogOpen();
	}
};

const onHandleUnassignClick = (
	handleUnassignDialogOpen: () => void,
	setAlertsToUnassign: (alerts: DetailsViewItemData[]) => void,
	selectedAlert?: DetailsViewItemData,
): void => {
	if (selectedAlert) {
		setAlertsToUnassign([selectedAlert]);
		handleUnassignDialogOpen();
	}
};

export const AlertsDetails = observer(
	({ alertDetailsViewStore: store, onCloseButtonClick, onHandleNextAlert, onAssignOrCreateClick }: AlertsDetailsProps) => {
		const alertStateActionStore = useInstance(AlertsStateActionsViewStore);
		const actionDialogStore = useInstance(AlertsAssignDialogViewStore);

		const [isUnassignDialogOpen, setIsUnassignDialogOpen] = useState(false);
		const [alertsToUnassign, setAlertsToUnassign] = useState<DetailsViewItemData[]>([]);

		const { t } = useTranslation([Namespaces.DetailsView]);

		const {
			selectedItemTypename,
			selectedAlertData: selectedAlert,
			selectedItems,
			clearSelectedAlertId,
			onNextAlert,
			onPreviousAlert,
			loading,
			isAlertSelected,
			totalAlertsAmount,
			currentAlertIndex,
			selectedAlertId,
			selectedAlertConfidence,
			selectedAlertDescription,
			selectedAlertRecommendation,
			loadingAssignedInvestigation,
			selectedMitreCategory,
			selectedAlertState,
			retryRead,
			alertError,
			threatIntelligenceMatches,
			threatIntelligenceDescriptions,
		} = store;

		const nextAlert = useCallback(() => {
			const currId = selectedAlert?.id;
			if (selectedItems?.length > 1) {
				onNextAlert();
			}
			onHandleNextAlert?.(currId);
		}, [onNextAlert, onHandleNextAlert, selectedAlert?.id, selectedItems?.length]);

		const selectedAlertsIds = useMemo(() => selectedItems.map(({ id }) => id), [selectedItems]);

		const handleUnassignDialogOpen = useCallback(() => {
			setIsUnassignDialogOpen(true);
		}, []);

		const handleUnassignDialogClose = useCallback(() => {
			setIsUnassignDialogOpen(false);
		}, []);

		const handleAssignOrCreateInvestigationClick = useCallback(() => {
			if (selectedAlert) {
				actionDialogStore.open([selectedAlert.id]);
			}
		}, [actionDialogStore, selectedAlert]);

		const handleAssignOrCreateSelectedInvestigationClick = useCallback(
			(alerts: DetailsViewItemData[]) => {
				if (alerts?.length > 0) {
					actionDialogStore.open(alerts.map((alert) => alert.id));
				}
			},
			[actionDialogStore],
		);

		const handleUnassignClick = useCallback(() => {
			onHandleUnassignClick(handleUnassignDialogOpen, setAlertsToUnassign, selectedAlert);
		}, [selectedAlert, handleUnassignDialogOpen, setAlertsToUnassign]);

		const handleUnassignSelectedClick = useCallback(
			(alerts: DetailsViewItemData[]) => {
				onHandleUnassignSelectedClick(alerts, setAlertsToUnassign, handleUnassignDialogOpen);
			},
			[setAlertsToUnassign, handleUnassignDialogOpen],
		);

		const handleDismissClick = useCallback(() => {
			onHandleDismissClick(alertStateActionStore, store, nextAlert);
		}, [alertStateActionStore, store, nextAlert]);

		const handleDismissSelectedClick = useCallback(
			(alerts: DetailsViewItemData[]) => {
				onHandleDismissSelectedClick(alerts, alertStateActionStore);
			},
			[alertStateActionStore],
		);

		const handleUndismissClick = useCallback(() => {
			if (selectedAlert) {
				alertStateActionStore.undismissAlert(selectedAlert.id);
				nextAlert();
			}
		}, [alertStateActionStore, nextAlert, selectedAlert]);

		const handleUndismissSelectedClick = useCallback(
			(alerts: DetailsViewItemData[]) => {
				if (alerts?.length > 0) {
					alertStateActionStore.undismissAlerts(alerts.map((alert) => alert.id));
				}
			},
			[alertStateActionStore],
		);

		const handleAssignOrCreateButtonClick = useCallback(() => {
			if (onAssignOrCreateClick) {
				onAssignOrCreateClick();
				return;
			}
			onHandleAssignOrCreateButtonClick(
				selectedAlertsIds,
				selectedItems,
				handleAssignOrCreateInvestigationClick,
				handleAssignOrCreateSelectedInvestigationClick,
				selectedAlert?.id,
			);
		}, [
			selectedItems,
			selectedAlertsIds,
			selectedAlert?.id,
			handleAssignOrCreateInvestigationClick,
			handleAssignOrCreateSelectedInvestigationClick,
			onAssignOrCreateClick,
		]);

		const singleActions = useMemo<AlertActionProps[]>(() => {
			return [
				{
					id: ALERT_ACTIONS.AssignOrCreateInvestigation,
					show: isAlertAssignable(selectedAlert),
					label: t(`actions.single.items.${ALERT_ACTIONS.AssignOrCreateInvestigation}`),
					onClick: handleAssignOrCreateInvestigationClick,
				},
				{
					id: ALERT_ACTIONS.Unassign,
					show: (investigations: Investigation[]) =>
						isAlertInvestigationClosed(selectedAlert, investigations) || isAlertUnassignable(selectedAlert, investigations),
					label: t(`actions.single.items.${ALERT_ACTIONS.Unassign}`),
					disabled: (investigations: Investigation[]) => isAlertInvestigationClosed(selectedAlert, investigations),
					disabledTooltip: t('actions.single.disabledTooltip'),
					onClick: handleUnassignClick,
				},
				{
					id: ALERT_ACTIONS.Dismiss,
					show: (investigations: Investigation[]) =>
						isAlertInvestigationClosed(selectedAlert, investigations) || isAlertDismissable(selectedAlert, investigations),
					label: t(`actions.single.items.${ALERT_ACTIONS.Dismiss}`),
					disabled: (investigations: Investigation[]) => isAlertInvestigationClosed(selectedAlert, investigations),
					disabledTooltip: t('actions.single.disabledTooltip'),
					onClick: handleDismissClick,
				},
				{
					id: ALERT_ACTIONS.Undismiss,
					show: isAlertUndismissable(selectedAlert),
					label: t(`actions.single.items.${ALERT_ACTIONS.Undismiss}`),
					onClick: handleUndismissClick,
				},
			];
		}, [selectedAlert, t, handleAssignOrCreateInvestigationClick, handleUnassignClick, handleDismissClick, handleUndismissClick]);

		// @ts-ignore TODO: Temporary solution, will be improved with general improvement for types for details, menu and table
		const bulkActions = useMemo<AlertActionProps[]>(() => {
			const assignableAlerts = selectedItems.filter((alert) => isAlertAssignable(alert));
			const undismissableAlerts = selectedItems.filter((alert) => isAlertUndismissable(alert));

			return [
				{
					id: ALERT_ACTIONS.AssignOrCreateSelectedInvestigation,
					show: areAlertsAssignable(selectedAlert, selectedItems),
					label: t(`actions.bulk.items.${ALERT_ACTIONS.AssignOrCreateSelectedInvestigation}`, { count: assignableAlerts?.length }),
					alerts: assignableAlerts,
					onClick: handleAssignOrCreateSelectedInvestigationClick,
				},
				{
					id: ALERT_ACTIONS.UnassignSelected,
					show: (investigations: Investigation[]) => areAlertsUnassignable(selectedAlert, selectedItems, investigations),
					label: (count: number) => t(`actions.bulk.items.${ALERT_ACTIONS.UnassignSelected}`, { count }),
					alerts: (investigations: Investigation[]) => selectedItems.filter((alert) => isAlertUnassignable(alert, investigations)),
					onClick: handleUnassignSelectedClick,
				},
				{
					id: ALERT_ACTIONS.DismissSelected,
					show: (investigations: Investigation[]) => areAlertsDismissable(selectedAlert, selectedItems, investigations),
					label: (count: number) => t(`actions.bulk.items.${ALERT_ACTIONS.DismissSelected}`, { count }),
					alerts: (investigations: Investigation[]) => selectedItems.filter((alert) => isAlertDismissable(alert, investigations)),
					onClick: handleDismissSelectedClick,
				},
				{
					id: ALERT_ACTIONS.UndismissSelected,
					show: areAlertsUndismissable(selectedAlert, selectedItems),
					label: t(`actions.bulk.items.${ALERT_ACTIONS.UndismissSelected}`, { count: undismissableAlerts?.length }),
					alerts: undismissableAlerts,
					onClick: handleUndismissSelectedClick,
				},
			];
		}, [
			selectedAlert,
			selectedItems,
			t,
			handleAssignOrCreateSelectedInvestigationClick,
			handleUnassignSelectedClick,
			handleDismissSelectedClick,
			handleUndismissSelectedClick,
		]);

		if (!selectedAlert) {
			return null;
		}

		return (
			<>
				<DetailsView
					typename={selectedItemTypename}
					onCloseButtonClick={onCloseButtonClick}
					selectedItem={selectedAlert}
					clearSelectedItemId={clearSelectedAlertId}
					onNextItem={onNextAlert}
					onPreviousItem={onPreviousAlert}
					selectedItems={selectedItems}
					loading={loading}
					isItemSelected={isAlertSelected}
					totalItemsAmount={totalAlertsAmount}
					currentItemIndex={currentAlertIndex}
					selectedItemTimestamp={selectedAlert?.timestamp ?? ''}
					selectedItemId={selectedAlertId}
					selectedItemSeverity={selectedAlert?.severity ?? ''}
					selectedItemConfidence={selectedAlertConfidence as AlertConfidence}
					selectedItemDescription={selectedAlertDescription}
					selectedItemRecommendation={selectedAlertRecommendation}
					loadingAssignedInvestigation={loadingAssignedInvestigation}
					selectedMitreCategory={selectedMitreCategory}
					selectedItemState={selectedAlertState}
					handleAssignOrCreateButtonClick={handleAssignOrCreateButtonClick}
					singleActions={singleActions}
					bulkActions={bulkActions}
					retryRead={retryRead}
					hasError={Boolean(alertError)}
					selectedItemTitle={selectedAlert.title || '-'}
					threatIntelligenceMatches={threatIntelligenceMatches}
					threatIntelligenceDescriptions={threatIntelligenceDescriptions}
				/>

				<AlertsActionDialog
					action={AlertActions.Unassign}
					title={() => t('actionDialog.unassign.title', { count: alertsToUnassign?.length })}
					description={() => t('actionDialog.unassign.description', { count: alertsToUnassign?.length })}
					isOpen={isUnassignDialogOpen}
					onClose={handleUnassignDialogClose}
					alerts={alertsToUnassign}
					submitButtonText={t('actionDialog.unassign.buttons.submit')}
					cancelButtonText={t('actionDialog.unassign.buttons.cancel')}
				/>
			</>
		);
	},
);
