import { TimeRangeFilterStore } from '@/app/_common/stores/time-range-filter-store';

const TIME_RANGE_FILTER_STORAGE_KEY = 'ss/management/action-history/date-filters';
const STATE_OBJECT_NAME = 'actions-history-time-range';

export class ActionHistoryTimeRangeFilterStore extends TimeRangeFilterStore {
	constructor() {
		super(TIME_RANGE_FILTER_STORAGE_KEY, STATE_OBJECT_NAME);
	}
}
