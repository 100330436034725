import { makeAutoObservable } from 'mobx';
import { injectInterface } from '@/app/_common/ioc/inject-interface';

import { QueryResultResultsTableDataStore } from '@/app/investigation-details/_common/stores/query-result-results-table.data-store';

export class QueryResultResultsTableViewStore {
	private dataStore = injectInterface(this, QueryResultResultsTableDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get loading(): boolean {
		return this.dataStore.loading;
	}

	get error() {
		return this.dataStore.error;
	}

	get results() {
		return this.dataStore.results;
	}
}
