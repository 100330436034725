import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '@/translations/namespaces';
import {
	RangeInput,
	TimeLineExplanation,
} from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/alerts-details-timeline/_components';

import styles from './timeline-header.module.scss';

const RANGE_MIN = 0.8;
const RANGE_MAX = 2.5;
const RANGE_STEP = 0.1;

export interface TimelineHeaderProps {
	value: number;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const TimelineHeader: FC<TimelineHeaderProps> = ({ value, onChange }) => {
	const { t } = useTranslation([Namespaces.DetailsView]);

	return (
		<header className={styles.timelineHeader}>
			<h3 className={styles.title}>
				{t('timeLine.title')}
				<TimeLineExplanation />
			</h3>
			<span className={styles.zoom}>
				<span>{t('timeLine.zoom')}</span> <RangeInput min={RANGE_MIN} max={RANGE_MAX} step={RANGE_STEP} value={value} onChange={onChange} />
			</span>
		</header>
	);
};
