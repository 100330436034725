import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import { Button, ButtonSize } from '@/app/_common/_components';
import { AdvancedQueryPageKeyPrefix } from '@/app/_common/constants';
import { AdvancedQueryViewStore, AdvancedQueryChartViewStore } from '@/app/advanced-query/_common/stores';
import { Namespaces } from '@/translations/namespaces';

import styles from './run-query-button.module.scss';

export const RunQueryButton = observer(() => {
	const store = useInstance(AdvancedQueryViewStore);
	const chartViewStore = useInstance(AdvancedQueryChartViewStore);
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });

	const handleRunQueryButtonClick = () => {
		store.runPersistentQuery();
		chartViewStore.fetchChartDataWithAutoInterval();
	};

	const handleCancelQueryButtonClick = () => {
		store.cancelRequest();
		chartViewStore.cancelRequest();
	};

	return (
		<div className={styles.runQueryButtonContainer}>
			{!store.runQueryLoading && (
				<Button
					className={styles.runQueryButton}
					size={ButtonSize.Medium}
					onClick={handleRunQueryButtonClick}
					disabled={!store.runQueryButtonEnabled}
				>
					<DoubleArrowIcon /> {t('runQueryButton')}
				</Button>
			)}
			{store.runQueryLoading && (
				<Button className={styles.cancelQueryButton} fillMode="outline" size={ButtonSize.Medium} onClick={handleCancelQueryButtonClick}>
					{t('cancelQueryButton')}
				</Button>
			)}
		</div>
	);
});
