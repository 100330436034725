import { FiltersInput, ValueOperator } from '@/generated/graphql';

export enum ResponseIntegrationsPropertiesPaths {
	Id = 'node.id',
	Status = 'node.status',
	Vendor = 'node.vendor',
	Product = 'node.productName',
	Name = 'node.name',
	Description = 'node.description',
	StatusReason = 'node.statusReason',
}

export const ResponseIntegrationsListHeadersTranslationKeys: Record<string, string> = {
	[ResponseIntegrationsPropertiesPaths.Status]: 'grid.headers.status',
	[ResponseIntegrationsPropertiesPaths.Vendor]: 'grid.headers.vendor',
	[ResponseIntegrationsPropertiesPaths.Product]: 'grid.headers.product',
	[ResponseIntegrationsPropertiesPaths.Description]: 'grid.headers.description',
	[ResponseIntegrationsPropertiesPaths.Name]: 'grid.headers.name',
};

export enum ResponseIntegrationsStatusTranslationSuffixes {
	HEALTHY = 'healthy',
	NOT_HEALTHY = 'notHealthy',
	NOT_AVAILABLE = 'notAvailable',
	PROVISIONING = 'provisioning',
}

export const RESPONSE_INTEGRATE_DEFAULT_FILTERS_INPUT: FiltersInput = {
	valueFilters: [
		{
			field: 'type',
			operator: ValueOperator.Is,
			value: 'RESPONSE',
		},
	],
};

export enum ResponseIntegrationListActions {
	Actions = 'Actions',
	ViewIntegrationConfiguration = 'ViewIntegrationConfiguration',
}

export const ResponseIntegrationsListActionsTranslationKeys = {
	[ResponseIntegrationListActions.Actions]: 'grid.row.actions.label',
	[ResponseIntegrationListActions.ViewIntegrationConfiguration]: 'grid.row.actions.viewConfiguration',
};
