import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { Notification as KendoNotification, NotificationGroup } from '@progress/kendo-react-notification';
import { Slide } from '@progress/kendo-react-animation';

import { Notification } from '@/app/_common/types';

import styles from './notifications.module.scss';

const position = {
	topRight: { top: '7px', right: '7px', alignItems: 'flex-end' },
};

interface NotificationGroupProps {
	notifications: Notification[];
	onClose?: () => void;
}

export const NotificationsGroup: FC<NotificationGroupProps> = ({ notifications, onClose }) => {
	const { pathname, search } = useLocation();

	return (
		<NotificationGroup className={styles.notifications} style={position.topRight}>
			<Slide>
				{notifications.map(({ id, look, title, content, link, className, children, ...rest }) => (
					<KendoNotification key={id} className={classNames(styles.notification, styles[look], className)} {...rest}>
						<div className={styles.container} data-testid="notification">
							{title && <div className={styles.title}>{title}</div>}
							{(content || link) && (
								<div className={styles.content}>
									{content}
									{link && (
										<Link className={styles.link} to={{ pathname: link.url, search }} state={{ from: pathname }} onClick={onClose}>
											{link.text}
										</Link>
									)}
								</div>
							)}
							{children}
						</div>
					</KendoNotification>
				))}
			</Slide>
		</NotificationGroup>
	);
};
