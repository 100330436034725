import { FC } from 'react';
import classNames from 'classnames';

import { DateInputProps } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { DateRange } from '@mui/icons-material';

import styles from './date-picker-input.module.scss';
import { formatDateTimePickerInputDate, formatDateTimePickerInputUtcDate } from './utils';

interface DatePickerInputProps extends DateInputProps {
	toggleDatePicker: () => void;
	utc?: boolean;
}

export const DatePickerInput: FC<DatePickerInputProps> = (props) => {
	const { toggleDatePicker: toggleDatePicker, value, label, id, valid, utc = false } = props;
	const dateValue = utc ? formatDateTimePickerInputUtcDate(value) : formatDateTimePickerInputDate(value);
	return (
		<div className={styles.wrapper}>
			{label && (
				<Label className={classNames(styles.inputLabel)} editorId={id}>
					{label}
				</Label>
			)}
			<div className={styles.datePickerInput}>
				<Input
					readOnly
					onClick={toggleDatePicker}
					className={styles.inputField}
					valid={valid}
					type="text"
					id={id}
					value={dateValue}
					placeholder="YYYY-MM-DD HH-MM-SS"
				/>
				<span className={styles.dateIcon}>
					<DateRange onClick={toggleDatePicker} />
				</span>
			</div>
		</div>
	);
};
