import { action, computed, makeObservable, reaction } from 'mobx';
import moment from 'moment';

import { QueryGetAlertCountArgs, Query, AlertCount } from '@/generated/graphql';

import { AlertsTimeRangeFilterStore } from '@/app/dashboards/alerts-dashboard/_common/stores';
import { injectInterface } from '@/app/_common/ioc/inject-interface';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { GetAlertCount } from '@/app/_common/graphql/queries';
import { momentToUtcString } from '@/app/_common/utils';

export type AlertCountQuery = Pick<Query, 'getAlertCount'>;

const INITIAL_ALERT_COUNT: AlertCount = {
	assignment: {
		assigned: 0,
		unassigned: 0,
		dismissed: 0,
	},
	severity: {
		critical: 0,
		high: 0,
		medium: 0,
		low: 0,
		unknown: 0,
	},
	detectedBy: {
		aiEngine: 0,
		vendor: 0,
	},
	total: 0,
};

export class AlertsCountDataStore extends GraphqlBaseDataStore<AlertCountQuery, QueryGetAlertCountArgs> {
	private filtersStore = injectInterface(this, AlertsTimeRangeFilterStore);
	private backgroundLoading = false;

	constructor() {
		super();
		makeObservable(this, {
			counts: computed,
			read: action,
		});

		this.read();
	}

	get counts(): AlertCount {
		if (this.data?.getAlertCount.__typename === 'AlertCount') {
			return this.data?.getAlertCount;
		}

		return INITIAL_ALERT_COUNT;
	}

	get isLoading(): boolean {
		return this.backgroundLoading ? false : this.loading;
	}

	read() {
		if (!this.filtersStore.filters?.timeRange?.from || !this.filtersStore.filters?.timeRange?.to) {
			return;
		}

		const variables = {
			tenantId: this.authStore.currentTenantId,
			from: this.filtersStore.filters.timeRange.from,
			to: this.filtersStore.filters.timeRange.to,
		};

		this.query({
			query: GetAlertCount,
			variables,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		});
	}

	readInBackground() {
		this.backgroundLoading = true;

		if (!this.filtersStore.filters?.timeRange?.from) {
			return;
		}

		const variables = {
			tenantId: this.authStore.currentTenantId,
			from: this.filtersStore.filters.timeRange.from,
			to: momentToUtcString(moment()),
		};

		this.query(
			{
				query: GetAlertCount,
				variables,
				fetchPolicy: 'network-only',
			},
			true,
		);

		this.backgroundLoading = false;
	}

	tenantChangeDisposer = reaction(
		() => this.authStore.currentTenantId,
		() => this.read,
	);

	filtersChangeDisposer = reaction(
		() => this.filtersStore.filters?.timeRange,
		() => {
			this.read();
		},
	);

	dispose() {
		this.filtersChangeDisposer();
		this.tenantChangeDisposer();
	}
}
