import { FC } from 'react';
import classNames from 'classnames';

import { ButtonsPopupSettings, DropDownButton as KendoDropdownButton, DropDownButtonProps } from '@progress/kendo-react-buttons';

import styles from './dropdown-button.module.scss';

const DEFAULT_LOOK = 'flat';
const DEFAULT_POPUP_SETTINGS: ButtonsPopupSettings = {
	popupClass: styles.popup,
	anchorAlign: {
		horizontal: 'right',
		vertical: 'bottom',
	},
	popupAlign: {
		horizontal: 'right',
		vertical: 'top',
	},
};

export const DropdownButton: FC<DropDownButtonProps> = ({ fillMode = DEFAULT_LOOK, popupSettings = {}, ...props }) => {
	const popupSettingsWithDefaults = { ...DEFAULT_POPUP_SETTINGS, ...popupSettings, popupClass: classNames(popupSettings.popupClass, styles.popup) };

	return <KendoDropdownButton className={styles.button} fillMode={fillMode} popupSettings={popupSettingsWithDefaults} {...props} />;
};
