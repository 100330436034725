import { AppBar, AppBarSection } from '@progress/kendo-react-layout';

import { UserTile } from '@/app/_common/_components/navbar/_components/user-tile';
import { Help } from '@/app/_common/_components/navbar/_components/help';
import { Breadcrumbs } from '@/app/_common/_components/breadcrumbs';
import { User } from '@/app/_common/interfaces/auth-details';
import { Button } from '../button';
import { PageLogo } from './_components/page-logo';

import styles from './navbar.module.scss';

export interface NavbarProps {
	loggedIn: boolean;
	user: User;
	login: () => void;
}

export const Navbar = (props: NavbarProps) => {
	return (
		<AppBar className={styles.navbar}>
			<AppBarSection>
				<PageLogo />
				<Breadcrumbs />
			</AppBarSection>
			<AppBarSection className="actions">
				<Help />
				{props.loggedIn ? <UserTile user={props.user} /> : <Button onClick={props.login}>Log In</Button>}
			</AppBarSection>
		</AppBar>
	);
};
