import { FC } from 'react';

import styles from './alert-state-tooltip-content.module.scss';

interface AlertStateTooltipContentProps {
	tooltipMessage: string;
}

export const AlertStateTooltipContent: FC<AlertStateTooltipContentProps> = ({ tooltipMessage }) => {
	return (
		<div className={styles.container}>
			<p>{tooltipMessage}</p>
		</div>
	);
};
