import { action, makeObservable } from 'mobx';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { FetchBaseDataStore } from '@/app/_common/fetch';
import {
	AVAILABLE_FIELDS_VALUES_COUNT_TABLE,
	AVAILABLE_FIELDS_VALUES_RARE_TABLE,
	AVAILABLE_FIELDS_VALUES_TOP_TABLE,
	AVAILABLE_FIELDS_VALUES_UNIQUE_COUNT_TABLE,
	AdxEndpoints,
} from '@/app/_common/constants';
import { AvailableField, DataTable, FetchBaseErrorKey, TableRow } from '@/app/_common/types';

const API_URL = process.env.REACT_APP_ADVANCED_QUERY_URL;
const ERROR_KEY = FetchBaseErrorKey.AvailableFields;
const ALL_FIELDS_MAX = 20;
const FIELDS_SECTION_MAX = 10;

interface Results {
	top: AvailableField[];
	rare: AvailableField[];
	uniqValuesCount: number;
	valuesTotalCount: number;
}

const INITIAL_DATA: Results = {
	top: [],
	rare: [],
	uniqValuesCount: 0,
	valuesTotalCount: 0,
};

export class AvailableFieldsValuesDataStore extends FetchBaseDataStore<Results> {
	private authStore = injectInterface({}, AuthStore);
	private cancelSource?: CancelTokenSource;

	constructor() {
		super(ERROR_KEY);

		makeObservable(this, {
			clear: action,
			fetch: action,
		});
	}

	get values(): Results {
		return this.data ?? INITIAL_DATA;
	}

	public clear = () => {
		this.setInitialResults();
	};

	public fetch = (query: string) => {
		this.cancelSource = axios.CancelToken.source();

		const fieldsConfig: AxiosRequestConfig = {
			method: 'POST',
			url: `${API_URL}/${AdxEndpoints.QueryV2}`,
			data: { tenantId: this.authStore.currentTenantId, query },
			cancelToken: this.cancelSource.token,
			transformResponse: [this.formatResults],
			params: { scope: 'available_fields_popup' },
		};

		return this.fetchApiByAxios('', fieldsConfig);
	};

	private formatResults = (response: string): Results => {
		try {
			const parsedResponse: DataTable[] = JSON.parse(response);
			const uniqValuesCountStr = parsedResponse.find(({ TableName }) => TableName === AVAILABLE_FIELDS_VALUES_UNIQUE_COUNT_TABLE)?.Rows?.[0]?.[0];
			const valuesCountStr = parsedResponse.find(({ TableName }) => TableName === AVAILABLE_FIELDS_VALUES_COUNT_TABLE)?.Rows?.[0]?.[0];
			const uniqValuesCount = uniqValuesCountStr ? Number(uniqValuesCountStr) : 0;
			const topCount = parsedResponse.find(({ TableName }) => TableName === AVAILABLE_FIELDS_VALUES_TOP_TABLE)?.Rows?.length ?? 0;

			return parsedResponse.reduce<Results>(
				(result, { TableName, Rows }) => {
					if (TableName === AVAILABLE_FIELDS_VALUES_TOP_TABLE) {
						result.top = this.getTopFields(Rows);
					} else if (TableName === AVAILABLE_FIELDS_VALUES_RARE_TABLE) {
						result.rare = this.getRareFields(Rows, uniqValuesCount, topCount);
					} else if (TableName === AVAILABLE_FIELDS_VALUES_UNIQUE_COUNT_TABLE) {
						result.uniqValuesCount = uniqValuesCount;
					} else if (TableName === AVAILABLE_FIELDS_VALUES_COUNT_TABLE) {
						result.valuesTotalCount = Number(valuesCountStr);
					}
					return result;
				},
				{ ...INITIAL_DATA },
			);
		} catch (error) {
			return INITIAL_DATA;
		}
	};

	public cancelRequest() {
		this.cancelSource?.cancel();
	}

	private getTopFields = (rows: TableRow[]): AvailableField[] => {
		return rows.map(([value, count]) => ({
			value: String(value),
			count: Number(count),
		}));
	};

	private getRareFields = (rows: TableRow[], uniqValuesCount: number, topCount: number): AvailableField[] => {
		if (uniqValuesCount < ALL_FIELDS_MAX && uniqValuesCount > FIELDS_SECTION_MAX) {
			return rows.slice(FIELDS_SECTION_MAX - (uniqValuesCount - topCount), rows.length).map(([value, count]) => ({
				value: String(value),
				count: Number(count),
			}));
		} else if (uniqValuesCount >= ALL_FIELDS_MAX) {
			return rows.map(([value, count]) => ({
				value: String(value),
				count: Number(count),
			}));
		}

		return [];
	};

	dispose = () => {
		this.cancelRequest();
	};
}
