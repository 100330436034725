import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import { NotificationsGroup } from './notifications-group';
import { NotificationsStore } from '@/app/_common/stores';

export const Notifications = observer(() => {
	const store = useInstance(NotificationsStore);

	return <NotificationsGroup notifications={store.notifications} onClose={store.closeAll} />;
});
