import { Severity } from '@/generated/graphql';

export const getSeverityColor = (severity: Severity) => {
	const severityColor = {
		[Severity.Critical]: 'var(--severityCritical)',
		[Severity.High]: 'var(--severityHigh)',
		[Severity.Medium]: 'var(--severityMedium)',
		[Severity.Low]: 'var(--severityLow)',
		[Severity.Unknown]: 'var(--severityUnknown)',
	};

	return severityColor[severity] ?? severityColor[Severity.Unknown];
};
