import { Pager, PagerProps } from '@progress/kendo-react-data-tools';
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from '@mui/icons-material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Page } from '@progress/kendo-react-dropdowns';
import { Namespaces } from '@/translations/namespaces';
import { AdvancedQueryPageKeyPrefix } from '@/app/_common/constants';
import { numberThousandSeparateFormat } from '@/app/advanced-query/_common/utils';
import { FormDropdown } from '@/app/_common/_components/form-components/form-dropdown';
import { InfoTooltip } from '@/app/_common/_components';

import styles from './query-results-table-pager.module.scss';

export interface QueryResultsTablePagerProps extends PagerProps {
	changeRowsPerPage: (take: number) => void;
	updatePage: (page: Page) => void;
	totalCount: number;
}

export const QueryResultsTablePager = ({ totalCount, ...rest }: QueryResultsTablePagerProps) => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });
	const { skip, take, pageSizes, updatePage, changeRowsPerPage, total } = rest;

	if (take === 0) {
		return null;
	}

	const currentPage = Math.floor(skip / take);
	const totalPages = Math.ceil((total || 0) / take);

	const hasPreviousPage = currentPage !== 0;
	const hasNextPage = currentPage + 1 !== totalPages;

	const getOptions = (): string[] => {
		if (pageSizes) {
			return pageSizes.map((pageSize) => {
				return String(pageSize);
			});
		}
		return [];
	};

	const onNextPage = () => {
		if (hasNextPage) {
			updatePage({
				skip: (currentPage + 1) * take,
				take: take,
			});
		}
	};

	const onLastPage = () => {
		if (hasNextPage) {
			updatePage({
				skip: (totalPages - 1) * take,
				take: take,
			});
		}
	};

	const onPreviousPage = () => {
		if (hasPreviousPage) {
			updatePage({
				skip: (currentPage - 1) * take,
				take: take,
			});
		}
	};

	const onFirstPage = () => {
		if (hasPreviousPage) {
			updatePage({
				skip: 0,
				take,
			});
		}
	};

	const getRange = () => {
		if (total < take) {
			return total;
		}

		if (total < take + skip) {
			return `${skip + 1}-${total}`;
		}

		return `${skip + 1}-${take + skip}`;
	};

	return (
		<div className={styles.pagerContainer}>
			<span className={styles.rowsPerPage}>
				<span>Rows per page:</span>
				{/* @ts-ignore */}
				<FormDropdown
					options={getOptions()}
					value={take.toString()}
					onChange={(event) => {
						changeRowsPerPage(parseInt(event.value));
					}}
				/>
			</span>
			<div className={styles.range}>
				{getRange()} of {total}
				{total < totalCount && (
					<InfoTooltip
						id={'resultsPagerId'}
						description={t('results.resultLimit', {
							total: numberThousandSeparateFormat(total),
							totalEvents: numberThousandSeparateFormat(totalCount),
						})}
					/>
				)}
			</div>
			<div className={styles.pagination}>
				<FirstPage className={classNames(styles.navigationIcon, { [styles.disabled]: !hasPreviousPage })} onClick={onFirstPage} />
				<ChevronLeft
					className={classNames(styles.navigationIcon, styles.chevronLeft, { [styles.disabled]: !hasPreviousPage })}
					onClick={onPreviousPage}
				/>
				<Pager {...rest} previousNext={false} info={false} pageSizes={undefined} className={styles.pager} />
				<ChevronRight className={classNames(styles.navigationIcon, styles.chevronRight, { [styles.disabled]: !hasNextPage })} onClick={onNextPage} />
				<LastPage className={classNames(styles.navigationIcon, { [styles.disabled]: !hasNextPage })} onClick={onLastPage} />
			</div>
		</div>
	);
};
