import { FC, ReactNode, useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { AuthStore } from '@/app/_common/stores';
import { createTelemetryService } from './app-insights';

interface TelemetryProviderProps {
	connectionString: string | undefined;
	children?: ReactNode;
}

export const TelemetryProvider: FC<TelemetryProviderProps> = observer(({ connectionString, children }) => {
	const authStore = useInstance(AuthStore);

	useEffect(() => {
		if (connectionString && authStore.userEmail) {
			const ai = createTelemetryService(authStore.userEmail);
			ai.initialize(connectionString);
			ai.start();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectionString, authStore.userEmail]);

	return <>{children}</>;
});
