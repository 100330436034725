import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Namespaces } from '@/translations/namespaces';
import { OpenInModalButton } from '@/app/_common/_components/rich-text-editor/_components/buttons';
import { getFormattedDate } from '@/app/_common/utils';
import { ArtifactType } from '@/generated/graphql';
import { artifactTypeToString } from '@/app/_common/constants';
import {
	AvatarsList,
	RichTextEditor,
	NoteDialog,
	NoteActions,
	ReadOnlyWrapper,
	NoteDraft,
	NoteEditDialog,
	getRichTextEditorTranslations,
} from '@/app/_common/_components';

import styles from './note.module.scss';

interface NoteProps {
	loading?: boolean;
	createdDate: string | Date;
	updatedDate: string | Date;
	type: ArtifactType;
	noteId: string;
	editedNoteId?: string;
	focusedNoteId?: string;
	noteContent: string;
	names: Array<string>;
	loggedUserEmail?: string;
	isNoteWindowOpen: boolean;
	deleteNote: (id: string) => void;
	updateNote: (id: string, updatedNoteContent: string) => void;
	openNoteWindow: (id: string) => void;
	closeNoteWindow: () => void;
	sharePanelNote: (id: string) => void | Promise<void>;
	shareNoteWindow: (id: string) => void | Promise<void>;
	onEdit: (id: string) => void;
	onCancelEdit: () => void;
}

export const Note = observer<NoteProps>(
	({
		loading,
		createdDate,
		updatedDate,
		type,
		noteId,
		editedNoteId,
		noteContent,
		names,
		loggedUserEmail,
		isNoteWindowOpen,
		focusedNoteId,
		updateNote,
		deleteNote,
		openNoteWindow,
		closeNoteWindow,
		sharePanelNote,
		shareNoteWindow,
		onEdit,
		onCancelEdit,
	}) => {
		const [maximized, setMaximized] = useState(false);
		const [draftNoteContent, setDraftNoteContent] = useState<string | undefined>(noteContent);

		const { t } = useTranslation(Namespaces.Notes);
		const noteRef = useRef<HTMLDivElement | null>(null);
		const formattedUpdateDate = getFormattedDate(updatedDate as string);
		const editing = editedNoteId === noteId;

		const actionsDisabled = Boolean(editedNoteId) && editedNoteId !== noteId;

		const handleUpdateNote = useCallback(
			(updatedNoteContent: string) => {
				updateNote(noteId, updatedNoteContent);
			},
			[noteId, updateNote],
		);

		const handleActivateEditNoteMode = useCallback(() => {
			onEdit(noteId);
		}, [noteId, onEdit]);

		const handleDeleteNote = useCallback(() => {
			deleteNote(noteId);
			onCancelEdit();
		}, [deleteNote, noteId, onCancelEdit]);

		const handleOpenNoteWindow = useCallback(() => {
			openNoteWindow(noteId);
			onCancelEdit();
		}, [noteId, onCancelEdit, openNoteWindow]);

		const handleCloseNoteWindow = useCallback(() => {
			closeNoteWindow();
			onCancelEdit();
		}, [closeNoteWindow, onCancelEdit]);

		const handleSharePanelNote = () => {
			sharePanelNote(noteId);
		};

		const handleShareNoteWindow = useCallback(() => {
			shareNoteWindow(noteId);
		}, [noteId, shareNoteWindow]);

		const handleMaximizeClick = useCallback(() => {
			setMaximized(true);
		}, []);

		const handleMinimizeClick = useCallback(() => {
			setMaximized(false);
		}, []);

		const handleSubmitDialog = useCallback(
			async (noteContent: string) => {
				await updateNote(noteId, noteContent);
				setMaximized(false);
			},
			[updateNote, noteId],
		);

		const handleCancelClick = useCallback(() => {
			setMaximized(false);
			setDraftNoteContent(noteContent);
			onCancelEdit();
		}, [noteContent, onCancelEdit]);

		useEffect(() => {
			if (focusedNoteId) {
				scrollToExpanded(focusedNoteId);
			}
		}, [focusedNoteId]);

		const scrollToExpanded = (urlNoteId: string) => {
			if (urlNoteId) {
				const note = document.getElementById(urlNoteId) as HTMLDivElement;
				if (note) {
					note.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
				}
			}
		};

		useEffect(() => {
			return () => {
				onCancelEdit();
			};
		}, [noteContent, onCancelEdit]);

		useEffect(() => {
			setDraftNoteContent(noteContent);
		}, [noteContent]);

		const avatars = useMemo(() => names.map((name) => ({ name, active: name === loggedUserEmail })), [names, loggedUserEmail]);
		const translations = useMemo(() => getRichTextEditorTranslations(Namespaces.Notes), []);

		if (!isNoteWindowOpen && editing) {
			return (
				<NoteDraft title={t('note.headers.edit')} actions={<OpenInModalButton onClick={handleMaximizeClick} />}>
					{maximized && (
						<NoteEditDialog
							title={t('note.headers.edit')}
							noteContent={draftNoteContent}
							loading={loading}
							onSubmit={handleSubmitDialog}
							onCancel={handleCancelClick}
							onMinimize={handleMinimizeClick}
							confirmTitle={t('confirm.edit.title')}
							confirmMessage={t('confirm.edit.message')}
							onChange={setDraftNoteContent}
						/>
					)}
					<RichTextEditor
						onCancel={onCancelEdit}
						editorContent={draftNoteContent}
						onUpdate={handleUpdateNote}
						isItemWindowOpen={isNoteWindowOpen}
						isEditModeActive={true}
						readOnly={true}
						loading={loading}
						onChange={setDraftNoteContent}
						translations={translations}
					/>
				</NoteDraft>
			);
		}

		return (
			<>
				{isNoteWindowOpen && focusedNoteId === noteId && (
					<NoteDialog
						createdDate={createdDate}
						updatedDate={updatedDate}
						type={ArtifactType.Investigation}
						noteContent={noteContent}
						names={names}
						loggedUserEmail={loggedUserEmail}
						deleteNote={handleDeleteNote}
						closeNoteWindow={handleCloseNoteWindow}
						handleUpdateNote={handleUpdateNote}
						activateEditNoteMode={handleActivateEditNoteMode}
						onCancelEdit={onCancelEdit}
						isEditModeActive={editing}
						shareNoteWindow={handleShareNoteWindow}
						loading={loading}
					/>
				)}
				<ReadOnlyWrapper
					className={styles.readOnlyWrapper}
					ref={noteRef}
					id={noteId}
					active={focusedNoteId === noteId}
					title={`${t('note.updated')}: ${formattedUpdateDate}`}
					subTitle={artifactTypeToString.get(type)}
					actions={
						<>
							<AvatarsList className={styles.avatars} items={avatars} />
							<NoteActions
								disabled={actionsDisabled}
								shareNote={handleSharePanelNote}
								deleteNote={handleDeleteNote}
								openNoteWindow={handleOpenNoteWindow}
								activateEditNoteMode={handleActivateEditNoteMode}
							/>
						</>
					}
				>
					<RichTextEditor
						className={styles.textEditor}
						editorContent={noteContent}
						isItemWindowOpen={isNoteWindowOpen}
						isEditModeActive={false}
						readOnly={true}
						translations={translations}
					/>
				</ReadOnlyWrapper>
			</>
		);
	},
);
