import { FC } from 'react';

import { LoadingPageLayout } from '@/app/_common/_components/empty-page-layout/loading-page-layout';
import { LoadingSpinner } from '@/app/_common/_components/loading-spinner/loading-spinner';
import { RootPaths } from '@/app/_common/navigation/root-paths';

export interface RouterLoaderProps {
	pathname: string;
}

export const RouterLoader: FC<RouterLoaderProps> = ({ pathname }) => {
	switch (pathname) {
		case RootPaths.DASHBOARDS:
		case RootPaths.ALERTS_DASHBOARDS:
		case RootPaths.INVESTIGATIONS:
		case RootPaths.INVESTIGATION_DETAILS:
		case RootPaths.ADVANCED_QUERY:
		case RootPaths.COLLECTORS:
		case RootPaths.SUBSCRIPTION:
		case RootPaths.TOOLS:
		case RootPaths.MANAGEMENT: {
			return <LoadingSpinner />;
		}
		default:
			return <LoadingPageLayout />;
	}
};
