import { Alert, AlertCount, AlertState } from '@/generated/graphql';
import { AlertTypename } from '@/app/_common/constants';
import { readAlertStateFragmentById } from '@/app/_common/utils';
import { SetAlertsStateUpdateCacheFn } from '@/app/_features/alerts-actions/_common/stores/_common/types';
import { setAlertStateListAlertsUpdate } from '@/app/dashboards/alerts-dashboard/_common/utils/cache-updates';

export const updateCacheDismissAlertsDashboard: SetAlertsStateUpdateCacheFn = (cache, { data }) => {
	if (!data?.setAlertsState || data?.setAlertsState.__typename !== AlertTypename.Alerts) {
		return;
	}

	const alerts = data?.setAlertsState?.alerts || [];
	const alertsIds = alerts.map(({ id }) => id);

	if (!alertsIds.length) {
		return;
	}

	const oldAlerts = alertsIds.reduce<Alert[]>((result, id) => {
		const alert = readAlertStateFragmentById(cache, id);

		if (alert) {
			result.push(alert);
		}

		return result;
	}, []);

	const assignedCount = oldAlerts.filter(({ state }) => state?.alertState === AlertState.Assigned).length;
	const unassignedCount = oldAlerts.filter(({ state }) => !state?.alertState).length;

	cache.modify({
		fields: {
			getAlertCount(existingAlertCount: AlertCount) {
				return {
					...(existingAlertCount ?? {}),
					assignment: {
						...existingAlertCount.assignment,
						assigned: existingAlertCount.assignment.assigned - assignedCount,
						unassigned: existingAlertCount.assignment.unassigned - unassignedCount,
						dismissed: existingAlertCount.assignment.dismissed + alertsIds.length,
					},
				};
			},
			listAlerts: setAlertStateListAlertsUpdate(cache, alerts),
		},
	});
};
