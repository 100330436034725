import i18n from '@/translations/i18n';
import { makeAutoObservable, reaction } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';

import { NoteTypename, URL_STATE_SHOW_NOTES_PANEL, URL_STATE_NOTE_ID } from '@/app/_common/constants';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { ArtifactType, NoteEdge, NotesConnection, MutationCreateNoteArgs } from '@/generated/graphql';
import { NotificationsStore, RouterStore, UrlStore } from '@/app/_common/stores';
import { Namespaces } from '@/translations/namespaces';
import { InvestigationDetailsViewStore } from '@/app/investigation-details/_common/stores';
import { InvestigationNotesListDataStore } from './investigation-notes-list.data-store';
import { InvestigationNotesCreateDataStore } from './investigation-notes-create.data-store';
import { InvestigationNotesUpdateDataStore } from './investigation-notes-update.data-store';
import { InvestigationNotesDeleteDataStore } from './investigation-notes-delete.data-store';
import { getGraphQLError } from '@/app/_common/graphql/graphql-error-handler';

const INVESTIGATION_NOTES_PANEL_KEY = 'ls/investigation-details/notes_panel';
const DEFAULT_PANEL_WIDTH = '440px';

interface State {
	panelWidth: string;
	showPanel: boolean;
	isNoteWindowOpen: boolean;
	focusedNoteId?: string;
	editedNoteId?: string;
}

export type CreateNote = Omit<MutationCreateNoteArgs, 'tenantId'>;

export class InvestigationNotesViewStore {
	private investigationNotesListDataStore = injectInterface(this, InvestigationNotesListDataStore);
	private investigationNotesCreateDataStore = injectInterface(this, InvestigationNotesCreateDataStore);
	private investigationNotesUpdateDataStore = injectInterface(this, InvestigationNotesUpdateDataStore);
	private investigationNotesDeleteDataStore = injectInterface(this, InvestigationNotesDeleteDataStore);
	private investigationDetailsViewStore = injectInterface(this, InvestigationDetailsViewStore);
	private notificationsStore = injectInterface(this, NotificationsStore);
	private urlStore = injectInterface(this, UrlStore);
	private routerStore = injectInterface(this, RouterStore);

	private state: State = {
		panelWidth: DEFAULT_PANEL_WIDTH,
		showPanel: false,
		isNoteWindowOpen: false,
		focusedNoteId: undefined,
		editedNoteId: undefined,
	};

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
		makePersistable(this.state, {
			name: INVESTIGATION_NOTES_PANEL_KEY,
			properties: ['panelWidth'],
			storage: window.localStorage,
		});
		this.initializeUrlStore();
	}

	get notes(): NoteEdge[] {
		return (this.investigationNotesListDataStore.data?.listNotes as NotesConnection)?.edges
			?.slice()
			.sort((a, b) => Date.parse(a.node.last_updated) - Date.parse(b.node.last_updated));
	}

	get loading(): boolean {
		return this.investigationNotesListDataStore.loading;
	}

	get createLoading(): boolean {
		return this.investigationNotesCreateDataStore.loading;
	}

	get updateLoading(): boolean {
		return this.investigationNotesUpdateDataStore.loading;
	}

	get deleteLoading(): boolean {
		return this.investigationNotesDeleteDataStore.loading;
	}

	get panelWidth() {
		return this.state.panelWidth;
	}

	get showPanel() {
		return this.state.showPanel;
	}

	get isNoteWindowOpen() {
		return this.state.isNoteWindowOpen;
	}

	get focusedNoteId() {
		return this.state.focusedNoteId;
	}

	get editedNoteId() {
		return this.state.editedNoteId;
	}

	setPanelWidth = (newWidth?: string) => {
		if (!newWidth) {
			return;
		}
		this.state.panelWidth = newWidth;
	};

	setEditedNoteId = (id: string) => {
		this.state.editedNoteId = id;
	};

	clearEditedNoteId = () => {
		this.state.editedNoteId = undefined;
	};

	togglePanel = () => {
		this.state.showPanel = !this.state.showPanel;
		this.updateUrlStore();
	};

	read = (investigationId?: string) => {
		if (!investigationId) {
			return;
		}
		this.investigationNotesListDataStore.read(investigationId);
	};

	deleteNote = async (noteId: string) => {
		if (!noteId || !this.investigationDetailsViewStore.investigationId) {
			return;
		}

		const deleteNoteRequest = {
			artifactId: this.investigationDetailsViewStore.investigationId,
			artifactType: ArtifactType.Investigation,
			id: noteId,
		};

		const responseData = await this.investigationNotesDeleteDataStore.delete({ deleteNoteRequest });

		if (responseData?.data?.deleteNote.__typename === 'Note') {
			this.notificationsStore.openSuccess({
				title: i18n.t('notes.deleteNote.success.title', { ns: Namespaces.Notifications }),
				content: i18n.t('notes.deleteNote.success.content', { ns: Namespaces.Notifications, id: responseData?.data?.deleteNote.id }),
			});
		} else if (responseData?.data?.deleteNote.__typename === 'Error') {
			this.notificationsStore.openError({
				title: i18n.t('notes.deleteNote.error.title', { ns: Namespaces.Notifications }),
				content: responseData.data.deleteNote.message,
			});
		}
	};

	updateNote = async (noteId: string, updatedNoteContent: string) => {
		if (!noteId || !this.investigationDetailsViewStore.investigationId) {
			return;
		}

		const updateNoteRequest = {
			artifactId: this.investigationDetailsViewStore.investigationId,
			artifactType: ArtifactType.Investigation,
			id: noteId,
			noteContent: updatedNoteContent,
		};

		const response = await this.investigationNotesUpdateDataStore.update({ updateNote: updateNoteRequest });

		const responseData = response?.data?.updateNote;

		if (responseData?.__typename === 'Note') {
			this.notificationsStore.openSuccess({
				title: i18n.t('notes.updateNote.success.title', { ns: Namespaces.Notifications }),
				content: i18n.t('notes.updateNote.success.content', { ns: Namespaces.Notifications, id: responseData?.id }),
			});
			this.clearEditedNoteId();
		} else if (responseData?.__typename === 'Error') {
			this.notificationsStore.openError({
				title: i18n.t('notes.updateNote.error.title', { ns: Namespaces.Notifications }),
				content: responseData.message,
			});
		}
	};

	submitCreateNote = async (data: CreateNote['createNoteRequest']) => {
		const response = await this.investigationNotesCreateDataStore.create({
			createNoteRequest: data,
		});

		const responseData = response?.data?.createNote;

		if (responseData?.__typename === NoteTypename.Note) {
			this.notificationsStore.openSuccess({
				title: i18n.t('notes.createNote.success.title', { ns: Namespaces.Notifications }),
				content: i18n.t('notes.createNote.success.content', { ns: Namespaces.Notifications, id: responseData?.id }),
			});
		} else if (responseData?.__typename === NoteTypename.Error) {
			const error = getGraphQLError(i18n.t('notes.createNote.error.title', { ns: Namespaces.Notifications }), responseData);
			this.notificationsStore.openError(error);
		}
	};

	openNoteWindow = (noteId: string) => {
		this.state.isNoteWindowOpen = true;
		this.state.focusedNoteId = noteId;
		this.urlStore.updateStore({
			[URL_STATE_NOTE_ID]: noteId,
		});
	};

	closeNoteWindow = () => {
		this.state.isNoteWindowOpen = false;
		this.state.focusedNoteId = undefined;
		this.urlStore.clearUrl(URL_STATE_NOTE_ID);
	};

	shareNoteWindow = async (noteId: string): Promise<void> => {
		await navigator.clipboard.writeText(window.location.href);
		this.urlStore.updateStore({
			[URL_STATE_NOTE_ID]: noteId,
		});
	};

	sharePanelNote = async (noteId: string): Promise<void> => {
		const noteURL = this.urlStore.getEncodedURLWithNewState({
			[URL_STATE_NOTE_ID]: noteId,
			[URL_STATE_SHOW_NOTES_PANEL]: true,
		});
		await navigator.clipboard.writeText(noteURL);
	};

	clearUrlStateDisposer = reaction(
		() => this.routerStore.location,
		(nextLocation, prevLocation) => {
			const hasPathnameChanged = nextLocation.pathname !== prevLocation.pathname;

			if (hasPathnameChanged) {
				this.urlStore.clearUrl(URL_STATE_SHOW_NOTES_PANEL);
				this.urlStore.clearUrl(URL_STATE_NOTE_ID);
			}
		},
	);

	private updateUrlStore = () => {
		if (this.state.showPanel) {
			this.urlStore.updateStore({ [URL_STATE_SHOW_NOTES_PANEL]: true });
		} else {
			this.urlStore.clearUrl(URL_STATE_SHOW_NOTES_PANEL);
			this.urlStore.clearUrl(URL_STATE_NOTE_ID);
			this.state.focusedNoteId = undefined;
		}
	};

	private initializeUrlStore = () => {
		const urlShowNotesPanel = this.urlStore.getStateVariables(URL_STATE_SHOW_NOTES_PANEL) as boolean;
		const focusedNoteId = this.urlStore.getStateVariables(URL_STATE_NOTE_ID) as string;

		this.state.showPanel = urlShowNotesPanel;

		if (focusedNoteId) {
			this.state.focusedNoteId = focusedNoteId;
			this.state.isNoteWindowOpen = true;
		}
	};

	dispose() {
		stopPersisting(this);
		this.clearUrlStateDisposer();
	}
}
