import { makeAutoObservable } from 'mobx';
import { Location, History } from 'history';
import { parse as queryParse, stringify as queryStringify } from 'query-string';

import { encodeQueryParameters, decodeQueryString } from '@/app/_common/utils';
import { To } from 'react-router-dom';

export class RouterStore {
	private _location!: Location;
	private _history!: History;

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	updateLocation(newState: Location) {
		this._location = newState;
	}

	updateHistory(history: History) {
		this._history = history;
	}

	pushEncoded(state: Record<string, string>) {
		const encodedState = encodeQueryParameters(state);
		const search = queryStringify({ s: encodedState });
		this.push({ search });
	}

	getEncoded(state: Record<string, string>, url: string): string {
		const encodedState = encodeQueryParameters(state);
		const search = queryStringify({ s: encodedState });
		return url + '?' + search;
	}

	push(arg1: To, arg2?: Record<string, string>) {
		if (typeof arg1 === 'string') {
			this._history?.push(arg1, arg2);
		} else {
			this._history?.push(arg1);
		}
	}

	replace(to: To) {
		this._history.replace(to);
	}

	go(n: number) {
		this._history.go(n);
	}

	goBack() {
		this._history.back();
	}

	goForward() {
		this._history.forward();
	}

	get location() {
		return this._location;
	}

	get currentPath() {
		return this.location.pathname;
	}

	get search() {
		return this._location?.search;
	}

	get queryParams() {
		const hashedSearch = queryParse(this.search);

		if (typeof hashedSearch?.s === 'string') {
			return decodeQueryString(hashedSearch.s);
		}

		return {};
	}

	get history() {
		return this._history;
	}
}
