import { DocumentNode } from 'graphql';
import {
	CreateAndAssignAlertsUpdateCacheFn,
	SetAlertsOnInvestigationUpdateCacheFn,
	SetAlertsStateUpdateCacheFn,
	UnassignAlertsDashboardUpdateCacheFn,
} from '@/app/_features/alerts-actions/_common/stores/_common/types';

export enum AlertActions {
	Assign = 'assign',
	Unassign = 'unassign',
	Dismiss = 'dismiss',
	Undismiss = 'undismiss',
}

// IMPORTANT: fragment name should be one of the following AssignAlertsFragment, CreateAndAssignAlertsFragment, UnassignAlertsFragment, DismissAlertsFragment or UndismissAlertsFragment.
interface MutationConfigItem<T> {
	fragment: DocumentNode;
	updateCache: T;
}

export interface MutationsConfig {
	assignAlerts: MutationConfigItem<SetAlertsOnInvestigationUpdateCacheFn>;
	createAndAssignAlerts: MutationConfigItem<CreateAndAssignAlertsUpdateCacheFn>;
	unassignAlerts: MutationConfigItem<UnassignAlertsDashboardUpdateCacheFn>;
	dismissAlerts: MutationConfigItem<SetAlertsStateUpdateCacheFn>;
	undismissAlerts: MutationConfigItem<SetAlertsStateUpdateCacheFn>;
}

export type IsRowSelected = (id: string) => boolean;
