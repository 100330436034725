import { ReactNode } from 'react';

import { Investigation, State as AlertStateData, Alert, InvestigationSummary, Maybe } from '@/generated/graphql';
import { AlertStateOptions, DescriptionItem } from '@/app/_common/constants';
import { DataGridDataItem } from './types';

export interface AlertDetailsInvestigationSummary {
	id: string;
	name: string;
	clickable?: boolean;
}

export interface AlertDetailsInvestigationSummary {
	id: string;
	name: string;
	clickable?: boolean;
}

export type AlertsDetailsDescriptionGridEdge = DataGridDataItem<DescriptionItem>;

export type { AlertStateData };

export interface AlertStateInfoDetails {
	state: AlertStateOptions;
	label: string;
	investigationId?: string;
	tooltipInfo?: string;
}

export enum ALERT_ACTIONS {
	AssignOrCreateInvestigation = 'assignOrCreateInvestigation',
	AssignOrCreateSelectedInvestigation = 'assignOrCreateSelectedInvestigation',
	Unassign = 'unassign',
	UnassignSelected = 'unassignSelected',
	Dismiss = 'dismiss',
	DismissSelected = 'dismissSelected',
	Undismiss = 'undismiss',
	UndismissSelected = 'undismissSelected',
	CopyValue = 'copyValue',
	FilterByValue = 'filterByValue',
	OpenInvestigation = 'openInvestigation',
	ResetFilters = 'resetFilters',
	ColumnToggle = 'columnToggle',
	ViewDetails = 'viewDetails',
}

export interface AlertActionProps {
	id: ALERT_ACTIONS;
	label: string | ((count: number, closeContextMenu?: () => void) => ReactNode) | ReactNode;
	show?: boolean | ((investigations: Investigation[]) => boolean);
	disabled?: boolean | ((investigations: Investigation[]) => boolean);
	disabledTooltip?: string;
	onClick?: (alerts: Alert[]) => void;
	alerts?: Alert[] | ((investigations: Investigation[]) => Alert[]);
	className?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Evidence = Record<string, any>; // evidence can be of any structure

type InvestigationSummaryExtended = InvestigationSummary & Partial<Pick<Investigation, 'status' | 'name'>>;

export interface AlertWithInvestigationSummaryExtended extends Alert {
	investigationSummary?: Maybe<InvestigationSummaryExtended>;
}
