import { makeAutoObservable } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';

const ADVANCED_QUERY_CHART_SETTINGS_PERSISTABLE_KEY = 'ls/advanced-query-chart/settings';

const INITIAL_STATE: State = {
	isStackedBarChart: undefined,
};

interface State {
	isStackedBarChart?: boolean;
}

export class AdvancedQueryChartSettingsViewStore {
	private state: State = INITIAL_STATE;

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		makePersistable(this.state, {
			name: ADVANCED_QUERY_CHART_SETTINGS_PERSISTABLE_KEY,
			properties: ['isStackedBarChart'],
			storage: window.localStorage,
		});
	}

	get isStackedBarChart() {
		return this.state.isStackedBarChart;
	}

	setIsStackedBarChart(isStackedBarChart: boolean) {
		this.state.isStackedBarChart = isStackedBarChart;
	}

	dispose = () => {
		stopPersisting(this.state);
	};
}
