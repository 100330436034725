import { useState, useRef, RefObject, useCallback, useMemo, useEffect } from 'react';

import ResizeObserver from 'resize-observer-polyfill';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

import { Grid, GridColumnProps, GridColumnResizeEvent } from '@progress/kendo-react-grid';

import { migrateToVersioned, migrateToNewestVersion } from '@/app/_common/utils';
import { StorageType } from '@/app/_common/types';

type Columns = Record<string, string>;
type VisibleColumns = Record<string, boolean>;
export type UseDataGridWidthReturn<T> = {
	widths: T;
	setWidths: (event: GridColumnResizeEvent) => void;
	resetWidths: () => void;
	fitWidths: () => void;
	ref: RefObject<Grid>;
	measuredRef: (node: HTMLDivElement) => void;
	autoResize: () => boolean;
};

export const SCROLLBAR_WIDTH = 12;
export const GRID_BORDER_WIDTH = 2;
export const ACTIONS_COLUMN_WIDTH = 70;

const calculateLockedColumnsWidth = (columns: GridColumnProps[]) => {
	return (
		columns.reduce((result, item) => {
			if (item.locked && typeof item.width === 'string') {
				return result + parseInt(item.width.replace(/px/, ''));
			}
			return result;
		}, 0) || 0
	);
};

const getResizableColumns = (columns: GridColumnProps[] = [], visibleColumns?: VisibleColumns) => {
	return visibleColumns
		? Object.keys(visibleColumns).filter((column) => visibleColumns[column])
		: columns.filter((item) => !item.locked).map((column) => column.field);
};

function calculateColumnsWidth<T extends Columns>(
	columns: GridColumnProps[],
	gridWidth: number,
	initialValue: T,
	visibleColumns?: VisibleColumns,
	initialValueAfterReset?: boolean,
	totalColumnWidths?: number,
) {
	columns = columns || [];
	const lockedWidth = calculateLockedColumnsWidth(columns);
	const resizableColumns = getResizableColumns(columns, visibleColumns);
	const resizableColumnsCount = resizableColumns.length || 1;
	const remainingWidth = gridWidth - SCROLLBAR_WIDTH - lockedWidth - GRID_BORDER_WIDTH;
	const columnWidth = Math.round(remainingWidth / resizableColumnsCount);

	return resizableColumns.reduce(
		(result, column, index) => {
			let fixPx = 0;

			if (index === resizableColumns.length - 1) {
				fixPx = gridWidth - lockedWidth - SCROLLBAR_WIDTH - GRID_BORDER_WIDTH - columnWidth * resizableColumnsCount;
			}

			if (initialValueAfterReset && column && totalColumnWidths && totalColumnWidths > gridWidth) {
				return result;
			}
			if (column && result) {
				(result as Columns)[column] = `${Math.round(columnWidth + fixPx)}px`;
			}

			return result;
		},
		initialValueAfterReset ? { ...initialValue } : initialValue,
	);
}

type StorageDetails = {
	storage: StorageType;
	key: string;
	version: string;
	nestedObjectKey: string;
};

export function useDataGridWidth<T extends Columns>(
	storageDetails: StorageDetails,
	initialValue: T,
	visibleColumns: VisibleColumns = {},
	initialValueAfterReset?: boolean,
): UseDataGridWidthReturn<T> {
	const prevWidth = useRef<number>();
	const initialColumns = useRef<GridColumnProps[]>([]);

	const { storage, key, version, nestedObjectKey } = storageDetails;

	const [storedValue, setStoredValue] = useState<T>(() => {
		try {
			migrateToVersioned({ key, type: storage }, version, nestedObjectKey);
			migrateToNewestVersion(key, version);

			const item = key ? window[storage].getItem(key) : null;

			return (item && JSON.parse(item)[nestedObjectKey]) || initialValue;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('useDataGridWidth setStoredValue', error);

			return initialValue;
		}
	});

	const saveDataInStorage = useCallback(
		(data: T) => {
			window[storage].setItem(key, JSON.stringify({ [nestedObjectKey]: data, version }));
		},
		[storage, key, version, nestedObjectKey],
	);

	const gridRef = useRef<Grid>(null);
	const measuredRef = useRef<HTMLDivElement | null>(null);
	const fitColumnWidthsRef = useRef(
		_debounce(() => {
			const grid = gridRef?.current;
			const container = measuredRef?.current;

			if (grid && container && !window[storage].getItem(key)) {
				visibleColumns = grid?.columns.reduce((acc, curr) => ({ ...acc, [_get(curr, 'field', '')]: _get(curr, 'show', '') }), {}) as VisibleColumns;

				const displayedColumns = Object.keys(visibleColumns).filter((key) => visibleColumns[key]);

				const totalColumnWidths = displayedColumns.reduce((acc, curr) => acc + parseInt(initialValue[curr], 10), ACTIONS_COLUMN_WIDTH);
				if (container.getBoundingClientRect().width <= 0) {
					return;
				}
				const columnWidths = calculateColumnsWidth(
					grid.columns,
					container.getBoundingClientRect().width,
					initialValue,
					visibleColumns,
					initialValueAfterReset,
					totalColumnWidths,
				);

				setStoredValue({ ...columnWidths });
			}

			prevWidth.current = measuredRef?.current?.getBoundingClientRect()?.width;
		}, 100),
	);

	const fitColumnWidths = useCallback(() => {
		const grid = gridRef?.current;
		const container = measuredRef?.current;

		if (grid && container && !window[storage].getItem(key)) {
			const displayedColumns = Object.keys(visibleColumns).filter((key) => visibleColumns[key]);

			const totalColumnWidths = displayedColumns.reduce((acc, curr) => acc + parseInt(initialValue[curr], 10), ACTIONS_COLUMN_WIDTH);

			const columnWidths = calculateColumnsWidth(
				grid.columns,
				container.getBoundingClientRect().width,
				initialValue,
				visibleColumns,
				initialValueAfterReset,
				totalColumnWidths,
			);

			setStoredValue({ ...columnWidths });
		}
	}, [initialValue, visibleColumns, initialValueAfterReset, storage, key]);

	const measuredRefCallback = useCallback((node: HTMLDivElement) => {
		if (node !== null) {
			measuredRef.current = node;

			const width = node.getBoundingClientRect()?.width;
			const grid = gridRef?.current;

			fitColumnWidths();

			prevWidth.current = width;

			if (grid && width) {
				const columns = gridRef.current?.columns || [];
				initialColumns.current = _cloneDeep(columns);
			}
		}
	}, []); //eslint-disable-line react-hooks/exhaustive-deps

	const resizeHandler = useCallback((entries: ResizeObserverEntry[]) => {
		const contentBoxSize = entries.reduce((_, entry) => {
			return Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
		}, {} as Record<string, string | number | null>);

		if (prevWidth?.current !== contentBoxSize?.inlineSize) {
			fitColumnWidthsRef.current();
		}

		prevWidth.current = measuredRef?.current?.getBoundingClientRect().width;
	}, []);

	useEffect(() => {
		let resizeObserver: ResizeObserver;

		if (measuredRef?.current) {
			resizeObserver = new ResizeObserver(resizeHandler);
			resizeObserver.observe(measuredRef.current);
		}

		return () => {
			if (resizeObserver) {
				resizeObserver.disconnect();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resizeHandler, measuredRef?.current, visibleColumns]);

	useEffect(() => {
		const onResize = () => {
			fitColumnWidthsRef.current();
		};

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const setColumnWidth = useMemo(
		() =>
			_debounce((event: GridColumnResizeEvent) => {
				try {
					const shouldRecalculate = Object.values(storedValue).some((value) => value === 'auto');
					let data = { ...storedValue };

					if (shouldRecalculate) {
						const grid = gridRef?.current;
						const container = measuredRef?.current;

						if (grid && container) {
							data = calculateColumnsWidth(grid.columns, container.getBoundingClientRect().width, initialValue, visibleColumns);
						}
					}

					if (event.targetColumnId) {
						const newWidth = event.newWidth || event.columns[event.index].width;
						(data as Columns)[event.targetColumnId] = `${Math.round(Number(newWidth))}px`;
					}

					setStoredValue(data);
					saveDataInStorage(data);
				} catch (error) {
					// eslint-disable-next-line no-console
					console.log(error);
				}
			}, 500),
		[initialValue, storedValue, visibleColumns, saveDataInStorage],
	);

	const resetColumnWidths = () => {
		try {
			const node = measuredRef?.current;

			if (!node) {
				return;
			}

			const width = node.getBoundingClientRect()?.width;
			const storedValueKeys = Object.keys(storedValue);
			const totalColumnWidths = storedValueKeys.reduce((acc, curr) => acc + parseInt(initialValue[curr], 10), ACTIONS_COLUMN_WIDTH);
			const columnWidths = calculateColumnsWidth(initialColumns.current, width, initialValue, undefined, initialValueAfterReset, totalColumnWidths);

			setStoredValue({ ...columnWidths });

			window[storage].removeItem(key);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const columnNeedAutoResize = (): boolean => {
		const node = measuredRef?.current;
		if (!node) {
			return false;
		}
		const item = window[storage].getItem(key);
		const gridWidth = node.getBoundingClientRect()?.width;
		const storedValueKeys = Object.keys(storedValue);
		const totalColumnWidths = storedValueKeys.reduce((acc, curr) => acc + parseInt(initialValue[curr], 10), ACTIONS_COLUMN_WIDTH);

		return gridWidth > totalColumnWidths && item === null;
	};

	return {
		widths: storedValue,
		setWidths: setColumnWidth,
		resetWidths: resetColumnWidths,
		fitWidths: fitColumnWidths,
		ref: gridRef,
		measuredRef: measuredRefCallback,
		autoResize: columnNeedAutoResize,
	};
}
