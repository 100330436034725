import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { computed, makeObservable } from 'mobx';
import { InvestigationDetailsDataStore } from './investigation-details.data-store';
import { QueryResultsDataStore } from './query-results.data-store';

export class QueryResultsViewStore {
	private queryResultsDataStore = injectInterface(this, QueryResultsDataStore);
	private investigationDetailsDataStore = injectInterface(this, InvestigationDetailsDataStore);

	private state = {
		lastRemovedId: '',
	};

	constructor() {
		makeObservable(this, {
			queryResults: computed,
			hasNextPage: computed,
		});

		this.read();
	}

	get queryResults() {
		return this.queryResultsDataStore.queryResults;
	}

	get loading() {
		return this.queryResultsDataStore.loading;
	}

	get selectedInvestigationId(): string | undefined {
		if (this.investigationDetailsDataStore.data?.getInvestigation.__typename === 'Investigation') {
			return this.investigationDetailsDataStore.data?.getInvestigation.id;
		}
		return undefined;
	}

	get lastRemovedId() {
		return this.state.lastRemovedId;
	}

	get hasNextPage() {
		return this.queryResultsDataStore.hasNextPage;
	}

	setRemovedId(id: string) {
		this.state.lastRemovedId = id;
	}

	read = () => {
		this.queryResultsDataStore.read(this.selectedInvestigationId);
	};

	readMore = () => {
		return this.queryResultsDataStore.readMore(this.selectedInvestigationId);
	};
}
