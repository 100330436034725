import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';

import { InvestigationAlertSummary, Severity } from '@/generated/graphql';
import { sortByOrder } from '@/app/_common/utils';
import { AssignedAlertPropertiesPaths, SortDirection } from '@/app/_common/constants';
import { CONFIDENCE_PRIORITY_WITH_UNKNOWN } from '@/app/_features/alerts/_common/constants';
import { DataGridDataItem } from '@/app/_common/types';

export type InvestigationAlertSummaryWithSelected = DataGridDataItem<InvestigationAlertSummary>;

export const sortAssignedAlerts = (data: InvestigationAlertSummaryWithSelected[], sort: SortDescriptor[]) => {
	const sortOptions = sort[0];

	if (sortOptions?.field === AssignedAlertPropertiesPaths.Severity) {
		return sortByOrder<InvestigationAlertSummaryWithSelected>(
			data,
			AssignedAlertPropertiesPaths.Severity,
			[Severity.Critical, Severity.High, Severity.Medium, Severity.Low, Severity.Unknown],
			sortOptions?.dir as SortDirection,
		);
	} else if (sortOptions?.field === AssignedAlertPropertiesPaths.Confidence) {
		return sortByOrder<InvestigationAlertSummaryWithSelected>(
			data,
			AssignedAlertPropertiesPaths.Confidence,
			CONFIDENCE_PRIORITY_WITH_UNKNOWN,
			sortOptions?.dir as SortDirection,
		);
	} else if (sortOptions?.field === AssignedAlertPropertiesPaths.Source || sortOptions?.field === AssignedAlertPropertiesPaths.Destination) {
		return _orderBy(
			data,
			(item) => {
				const value = _get(item, sortOptions?.field);
				if (Array.isArray(value) && value.length === 1) {
					return value[0].toLowerCase();
				}

				// Put "Multiple" cells at the end of the table
				return '';
			},
			sortOptions?.dir,
		);
	}

	return orderBy<InvestigationAlertSummaryWithSelected>(data, sort);
};
