import { MouseEvent, useCallback, useState } from 'react';
import { isExternalURL } from '@/app/_common/utils';

export const useExternalLink = () => {
	const [externalLink, setExternalLink] = useState('');

	const updateExternalLink = useCallback((event?: MouseEvent) => {
		if (!event) {
			return clearExternalLink();
		}

		const link = (event?.target as HTMLElement)?.closest('a');
		if (link && isExternalURL(link.href)) {
			event?.preventDefault();
			setExternalLink(link.href);
		}
	}, []);

	const clearExternalLink = () => {
		setExternalLink('');
	};

	return { externalLink, updateExternalLink };
};
