import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import _get from 'lodash/get';
import classNames from 'classnames';

import { FilterOption, Filters } from '@/app/_common/types';
import { Divider } from '@/app/_common/_components';
import { AlertPropertiesPaths, AssignmentActions, AssignmentActionsTranslationKeys } from '@/app/_common/constants';
import { AlertEdge, AlertState, InvestigationAlertSummary } from '@/generated/graphql';
import { Namespaces } from '@/translations/namespaces';
import { buttonize, extractResultsTableItemId } from '@/app/_common/utils';

import {
	AlertUpdateStateAction,
	CopyValueAction,
	FiltersAction,
	OpenInvestigationAction,
	OpenResultsTableAlertDetailsAction,
	AssignToInvestigationAction,
} from './_components';

import { RootPaths } from '@/app/_common/navigation';
import { getDetailsViewRowId } from '@/app/_common/_components/details-view/utils';

import styles from './alerts-widget-context-menu.module.scss';

interface AlertsWidgetContextMenuProps {
	dataItem: AlertEdge | InvestigationAlertSummary;
	customValue?: string | number;
	title?: string;
	field?: string;
	handleAddEqualFilter?: (field: string, values: Filters) => void;
	handleAddNotEqualFilter?: (field: string, values: Filters) => void;
	filterLabel?: ReactNode;
	activeEqualFilters?: Filters;
	customCopyValue?: string;
	showCopy?: boolean;
	showFilter?: boolean;
	closeContextMenu?: () => void;
	filterOptions?: FilterOption[];
	investigationId?: string;
	handleDismissAlert?: (alertId: string) => void;
	handleDismissAlerts?: () => void;
	handleUndismissedAlert?: (alertId: string) => void;
	handleUndismissedAlerts?: () => void;
	dismissAlertsCount?: number;
	undismissAlertsCount?: number;
	state?: AlertState;
	isSelected?: boolean;
	showDismiss?: boolean;
	showUndismiss?: boolean;
	onAssignOrCreateInvestigationClick?: () => void;
	onUnassignFromInvestigationClick?: () => void;
}

export const AlertsWidgetContextMenu: FC<AlertsWidgetContextMenuProps> = observer(
	({
		customValue,
		title = '',
		field = '',
		handleAddEqualFilter,
		handleAddNotEqualFilter,
		filterLabel,
		activeEqualFilters,
		closeContextMenu,
		dataItem,
		handleDismissAlert,
		handleDismissAlerts,
		handleUndismissedAlert,
		handleUndismissedAlerts,
		dismissAlertsCount,
		undismissAlertsCount,
		state,
		isSelected,
		showCopy = true,
		showFilter = true,
		showDismiss = false,
		showUndismiss = false,
		onAssignOrCreateInvestigationClick,
		onUnassignFromInvestigationClick,
	}) => {
		const location = useLocation();
		const isAdvancedQueryPage = location.pathname === RootPaths.ADVANCED_QUERY;
		const value = customValue ?? _get(dataItem, field, '');
		const copyValue = title ? title : value;

		const investigationId = _get(dataItem, AlertPropertiesPaths.InvestigationSummaryId);
		const showOpenInvestigationAction = Boolean(investigationId);

		const detailsViewRowId = getDetailsViewRowId(dataItem);
		const showOpenResultsTableAlertDetailsAction = Boolean(extractResultsTableItemId(detailsViewRowId));
		const showAssignToInvestigationAction = isAdvancedQueryPage;
		const showActionsTitle = showOpenResultsTableAlertDetailsAction || showAssignToInvestigationAction;

		const { t } = useTranslation([Namespaces.AlertsDashboard]);

		const showValueLabel = showFilter || showCopy;
		const showDivider = !detailsViewRowId && showValueLabel;

		const valueLabelKey = Array.isArray(value) && value.length > 1 ? 'multipleValues' : 'value';
		return (
			<div className={styles.container} data-testid="context-menu">
				{showValueLabel && (
					<div className={classNames(styles.actionContainer, styles.blockLabel)}>{t(`alertsGrid.contextMenu.${valueLabelKey}`)}</div>
				)}

				{showFilter && (
					<FiltersAction
						className={styles.actionContainer}
						value={value}
						field={field}
						handleAddEqualFilter={handleAddEqualFilter}
						handleAddNotEqualFilter={handleAddNotEqualFilter}
						closeContextMenu={closeContextMenu}
						activeEqualFilters={activeEqualFilters}
						filterLabel={filterLabel}
					/>
				)}

				{showCopy && <CopyValueAction className={styles.actionContainer} closeContextMenu={closeContextMenu} value={copyValue} />}

				{showDivider && <Divider className={styles.visible} />}

				{showOpenInvestigationAction && <OpenInvestigationAction className={styles.actionContainer} investigationId={investigationId} />}

				{showDismiss && (
					<AlertUpdateStateAction
						action="dismiss"
						className={styles.actionContainer}
						handleSelectedAlertAction={handleDismissAlerts}
						handleSingleAlertAction={handleDismissAlert}
						closeContextMenu={closeContextMenu}
						count={dismissAlertsCount}
						dataItem={dataItem}
						isSelected={isSelected}
						state={state}
					/>
				)}

				{showUndismiss && (
					<AlertUpdateStateAction
						action="undismiss"
						className={styles.actionContainer}
						handleSelectedAlertAction={handleUndismissedAlerts}
						handleSingleAlertAction={handleUndismissedAlert}
						closeContextMenu={closeContextMenu}
						count={undismissAlertsCount}
						dataItem={dataItem}
						isSelected={isSelected}
						state={state}
					/>
				)}

				{onAssignOrCreateInvestigationClick && (
					<div className={classNames(styles.actionContainer, styles.cursorPointer)} {...buttonize(onAssignOrCreateInvestigationClick)}>
						{t(AssignmentActionsTranslationKeys[AssignmentActions.Assign])}
					</div>
				)}

				{onUnassignFromInvestigationClick && (
					<div className={classNames(styles.actionContainer, styles.cursorPointer)} {...buttonize(onUnassignFromInvestigationClick)}>
						{t(AssignmentActionsTranslationKeys[AssignmentActions.Unassign])}
					</div>
				)}

				{showActionsTitle && <div className={classNames(styles.actionContainer, styles.blockLabel)}>{t('alertsGrid.contextMenu.alertActions')}</div>}
				{showOpenResultsTableAlertDetailsAction && (
					<OpenResultsTableAlertDetailsAction className={styles.actionContainer} itemId={detailsViewRowId} closeContextMenu={closeContextMenu} />
				)}
				{showAssignToInvestigationAction && (
					<AssignToInvestigationAction className={styles.actionContainer} item={dataItem} closeContextMenu={closeContextMenu} />
				)}
			</div>
		);
	},
);
