import { Severity } from '@/generated/graphql';

export const getSeverityColorIndicator = (severity: Severity) => {
	switch (severity) {
		case Severity.Critical:
			return ['var(--severityCritical)', 'var(--severityCritical)', 'var(--severityCritical)', 'var(--severityCritical)'];
		case Severity.High:
			return ['var(--severityHigh)', 'var(--severityHigh)', 'var(--severityHigh)'];
		case Severity.Medium:
			return ['var(--severityMedium)', 'var(--severityMedium)'];
		case Severity.Low:
			return ['var(--severityLow)'];
		default:
			return [];
	}
};
