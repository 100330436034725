import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/app/_common/_components/';

import styles from './data-header-cell-filter-reset.module.scss';

interface DataHeaderCellFilterResetProps {
	onClick: () => void;
	className?: string;
	disabled?: boolean;
}

export const DataHeaderCellFilterReset: FC<DataHeaderCellFilterResetProps> = ({ onClick, disabled = false }) => {
	const { t } = useTranslation();

	return (
		<Button className={styles.resetButton} fillMode="outline" onClick={onClick} disabled={disabled}>
			{t('grid.filters.resetButton')}
		</Button>
	);
};
