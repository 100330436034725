import { useCallback, useId } from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellProps } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import { AlertPropertiesPaths, AlertsKeyPrefix } from '@/app/_common/constants';
import { AlertsAssignListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog';
import { Checkbox } from '@/app/_common/_components/checkbox';
import { Namespaces } from '@/translations/namespaces';
import { Tooltip } from '@/app/_common/_components';

import styles from './investigation-select-cell.module.scss';

const idGetter = getter(AlertPropertiesPaths.Id);

export const InvestigationSelectCell = observer(({ dataItem }: GridCellProps) => {
	const alertsAssignListStore = useInstance(AlertsAssignListViewStore);
	const { t } = useTranslation([Namespaces.AlertsDashboard], { keyPrefix: AlertsKeyPrefix.AssignAlertDialog });
	const disabled = Boolean(dataItem.disabled);
	const tooltipContent = disabled ? t('tooltips.alertAlreadyAssigned') : '';
	const tooltipId = useId();
	const id = idGetter(dataItem);

	const { isRowSelected, toggleSelectedRow } = alertsAssignListStore;
	const checked = isRowSelected(id);

	const onSelectionChange = useCallback(() => {
		toggleSelectedRow(id);
	}, [id, toggleSelectedRow]);

	return (
		<td className={styles.investigationSelectCell}>
			{tooltipContent && <Tooltip tooltipId={tooltipId} tooltipContent={tooltipContent} className={styles.tooltip} />}
			<div data-tip={tooltipContent} data-for={tooltipId}>
				<Checkbox checked={checked} onChange={onSelectionChange} disabled={disabled} />
			</div>
		</td>
	);
});
