import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';
import { AvatarsList } from '@/app/_common/_components/avatars-list';
import { InlineActions } from '@/app/_common/_components/note/inline-actions';
import { getFormattedDate } from '@/app/_common/utils';
import { ArtifactType } from '@/generated/graphql';
import { Dialog, RichTextEditor, ReadOnlyWrapper, getRichTextEditorTranslations } from '@/app/_common/_components';
import { artifactTypeToString, NOTE_DIALOG_WIDTH } from '@/app/_common/constants';

import styles from './note-dialog.module.scss';

interface NoteProps {
	loading?: boolean;
	createdDate?: string | Date;
	updatedDate?: string | Date;
	type: ArtifactType;
	noteContent: string;
	names: Array<string>;
	loggedUserEmail?: string;
	lengthOfText?: number;
	showCreatedDate?: boolean;
	deleteNote: () => void;
	closeNoteWindow: () => void;
	onCancelEdit: () => void;
	handleUpdateNote: (updatedNoteContent: string) => void;
	activateEditNoteMode: () => void;
	shareNoteWindow: () => void;
	isEditModeActive: boolean;
}

export const NoteDialog: FC<NoteProps> = ({
	loading,
	createdDate,
	updatedDate,
	type,
	noteContent,
	names,
	loggedUserEmail,
	deleteNote,
	closeNoteWindow,
	handleUpdateNote,
	activateEditNoteMode,
	shareNoteWindow,
	onCancelEdit,
	isEditModeActive,
}) => {
	const [draftNoteContent, setDraftNoteContent] = useState<string | undefined>(noteContent);

	const { t } = useTranslation(Namespaces.Notes);

	const translations = useMemo(() => getRichTextEditorTranslations(Namespaces.Notes), []);

	const formattedUpdateDate = getFormattedDate(updatedDate as string);
	const formattedCreateDate = getFormattedDate(createdDate as string);

	const avatars = useMemo(() => names.map((name) => ({ name, active: name === loggedUserEmail })), [names, loggedUserEmail]);

	useEffect(() => {
		return () => {
			onCancelEdit();
		};
	}, [onCancelEdit]);

	useEffect(() => {
		setDraftNoteContent(noteContent);
	}, [noteContent]);

	return (
		<Dialog
			width={NOTE_DIALOG_WIDTH}
			headerDivider={false}
			footerDivider={false}
			closeIcon={false}
			className={styles.dialog}
			onClose={closeNoteWindow}
		>
			<ReadOnlyWrapper
				title={`${t('note.created')}: ${formattedCreateDate}, ${t('note.lastUpdated')}: ${formattedUpdateDate}`}
				subTitle={artifactTypeToString.get(type)}
				actions={
					<>
						<AvatarsList className={styles.avatars} items={avatars} />
						<InlineActions
							activateEditNoteMode={activateEditNoteMode}
							shareNoteWindow={shareNoteWindow}
							closeNoteWindow={closeNoteWindow}
							deleteNote={deleteNote}
						/>
					</>
				}
			>
				<RichTextEditor
					editorContent={draftNoteContent}
					onUpdate={handleUpdateNote}
					isEditModeActive={isEditModeActive}
					loading={loading}
					readOnly={true}
					translations={translations}
					onChange={setDraftNoteContent}
				/>
			</ReadOnlyWrapper>
		</Dialog>
	);
};
