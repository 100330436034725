import { ComponentType, PropsWithChildren, ReactElement, ReactNode, MouseEvent, useCallback, useRef, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { getter } from '@progress/kendo-react-common';

import { Grid, GridCellProps, GridDetailRowProps, GridRowProps } from '@progress/kendo-react-grid';
import { PagerProps } from '@progress/kendo-react-data-tools';
import { GridContextMenuEvent, GridPageChangeEvent } from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import { GridProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridProps';

import { Column, DataGrid } from '@/app/_common/_components/data-grid/data-grid';
import { ResultItemGridEdge, ResultsDataItem } from '@/app/_common/types';
import { ADX_PAGE_SIZES, ResultsTablePropertiesPaths } from '@/app/_common/constants';
import {
	QueryResultsTableItemExpanded,
	QueryResultsTablePager,
	QueryResultsTableListRow,
} from '@/app/_common/_components/query-results/query-results-table/components/';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';
import { useQueryResultsTableContextMenuConfig } from '@/app/_common/_components/query-results/query-results-table/hooks';
import { CommonContextMenuProps } from '@/app/_common/_components/new-context-menu';
import { ResultsContextMenuContent } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components';
import { AssignQueryResultsToInvestigationContextMenuContent } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/assign-query-results-to-investigation-dialog/components';
import { InvestigationQueryResultsContextMenuContent } from '@/app/investigation-details/_components/investigation-details/query-result/_components';
import { ContextMenuContentKey } from '@/app/_common/_components/query-results/query-results-table/types';

import dataGridStyles from '@/app/_common/_components/data-grid/data-grid.module.scss';

export interface QueryResultsTableListProps {
	data: ResultItemGridEdge[];
	columns: Column[];
	gridNoRecordsContent: JSX.Element | null;
	shouldRenderPaginator: boolean;
	totalCount: number;
	disableLookup: boolean;
	disableVirtualRows?: boolean;
	contextMenuContentKey?: ContextMenuContentKey;
	dataGridClassName?: string;
	itemDetailsId?: string;
}

const contextMenuContentMapping: Record<ContextMenuContentKey, ComponentType<CommonContextMenuProps<ResultsDataItem>> | undefined> = {
	[ContextMenuContentKey.Results]: ResultsContextMenuContent,
	[ContextMenuContentKey.AssignQueryResultsToInvestigation]: AssignQueryResultsToInvestigationContextMenuContent,
	[ContextMenuContentKey.InvestigationQueryResults]: InvestigationQueryResultsContextMenuContent,
	[ContextMenuContentKey.Default]: undefined,
};

const idGetter = getter(ResultsTablePropertiesPaths.Id);

export const QueryResultsTableList = observer<QueryResultsTableListProps>(
	({
		data,
		columns,
		gridNoRecordsContent,
		shouldRenderPaginator,
		totalCount,
		contextMenuContentKey = ContextMenuContentKey.Default,
		disableLookup = false,
		disableVirtualRows,
		dataGridClassName,
		itemDetailsId,
	}) => {
		const { isRowSelected, sort, page, selectedItems, selectRows, setPage, setPageSize, totalResultsCount, toggleSelectAllRows, selectedResultsIds } =
			useQueryResultsTableStore();

		const onPageChange = (event: GridPageChangeEvent) => {
			setPage(event.page);
		};

		const changeRowsPerPage = useCallback(
			(take: number) => {
				setPageSize(take);
			},
			[setPageSize],
		);

		const gridRef = useRef<Grid | null>(null);

		const contextMenuOptions = useQueryResultsTableContextMenuConfig(selectedItems, selectRows, disableLookup, selectedResultsIds);

		const rowRender = (trElement: ReactElement<HTMLTableRowElement>, props: GridRowProps): ReactNode => {
			if (!props.dataItem) {
				return null;
			}

			const id = idGetter(props.dataItem);
			const isSelected = isRowSelected(id);
			const isItemDetailsShown = Boolean(itemDetailsId) && itemDetailsId === id;
			const className = classNames(dataGridStyles.row, trElement.props.className, {
				[dataGridStyles.expandedRow]: isItemDetailsShown,
				[dataGridStyles.selectedRow]: props.dataItem.expanded || isSelected,
			});

			return (
				<QueryResultsTableListRow
					columns={columns}
					trElement={trElement}
					{...props}
					selected={isSelected}
					expanded={props.dataItem.expanded}
					className={className}
					disableVirtualRows={disableVirtualRows}
				/>
			);
		};

		const ResultsPagerRender = useCallback(
			(props: PagerProps) => <QueryResultsTablePager {...props} totalCount={totalCount} changeRowsPerPage={changeRowsPerPage} updatePage={setPage} />,
			[changeRowsPerPage, setPage, totalCount],
		);

		const handleContextMenu = useCallback(
			(event: MouseEvent<HTMLElement>, field: string, dataItem: PropsWithChildren<GridCellProps>) => {
				const grid = gridRef.current;

				if (!grid) {
					return;
				}

				contextMenuOptions?.onOpen(field, dataItem);

				const ev: GridContextMenuEvent = {
					target: grid,
					syntheticEvent: event,
					nativeEvent: event.nativeEvent,
					dataItem,
					field,
				};

				/*
					We have to call the Kendo build-in private method to extend the context menu functionality.
					The main reason is that they didn't predict such a use-case where we will duplicate the data from table in expanded row and add context menu there.
				*/
				// @ts-ignore
				gridRef.current?.onContextMenu.call(undefined, ev, dataItem, field);
			},
			[contextMenuOptions],
		);

		const ItemExpandedRender = useCallback(
			(props: GridDetailRowProps) => <QueryResultsTableItemExpanded {...props} onContextMenu={handleContextMenu} />,
			[handleContextMenu],
		);

		const gridProps: GridProps = {
			data: data,
			sort: sort,
			dataItemKey: ResultsTablePropertiesPaths.Id,
			scrollable: 'scrollable',
			rowHeight: 32,
			style: { height: '100%' },
			fixedScroll: true,
			total: totalResultsCount,
			skip: page.skip,
			pageSize: page.take,
			pageable: shouldRenderPaginator && {
				buttonCount: 5,
				info: true,
				type: 'numeric',
				pageSizes: ADX_PAGE_SIZES,
				previousNext: true,
			},
			pager: ResultsPagerRender,
			resizable: true,
			rowRender,
			onPageChange,
			onHeaderSelectionChange: toggleSelectAllRows,
		};

		const contextMenuContent = useMemo(() => contextMenuContentMapping[contextMenuContentKey], [contextMenuContentKey]);

		return (
			<DataGrid
				gridRef={gridRef}
				expandDetailComponent={ItemExpandedRender}
				expandField={true}
				gridProps={gridProps}
				columns={columns}
				gridNoRecordsContent={gridNoRecordsContent}
				data-testid="results-grid"
				contextMenuProps={contextMenuOptions}
				contextMenuContent={contextMenuContent}
				className={dataGridClassName}
			/>
		);
	},
);

QueryResultsTableList.displayName = 'QueryResultsTableList';
