import { FC } from 'react';

import classNames from 'classnames';

import styles from './editor-counter.module.scss';

interface EditorCounterProps {
	limit: number;
	count: number;
}

export const EditorCounter: FC<EditorCounterProps> = ({ limit = 0, count = 0 }) => {
	return (
		<span className={classNames(styles.editorCounter, { [styles.exceededLimit]: count > limit })} data-testid="rich-text-editor-character-count">
			{count}/{limit}
		</span>
	);
};
