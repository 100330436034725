import { CellProps } from '@progress/kendo-react-data-tools';

import { ActionsItem } from '@/app/_common/_components/data-grid/_components/actions-cell/types';

export const withDataItem = (
	Component: ({ item }: { item: ActionsItem; dataItem: CellProps['dataItem'] }) => JSX.Element,
	dataItem: CellProps['dataItem'],
) => {
	const ComponentRenderer = ({ item }: { item: ActionsItem }) => {
		return <Component item={item} dataItem={dataItem} />;
	};

	ComponentRenderer.displayName = 'ComponentRendererWithDataItem';

	return ComponentRenderer;
};
