import { FC, ReactElement } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

import { BaseCell } from '@/app/_common/_components/data-grid/_components';

import styles from './title-subtitle-cell.module.scss';

interface TitleSubtitleProps extends GridCellProps {
	title?: string;
	subtitle?: string;
}

export const TitleSubtitleCell: FC<TitleSubtitleProps> = ({ title, subtitle, ...rest }) => {
	const displayValues: ReactElement[] = [];

	if (title) {
		displayValues.push(<p key={title}>{title}</p>);
	}

	if (subtitle) {
		displayValues.push(
			<p key={subtitle} className={styles.subtitle}>
				{subtitle}
			</p>,
		);
	}

	return <BaseCell {...rest} hasValue={true} cellTitle="" defaultTitle="" displayValues={displayValues} />;
};
