export const getLabelWithCounter = (label: string, counter?: number | string): string => {
	if (!counter) {
		return label;
	}

	const isCounterOfZeroInString = typeof counter === 'string' && parseInt(counter, 10) === 0;
	const isCounterOfZero = counter === 0;

	if (isCounterOfZero || isCounterOfZeroInString) {
		return label;
	}

	return `${label} (${counter})`;
};
