import { FC } from 'react';

import { Stepper } from '@progress/kendo-react-layout';

import { WizardStep } from '@/app/_common/_components/wizard/_components/wizard-step';
import { WizardStepProps } from '@/app/_common/_components/wizard/_common';

import styles from './wizard-stepper.module.scss';
import classNames from 'classnames';

export interface WizardStepperProps {
	items: WizardStepProps[];
	currentStepNumber: number;
	className?: string;
}

export const WizardStepper: FC<WizardStepperProps> = ({ className, items, currentStepNumber }) => {
	return (
		<div className={className}>
			<Stepper
				className={classNames(styles.stepper, { [styles.lastStepUndefined]: items[items.length - 1]?.stepUndefined })}
				items={items}
				item={WizardStep}
				value={currentStepNumber}
			/>
			{items[currentStepNumber]?.actionDescription && <p className={styles.actionDescription}>{items[currentStepNumber].actionDescription}</p>}
		</div>
	);
};
