import { isStackedChartDataItem } from '@/app/advanced-query/_common/types';
import { ChartDataItem, StackedChartDataItem, ChartStackBarKeys } from '@/app/_common/types';

const STACKED_BAR_CHART_KEYS = Object.values(ChartStackBarKeys).filter((value) => value !== ChartStackBarKeys.Date);

type StackedBarChartKeys = (typeof STACKED_BAR_CHART_KEYS)[number];

export function getBarDataCounts(data: ChartDataItem | StackedChartDataItem) {
	const barCount: Partial<Record<StackedBarChartKeys, number>> = {
		[ChartStackBarKeys.EventsCount]: Number(data[ChartStackBarKeys.EventsCount]),
	};

	if (isStackedChartDataItem(data)) {
		STACKED_BAR_CHART_KEYS.forEach((key) => {
			barCount[key] = Number(data[key]);
		});
	}

	return barCount;
}
