import { LABEL_VALUE_ROW_FORMAT } from './_common/label-value-row.constants';
import { Value } from './_components';
import { LabelValueRowValue } from './_common/types';

import styles from './label-value-row.module.scss';

interface LabelValueRowProps {
	label: string;
	value?: LabelValueRowValue;
	format?: LABEL_VALUE_ROW_FORMAT;
	formatSeparator?: string; // separator for LABEL_VALUE_ROW_FORMAT.JOINED_LIST
}

export const LabelValueRow = ({ label, value, format = LABEL_VALUE_ROW_FORMAT.NORMAL, formatSeparator = ', ' }: LabelValueRowProps) => {
	return (
		<div className={styles.labelValueRow}>
			<span className={styles.label} data-testid="label">
				{label}
			</span>
			<Value format={format} value={value} formatSeparator={formatSeparator} />
		</div>
	);
};
