import { Children, FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import { ContextMenuTitle, ContextMenuItem } from '@/app/_common/_components';

import styles from './context-menu-section.module.scss';

interface ContextMenuSectionProps {
	children: ReactNode;
	title?: string;
	loading?: boolean;
	className?: string;
}

export const ContextMenuSection: FC<ContextMenuSectionProps> = ({ title, loading, className, children }) => {
	const loadingSkeletonsIds = useMemo(() => {
		return Children.toArray(children).map(() => uuid());
	}, [children]);

	return (
		<div className={classNames(styles.contextMenuSection, className)}>
			{title && <ContextMenuTitle>{title}</ContextMenuTitle>}
			<ul className={styles.contextMenuSectionList}>{loading ? loadingSkeletonsIds.map((id) => <ContextMenuItem key={id} loading />) : children}</ul>
		</div>
	);
};
