import { cloneElement, ReactElement, useCallback } from 'react';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import _isNil from 'lodash/isNil';
import moment from 'moment';

import { Form, Field, FormProps, FormElement, KeyValue } from '@progress/kendo-react-form';
import { ListItemProps } from '@progress/kendo-react-dropdowns';

import { Investigation } from '@/generated/graphql';
import { Namespaces } from '@/translations/namespaces';
import { Tooltip } from '@/app/_common/_components';
import { FormDropdown } from '@/app/_common/_components/form-components/form-dropdown';
import { AssignToInvestigationTabs, DATE_TIME_FORMAT } from '@/app/_common/constants';
import { DropdownOption } from '@/app/_common/_components/forms/_common';
import { tooltipValueRender } from '@/app/investigation-details/_common/utils/dropdown-render-utils';
import { AssignInvestigationFormValues } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/assign-query-results-to-investigation-dialog/types';
import { InvestigationSelectViewStore } from '@/app/_common/_components/forms/assign-to-investigation-form/_common/stores';

import styles from './assign-to-investigation-form.module.scss';

export interface AssignToInvestigationFormData {
	investigation: DropdownOption<
		Pick<Investigation, 'id'> & {
			alertIds: string[];
		}
	>;
}

export type AssignToInvestigationFormValidator = (data: AssignToInvestigationFormData) => KeyValue<string> | undefined;

export interface AssignToInvestigationFormProps {
	disabled?: boolean;
	onSubmit?: FormProps['onSubmit'];
	onErrorsChange: (errors: { [key: string]: string }, tab: AssignToInvestigationTabs) => void;
	onChange?: (values: AssignInvestigationFormValues) => void;
}

const useFormValidator = () => {
	const { t } = useTranslation([Namespaces.AssignAlertToInvestigation]);

	const formValidator: AssignToInvestigationFormValidator = (data) => {
		const messages: { [key: string]: string } = {};

		if (!data.investigation) {
			messages.investigation = t('id.messages.required');
		}

		if (Object.keys(messages).length === 0) {
			return;
		}

		return messages;
	};

	return formValidator;
};

const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
	const date = _isNil(itemProps.dataItem.date) ? '' : moment(itemProps.dataItem.date).format(DATE_TIME_FORMAT);
	const tooltipId = itemProps.id ?? itemProps.dataItem.label;

	const itemChildren = (
		<div className={styles.assignOption}>
			<div className={styles.assignOptionLabel}>
				<span data-tip={true} data-for={tooltipId}>
					{itemProps.dataItem.label}
				</span>
			</div>
			<div className={styles.assignOptionDate}>{date}</div>
			<Tooltip tooltipId={tooltipId} place={'right'} tooltipContent={itemProps.dataItem.label} />
		</div>
	);

	return cloneElement(li, li.props, itemChildren);
};

export const AssignToInvestigationForm = observer<AssignToInvestigationFormProps, Form>(
	({ disabled = false, onSubmit, onErrorsChange, onChange }, ref) => {
		const { loading: investigationLoading, data: investigationOptions } = useInstance(InvestigationSelectViewStore);
		const { t } = useTranslation([Namespaces.AssignAlertToInvestigation]);
		const formValidator = useFormValidator();

		const handleChange = useCallback(() => {
			if (ref && 'current' in ref && onChange) {
				onChange(ref?.current?.values as AssignInvestigationFormValues);
			}
		}, [ref, onChange]);

		return (
			<Form
				ref={ref}
				validator={formValidator as FormProps['validator']}
				onSubmit={onSubmit}
				render={({ errors }) => {
					onErrorsChange(errors, AssignToInvestigationTabs.assignToInvestigation);

					return (
						<FormElement>
							<fieldset className={styles.fields}>
								<Field
									id={'investigation'}
									name={'investigation'}
									label={t('id.label')}
									component={FormDropdown}
									dataItemKey="value"
									textField="label"
									itemRender={itemRender}
									valueRender={tooltipValueRender}
									options={investigationOptions}
									filterable={true}
									disabled={disabled}
									loading={investigationLoading}
									onChange={handleChange}
								/>
							</fieldset>
						</FormElement>
					);
				}}
			/>
		);
	},
	{ forwardRef: true },
);
