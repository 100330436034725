import { FC, useCallback } from 'react';

import _get from 'lodash/get';
import classNames from 'classnames';

import { GridCellProps } from '@progress/kendo-react-grid';

import { AlertEdge, InvestigationAlertSummary } from '@/generated/graphql';

import { ColorIndicator, ColorIndicatorWithValueWrapper } from '@/app/_common/_components';
import { DataCell, HighlightedText } from '@/app/_common/_components/data-grid/_components';
import { getSeverityColorIndicator } from '@/app/_common/utils/colors';
import { ContextMenuComponentProps } from '@/app/_common/types';

export interface SeverityCellProps extends GridCellProps {
	onClick?: (dataItem: AlertEdge | InvestigationAlertSummary) => void;
	ContextMenuComponent?: FC<ContextMenuComponentProps>;
	searchValue?: string;
	showLabel?: boolean;
	ignoreUnknownValue?: boolean;
	oldContextMenu?: boolean;
}

export const SeverityCell: FC<SeverityCellProps> = ({
	field,
	dataItem,
	onClick = () => undefined,
	ContextMenuComponent,
	searchValue,
	ignoreUnknownValue = false,
	showLabel,
	...rest
}) => {
	const value = _get(dataItem, field || '');
	const isCellHighlighted = searchValue && String(value).toLowerCase().includes(searchValue.toLowerCase());
	const highlightedValue = searchValue ? <HighlightedText value={value} searchLine={searchValue} /> : value;

	const handleCellClick = useCallback(() => {
		if (typeof onClick === 'function') {
			onClick(dataItem);
		}
	}, [dataItem, onClick]);

	if (ignoreUnknownValue && (value === null || value === undefined)) {
		return <DataCell empty={true} dataItem={dataItem} ContextMenuComponent={ContextMenuComponent} {...rest} />;
	}

	return (
		<DataCell
			title={value}
			field={field}
			onClick={handleCellClick}
			dataItem={dataItem}
			ContextMenuComponent={ContextMenuComponent}
			{...rest}
			className={classNames({ isCellHighlighted })}
		>
			<ColorIndicatorWithValueWrapper>
				<ColorIndicator bulletsConfig={getSeverityColorIndicator(value)} /> <span>{showLabel ? highlightedValue : ''}</span>
			</ColorIndicatorWithValueWrapper>
		</DataCell>
	);
};
