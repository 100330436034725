import { ShowMore } from '@/app/_common/_components/show-more/show-more';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Namespaces } from '@/translations/namespaces';
import { SummarySectionItemTitle } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-section-item-title';

import styles from './description.module.scss';

interface DescriptionProps {
	description: string;
	className?: string;
	isOneLineOnCollapse?: boolean;
}

export const Description: FC<DescriptionProps> = ({ description = '-', className, isOneLineOnCollapse = false }) => {
	const { t } = useTranslation(Namespaces.DetailsView);

	return (
		<div className={classNames(styles.container, className)}>
			<SummarySectionItemTitle title={t('summary.description')} />
			<ShowMore charactersCount={100} text={description} isOneLineOnCollapse={isOneLineOnCollapse} />
		</div>
	);
};
