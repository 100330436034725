import { ApolloCache, Reference, gql } from '@apollo/client';

import { Alert } from '@/generated/graphql';
import { writeFragmentById } from '@/app/_common/utils';
import { AlertTypename } from '@/app/_common/constants';

export const AlertStateUpdateFragment = gql`
	fragment UpdatedAlert on Alert {
		state {
			alertState
			requesterUser {
				id
				upn
			}
			timestamp
		}
		investigationSummary {
			id
		}
	}
`;

export const writeAlertStateUpdateFragment = (
	cache: ApolloCache<unknown>,
	id: string | undefined,
	data: Partial<Alert>,
): Reference | undefined | null => {
	return writeFragmentById(cache, id, data, AlertStateUpdateFragment, AlertTypename.Alert);
};
