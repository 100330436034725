import { FC, ReactNode } from 'react';

import { useSlate } from 'slate-react';
import classNames from 'classnames';

import { EditorAction, IsCurrentFormatActive, toggleActionButton, isMarkActive } from '@/app/_common/_components/rich-text-editor';
import { FormattingType } from '@/app/_common/_components/rich-text-editor/_common/constant';

import styles from '../editor-button.module.scss';

interface EditorButtonProps {
	icon: ReactNode;
	format: FormattingType;
	action: EditorAction;
	disabled?: boolean;
}

export const EditorButton: FC<EditorButtonProps> = ({ icon, format, action, disabled }) => {
	const editor = useSlate();

	const active = action === EditorAction.Mark ? isMarkActive(editor, format) : IsCurrentFormatActive(editor, format);

	return (
		<button
			className={classNames(styles.blockBtn, {
				[styles.active]: active,
				[styles.disabled]: disabled,
			})}
			onClick={() => toggleActionButton(editor, format, action)}
			disabled={disabled}
		>
			{icon}
		</button>
	);
};
