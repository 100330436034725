import { FC, memo } from 'react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import { deepEqual, isSearchStringEqual, parseMultilineJsonInput, capitalizeFirstLetter } from '@/app/_common/utils';
import { BaseCell } from '@/app/_common/_components/data-grid/_components';
import { HighlightedText } from '@/app/_common/_components/data-grid/_components/highlighted-text';
import { ResultsCellProps } from '@/app/advanced-query/_common/types';

export const MultiLineCell: FC<ResultsCellProps> = memo(
	({ title, field, dataItem, tooltip, defaultMessage, className, onClick, searchValue = '', ContextMenuComponent, ...rest }) => {
		const value = title || _get(dataItem, field || '');
		const hasValue = !_isEmpty(value);
		const multiLineValues = parseMultilineJsonInput(value);
		const cellTitle = tooltip || multiLineValues.join('\n');
		const defaultTitle = tooltip || '';

		let displayValues;

		const shouldRenderAsMultipleLines = multiLineValues.length > 1;

		if (shouldRenderAsMultipleLines) {
			displayValues = multiLineValues.map((val: string, index: number) => {
				const highlightedValue = searchValue ? <HighlightedText value={val} searchLine={searchValue} /> : val;
				const key = `${dataItem.id}-${field}-${index}`;
				return <p key={key}>{highlightedValue}</p>;
			});
		} else {
			const capitalizedValue = capitalizeFirstLetter(value);

			displayValues = searchValue ? <HighlightedText value={capitalizedValue} searchLine={searchValue} /> : capitalizedValue;
		}

		const isCellHighlighted = searchValue && String(value).toLowerCase().includes(searchValue.toLowerCase());

		return (
			<BaseCell
				{...rest}
				hasValue={hasValue}
				displayValues={displayValues}
				cellTitle={cellTitle}
				defaultTitle={defaultTitle}
				customClassnames={classNames('isMultiLine', { isCellHighlighted }, className)}
				onClick={onClick}
				dataItem={dataItem}
				ContextMenuComponent={ContextMenuComponent}
				defaultMessage={defaultMessage}
			/>
		);
	},
	function (prev, next) {
		const isItemDataEqual = deepEqual(prev, next, ['field', `dataItem[${prev.field}]`]);
		const isSearchValueEqual = isSearchStringEqual(prev.searchValue, next.searchValue);
		return isItemDataEqual && isSearchValueEqual;
	},
);

MultiLineCell.displayName = 'MultiLineCell';
