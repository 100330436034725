import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { Button } from '@/app/_common/_components/button';

import styles from './section.module.scss';

interface SectionProps {
	header: string;
	buttonText?: string;
	buttonDisabled?: boolean;
	className?: string;
	onClick?: () => void;
	children?: ReactNode;
}

export const Section: FC<SectionProps> = ({ header, buttonText, buttonDisabled, onClick, children, className }) => (
	<section className={classNames(styles.section, className)}>
		<header className={styles.sectionHeader}>
			<h2>{header}</h2>
			{buttonText && (
				<Button onClick={onClick} disabled={buttonDisabled} className={styles.sectionButton}>
					{buttonText}
				</Button>
			)}
		</header>
		{children}
	</section>
);
