import { FC, ReactNode, useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { BaseSelection, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { useOutsideClick } from '@/app/_common/hooks';
import { Popup } from '@progress/kendo-react-popup';

import { useTable, IsCurrentFormatActive, RICH_TEXT_EDITOR_POPUP_ID } from '@/app/_common/_components/rich-text-editor';

import classNames from 'classnames';

import styles from './table-button.module.scss';
import { useInstance } from 'react-ioc';
import { ThemeStore } from '@/app/_common/stores';

interface EditorTableProps {
	format: string;
	icon: ReactNode;
	disabled?: boolean;
}

export const TableButton: FC<EditorTableProps> = observer(({ disabled, format, icon }) => {
	const editor = useSlate();
	const themeStore = useInstance(ThemeStore);
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const { insertTable } = useTable(editor);
	const [selection, setSelection] = useState<BaseSelection>();
	const [tableData, setTableData] = useState({
		row: 0,
		column: 0,
	});
	const [tableInput, setTableInput] = useState(
		Array.from({ length: 6 }, () =>
			Array.from({ length: 6 }, (v, i) => ({
				bg: 'none',
				column: i,
			})),
		),
	);
	const activeTableColor = themeStore.isDarkTheme ? '#1c2b32' : '#ebf4fa';
	const noActiveTableColor = themeStore.isDarkTheme ? '#1e2022' : '#ffffff';

	useEffect(() => {
		const newTable = Array.from({ length: 6 }, (obj, row) =>
			Array.from({ length: 6 }, (v, col) => ({
				bg: row + 1 <= tableData.row && col + 1 <= tableData.column ? activeTableColor : noActiveTableColor,
				column: col,
			})),
		);
		setTableInput(newTable);
	}, [tableData, noActiveTableColor, activeTableColor]);

	const { popupRef, anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		showDropdown,
		useCallback(() => setShowDropdown(false), []),
	);

	const toggleDropdown = () => {
		setSelection(editor.selection);
		setShowDropdown((showDropdown) => !showDropdown);
	};

	const handleInsert = () => {
		selection && Transforms.select(editor, selection);
		setTableData({ row: 0, column: 0 });
		insertTable(tableData.row, tableData.column);
		setShowDropdown(false);
	};

	return (
		<div ref={anchorRef}>
			<button
				className={classNames(styles.tableBtn, {
					[styles.active]: IsCurrentFormatActive(editor, format),
					[styles.disabled]: disabled,
				})}
				onClick={toggleDropdown}
				disabled={disabled}
			>
				{icon}
			</button>
			<Popup
				anchor={anchorRef.current}
				show={showDropdown}
				popupClass={styles.popup}
				id={RICH_TEXT_EDITOR_POPUP_ID}
				data-testid={RICH_TEXT_EDITOR_POPUP_ID}
			>
				<div ref={popupRef}>
					<div className={styles.tableInfo}>
						<i>{`${tableData.row} x ${tableData.column}`}</i>
					</div>

					<div className={styles.tableInput}>
						{tableInput.map((grp, row) =>
							grp.map(({ column, bg }) => (
								<button
									data-testid={`rich-text-editor-table-input-button`}
									key={`${column}-${row}`}
									onClick={handleInsert}
									onMouseDown={handleInsert}
									onMouseOver={() => setTableData({ row: row + 1, column: column + 1 })}
									onFocus={() => setTableData({ row: row + 1, column: column + 1 })}
									className={styles.tableUnit}
									style={{ backgroundColor: bg }}
								/>
							)),
						)}
					</div>
				</div>
			</Popup>
		</div>
	);
});
