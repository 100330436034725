import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchOutlined } from '@mui/icons-material';
import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';
import classNames from 'classnames';

import { Namespaces } from '@/translations/namespaces';
import { buttonize } from '@/app/_common/utils';

import styles from './field-search.module.scss';

interface FieldSearchProps {
	value: string;
	id: string; // unique id allows us to differentiate the autocompletion values for different fields
	className?: string;
	onChange: (event: InputChangeEvent) => void;
	onSubmit?: () => void;
	keyDownHandler?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	showLabel?: boolean;
	placeholder?: string;
	disabled?: boolean;
}

export const FieldSearch: FC<FieldSearchProps> = ({
	value,
	id = 'search',
	className,
	onChange,
	onSubmit,
	keyDownHandler,
	showLabel = true,
	placeholder = '',
	disabled = false,
}) => {
	const { t } = useTranslation([Namespaces.Common]);

	const handleSubmit = () => {
		if (typeof onSubmit === 'function') {
			onSubmit();
		}
	};

	return (
		<div className={classNames(styles.fieldSearch, className)}>
			{showLabel && (
				<label className={styles.label} htmlFor={id}>
					{t('search')}
				</label>
			)}
			<Input
				className={classNames(styles.input, { [styles.disabled]: disabled })}
				id={id}
				name={id}
				value={value}
				onChange={onChange}
				disabled={disabled}
				onKeyPress={keyDownHandler}
				placeholder={placeholder}
			/>
			<div
				className={classNames(styles.icon, { [styles.showLabel]: showLabel, [styles.disabled]: disabled })}
				{...buttonize<HTMLDivElement>(handleSubmit)}
			>
				<SearchOutlined />
			</div>
		</div>
	);
};
