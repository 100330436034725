import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';
import { Dialog, Button } from '@/app/_common/_components';

import styles from './confirm-dialog.module.scss';

const DEFAULT_WIDTH = 439;

interface ConfirmDialogProps {
	title?: string;
	width?: number;
	onSubmit?: () => void;
	onCancel?: () => void;
	children: ReactNode;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = ({ width = DEFAULT_WIDTH, title, onSubmit, onCancel, children }) => {
	const { t } = useTranslation(Namespaces.Notes);

	return (
		<Dialog
			width={width}
			title={title}
			className={styles.dialog}
			headerDivider={false}
			footerDivider={false}
			closeIcon={false}
			onClose={onCancel}
			onSubmit={onSubmit}
			actions={
				<div className={styles.actions}>
					<Button fillMode="flat" onClick={onCancel}>
						{t('confirm.buttons.back')}
					</Button>
					<Button fillMode="outline" onClick={onSubmit}>
						{t('confirm.buttons.confirm')}
					</Button>
				</div>
			}
		>
			{children}
		</Dialog>
	);
};
