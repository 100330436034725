import { FC, useRef, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import {
	TimelineItem,
	Line,
	TimelineItemData,
	prepareTimelineDetails,
	AlertsDetailsNavigationViewStore,
} from '@/app/_common/_components/details-view/alerts-details-view-navigation';

import styles from './timeline.module.scss';
import { buttonize } from '@/app/_common/utils';
import { setTimelineItemsZindex } from '../../_common/utils/set-timeline-items-zindex';

const HIDDEN_SPACE_WIDTH = 10;
const IMAGE_WIDTH = 20;
const ITEM_WIDTH = 150;
const TIMELINE_PADDING = 25; // Padding between border of each of side element and side of timeline area.
const LINE_ZINDEX = 10;

export interface TimelineProps {
	items: TimelineItemData[];
	itemWidth: number;
	zoom?: number;
}

export const Timeline: FC<TimelineProps> = observer(({ items, itemWidth, zoom = 1 }) => {
	const store = useInstance(AlertsDetailsNavigationViewStore);
	const timelineRef = useRef<HTMLDivElement | null>(null);
	const [width, setWidth] = useState(0);

	useEffect(() => {
		if (timelineRef.current) {
			setWidth(timelineRef.current.offsetWidth);
		}

		return () => {
			store.setActiveBoostedAlert('');
		};
	}, [store, timelineRef]);

	const handleScrollToElement = (left: number, itemWidth: number) => {
		const currentLeft = left + itemWidth - width / 2;
		timelineRef.current?.scrollTo({ left: currentLeft, behavior: 'smooth' });
	};

	useEffect(() => {
		const activeItem = items.find((item) => item.id === store.activeBoostedAlert);
		if (activeItem) {
			handleScrollToElement(activeItem?.left, ITEM_WIDTH);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [store.activeBoostedAlert]);

	const maxZindex = useMemo(() => setTimelineItemsZindex(items, LINE_ZINDEX), [items]);

	const handleSetActive = (key: string, left: number, itemWidth: number) => {
		store.setActiveBoostedAlert(key);
		handleScrollToElement(left, itemWidth);
	};

	const handleDeSelectActive = () => {
		store.setActiveBoostedAlert('');
	};

	// When there is no way to display (happens on loading), then let's cut disable calculating timeline details.
	if (!width) {
		return <div ref={timelineRef}></div>;
	}

	const drawableWidth = width * zoom;

	let itemDetails: TimelineItemData[] = [];
	if (items.length !== 0) {
		itemDetails = prepareTimelineDetails({
			items,
			itemWidth,
			hiddenSpace: HIDDEN_SPACE_WIDTH,
			imageWidth: IMAGE_WIDTH,
			drawableWidth,
			extraBeginningPadding: TIMELINE_PADDING,
		});
	}

	const last = itemDetails.length - 1;
	const displayItems = itemDetails.map((item: TimelineItemData, index: number) => {
		const isActive = store.activeBoostedAlert === item.id;
		const isLast = index === last;

		return (
			<TimelineItem
				key={item.id ?? index}
				{...item}
				active={isActive}
				handleSetActive={handleSetActive}
				zindexForOverlay={maxZindex}
				isClickEnabled={!isLast}
			/>
		);
	});

	return (
		<div className={styles.timeline} ref={timelineRef}>
			<div className={styles.wrapper} {...buttonize<HTMLDivElement>(handleDeSelectActive)} style={{ width: drawableWidth, minWidth: `${width}px` }}>
				<div className={styles.timelineItems}>{displayItems}</div>
				<Line />
			</div>
		</div>
	);
});

Timeline.displayName = 'Timeline';
