import { observer } from 'mobx-react-lite';
import { memo } from 'react';

import { ActionsHeaderCell } from '@/app/_common/_components/data-grid/_components';
import { getResetFiltersMenuItem } from '@/app/_common/_components/data-grid/utils';
import { QueryResultsTableHeaderCellWithStoreProps } from '@/app/_common/_components/query-results/query-results-table/types';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';
import { shallowEqual } from '@/app/_common/utils';

const QueryResultsTableResultsActionsHeaderCellComponent = observer((props: QueryResultsTableHeaderCellWithStoreProps) => {
	const {
		areFiltersActive,
		resetAllFilters,
		resultsColumnOptions,
		hideColumnsDisabled,
		resetColumnsWithConsideringEmptyValues,
		toggleOptionsColumn,
	} = useQueryResultsTableStore();

	return (
		<ActionsHeaderCell
			{...props}
			items={[
				getResetFiltersMenuItem({
					disabled: !areFiltersActive,
					resetAllFilters: resetAllFilters,
				}),
			]}
			columnOptions={resultsColumnOptions}
			hideColumnsDisabled={hideColumnsDisabled}
			onColumnsReset={resetColumnsWithConsideringEmptyValues}
			onColumnOptionsChange={toggleOptionsColumn}
		/>
	);
});

QueryResultsTableResultsActionsHeaderCellComponent.displayName = 'QueryResultsTableResultsActionsHeaderCellComponent';

/*
 * 'columnMenuWrapperProps' leads to rerendering of the header cell component.
 * As this prop does not seem to be used by custom header cells, it is ignored when comparing.
 */
export const QueryResultsTableResultsActionsHeaderCell = memo(
	QueryResultsTableResultsActionsHeaderCellComponent,
	(prevProps: QueryResultsTableHeaderCellWithStoreProps, nextProps: QueryResultsTableHeaderCellWithStoreProps) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { columnMenuWrapperProps: prevColumnMenuWrapperProps, ..._prevProps } = prevProps;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { columnMenuWrapperProps: nextColumnMenuWrapperProps, ..._nextProps } = nextProps;

		return shallowEqual(_prevProps, _nextProps);
	},
);

QueryResultsTableResultsActionsHeaderCell.displayName = 'QueryResultsTableResultsActionsHeaderCell';
