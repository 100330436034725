import { RefObject, useEffect, useRef, useState } from 'react';

interface IntersectionObserverOptions {
	root?: Element | null;
	rootMargin?: string;
	threshold?: number | number[];
}

interface UseIsVisibleProps {
	disabled?: boolean;
	options?: IntersectionObserverOptions;
}

export const useIsVisible = ({ disabled = false, options }: UseIsVisibleProps = {}) => {
	const ref: RefObject<HTMLDivElement> = useRef(null);
	// Initially consider element to be hidden for performance reasons, if only the hook is not disabled.
	const [isVisible, setIsVisible] = useState(disabled);

	useEffect(() => {
		if (disabled) {
			return;
		}

		const observer: IntersectionObserver = new IntersectionObserver(([entry]) => {
			/*
			 * The below condition fixes cases of improper intersection observer data generated.
			 * E.g. grid rows being sorted, some of the visible rows' IntersectionObserver data may have wrong zero boundingClientRect properties.
			 * That leads to empty element content.
			 */
			if (!entry.boundingClientRect.height) {
				return;
			}

			if (isVisible !== entry.isIntersecting) {
				setIsVisible(entry.isIntersecting);
			}
		}, options);

		const refCurrent = ref?.current;

		if (refCurrent) {
			observer.observe(refCurrent);
		}

		return () => {
			observer.disconnect();
		};
	}, [ref, options, disabled, isVisible]);

	return { ref, isVisible };
};
