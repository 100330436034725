import { FC } from 'react';
import { useSlate } from 'slate-react';
import { Dialog } from '@/app/_common/_components/dialog';
import { Button } from '@/app/_common/_components/button';

import { useTable } from '@/app/_common/_components/rich-text-editor';
import { RichTextEditorTranslations } from '@/app/_common/_components/rich-text-editor/types';

import styles from './table-dialog.module.scss';

const DIALOG_WIDTH = 440;

interface AddLinkDialog {
	open: boolean;
	handleToggleDialog: () => void;
	translations: RichTextEditorTranslations;
}

export const DeleteTableDialog: FC<AddLinkDialog> = ({ open, handleToggleDialog, translations }) => {
	const editor = useSlate();
	const { removeTable } = useTable(editor);

	if (!open) {
		return null;
	}

	return (
		<Dialog
			className={styles.addLinkDialog}
			title={translations.deleteTable}
			width={DIALOG_WIDTH}
			headerDivider={true}
			footerDivider={true}
			closeIcon={false}
			onSubmit={removeTable}
			onClose={handleToggleDialog}
			actions={
				<div className={styles.wrapper}>
					<Button fillMode="flat" onClick={handleToggleDialog}>
						{translations.cancel}
					</Button>
					<Button fillMode="outline" onClick={removeTable}>
						{translations.delete}
					</Button>
				</div>
			}
		>
			<div className={styles.content}> {translations.deleteTableDescription}</div>
		</Dialog>
	);
};
