import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { useMemo } from 'react';
import _sortBy from 'lodash/sortBy';

import { DetailsViewNavigationFieldsBlock } from '@/app/_common/_components/details-view/components/details-view-navigation-fields/components/details-view-navigation-fields-block';
import { Namespaces } from '@/translations/namespaces';
import { UserPreferenceStore } from '@/app/_common/stores';
import { detailsViewTypenameToFieldsBlockNameMapping } from '@/app/_common/_components/details-view/constants';
import { getDetailsViewTypename, isDetailsViewItemIgnoreField } from '@/app/_common/_components/details-view/utils';
import { DetailsViewItemData, DetailsViewItemDataPropertyPaths, DetailsViewNavigationField } from '@/app/_common/_components/details-view/types';
import { ResultsFieldValue } from '@/app/_common/types';
import { isInternalColumn } from '@/app/_common/utils';
import { isFieldValueEmpty, isIgnoredColumn } from '@/app/_common/_components/query-results/query-results-table/utils';

import styles from './details-view-navigation-fields.module.scss';

interface DetailsViewNavigationFieldsProps {
	itemData: DetailsViewItemData;
}

const SORT_FIELD = 'label';

export const DetailsViewNavigationFields = observer(({ itemData }: DetailsViewNavigationFieldsProps) => {
	const { t } = useTranslation([Namespaces.DetailsView], { keyPrefix: 'expansionPanel' });
	const userPreferenceStore = useInstance(UserPreferenceStore);
	const { favoriteFields } = userPreferenceStore;

	const typename = getDetailsViewTypename(itemData);
	const blockName = detailsViewTypenameToFieldsBlockNameMapping[typename];

	const userFavoriteFields = useMemo(() => {
		if (!blockName) {
			return new Set();
		}

		return new Set(favoriteFields[blockName]);
	}, [blockName, favoriteFields]);

	const [favoriteFieldsBlockData, otherFieldsBlockData] = useMemo(() => {
		const favoriteFieldsData: DetailsViewNavigationField[] = [];
		const otherFieldsData: DetailsViewNavigationField[] = [];

		Object.entries(itemData ?? {})
			.filter(
				([key, value]) =>
					!(
						isFieldValueEmpty(value as ResultsFieldValue) ||
						isIgnoredColumn(key) ||
						isInternalColumn(key) ||
						isDetailsViewItemIgnoreField(key as DetailsViewItemDataPropertyPaths)
					),
			)
			.forEach(([key, value]) => {
				const fieldData = { label: key, value };

				if (userFavoriteFields.has(key)) {
					favoriteFieldsData.push(fieldData);
				} else {
					otherFieldsData.push(fieldData);
				}
			});

		const sortedFavoriteFields = _sortBy(favoriteFieldsData, SORT_FIELD);
		const sortedOtherFields = _sortBy(otherFieldsData, SORT_FIELD);

		return [sortedFavoriteFields, sortedOtherFields];
	}, [itemData, userFavoriteFields]);

	if (!itemData) {
		return null;
	}

	return (
		<div className={styles.container}>
			<DetailsViewNavigationFieldsBlock
				title={t('favoriteFields', { count: favoriteFieldsBlockData.length })}
				fields={favoriteFieldsBlockData}
				blockName={blockName}
			/>
			<DetailsViewNavigationFieldsBlock
				title={t('otherFields', { count: otherFieldsBlockData.length })}
				fields={otherFieldsBlockData}
				blockName={blockName}
			/>
		</div>
	);
});
