import { action, makeObservable } from 'mobx';

import { InvestigationAlertSummary, Mutation, MutationAssignQueryResultToInvestigationArgs } from '@/generated/graphql';

import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { AssignQueryResultToInvestigation } from '@/app/_common/graphql/queries';
import { InvestigationTypename } from '@/app/_common/constants';
import { UpdateListAlertsCacheAlertData } from '@/app/advanced-query/_components/advanced-query-subscriptions/types';
import { updateListAlertsCache } from '@/app/advanced-query/_components/advanced-query-subscriptions/utils';

export class AssignQueryResultsToInvestigationDialogDataStore extends GraphqlBaseDataStore<Mutation, MutationAssignQueryResultToInvestigationArgs> {
	constructor() {
		super();

		makeObservable(this, {
			assign: action,
		});
	}

	async assign(args: Omit<MutationAssignQueryResultToInvestigationArgs, 'tenantId'>) {
		const variables: MutationAssignQueryResultToInvestigationArgs = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationAssignQueryResultToInvestigationArgs>({
			mutation: AssignQueryResultToInvestigation,
			variables,
			update(cache, { data }) {
				const result = data?.assignQueryResultToInvestigation;
				if (!result || result.__typename !== InvestigationTypename.Investigation) {
					return;
				}

				const investigationId = result.id;
				const alerts: UpdateListAlertsCacheAlertData[] = (result.alerts || []).map((item: InvestigationAlertSummary) => ({
					id: item.id,
					state: item.state,
				}));
				updateListAlertsCache(cache, investigationId, alerts, args.alertIds);
			},
		});

		return response;
	}
}
