import { UserRoles } from '@/app/_common/constants';
import { RootPaths } from '@/app/_common/navigation/root-paths';

export const userDefaultScreen: Record<UserRoles, RootPaths> = {
	[UserRoles.FRANCHISE_OWNER]: RootPaths.FRANCHISE_CLIENTS,
	[UserRoles.FRANCHISE_MANAGER]: RootPaths.FRANCHISE_CLIENTS,
	[UserRoles.FRANCHISE_USER]: RootPaths.FRANCHISE_CLIENTS,
	[UserRoles.TENANT_OWNER]: RootPaths.ALERTS_DASHBOARDS,
	[UserRoles.TENANT_USER]: RootPaths.ALERTS_DASHBOARDS,
};
