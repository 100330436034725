export const formatCounterNumber = (number: number, locale: string): string => {
	try {
		const normalizedNumber = Number(number);

		const numberWithFractionDigits = new Intl.NumberFormat(locale, {
			notation: 'compact',
			compactDisplay: 'short',
			style: 'decimal',
			maximumFractionDigits: 1,
		}).format(normalizedNumber);

		const numberWithoutFractionDigits = new Intl.NumberFormat(locale, {
			notation: 'compact',
			compactDisplay: 'short',
			style: 'decimal',
			maximumFractionDigits: 0,
		}).format(normalizedNumber);

		const splitter = /\..+/;
		const integerPart = numberWithFractionDigits.split(splitter)[0];

		return integerPart.length > 1 ? numberWithoutFractionDigits : numberWithFractionDigits;
	} catch (error) {
		return '';
	}
};
