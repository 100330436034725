import { FC, useCallback, useState } from 'react';

import { Popup } from '@progress/kendo-react-popup';
import { User } from '@/app/_common/interfaces/auth-details';
import { Divider } from '@/app/_common/_components/divider';

import { UserInfo, UserSignOut, UserPreferences, UserTenants, SwitchMode } from '.';

import '@/assets/styles/index.scss';
import styles from './user-tile.module.scss';
import { observer } from 'mobx-react-lite';
import { useOutsideClick } from '@/app/_common/hooks';
import classNames from 'classnames';
import { ThemeStore } from '@/app/_common/stores';
import { useInstance } from 'react-ioc';

export interface UserTileProps {
	user: User;
}

export const UserTile: FC<UserTileProps> = observer(({ user }) => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const themeStore = useInstance(ThemeStore);

	const { popupRef, anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		showDropdown,
		useCallback(() => setShowDropdown(false), []),
	);

	const toggleDropdown = () => {
		setShowDropdown((showDropdown) => !showDropdown);
	};

	return (
		<>
			<div className={styles.userTile} ref={anchorRef} onClick={toggleDropdown} onKeyDown={toggleDropdown} data-testid="user-info">
				<UserInfo user={user} opened={showDropdown} />
			</div>

			<Popup
				className={styles.popup}
				style={{ width: `${anchorRef.current?.offsetWidth ?? 300}px` }}
				anchor={anchorRef.current}
				show={showDropdown}
				popupClass={classNames(styles.userTilePopup, [styles[themeStore.theme]])}
			>
				<div ref={popupRef}>
					<UserPreferences />
					<UserSignOut />
					<Divider />
					<SwitchMode />
					<UserTenants toggleDropdown={toggleDropdown} />
				</div>
			</Popup>
		</>
	);
});
