import { FC, useState, ChangeEvent } from 'react';

import classNames from 'classnames';

import styles from './confirm-action-input.module.scss';

interface ConfirmActionInputProps {
	onConfirmedValue: (confirmed: boolean) => void;
	expectedValue?: string | null;
	explanation: string;
	label: string;
	placeholder?: string;
	errorMessage: string;
	className?: string;
}

export const ConfirmActionInput: FC<ConfirmActionInputProps> = ({
	expectedValue,
	onConfirmedValue,
	explanation,
	label,
	placeholder,
	errorMessage,
	className,
}) => {
	const [value, setValue] = useState<string>('');

	const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;

		setValue(newValue);

		if (!expectedValue) {
			return;
		}

		if (expectedValue === newValue) {
			onConfirmedValue(true);
		} else {
			onConfirmedValue(false);
		}
	};

	const hasMatch = expectedValue && value === expectedValue;
	const hasError = !hasMatch && Boolean(value);

	return (
		<div className={classNames(styles.container, className)} data-testid="confirm-action">
			<div className={styles.subcontainer}>
				<div className={styles.explanation}>{explanation}</div>

				<div className={classNames(styles.inputContainer, { [styles.error]: hasError })}>
					<label htmlFor="test-input">{label}</label>
					<input
						id="test-input"
						className={classNames(styles.valueInput, { [styles.success]: hasMatch })}
						type="text"
						value={value}
						placeholder={placeholder}
						onChange={handleValueChange}
					/>
					{hasError && <p className={styles.errorMessage}>{errorMessage}</p>}
				</div>
			</div>
		</div>
	);
};
