import { FetchBaseErrorKey } from '@/app/_common/types';

export const FETCH_BASE_ERROR_TITLE_KEYS = {
	[FetchBaseErrorKey.Default]: 'fetchBaseErrorTitles.default',
	[FetchBaseErrorKey.AdvancedQueryResults]: 'fetchBaseErrorTitles.advancedQuery.results',
	[FetchBaseErrorKey.AvailableFields]: 'fetchBaseErrorTitles.advancedQuery.availableFields',
	[FetchBaseErrorKey.AdvancedQueryChart]: 'fetchBaseErrorTitles.advancedQuery.graph',
	[FetchBaseErrorKey.AssignQueryResult]: 'assignQueryResult.error.title',
};

export const FETCH_BASE_ERROR_CONTENT_KEYS = {
	[FetchBaseErrorKey.AssignQueryResult]: 'assignQueryResult.error.content',
};
