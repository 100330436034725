import { observer } from 'mobx-react-lite';

import { QueryResultsTable, QueryResultsTableProps } from '@/app/_common/_components/query-results/query-results-table/query-results-table';
import { QueryResultsTableViewStore } from '@/app/_common/_components/query-results/query-results-table/stores';
import { QueryResultsTableStoreProvider } from '@/app/_common/_components/query-results/query-results-table/components';
import AlertsActionsModule from '@/app/_features/alerts-actions/alerts-actions-module';
import { mutationConfig } from '@/app/dashboards/alerts-dashboard/_common/constants';

interface QueryResultsTableModuleProps extends Omit<QueryResultsTableProps, 'isRowSelected' | 'toggleSelectedRow'> {
	store: QueryResultsTableViewStore;
}

export const QueryResultsTableModule = observer(({ store, ...props }: QueryResultsTableModuleProps) => {
	return (
		<AlertsActionsModule mutationConfig={mutationConfig}>
			<QueryResultsTableStoreProvider store={store}>
				<QueryResultsTable {...props} />
			</QueryResultsTableStoreProvider>
		</AlertsActionsModule>
	);
});

QueryResultsTableModule.displayName = 'QueryResultsTableModule';
