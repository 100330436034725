import { gql } from '@apollo/client';

export const ListTenants = gql`
	query ListTenants($franchiseId: ID!, $filtersInput: FiltersInput, $input: Paging) {
		listTenants(franchiseId: $franchiseId, filtersInput: $filtersInput, input: $input) {
			edges {
				node {
					hasCompletedRegistration
					id
					name
					ownerUser {
						id
						upn
					}
					timestamp
					registeredTimestamp
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
`;

export const ListTenantsBasicInfo = gql`
	query ListTenants($franchiseId: ID!, $filtersInput: FiltersInput, $input: Paging) {
		listTenants(franchiseId: $franchiseId, filtersInput: $filtersInput, input: $input) {
			edges {
				node {
					hasCompletedRegistration
					id
					name
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
`;

export const SignupTenant = gql`
	mutation SignupTenant($franchiseId: ID!, $signupTenantInput: SignupTenantInput!) {
		signupTenant(franchiseId: $franchiseId, signupTenantInput: $signupTenantInput) {
			... on SignupTenant {
				tenantId
				tenantName
			}

			... on Error {
				message
				code
				correlationId
				additionalInformation {
					name
					value
				}
			}
		}
	}
`;
