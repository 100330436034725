import { action, makeObservable, computed } from 'mobx';

import { Query, QueryGetInvestigationArgs } from '@/generated/graphql';

import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { GetInvestigation, GetInvestigationName } from '@/app/_common/graphql/queries';
import { RootPaths } from '@/app/_common/navigation';

export type GetInvestigation = Pick<Query, 'getInvestigation'>;

export class InvestigationDetailsDataStore extends GraphqlBaseDataStore<GetInvestigation, QueryGetInvestigationArgs> {
	constructor() {
		super();

		makeObservable(this, {
			investigation: computed,
			read: action,
		});
	}

	get investigation() {
		if (this.data?.getInvestigation?.__typename === 'Investigation') {
			return this.data?.getInvestigation;
		}

		return {};
	}

	read(id?: string, fetchOnlyInvestigationName = false) {
		const getInvestigationId = () => {
			if (id) {
				return id;
			}

			return window.location.pathname.includes(RootPaths.INVESTIGATIONS) ? window.location.pathname.split('/').pop() : undefined;
		};

		if (!getInvestigationId()) {
			return;
		}

		const variables = {
			id: getInvestigationId() || '',
			tenantId: this.authStore.currentTenantId,
		};

		const GetInvestigationQuery = GetInvestigation;

		this.query({
			query: fetchOnlyInvestigationName ? GetInvestigationName : GetInvestigationQuery,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}
}
