import { Tooltip } from '@/app/_common/_components';

import styles from './tab-menu-tab-title.module.scss';

interface TabMenuTabTitleProps {
	title: string;
	disabled: boolean;
	tooltip?: string;
}

export const TabMenuTabTitle = ({ disabled, title, tooltip }: TabMenuTabTitleProps) => {
	return (
		<div data-tip data-for={title}>
			{title}
			{disabled ? <Tooltip tooltipId={title} effect="solid" tooltipContent={tooltip} className={styles.tooltip} /> : null}
		</div>
	);
};
