import { SortDescriptor } from '@progress/kendo-data-query';

import { SortDirection, FieldSort } from '@/generated/graphql';
import { DEFAULT_FIELD_PREFIX } from '@/app/_common/constants';

import { SortDirection as KendoSortDirection } from '@/app/_common/constants/data-grid.constants';

export class KendoServerSortAdapter {
	private static directionsMap: Map<KendoSortDirection | undefined, SortDirection | undefined> = new Map([
		[KendoSortDirection.Asc, SortDirection.Asc],
		[KendoSortDirection.Desc, SortDirection.Desc],
		[undefined, undefined],
	]);
	static mapDirection(direction: SortDescriptor['dir']): SortDirection | undefined {
		return KendoServerSortAdapter.directionsMap.get(direction as KendoSortDirection);
	}

	static mapFieldSort(fieldSort: SortDescriptor, removablePrefix = DEFAULT_FIELD_PREFIX): FieldSort | null {
		const newDirection = KendoServerSortAdapter.mapDirection(fieldSort.dir);
		const field = fieldSort.field.replace(removablePrefix, '');

		if (newDirection === undefined) {
			return null;
		}

		return {
			field,
			direction: newDirection,
		};
	}
}
