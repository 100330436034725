import { ApolloCache, gql } from '@apollo/client';

import { readAlertFragmentById } from '@/app/_common/utils';

const AlertStateFragment = gql`
	fragment Alert on Alert {
		id
		state {
			alertState
		}
	}
`;

export const readAlertStateFragmentById = (cache: ApolloCache<unknown>, id: string | undefined) => {
	return readAlertFragmentById(cache, id, AlertStateFragment);
};
