import { ContextMenuLabel } from '@/app/_common/_components/new-context-menu/_components';
import { useActionLabel } from '@/app/_features/alerts-actions-menu/_components/action-label/hooks';
import { ALERT_CONTEXT_MENU_ACTIONS } from '@/app/_features/alerts-actions-menu/_components/action-label/types';

interface AssignActionLabelProps {
	action: ALERT_CONTEXT_MENU_ACTIONS;
	count?: number;
}

export const ActionLabel = ({ action, count }: AssignActionLabelProps) => {
	const { icon: Icon, label } = useActionLabel(action, count);

	return <ContextMenuLabel prefixIcon={<Icon />}>{label}</ContextMenuLabel>;
};
