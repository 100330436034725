import { FC, ReactNode } from 'react';
import { useFocused, useSelected, useSlateStatic } from 'slate-react';

import LinkOffIcon from '@mui/icons-material/LinkOff';

import { useLink, UnknownObject } from '@/app/_common/_components/rich-text-editor';

import styles from './link.module.scss';

interface LinkProps {
	attributes: UnknownObject;
	element: UnknownObject;
	children: ReactNode;
}

export const Link: FC<LinkProps> = ({ attributes, element, children }) => {
	const editor = useSlateStatic();
	const selected = useSelected();
	const focused = useFocused();
	const { removeLink } = useLink();

	return (
		<span className={styles.link}>
			<a href={element.href} {...attributes}>
				{children}
			</a>
			{selected && focused && (
				<div className={styles.linkPopup} contentEditable="false">
					<a href={element.href} target="_blank" rel="noreferrer">
						{element.href}
					</a>
					<button className={styles.unLinkBtn} onClick={() => removeLink(editor)}>
						<LinkOffIcon />
					</button>
				</div>
			)}
		</span>
	);
};
