import { FC, memo } from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import classNames from 'classnames';

import { ContextMenuComponentProps } from '@/app/_common/types';
import { GridCellProps } from '@progress/kendo-react-grid';
import { HighlightedText } from '@/app/_common/_components/data-grid/_components/highlighted-text';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/app/_common/constants';

import { DataCell } from '@/app/_common/_components/data-grid/_components';

import styles from '@/app/_common/_components/data-grid/_components/date-time-cell/date-time-cell.module.scss';

const determineFormat = (showTime: boolean): string => {
	return showTime ? DATE_TIME_FORMAT : DATE_FORMAT;
};

export interface DateTimeCellProps extends GridCellProps {
	showTime?: boolean;
	tooltip?: string;
	className?: string;
	onClick?: () => void;
	searchValue?: string;
	ContextMenuComponent?: React.FC<ContextMenuComponentProps>;
	oldContextMenu?: boolean;
}

export const DateTimeCell: FC<DateTimeCellProps> = memo(
	({ field, dataItem, tooltip, showTime = true, className, onClick, searchValue = '', ContextMenuComponent, ...rest }) => {
		const value = _get(dataItem, field || '');

		if (_isNil(value)) {
			return (
				<DataCell
					{...rest}
					onClick={onClick}
					dataItem={dataItem}
					field={field}
					title={tooltip || ''}
					className={classNames(styles.dateTimeCell, className)}
					empty={true}
					ContextMenuComponent={ContextMenuComponent}
				/>
			);
		}

		const date = moment(value).parseZone().format(determineFormat(showTime));
		const highlightedValue = searchValue ? <HighlightedText value={date} searchLine={searchValue} /> : date;
		const isCellHighlighted = searchValue && String(date).toLowerCase().includes(searchValue.toLowerCase());

		return (
			<DataCell
				{...rest}
				onClick={onClick}
				title={tooltip || date}
				className={classNames(styles.dateTimeCell, { isCellHighlighted }, className)}
				field={field}
				dataItem={dataItem}
				ContextMenuComponent={ContextMenuComponent}
			>
				{highlightedValue}
			</DataCell>
		);
	},
);

DateTimeCell.displayName = 'DateTimeCell';
