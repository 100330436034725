import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

import { FilterOption, MitreTacticsValue } from '@/app/_common/types';
import { MITRE_TACTICS_PRIORITY } from '@/app/_features/alerts/_common/constants';

export const sortMitreTacticsFilter = (data: FilterOption[], path: string) => {
	return _sortBy(data, (item) => {
		const value = _get(item, path);
		return MITRE_TACTICS_PRIORITY.indexOf(value as MitreTacticsValue);
	});
};
