import { makeObservable, reaction } from 'mobx';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { Alert, AlertEdge, Investigation } from '@/generated/graphql';

import { AlertsDataStore } from './alerts.data-store';
import { InvestigationsDataStore } from './investigations.data-store';
import { AlertsDataGridViewStore as NewAlertsDataGridViewStore } from '@/app/_features/alerts-actions/_common/stores';

export class AlertsDataGridViewStore extends NewAlertsDataGridViewStore {
	private alertsDataStore = injectInterface(this, AlertsDataStore);
	private investigationsDataStore = injectInterface(this, InvestigationsDataStore);

	constructor() {
		super();
		makeObservable(this, {});
	}

	setSourceData = () => {
		const alerts: Alert[] = (this.alertsDataStore.alerts?.listAlerts.edges || []).map((item: AlertEdge) => item.node);
		const investigations: Investigation[] = Object.values(this.investigationsDataStore.investigations) || [];

		super.setSourceData(
			alerts.map((alert) => {
				const alertInvestigationSummary = alert.investigationSummary;

				if (alertInvestigationSummary) {
					const investigation = investigations.find((investigation) => investigation.id === alertInvestigationSummary.id);
					const name = investigation?.name;
					const status = investigation?.status;

					if (name && status) {
						return {
							...alert,
							investigationSummary: {
								...alertInvestigationSummary,
								name,
								status,
							},
						};
					}
				}

				return alert;
			}),
		);
	};

	private selectAllAlerts = () => {
		if (this.sourceData) {
			this.selectRows(
				this.sourceData.reduce<{ [key: string]: boolean }>((result, alert) => {
					result[alert.id] = true;
					return result;
				}, {}),
			);
		}
	};

	alertsDataDisposer = reaction(
		() => this.alertsDataStore.alerts,
		() => {
			this.setSourceData();
			this.selectAllAlerts();
		},
	);

	investigationsDataDisposer = reaction(
		() => this.investigationsDataStore.investigations,
		() => {
			this.setSourceData();
		},
	);

	dispose = () => {
		this.alertsDataDisposer();
		this.investigationsDataDisposer();
	};
}
