import { action, computed, makeObservable } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { AppConfiguration, FeatureFlag, Query, QueryGetAppConfigurationArgs } from '@/generated/graphql';
import { GetAppConfiguration } from '@/app/_common/graphql/queries';
import { FeaturesFlag } from '@/app/_common/interfaces';

interface StoreAppConfiguration {
	restrictedFeatures: FeaturesFlag[];
}

/**
 * Requests to AppConfiguration service are limited to 1000 per month!
 * Thus, for local development switch off requests and use default data.
 */
const isAppConfigurationEnabled = process.env.REACT_APP_CONFIGURATION_ENABLED === 'true';
const RESTRICTED_FEATURES_FOR_LOCAL_DEVELOPMENT: FeaturesFlag[] = []; // Always keep it empty to enable all features on localhost
/** Add your feature flags here below */
const RESTRICTED_FEATURES: FeaturesFlag[] = [FeaturesFlag.COMPROMISE_MONITOR_ENABLED];
const INITIAL_APP_CONFIGURATION: StoreAppConfiguration = {
	restrictedFeatures: isAppConfigurationEnabled ? RESTRICTED_FEATURES : RESTRICTED_FEATURES_FOR_LOCAL_DEVELOPMENT,
};

export class AppConfigurationDataStore extends GraphqlBaseDataStore<Query, QueryGetAppConfigurationArgs> {
	constructor() {
		super();

		makeObservable(this, {
			appConfiguration: computed,
			restrictedFeatures: computed,
			read: action,
		});
	}

	get appConfiguration(): StoreAppConfiguration {
		if (this.data?.getAppConfiguration.__typename === 'Error') {
			return INITIAL_APP_CONFIGURATION;
		} else if (this.data?.getAppConfiguration.__typename === 'AppConfiguration') {
			const restrictedFeatures = this.getRestrictedFeatureFlagsFromData(this.data.getAppConfiguration);

			return {
				restrictedFeatures,
			};
		} else {
			return INITIAL_APP_CONFIGURATION;
		}
	}

	get restrictedFeatures(): FeaturesFlag[] {
		return this.appConfiguration.restrictedFeatures;
	}

	read(currentTenantId: string) {
		/**
		 * Requests to AppConfiguration service are limited to 1000 per month.
		 * Thus, for local development switch off requests and use default data.
		 */
		if (!isAppConfigurationEnabled) {
			return;
		}
		if (currentTenantId) {
			const variables = {
				tenantId: currentTenantId,
			};

			this.query({
				query: GetAppConfiguration,
				fetchPolicy: 'network-only',
				variables,
			});
		}
	}

	private getRestrictedFeatureFlagsFromData = (data: AppConfiguration): FeaturesFlag[] => {
		const enabledFeaturesFromData: FeaturesFlag[] = data.featureFlags
			.filter((flag: FeatureFlag) => flag.enabled)
			.map((enabledFlag: FeatureFlag) => FeaturesFlag[enabledFlag.key as keyof typeof FeaturesFlag] as unknown as FeaturesFlag);

		return RESTRICTED_FEATURES.filter((restrictedFlag: FeaturesFlag) => {
			return !enabledFeaturesFromData.includes(restrictedFlag);
		});
	};
}
