import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { AlertsListFilter } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/alerts-list-filter';
import { AlertsHeaderFilter } from '@/app/dashboards/alerts-dashboard/_common/constants/alerts.constant';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '@/translations/namespaces';

import styles from './list-header.module.scss';

interface ListHeaderProps {
	countLabel?: string;
	selectedCount?: number;
	newCount?: number;
	openCount?: number;
	dismissedCount?: number;
	appliedFilters?: AlertsHeaderFilter[];
	availableFilters?: AlertsHeaderFilter[];
	title?: string;
	onFilterChange?: (filter: AlertsHeaderFilter) => void;
	showFilters?: boolean;
	isSelectedRowsFilterActive?: boolean;
	loading?: boolean;
	'data-testid'?: string;
	children?: ReactNode;
}

interface FilterCheckbox {
	label: AlertsHeaderFilter;
	value: AlertsHeaderFilter;
	counter: number;
}

export const ListHeader: FC<ListHeaderProps> = ({
	countLabel,
	selectedCount = 0,
	newCount = 0,
	openCount = 0,
	dismissedCount = 0,
	appliedFilters = [],
	availableFilters = [],
	title,
	onFilterChange = () => undefined,
	showFilters = true,
	isSelectedRowsFilterActive = false,
	children,
	'data-testid': dataTestId,
}) => {
	const { t } = useTranslation(Namespaces.ListHeader, { keyPrefix: 'filters' });
	const counters = new Map<AlertsHeaderFilter, number>([
		[AlertsHeaderFilter.Selected, selectedCount],
		[AlertsHeaderFilter.Unassigned, newCount],
		[AlertsHeaderFilter.Assigned, openCount],
		[AlertsHeaderFilter.Dismissed, dismissedCount],
	]);

	const [filterBlockFilters, showBlockFilters]: [FilterCheckbox[], FilterCheckbox[]] = availableFilters.reduce(
		(acc: [FilterCheckbox[], FilterCheckbox[]], filter: AlertsHeaderFilter) => {
			const mappedFilter = { label: filter, value: filter, counter: counters.get(filter) ?? 0 };
			if (filter === AlertsHeaderFilter.Selected) {
				acc[0].push(mappedFilter);
				return acc;
			}
			acc[1].push(mappedFilter);
			return acc;
		},
		[[], []],
	);

	return (
		<div data-testid={dataTestId} className={`${!showFilters ? styles.noFiltersListHeader : styles.listHeader}`}>
			{title && <div className={styles.title}>{title}</div>}
			{countLabel ? <div className={classNames(styles.label, styles.totalLabel)}>{countLabel}</div> : ''}
			{showFilters && (
				<div className={styles.filters}>
					{filterBlockFilters?.length > 0 && <div className={styles.label}>{t('filter')}</div>}
					{filterBlockFilters.map(({ label, counter, value }) => (
						<AlertsListFilter
							key={label}
							label={label}
							value={value}
							counter={counter}
							onToggle={onFilterChange}
							checked={isSelectedRowsFilterActive}
						/>
					))}

					{showBlockFilters?.length > 0 && <div className={styles.label}>{t('show')}</div>}
					{showBlockFilters.map(({ label, counter, value }) => (
						<AlertsListFilter
							key={label}
							label={label}
							value={value}
							counter={counter}
							onToggle={onFilterChange}
							checked={appliedFilters.includes(value)}
						/>
					))}
				</div>
			)}
			{children}
		</div>
	);
};
