import { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PersonIcon from '@mui/icons-material/Person';

import { Namespaces } from '@/translations/namespaces';
import { BoxContainer } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alert-host-user-box/_components/box-container';
import { ShowMore } from '@/app/_common/_components/show-more/show-more';
import { BlockItem } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alert-host-user-box/_components/block-item';
import { useOutsideClick } from '@/app/_common/hooks';

import styles from './alert-user-box.module.scss';

interface AlertUserBoxProps {
	usernames: string[];
}

export const AlertUserBox: FC<AlertUserBoxProps> = ({ usernames }) => {
	const { t } = useTranslation(Namespaces.DetailsView);
	const [expanded, setExpanded] = useState(false);

	const uniqueUsernames: string[] = Array.from(new Set(usernames));
	const usernamesText = uniqueUsernames.join(', ');

	const handleShowMore = useCallback(() => {
		setExpanded(true);
	}, []);

	const handleShowLess = useCallback(() => {
		setExpanded(false);
	}, []);

	const { popupRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(expanded, handleShowLess);

	if (!uniqueUsernames?.length) {
		return null;
	}

	return (
		<div className={classNames(styles.wrapper, { [styles.showMore]: expanded })}>
			<BoxContainer
				containerClassName={classNames(styles.container, { [styles.expanded]: expanded })}
				contentClassName={styles.content}
				icon={<PersonIcon />}
				ref={popupRef}
			>
				<BlockItem
					className={styles.blockItem}
					textClassName={styles.blockItemText}
					titleClassName={styles.blockItemTitle}
					title={t('user.username')}
					text={
						<div className={styles.usernames}>
							{expanded ? (
								<ul className={styles.usernamesList}>
									{usernames.map((user) => (
										<li key={user}>{user}</li>
									))}
								</ul>
							) : (
								<ShowMore charactersCount={70} text={usernamesText} isOneLineOnCollapse={false} onToggle={handleShowMore} />
							)}
						</div>
					}
				/>
			</BoxContainer>
		</div>
	);
};
