import { Investigation } from '@/generated/graphql';
import { isActiveAlertTheOnlyOne, isAlertInSelected, isAlertUnassignable } from '@/app/dashboards/alerts-dashboard/_common/utils';
import { DetailsViewItemData } from '@/app/_common/_components/details-view/types';

export const areAlertsUnassignable = (activeAlert?: DetailsViewItemData, alerts?: DetailsViewItemData[], investigations?: Investigation[]) => {
	if (!alerts || alerts.length === 0 || !activeAlert) {
		return false;
	}

	if (isActiveAlertTheOnlyOne(activeAlert, alerts) || !isAlertInSelected(activeAlert, alerts)) {
		return false;
	}

	return alerts.some((alert) => isAlertUnassignable(alert, investigations));
};
