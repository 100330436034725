import React from 'react';
import { useTranslation } from 'react-i18next';

import { AvailableFieldsList } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/available-fields-list';
import { AVAILABLE_FIELDS_BLOCK_NAME, AvailableField, NotificationMessage } from '@/app/_common/types';
import { AvailableFieldsBlockHeader } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components';
import { Namespaces } from '@/translations/namespaces';

interface AvailableFieldsBlockProps {
	blockName: AVAILABLE_FIELDS_BLOCK_NAME;
	favoriteFields: AvailableField[];
	otherFields: AvailableField[];
	toggleHeaderExpandedState: () => void;
	toggleOtherFieldsExpandedState: () => void;
	isHeaderExpanded: boolean;
	isOtherFieldsExpanded: boolean;
	favoriteFieldsLoading?: boolean;
	otherFieldsLoading?: boolean;
	favoriteFieldsError?: NotificationMessage;
	otherFieldsError?: NotificationMessage;
	stickyClassName?: string;
}

export const AvailableFieldsBlock = ({
	blockName,
	favoriteFields,
	otherFields,
	favoriteFieldsLoading,
	otherFieldsLoading,
	favoriteFieldsError,
	otherFieldsError,
	toggleHeaderExpandedState,
	toggleOtherFieldsExpandedState,
	isHeaderExpanded,
	isOtherFieldsExpanded,
	stickyClassName,
}: AvailableFieldsBlockProps) => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: 'tabs.advancedQuery' });

	return (
		<>
			<AvailableFieldsBlockHeader
				blockName={blockName}
				isExpanded={isHeaderExpanded}
				toggleExpandedState={toggleHeaderExpandedState}
				stickyClassName={stickyClassName}
			/>

			{isHeaderExpanded && (
				<>
					<AvailableFieldsList
						blockName={blockName}
						header={t('availableFields.fields.favorite')}
						fields={favoriteFields}
						loading={favoriteFieldsLoading}
						error={favoriteFieldsError}
						data-testid={`available-fields-${blockName}-favorite`}
						loaders={1}
						isExpanded={true}
					/>

					<AvailableFieldsList
						blockName={blockName}
						header={t('availableFields.fields.other')}
						fields={otherFields}
						loading={otherFieldsLoading}
						error={otherFieldsError}
						data-testid={`available-fields-${blockName}-other`}
						loaders={1}
						isExpanded={isOtherFieldsExpanded}
						toggleExpandedState={toggleOtherFieldsExpandedState}
					/>
				</>
			)}
		</>
	);
};
