import { Integration } from '@/generated/graphql';

import { isLocalhost } from '@/app/_common/utils';

import { getTelemetryIntegrationType } from '@/app/_features/integration/_common/utils';
import { TelemetryIntegrationType } from '@/app/_features/integration/_common/types';

export const getTelemetryIntegrationDisplayName = (integration: Integration): string => {
	const { type, sourceType, collector, vendor, productName } = integration;
	const collectorType = collector?.type;

	const telemetryIntegrationType = getTelemetryIntegrationType(type, sourceType, collectorType);
	const displayNameFallbackValue = `${vendor ?? ''} ${productName ?? ''}`.trim();

	switch (telemetryIntegrationType) {
		case TelemetryIntegrationType.SecureSyslog: {
			if (!integration.hostname) {
				return displayNameFallbackValue;
			}

			const isLocalhostHostname = isLocalhost(integration.hostname);

			if (isLocalhostHostname) {
				return displayNameFallbackValue;
			}

			return integration.hostname;
		}

		case TelemetryIntegrationType.Cloud:
		case TelemetryIntegrationType.Unknown:
		default:
			return integration.name ?? displayNameFallbackValue;
	}
};
