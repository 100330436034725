import i18n from '@/translations/i18n';

import { History, BusinessCenter, Group } from '@mui/icons-material';
import { RootPaths } from '@/app/_common/navigation/root-paths';
import { Namespaces } from '@/translations/namespaces';
import { MenuItem } from './interfaces';

export const franchiseMenuItems: MenuItem[] = [
	{
		text: i18n.t('franchiseSidebar.clients', { ns: Namespaces.Sidebar }),
		Icon: BusinessCenter,
		route: RootPaths.FRANCHISE_CLIENTS,
		hasNestedDynamicSubroutes: true,
	},
	{
		text: i18n.t('franchiseSidebar.team', { ns: Namespaces.Sidebar }),
		Icon: Group,
		route: RootPaths.FRANCHISE_TEAM,
		hasNestedDynamicSubroutes: true,
	},
	{
		text: i18n.t('franchiseSidebar.actionHistory', { ns: Namespaces.Sidebar }),
		Icon: History,
		route: RootPaths.FRANCHISE_ACTION_HISTORY,
		hasNestedDynamicSubroutes: true,
	},
];
