import { FC } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT } from '@/app/_common/constants';
import { ThreatIntelligenceHit } from '@/app/_common/types';
import { buttonize } from '@/app/_common/utils';
import { Namespaces } from '@/translations/namespaces';

import styles from './threat-intelligence-panel-content-multiple-values.module.scss';

const UNSELECTED_HIT_INDEX = -1;

interface ThreatIntelligencePanelContentMultipleMatchesProps {
	multipleHits: ThreatIntelligenceHit[];
	className?: string;
	selectedHit: number | null;
	handleSelectHit: (hitIndex: number) => void;
}

export const ThreatIntelligencePanelContentMultipleMatches: FC<ThreatIntelligencePanelContentMultipleMatchesProps> = ({
	multipleHits,
	className = '',
	selectedHit,
	handleSelectHit,
}) => {
	const { t } = useTranslation(Namespaces.DetailsView, { keyPrefix: 'threatIntelligencePanel' });

	return (
		<div className={className}>
			<h4> {t('multipleMatches')}</h4>
			<div className={styles.labelsContainer}>
				<span
					{...buttonize(handleSelectHit.bind(null, UNSELECTED_HIT_INDEX))}
					className={classNames(styles.label, { [styles.selected]: selectedHit === UNSELECTED_HIT_INDEX })}
				>
					{t('summary')}
				</span>
				{multipleHits.map((hit, index) => {
					return (
						<span
							{...buttonize(handleSelectHit.bind(null, index))}
							key={index}
							className={classNames(styles.label, { [styles.selected]: selectedHit === index })}
						>
							{hit.created_at ? moment(hit.created_at).format(DATE_FORMAT) : index + 1}
						</span>
					);
				})}
			</div>
		</div>
	);
};
