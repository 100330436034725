import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { resultsTableToDetailsViewTypenameMapping } from '@/app/_common/_components/details-view/constants';
import { ResultsDataItem } from '@/app/_common/types';
import { ResultsTablePropertiesPaths } from '@/app/_common/constants';

export const getAdvancedQueryDetailsViewTypename = (item?: ResultsDataItem): DetailsViewTypename => {
	if (!item?.[ResultsTablePropertiesPaths.Table]) {
		return DetailsViewTypename.UNKNOWN;
	}

	try {
		const tableName = String(item[ResultsTablePropertiesPaths.Table]);
		return resultsTableToDetailsViewTypenameMapping[tableName] || resultsTableToDetailsViewTypenameMapping.default;
	} catch (error) {
		return DetailsViewTypename.UNKNOWN;
	}
};
