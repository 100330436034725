import { gql } from '@apollo/client';
import _uniq from 'lodash/uniq';

import {
	CoreGetInvestigationFragment,
	CoreGetInvestigationQueryResultsFragment,
	CoreInvestigationAlertSummaryFragment,
	CoreInvestigationFragment,
} from '@/app/_common/graphql/fragments.constants';

export const GetInvestigation = gql`
	query GetInvestigation($tenantId: ID!, $id: ID!) {
		getInvestigation(tenantId: $tenantId, id: $id) {
			... on Investigation {
				...CoreGetInvestigationFragment
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
	${CoreGetInvestigationFragment}
`;

export const GetInvestigationName = gql`
	query GetInvestigationName($tenantId: ID!, $id: ID!) {
		getInvestigation(tenantId: $tenantId, id: $id) {
			... on Investigation {
				id
				name
			}
		}
	}
`;

export const GetInvestigationBreadcrumb = gql`
	query GetInvestigationBreadcrumb($tenantId: ID!, $id: ID!) {
		getInvestigation(tenantId: $tenantId, id: $id) {
			... on Investigation {
				id
				name
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const UpdateInvestigation = gql`
	mutation UpdateInvestigation($tenantId: ID!, $updateInvestigation: UpdateInvestigation!) {
		updateInvestigation(tenantId: $tenantId, updateInvestigation: $updateInvestigation) {
			... on Investigation {
				...CoreGetInvestigationFragment
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
	${CoreGetInvestigationFragment}
`;

export const CreateInvestigation = gql`
	mutation CreateInvestigation($tenantId: ID!, $name: String!, $priority: InvestigationPriority!, $type: InvestigationType!, $assigneeId: ID) {
		createInvestigation(tenantId: $tenantId, name: $name, priority: $priority, type: $type, assigneeId: $assigneeId) {
			... on Error {
				message
				code
				correlationId
			}
			... on Investigation {
				id
				name
			}
		}
	}
`;

export const CreateInvestigationWithQueryResults = gql`
	mutation CreateInvestigationWithQueryResults(
		$tenantId: ID!
		$name: String!
		$priority: InvestigationPriority!
		$type: InvestigationType!
		$assigneeId: ID
		$alertIds: [ID!]
		$queryResultInput: QueryResultInput
	) {
		createInvestigation(
			tenantId: $tenantId
			name: $name
			priority: $priority
			type: $type
			assigneeId: $assigneeId
			alertIds: $alertIds
			queryResultInput: $queryResultInput
		) {
			... on Error {
				message
				code
				correlationId
			}
			... on Investigation {
				id
				name
				queryResults {
					id
				}
				last_updated
				alerts {
					id
					state {
						alertState
						requesterUser {
							upn
							id
						}
						timestamp
					}
				}
			}
		}
	}
`;

export function createGetInvestigationsByIdsQuery(ids: string[]) {
	const queries = _uniq(ids).map(
		(id, index) => `getInvestigation${index}: getInvestigation(tenantId: $tenantId, id: "${id}") {
			... on Investigation {
        id
        name
        status
        alerts {
        	id
        }
      }
		}`,
	);

	const queryContent = 'query GetInvestigationsByIds($tenantId: ID!) {' + queries.join('\n') + '}';

	const query = gql`
		${queryContent}
	`;

	return query;
}

export function createUnassignAlertsFromInvestigationMutation(investigationsWithAlerts: Array<[investigationId: string, alertIds: string[]]>) {
	const mutations = investigationsWithAlerts.map(([investigationId, alertIds], index) => {
		const alertIdsString = `[${alertIds.map((id) => '"' + id + '"')}]`;

		return `setAlertsOnInvestigation${index}: setAlertsOnInvestigation(tenantId: $tenantId, investigationId: "${investigationId}", alertIds: ${alertIdsString}) {
						... on Investigation {
							id
							alerts {
								...CoreInvestigationAlertSummaryFragment
							}
							last_updated
						}
						... on Error {
							message
							code
							correlationId
						}
					}`;
	});

	const mutationContent = 'mutation UnassignAlertsFromInvestigation($tenantId: ID!) {' + mutations.join('\n') + '}';

	return gql`
		${mutationContent}
		${CoreInvestigationAlertSummaryFragment}
	`;
}

export const GetAllInvestigations = gql`
	query GetAllInvestigations($tenantId: ID!, $filtersInput: FiltersInput, $input: Paging) {
		listInvestigations(tenantId: $tenantId, filtersInput: $filtersInput, input: $input) {
			pageInfo {
				hasPreviousPage
				hasNextPage
				startCursor
				endCursor
			}
			edges {
				node {
					...CoreInvestigationFragment
				}
			}
		}
	}
	${CoreInvestigationFragment}
`;

export const ListInvestigationsByIds = gql`
	query ListInvestigationsByIds($tenantId: ID!, $filtersInput: FiltersInput) {
		listInvestigations(tenantId: $tenantId, filtersInput: $filtersInput) {
			edges {
				node {
					id
					name
					status
					alerts {
						id
					}
				}
			}
		}
	}
`;

export const ListInvestigationsForSelect = gql`
	query ListInvestigationsForSelect($tenantId: ID!, $filtersInput: FiltersInput) {
		listInvestigations(tenantId: $tenantId, filtersInput: $filtersInput) {
			edges {
				node {
					id
					name
					status
					alerts {
						id
					}
				}
			}
		}
	}
`;

export const InvestigationCreatedSubscription = gql`
	subscription InvestigationCreatedSubscription($tenantId: ID!) {
		investigationCreated(tenantId: $tenantId) {
			...CoreInvestigationFragment
		}
	}
	${CoreInvestigationFragment}
`;

export const InvestigationUpdatedSubscription = gql`
	subscription InvestigationUpdatedSubscription($tenantId: ID!) {
		investigationUpdated(tenantId: $tenantId) {
			...CoreInvestigationFragment
		}
	}
	${CoreInvestigationFragment}
`;

export const InvestigationWidgetCreatedSubscription = gql`
	subscription InvestigationWidgetCreatedSubscription($tenantId: ID!) {
		investigationCreated(tenantId: $tenantId) {
			...CoreInvestigationFragment
		}
	}
	${CoreInvestigationFragment}
`;

export const InvestigationWidgetUpdatedSubscription = gql`
	subscription InvestigationWidgetUpdatedSubscription($tenantId: ID!) {
		investigationUpdated(tenantId: $tenantId) {
			...CoreInvestigationFragment
		}
	}
	${CoreInvestigationFragment}
`;

export const InvestigationDetailsUpdatedSubscription = gql`
	subscription InvestigationDetailsUpdatedSubscription($tenantId: ID!) {
		investigationUpdated(tenantId: $tenantId) {
			...CoreInvestigationFragment
			...CoreGetInvestigationQueryResultsFragment
		}
	}
	${CoreInvestigationFragment}
	${CoreGetInvestigationQueryResultsFragment}
`;
