import { FC } from 'react';

import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RootPaths } from '@/app/_common/navigation/root-paths';

import { Namespaces } from '@/translations/namespaces';

import '@/assets/styles/index.scss';
import styles from './user-tile.module.scss';
import classNames from 'classnames';

export const UserSignOut: FC = () => {
	const { t } = useTranslation([Namespaces.NavbarMenu]);
	const text = t('signOut');

	return (
		<NavLink end to={RootPaths.SIGN_OUT} key={text} className={classNames(styles.link, styles.paddedItem)}>
			{text}
		</NavLink>
	);
};
