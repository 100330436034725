import { editor } from 'monaco-editor';

export const editorOptions: editor.IStandaloneEditorConstructionOptions = {
	value: '',
	language: 'kusto',
	automaticLayout: true,
	scrollBeyondLastLine: false,
	theme: 'defaultTheme',
	minimap: {
		enabled: false,
	},
	contextmenu: false,
	fixedOverflowWidgets: true,
};

export const editorTheme: editor.IStandaloneThemeData = {
	base: 'vs',
	inherit: true,
	rules: [{ token: '', background: '#EFF1F6' }],
	colors: {
		'editor.background': '#EFF1F6',
	},
};

export const editorDefaultSize: editor.IDimension = {
	width: 500,
	height: 360,
};
