import { InvestigationAlertSummary } from '@/generated/graphql';
import { AlertConfidence, DetectedByTypes } from '@/app/_common/constants';

export const getQualifiedAlertNodes = (alerts: InvestigationAlertSummary[]) => {
	return alerts.map((alert) => {
		if (alert.event_source === DetectedByTypes.RTCE_AI) {
			alert.event_source = DetectedByTypes.AIEngine;
		}

		if (!alert.event_source) {
			alert.event_source = DetectedByTypes.Unknown;
		}

		if (!alert.confidence || alert.confidence === '') {
			alert.confidence = AlertConfidence.Unknown;
		}

		return alert;
	});
};
