import { AlertDetailsKeyPrefix } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ThreatIntelligencePanelContentValueSectionProps {
	value: string;
	className?: string;
}

export const ThreatIntelligencePanelContentValueSection: FC<ThreatIntelligencePanelContentValueSectionProps> = ({ value, className = '' }) => {
	const { t } = useTranslation(Namespaces.DetailsView, { keyPrefix: AlertDetailsKeyPrefix.ThreatIntelligencePanel });

	return (
		<div className={className}>
			<h4>{t('value')}</h4>
			<p>{value}</p>
		</div>
	);
};
