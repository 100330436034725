import classNames from 'classnames';
import { cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import { Namespaces } from '@/translations/namespaces';
import { getAlertVendorKey, VENDOR_LINK_ICONS, DARK_THEME_POSTFIX } from '@/app/_common/_components/details-view/alerts-details-view-navigation';
import { ThemeStore } from '@/app/_common/stores';
import { SummarySectionItemTitle } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-section-item-title';

import styles from './vendor-link.module.scss';

interface VendorLinkProps {
	link: string;
	eventSource: string;
	className?: string;
}

export const VendorLink = observer(({ link, eventSource, className }: VendorLinkProps) => {
	const themeStore = useInstance(ThemeStore);
	const { t } = useTranslation(Namespaces.DetailsView);
	const vendorKey = getAlertVendorKey(eventSource);

	if (!(link && vendorKey)) {
		return null;
	}

	const logoProps = {
		className: classNames(styles.vendorLogo),
	};

	const themePostfix = themeStore.isDarkTheme ? DARK_THEME_POSTFIX : '';
	const iconName = `${vendorKey}${themePostfix}`;
	const Icon = VENDOR_LINK_ICONS[iconName] || null;
	const Logo = cloneElement(Icon, logoProps);

	return (
		<div className={classNames(styles.container, className)}>
			<SummarySectionItemTitle title={t('summary.openLink')} />
			<Link to={link} target="_blank" className={styles.linkContainer}>
				{Logo}
				<span>{t(`summary.openLinkVendor.${vendorKey}`)}</span>
				<OpenInNew htmlColor="var(--linkIconColor)" />
			</Link>
		</div>
	);
});
