import { isActiveAlertTheOnlyOne, isAlertAssignable, isAlertInSelected } from '@/app/dashboards/alerts-dashboard/_common/utils';
import { DetailsViewItemData } from '@/app/_common/_components/details-view/types';

export const areAlertsAssignable = (activeAlert?: DetailsViewItemData, alerts?: DetailsViewItemData[]) => {
	if (!alerts || alerts.length === 0 || !activeAlert) {
		return false;
	}

	if (isActiveAlertTheOnlyOne(activeAlert, alerts) || !isAlertInSelected(activeAlert, alerts)) {
		return false;
	}

	return alerts.some(isAlertAssignable);
};
