import { MATERIALIZE_REGEX } from '@/app/_common/constants';

export type RetrievedQuery = {
	query: string;
	materializeMatch: RegExpMatchArray | null;
};

export const retrieveQuery = (query: string): RetrievedQuery => {
	const trimmedQuery = query.trim() || '';

	// Management query
	if (trimmedQuery.startsWith('.')) {
		return {
			query: trimmedQuery,
			materializeMatch: null,
		};
	}

	// Materialize query
	const materializeMatch = trimmedQuery.match(MATERIALIZE_REGEX);

	if (materializeMatch?.groups) {
		return {
			query: materializeMatch?.groups.query,
			materializeMatch,
		};
	}

	return {
		query: trimmedQuery,
		materializeMatch: null,
	};
};
