import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { LOGGING_TAG } from '@/app/_common/constants';

const getCorrelationId = (response: AxiosResponse): string => {
	return response.headers?.correlationid;
};

export const LOG_FETCH_REQUESTS = (response: AxiosResponse, options?: AxiosRequestConfig) => {
	if (process.env.REACT_APP_LOG_GRAPHQL_OPERATIONS === 'true') {
		const displayOptions = options ? options.params : {};

		// eslint-disable-next-line
		console.debug(`${LOGGING_TAG}\n`, `File API (${getCorrelationId(response)})\n`, `Params: `, displayOptions);
	}
};
