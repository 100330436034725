import { Transforms, Element } from 'slate';

import { IsCurrentFormatActive, EditorType, CustomEditor } from '@/app/_common/_components/rich-text-editor';
import { FormattingType } from '@/app/_common/_components/rich-text-editor/_common/constant';

const LIST_TYPES = ['ordered-list', 'unordered-list'];

export const toggleBlockActionButton = (editor: CustomEditor, format: FormattingType) => {
	const isActive = IsCurrentFormatActive(editor, format);
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: (node) => Element.isElement(node) && LIST_TYPES.includes(node.type),
		split: true,
	});

	const notActiveNode = isList ? EditorType.LIST_ITEM : format;

	Transforms.setNodes(editor, {
		type: isActive ? EditorType.Paragraph : notActiveNode,
	});

	if (!isActive && isList) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};
