import { TimelineItemData } from '@/app/_common/_components/details-view/alerts-details-view-navigation';
import { calculateTimespan, countMiddleItemOffset, improveMiddleItemOffset } from './';
import { setShowDetails } from './set-show-timeline-item-details';

export const prepareTimelineDetails = ({
	items,
	itemWidth,
	hiddenSpace,
	imageWidth,
	drawableWidth,
	extraBeginningPadding,
}: {
	items: TimelineItemData[]; //< Items are required to be sorted by time. Oldest first.
	itemWidth: number; //< Space for each displayed item.
	hiddenSpace: number; // < If distance between current and previous item is larger than this, then show current item's text.
	imageWidth: number; //< Width of each icon.
	drawableWidth: number; //< Width of drawable timeline area. When zoomed, then it is larger than displayable area.
	extraBeginningPadding: number; //< Decoratory padding between side items and borders of area of timeline.
}): TimelineItemData[] => {
	// When item is the last, then it should be distanced from previous items.
	const itemsExceptLastWidth = drawableWidth - 2 * extraBeginningPadding - imageWidth - hiddenSpace;
	const lastIndex = items.length - 1;
	const { firstItemDate, timespan } = calculateTimespan(items);
	const calculationData = {
		timespan,
		firstItemDate,
		itemsExceptLastWidth,
		imageWidth,
		itemWidth,
		extraBeginningPadding,
	};

	const details = items.reduce((acc: TimelineItemData[], curr, index) => {
		// Set left offset
		if (index === 0) {
			curr.left = extraBeginningPadding;
		} else if (index === lastIndex) {
			curr.left = drawableWidth - extraBeginningPadding - itemWidth;
		} else {
			const uncorrectedOffset = countMiddleItemOffset({ ...calculationData, currentItem: curr });
			const prevValue = acc[index - 1];
			const offset = improveMiddleItemOffset({
				...calculationData,
				currentItemOffset: uncorrectedOffset,
				prevItemOffset: prevValue.left - extraBeginningPadding,
			});
			curr.left = extraBeginningPadding + offset;
		}

		acc.push(curr);
		return acc;
	}, []);

	// Correcting "show details" when offsets are known.
	setShowDetails({ items, additionalSpace: hiddenSpace, itemWidth });

	return details;
};
