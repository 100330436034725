import { Storage, Cloud, ListAlt } from '@mui/icons-material';

import { TelemetryIntegrationType } from '@/app/_features/integration/_common/types';

export const getTelemetryIntegrationTypeIcon = (telemetryIntegrationType?: TelemetryIntegrationType): typeof Storage | null => {
	switch (telemetryIntegrationType) {
		case TelemetryIntegrationType.Cloud:
			return Cloud;
		case TelemetryIntegrationType.SecureSyslog:
			return ListAlt;
		default:
			return null;
	}
};
