export const COLLECTORS_DEFAULT_PAGE_SIZE = 500;

export enum CloudCollectorIntegrationPropertiesPaths {
	Id = 'node.id',
	Status = 'node.status',
	StatusReason = 'node.statusReason',
	Vendor = 'node.vendor',
	ProductName = 'node.productName',
	CollectorId = 'node.collector.id',
	CollectorType = 'node.collector.type',
	Name = 'node.name',
	Description = 'node.description',
	LastEventTimestamp = 'node.lastEventTimestamp',
	Timestamp = 'node.timestamp',
	TelemetryIntegrationType = 'node.telemetryIntegrationType', // custom, computed from `type`, `sourceType` and `collectorType`
}

export const CloudCollectorIntegrationHeadersTranslationKeys = {
	[CloudCollectorIntegrationPropertiesPaths.Status]: 'grid.headers.status',
	[CloudCollectorIntegrationPropertiesPaths.Vendor]: 'grid.headers.vendor',
	[CloudCollectorIntegrationPropertiesPaths.ProductName]: 'grid.headers.productName',
	[CloudCollectorIntegrationPropertiesPaths.CollectorType]: 'grid.headers.type',
	[CloudCollectorIntegrationPropertiesPaths.Name]: 'grid.headers.name',
	[CloudCollectorIntegrationPropertiesPaths.Description]: 'grid.headers.description',
	[CloudCollectorIntegrationPropertiesPaths.LastEventTimestamp]: 'grid.headers.lastEventTimestamp',
	[CloudCollectorIntegrationPropertiesPaths.Timestamp]: 'grid.headers.timestamp',
	[CloudCollectorIntegrationPropertiesPaths.TelemetryIntegrationType]: 'grid.headers.telemetryIntegrationType',
};

export enum SyslogCollectorIntegrationPropertiesPaths {
	Id = 'node.id',
	Status = 'node.status',
	StatusReason = 'node.statusReason',
	Vendor = 'node.vendor',
	ProductName = 'node.productName',
	Hostname = 'node.hostname',
	LastEventTimestamp = 'node.lastEventTimestamp',
	Timestamp = 'node.timestamp',
	CollectorId = 'node.collector.id',
}

export const SyslogCollectorIntegrationHeadersTranslationKeys = {
	[SyslogCollectorIntegrationPropertiesPaths.Status]: 'grid.headers.status',
	[SyslogCollectorIntegrationPropertiesPaths.Vendor]: 'grid.headers.vendor',
	[SyslogCollectorIntegrationPropertiesPaths.ProductName]: 'grid.headers.productName',
	[SyslogCollectorIntegrationPropertiesPaths.Hostname]: 'grid.headers.hostname',
	[SyslogCollectorIntegrationPropertiesPaths.LastEventTimestamp]: 'grid.headers.lastEventTimestamp',
	[SyslogCollectorIntegrationPropertiesPaths.Timestamp]: 'grid.headers.timestamp',
};
