import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import type { editor } from 'monaco-editor';
import { Editor, Monaco } from '@monaco-editor/react';

import { ThemeStore } from '@/app/_common/stores';

import { editorOptions, editorDefaultSize, editorTheme } from './constants';
import { useMonaco } from '../../hooks';

export type CodeEditorAPI = editor.IStandaloneCodeEditor;

export interface CodeEditorProps {
	language?: string;
	readOnly?: boolean;
	defaultValue?: string;
	onChange?: (value: string) => void;
	onSubmit?: (value: string) => void;
	onMount?: (editor: CodeEditorAPI, monaco: Monaco) => void;
	height?: string;
	width?: string;
	customOptions?: Partial<Omit<editor.IStandaloneEditorConstructionOptions, 'langauge'>>;
}

export const CodeEditor = observer(
	({ language, readOnly, defaultValue, onChange, onSubmit, onMount, height = '100%', width, customOptions }: CodeEditorProps) => {
		const [editor, setEditor] = useState<CodeEditorAPI>();
		const themeStore = useInstance(ThemeStore);
		const monaco = useMonaco();

		useEffect(() => {
			if (monaco) {
				monaco.editor.defineTheme('defaultTheme', editorTheme);
			}
		}, [monaco]);

		const handleOnMount = useCallback(
			(editor: CodeEditorAPI, monaco: Monaco) => {
				setEditor(editor);
				onMount?.(editor, monaco);
			},
			[onMount],
		);

		useEffect(() => {
			const onResize = () => editor?.layout(editorDefaultSize);
			window.addEventListener('resize', onResize);
			return () => window.removeEventListener('resize', onResize);
		}, [editor]);

		useEffect(() => {
			if (!editor || !monaco || readOnly) return;
			const submitListener = editor.addAction({
				id: 'Submit',
				label: 'Submit',
				keybindings: [monaco.KeyMod.Shift | monaco.KeyCode.Enter],
				run: (editor) => onSubmit?.(editor.getValue()),
			});
			return () => submitListener.dispose();
		}, [monaco, editor, onSubmit, readOnly]);

		const options: editor.IStandaloneEditorConstructionOptions = useMemo(
			() => ({
				...editorOptions,
				...customOptions,
				readOnly,
			}),
			[readOnly, customOptions],
		);

		// Wait for monaco + kusto to load, earlier render of Editor will trigger default monaco loader
		if (!monaco) return <></>;

		return (
			<Editor
				language={language ?? options.language}
				onMount={handleOnMount}
				options={options}
				onChange={(value) => onChange?.(value ?? '')}
				theme={themeStore.isDarkTheme ? 'vs-dark' : 'defaultTheme'}
				defaultValue={defaultValue}
				height={height}
				width={width}
			/>
		);
	},
);
