import { ReactNode, FC } from 'react';

import classNames from 'classnames';

import styles from './plain-link.module.scss';

interface PlainLinkProps {
	href: string;
	text: string;
	icon?: ReactNode;
	className?: string;
	target: string;
}

export const PlainLink: FC<PlainLinkProps> = ({ href, text, icon, className, target }) => {
	return (
		<a className={classNames(styles.plainLink, className)} href={href} target={target} rel="noreferrer noopener">
			{icon}
			{text}
		</a>
	);
};
