import { ReactNode, useCallback, useId } from 'react';

import classNames from 'classnames';

import { Skeleton } from '@progress/kendo-react-indicators';

import { buttonize } from '@/app/_common/utils';
import { Tooltip } from '@/app/_common/_components';

import styles from './context-menu-item.module.scss';

interface ContextMenuItemProps {
	children?: ReactNode;
	disabled?: boolean;
	loading?: boolean;
	disabledTooltip?: string;
	className?: string;
	onClick?: () => void;
}

export const ContextMenuItem = ({ children, disabled, disabledTooltip, loading, className, onClick }: ContextMenuItemProps) => {
	const tooltipId = useId();
	const handleClick = useCallback(() => {
		if (!disabled && onClick) {
			onClick();
		}
	}, [disabled, onClick]);

	const itemClassName = classNames(styles.contextMenuItem, { [styles.disabled]: disabled, [styles.loading]: loading }, className);

	return (
		<>
			{disabled && <Tooltip tooltipId={tooltipId} tooltipContent={disabledTooltip} />}
			<li data-tip={true} data-for={tooltipId} className={itemClassName} {...buttonize<HTMLLIElement>(handleClick)}>
				{loading ? <Skeleton className={styles.loadingSkeleton} shape="text" /> : children}
			</li>
		</>
	);
};
