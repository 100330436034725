import { CoreCloudCollectorIntegrationFragment, CoreIntegrationFragment, CoreSyslogCollectorIntegrationFragment } from '@/app/_common/graphql';
import { gql } from '@apollo/client';

export const CoreCollectorFragment = gql`
	fragment CoreCollectorFragment on Collector {
		id
		name
		networkSettings {
			hostname
			portNumber
		}
		type
	}
`;

export const CoreCollectorDetailsFragment = gql`
	fragment CoreCollectorDetailsFragment on Collector {
		name
		id
		tenantId
		description
		hostname
		location
		proxyServerIp
		ntpServers
		networkSettings {
			ipAddressType
			ipAddress
			staticIpDetails {
				subnetMaskIpv4
				defaultGatewayIpv4
				primaryDnsServerIpv4
				alternateDnsServerIpv4
			}
		}
		state
		status
		statusReason
		timestamp
		last_updated
		completedTimestamp
		download {
			iso {
				key
				name
			}
		}
		lastEventTimestamp
	}
`;

export const ListCloudCollectorIntegrations = gql`
	query ListCloudCollectorIntegrations($tenantId: ID!, $filtersInput: FiltersInput, $input: Paging) {
		listIntegrations(tenantId: $tenantId, filtersInput: $filtersInput, input: $input) {
			edges {
				node {
					...CoreCloudCollectorIntegrationFragment
				}
				cursor
			}
			pageInfo {
				hasPreviousPage
				hasNextPage
				startCursor
				endCursor
			}
		}
	}
	${CoreCloudCollectorIntegrationFragment}
`;

export const CreateCloudCollectorIntegrationSubscription = gql`
	subscription CreateCloudCollectorIntegrationSubscription($tenantId: ID!) {
		integrationCreated(tenantId: $tenantId) {
			...CoreCloudCollectorIntegrationFragment
		}
	}
	${CoreCloudCollectorIntegrationFragment}
`;

export const UpdateCloudCollectorIntegrationSubscription = gql`
	subscription UpdateCloudCollectorIntegrationSubscription($tenantId: ID!) {
		integrationUpdated(tenantId: $tenantId) {
			...CoreCloudCollectorIntegrationFragment
		}
	}
	${CoreCloudCollectorIntegrationFragment}
`;

export const ListSyslogCollectorIntegrations = gql`
	query ListSyslogCollectorIntegrations($tenantId: ID!, $filtersInput: FiltersInput, $input: Paging) {
		listIntegrations(tenantId: $tenantId, filtersInput: $filtersInput, input: $input) {
			edges {
				node {
					...CoreSyslogCollectorIntegrationFragment
				}
				cursor
			}
			pageInfo {
				hasPreviousPage
				hasNextPage
				startCursor
				endCursor
			}
		}
	}
	${CoreSyslogCollectorIntegrationFragment}
`;

export const CreateSyslogCollectorIntegrationSubscription = gql`
	subscription CreateSyslogCollectorIntegrationSubscription($tenantId: ID!) {
		integrationCreated(tenantId: $tenantId) {
			...CoreSyslogCollectorIntegrationFragment
		}
	}
	${CoreSyslogCollectorIntegrationFragment}
`;

export const UpdateSyslogCollectorIntegrationSubscription = gql`
	subscription UpdateSyslogCollectorIntegrationSubscription($tenantId: ID!) {
		integrationUpdated(tenantId: $tenantId) {
			...CoreSyslogCollectorIntegrationFragment
		}
	}
	${CoreSyslogCollectorIntegrationFragment}
`;

export const GetCollectors = gql`
	query ListCollectors($tenantId: ID!, $input: Paging, $sortersInput: SortersInput, $filtersInput: FiltersInput) {
		listCollectors(tenantId: $tenantId, input: $input, sortersInput: $sortersInput, filtersInput: $filtersInput) {
			edges {
				node {
					...CoreCollectorFragment
				}
			}
		}
	}
	${CoreCollectorFragment}
`;

export const UpdateCollectorSubscription = gql`
	subscription UpdateCollectorSubscription($tenantId: ID!) {
		collectorUpdated(tenantId: $tenantId) {
			...CoreCollectorFragment
		}
	}
	${CoreCollectorFragment}
`;

export const UpdateCollectorDetailsSubscription = gql`
	subscription UpdateCollectorDetailsSubscription($tenantId: ID!) {
		collectorUpdated(tenantId: $tenantId) {
			...CoreCollectorDetailsFragment
		}
	}
	${CoreCollectorDetailsFragment}
`;

export const GetCollector = gql`
	query GetCollector($tenantId: ID!, $id: ID!) {
		getCollector(tenantId: $tenantId, id: $id) {
			... on Collector {
				...CoreCollectorDetailsFragment
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
	${CoreCollectorDetailsFragment}
`;

export const ListIntegrations = gql`
	query ListIntegrations($tenantId: ID!, $filtersInput: FiltersInput, $input: Paging) {
		listIntegrations(tenantId: $tenantId, filtersInput: $filtersInput, input: $input) {
			edges {
				node {
					...CoreIntegrationFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${CoreIntegrationFragment}
`;

export const IntegrationCreatedSubscription = gql`
	subscription IntegrationCreatedSubscription($tenantId: ID!) {
		integrationCreated(tenantId: $tenantId) {
			...CoreIntegrationFragment
		}
	}
	${CoreIntegrationFragment}
`;

export const IntegrationUpdatedSubscription = gql`
	subscription IntegrationUpdatedSubscription($tenantId: ID!) {
		integrationUpdated(tenantId: $tenantId) {
			...CoreIntegrationFragment
		}
	}
	${CoreIntegrationFragment}
`;
