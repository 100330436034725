import linkIcon from '@/assets/images/link.svg';
import { Popup } from '@progress/kendo-react-popup';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useOutsideClick } from '@/app/_common/hooks';
import styles from './help.module.scss';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '@/translations/namespaces';
import { MainDashboardKeyPrefix } from '@/app/_common/constants/main-dashboard.constants';
import { HelpRounded } from '@mui/icons-material';

export const Help = () => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	const { popupRef, anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		showDropdown,
		useCallback(() => setShowDropdown(false), []),
	);

	const handleCloseDropDown = () => {
		setShowDropdown(false);
	};

	const toggleDropdown = () => {
		setShowDropdown((showDropdown) => !showDropdown);
	};

	const { t } = useTranslation([Namespaces.MainDashboard], { keyPrefix: MainDashboardKeyPrefix.Help });

	const links = [{ text: t('supportAndDocumentation'), link: process.env.REACT_APP_FRESHDESK_SUPPORT_URL }];

	return (
		<>
			<div
				ref={anchorRef}
				className={classNames(styles.helpIconWrapper, { [styles.active]: showDropdown })}
				onClick={toggleDropdown}
				onKeyDown={toggleDropdown}
			>
				<HelpRounded className={classNames(styles.helpIcon)} />
			</div>

			<Popup anchor={anchorRef.current} show={showDropdown} popupClass={styles.helpPopup}>
				<div ref={popupRef}>
					{links.map((item) => (
						<a className={styles.link} href={item.link} key={item.text} rel="noreferrer" target="_blank" onClick={handleCloseDropDown}>
							<span>{item.text}</span>
							<img src={linkIcon} alt="Link Icon" className={styles.linkIcon} />
						</a>
					))}
				</div>
			</Popup>
		</>
	);
};
