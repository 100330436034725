import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import DnsIcon from '@mui/icons-material/Dns';

import { Namespaces } from '@/translations/namespaces';
import { BoxContainer } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alert-host-user-box/_components/box-container';
import { BlockItem } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alert-host-user-box/_components/block-item';

import styles from './alert-host-box.module.scss';

interface AlertHostBoxProps {
	hostName: string;
	hostType: string;
	operatingSystem: string;
}

export const AlertHostBox = ({ hostName, hostType, operatingSystem }: AlertHostBoxProps) => {
	const { t } = useTranslation(Namespaces.DetailsView);
	const hasHostName = Boolean(hostName);
	const hasHostType = Boolean(hostType);
	const hasOperatingSystem = Boolean(operatingSystem);
	const gridCellsNumber: number = Number(hasHostName) + Number(hasHostType) + Number(hasOperatingSystem);
	const hasNoData = !gridCellsNumber;

	if (hasNoData) {
		return null;
	}

	return (
		<BoxContainer
			containerClassName={styles.container}
			contentClassName={classNames(styles.content, { [styles.oneColumnLayout]: gridCellsNumber <= 2 })}
			icon={<DnsIcon />}
		>
			{hasHostName && <BlockItem title={t('host.hostName')} text={hostName} />}
			{hasOperatingSystem && <BlockItem title={t('host.operatingSystem')} text={operatingSystem} className={styles.operatingSystem} />}
			{hasHostType && <BlockItem title={t('host.hostType')} text={hostType} />}
		</BoxContainer>
	);
};
