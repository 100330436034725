import _orderBy from 'lodash/orderBy';

import { Tenant } from '@/app/_common/interfaces/auth-details';

import { getCountBucket, CountBucket, createWrappedString, createBucket } from '@/app/_common/utils';

export const getUniqueTenantsNames = (tenants: Tenant[]): Tenant[] => {
	const sortedTenants = _orderBy([...tenants], ['name', 'id'], ['asc', 'asc']);

	const tenantOccurrences: CountBucket = getCountBucket<Tenant>(sortedTenants, (tenant) => tenant.name);
	const currentTenantsCount: CountBucket = createBucket(Object.keys(tenantOccurrences));

	const uniqueTenantsNames = sortedTenants.map((tenant): Tenant => {
		const { name } = tenant;
		const currentCount = currentTenantsCount[name];

		currentTenantsCount[name] += 1;

		return {
			...tenant,
			name: `${name} ${createWrappedString(currentCount.toString(), '(', ')', tenantOccurrences[name] > 1)}`.trim(),
		};
	});
	return uniqueTenantsNames;
};
