import { makeAutoObservable } from 'mobx';
import _uniqueId from 'lodash/uniqueId';

import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { getGraphQLError } from '@/app/_common/graphql/graphql-error-handler';
import { Investigation, MutationCreateInvestigationArgs, InvestigationStatus, AlertState, Error } from '@/generated/graphql';
import {
	AlertsDataGridViewStore,
	AssignAlertsDataStore,
	CreateInvestigationAndAssignAlertsDataStore,
	DismissAlertsDataStore,
	UnassignAlertsDataStore,
	UndismissAlertsDataStore,
} from '@/app/_features/alerts-actions/_common';
import { AlertActions, MutationsConfig } from '@/app/_features/alerts-actions/_common/types';
import { Notification, NotificationLook, AlertWithInvestigationSummaryExtended, NotificationMessage } from '@/app/_common/types';
import { CLOSE_NOTIFICATION_TIMEOUT, NotificationsStore } from '@/app/_common/stores';
import { AlertTypename, ErrorTypename, InvestigationTypename } from '@/app/_common/constants';

interface AlertsActionContext {
	investigations: Investigation[];
}

interface State {
	action: AlertActions | null;
	errors: Notification[];
	context: AlertsActionContext;
}

const INITIAL_CONTEXT: AlertsActionContext = {
	investigations: [],
};

const INITIAL_STATE: State = {
	action: null,
	errors: [],
	context: INITIAL_CONTEXT,
};

export class AlertsActionsViewStore {
	notificationsStore = injectInterface(this, NotificationsStore);
	alertsDataGridStore = injectInterface(this, AlertsDataGridViewStore);
	assignAlertsDataStore = injectInterface(this, AssignAlertsDataStore);
	dismissAlertsDataStore = injectInterface(this, DismissAlertsDataStore);
	unassignAlertsDataStore = injectInterface(this, UnassignAlertsDataStore);
	undismissAlertsDataStore = injectInterface(this, UndismissAlertsDataStore);
	createInvestigationAndAssignAlertsDataStore = injectInterface(this, CreateInvestigationAndAssignAlertsDataStore);

	private state = INITIAL_STATE;

	/**
	 * @param  {Object}  mutationsConfig              - Configuration related to cache update after mutation
	 * @param  {String}  mutationsConfig.fragment     - IMPORTANT: fragment name should be one of the following AssignAlertsFragment, CreateAndAssignAlertsFragment, UnassignAlertsFragment, DismissAlertsFragment or UndismissAlertsFragment.
	 * @param  {Array}   mutationsConfig.updateCache  - A callback function that updates cache after successful mutation.
	 */
	constructor(private mutationsConfig: MutationsConfig) {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get action() {
		return this.state.action;
	}

	get loading() {
		switch (this.action) {
			case AlertActions.Assign:
				return this.assignAlertsDataStore.loading || this.createInvestigationAndAssignAlertsDataStore.loading;
			case AlertActions.Unassign:
				return this.unassignAlertsDataStore.loading;
			case AlertActions.Dismiss:
				return this.dismissAlertsDataStore.loading;
			case AlertActions.Undismiss:
				return this.undismissAlertsDataStore.loading;
			default:
				return false;
		}
	}

	get errors(): Notification[] {
		return this.state.errors;
	}

	get context() {
		return this.state.context;
	}

	get investigations() {
		return this.context.investigations ?? [];
	}

	handleError = (translationKey: string, responseError: Error) => {
		const graphQLError = getGraphQLError(i18n.t(translationKey, { ns: Namespaces.Notifications }), responseError);
		const error: Notification = {
			...graphQLError,
			look: NotificationLook.Outlined,
			id: _uniqueId(),
			type: { style: 'error', icon: true },
			closable: true,
		};

		this.state.errors = [...this.state.errors, { onClose: () => this.clearError(error.id), ...error }];

		setTimeout(() => {
			this.clearError(error.id);
		}, CLOSE_NOTIFICATION_TIMEOUT);
	};

	handleSuccess = ({
		url,
		titleTranslationPath,
		titleTranslationOptions = {},
		linkTranslationPath,
		linkTranslationOptions = {},
	}: {
		url?: string;
		titleTranslationPath: string;
		titleTranslationOptions?: Record<string, string>;
		linkTranslationPath?: string;
		linkTranslationOptions?: Record<string, string>;
	}) => {
		const notificationMessage: NotificationMessage = {
			title: i18n.t(titleTranslationPath, { ns: Namespaces.Notifications, ...titleTranslationOptions }),
		};

		if (linkTranslationPath && url) {
			notificationMessage.link = {
				text: i18n.t(linkTranslationPath, { ns: Namespaces.Notifications, ...linkTranslationOptions }),
				url,
			};
		}

		this.notificationsStore.openSuccess(notificationMessage);

		if (this.action) {
			this.closeDialog();
		}
	};

	clearError = (id: string) => {
		this.state.errors = this.state.errors.filter((error) => error.id !== id);
	};

	clearErrors = () => {
		this.state.errors = [];
	};

	clearContext = () => {
		this.state.context = INITIAL_CONTEXT;
	};

	setInvestigations = (investigations: Investigation[]) => {
		this.state.context.investigations = investigations;
	};

	assignAlerts = (alerts: AlertWithInvestigationSummaryExtended[]) => {
		this.initializeAction(AlertActions.Assign, alerts);
	};

	unassignAlerts = (alerts: AlertWithInvestigationSummaryExtended[], investigations: Investigation[]) => {
		this.initializeAction(AlertActions.Unassign, alerts, investigations);
	};

	dismissAlerts = (alerts: AlertWithInvestigationSummaryExtended[]) => {
		if (alerts.some((alert) => alert.investigationSummary)) {
			this.initializeAction(AlertActions.Dismiss, alerts);
		} else {
			this.executeDismissAlerts(alerts);
		}
	};

	undismissAlerts = (alerts: AlertWithInvestigationSummaryExtended[]) => {
		this.executeUndismissAlerts(alerts);
	};

	executeCreateInvestigationAndAssignAlertsAlerts = async (investigation: Omit<MutationCreateInvestigationArgs, 'alertIds' | 'tenantId'>) => {
		const alerts = this.alertsDataGridStore.data;
		const alertIds = alerts.filter(this.isAlertAssignable).map(({ id }) => id);
		const { fragment, updateCache } = this.mutationsConfig.createAndAssignAlerts;
		const response = await this.createInvestigationAndAssignAlertsDataStore.createAndAssign({ ...investigation, alertIds }, fragment, updateCache);
		const responseData = response?.data?.createInvestigation;

		if (responseData?.__typename === InvestigationTypename.Investigation) {
			this.handleSuccess({
				titleTranslationPath: 'createInvestigation.success.title',
				titleTranslationOptions: { name: responseData?.name },
				linkTranslationPath: 'createInvestigation.success.content',
				linkTranslationOptions: { id: responseData.id },
				url: `/investigations/${responseData.id}`,
			});
		} else if (responseData?.__typename === ErrorTypename) {
			this.handleError('createInvestigation.error.assignError.title', responseData);
		}
	};

	executeAssignAlerts = async (
		investigation: Pick<Investigation, 'id'> & {
			alertIds: string[];
		},
	) => {
		const alerts = this.alertsDataGridStore.data;
		const alertIds = alerts.filter((alert) => this.isAlertAssignable(alert) && this.alertsDataGridStore.isRowSelected(alert.id)).map(({ id }) => id);
		const { fragment, updateCache } = this.mutationsConfig.assignAlerts;
		const response = await this.assignAlertsDataStore.assign(investigation, alertIds, fragment, updateCache);
		const responseData = response?.data?.setAlertsOnInvestigation;

		if (responseData?.__typename === InvestigationTypename.Investigation) {
			this.handleSuccess({
				titleTranslationPath: 'setAlertsOnInvestigation.success.title',
				titleTranslationOptions: { name: responseData?.name },
				linkTranslationPath: 'setAlertsOnInvestigation.success.content',
				linkTranslationOptions: { id: responseData.id },
				url: `/investigations/${responseData.id}`,
			});
		} else if (responseData?.__typename === ErrorTypename) {
			this.handleError('setAlertsOnInvestigation.error.title', responseData);
		}
	};

	executeUnassignAlerts = async (alerts: AlertWithInvestigationSummaryExtended[]) => {
		const alertIds = alerts.filter(this.isAlertUnassignable).map(({ id }) => id);
		const { fragment, updateCache } = this.mutationsConfig.unassignAlerts;
		const response = await this.unassignAlertsDataStore.unassign(alertIds, this.investigations, fragment, updateCache);
		const responseData = Object.values(response?.data?.setAlertsOnInvestigation ?? []);

		if (responseData.every((data) => data?.__typename === InvestigationTypename.Investigation)) {
			this.handleSuccess({
				titleTranslationPath: 'alertsStateChange.unassign.success.title',
			});
		} else if (responseData.some((data) => data?.__typename === ErrorTypename)) {
			this.handleError(
				'alertsStateChange.unassign.error.title',
				responseData.find((data) => data?.__typename === ErrorTypename),
			);
		}
	};

	executeDismissAlerts = async (alerts: AlertWithInvestigationSummaryExtended[]) => {
		const alertIds = alerts.filter(this.isAlertDismissable).map(({ id }) => id);
		const { fragment, updateCache } = this.mutationsConfig.dismissAlerts;
		const response = await this.dismissAlertsDataStore.dismiss(alertIds, fragment, updateCache);
		const responseData = response?.data?.setAlertsState;

		if (responseData?.__typename === AlertTypename.Alerts) {
			this.handleSuccess({
				titleTranslationPath: 'alertsStateChange.dismiss.success.title',
			});
		} else if (responseData?.__typename === ErrorTypename) {
			this.handleError('alertsStateChange.dismiss.error.title', responseData);
		}
	};

	executeUndismissAlerts = async (alerts: AlertWithInvestigationSummaryExtended[]) => {
		const alertIds = alerts.filter(this.isAlertUndismissable).map(({ id }) => id);
		const { fragment, updateCache } = this.mutationsConfig.undismissAlerts;
		const response = await this.undismissAlertsDataStore.undismiss(alertIds, fragment, updateCache);
		const responseData = response?.data?.setAlertsState;

		if (responseData?.__typename === AlertTypename.Alerts) {
			this.handleSuccess({
				titleTranslationPath: 'alertsStateChange.undismiss.success.title',
			});
		} else if (responseData?.__typename === ErrorTypename) {
			this.handleError('alertsStateChange.undismiss.error.title', responseData);
		}
	};

	closeDialog = () => {
		this.setAction(null);
		this.alertsDataGridStore.clearSourceData();
		this.alertsDataGridStore.resetGridState();
		this.clearResult();
		this.clearContext();
	};

	private clearResult = () => {
		this.assignAlertsDataStore.clearResult();
		this.createInvestigationAndAssignAlertsDataStore.clearResult();
		this.unassignAlertsDataStore.clearResult();
		this.dismissAlertsDataStore.clearResult();
		this.unassignAlertsDataStore.clearResult();
	};

	private setAction = (action: AlertActions | null) => {
		this.state.action = action;
	};

	private initializeAction = (action: AlertActions, alerts: AlertWithInvestigationSummaryExtended[], investigations: Investigation[] = []) => {
		this.alertsDataGridStore.setSourceData(alerts);
		this.setAction(action);

		const selectedIds: Record<string, boolean> = {};
		const disabledIds: Record<string, boolean> = {};

		alerts.forEach((alert) => {
			const isDisabled = this.isAlertDisabled(alert);
			selectedIds[alert.id] = !isDisabled;
			disabledIds[alert.id] = isDisabled;
		});

		this.alertsDataGridStore.selectRows(selectedIds);
		this.alertsDataGridStore.disableRows(disabledIds);

		this.setInvestigations(investigations);
	};

	private isAlertAssignable = (alert: AlertWithInvestigationSummaryExtended) => {
		return alert.state?.alertState !== AlertState.Assigned;
	};

	private isAlertUnassignable = (alert: AlertWithInvestigationSummaryExtended) => {
		return alert.state?.alertState === AlertState.Assigned && alert.investigationSummary?.status !== InvestigationStatus.Closed;
	};

	private isAlertDismissable = (alert: AlertWithInvestigationSummaryExtended) => {
		return alert.state?.alertState !== AlertState.Dismissed && alert.investigationSummary?.status !== InvestigationStatus.Closed;
	};

	private isAlertUndismissable = (alert: AlertWithInvestigationSummaryExtended) => {
		return alert.state?.alertState === AlertState.Dismissed;
	};

	private isAlertDisabled = (alert: AlertWithInvestigationSummaryExtended) => {
		switch (this.action) {
			case AlertActions.Assign:
				return !this.isAlertAssignable(alert);
			case AlertActions.Unassign:
				return !this.isAlertUnassignable(alert);
			case AlertActions.Dismiss:
				return !this.isAlertDismissable(alert);
			case AlertActions.Undismiss:
				return !this.isAlertUndismissable(alert);
			default:
				return false;
		}
	};
}
