import { makeAutoObservable } from 'mobx';

import moment from 'moment/moment';

import { injectInterface } from '@/app/_common/ioc/inject-interface';

import { Endpoint, EndpointIpAddress } from '@/generated/graphql';
import { DATE_TIME_FORMAT } from '@/app/_common/constants';

import { EndpointRowDetailsDataStore } from './endpoint-row-details.data-store';
import { EndpointsListViewStore } from '@/app/response/endpoints/_common/stores/endpoints-list.view-store';

export class EndpointRowDetailsViewStore {
	private dataStore = injectInterface(this, EndpointRowDetailsDataStore);
	private endpointListStore = injectInterface(this, EndpointsListViewStore);

	constructor() {
		makeAutoObservable(this);

		this.read(this.endpointListStore.expandedId);
	}

	get loading(): boolean {
		return this.dataStore.loading;
	}

	get error() {
		return this.dataStore.error;
	}

	get data() {
		return this.dataStore.data;
	}

	get endpoint(): Endpoint | undefined {
		if (this.dataStore.data?.getEndpoint.__typename === 'Endpoint') {
			return this.dataStore.data?.getEndpoint;
		}

		return undefined;
	}

	get endpointId(): string {
		return this.endpoint?.id || '';
	}

	get externalReferenceID(): string {
		return this.endpoint?.externalReference || '';
	}

	get ipAddresses(): EndpointIpAddress[] {
		return this.endpoint?.networkInformation?.ipAddresses || [];
	}

	get ipAddressesList(): string[] {
		const ipAddresses = this.ipAddresses;

		return (
			ipAddresses?.reduce<string[]>((acc, ipAddress) => {
				if (!ipAddress || !ipAddress.ipAddress) {
					return acc;
				}

				acc.push(ipAddress.ipAddress);

				return acc;
			}, []) || []
		);
	}

	get os(): string {
		return this.endpoint?.operatingSystem || '';
	}

	get vendor(): string {
		return this.endpoint?.responseIntegration.vendor || '';
	}

	get product(): string {
		return this.endpoint?.responseIntegration.productName || '';
	}

	get edr(): string {
		return `${this.vendor} ${this.product}` || '';
	}

	get networkInformation(): string[] {
		const ipAddresses = this.ipAddresses;

		return (
			ipAddresses?.reduce<string[]>((acc, ipAddress) => {
				if (!ipAddress) {
					return acc;
				}

				acc.push([ipAddress.ipAddress, ipAddress.macAddress, ipAddress.type, ipAddress.operationalStatus].join(', '));

				return acc;
			}, []) || []
		);
	}

	get statistics() {
		return this.endpoint?.statistics;
	}

	get firstSeen(): string {
		if (this.statistics?.firstSeenTimestamp === undefined) {
			return '';
		}

		return moment(this.statistics?.firstSeenTimestamp).parseZone().format(DATE_TIME_FORMAT) || '';
	}

	get lastSeen(): string {
		if (this.statistics?.lastSeenTimestamp === undefined) {
			return '';
		}

		return moment(this.statistics?.lastSeenTimestamp).parseZone().format(DATE_TIME_FORMAT) || '';
	}

	get lastUpdated(): string {
		if (this.endpoint?.last_updated === undefined) {
			return '';
		}

		return moment(this.endpoint?.last_updated).parseZone().format(DATE_TIME_FORMAT) || '';
	}

	read(endpointId: string) {
		if (!endpointId) {
			return;
		}

		this.dataStore.read(endpointId);
	}
}
