import { ReactNode, memo, useCallback } from 'react';

import { AssignToInvestigationTabs } from '@/app/_common/constants';
import { buttonize } from '@/app/_common/utils';

interface AssignToInvestigationFormTabTitleProps {
	id: AssignToInvestigationTabs;
	children: ReactNode;
	className: string;
	onClick: (id: AssignToInvestigationTabs) => void;
}

export const AssignToInvestigationFormTabTitle = memo(({ id, children, className, onClick }: AssignToInvestigationFormTabTitleProps) => {
	const handleClick = useCallback(() => {
		onClick(id);
	}, [id, onClick]);

	return (
		<div {...buttonize(handleClick)} className={className}>
			{children}
		</div>
	);
});

AssignToInvestigationFormTabTitle.displayName = 'AssignToInvestigationFormTabTitle';
