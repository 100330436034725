import axios from 'axios';

import { AuthDetailsTokens, defaultAuthDetailsTokens } from '@/app/_common/interfaces/auth-details';
import { getTokens } from '@/app/_common/utils/auth';
import { appendSearchParam } from '@/app/_common/utils';

export const verifyCode = async (code: string, flow: string): Promise<AuthDetailsTokens> => {
	const requestPayload = {};

	const codeSearchParam = code
		? new URLSearchParams({
				code,
		  }).toString()
		: '';

	const basicUrl = (process.env.REACT_APP_LOGIN_REQUEST as string) + flow + (process.env.REACT_APP_LOGIN_REFRESH_TOKEN_REQUEST as string);
	const requestUrl = appendSearchParam(basicUrl, codeSearchParam);

	try {
		const response = await axios.post(requestUrl, requestPayload);

		return getTokens(response);
	} catch (error) {
		return defaultAuthDetailsTokens;
	}
};
