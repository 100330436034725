import { FC, useMemo, useState } from 'react';
import { ThreatIntelligenceDescription, ThreatIntelligenceHit } from '@/app/_common/types';
import {
	ThreatIntelligencePanelContentCommentSection,
	ThreatIntelligencePanelContentDescriptionsSection,
	ThreatIntelligencePanelContentMultipleMatches,
	ThreatIntelligencePanelContentReferencesSection,
	ThreatIntelligencePanelContentTagsSection,
	ThreatIntelligencePanelContentValueSection,
} from './_components';

import styles from './threat-intelligence-panel-content.module.scss';
import {
	getHitsComments,
	getHitsDescriptions,
	getHitsReferences,
	getHitsTags,
} from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/threat-intelligence/_utils';

interface ThreatIntelligencePanelContentProps {
	hits: ThreatIntelligenceHit[];
	availableDescriptions: ThreatIntelligenceDescription[];
}

export const ThreatIntelligencePanelContent: FC<ThreatIntelligencePanelContentProps> = ({ hits, availableDescriptions }) => {
	const [activeHit, setActiveHit] = useState<number>(-1);
	const value = hits[0]?.identifier || '';
	const hasMultipleHits = hits.length > 1;
	const isRenderingSummary = activeHit === -1;

	const handleSelectHit = (hitIndex: number) => {
		setActiveHit(hitIndex);
	};

	const summaryTags = getHitsTags(hits);
	const summaryComments = getHitsComments(hits);
	const summaryReferences = getHitsReferences(hits);
	const summaryDescriptions = getHitsDescriptions(summaryTags, availableDescriptions);

	const tags = useMemo(() => (isRenderingSummary ? summaryTags : getHitsTags([hits[activeHit]])), [activeHit, hits, isRenderingSummary, summaryTags]);
	const comments = useMemo(
		() => (isRenderingSummary ? summaryComments : getHitsComments([hits[activeHit]])),
		[activeHit, hits, isRenderingSummary, summaryComments],
	);
	const references = useMemo(
		() => (isRenderingSummary ? summaryReferences : getHitsReferences([hits[activeHit]])),
		[activeHit, hits, isRenderingSummary, summaryReferences],
	);

	const descriptions = useMemo(
		() => (isRenderingSummary ? summaryDescriptions : getHitsDescriptions(tags, availableDescriptions)),
		[availableDescriptions, isRenderingSummary, summaryDescriptions, tags],
	);

	return (
		<div className={styles.content}>
			<ThreatIntelligencePanelContentValueSection value={value} className={styles.section} />
			{hasMultipleHits && (
				<ThreatIntelligencePanelContentMultipleMatches
					className={styles.section}
					multipleHits={hits}
					selectedHit={activeHit}
					handleSelectHit={handleSelectHit}
				/>
			)}
			<ThreatIntelligencePanelContentTagsSection tags={tags} className={styles.section} />
			<ThreatIntelligencePanelContentCommentSection comments={comments} className={styles.section} />
			<ThreatIntelligencePanelContentReferencesSection references={references} className={styles.section} />
			<ThreatIntelligencePanelContentDescriptionsSection descriptions={descriptions} className={styles.section} />
		</div>
	);
};
