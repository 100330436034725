import _isNil from 'lodash/isNil';
import _pickBy from 'lodash/pickBy';

export const removeIrrelevantObjectProperties = (obj: Record<string, unknown>) => {
	return _pickBy(obj, (value) => {
		if (_isNil(value) || value === '') {
			return false;
		}
		return true;
	});
};
