/* eslint-disable jsx-a11y/click-events-have-key-events */

import { FC, KeyboardEvent, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useInstance } from 'react-ioc';

import classNames from 'classnames';
import _intersection from 'lodash/intersection';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';

import { buttonize } from '@/app/_common/utils';
import { FilterDescriptorWithId, Filters, FilterValue } from '@/app/_common/types';
import { ThemeStore } from '@/app/_common/stores';
import { FieldValue } from '@/generated/graphql';

import { ItemExpanded } from '@/app/_common/_components/data-grid/_components/data-header-cell/item-expanded';
import { OptionGroupItemIcon } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/option-group-item-icon';
import { OptionGroupItemLabel } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/option-group-item-label';
import { OptionGroupItemCounter } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/option-group-item-counter';

import styles from './data-header-subgroup.module.scss';

export interface DataHeaderSubgroupFilter {
	name: string;
	count: number;
	options: SubgroupOption[];
}

interface SubgroupOption {
	value: string;
	label: string;
	counter: number;
}

interface HeaderCellSubgroupProps {
	name: string;
	options: SubgroupOption[];
	handleFilterChange: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, value: FilterValue[]) => void;
	count: number;
	filter: Filters | FilterDescriptorWithId[];
	icon?: JSX.Element;
}

export const HeaderCellSubgroup: FC<HeaderCellSubgroupProps> = ({ name, count, options, handleFilterChange, filter, icon }) => {
	const [expanded, setExpanded] = useState(true);

	const { theme } = useInstance(ThemeStore);

	const handleOnElementClick = useCallback(
		(event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, clickedSubgroups: SubgroupOption[]) => {
			let clickedOptionsValues = clickedSubgroups.map((option) => option.value);

			//@ts-expect-error
			const duplicatedFilterOptions = _intersection(filter, clickedOptionsValues);

			if (duplicatedFilterOptions.length) {
				clickedOptionsValues.sort((a, b) => a.localeCompare(b));
				if (!_isEqual(_sortBy(duplicatedFilterOptions), clickedOptionsValues)) {
					//@ts-ignore
					clickedOptionsValues = clickedOptionsValues.filter((option) => !duplicatedFilterOptions.includes(option));
				}
			}

			handleFilterChange(event, clickedOptionsValues);
		},
		[handleFilterChange, filter],
	);

	const selectAllSuboptions = (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
		handleOnElementClick(event, options);
	};

	const toggleExpanded = () => {
		setExpanded(!expanded);
	};

	const areAllOptionsSelected = useMemo(() => {
		const optionValues = options.map((option) => option.value as FieldValue & FilterValue);

		return optionValues.every((optionValue) => filter.includes(optionValue));
	}, [options, filter]);

	if (!options.length) {
		return null;
	}

	return (
		<>
			<div className={classNames(styles.container, theme, { [styles.allOptionsSelected]: areAllOptionsSelected })}>
				<div className={classNames(styles.nameContainer, theme)}>
					<div className={styles.nameContent} {...buttonize<HTMLElement>(selectAllSuboptions)}>
						<OptionGroupItemIcon icon={icon} />
						<OptionGroupItemLabel value={name} />
						<OptionGroupItemCounter value={count} />
					</div>
					<ItemExpanded expanded={expanded} onClick={toggleExpanded} />
				</div>
				{expanded && (
					<ul className={styles.listContainer}>
						{options?.map((option) => {
							const isSelected = filter?.includes(option.value as FieldValue & FilterValue);

							return (
								<li
									className={classNames(styles.listItem, theme, { [styles.listItemSelected]: isSelected || areAllOptionsSelected })}
									key={option.value}
									{...buttonize<HTMLElement>((event) => handleOnElementClick(event, [option]))}
								>
									<p>
										{option.label} {option.counter && `(${option.counter})`}
									</p>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</>
	);
};
