import { routeIDRegex } from '@/app/_common/constants';

// checks if paths are similar in form: /xyz/123 is equal to /xyz/:id
// similar path is also a shorter path to get longer paths
export const isSimilarPath = (pathname: string, path: string) => {
	if (pathname === path) {
		return true;
	}

	const splitPathname = pathname.split('/');
	const splitPath = path.split('/');

	if (
		splitPathname.every((pathPart, index) => {
			const correspondingPathPart = splitPath[index];

			if (pathPart === correspondingPathPart) {
				return true;
			}

			if (correspondingPathPart === undefined || correspondingPathPart.match(routeIDRegex)) {
				return true;
			}

			return false;
		})
	) {
		return true;
	}

	return false;
};
