import { Tenant as GraphqlTenant } from '@/generated/graphql';

export interface AuthDetails {
	token?: string | null;
	user?: User;
}

export interface AuthDetailsTokens {
	id_token: string;
	id_token_expires_in: number;
	access_token: string;
	refresh_token: string;
	expires_on?: string | number;
	refresh_token_expires_in?: string | number;
}

export interface User {
	id: string;
	upn: string;
	tenants?: Tenant[];
	displayName: string;
	firstName: string;
	lastName: string;
	isTenantUser: boolean;
	franchise: Franchise;
}

// Retrieved from token
export interface UserTenantInfo {
	name: string;
	franchiseId: string;
	id: string;
	isBillingUser: boolean;
	role: string;
	invitedTimestamp: string | null;
	invitedBy: string | null;
	hasFreshdeskCompanyAssigned: boolean;
	registrationTimestamp?: string;
}

// Retrieved from graphql-server
export type FranchiseTenantInfo = GraphqlTenant;

export type FranchiseTenantBasicInfo = Pick<GraphqlTenant, 'name' | 'id' | 'hasCompletedRegistration'>;

export type Tenant = UserTenantInfo | FranchiseTenantBasicInfo;

export interface Franchise {
	id: string;
	name: string;
	isOwner: boolean;
	darkModeLogoFileName?: string;
	lightModeLogoFileName?: string;
}

export const defaultAuthDetails: AuthDetails = {};

export const defaultAuthDetailsTokens: AuthDetailsTokens = {
	id_token: '',
	id_token_expires_in: 0,
	access_token: '',
	refresh_token: '',
	expires_on: 0,
	refresh_token_expires_in: 0,
};
