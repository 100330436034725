import i18n from '@/translations/i18n';

import { Namespaces } from '@/translations/namespaces';
import { ActionHistoryActions } from '@/app/_features/action-history/_common/types';

export const getActionHistoryActionLabel = (action: ActionHistoryActions) => {
	const actionHistoryActionTranslationKey = new Map<ActionHistoryActions, string>([
		[ActionHistoryActions.CREATE, 'grid.actionLabels.create'],
		[ActionHistoryActions.READ, 'grid.actionLabels.read'],
		[ActionHistoryActions.UPDATE, 'grid.actionLabels.update'],
		[ActionHistoryActions.DELETE, 'grid.actionLabels.delete'],
		[ActionHistoryActions.ISOLATE, 'grid.actionLabels.isolate'],
		[ActionHistoryActions.DEISOLATE, 'grid.actionLabels.deisolate'],
	]);

	return i18n.t(actionHistoryActionTranslationKey.get(action) ?? '', { ns: Namespaces.ActionHistory });
};
