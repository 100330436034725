import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { computedFn } from 'mobx-utils';
import _isNil from 'lodash/isNil';

import { Filters, FilterValue } from '@/app/_common/types';

import {
	AlertPropertiesPaths,
	AssignedAlertPropertiesPaths,
	FilterOperators,
	FilterGroupId,
	FilterLogic,
	SortDirection,
} from '@/app/_common/constants';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AlertState, InvestigationAlertSummary, State } from '@/generated/graphql';
import _get from 'lodash/get';
import { sortAssignedAlerts } from '@/app/investigation-details/_common/utils';
import { AssignedAlertsListViewStore } from '@/app/investigation-details/_common/stores';
import { AlertsUtilsViewStore } from '@/app/_common/stores';
import { mapFilterOption, getConfidenceOption, getSeverityOption } from '@/app/_common/_components/data-grid/utils';

const INITIAL_COLUMNS = {
	[AlertPropertiesPaths.Selected]: true,
	[AlertPropertiesPaths.Timestamp]: true,
	[AlertPropertiesPaths.InvestigationSummaryId]: true,
	[AlertPropertiesPaths.Severity]: true,
	[AlertPropertiesPaths.Confidence]: true,
	[AlertPropertiesPaths.Title]: true,
	[AlertPropertiesPaths.Source]: true,
	[AlertPropertiesPaths.Destination]: true,
	[AlertPropertiesPaths.Mitre]: true,
	[AlertPropertiesPaths.DetectedBy]: true,
};

const INITIAL_SORT = [{ field: AlertPropertiesPaths.Severity, dir: SortDirection.Desc }];

export class InvestigationAlertsDismissListViewStore extends AlertsUtilsViewStore<InvestigationAlertSummary> {
	private initialIds: string[] = [];
	private viewStore = injectInterface(this, AssignedAlertsListViewStore);

	constructor() {
		super(AssignedAlertPropertiesPaths.Id, INITIAL_COLUMNS, INITIAL_SORT, sortAssignedAlerts);
		makeObservable(this, {
			// @ts-ignore - for protected/private fields
			initialIds: observable,
			alertIds: computed,
			setInitialAlertIds: action,
			setSourceData: action,
		});
	}

	getFilterOptions = computedFn((field: string) => {
		const counters = this.getCountedValues(field);
		return this.getUniqValues(field).map((value) => {
			return mapFilterOption(
				field,
				{ value: value as FilterValue, counter: counters[value as string] },
				{
					[AlertPropertiesPaths.Confidence]: getConfidenceOption,
					[AlertPropertiesPaths.Severity]: getSeverityOption,
				},
			);
		});
	});

	get alertIds() {
		const data = this.data;

		return data.reduce<string[]>((result: string[], alert) => {
			if (alert.state?.alertState === AlertState.Dismissed || !this.isRowSelected(alert[AssignedAlertPropertiesPaths.Id])) {
				return result;
			}

			return [...result, alert.id];
		}, []);
	}

	get selectedAssignCount() {
		return this.data
			.filter((alert) => alert.state?.alertState === AlertState.Assigned)
			.filter((item) => {
				const id = _get(item, this.getIdPath);

				return this.selectedRows[id];
			}).length;
	}

	setGridHeaderFilter = (field: string, values: Filters) => {
		const filters = values.map((value) => {
			// eslint-disable-next-line @typescript-eslint/ban-types
			let operator: string | Function = 'eq';

			if (field === AlertPropertiesPaths.Source || field === AlertPropertiesPaths.Destination) {
				operator = FilterOperators.IsSomeIn;
			}

			return { value, field, operator };
		});

		this.setFilter({
			id: FilterGroupId.gridHeader,
			filters,
			logic: FilterLogic.And,
			nestedId: field,
			nestedLogic: FilterLogic.Or,
		});
	};

	isStateDismissed = (state?: State | null) => {
		if (_isNil(state)) {
			return false;
		}
		return state?.alertState === AlertState.Dismissed;
	};

	setInitialAlertIds = (ids: string[]) => {
		this.initialIds = ids;
	};

	setSourceData = () => {
		const data = this.viewStore.assignedAlerts.filter((alert) => this.initialIds.includes(alert.id));
		this.sourceData = data;

		this.selectRows(
			data.reduce<{ [key: string]: boolean }>((result, alert) => {
				result[alert.id] = !this.isStateDismissed(alert.state);
				return result;
			}, {}),
		);
	};

	initialIdsDisposer = reaction(
		() => this.initialIds,
		() => {
			this.setSourceData();
		},
	);

	dispose = () => {
		this.initialIdsDisposer();
	};
}
