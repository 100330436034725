import { ResultsTablePropertiesPaths } from '@/app/_common/constants';

export const isIgnoredColumn = (key: string) => {
	return (
		key === ResultsTablePropertiesPaths.Expanded ||
		key === ResultsTablePropertiesPaths.Selected ||
		key === ResultsTablePropertiesPaths.Id ||
		key === ResultsTablePropertiesPaths.Actions
	);
};
