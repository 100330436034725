import { FC } from 'react';
import classNames from 'classnames';
import WarningIcon from '@mui/icons-material/Warning';

import styles from './error-details-icon.module.scss';

interface ErrorDetailsIconProps {
	className?: string;
}

export const ErrorDetailsIcon: FC<ErrorDetailsIconProps> = ({ className }) => <WarningIcon className={classNames(styles.icon, className)} />;
