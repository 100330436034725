import { useCallback } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { ALERT_ACTIONS, ResultsDataItem } from '@/app/_common/types';
import { CommonContextMenuProps } from '@/app/_common/_components/new-context-menu';
import { QueryResultsTableContextMenuContent } from '@/app/_common/_components/query-results/query-results-table/components';
import { RESULTS_ARTIFICIAL_ID_PATH } from '@/app/_common/constants';
import { QueryResultsDetailsViewStore } from '@/app/_common/_components/details-view/stores';

const HIDDEN_ACTIONS = [
	ALERT_ACTIONS.AssignOrCreateInvestigation,
	ALERT_ACTIONS.OpenInvestigation,
	ALERT_ACTIONS.Unassign,
	ALERT_ACTIONS.Dismiss,
	ALERT_ACTIONS.Undismiss,
];

interface ResultsContextMenuContextProps extends CommonContextMenuProps<ResultsDataItem> {
	onResetGridPage?: () => void;
}

export const InvestigationQueryResultsContextMenuContent = observer((props: ResultsContextMenuContextProps) => {
	const { setSelectedAlertId } = useInstance(QueryResultsDetailsViewStore);
	const { dataItem } = props;
	const artificialId = dataItem?.[RESULTS_ARTIFICIAL_ID_PATH];

	const handleOpenDetailsClick = useCallback(() => {
		if (artificialId) {
			setSelectedAlertId(artificialId);
		}
	}, [artificialId, setSelectedAlertId]);

	return (
		<QueryResultsTableContextMenuContent
			{...props}
			onViewDetailsClick={handleOpenDetailsClick}
			hiddenSingleActions={HIDDEN_ACTIONS}
			hiddenBulkActions={HIDDEN_ACTIONS}
		/>
	);
});
