import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AdvancedQueryPageKeyPrefix } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';
import { NotificationMessage } from '@/app/_common/types';
import { ErrorDetailsContent, ErrorDetailsIcon, ErrorDetailsTitle } from '@/app/_common/_components';
import { QueryErrorInstructions } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components';

import styles from './query-results-table-empty-results.module.scss';

interface EmptyResultsProps {
	hasQueryBeenPerformed: boolean;
	error?: NotificationMessage;
}

export const QueryResultsTableEmptyResults: FC<EmptyResultsProps> = ({ hasQueryBeenPerformed, error }) => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });

	return (
		<div className={classNames(styles.emptyResults, { error: error })} data-testid="results-empty">
			<div className={styles.emptyResultsContent}>
				{error && <ErrorDetailsIcon />}
				<ErrorDetailsTitle title={t('results.empty.title')} />
				{hasQueryBeenPerformed && !error && <p className={styles.noRecords}>{t('results.empty.noRecords')}</p>}
				{hasQueryBeenPerformed && error && (
					<>
						<QueryErrorInstructions />
						<ErrorDetailsContent error={error.content} buttonTitle={t('error.details')} />
					</>
				)}
			</div>
		</div>
	);
};
