import { useCallback } from 'react';
import { useInstance } from 'react-ioc';
import { useNavigate } from 'react-router-dom';

import { AUTH_BROADCAST_CHANNEL_MESSAGE } from '@/app/_common/constants';
import { RootPaths } from '@/app/_common/navigation/root-paths';
import { GraphqlClient } from '@/app/_common/graphql';
import { AuthStore } from '@/app/_common/stores';

import { getEncodedLogoutRedirectURL } from '@/app/_common/utils';

export const useAuthBroadcastChannel = () => {
	const navigate = useNavigate();
	const client = useInstance(GraphqlClient);
	const authStore = useInstance(AuthStore);

	const handleSetNewTokens = useCallback(() => {
		authStore.setNewTokens();
		client.reconnectWS();
	}, [authStore, client]);

	const handleTokenExpiry = useCallback(() => {
		navigate(RootPaths.EXPIRED_TOKEN);
		authStore.cleanUpState();
	}, [authStore, navigate]);

	const handleBroadcastChannelMessage = useCallback(
		(message: string) => {
			switch (message) {
				case AUTH_BROADCAST_CHANNEL_MESSAGE.LogoutMessage:
					window.location.href = getEncodedLogoutRedirectURL();
					authStore.setInitialState();
					break;
				case AUTH_BROADCAST_CHANNEL_MESSAGE.ReplaceTokens:
					handleSetNewTokens();
					break;
				case AUTH_BROADCAST_CHANNEL_MESSAGE.ExpiredToken:
					handleTokenExpiry();
					break;
				default:
					// eslint-disable-next-line no-console
					console.log('Unsupported broadcast channel message:', message);
					break;
			}
		},
		[authStore, handleTokenExpiry, handleSetNewTokens],
	);

	authStore.assignBroadcastChannelHandler(handleBroadcastChannelMessage);
};
