export const ErrorTypename = 'Error';

export enum InvestigationTypename {
	Investigation = 'Investigation',
	InvestigationEdge = 'InvestigationEdge',
	Error = 'Error',
}

export enum NoteTypename {
	Note = 'Note',
	NoteEdge = 'NoteEdge',
	Error = 'Error',
}

export enum IntegrationTypename {
	Integration = 'Integration',
	IntegrationEdge = 'IntegrationEdge',
	Error = 'Error',
}

export enum SignupTenantTypename {
	SignupTenant = 'SignupTenant',
	Error = 'Error',
}

export const DEFAULT_SUBSCRIPTION_RECONNECT_ATTEMPTS = 1;
export const SUBSCRIPTION_DEBOUNCE_TIME = 500;

export enum StatusCodes {
	UNAUTHORIZED = 401,
}

export enum InviteUserResponseTypenames {
	User = 'User',
	Error = 'Error',
}

export const InvestigationSummaryTypename = 'InvestigationSummary';
export const RequesterUserTypename = 'RequesterUser';
export const AlertStateTypename = 'State';

export const PAGE_SIZE = 40;
export const INITIAL_PAGE = {
	skip: 0,
	take: PAGE_SIZE,
};

export const DEFAULT_FIELD_PREFIX = 'node.';
