export enum WidgetKeys {
	alertCounter = 'alertCounter',
	severityCounter = 'severityCounter',
	detectedByCounter = 'detectedByCounter',
	alerts = 'alerts',
	investigations = 'investigations',
}

export enum TileWidgetGraphType {
	None = 'none',
	Bars = 'bars',
	PieChart = 'pieChart',
}

export interface Position {
	col: number;
	colSpan: number;
	rowSpan: number;
	order?: number;
	widgetKey?: string;
	expanded: boolean;
}
