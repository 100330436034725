import { FC, useState, ReactElement, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _isObject from 'lodash/isObject';
import classNames from 'classnames';
import moment from 'moment';
import { DateInputProps, DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { ListItemProps } from '@progress/kendo-react-dropdowns';

import { useOutsideClick } from '@/app/_common/hooks';
import { DateTimePickerOptionKeys, DATE_TIME_PICKER_FORMAT, EarliestCustomDateStart } from '@/app/_common/constants';
import { getTimeRange, dateToUtcMoment } from '@/app/_common/utils';
import { FormDropdown, FormDropdownOption, FormDropdownProps } from '@/app/_common/_components/form-components';
import { getTimeRangeBoundaries } from '@/app/_common/_components/date-picker/utils';
import { DatePickerInput } from './date-picker-input';
import { FormDateTimePicker } from './form-date-time-picker';
import { datePickerItemRender } from '../date-picker/date-picker-render-utils';
import { Namespaces } from '@/translations/namespaces';

import styles from './form-date-picker.module.scss';

export interface DateRangePickerValue {
	start?: string;
	end?: string;
}

export interface RangePeriodValue {
	value: DateRangePickerValue | null;
	label: string;
	key?: string;
}

interface FormDateTimePickerProps extends FormDropdownProps {
	formatter?: (date: moment.Moment) => string;
	options: Array<{ label: string; value: DateTimePickerOptionKeys }>;
	defaultValue?: RangePeriodValue;
	onCustomChange?: (isCustom: boolean) => void;
	isMaximumDateNow?: boolean;
	utc?: boolean;
	disabledMessage?: string;
	hideCustomDateLabels?: boolean;
	earliestCustomDateStart?: EarliestCustomDateStart;
	dropdownClassname?: string;
}

export const FormDateRangePicker: FC<FormDateTimePickerProps> = ({
	value: initialValue,
	options,
	onChange = () => null,
	onCustomChange = () => null,
	formatter = (date) => date.format(),
	className,
	isMaximumDateNow = false,
	utc = false,
	disabled = false,
	disabledMessage,
	hideCustomDateLabels = false,
	earliestCustomDateStart,
	dropdownClassname,
	...restProps
}) => {
	const [datePickerValue, setDatePickerValue] = useState(initialValue);

	useEffect(() => {
		if (!initialValue.isTemporal) {
			setDatePickerValue(initialValue);
		}
	}, [initialValue]);

	const { t } = useTranslation([Namespaces.TimeRange]);
	const CUSTOM_OPTION_LABEL = t('options.custom');

	const [showStart, setShowStart] = useState(false);
	const [showEnd, setShowEnd] = useState(false);

	const { startMin, startMax, endMin, endMax } = getTimeRangeBoundaries(
		datePickerValue?.value?.start,
		datePickerValue?.value?.end,
		isMaximumDateNow,
		utc,
		earliestCustomDateStart,
	);

	const { popupRef: popupRefFrom } = useOutsideClick<HTMLTableCellElement, HTMLDivElement>(
		showStart,
		useCallback(() => setShowStart(false), []),
	);
	const { popupRef: popupRefTo } = useOutsideClick<HTMLTableCellElement, HTMLDivElement>(
		showEnd,
		useCallback(() => setShowEnd(false), []),
	);

	const toggleStartDatePicker = useCallback(() => {
		setShowStart(!showStart);
	}, [showStart]);

	const toggleEndDatePicker = useCallback(() => {
		setShowEnd(!showEnd);
	}, [showEnd]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleDropdownChange = (event: any) => {
		const newValue = {
			...event.value,
			value: getTimeRange(event.value.value, formatter),
			key: event.value.value,
		};

		setDatePickerValue(newValue);

		onCustomChange(event.value.value === DateTimePickerOptionKeys.CUSTOM);

		if (event.value.value !== DateTimePickerOptionKeys.CUSTOM) {
			onChange({ ...event, value: newValue, isTemporal: false });
		} else {
			toggleStartDatePicker();
		}
	};

	const handleStartChange = (event: DateTimePickerChangeEvent) => {
		if (!event.value) return;

		const dateMoment = utc ? dateToUtcMoment(event.value) : moment(event.value);

		const newValue = {
			value: {
				...datePickerValue.value,
				start: formatter(dateMoment),
			},
			label: CUSTOM_OPTION_LABEL,
			key: DateTimePickerOptionKeys.CUSTOM,
			isTemporal: false,
		};

		setDatePickerValue(newValue);

		if (datePickerValue?.value?.end) {
			onChange({ ...event, value: newValue });
		}

		toggleStartDatePicker();

		if (!datePickerValue.value?.end) {
			toggleEndDatePicker();
		}
	};

	const handleEndChange = (event: DateTimePickerChangeEvent) => {
		if (!event.value) return;

		const isValueLessThanEndMin = endMin && new Date(event.value) < new Date(endMin);
		const validatedValue = isValueLessThanEndMin ? endMin : event.value;

		const dateMoment = utc ? dateToUtcMoment(validatedValue) : moment(validatedValue);

		const newValue = {
			value: {
				...datePickerValue.value,
				end: formatter(dateMoment),
			},
			label: CUSTOM_OPTION_LABEL,
			key: DateTimePickerOptionKeys.CUSTOM,
			isTemporal: false,
		};

		setDatePickerValue(newValue);

		if (datePickerValue?.value?.start) {
			onChange({ ...event, value: newValue });
		}

		toggleEndDatePicker();
	};

	const dropdownOptions = useMemo(() => {
		const _options = options as FormDropdownOption[];

		return _options.filter(_isObject);
	}, [options]);

	const disabledOption: FormDropdownOption[] = [
		{
			value: disabledMessage || 'disabled',
			label: disabledMessage || '',
		},
	];

	const getDatePickerInputValue = (dateTimeString: string, utc: boolean) => {
		const timezone = utc ? '.000Z' : '';
		return dateTimeString ? new Date(`${dateTimeString}${timezone}`) : undefined;
	};

	const DatePickerInputStartRender = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: DateInputProps<any>) => (
			<DatePickerInput
				{...props}
				toggleDatePicker={toggleStartDatePicker}
				utc={utc}
				label={!hideCustomDateLabels ? t('fromDateTimePicker.label') : undefined}
				value={getDatePickerInputValue(datePickerValue?.value?.start, utc)}
			/>
		),
		[toggleStartDatePicker, utc, hideCustomDateLabels, t, datePickerValue?.value?.start],
	);

	const DatePickerInputEndRender = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: DateInputProps<any>) => (
			<DatePickerInput
				{...props}
				toggleDatePicker={toggleEndDatePicker}
				utc={utc}
				label={!hideCustomDateLabels ? t('toDateTimePicker.label') : undefined}
				value={getDatePickerInputValue(datePickerValue?.value?.end, utc)}
			/>
		),
		[toggleEndDatePicker, utc, hideCustomDateLabels, t, datePickerValue?.value?.end],
	);

	return (
		<div className={classNames(styles.datePickerContainer, className)}>
			<FormDropdown
				{...restProps}
				options={disabled ? disabledOption : dropdownOptions}
				value={disabled ? disabledOption[0] : { ...datePickerValue, label: t(`options.${datePickerValue?.key}`) }}
				onChange={handleDropdownChange}
				itemRender={(li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => datePickerItemRender(li, itemProps, toggleStartDatePicker)}
				disabled={disabled}
				className={dropdownClassname}
			/>
			{datePickerValue.key === DateTimePickerOptionKeys.CUSTOM && (
				<div className={styles.customDateInputsContainer}>
					<FormDateTimePicker
						dateInput={DatePickerInputStartRender}
						className={styles.fromCustomDateInput}
						show={showStart}
						format={{
							skeleton: DATE_TIME_PICKER_FORMAT,
						}}
						max={startMax}
						cancelButton={false}
						onChange={handleStartChange}
						ref={popupRefFrom}
						min={startMin}
					/>
					<FormDateTimePicker
						dateInput={DatePickerInputEndRender}
						show={showEnd}
						format={{
							skeleton: DATE_TIME_PICKER_FORMAT,
						}}
						min={endMin}
						max={endMax}
						cancelButton={false}
						onChange={handleEndChange}
						ref={popupRefTo}
					/>
				</div>
			)}
		</div>
	);
};
