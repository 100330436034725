import { FC, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';

import { Align } from '@progress/kendo-react-popup';
import { PanelBar, PanelBarItemProps, PanelBarSelectEventArguments, PanelBarUtils } from '@progress/kendo-react-layout';
import { AdvancedQueryChartSettingsViewStore, AdvancedQueryChartViewStore } from '@/app/advanced-query/_common/stores';
import { ContextMenu, ContextMenuSection } from '@/app/_common/_components';
import { ChatSettingsIcon } from '../chart-settings-icon';
import { AdvancedQueryPageKeyPrefix, INTERVAL_OPTIONS } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';

import { StackedBarChartSwitch } from './_components';

import styles from './chart-settings.module.scss';

const DROPDOWN_ANCHOR_ALIGN: Align = {
	horizontal: 'right',
	vertical: 'bottom',
};

const DROPDOWN_POPUP_ALIGN: Align = {
	horizontal: 'right',
	vertical: 'top',
};

export const ChartSettings: FC = observer(() => {
	const chartViewStore = useInstance(AdvancedQueryChartViewStore);
	const advancedQueryChartSettingsViewStore = useInstance(AdvancedQueryChartSettingsViewStore);

	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });

	const items: Array<PanelBarItemProps> = [
		{
			title: t('chart.actionBlock.interval'),
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			children: INTERVAL_OPTIONS as any,
		},
	];

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const components: any = PanelBarUtils.mapItemsToComponents(items);

	const handleOnSelect = useCallback(
		(e: PanelBarSelectEventArguments) => {
			if (e.target.props.children) {
				return;
			}
			chartViewStore.onSelectInterval(e.target.props.uniquePrivateKey, e.target.props.valueInSeconds);
		},
		[chartViewStore],
	);

	const ContentRenderer = useCallback(
		() => (
			<ContextMenuSection className={styles.section} title={t('chart.actionBlock.resultsGraph')}>
				<StackedBarChartSwitch
					onChange={chartViewStore.onStackedBarChartChange}
					checked={advancedQueryChartSettingsViewStore.isStackedBarChart ?? chartViewStore.isStackedBarChart}
				/>

				<PanelBar className={styles.popupContent} selected={chartViewStore.intervalSelected} onSelect={handleOnSelect}>
					{components}
				</PanelBar>
			</ContextMenuSection>
		),
		[
			advancedQueryChartSettingsViewStore.isStackedBarChart,
			chartViewStore.intervalSelected,
			chartViewStore.isStackedBarChart,
			chartViewStore.onStackedBarChartChange,
			components,
			handleOnSelect,
			t,
		],
	);

	return (
		<ContextMenu anchorAlign={DROPDOWN_ANCHOR_ALIGN} popupAlign={DROPDOWN_POPUP_ALIGN} content={ContentRenderer} className={styles.popup}>
			<ChatSettingsIcon />
		</ContextMenu>
	);
});
