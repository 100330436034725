import { useTranslation } from 'react-i18next';

import { ReactComponent as AlertTimelineIcon } from '@/assets/images/alert-timeline-icon.svg';
import {
	AlertDetailsTimestamp,
	Description,
	MitreAttackTreeBox,
	ExpansionPanelWrapper,
} from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components';
import { AlertDetailsSeverity } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alert-details-severity';
import { AlertDetailsConfidence } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alert-details-confidence';
import { MitreAttackTactic } from '@/app/_common/_components/mitre-attack-tree/types';
import { TreeStyle } from '@/app/_common/_components/mitre-attack-tree/constants';
import { RawField } from '@/app/_common/_components/details-view/components';
import { prepareMitreAttackTreeTactic } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_common/utils/prepare-mitre-attack-tree-tactics/prepare-mitre-attack-tree-tactic';
import { Evidence } from '@/app/_common/types';
import { getMergedTacticsAndTechniques } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_common/utils/getMergedTacticsAndTechniques';
import { Namespaces } from '@/translations/namespaces';
import { getSeverityColor } from '@/app/_common/utils/colors/get-severity-color';

import styles from './correlated-alert-evidence.module.scss';

interface CorrelatedAlertEvidenceProps {
	evidence: Evidence;
	enumerate: number;
	isSingleItemView: boolean;
}

export const CorrelatedAlertEvidence = ({ evidence, enumerate, isSingleItemView }: CorrelatedAlertEvidenceProps) => {
	const { t } = useTranslation(Namespaces.DetailsView);

	const { timestamp, signature, severity, confidence, signature_details, mitre_tactics, mitre_techniques, raw, alert_id } = evidence;
	const mitreAttackTactic: MitreAttackTactic | undefined = prepareMitreAttackTreeTactic(mitre_tactics, mitre_techniques);
	const mergedTacticsAndTechniques: MitreAttackTactic[] = getMergedTacticsAndTechniques([mitreAttackTactic] as MitreAttackTactic[]).filter(
		(tactic) => tactic,
	);

	return (
		<div className={styles.container}>
			<div className={styles.enumerateContainer}>
				{!isSingleItemView && <span className={styles.enumerate}>{enumerate}.</span>}
				<AlertTimelineIcon style={{ fill: getSeverityColor(severity) }} />
			</div>
			<div className={styles.nameContainer}>
				<span className={styles.name}>{signature ?? '-'}</span>
			</div>
			{/* grid placeholder column */}
			<div />
			<div>
				<div className={styles.state}>
					<AlertDetailsTimestamp timestamp={timestamp} />
					{!isSingleItemView && (
						<>
							<AlertDetailsSeverity severity={severity} />
							<AlertDetailsConfidence confidence={confidence} />
							<MitreAttackTreeBox tactics={mergedTacticsAndTechniques} treeStyle={TreeStyle.COMPRESSED} />
						</>
					)}
				</div>
				{!isSingleItemView && <Description className={styles.description} description={signature_details} isOneLineOnCollapse />}
				<div className={styles.alertRawContainer}>
					{raw !== undefined && (
						<ExpansionPanelWrapper title={t('rawField.expansionPanel.title')} expanded={true}>
							<RawField itemId={alert_id} itemName={signature} value={raw} />
						</ExpansionPanelWrapper>
					)}
				</div>
			</div>
		</div>
	);
};
