import { useMemo } from 'react';
import classNames from 'classnames';

import { Avatar, AvatarProps } from '@/app/_common/_components/avatar';

import styles from './avatars-list.module.scss';

interface AvatarsListProps {
	items: (AvatarProps & { id?: string | number })[];
	remainingItemProps?: Partial<AvatarProps>;
	maxItems?: number;
	className?: string;
}

const DEFAULT_MAX_NUMBER_OF_AVATARS = 4;

export const AvatarsList = ({ items, remainingItemProps = {}, maxItems = DEFAULT_MAX_NUMBER_OF_AVATARS, className }: AvatarsListProps) => {
	const { avatars, remaining } = useMemo(() => {
		const index = items.length <= maxItems ? maxItems : maxItems - 1;
		return {
			avatars: items.slice(0, index),
			remaining: items.slice(index),
		};
	}, [items, maxItems]);

	const remainingProps: AvatarProps = useMemo(
		() => ({
			name: `+${remaining.length}`,
			tooltipContent: items.map(({ name }) => <p key={name}>{name}</p>),
			...remainingItemProps,
		}),
		[items, remaining, remainingItemProps],
	);

	return (
		<div className={classNames(styles.avatars, className)}>
			{avatars.map(({ id, name, ...props }, index) => (
				<Avatar key={id ?? name} name={name} overlap={index > 0} {...props} />
			))}
			{remaining.length > 0 && <Avatar {...remainingProps} />}
		</div>
	);
};
