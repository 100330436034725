import { MitreAttackBadgeColor } from './_constants/mitre-attack-badge-color';
import { MitreAttackBadgeText } from './_constants/mitre-attack-badge-text';

export const getMitreAttackColor = (text: string): MitreAttackBadgeColor => {
	switch (text) {
		case MitreAttackBadgeText.PREATTACK:
		case MitreAttackBadgeText.RECONNAISSANCE:
		case MitreAttackBadgeText.RESOURCE_DEVELOPMENT:
		case MitreAttackBadgeText.INITIAL_ACCESS:
			return MitreAttackBadgeColor.BLUE;
		case MitreAttackBadgeText.EXECUTION:
		case MitreAttackBadgeText.PERSISTENCE:
		case MitreAttackBadgeText.PRIVILEGE_ESCALATION:
		case MitreAttackBadgeText.DEFENSE_EVASION:
			return MitreAttackBadgeColor.YELLOW;
		case MitreAttackBadgeText.CREDENTIAL_ACCESS:
		case MitreAttackBadgeText.DISCOVERY:
		case MitreAttackBadgeText.LATERAL_MOVEMENT:
			return MitreAttackBadgeColor.GRAPE;
		case MitreAttackBadgeText.COLLECTION:
		case MitreAttackBadgeText.COMMAND_AND_CONTROL:
		case MitreAttackBadgeText.EXFILTRATION:
		case MitreAttackBadgeText.IMPACT:
			return MitreAttackBadgeColor.RED;
		default:
			return MitreAttackBadgeColor.BLUE;
	}
};
