import { action, computed, makeObservable } from 'mobx';

import { InvestigationStatus, Query, QueryListInvestigationsArgs, ValueOperator } from '@/generated/graphql';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { ListInvestigationsForSelect } from '@/app/_common/graphql/queries';

export class InvestigationSelectDataStore extends GraphqlBaseDataStore<Query, QueryListInvestigationsArgs> {
	constructor() {
		super();
		makeObservable(this, {
			investigations: computed,
			read: action,
		});
	}

	get investigations() {
		return this.data?.listInvestigations?.edges || [];
	}

	read() {
		const variables = {
			tenantId: this.authStore.currentTenantId,
			filtersInput: {
				valueFilters: [
					{
						field: 'status',
						operator: ValueOperator.IsNot,
						value: InvestigationStatus.Closed,
					},
				],
			},
		};

		this.query({
			query: ListInvestigationsForSelect,
			variables,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
		});
	}
}
