import { FC, ReactNode } from 'react';

import { CustomText } from '@/app/_common/_components/rich-text-editor';

interface LeafProps {
	leaf: CustomText;
	text: Text | CustomText;
	attributes: {
		'data-slate-leaf': true;
	};
	children?: ReactNode;
}

export const Leaf: FC<LeafProps> = ({ attributes, children, leaf }) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>;
	}

	if (leaf.italic) {
		children = <em>{children}</em>;
	}

	if (leaf.underline) {
		children = <u>{children}</u>;
	}

	return <span {...attributes}>{children}</span>;
};
