import { makeObservable, computed, action, reaction } from 'mobx';

import { FieldValue, Query, QueryGetEndpointsTableHeadersCountArgs } from '@/generated/graphql';
import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { GetEndpointsTableHeadersCountTypename } from '@/app/_common/constants';
import { GetEndpointsTableHeadersCount } from '@/app/_common/graphql/queries';
import { getGraphQLError } from '@/app/_common/graphql/graphql-error-handler';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { NotificationsStore } from '@/app/_common/stores';

type GetEndpointsTableHeadersCountResponse = Pick<Query, 'getEndpointsTableHeadersCount'>;

export class EndpointsColumnCountersDataStore extends GraphqlBaseDataStore<
	GetEndpointsTableHeadersCountResponse,
	QueryGetEndpointsTableHeadersCountArgs
> {
	private notificationsStore = injectInterface(this, NotificationsStore);

	constructor() {
		super();

		makeObservable(this, {
			counters: computed,
			read: action,
		});
	}

	get counters() {
		return this.data?.getEndpointsTableHeadersCount;
	}

	read(valueFilters: Array<FieldValue> = []) {
		const variables: QueryGetEndpointsTableHeadersCountArgs = {
			tenantId: this.authStore.currentTenantId,
			filtersInput: {
				valueFilters,
			},
		};

		this.query({
			query: GetEndpointsTableHeadersCount,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}

	errorDisposer = reaction(
		() => this.counters,
		() => {
			if (this.counters?.__typename === GetEndpointsTableHeadersCountTypename.Error) {
				this.notificationsStore.openError(getGraphQLError('Error', this.counters));
			}
		},
	);

	dispose() {
		this.errorDisposer();
	}
}
