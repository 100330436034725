import { MouseEvent, useState } from 'react';
import classNames from 'classnames';
import { Popup } from '@progress/kendo-react-popup';

import { DetailsViewNavigationField, DetailsViewNavigationFieldContextMenuType } from '@/app/_common/_components/details-view/types';
import { useContextMenu } from '@/app/_common/hooks';
import { DetailsViewNavigationFieldRowContextMenu } from '@/app/_common/_components/details-view/components/details-view-navigation-fields/components/details-view-navigation-field-row-context-menu';
import { AVAILABLE_FIELDS_BLOCK_NAME } from '@/app/_common/types';

import styles from './details-view-navigation-fields-row.module.scss';

interface DetailsViewNavigationFieldsRowProps {
	fieldData: DetailsViewNavigationField;
	blockName: AVAILABLE_FIELDS_BLOCK_NAME | null;
}

export const DetailsViewNavigationFieldsRow = ({ fieldData, blockName }: DetailsViewNavigationFieldsRowProps) => {
	const { label, value } = fieldData;

	const [contextMenuType, setContextMenuType] = useState<DetailsViewNavigationFieldContextMenuType>(
		DetailsViewNavigationFieldContextMenuType.Default,
	);

	const { showContextMenu, contextMenuOffset, openContextMenu, closeContextMenu, contextMenuRef } = useContextMenu();

	const handleOpenContextMenu = (menuType: DetailsViewNavigationFieldContextMenuType) => (event: MouseEvent) => {
		event.preventDefault();
		setContextMenuType(menuType);
		openContextMenu(event);
	};

	return (
		<>
			<div className={styles.container}>
				<span
					onContextMenu={handleOpenContextMenu(DetailsViewNavigationFieldContextMenuType.Label)}
					className={classNames(styles.label, {
						showContextMenu: showContextMenu && contextMenuType === DetailsViewNavigationFieldContextMenuType.Label,
					})}
				>
					{label}
				</span>
				<span
					onContextMenu={handleOpenContextMenu(DetailsViewNavigationFieldContextMenuType.Value)}
					className={classNames(styles.value, {
						showContextMenu: showContextMenu && contextMenuType === DetailsViewNavigationFieldContextMenuType.Value,
					})}
				>
					{String(value)}
				</span>
			</div>

			<Popup className={styles.contextMenuContainer} show={showContextMenu} offset={contextMenuOffset} animate={false}>
				<div ref={contextMenuRef}>
					<DetailsViewNavigationFieldRowContextMenu
						fieldData={fieldData}
						menuType={contextMenuType}
						closeContextMenu={closeContextMenu}
						blockName={blockName}
					/>
				</div>
			</Popup>
		</>
	);
};
