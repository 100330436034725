import classNames from 'classnames';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@/app/_common/_components/button';

import styles from './reason-dialog-actions.module.scss';
import { Namespaces } from '@/translations/namespaces';
import { useTranslation } from 'react-i18next';
import { buttonize } from '@/app/_common/utils';
import { ReasonKeyPrefix } from '@/app/_common/constants';

interface ReasonDialogActionsProps {
	isEditModeActive: boolean;
	activateEditMode: () => void;
	closeWindow: () => void;
	disabled?: boolean;
}

export const ReasonDialogActions = ({ isEditModeActive, closeWindow, activateEditMode, disabled }: ReasonDialogActionsProps) => {
	const { t } = useTranslation(Namespaces.QueryResults, { keyPrefix: ReasonKeyPrefix.Reason });

	return (
		<div className={styles.actions}>
			<div className={styles.icons}>
				{isEditModeActive && <div className={styles.editingText}>{t('editing')}</div>}
				{!disabled && (
					<div className={classNames(styles.icon, { [styles.editingActive]: isEditModeActive })} {...buttonize(activateEditMode)}>
						<EditIcon />
					</div>
				)}
			</div>
			<Button fillMode="outline" onClick={closeWindow}>
				{t('actions.close')} <CloseIcon />
			</Button>
		</div>
	);
};
