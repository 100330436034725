import { DetailsViewItem } from '@/app/_common/_components/details-view/types';
import { DetailsViewItemPropertiesPaths } from '@/app/_common/_components/details-view/types/details-view-item-properties-paths';

export const getEventDetailsViewItemTitle = (item: DetailsViewItem): string => {
	if (!item) {
		return '';
	}

	try {
		const vendor: string = DetailsViewItemPropertiesPaths.Vendor in item ? item[DetailsViewItemPropertiesPaths.Vendor] : '';
		const product: string = DetailsViewItemPropertiesPaths.Product in item ? item[DetailsViewItemPropertiesPaths.Product] : '';
		const type: string = DetailsViewItemPropertiesPaths.Type in item ? item[DetailsViewItemPropertiesPaths.Type] : '';

		const titleParts = [vendor, product, type].filter(Boolean);
		return titleParts.join(' - ');
	} catch (error) {
		return '';
	}
};
