import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { Investigation, InvestigationsConnection } from '@/generated/graphql';
import { InvestigationTypename } from '@/app/_common/constants';

export const assignAlertListInvestigationUpdate =
	(node: Investigation) =>
	(existingInvestigationRefs: InvestigationsConnection, { readField }: { readField: ReadFieldFunction }) => {
		const exists = existingInvestigationRefs?.edges?.some(
			(ref) => node?.__typename === InvestigationTypename.Investigation && readField('id', ref.node) === node?.id,
		);

		if (exists) {
			return existingInvestigationRefs;
		}

		return {
			...existingInvestigationRefs,
			edges: [
				...(existingInvestigationRefs?.edges || []),
				{
					node,
					__typename: InvestigationTypename.InvestigationEdge,
				},
			],
		};
	};
