import { action, makeObservable } from 'mobx';

import { UpdateInvestigation } from '@/app/_common/graphql/queries';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { Mutation, MutationUpdateInvestigationArgs } from '@/generated/graphql';

export class InvestigationsEditDataStore extends GraphqlBaseDataStore<Mutation, MutationUpdateInvestigationArgs> {
	constructor() {
		super();
		makeObservable(this, {
			update: action,
		});
	}

	async update(args: Omit<MutationUpdateInvestigationArgs, 'tenantId'>) {
		const variables = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationUpdateInvestigationArgs>({
			mutation: UpdateInvestigation,
			variables,
		});

		return response;
	}
}
