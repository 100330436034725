import { ApolloCache } from '@apollo/client';

import { writeAlertStateUpdateFragment } from '@/app/_common/utils';
import { Alert, AlertsConnection } from '@/generated/graphql';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

export const setAlertStateListAlertsUpdate =
	(cache: ApolloCache<unknown>, alerts: Alert[]) =>
	(existingAlertRefs: AlertsConnection, { readField }: { readField: ReadFieldFunction }) => {
		const alertsIds = alerts.map(({ id }) => id);
		return {
			...(existingAlertRefs || {}),
			edges: existingAlertRefs?.edges.map((edgeRef) => {
				const alertId = readField('id', edgeRef.node);

				if (typeof alertId === 'string' && alertsIds.includes(alertId)) {
					const updatedAlert = alerts.find(({ id }) => id === alertId);

					if (!updatedAlert) {
						return edgeRef;
					}

					const updatedAlertRef = writeAlertStateUpdateFragment(cache, alertId, updatedAlert);

					return {
						...edgeRef,
						node: updatedAlertRef,
					};
				}
				return edgeRef;
			}),
		};
	};
