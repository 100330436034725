/* istanbul ignore file */

import { AdxEndpoints } from '@/app/_common/constants';
import { editor, Uri } from 'monaco-editor';

const API_URL = process.env.REACT_APP_ADVANCED_QUERY_URL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setKustoSchema(editor: editor.IStandaloneCodeEditor, schema: any, tenantId: string) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(window as any).monaco.languages.kusto.getKustoWorker().then((workerAccessor: (arg0: Uri) => Promise<unknown>) => {
		const model = editor.getModel();

		if (!model || !schema) {
			return;
		}

		workerAccessor(model.uri)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then((worker: any) => {
				worker.setSchemaFromShowSchema(JSON.parse(JSON.stringify(schema)), `${API_URL}/${AdxEndpoints.ShowSchemaV2}`, tenantId);
			})
			// eslint-disable-next-line no-console
			.catch(console.error);
	});
}
