import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';
import { MultiItemsCellContentProps } from '../types';
import { MultiValueCellLabel } from '@/app/_common/_components/data-grid/_components/multi-value-cell/components/multi-value-cell-label';
import { MultiValueCellCounter } from '@/app/_common/_components/data-grid/_components/multi-value-cell/components/multi-value-cell-counter';

export const DefaultCellContent = ({ value }: MultiItemsCellContentProps) => {
	const { t } = useTranslation([Namespaces.Common], { keyPrefix: 'grid' });
	return (
		<>
			<MultiValueCellLabel text={t('multiple')} />
			<MultiValueCellCounter count={`(${value.length})`} />
		</>
	);
};
