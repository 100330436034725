import React, { FC, useMemo, MutableRefObject } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import classNames from 'classnames';
import _max from 'lodash/max';

import BaseBrush from '@visx/brush/lib/BaseBrush';
import { Bar, BarStack } from '@visx/shape';
import { Group } from '@visx/group';
import { Grid } from '@visx/grid';
import { AxisLeft } from '@visx/axis';
import { ContinuousInput, scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Brush } from '@visx/brush';
import { Bounds } from '@visx/brush/lib/types';
import { max } from 'd3-array';

import { AdvancedQueryChartViewStore, AdvancedQueryViewStore } from '@/app/advanced-query/_common/stores';
import { ThemeStore } from '@/app/_common/stores';
import {
	ACTION_BLOCK_MARGIN,
	AXIS_FONT_SIZE,
	BAR_STACK_INNER_PADDING,
	BRUSH_EXTRA_HEIGHT,
	BRUSH_RESIZE_TRIGGER_AREAS,
	DEFAULT_CHART_MARGIN,
	GRID_COLUMN_LINE_STYLE,
	GRID_ROW_HEIGHT,
	ONE_DIGIT_WIDTH,
	TOOLTIP_BOTTOM_OFFSET,
	X_AXIS_HEIGHT,
} from '@/app/_common/constants';
import { ChartBarData, ChartStackBarKeys, ShowTooltipData, TooltipData } from '@/app/_common/types';
import { getTheme } from '@/app/_common/utils';
import {
	formatCount,
	getBrushValue,
	getChartGraphKeys,
	getChartGraphStackColors,
	getDate,
	getTooltipHeight,
	prepareTimeRangeForQuery,
} from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/chart/utils';
import { isStackedChartDataItemArray } from '@/app/advanced-query/_common/types';

interface ChartProps {
	width: number;
	barGroupLeft: number;
	height: number;
	xMax: number;
	containerRef: (element: HTMLElement | SVGElement | null) => void;
	hideTooltip: () => void;
	showTooltip: (args: ShowTooltipData) => void;
	tooltipWidth: number;
	selectedBrushRange: string[];
	brushRef: MutableRefObject<BaseBrush | null> | undefined;
	resetBrush: () => void;
	setSelectedBrushRange: (range: string[]) => void;
	actionBlockHeight: number;
	setIsArrowToRight: (isArrowToRight: boolean) => void;
}

const { top: marginTop, bottom: marginBottom, left: marginLeft } = DEFAULT_CHART_MARGIN;

export const ChartGraph: FC<ChartProps> = observer(
	({
		barGroupLeft,
		xMax,
		width,
		height,
		containerRef,
		hideTooltip,
		showTooltip,
		tooltipWidth,
		selectedBrushRange,
		brushRef,
		resetBrush,
		setSelectedBrushRange,
		actionBlockHeight,
		setIsArrowToRight,
	}) => {
		const { theme } = useInstance(ThemeStore);
		const { chartData, countTotals, isStackedBarChart } = useInstance(AdvancedQueryChartViewStore);
		const { runCustomQuery, persistentTimeRange } = useInstance(AdvancedQueryViewStore);

		const persistentTimeRangeToValue = persistentTimeRange?.value?.to || '';
		const showStackedBars = isStackedBarChart && isStackedChartDataItemArray(chartData);
		const useStackedBarsTotalCountKey = isStackedChartDataItemArray(chartData) && !isStackedBarChart;

		// chart styles
		const themeVariables = useMemo(() => {
			return getTheme(theme);
		}, [theme]);

		const keys = getChartGraphKeys(showStackedBars, useStackedBarsTotalCountKey);
		const barStackColors = getChartGraphStackColors({ showStackedBars, themeVariables, useStackedBarsTotalCountKey });

		const AXIS_COLOR = themeVariables.advancedQueryChartAxisColor;

		const gridProps = useMemo(() => {
			return {
				top: DEFAULT_CHART_MARGIN.top,
				stroke: themeVariables.advancedQueryChartGridStrokeColor,
				columnLineStyle: GRID_COLUMN_LINE_STYLE,
			};
		}, [themeVariables]);

		const selectedBrushStyle = useMemo(() => {
			return {
				fill: themeVariables.advancedQueryChartBrushFillColor,
				stroke: themeVariables.advancedQueryChartBrushStrokeColor,
			};
		}, [themeVariables]);

		const yAxisLabelMaxLength = String(_max(countTotals)).length;
		// widths and margins
		const yAxisComponentNetWidth = yAxisLabelMaxLength * ONE_DIGIT_WIDTH;
		const barStackLeft = yAxisComponentNetWidth + marginLeft;
		const brushMargin = { top: 0, bottom: 0, left: barGroupLeft, right: 0 };

		// bounds
		const actionBlockContainerHeight = actionBlockHeight + ACTION_BLOCK_MARGIN;
		const svgHeight = height - actionBlockContainerHeight;

		if (svgHeight < 0) {
			return null;
		}

		const yMax = height - marginTop - marginBottom - X_AXIS_HEIGHT - actionBlockContainerHeight;

		// scales
		const dateScale = useMemo(
			() =>
				scaleBand<string>({
					domain: chartData.map(getDate),
					paddingInner: BAR_STACK_INNER_PADDING,
					paddingOuter: 0,
					range: [0, xMax],
				}),
			[xMax, chartData],
		);

		const countScale = useMemo(
			() =>
				scaleLinear<number>({
					domain: [0, _max(countTotals) as ContinuousInput],
					nice: true,
					range: [yMax, 0],
				}),
			[yMax, countTotals],
		);

		const brushXScale = useMemo(
			() =>
				scaleBand<string>({
					domain: chartData.map(getDate),
					paddingInner: BAR_STACK_INNER_PADDING,
					paddingOuter: 0,
					range: [0, xMax],
				}),
			[xMax, chartData],
		);

		const brushYScale = useMemo(
			() =>
				scaleLinear({
					range: [yMax, 0],
					domain: [0, max(chartData, getBrushValue) || 0],
				}),
			[yMax, chartData],
		);

		const barStackColorScale = scaleOrdinal<string, string>({
			domain: keys,
			range: barStackColors,
		});

		const yAxisTicksNumber = Math.floor(yMax / GRID_ROW_HEIGHT) + 1;

		const onBrushEnd = (domain: Bounds | null) => {
			const xValues = domain?.xValues as string[];
			const normalizedTimeRange = xValues?.filter((item: string) => Boolean(item));
			if (normalizedTimeRange?.length) {
				setSelectedBrushRange(normalizedTimeRange);
				runCustomQuery(prepareTimeRangeForQuery(normalizedTimeRange, chartData, persistentTimeRangeToValue));
			}
		};

		const onBarClick = (barData: ChartBarData) => () => {
			const timestamp = barData.bar.data[ChartStackBarKeys.Date];
			setSelectedBrushRange([timestamp]);
			runCustomQuery(prepareTimeRangeForQuery([timestamp], chartData, persistentTimeRangeToValue));
			resetBrush();
		};

		const isBarSelected = (date: string) => {
			return selectedBrushRange.some((item: string) => {
				return item === date;
			});
		};

		const hasSelection = selectedBrushRange.length;

		const calculateTooltipPosition = (bar: TooltipData, tooltipWidth: number, barStackLeft: number, xMax: number) => {
			const borderWidth = 2;
			const arrowLegLength = 8;
			const arrowHypotenuse = Math.sqrt(2) * arrowLegLength;

			const tooltipHeight = getTooltipHeight({ barData: bar.bar.data, isStackedBarChart });

			const barRight = bar.x + bar.width + barStackLeft + borderWidth;
			const barTop = bar.y - tooltipHeight - TOOLTIP_BOTTOM_OFFSET;
			const barCenter = bar.x + bar.width / 2 + barStackLeft + borderWidth - tooltipWidth / 2;

			const leftPositionedTooltipLeft = barRight - bar.width - tooltipWidth - arrowLegLength;
			const leftPositionedTooltipTop = bar.y + bar.height / 2 + arrowHypotenuse / 2 - tooltipHeight / 2;

			const isTooltipWithinBar = tooltipWidth <= bar.width;
			const isBeyondContainer = barRight >= xMax - tooltipWidth / 2;
			const isTooltipPositionedLeft = isBeyondContainer && !isTooltipWithinBar;

			const tooltipTop = isTooltipPositionedLeft ? leftPositionedTooltipTop : barTop;
			const tooltipLeft = isTooltipPositionedLeft ? leftPositionedTooltipLeft : barCenter;

			return { tooltipTop, tooltipLeft, isTooltipPositionedLeft };
		};

		const onMouseEnter = (bar: TooltipData) => () => {
			const { tooltipTop, tooltipLeft, isTooltipPositionedLeft } = calculateTooltipPosition(bar, tooltipWidth, barStackLeft, xMax);

			setIsArrowToRight(isTooltipPositionedLeft);

			showTooltip({
				tooltipData: bar,
				tooltipTop,
				tooltipLeft,
			});
		};

		return (
			<div>
				<svg ref={containerRef} width={width} height={svgHeight}>
					{/*@ts-ignore*/}
					<Grid {...gridProps} left={barGroupLeft} numTicks={yAxisTicksNumber} xScale={dateScale} yScale={countScale} width={xMax} height={yMax} />
					<Group top={marginTop} left={barGroupLeft} width={xMax}>
						{!!chartData.length && (
							<Brush
								xScale={brushXScale}
								yScale={brushYScale}
								width={xMax}
								height={yMax + BRUSH_EXTRA_HEIGHT}
								margin={brushMargin}
								innerRef={brushRef}
								resizeTriggerAreas={BRUSH_RESIZE_TRIGGER_AREAS}
								brushDirection="horizontal"
								onBrushEnd={onBrushEnd}
								selectedBoxStyle={selectedBrushStyle}
								useWindowMoveEvents
							/>
						)}

						<BarStack data={chartData} keys={keys} x={getDate} xScale={dateScale} yScale={countScale} color={barStackColorScale}>
							{(barStacks) => {
								return barStacks.map((barStack) =>
									barStack.bars.map((bar) => {
										const isSelected = isBarSelected(bar.bar.data[ChartStackBarKeys.Date]);
										const isFaded = hasSelection && !isSelected;

										if (bar.y < 0) {
											return null;
										}

										return (
											<Bar
												key={`bar-stack-${barStack.index}-${bar.index}`}
												x={bar.x}
												y={bar.y}
												height={bar.height}
												width={bar.width}
												fill={bar.color}
												onClick={onBarClick(bar)}
												onMouseLeave={() => hideTooltip()}
												onMouseEnter={onMouseEnter(bar)}
												className={classNames('chart-bar', { faded: isFaded })}
											/>
										);
									}),
								);
							}}
						</BarStack>
					</Group>

					<AxisLeft
						top={marginTop}
						left={barGroupLeft}
						scale={countScale}
						stroke={AXIS_COLOR}
						tickStroke={AXIS_COLOR}
						tickFormat={formatCount}
						numTicks={yAxisTicksNumber}
						hideAxisLine
						hideTicks
						tickLabelProps={() => ({
							fill: AXIS_COLOR,
							fontSize: AXIS_FONT_SIZE,
							textAnchor: 'end',
							dy: '0.33em',
							dx: '0',
						})}
					/>
				</svg>
			</div>
		);
	},
);

ChartGraph.displayName = 'ChartGraph';
