import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { makeAutoObservable } from 'mobx';
import { makePersistable, PersistenceStorageOptions, stopPersisting } from 'mobx-persist-store';

export class SwitchTenantStore {
	private authStore = injectInterface(this, AuthStore);
	private childStateRef = {};
	private storageOptions: PersistenceStorageOptions<string> = {
		name: `${this.authStore.currentTenantId}_storage_placeholder`,
		properties: [],
	};
	private readonly source: string = '';

	constructor(source: string) {
		this.source = source;
		makeAutoObservable(this);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	init = (childStateRef: any, storageOptions: PersistenceStorageOptions<string>) => {
		this.childStateRef = childStateRef;
		this.storageOptions = storageOptions;
		const { currentTenantId } = this.authStore;
		if (this.childStateRef !== null) {
			stopPersisting(this.childStateRef);
			// @ts-ignore
			return makePersistable(this.childStateRef, { ...this.storageOptions, name: `${currentTenantId}/${this.storageOptions.name}` });
		}
	};
}
