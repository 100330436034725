import { LABEL_VALUE_ROW_FORMAT } from '@/app/_common/_components';

import { LabelValueRowValue } from '@/app/_common/_components/label-value-row/_common/types';

import { ValueNormal } from './value-normal';
import { ValueJoinedList } from './value-joined-list';
import { ValueUnorderedList } from './value-unordered-list';
import { ValuePre } from './value-pre';

interface ValueProps {
	value?: LabelValueRowValue;
	format: LABEL_VALUE_ROW_FORMAT;
	formatSeparator?: string;
}

export const Value = ({ value, format, formatSeparator }: ValueProps) => {
	switch (format) {
		case LABEL_VALUE_ROW_FORMAT.PRE:
			return <ValuePre value={value} />;
		case LABEL_VALUE_ROW_FORMAT.UNORDERED_LIST:
			return <ValueUnorderedList value={value} />;
		case LABEL_VALUE_ROW_FORMAT.JOINED_LIST:
			return <ValueJoinedList value={value} formatSeparator={formatSeparator} />;
		case LABEL_VALUE_ROW_FORMAT.NORMAL:
		default:
			return <ValueNormal value={value} />;
	}
};
