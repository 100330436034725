import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { Popup } from '@progress/kendo-react-popup';
import { observer } from 'mobx-react-lite';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

import { AdvancedQueryPageKeyPrefix, DATE_TIME_FORMAT_WITH_SECONDS, DateTimePickerOptionKeys } from '@/app/_common/constants';
import { AdvancedQueryChartViewStore, AdvancedQueryViewStore } from '@/app/advanced-query/_common/stores';
import { Button, ButtonSize } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';
import { useOutsideClick } from '@/app/_common/hooks';
import { TimeRangeHistoryFilterOption } from '@/app/_common/types';
import { getTimeRangeString } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/chart/utils';

import styles from './chart-history.module.scss';

export const ChartHistory: FC = observer(() => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const { fetchChartDataWithAutoInterval } = useInstance(AdvancedQueryChartViewStore);
	const { runCustomQuery, timeRangeHistory } = useInstance(AdvancedQueryViewStore);

	const { popupRef, anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		showDropdown,
		useCallback(() => setShowDropdown(false), []),
	);

	const toggleDropdown = () => {
		setShowDropdown((showDropdown) => !showDropdown);
	};

	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });

	const makeQuery = (timeRange: TimeRangeHistoryFilterOption) => (): void => {
		runCustomQuery(timeRange.value, DateTimePickerOptionKeys.CUSTOM, false);
		fetchChartDataWithAutoInterval();
	};

	if (!timeRangeHistory.length) return null;

	return (
		<>
			<div ref={anchorRef}>
				<Button className={styles.dropdownButton} fillMode="flat" size={ButtonSize.Small} onClick={toggleDropdown}>
					<span className={styles.dropdownButtonLabel}>{t('chart.actionBlock.historyLabel')}</span>
					{showDropdown ? <ArrowDropUp /> : <ArrowDropDown />}
				</Button>
			</div>
			<Popup anchor={anchorRef.current} show={showDropdown} popupClass={styles.popup}>
				<div ref={popupRef}>
					{timeRangeHistory
						.map((timeRange: TimeRangeHistoryFilterOption) => {
							const date = getTimeRangeString(timeRange.value, DATE_TIME_FORMAT_WITH_SECONDS);
							return (
								<div className={styles.dateTime} key={date} onClick={makeQuery(timeRange)} onKeyDown={makeQuery(timeRange)}>
									{date}
								</div>
							);
						})
						.reverse()}
				</div>
			</Popup>
		</>
	);
});
