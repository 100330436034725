import { FC, ReactElement, ReactNode } from 'react';

import styles from './note-draft.module.scss';

interface NoteDraftProps {
	title: ReactElement | string;
	subTitle?: ReactElement | string;
	actions?: ReactElement;
	children: ReactNode;
}

export const NoteDraft: FC<NoteDraftProps> = ({ actions, title, subTitle, children }) => {
	return (
		<div className={styles.note}>
			<div className={styles.header}>
				<div className={styles.titles}>
					{title && <span className={styles.title}>{title}</span>}
					{subTitle && <span className={styles.subTitle}>{subTitle}</span>}
				</div>
				{actions && <div className={styles.actions}>{actions}</div>}
			</div>
			<div className={styles.content}>{children}</div>
		</div>
	);
};
