import { action, computed, makeObservable } from 'mobx';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { FetchBaseDataStore } from '@/app/_common/fetch';
import { AdxEndpoints } from '@/app/_common/constants';
import { DataTable, FetchBaseErrorKey, ResultsDataItem, TableColumn } from '@/app/_common/types';
import { formatQueryResults } from '@/app/advanced-query/_common/utils';

const API_URL = process.env.REACT_APP_ADVANCED_QUERY_URL;
const ERROR_KEY = FetchBaseErrorKey.AdvancedQueryResults;

interface State {
	rawColumns: TableColumn[];
}

const INITIAL_STATE: State = {
	rawColumns: [],
};

export class ResultsDataStore extends FetchBaseDataStore<ResultsDataItem[]> {
	private authStore = injectInterface({}, AuthStore);
	private cancelSource?: CancelTokenSource;
	private state: State = INITIAL_STATE;

	constructor() {
		super(ERROR_KEY);

		makeObservable(this, {
			results: computed,
			isDataEmpty: computed,
			rawColumns: computed,
			fetchResults: action,
		});
	}

	get results(): ResultsDataItem[] {
		return this.data ?? [];
	}

	get isDataEmpty(): boolean {
		return this.results.length === 0;
	}

	get rawColumns() {
		return this.state.rawColumns;
	}

	public fetchResults(query: string) {
		if (!query) return;

		this.cancelSource = axios.CancelToken.source();
		const config: AxiosRequestConfig = {
			method: 'POST',
			url: `${API_URL}/${AdxEndpoints.QueryV2}`,
			data: { tenantId: this.authStore.currentTenantId, query },
			cancelToken: this.cancelSource.token,
			transformResponse: [this.formatResults],
			params: { scope: 'results' },
		};

		return this.fetchApiByAxios('', config);
	}

	private formatResults = (response: string): ResultsDataItem[] => {
		try {
			const parsedResponse: DataTable[] = JSON.parse(response);
			const dataTable: DataTable = parsedResponse[0];
			this.state.rawColumns = dataTable.Columns;
			return formatQueryResults<ResultsDataItem>(dataTable, true);
		} catch (error) {
			return [];
		}
	};

	public cancelRequest() {
		this.cancelSource?.cancel();
	}
}
