import { FC, useEffect } from 'react';
import { useSlate } from 'slate-react';
import { useForm, FieldValues } from 'react-hook-form';
import { Dialog } from '@/app/_common/_components/dialog';
import { FormInput } from '@/app/_common/_components/form-components';
import { Button } from '@/app/_common/_components/button';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { RICH_TEXT_EDITOR_POPUP_ID, useLink } from '@/app/_common/_components/rich-text-editor';
import { RichTextEditorTranslations } from '@/app/_common/_components/rich-text-editor/types';

import styles from './add-link-dialog.module.scss';

const DIALOG_WIDTH = 440;

const LINK_VALIDATION_REGEX = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%\-/]))?/g;
const ADD_LINK_PLACEHOLDER = 'https://...';
const ADD_LINK_NAME = 'link';

interface AddLinkDialog {
	open: boolean;
	handleToggleDialog: () => void;
	currentUrl: string;
	translations: RichTextEditorTranslations;
}

export const AddLinkDialog: FC<AddLinkDialog> = ({ open, handleToggleDialog, currentUrl, translations }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();
	const editor = useSlate();

	const { insertLink } = useLink();

	const onSubmit = (data: FieldValues) => {
		insertLink(editor, data.link);
		handleToggleDialog();
	};

	useEffect(() => {
		reset({ link: currentUrl });
	}, [currentUrl, reset]);

	if (!open) {
		return null;
	}

	return (
		<Dialog
			onClose={handleToggleDialog}
			id={RICH_TEXT_EDITOR_POPUP_ID}
			className={styles.addLinkDialog}
			title={translations.addLink}
			width={DIALOG_WIDTH}
			headerDivider={true}
			onSubmit={handleSubmit(onSubmit)}
			footerDivider={true}
			closeIcon={false}
			actions={
				<div className={styles.wrapper}>
					<Button fillMode="flat" onClick={handleToggleDialog}>
						{translations.cancel}
					</Button>
					<Button type="submit" fillMode="flat" onClick={handleSubmit(onSubmit)}>
						{translations.confirm}
					</Button>
				</div>
			}
		>
			<Form
				initialValues={{ link: currentUrl }}
				render={() => (
					<FormElement>
						<fieldset className={styles.fieldset}>
							<div className={styles.field}>
								<Field
									id={ADD_LINK_NAME}
									label={translations.link}
									type="text"
									component={FormInput}
									touched={true}
									placeholder={ADD_LINK_PLACEHOLDER}
									valid={false}
									message={errors?.[ADD_LINK_NAME]?.message}
									{...register(ADD_LINK_NAME, {
										required: false,
										pattern: {
											value: LINK_VALIDATION_REGEX,
											message: translations.errorLink,
										},
									})}
								/>
							</div>
						</fieldset>
					</FormElement>
				)}
			/>
		</Dialog>
	);
};
