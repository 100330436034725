import { FC } from 'react';

import { Button } from '@/app/_common/_components';

import styles from './alerts-grid-reset-filters-button.module.scss';

interface AlertsGridResetFiltersButtonProps {
	text: string;
	disabled: boolean;
}

export const AlertsGridResetFiltersButton: FC<AlertsGridResetFiltersButtonProps> = ({ text, disabled }) => {
	return (
		<Button className={styles.alertsGridResetFiltersButton} disabled={disabled}>
			{text}
		</Button>
	);
};
