import { FC } from 'react';
import classNames from 'classnames';

import '@/assets/styles/index.scss';
import styles from './user-tile.module.scss';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '@/translations/namespaces';

export const UserPreferences: FC = () => {
	const { t } = useTranslation([Namespaces.NavbarMenu]);

	return <div className={classNames(styles.link, styles.paddedItem, styles.disabled)}>{t('userPreferences')}</div>;
};
