import { action, makeObservable } from 'mobx';

import { AssignAlertsToInvestigation } from '@/app/_common/graphql/queries';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { Mutation, MutationSetAlertsOnInvestigationArgs } from '@/generated/graphql';

export class UnassignAlertDataStore extends GraphqlBaseDataStore<Mutation, MutationSetAlertsOnInvestigationArgs> {
	constructor() {
		super();
		makeObservable(this, {
			unassign: action,
		});
	}

	async unassign(args: Omit<MutationSetAlertsOnInvestigationArgs, 'tenantId'>) {
		const variables = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationSetAlertsOnInvestigationArgs>({
			mutation: AssignAlertsToInvestigation,
			variables,
		});
		return response;
	}
}
