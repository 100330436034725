import { observer } from 'mobx-react-lite';
import { provider, toFactory } from 'react-ioc';
import { memo, useMemo } from 'react';

import {
	ActionHistoryDataGridDataStore,
	ActionHistoryDataGridViewStore,
	ActionHistoryTimeRangeFilterStore,
	ActionHistorySystemActionsSwitchStore,
} from './_common/stores';
import { ActionHistoryPage } from './_components';

interface ActionHistoryModuleProps {
	isFranchise: boolean;
}

const ActionHistoryModule = memo(({ isFranchise = false }: ActionHistoryModuleProps) => {
	const storeProvider = useMemo(
		() =>
			provider(
				[ActionHistoryDataGridDataStore, toFactory(() => new ActionHistoryDataGridDataStore(isFranchise))],
				[ActionHistoryDataGridViewStore, toFactory(() => new ActionHistoryDataGridViewStore())],
				[ActionHistoryTimeRangeFilterStore, toFactory(() => new ActionHistoryTimeRangeFilterStore())],
				[ActionHistorySystemActionsSwitchStore, toFactory(() => new ActionHistorySystemActionsSwitchStore())],
			),
		[isFranchise],
	);
	const Component = storeProvider(observer(() => <ActionHistoryPage isFranchise={isFranchise} />));
	return <Component />;
});

ActionHistoryModule.displayName = 'ActionHistoryModule';

export default ActionHistoryModule;
