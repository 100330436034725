import { FC } from 'react';

import Fullscreen from '@mui/icons-material/Fullscreen';

import styles from './open-in-modal-button.module.scss';
import classNames from 'classnames';

interface OpenInModalButtonProps {
	onClick: () => void;
	className?: string;
}

export const OpenInModalButton: FC<OpenInModalButtonProps> = ({ onClick, className = '' }) => {
	return (
		<button className={classNames(styles.button, className)} onClick={onClick}>
			<Fullscreen className={styles.icon} />
		</button>
	);
};
