import { gql } from '@apollo/client';
import { FranchiseUserFragment, UserFragment } from '@/app/_common/graphql/fragments.constants';

export const ListUsers = gql`
	query ListUsers($tenantId: ID!, $input: Paging, $filtersInput: FiltersInput) {
		listUsers(tenantId: $tenantId, input: $input, filtersInput: $filtersInput) {
			edges {
				node {
					...UserFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${UserFragment}
`;

export const ListFranchiseUsers = gql`
	query ListFranchiseUsers($franchiseId: ID!, $input: Paging, $filtersInput: FiltersInput) {
		listFranchiseUsers(franchiseId: $franchiseId, input: $input, filtersInput: $filtersInput) {
			edges {
				node {
					...FranchiseUserFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${FranchiseUserFragment}
`;

export const RemoveUser = gql`
	mutation RemoveUser($tenantId: ID!, $userId: ID!) {
		removeUser(tenantId: $tenantId, userId: $userId) {
			... on Error {
				message
				code
				correlationId
			}
			... on User {
				id
				upn
				displayName
			}
		}
	}
`;

export const ResendInvitation = gql`
	mutation ResendInvitation($tenantId: ID!, $email: String!) {
		resendInvitation(tenantId: $tenantId, email: $email) {
			... on User {
				id
				displayName
				firstName
				lastName
				upn
				lastLogin
				inviteExpirationTimestamp
				tenants {
					id
					invitedBy
					invitedTimestamp
					registrationTimestamp
					isBillingUser
				}
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const CancelInvitation = gql`
	mutation CancelInvitation($tenantId: ID!, $userId: String!) {
		cancelInvitation(tenantId: $tenantId, userId: $userId) {
			... on Error {
				message
				code
				correlationId
			}
			... on User {
				id
				upn
			}
		}
	}
`;
