import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { detailsViewTypenameToIdKeyMapping } from '@/app/_common/_components/details-view/constants/details-view-typename-to-id-key-mapping';

export const getDetailsViewItemIdKeyByTypename = (typename: DetailsViewTypename): string => {
	if (!typename) {
		return '';
	}

	return detailsViewTypenameToIdKeyMapping[typename] || detailsViewTypenameToIdKeyMapping[DetailsViewTypename.UNKNOWN];
};
