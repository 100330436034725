import { makeAutoObservable } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';

import {
	ADX_ALERTS_DEFAULT_FAVORITE_FIELDS,
	ADX_EVENTS_DEFAULT_FAVORITE_FIELDS,
	ADX_EVIDENCE_DEFAULT_FAVORITE_FIELDS,
} from '@/app/_common/constants';
import { AVAILABLE_FIELDS_BLOCK_NAME } from '@/app/_common/types';
import { migrateInOrder } from '@/app/_common/utils/storage-migration/migrate-in-order';

import { USER_PREFERENCE_PERSISTABLE_STATE_KEY, USER_PREFERENCE_PERSISTABLE_STATE_VERSION } from './user-preference.constants';
import { migrateV0ToV1 } from './migrations';

const EVENTS_KEY = AVAILABLE_FIELDS_BLOCK_NAME.EVENTS;
const ALERTS_KEY = AVAILABLE_FIELDS_BLOCK_NAME.ALERTS;
const EVIDENCE_KEY = AVAILABLE_FIELDS_BLOCK_NAME.EVIDENCE;

const INITIAL_STATE: State = {
	version: USER_PREFERENCE_PERSISTABLE_STATE_VERSION,
	favoriteFields: {
		[EVENTS_KEY]: ADX_EVENTS_DEFAULT_FAVORITE_FIELDS,
		[ALERTS_KEY]: ADX_ALERTS_DEFAULT_FAVORITE_FIELDS,
		[EVIDENCE_KEY]: ADX_EVIDENCE_DEFAULT_FAVORITE_FIELDS,
	},
};

interface State {
	favoriteFields: Record<AVAILABLE_FIELDS_BLOCK_NAME, string[]>;
	version: `v${number}`;
}

const setDefaultPersistedValues = (fieldName: AVAILABLE_FIELDS_BLOCK_NAME, defaultValues: string[]) => {
	const userFavoriteFields = window.localStorage.getItem(USER_PREFERENCE_PERSISTABLE_STATE_KEY);

	if (userFavoriteFields) {
		const parsedUserFavoriteFields = JSON.parse(userFavoriteFields);
		if (!parsedUserFavoriteFields.favoriteFields[fieldName]?.length) {
			parsedUserFavoriteFields.favoriteFields[fieldName] = defaultValues;
		}

		window.localStorage.setItem(USER_PREFERENCE_PERSISTABLE_STATE_KEY, JSON.stringify(parsedUserFavoriteFields));
	}
};

export class UserPreferenceStore {
	private state: State = INITIAL_STATE;

	constructor() {
		makeAutoObservable(this);
		migrateInOrder({
			key: USER_PREFERENCE_PERSISTABLE_STATE_KEY,
			latestVersion: USER_PREFERENCE_PERSISTABLE_STATE_VERSION,
			migrationScripts: {
				v0: migrateV0ToV1,
			},
		});

		setDefaultPersistedValues(AVAILABLE_FIELDS_BLOCK_NAME.EVIDENCE, ADX_EVIDENCE_DEFAULT_FAVORITE_FIELDS);
		setDefaultPersistedValues(AVAILABLE_FIELDS_BLOCK_NAME.ALERTS, ADX_ALERTS_DEFAULT_FAVORITE_FIELDS);
		setDefaultPersistedValues(AVAILABLE_FIELDS_BLOCK_NAME.EVENTS, ADX_EVENTS_DEFAULT_FAVORITE_FIELDS);

		makePersistable(this.state, {
			name: USER_PREFERENCE_PERSISTABLE_STATE_KEY,
			properties: ['favoriteFields', 'version'],
			storage: window.localStorage,
		});
	}

	get favoriteFields() {
		return {
			[EVENTS_KEY]: this.state.favoriteFields[EVENTS_KEY],
			[ALERTS_KEY]: this.state.favoriteFields[ALERTS_KEY],
			[EVIDENCE_KEY]: this.state.favoriteFields[EVIDENCE_KEY],
		};
	}

	toggleFavoriteField = (blockName: AVAILABLE_FIELDS_BLOCK_NAME, field: string) => {
		const favoriteFields = this.state.favoriteFields[blockName];
		if (favoriteFields.includes(field)) {
			this.state.favoriteFields[blockName] = favoriteFields.filter((value: string) => value !== field);
		} else {
			this.state.favoriteFields[blockName] = [...favoriteFields, field];
		}
	};

	dispose() {
		stopPersisting(this.state);
	}
}
