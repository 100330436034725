import { MonacoInlineCodeTheme, MonacoInlineCodeThemeClassName } from '@/app/_common/_components/monaco-inline-code/_common';

export const determineClassNameUponTheme = (theme: MonacoInlineCodeTheme): MonacoInlineCodeThemeClassName => {
	switch (theme) {
		case MonacoInlineCodeTheme.Light:
			return MonacoInlineCodeThemeClassName.Light;
		case MonacoInlineCodeTheme.Dark:
			return MonacoInlineCodeThemeClassName.Dark;
	}
};
