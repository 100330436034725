import { useEffect, useCallback, useRef } from 'react';

export function useOutsideClick<T extends HTMLElement, P extends HTMLElement>(
	isOpen: boolean,
	callback: (event: MouseEvent) => void,
	isNestedPopupOpen?: boolean,
	nestedCallback?: (event: MouseEvent) => void,
) {
	const anchorRef = useRef<T>(null);
	const popupRef = useRef<P>(null);
	const nestedPopupRef = useRef<P>(null);

	const handleOutsideClick = useCallback(
		(event: MouseEvent) => {
			const clickedAnchor = anchorRef?.current?.contains(event.target as Node);
			const clickedPopup = popupRef?.current?.contains(event.target as Node);

			if (!clickedAnchor && !clickedPopup && isOpen) {
				callback(event);
			}
		},
		[isOpen, callback],
	);

	const handleOutsideNestedPopupClick = useCallback(
		(event: MouseEvent) => {
			const clickedPopup = popupRef?.current?.contains(event.target as Node);
			const clickedNestedPopup = nestedPopupRef?.current?.contains(event.target as Node);

			if (!clickedPopup && !clickedNestedPopup && isNestedPopupOpen) {
				nestedCallback?.(event);
			}
		},
		[isNestedPopupOpen, nestedCallback],
	);

	useEffect(() => {
		isOpen && document.addEventListener('mousedown', handleOutsideClick, true);
		isNestedPopupOpen && nestedCallback && document.addEventListener('mousedown', handleOutsideNestedPopupClick, true);
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick, true);
			nestedCallback && document.removeEventListener('mousedown', handleOutsideNestedPopupClick, true);
		};
	}, [handleOutsideClick, handleOutsideNestedPopupClick, isNestedPopupOpen, isOpen, nestedCallback]);

	return { anchorRef, popupRef, nestedPopupRef };
}
