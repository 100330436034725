import classNames from 'classnames';

import styles from './assign-query-results-to-investigation-dialog-content-block.module.scss';

interface AssignQueryResultsToInvestigationDialogContentBlockProps {
	children: JSX.Element;
	title?: string;
	className?: string;
	'data-testid'?: string;
}

export const AssignQueryResultsToInvestigationDialogContentBlock = ({
	children,
	title,
	className = '',
	'data-testid': dataTestId,
}: AssignQueryResultsToInvestigationDialogContentBlockProps) => {
	return (
		<div className={classNames(styles.container, className)} data-testid={dataTestId}>
			{title && (
				<div className={styles.title} data-testid="title">
					{title}
				</div>
			)}
			{children}
		</div>
	);
};
