import { ThreatIntelligenceDescription } from '@/app/_common/types';

export const getHitsDescriptions = (tags: string[], availableDescriptions: ThreatIntelligenceDescription[]): ThreatIntelligenceDescription[] => {
	if (!Array.isArray(tags) || !Array.isArray(availableDescriptions) || !tags.length || !availableDescriptions.length) {
		return [];
	}
	const descriptions: ThreatIntelligenceDescription[] = [];

	tags.forEach((tag) => {
		for (const description of availableDescriptions) {
			if (tag === description.title) {
				descriptions.push(description);
			}
		}
	});

	return descriptions;
};
