import { Checkbox as KendoCheckbox, CheckboxProps as KendoCheckboxProps } from '@progress/kendo-react-inputs';
import classNames from 'classnames';
import { FC } from 'react';

import styles from './checkbox.module.scss';

interface CheckboxProps extends KendoCheckboxProps {
	className?: string;
}

export const Checkbox: FC<CheckboxProps> = ({ className, ...props }) => {
	return <KendoCheckbox className={classNames(styles.checkbox, className)} {...props} />;
};
