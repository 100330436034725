const LOG_OUT_PARAM = '&logOut=true';

export const getEncodedLogoutRedirectURL = (initialLogOut?: boolean): string => {
	// we add logout param to the login URL to show the information about logout on the login page
	const loginUrl = `${process.env.REACT_APP_LOGIN_URL}${initialLogOut ? '' : LOG_OUT_PARAM}`;
	const redirectPlaceholderUrl = process.env.REACT_APP_REDIRECT_PLACEHOLDER as string;
	const logoutUrl = process.env.REACT_APP_LOGOUT_URL as string;

	return logoutUrl.replace(redirectPlaceholderUrl, encodeURIComponent(loginUrl));
};
