import { FC } from 'react';

import _get from 'lodash/get';
import { GridCellProps } from '@progress/kendo-react-grid';

import styles from './close-reason-cell.module.scss';
import { CloseReason } from '@/generated/graphql';

import { DataCell } from '@/app/_common/_components/data-grid/_components';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

export const getCloseReasonTranslation = (reason?: CloseReason): string => {
	switch (reason) {
		case CloseReason.SecurityIncidentConfirmed:
			return i18n.t('investigationStatusDialog.closeStatus.closePrioritySecurity', { ns: Namespaces.InvestigationDetails });
		case CloseReason.FalsePositive:
			return i18n.t('investigationStatusDialog.closeStatus.closePriorityFalse', { ns: Namespaces.InvestigationDetails });
		case CloseReason.TruePositive:
			return i18n.t('investigationStatusDialog.closeStatus.closePriorityTrue', { ns: Namespaces.InvestigationDetails });
		default:
			return '';
	}
};

export const CloseReasonCell: FC<GridCellProps> = ({ field, dataItem, ...rest }) => {
	const value = _get(dataItem, field || '');

	if (value) {
		return (
			<DataCell {...rest} dataItem={dataItem} field={field} title={getCloseReasonTranslation(value)} className={styles.closeReasonCell}>
				{getCloseReasonTranslation(value)}
			</DataCell>
		);
	}

	return <DataCell {...rest} dataItem={dataItem} field={field} empty={true} />;
};
