import { makeAutoObservable, reaction } from 'mobx';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { AppConfigurationDataStore } from './app-configuration.data-store';
import { FeaturesFlag } from '@/app/_common/interfaces';
import { clearPersistedStore, makePersistable, stopPersisting } from 'mobx-persist-store';

const APP_CONFIGURATION_STATE_KEY = 'AppConfiguration';

interface State {
	restrictedFeatures: {
		[key: string]: FeaturesFlag[];
	};
}

const INITIAL_APP_CONFIGURATION: State = {
	restrictedFeatures: {},
};

export class AppConfigurationViewStore {
	private authStore = injectInterface(this, AuthStore);
	private appConfigurationDataStore = injectInterface(this, AppConfigurationDataStore);

	private state: State = INITIAL_APP_CONFIGURATION;

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		makePersistable(this.state, {
			name: APP_CONFIGURATION_STATE_KEY,
			properties: ['restrictedFeatures'],
			storage: window.sessionStorage,
		});

		this.setCurrentFlagsFromStorage();
	}

	get userLogout() {
		return this.authStore.logOut;
	}

	get currentTenantId() {
		return this.authStore.currentTenantId;
	}

	get restrictedFeaturesFromState() {
		const currentTenantId = this.currentTenantId;
		return this.state.restrictedFeatures[currentTenantId];
	}

	get hasRestrictedFeaturesSavedInState() {
		const currentTenantId = this.currentTenantId;
		return Boolean(this.state.restrictedFeatures[currentTenantId]);
	}

	get restrictedFeaturesFromDataStore() {
		return this.appConfigurationDataStore.restrictedFeatures;
	}

	setCurrentFlagsFromStorage() {
		try {
			const sessionStore = window.sessionStorage.getItem(APP_CONFIGURATION_STATE_KEY);
			if (sessionStore) {
				const parseJSON = JSON.parse(sessionStore);
				this.state.restrictedFeatures = Object.assign(this.state.restrictedFeatures, parseJSON.restrictedFeatures);
			}
		} catch (error) {
			// in case of error, we just ignore it and continue using the default values or data from the query
		}
	}

	readAppConfigurationData(currentTenantId: string) {
		if (Boolean(currentTenantId) && !this.hasRestrictedFeaturesSavedInState) {
			this.appConfigurationDataStore.read(currentTenantId);
		}
	}

	isFeatureRestricted(feature: FeaturesFlag) {
		if (this.hasRestrictedFeaturesSavedInState) {
			return this.restrictedFeaturesFromState.includes(feature);
		} else {
			return this.restrictedFeaturesFromDataStore.includes(feature);
		}
	}

	userHasAccess(feature?: FeaturesFlag): boolean {
		return !feature || !this.isFeatureRestricted(feature);
	}

	getAppConfigurationDisposer = reaction(
		() => this.currentTenantId,
		(currentTenantId) => {
			this.readAppConfigurationData(currentTenantId);
		},
	);
	getAppConfigurationDataDisposer = reaction(
		() => this.restrictedFeaturesFromDataStore,
		(restrictedFeaturesFromDataStore) => {
			const currentTenantId = this.currentTenantId;
			this.state.restrictedFeatures[currentTenantId] = restrictedFeaturesFromDataStore;
		},
	);

	getAppConfigurationLogoutDisposer = reaction(
		() => this.userLogout,
		(userLogout) => {
			if (userLogout) {
				clearPersistedStore(this.state);
				stopPersisting(this.state);
			}
		},
	);

	dispose = () => {
		this.getAppConfigurationDisposer();
		this.getAppConfigurationDataDisposer();
		this.getAppConfigurationLogoutDisposer();
	};
}
