export enum MitreTacticsValue {
	RECONNAISSANCE = 'Reconnaissance',
	RESOURCE_DEVELOPMENT = 'Resource.Development',
	INITIAL_ACCESS = 'Initial.Access',
	EXECUTION = 'Execution',
	PERSISTENCE = 'Persistence',
	PRIVILEGE_ESCALATION = 'Privilege.Escalation',
	DEFENSE_EVASION = 'Defense.Evasion',
	CREDENTIAL_ACCESS = 'Credential.Access',
	DISCOVERY = 'Discovery',
	LATERAL_MOVEMENT = 'Lateral.Movement',
	COLLECTION = 'Collection',
	COMMAND_AND_CONTROL = 'Command.and.Control',
	EXFILTRATION = 'Exfiltration',
	IMPACT = 'Impact',
	PREATTACK = 'Technical Information Gathering (Pre-ATT&CK)',
	UNKNOWN = 'Unknown',
}
