import { computed, makeObservable } from 'mobx';

import { FetchBaseDataStore } from '@/app/_common/fetch/fetch-base.data-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { DataTable, ResultsDataItem } from '@/app/_common/types';
import { formatQueryResults } from '@/app/advanced-query/_common/utils';

const CONTAINER_NAME = 'queryresults';

export class QueryResultResultsTableDataStore extends FetchBaseDataStore<ResultsDataItem[]> {
	private authStore = injectInterface(this, AuthStore);

	constructor(private resourceId: string) {
		super();

		makeObservable(this, {
			results: computed,
		});

		this.read(this.resourceId);
	}

	get results() {
		return this.data ?? [];
	}

	read(resourceId?: string) {
		this.clear();

		const endpoint = process.env.REACT_APP_FILE_ENDPOINT;

		if (!(resourceId && endpoint)) {
			return;
		}

		const key = `${this.authStore.currentTenantId}/${resourceId}/queryresult.json`;
		this.fetchApiByAxios(endpoint, { params: { containerName: CONTAINER_NAME, key }, transformResponse: [this.formatResults] });
	}

	private formatResults = (response: string): ResultsDataItem[] => {
		try {
			const parsedResponse: DataTable[] = JSON.parse(response);
			const dataTable: DataTable = parsedResponse[0];
			return formatQueryResults<ResultsDataItem>(dataTable, true);
		} catch (error) {
			return [];
		}
	};
}
