import { AlertsHeaderFilter } from '@/app/dashboards/alerts-dashboard/_common/constants';

export function isPaginationEnabled<T>(
	data: T[],
	headerFilterValues: AlertsHeaderFilter[],
	count: number,
	anyTableFilterEnabled: boolean,
	hasNextPage: boolean,
) {
	const isThereMoreData = data.length < count;
	const isOnlySelectedFilterEnabled = headerFilterValues.includes(AlertsHeaderFilter.Selected) && headerFilterValues.length === 1;

	return !anyTableFilterEnabled && hasNextPage && isThereMoreData && !isOnlySelectedFilterEnabled;
}
