import { Tooltip, OpenInModalButton } from '@/app/_common/_components';

interface MaximizeIconProps {
	tooltipId: string;
	className?: string;
	tooltipContent: string;
	onClick: () => void;
}

export const MaximizeIcon = ({ tooltipId, tooltipContent, className, onClick }: MaximizeIconProps) => {
	return (
		<>
			<span data-tip={tooltipId} data-for={tooltipId}>
				<OpenInModalButton onClick={onClick} className={className} />
			</span>
			<Tooltip tooltipId={tooltipId} tooltipContent={tooltipContent} />
		</>
	);
};
