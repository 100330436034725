import { ReactNode } from 'react';

import styles from './context-menu-label.module.scss';

interface ContextMenuLabelProps {
	prefixIcon?: ReactNode;
	children: ReactNode;
}

export const ContextMenuLabel = ({ prefixIcon = null, children }: ContextMenuLabelProps) => {
	return (
		<div className={styles.label}>
			{prefixIcon}
			{children}
		</div>
	);
};
