import { KeyboardEvent, MouseEvent, useCallback } from 'react';

import { Divider } from '@/app/_common/_components';
import { ArrayFilterOperators } from '@/app/_common/constants';
import { FilterDescriptorWithId, FilterOption, FilterValue, Filters } from '@/app/_common/types';
import { OptionGroupsList, DataHeaderCellFilterWrapper } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter';
import { DataHeaderCellLoader } from '@/app/_common/_components/data-grid/_components/data-header-cell/_components';
import { DataHeaderCellFilterReset } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/data-header-cell-filter-reset';
import { SelectMode } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/constants';

import styles from './data-header-cell-filter.module.scss';

interface DataHeaderCellFilterProps {
	filter: Filters | FilterDescriptorWithId[];
	optionGroups: FilterOption[][];
	renderFilterSubgroups: JSX.Element[];
	areSubgroupsVisible: boolean;
	selectMode?: SelectMode;
	loading?: boolean;
	options?: FilterOption[];
	onClearFilter?: () => void;
	onFilterChange?: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, values: FilterValue[]) => void;
	onButtonClick?: (value: FilterValue, operator: ArrayFilterOperators) => void;
	closePopup?: () => void;
}

export const DataHeaderCellFilter = ({
	onClearFilter,
	filter,
	loading,
	options,
	optionGroups,
	onFilterChange,
	selectMode,
	onButtonClick,
	renderFilterSubgroups,
	areSubgroupsVisible,
	closePopup,
}: DataHeaderCellFilterProps) => {
	const handleFilterChange = useCallback(
		(event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, values: FilterValue[]) => {
			if (typeof onFilterChange === 'function') {
				onFilterChange(event, values);
			}

			if (typeof closePopup === 'function') {
				closePopup();
			}
		},
		[onFilterChange, closePopup],
	);

	const handleClearFilterClick = () => {
		if (typeof onClearFilter === 'function') {
			onClearFilter();
		}

		if (typeof closePopup === 'function') {
			closePopup();
		}
	};

	const isOptionGroupsListVisible = !loading && Boolean(options?.length);
	const showGroups = isOptionGroupsListVisible || areSubgroupsVisible;

	return (
		<DataHeaderCellFilterWrapper>
			<DataHeaderCellFilterReset onClick={handleClearFilterClick} disabled={!filter.length} />
			<Divider />
			{loading && <DataHeaderCellLoader />}
			{showGroups && (
				<div className={styles.groupsContainer}>
					{isOptionGroupsListVisible && (
						<OptionGroupsList
							optionGroups={optionGroups}
							onFilterChange={handleFilterChange}
							filter={filter}
							selectMode={selectMode}
							onButtonClick={onButtonClick}
						/>
					)}
					{areSubgroupsVisible && renderFilterSubgroups}
				</div>
			)}
		</DataHeaderCellFilterWrapper>
	);
};
