import { SeriesPoint, BarGroupBar } from '@visx/shape/lib/types';

export enum ChartStackBarKeys {
	EventsCount = 'eventsCount',
	Date = 'timestamp',
	AlertsCount = 'alertsCount',
	TotalCount = 'totalCount',
	EvidenceCount = 'evidenceCount',
}

export interface ChartDataItem {
	[ChartStackBarKeys.EventsCount]: number;
	[ChartStackBarKeys.Date]: string;
}

export interface StackedChartDataItem extends ChartDataItem {
	[ChartStackBarKeys.AlertsCount]: number;
	[ChartStackBarKeys.TotalCount]: number;
	[ChartStackBarKeys.EvidenceCount]: number;
}

export type ChartData = ChartDataItem[] | StackedChartDataItem[];

export interface ChartBarData {
	bar: SeriesPoint<ChartDataItem>;
	key: ChartStackBarKeys;
	index: number;
	height: number;
	width: number;
	x: number;
	y: number;
	color: string;
}

export enum ChartBarsNumber {
	Default = 'default',
}

export type TooltipData = Omit<BarGroupBar<ChartStackBarKeys>, 'key' | 'value'> & {
	bar: SeriesPoint<ChartDataItem | StackedChartDataItem>;
	key: ChartStackBarKeys;
};

export interface ShowTooltipData {
	tooltipData: TooltipData;
	tooltipTop: number;
	tooltipLeft: number;
}

export enum AutoIntervalsKeys {
	ONE_SECOND,
	FIFTEEN_SECONDS,
	THIRTY_SECONDS,
	ONE_MINUTE,
	TEN_MINUTES,
	THIRTY_MINUTES,
	THREE_HOURS,
	TWELEVE_HOURS,
	ONE_DAY,
}

export enum AutoIntervalsBreakpointsKeys {
	FIVE_MINUTES,
	TWENTY_MINUTES,
	FORTY_FIVE_MINUTES,
	FOUR_HOURS,
	SIXTEEN_HOURS,
	THREE_DAYS,
	FOURTEEN_DAYS,
	SIXTY_DAYS,
	INFINITY,
}

export enum IntervalsKeys {
	MILLISECOND,
	SECOND,
	MINUTE,
	HOUR,
	DAY,
	WEEK,
	MONTH,
}
