import { FC } from 'react';
import { SvgIcon } from '@mui/material';

import { ReactComponent as CogConnectSvg } from '@/assets/images/cog-connect-icon.svg';

interface CogConnectIconProps {
	className?: string;
}

export const CogConnectIcon: FC<CogConnectIconProps> = ({ className = '' }) => {
	return (
		<SvgIcon viewBox="0 0 20 16" className={className}>
			<CogConnectSvg />
		</SvgIcon>
	);
};
