/*
  IMPORTANT: Use in component wrapped with observer!
*/

import { useMemo } from 'react';
import { useInstance } from 'react-ioc';

import { AlertsActionsViewStore } from '@/app/_features/alerts-actions/_common';

export const useAlertsActions = () => {
	const { assignAlerts, unassignAlerts, dismissAlerts, undismissAlerts } = useInstance(AlertsActionsViewStore);

	const actions = useMemo(
		() => ({
			assignAlerts,
			unassignAlerts,
			dismissAlerts,
			undismissAlerts,
		}),
		[assignAlerts, unassignAlerts, dismissAlerts, undismissAlerts],
	);

	return actions;
};
