import { FC } from 'react';

import { ColorIndicator } from '@/app/_common/_components';

import { OptionItemDetails } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/types';

import styles from './option-group-item-color-indicator.module.scss';

interface OptionGroupItemColorIndicatorProps {
	config?: OptionItemDetails['bulletsConfig'];
}

export const OptionGroupItemColorIndicator: FC<OptionGroupItemColorIndicatorProps> = ({ config }) => {
	if (!config) {
		return null;
	}

	return (
		<div className={styles.optionGroupItemColorIndicator}>
			<ColorIndicator bulletsConfig={config} />
		</div>
	);
};
