import { CUSTOM_OPTION_KEY, EMPTY_CUSTOM_OPTION_VALUE, InvestigationSleepTimePeriodOptionKeys } from '@/app/_common/constants';
import { CloseReason } from '@/generated/graphql';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';
import moment from 'moment';

enum InvestigationPriority {
	Critical = 'Critical',
	High = 'High',
	Medium = 'Medium',
	Low = 'Low',
}

export enum InvestigationType {
	Alert = 'Alert Investigation',
	Incident = 'Incident Response',
	Threat = 'Threat Hunting',
}

export const getInvestigationPriorityOptions = Object.keys(InvestigationPriority).map(
	(key) => InvestigationPriority[key as keyof typeof InvestigationPriority],
);

export const getInvestigationTypeOptions = Object.keys(InvestigationType).map((key) => ({
	value: key,
	label: InvestigationType[key as keyof typeof InvestigationType],
}));

export const getInvestigationCloseTypeOptions = Object.keys(CloseReason).map((key) => ({
	value: key,
	label: InvestigationType[key as keyof typeof InvestigationType],
}));

export interface SleepPeriodOption {
	value: string;
	label: string;
	key: string;
}

export const getSleepPeriods = (): SleepPeriodOption[] => {
	return [
		{
			value: EMPTY_CUSTOM_OPTION_VALUE,
			label: i18n.t('investigationStatusDialog.closeStatus.closePeriodCustom', { ns: Namespaces.InvestigationDetails }),
			key: CUSTOM_OPTION_KEY,
		},
		{
			value: moment.utc().add(7, 'day').format(),
			label: i18n.t('investigationStatusDialog.closeStatus.closePeriodOneWeek', { ns: Namespaces.InvestigationDetails }),
			key: InvestigationSleepTimePeriodOptionKeys.SEVEN_DAYS,
		},
		{
			value: moment.utc().add(1, 'M').format(),
			label: i18n.t('investigationStatusDialog.closeStatus.closePeriodOneMonth', { ns: Namespaces.InvestigationDetails }),
			key: InvestigationSleepTimePeriodOptionKeys.ONE_MONTH,
		},
		{
			value: moment.utc().add(3, 'M').format(),
			label: i18n.t('investigationStatusDialog.closeStatus.closePeriodThreeMonth', { ns: Namespaces.InvestigationDetails }),
			key: InvestigationSleepTimePeriodOptionKeys.THREE_MONTHS,
		},
		{
			value: moment.utc().add(99, 'y').format(),
			label: i18n.t('investigationStatusDialog.closeStatus.closePeriodIndefinitely', { ns: Namespaces.InvestigationDetails }),
			key: InvestigationSleepTimePeriodOptionKeys.INDEFINITELY,
		},
	];
};
