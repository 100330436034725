import { RICH_TEXT_EDITOR_LS } from '@/app/_common/_components/rich-text-editor';

export const getInitialValue = () => {
	const savedData = localStorage.getItem(RICH_TEXT_EDITOR_LS);

	if (savedData) {
		return JSON.parse(savedData);
	}

	return [
		{
			type: 'paragraph',
			children: [{ text: '' }],
		},
	];
};
