import { action, computed, makeObservable } from 'mobx';

import { Alert, AlertEdge, Query, ValueOperator } from '@/generated/graphql';
import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { ListAlertsStateByIds } from '@/app/_common/graphql/queries';

type ListAlerts = Pick<Query, 'listAlerts'>;

type QueryVariables = {
	tenantId: string;
};

export class AssignQueryResultsToInvestigationDialogAlertsDataStore extends GraphqlBaseDataStore<ListAlerts, QueryVariables> {
	constructor() {
		super();
		makeObservable(this, {
			alerts: computed,
			read: action,
		});
	}

	get alerts(): Alert[] {
		return (this.data?.listAlerts?.edges || []).map((item: AlertEdge) => item.node);
	}

	read(ids?: string[]) {
		if (!ids?.length) {
			return;
		}

		const query = ListAlertsStateByIds;
		const variables = {
			tenantId: this.authStore.currentTenantId,
			filtersInput: {
				valueFilters: [
					{
						field: 'id',
						operator: ValueOperator.IsIn,
						values: ids,
					},
				],
			},
		};

		this.query({
			query: query,
			variables,
			fetchPolicy: 'no-cache',
			nextFetchPolicy: 'cache-first',
		});
	}
}
