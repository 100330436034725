import { action, computed, makeObservable } from 'mobx';
import { ValueOperator } from '@/generated/graphql';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { ListAlertsByIds } from '@/app/_common/graphql/queries';

type QueryVariables = {
	tenantId: string;
};

export class AlertsDataStore<QUERY_RESULT> extends GraphqlBaseDataStore<QUERY_RESULT, QueryVariables> {
	constructor() {
		super();
		makeObservable(this, {
			alerts: computed,
			read: action,
		});
	}

	get alerts() {
		return this.data || [];
	}

	read(ids?: string[]) {
		if (!ids || ids.length === 0) {
			return;
		}

		const query = ListAlertsByIds;
		const variables = {
			tenantId: this.authStore.currentTenantId,
			filtersInput: {
				valueFilters: [
					{
						field: 'id',
						operator: ValueOperator.IsIn,
						values: ids,
					},
				],
			},
		};

		this.query({
			query: query,
			variables,
			fetchPolicy: 'no-cache',
			nextFetchPolicy: 'cache-first',
		});
	}
}
