import { Namespaces } from '@/translations/namespaces';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { editor } from 'monaco-editor';
import { useInstance } from 'react-ioc';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { MyLocation } from '@mui/icons-material';
import { RootPaths } from '@/app/_common/navigation';
import { CodeEditor, CodeEditorAPI, setKustoSchema } from '@/app/_common/_components';
import { AdxSchemaViewStore, AuthStore, UrlStore } from '@/app/_common/stores';
import { URL_STATE_QUERY } from '@/app/_common/constants';

import styles from './query-result-query.module.scss';

export interface QueryResultQueryProps {
	query: string;
	showTitle?: boolean;
	maxHeight?: number;
}

const customMonacoOptions: Partial<editor.IStandaloneEditorConstructionOptions> = {
	overviewRulerLanes: 0,
	padding: {
		top: 8,
		bottom: 8,
	},
	renderLineHighlight: 'none',
};

export const QueryResultQuery = observer(({ query, showTitle = false, maxHeight }: QueryResultQueryProps) => {
	const { search } = useLocation();
	const { t } = useTranslation([Namespaces.QueryResults], { keyPrefix: 'query' });
	const { updateStore } = useInstance(UrlStore);
	const { schema } = useInstance(AdxSchemaViewStore);
	const { currentTenantId } = useInstance(AuthStore);
	const [editor, setEditor] = useState<CodeEditorAPI>();

	const handleClick = useCallback(() => {
		updateStore({ [URL_STATE_QUERY]: query });
	}, [query, updateStore]);

	const editorsHeight = useMemo(() => {
		const linesCount = query.split(/\r\n|\r|\n/).length;
		const marginTopBottom = 18;
		const lineHeight = 19;
		const height = linesCount * lineHeight + marginTopBottom;
		if (maxHeight) {
			return maxHeight > height ? height : maxHeight;
		}
		return height;
	}, [query, maxHeight]);

	useEffect(() => {
		if (editor) {
			setKustoSchema(editor, schema, currentTenantId);
		}
	}, [editor, schema, currentTenantId]);

	const handleEditorMount = useCallback((editor: CodeEditorAPI) => {
		setEditor(editor);
	}, []);

	return (
		<div className={styles.queryContainer}>
			<div className={styles.queryTopRow}>
				{showTitle && (
					<>
						<p>{t('name')}</p>
						<div className={styles.linkContainer}>
							<Link onClick={handleClick} className={styles.link} to={{ pathname: RootPaths.ADVANCED_QUERY, search }}>
								<span className={styles.stateInfo}>{t('openInAdx')}</span>
								<MyLocation />
							</Link>
						</div>
					</>
				)}
			</div>

			<div className={styles.codeEditor} data-testid="query-editor">
				<CodeEditor
					onMount={handleEditorMount}
					readOnly={true}
					defaultValue={query}
					height={`${editorsHeight}px`}
					customOptions={customMonacoOptions}
				/>
			</div>
		</div>
	);
});
