import { ItemRenderProps } from '@progress/kendo-react-treeview';
import { MitreAttackBadge } from '../mitre-attack-badge';
import { MitreAttackTactic } from './types';

import styles from './mitre-attack-tree-item.module.scss';

export const MitreAttackTreeItem = (tactics: MitreAttackTactic[]) => {
	const MitreAttackItem = ({ item, itemHierarchicalIndex }: ItemRenderProps) => {
		const nodeIndexes = itemHierarchicalIndex.split('_');
		const tacticIndex = Number(nodeIndexes[0]);
		const techniqueIndex = nodeIndexes[1];

		const tactic = techniqueIndex ? tactics[tacticIndex].text : undefined;
		return <MitreAttackBadge text={item.text} count={item?.count} className={styles.treeNode} tactic={tactic} />;
	};

	return MitreAttackItem;
};
