import { FC, ReactNode } from 'react';

import styles from './context-menu-title.module.scss';

interface ContextMenuTitleProps {
	children: ReactNode;
}

export const ContextMenuTitle: FC<ContextMenuTitleProps> = ({ children }) => {
	return <h6 className={styles.contextMenuTitle}>{children}</h6>;
};
