import { Column } from '@/app/_common/_components/data-grid/data-grid';

export const moveColumnToFront = (gridColumns: Column[], columnName: string) => {
	const newGridColumns = gridColumns.slice();
	const dateTimeColumnIndex = newGridColumns.findIndex((item) => item.id === columnName);

	if (dateTimeColumnIndex !== -1) {
		const gridColumnToMove = newGridColumns.splice(dateTimeColumnIndex, 1)[0];
		newGridColumns.unshift(gridColumnToMove);
	}

	return newGridColumns;
};
