import { FilterPanel, FilterPanelList } from '@/app/_common/_components/filter-panel';
import { Namespaces } from '@/translations/namespaces';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FilterPanelListItemRenderer } from '../available-fields-list-item/_components';
import { AVAILABLE_FIELDS_BLOCK_NAME, AvailableField, FieldFilterValue } from '@/app/_common/types';
import { useInstance } from 'react-ioc';
import { AvailableFieldsValuesViewStore, AvailableFieldsViewStore } from '@/app/advanced-query/_common/stores';
import { Ref, useEffect, useMemo } from 'react';

export interface AvailableFieldsListItemPopupContentProps {
	blockName: AVAILABLE_FIELDS_BLOCK_NAME;
	value: string;
	popupRef: Ref<HTMLDivElement>;
}

export const AvailableFieldsListItemPopupContent = observer(({ blockName, value, popupRef }: AvailableFieldsListItemPopupContentProps) => {
	const availableFieldsViewStore = useInstance(AvailableFieldsViewStore);
	const availableFieldsValuesViewStore = useInstance(AvailableFieldsValuesViewStore);
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: 'tabs.advancedQuery.availableFields' });

	const otherFieldFiltersData: AvailableField[] = useMemo(() => {
		const fieldFilters = availableFieldsViewStore.filters.filter((filter) => filter.field === value);
		const fieldFiltersValues = [...fieldFilters.reduce<FieldFilterValue[]>((result, { values }) => [...result, ...values], [])];
		const otherFieldFilters: FieldFilterValue[] = fieldFiltersValues.filter(
			(filterValue) =>
				![...availableFieldsValuesViewStore.topValues, ...availableFieldsValuesViewStore.rareValues]
					.map(({ value }) => String(value))
					.includes(String(filterValue)),
		);

		return otherFieldFilters.map((value) => ({ value: String(value), count: 0 }));
	}, [availableFieldsViewStore.filters, value, availableFieldsValuesViewStore.topValues, availableFieldsValuesViewStore.rareValues]);

	useEffect(() => {
		// @ts-ignore
		availableFieldsValuesViewStore.read(blockName);
	}, [availableFieldsValuesViewStore, blockName]);

	const handleToggleEqualsFilter = (...args: [string | number]) => {
		availableFieldsViewStore.toggleEqualsFilter(blockName, ...args);
	};

	const handleToggleNotEqualsFilter = (...args: [string | number]) => {
		availableFieldsViewStore.toggleNotEqualsFilter(blockName, ...args);
	};

	return (
		<FilterPanel
			ref={popupRef}
			title={availableFieldsValuesViewStore.title}
			subTitle={t('values.subTitle', {
				total: availableFieldsValuesViewStore.uniqValuesCount,
				percentage: availableFieldsValuesViewStore.getPercentage(blockName),
			})}
			isFavorite={availableFieldsViewStore.savedFavoriteFields[blockName].includes(value)}
			onFavoriteClick={() => availableFieldsViewStore.toggleFavoriteField(blockName, value)}
			loading={availableFieldsValuesViewStore.loading}
		>
			{otherFieldFiltersData.length > 0 && (
				<FilterPanelList
					data={otherFieldFiltersData}
					valueHeader={t('values.headers.otherValues', { count: otherFieldFiltersData?.length })}
					countHeader={t('values.headers.count')}
					actionsHeader={t('values.headers.filter')}
					filters={availableFieldsViewStore.filters.filter((filter) => filter.field === value)}
					onEqualsFilter={handleToggleEqualsFilter}
					onNotEqualsFilter={handleToggleNotEqualsFilter}
					equalsFilterButtonTooltip={t('values.filters.tooltips.equals')}
					notEqualsFilterButtonTooltip={t('values.filters.tooltips.notEquals')}
					fieldName={value}
					itemRenderer={FilterPanelListItemRenderer}
				/>
			)}
			{availableFieldsValuesViewStore.topValues.length > 0 && (
				<FilterPanelList
					data={availableFieldsValuesViewStore.topValues}
					total={availableFieldsValuesViewStore.valuesTotalCount}
					valueHeader={t('values.headers.topValues', { count: availableFieldsValuesViewStore.topValues.length })}
					countHeader={t('values.headers.count')}
					actionsHeader={t('values.headers.filter')}
					filters={availableFieldsViewStore.filters.filter((filter) => filter.field === value)}
					onEqualsFilter={handleToggleEqualsFilter}
					onNotEqualsFilter={handleToggleNotEqualsFilter}
					equalsFilterButtonTooltip={t('values.filters.tooltips.equals')}
					notEqualsFilterButtonTooltip={t('values.filters.tooltips.notEquals')}
					fieldName={value}
					itemRenderer={FilterPanelListItemRenderer}
				/>
			)}
			{availableFieldsValuesViewStore.rareValues.length > 0 && (
				<FilterPanelList
					data={availableFieldsValuesViewStore.rareValues}
					total={availableFieldsValuesViewStore.valuesTotalCount}
					valueHeader={t('values.headers.rareValues', { count: availableFieldsValuesViewStore.rareValues.length })}
					countHeader={t('values.headers.count')}
					actionsHeader={t('values.headers.filter')}
					filters={availableFieldsViewStore.filters.filter((filter) => filter.field === value)}
					onEqualsFilter={handleToggleEqualsFilter}
					onNotEqualsFilter={handleToggleNotEqualsFilter}
					equalsFilterButtonTooltip={t('values.filters.tooltips.equals')}
					notEqualsFilterButtonTooltip={t('values.filters.tooltips.notEquals')}
					fieldName={value}
					itemRenderer={FilterPanelListItemRenderer}
				/>
			)}
		</FilterPanel>
	);
});
