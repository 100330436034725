import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';

import { SortDirection } from '@/app/_common/constants';
import { FilterOption } from '@/app/_common/types';

export const sortIndicatorsFilters = (data: FilterOption[], path: string) => {
	return _orderBy<FilterOption>(
		data,
		[
			(option) => {
				return _get(option, path).toString().toLowerCase();
			},
		],
		[SortDirection.Asc],
	);
};
