import { action, computed, makeObservable } from 'mobx';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { Alert } from '@/generated/graphql';
import { DataGridSelectedValue } from '@/app/_common/types';

import { AlertsListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/alerts-list.view-store';
import { BaseAlertsDetailsViewStore } from '@/app/_common/_components/details-view/stores/base-alerts-details.view-store';
import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';

export class DashboardAlertDetailsViewStore extends BaseAlertsDetailsViewStore {
	private alertsListViewStore = injectInterface(this, AlertsListViewStore);

	constructor() {
		super();

		makeObservable(this, {
			isAlertSelected: computed,
			currentAlertIndex: computed,
			selectedAlertIndex: computed,
			selectedAlerts: computed,
			totalAlertsAmount: computed,
			loading: computed,
			alertError: computed,
			onNextAlert: action,
			onPreviousAlert: action,
		});
	}

	get loading(): boolean {
		return this.isLoading;
	}

	get alertError() {
		return this.alertsListViewStore.error;
	}

	get selectedAlertIndex(): number {
		return this.selectedAlerts.findIndex((node) => this.selectedAlertId === node.id);
	}

	get selectedAlerts(): Alert[] {
		return this.alertsListViewStore.data.filter((alert) => this.alertsListViewStore.isRowSelected(alert.node.id)).map((alert) => alert.node);
	}

	get isAlertSelected(): DataGridSelectedValue {
		return this.selectedAlertId ? this.alertsListViewStore.selectedRows[this.selectedAlertId] : false;
	}

	get selectedItemTypename(): DetailsViewTypename {
		return DetailsViewTypename.ALERT;
	}

	get currentAlertIndex(): number {
		if (this.isAlertSelected) {
			return this.selectedAlerts.findIndex((alert) => alert.id === this.selectedAlertId) + 1;
		}
		return this.alertsListViewStore.data.findIndex((alert) => alert.node.id === this.selectedAlertId) + 1;
	}

	get totalAlertsAmount(): number {
		if (this.isAlertSelected) {
			return this.selectedAlerts.length;
		}
		return this.alertsListViewStore.totalCount;
	}

	get selectedAlert(): Alert | undefined {
		if (!this.state.selectedAlertId) {
			return undefined;
		}

		return this.alertsListViewStore.getAlertById(this.state.selectedAlertId)?.node;
	}

	onNextAlert = () => {
		if (this.selectedAlertIndex + 1 > this.totalAlertsAmount) {
			return;
		}
		if (this.totalAlertsAmount === 1) {
			this.clearSelectedAlertId();
			return;
		}
		if (this.totalAlertsAmount === this.selectedAlertIndex + 1) {
			this.onPreviousAlert();
			return;
		}

		const nextSelectedAlertId = this.selectedAlerts[this.selectedAlertIndex + 1]?.id;
		this.setSelectedAlertId(nextSelectedAlertId);
	};

	onPreviousAlert = () => {
		if (this.selectedAlertIndex - 1 < 0) {
			this.onNextAlert();
			return;
		}

		const nextSelectedAlertId = this.selectedAlerts[this.selectedAlertIndex - 1]?.id;
		this.setSelectedAlertId(nextSelectedAlertId);
	};
}
