import { useTranslation } from 'react-i18next';

import PageviewIcon from '@mui/icons-material/Pageview';

import { Namespaces } from '@/translations/namespaces';
import { CustomIcon } from '@/app/_common/_components';
import { ALERT_ACTIONS } from '@/app/_common/types';
import { ALERT_CONTEXT_MENU_ACTIONS, IconType, UseActionLabelResult } from '@/app/_features/alerts-actions-menu/_components/action-label/types';
import { ReactComponent as AssignActionIcon } from '@/assets/images/assign-alert-icon.svg';
import { ReactComponent as DismissActionIcon } from '@/assets/images/dismiss-alert-icon.svg';
import { ReactComponent as UnassignActionIcon } from '@/assets/images/unassign-alert-icon.svg';
import { ReactComponent as UndismissActionIcon } from '@/assets/images/undismiss-alert-icon.svg';

const ALERT_ACTION_ICONS = {
	[ALERT_ACTIONS.AssignOrCreateInvestigation]: () => (
		<CustomIcon>
			<AssignActionIcon />
		</CustomIcon>
	),
	[ALERT_ACTIONS.Unassign]: () => (
		<CustomIcon>
			<UnassignActionIcon />
		</CustomIcon>
	),
	[ALERT_ACTIONS.Dismiss]: () => (
		<CustomIcon>
			<DismissActionIcon />
		</CustomIcon>
	),
	[ALERT_ACTIONS.Undismiss]: () => (
		<CustomIcon>
			<UndismissActionIcon />
		</CustomIcon>
	),
	[ALERT_ACTIONS.ViewDetails]: PageviewIcon as unknown as IconType,
};

export const useActionLabel = (action: ALERT_CONTEXT_MENU_ACTIONS, count?: number): UseActionLabelResult => {
	const isBulkAction = typeof count === 'number';
	const keyPrefix = isBulkAction ? `menu.actions.bulk.items.${action}` : `menu.actions.single.items.${action}`;
	const options = isBulkAction ? { count } : undefined;
	const { t } = useTranslation([Namespaces.AlertsActions], { keyPrefix });

	return {
		icon: ALERT_ACTION_ICONS[action],
		label: t('label', options),
	};
};
