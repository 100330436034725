import _isEmpty from 'lodash/isEmpty';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapDecodedUrl = (state: { [key: string]: any }) => {
	if (_isEmpty(state)) {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return Object.keys(state).reduce((acc: { [key: string]: any }, curr) => {
		if (typeof state[curr] === 'object') {
			const concatVariables = Object.keys(state[curr]).reduce((acc, key) => {
				return { ...acc, [`${curr}_${key}`]: state[curr][key] };
			}, {});

			return { ...acc, ...concatVariables };
		}

		return { ...acc, [curr]: state[curr] };
	}, {});
};
