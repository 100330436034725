import { useMemo, useState, useCallback } from 'react';
import _uniq from 'lodash/uniq';
import _get from 'lodash/get';

import { Alert, ValueOperator } from '@/generated/graphql';
import { ListInvestigationsByIds } from '@/app/_common/graphql/queries';
import { ContextMenuConfig } from '@/app/_common/_components/new-context-menu';
import { DataGridDataItem, DataGridSelected } from '@/app/_common/types';

export const useAlertsContextMenuConfig = <T,>(
	alerts: DataGridDataItem<Alert>[],
	selectedAlertIds: string[],
	onSelectRow: (selectedRows: DataGridSelected) => void,
	dataItemPropertyPath?: string,
	dataItemIdPath = 'id',
): ContextMenuConfig<T> => {
	const [focusedAlertNode, setFocusedAlertNode] = useState<Alert | null>(null);

	const selectedAlerts = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return alerts.reduce<Alert[]>((result: Alert[], alertNode: any) => {
			const id: string = alertNode[dataItemIdPath];
			const isSelected = selectedAlertIds.includes(id);
			if (isSelected) {
				result.push(alertNode);
			}

			return result;
		}, []);
	}, [alerts, dataItemIdPath, selectedAlertIds]);

	const investigationIds: string[] = useMemo(() => {
		const focusedInvestigationId =
			focusedAlertNode && 'investigationSummary' in focusedAlertNode ? focusedAlertNode?.investigationSummary?.id : undefined;
		const selectedInvestigationIds =
			selectedAlerts?.map((alert) => ('investigationSummary' in alert ? alert.investigationSummary?.id : undefined)) ?? [];

		return _uniq([focusedInvestigationId, ...selectedInvestigationIds].filter((id): id is string => typeof id === 'string'));
	}, [focusedAlertNode, selectedAlerts]);

	const filtersInput = useMemo(() => {
		if (investigationIds.length === 0) {
			return {};
		}

		return {
			valueFilters: [
				{
					field: 'id',
					operator: ValueOperator.IsIn,
					values: investigationIds,
				},
			],
		};
	}, [investigationIds]);

	const query = useMemo(() => {
		return investigationIds.length > 0 ? ListInvestigationsByIds : undefined;
	}, [investigationIds]);

	const handleOpenContextMenu = useCallback(
		(_: string, dataItem: T) => {
			const node = dataItemPropertyPath ? _get(dataItem, dataItemPropertyPath) : dataItem;
			setFocusedAlertNode(node);

			const id = _get(node, dataItemIdPath);
			if (!selectedAlertIds.includes(id)) {
				onSelectRow({ [id]: true });
			}
		},
		[selectedAlertIds, dataItemPropertyPath, dataItemIdPath, onSelectRow],
	);

	const queryDefinitions = useMemo(() => {
		if (!query) {
			return [];
		}

		return [{ query, filtersInput }];
	}, [query, filtersInput]);

	const config = useMemo(() => {
		return {
			queryDefinitions,
			onOpen: handleOpenContextMenu,
		};
	}, [handleOpenContextMenu, queryDefinitions]);

	return config;
};
