import { OptionGroupItemLabelProps } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/option-group-item-label/types';
import { getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';
import { MitreAttackBadge } from '@/app/_common/_components/mitre-attack-badge';

import styles from './mitre-tactics-option-group-item-label.module.scss';

export const MitreTacticsOptionGroupItemLabel = (props: OptionGroupItemLabelProps) => {
	const { value } = props;
	const mitreText = getMitreAttackBadgeText(String(value));
	return <MitreAttackBadge text={mitreText} className={styles.mitreTacticsOptionGroupItemLabel} />;
};
