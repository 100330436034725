import { action, computed, makeObservable } from 'mobx';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { FetchBaseDataStore } from '@/app/_common/fetch';
import { AdxEndpoints } from '@/app/_common/constants';
import { DataTable, FetchBaseErrorKey, TotalCountResultsDataItem } from '@/app/_common/types';
import { formatQueryResults } from '@/app/advanced-query/_common/utils';

const API_URL = process.env.REACT_APP_ADVANCED_QUERY_URL;
const ERROR_KEY = FetchBaseErrorKey.AdvancedQueryResults;

export class TotalCountDataStore extends FetchBaseDataStore<TotalCountResultsDataItem[]> {
	private authStore = injectInterface({}, AuthStore);
	private cancelSource?: CancelTokenSource;

	constructor() {
		super(ERROR_KEY);

		makeObservable(this, {
			totalCount: computed,
			fetchTotalCount: action,
		});
	}

	get totalCount(): number {
		try {
			return (
				this.data?.reduce((result, { count_ }) => {
					if (typeof count_ === 'number') {
						return result + count_;
					}

					return result;
				}, 0) ?? 0
			);
		} catch (error) {
			return 0;
		}
	}

	public fetchTotalCount(totalCountQuery: string) {
		if (!totalCountQuery) return;

		this.cancelSource = axios.CancelToken.source();
		const totalCountConfig: AxiosRequestConfig = {
			method: 'POST',
			url: `${API_URL}/${AdxEndpoints.QueryV2}`,
			data: { tenantId: this.authStore.currentTenantId, query: totalCountQuery },
			cancelToken: this.cancelSource.token,
			transformResponse: [this.formatResults],
			params: { scope: 'total_count' },
		};

		return this.fetchApiByAxios('', totalCountConfig);
	}

	private formatResults = (response: string): TotalCountResultsDataItem[] => {
		try {
			const parsedResponse: DataTable[] = JSON.parse(response);
			const dataTable: DataTable = parsedResponse[0];
			return formatQueryResults<TotalCountResultsDataItem>(dataTable);
		} catch (error) {
			return [];
		}
	};

	public cancelRequest() {
		this.cancelSource?.cancel();
	}
}
