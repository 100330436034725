import { action, computed, makeObservable, reaction } from 'mobx';

import { Alert, AlertEdge, FieldValue, ValueOperator } from '@/generated/graphql';
import { extractResultsTableItemId } from '@/app/_common/utils';
import { DataGridSelectedValue, ResultsDataItem } from '@/app/_common/types';
import { AssignedAlertPropertiesPaths, ResultsTablePropertiesPaths } from '@/app/_common/constants';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AdvancedQueryResultsTableViewStore } from '@/app/advanced-query/_common/stores';
import { QueryResultsTableInvestigationDetails } from '@/app/investigation-details/_common/stores';
import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { getDetailsViewRowId, getDetailsViewTypename } from '@/app/_common/_components/details-view/utils';
import { BaseAlertsDetailsViewStore } from '@/app/_common/_components/details-view/stores/base-alerts-details.view-store';
import { AlertsDataStore } from '@/app/dashboards/alerts-dashboard/_common/stores/alerts.data-store';

export class BaseResultsDetailsViewStore extends BaseAlertsDetailsViewStore {
	protected resultsViewStore!: AdvancedQueryResultsTableViewStore | QueryResultsTableInvestigationDetails;
	protected alertsDataStore = injectInterface(this, AlertsDataStore);

	constructor() {
		super();

		makeObservable(this, {
			alerts: computed,
			selectedElements: computed,
			selectedAlerts: computed,
			allSelectedDataItems: computed,
			selectedAlertEdge: computed,
			selectedAlertsIdsForQuery: computed,
			isAlertSelected: computed,
			decodedAlertId: computed,
			currentAlertIndex: computed,
			selectedAlertIndex: computed,
			totalAlertsAmount: computed,
			loading: computed,
			alertError: computed,
			onNextAlert: action,
			onPreviousAlert: action,
		});
	}

	get loading(): boolean {
		return this.isLoading;
	}

	get alertError() {
		return this.alertsDataStore.error;
	}

	get alerts(): AlertEdge[] {
		return this.alertsDataStore.alerts;
	}

	get selectedElements() {
		return this.resultsViewStore.selectedElements;
	}

	get selectedAlertEdge(): AlertEdge | undefined {
		if (!this.state.selectedAlertId) {
			return undefined;
		}

		return this.alertsDataStore.alerts.find((alert: AlertEdge) => alert.node.id === this.decodedAlertId);
	}

	get selectedAlert(): Alert | ResultsDataItem | undefined {
		if (!this.state.selectedAlertId) {
			return undefined;
		}

		if (this.selectedItemTypename === DetailsViewTypename.ALERT) {
			return this.selectedAlertEdge?.node;
		}

		if ([DetailsViewTypename.EVENT, DetailsViewTypename.EVIDENCE].includes(this.selectedItemTypename)) {
			return this.resultsViewStore.data.find((item: ResultsDataItem) => item[ResultsTablePropertiesPaths.Id] === this.selectedAlertId);
		}

		return undefined;
	}

	get selectedResultsIds(): string[] {
		return this.resultsViewStore.selectedResultsIds;
	}

	get selectedAlertsIdsForQuery(): string[] {
		if (!this.selectedAlertId) {
			return [];
		}

		const isCurrentIdSelected = this.selectedResultsIds.includes(this.selectedAlertId);
		const preparedIds = this.selectedResultsIds.map((id: string) => extractResultsTableItemId(id));
		return isCurrentIdSelected ? preparedIds : [this.decodedAlertId];
	}

	get selectedAlertIndex(): number {
		return this.selectedResultsIds.findIndex((id) => this.selectedAlertId === id);
	}

	get decodedAlertId(): string {
		return extractResultsTableItemId(this.selectedAlertId);
	}

	get selectedAlerts(): Alert[] {
		if (!this.selectedAlertEdge) {
			return [];
		}

		return [this.selectedAlertEdge.node];
	}

	get allSelectedDataItems(): ResultsDataItem[] {
		if (!this.selectedElements) {
			return [];
		}
		const selectedElementIds = this.selectedElements.map((element) => element._id);

		return this.resultsViewStore.data.filter((element) => {
			return selectedElementIds.includes(element._id);
		});
	}

	get isAlertSelected(): DataGridSelectedValue {
		return this.selectedAlertId ? this.selectedResultsIds.includes(this.selectedAlertId) : false;
	}

	get currentAlertIndex(): number {
		return this.selectedResultsIds.findIndex((alertId) => extractResultsTableItemId(alertId) === this.decodedAlertId) + 1;
	}

	get totalAlertsAmount(): number {
		return this.selectedResultsIds.length || Number(Boolean(this.selectedAlertId));
	}

	setSelectedAlertId = (id?: string): void => {
		this.setTypenameByItemId(id);
		super.setSelectedAlertId(id);
	};

	private setTypenameByItemId = (id?: string) => {
		if (!id) {
			return;
		}

		const item: ResultsDataItem | undefined = this.resultsViewStore.data.find((item: ResultsDataItem) => {
			const itemId = getDetailsViewRowId(item);
			return itemId === id;
		});

		const typename = getDetailsViewTypename(item);
		this.setSelectedItemTypename(typename);
	};

	onNextAlert = () => {
		if (this.selectedAlertIndex + 1 > this.totalAlertsAmount) {
			return;
		}
		if (this.totalAlertsAmount === 1) {
			this.clearSelectedAlertId();
			return;
		}
		if (this.totalAlertsAmount === this.selectedAlertIndex + 1) {
			this.onPreviousAlert();
			return;
		}

		const nextSelectedAlertId = this.selectedResultsIds[this.selectedAlertIndex + 1];

		this.setSelectedAlertId(nextSelectedAlertId);
	};

	onPreviousAlert = () => {
		if (this.selectedAlertIndex - 1 < 0) {
			this.onNextAlert();
			return;
		}

		const nextSelectedAlertId = this.selectedResultsIds[this.selectedAlertIndex - 1];

		this.setSelectedAlertId(nextSelectedAlertId);
	};

	selectedAlertQueryDisposer = reaction(
		() => this.selectedAlertId,
		(alertId) => {
			if (this.isAlert(this.selectedItemTypename)) {
				return this.fetchAlertData(alertId);
			}
		},
	);

	private fetchAlertData = (id?: string): void => {
		const decodedId = extractResultsTableItemId(id);
		const isAlertLoaded = this.alerts.some((alert: AlertEdge) => alert.node.id === decodedId);

		if (decodedId && !isAlertLoaded) {
			this.setStoredDataObject(undefined);
			this.clearIocEventsDataObjects();
			const filters: FieldValue[] = [
				{
					field: AssignedAlertPropertiesPaths.Id,
					values: this.selectedAlertsIdsForQuery,
					operator: ValueOperator.IsIn,
				},
			];
			return this.alertsDataStore.read(filters, [], {});
		}
	};

	dispose() {
		this.selectedAlertQueryDisposer();
	}
}
