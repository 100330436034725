import { FieldValue, ValueOperator } from '@/generated/graphql';
import { CompositeFilterDescriptorWithId, FilterDescriptorWithId } from '@/app/_common/types';
import { DEFAULT_FIELD_PREFIX, FilterOperators, RootFilterId } from '@/app/_common/constants';
import { callOrReturnValue } from '@/app/_common/utils';

const operatorsMap = {
	[FilterOperators.Eq]: ValueOperator.Is,
	[FilterOperators.Neq]: ValueOperator.IsNot,
	[FilterOperators.IsIn]: ValueOperator.IsIn,
	[FilterOperators.IsNotIn]: ValueOperator.IsNotIn,
	[FilterOperators.IsNotNull]: ValueOperator.IsPresent,
};

const getNestedDescriptors = (
	results: Array<CompositeFilterDescriptorWithId | FilterDescriptorWithId>,
	filterGroup: CompositeFilterDescriptorWithId | FilterDescriptorWithId,
): Array<CompositeFilterDescriptorWithId | FilterDescriptorWithId> => {
	if ('filters' in filterGroup) {
		return [...results, ...filterGroup.filters];
	}

	return results;
};

const getFilterDescriptorsFromCompositeFilterDescriptor = (
	results: FilterDescriptorWithId[],
	filterGroup: CompositeFilterDescriptorWithId | FilterDescriptorWithId,
): FilterDescriptorWithId[] => {
	if ('filters' in filterGroup) {
		return [...results, ...filterGroup.filters.filter((filter): filter is FilterDescriptorWithId => 'value' in filter)];
	}

	return results;
};

export const adaptKendoFiltersToQueryValueFilters = (
	rootCompositeFilterDescriptor: CompositeFilterDescriptorWithId,
	removablePrefix = DEFAULT_FIELD_PREFIX,
): FieldValue[] | undefined => {
	const valueFilters: FieldValue[] = [];

	if (rootCompositeFilterDescriptor.id !== RootFilterId) {
		return;
	}

	const groupCompositeFilterDescriptors = rootCompositeFilterDescriptor.filters.filter((filterGroup) => 'filters' in filterGroup);
	const filterCompositeFilterDescriptors = groupCompositeFilterDescriptors.reduce(getNestedDescriptors, []);
	const filters = filterCompositeFilterDescriptors.reduce(getFilterDescriptorsFromCompositeFilterDescriptor, []);

	filters.forEach((filter) => {
		const filterField = callOrReturnValue(filter.field);
		const field = filterField?.replace(removablePrefix, '');

		if (!field) {
			return;
		}

		if (Array.isArray(filter.value)) {
			valueFilters.push({
				field,
				operator: operatorsMap[callOrReturnValue(filter.operator) as keyof typeof operatorsMap],
				values: filter.value,
			});
		} else {
			valueFilters.push({
				field,

				operator: operatorsMap[callOrReturnValue(filter.operator) as keyof typeof operatorsMap],
				value: filter.value,
			});
		}
	});

	return valueFilters;
};
