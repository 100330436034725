import { action, makeObservable } from 'mobx';

import { NoteTypename } from '@/app/_common/constants';
import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { DeleteNote } from '@/app/_common/graphql/queries/notes.queries';
import { Mutation, Query, MutationDeleteNoteArgs, NotesConnection } from '@/generated/graphql';

type ListNotes = Pick<Query, 'listNotes'>;

export class InvestigationNotesDeleteDataStore extends GraphqlBaseDataStore<ListNotes> {
	constructor() {
		super();
		makeObservable(this, {
			delete: action,
		});
	}

	async delete(args: Omit<MutationDeleteNoteArgs, 'tenantId'>) {
		const variables = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationDeleteNoteArgs>({
			mutation: DeleteNote,
			variables,
			update: (cache, { data }) => {
				if (!data?.deleteNote || data?.deleteNote.__typename !== NoteTypename.Note) {
					return;
				}

				cache.modify({
					fields: {
						listNotes(existingNotesRefs: NotesConnection, { readField }) {
							return {
								...(existingNotesRefs || {}),
								//@ts-ignore
								edges: [...existingNotesRefs.edges.filter((edge) => readField('id', edge.node) !== data.deleteNote.id)],
							};
						},
					},
				});
			},
		});

		return response;
	}
}
