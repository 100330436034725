import classNames from 'classnames';

import { LoadingSpinner } from '@/app/_common/_components';
import { AlertDetailsError } from '@/app/_common/_components/details-view/alerts-details-view-navigation';

import styles from './alert-details-loading-indicator.module.scss';

interface AlertsDetailsLoadingIndicatorProps {
	hasSelectedAlert: boolean;
	loading: boolean;
	hasError: boolean;
	retryRead: () => void;
}

export const AlertsDetailsLoadingIndicator = ({ loading, hasError = false, retryRead, hasSelectedAlert }: AlertsDetailsLoadingIndicatorProps) => {
	const shouldShowLoading = hasSelectedAlert && loading;
	const shouldShowError = hasSelectedAlert && hasError;

	if (!shouldShowLoading && !shouldShowError) {
		return null;
	}

	return (
		<div className={classNames(styles.container, { error: shouldShowError })}>
			{shouldShowError ? <AlertDetailsError handleOnClick={retryRead} /> : <LoadingSpinner />}
		</div>
	);
};
