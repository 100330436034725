import { FC, useState, useEffect, KeyboardEvent } from 'react';
import { observer } from 'mobx-react-lite';

import classNames from 'classnames';
import _debounce from 'lodash/debounce';

import { Input, InputChangeEvent } from '@progress/kendo-react-inputs';

import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { isEditableHeaderNameInValid } from '@/app/_common/_components/editable-header/utils/is-editable-header-name-invalid';

import styles from './editable-header.module.scss';

interface EditableHeaderProps {
	value?: string;
	saveNewName: (value: string) => Promise<boolean | undefined> | undefined;
	className?: string;
	isEditable?: boolean;
}

export const EditableHeader: FC<EditableHeaderProps> = observer(({ className = '', value = '', saveNewName, isEditable = true }) => {
	const [currentValue, setCurrentValue] = useState<string>('');

	const [inValid, setInValid] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);

	useEffect(() => {
		setCurrentValue(value);
	}, [value]);

	useEffect(() => {
		if (!isEditable) {
			setEdit(false);
		}
	}, [isEditable]);

	const handleActiveEdit = () => {
		setEdit(true);
	};

	const handleCloseEdit = () => {
		setEdit(false);
		setCurrentValue(value);
	};

	const handleSetNewName = (event: InputChangeEvent) => {
		const isNameInValid = isEditableHeaderNameInValid(event);
		setInValid(isNameInValid);
		setCurrentValue(event.value);
	};

	const handleSaveNewName = async () => {
		if (value === currentValue || !edit) {
			return;
		}

		const valid = await saveNewName(currentValue);

		if (valid) {
			setEdit(false);
		}
	};

	const keyListener = async (event: KeyboardEvent) => {
		if (['Enter', 'NumpadEnter'].includes(event.code)) {
			await handleSaveNewName();
		}
		if (event.code === 'Escape') {
			handleCloseEdit();
		}
	};

	const isDisabled = inValid || value === currentValue;

	return (
		<div className={styles.editableWrapper} data-testid="investigation-details-name">
			<div className={classNames(styles.box, { [styles.disabled]: !edit || !isEditable })}>
				<div className={styles.contentBox}>
					{isEditable && edit ? (
						<Input
							className={classNames(styles.editableInput, { [className]: className, [styles.disabledInput]: !edit, [styles.inValid]: inValid })}
							type="text"
							id={currentValue}
							disabled={!edit}
							value={currentValue}
							onKeyDown={_debounce((e) => keyListener(e), 500)}
							onChange={handleSetNewName}
						/>
					) : (
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events
						<div onClick={handleActiveEdit} className={classNames(styles.name, { [styles.active]: isEditable })}>
							<span className={className}>{value} </span>
							<EditIcon className={styles.editIcon} />
						</div>
					)}
				</div>
			</div>
			{isEditable && edit && (
				<div className={styles.actionsButton}>
					<button className={classNames(styles.action, { [styles.btnDisabled]: isDisabled })} disabled={isDisabled} onClick={handleSaveNewName}>
						<DoneIcon className={styles.check} />
					</button>

					<button className={styles.action} onClick={handleCloseEdit}>
						<CloseIcon className={styles.close} />
					</button>
				</div>
			)}
		</div>
	);
});
