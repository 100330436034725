import { FetchBaseDataStore } from '@/app/_common/fetch/fetch-base.data-store';

export class FranchiseLogoDataStore extends FetchBaseDataStore<Blob | MediaSource> {
	get franchiseLogo() {
		return this.data;
	}

	read(containerName?: string, key?: string) {
		this.clear();
		const fileEndpoint = process.env.REACT_APP_FILE_ENDPOINT;

		if (containerName && key && fileEndpoint) {
			const urlSearchParams = new URLSearchParams({ containerName, key, downloadFile: 'true' });
			this.fetchImageByFetch(fileEndpoint, urlSearchParams);
		}
	}
}
