import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { SubscriptionHookOptions, OperationVariables, useSubscription } from '@apollo/client';

interface SubscriptionInstanceProps<TData, TVariables = OperationVariables> {
	subscription: DocumentNode | TypedDocumentNode<TData, TVariables>;
	options?: SubscriptionHookOptions<TData, TVariables>;
}

export function SubscriptionInstance<TData, TVariables>({ subscription, options }: SubscriptionInstanceProps<TData, TVariables>) {
	useSubscription(subscription, options);

	return null;
}
