import SyntaxHighlighter from 'react-syntax-highlighter';
import classNames from 'classnames';

import { samuraiLight, samuraiDark } from './custom-themes';
import { formatDataForSyntaxHighlighter } from '@/app/_common/utils';

import styles from './json-syntax-highlighter.module.scss';

interface JsonSyntaxHighlighterProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
	isDarkTheme?: boolean;
	className?: string;
}

export const JsonSyntaxHighlighter = ({ value, isDarkTheme, className }: JsonSyntaxHighlighterProps) => {
	const formattedValue = formatDataForSyntaxHighlighter(value);

	if (!formattedValue) {
		return null;
	}

	return (
		<SyntaxHighlighter
			language={'json'}
			style={isDarkTheme ? samuraiDark : samuraiLight}
			className={classNames(styles.syntaxHighlighter, className)}
			wrapLongLines
		>
			{formattedValue}
		</SyntaxHighlighter>
	);
};
