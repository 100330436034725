import { UserRoles } from '@/app/_common/constants';
import { User, UserTenantInfo } from '@/app/_common/interfaces';

export const determineUserRole = (user: User, currentTenantId: string): UserRoles => {
	if (!user.isTenantUser && user.franchise.isOwner) {
		return UserRoles.FRANCHISE_OWNER;
	}

	if (!user.isTenantUser) {
		return UserRoles.FRANCHISE_MANAGER;
	}

	if (user.isTenantUser) {
		const tenant = user.tenants?.find((tenant) => tenant.id === currentTenantId);
		if (tenant && (tenant as UserTenantInfo).isBillingUser) {
			return UserRoles.TENANT_OWNER;
		}
	}

	return UserRoles.TENANT_USER;
};
