import { action, computed, makeObservable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { sortAlerts } from '@/app/_common/utils';
import { AlertsUtilsViewStore } from '@/app/_common/stores';
import { FilterGroupId, FilterLogic, FilterOperators, SortDirection } from '@/app/_common/constants';
import { Filters, FilterValue, AlertWithInvestigationSummaryExtended } from '@/app/_common/types';
import { mapFilterOption, getSeverityOption, getConfidenceOption, getStateOption } from '@/app/_common/_components/data-grid/utils';
import { AlertPropertiesPaths } from '@/app/_features/alerts-actions//_components/alerts-action-dialog/_common';
import { AlertExtended } from '@/app/_features/alerts-actions/_components/alerts-action-dialog/_common/types';

const INITIAL_COLUMNS = {
	[AlertPropertiesPaths.Selected]: true,
	[AlertPropertiesPaths.Timestamp]: true,
	[AlertPropertiesPaths.InvestigationSummaryName]: true,
	[AlertPropertiesPaths.Severity]: true,
	[AlertPropertiesPaths.Confidence]: true,
	[AlertPropertiesPaths.Title]: true,
	[AlertPropertiesPaths.Source]: true,
	[AlertPropertiesPaths.Destination]: true,
	[AlertPropertiesPaths.Mitre]: true,
	[AlertPropertiesPaths.DetectedBy]: true,
};

const INITIAL_SORT = [{ field: AlertPropertiesPaths.Severity, dir: SortDirection.Desc }];

export class AlertsDataGridViewStore extends AlertsUtilsViewStore<AlertExtended> {
	constructor() {
		super(AlertPropertiesPaths.Id, INITIAL_COLUMNS, INITIAL_SORT, sortAlerts);
		makeObservable(this, {
			noneSelected: computed,
			setSourceData: action,
		});
	}

	get noneSelected() {
		return this.selectedCount === 0;
	}

	getFilterOptions = computedFn((field: string) => {
		const counters = this.getCountedValues(field);
		return this.getUniqValues(field).map((value) => {
			return mapFilterOption(
				field,
				{
					value: value as FilterValue,
					counter: counters[value as string],
				},
				{
					[AlertPropertiesPaths.State]: getStateOption,
					[AlertPropertiesPaths.Severity]: getSeverityOption,
					[AlertPropertiesPaths.Confidence]: getConfidenceOption,
				},
			);
		});
	});

	setGridHeaderFilter = (field: string, values: Filters) => {
		const filters = values.map((value) => {
			let operator: string = FilterOperators.Eq;

			if (field === AlertPropertiesPaths.Source || field === AlertPropertiesPaths.Destination) {
				operator = FilterOperators.IsSomeIn;
			}

			return { value, field, operator, ignoreCase: false };
		});

		this.setFilter({
			id: FilterGroupId.gridHeader,
			filters,
			logic: FilterLogic.And,
			nestedId: field,
			nestedLogic: FilterLogic.Or,
		});
	};

	setSourceData(alerts: AlertWithInvestigationSummaryExtended[]) {
		this.sourceData = alerts;
	}

	clearSourceData = () => {
		this.sourceData = [];
	};
}
