import { FC } from 'react';

import { Namespaces } from '@/translations/namespaces';

import styles from './total-score.module.scss';
import { InfoTooltip } from '@/app/_common/_components/info-tooltip';
import { useTranslation } from 'react-i18next';
import { InsertChartOutlined } from '@mui/icons-material';
import { SummarySectionItemTitle } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-section-item-title';

import classNames from 'classnames';

interface TotalScoreProps {
	score: number;
	className?: string;
}

export const TotalScore: FC<TotalScoreProps> = ({ score, className }) => {
	const { t } = useTranslation(Namespaces.DetailsView);

	const tooltipContent = (
		<div className={styles.tooltipDescription}>
			<h4>{t('summary.scoreTooltip.title')}</h4>
			<p>{t('summary.scoreTooltip.description')}</p>
		</div>
	);

	if (!score) {
		return null;
	}

	return (
		<div className={classNames(styles.container, className)}>
			<div className={styles.titleContainer}>
				<SummarySectionItemTitle className={styles.title} title={t('summary.totalScore')} />
				<InfoTooltip description={tooltipContent} id={t('summary.totalScore')} place="right" />
			</div>
			<p className={styles.score}>
				<InsertChartOutlined htmlColor="var(--brandDanger)" />
				<span>{score}</span>
			</p>
		</div>
	);
};
