/* istanbul ignore file */

import { useEffect, useState } from 'react';
import { loadMonaco } from './load-monaco';
import type { Monaco } from '@monaco-editor/react';

export type { Monaco };
export const useMonaco = () => {
	const [monaco, setMonaco] = useState<Monaco>();
	useEffect(() => {
		loadMonaco()
			.then((monaco) => {
				setMonaco(monaco);
			})
			// eslint-disable-next-line no-console
			.catch(console.error);
	}, []);
	return monaco;
};
