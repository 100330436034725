import { DocumentNode } from 'graphql';

import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';
import { GetIntegrationAndCollectorBreadcrumb, GetIntegrationViewBreadcrumb, GetInvestigationBreadcrumb } from '@/app/_common/graphql/queries';

import { getTelemetryIntegrationDisplayName } from '@/app/_features/integration/_common/utils';

import { RootPaths } from './root-paths';

export type Route = {
	key: string;
	displayName: string;
	private?: boolean;
	path: RootPaths;
	childRoutes?: Route[];
	customRouteBreadcrumb?: RouteBreadcrumb;
};

export type RouteBreadcrumb = {
	queryParameterName: string;
	optionalQueryParameter?: {
		requirement: string;
		valid: string;
		queryParameterName: string;
	};
	queryType: string;
	query: DocumentNode;
	breadcrumbSuffix?: string;
	breadcrumbPrefix?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	computeBreadcrumb?: (item: any) => string;
};

export const Routes: Route[] = [
	{
		key: 'franchise',
		private: true,
		displayName: i18n.t('franchise.main', { ns: Namespaces.Breadcrumbs }),
		path: RootPaths.FRANCHISE,
		childRoutes: [
			// {
			// 	key: 'franchiseActionHistory',
			// 	private: true,
			// 	displayName: i18n.t('franchise.actionHistory', { ns: Namespaces.Breadcrumbs }),
			// 	path: RootPaths.FRANCHISE_ACTION_HISTORY,
			// },
			{
				key: 'franchiseClients',
				private: true,
				displayName: i18n.t('franchise.clients', { ns: Namespaces.Breadcrumbs }),
				path: RootPaths.FRANCHISE_CLIENTS,
				childRoutes: [
					{
						key: 'createFranchiseClient',
						private: true,
						displayName: i18n.t('franchise.createClient', { ns: Namespaces.Breadcrumbs }),
						path: RootPaths.CREATE_FRANCHISE_CLIENT,
					},
				],
			},
			// {
			// 	key: 'franchiseTeam',
			// 	private: true,
			// 	displayName: i18n.t('franchise.team', { ns: Namespaces.Breadcrumbs }),
			// 	path: RootPaths.FRANCHISE_TEAM,
			// },
		],
	},
	{
		key: 'dashboards',
		private: true,
		displayName: i18n.t('dashboards.main', { ns: Namespaces.Breadcrumbs }),
		path: RootPaths.DASHBOARDS,
		childRoutes: [
			{
				key: 'alertsDashboard',
				private: true,
				displayName: i18n.t('dashboards.alertsManagement', { ns: Namespaces.Breadcrumbs }),
				path: RootPaths.ALERTS_DASHBOARDS,
			},
			{
				key: 'telemetryDashboard',
				private: true,
				displayName: i18n.t('dashboards.telemetryDashboard', { ns: Namespaces.Breadcrumbs }),
				path: RootPaths.TELEMETRY_DASHBOARD,
			},
		],
	},
	{
		key: 'investigations',
		private: true,
		displayName: i18n.t('investigations.main', { ns: Namespaces.Breadcrumbs }),
		path: RootPaths.INVESTIGATIONS,
		childRoutes: [
			{
				key: 'investigationDetails',
				private: true,
				displayName: i18n.t('investigations.investigationDetails', { ns: Namespaces.Breadcrumbs }),
				path: RootPaths.INVESTIGATION_DETAILS,
				customRouteBreadcrumb: {
					queryParameterName: 'name',
					queryType: 'getInvestigation',
					query: GetInvestigationBreadcrumb,
				},
			},
		],
	},
	{
		key: 'advancedQuery',
		private: true,
		displayName: i18n.t('advancedQuery.main', { ns: Namespaces.Breadcrumbs }),
		path: RootPaths.ADVANCED_QUERY,
	},
	{
		key: 'telemetry',
		private: true,
		displayName: i18n.t('telemetry.main', { ns: Namespaces.Breadcrumbs }),
		path: RootPaths.TELEMETRY,
		childRoutes: [
			{
				key: 'collectors',
				private: true,
				displayName: i18n.t('collectors.main', { ns: Namespaces.Breadcrumbs }),
				path: RootPaths.COLLECTORS,
				childRoutes: [
					{
						key: 'deleteIntegration',
						private: true,
						displayName: i18n.t('collectors.deleteIntegration', { ns: Namespaces.Breadcrumbs }),
						path: RootPaths.INTEGRATION_DELETE_IN_COLLECTOR,
						customRouteBreadcrumb: {
							queryParameterName: 'name',
							optionalQueryParameter: {
								requirement: 'type',
								valid: 'AUTO_DETECTED',
								queryParameterName: 'productName',
							},
							queryType: 'getIntegration',
							query: GetIntegrationAndCollectorBreadcrumb,
							breadcrumbPrefix: i18n.t('integrations.deleteIntegration', { ns: Namespaces.Breadcrumbs }),
						},
					},
					{
						key: 'integrationConfiguration',
						private: true,
						displayName: i18n.t('collectors.integrationConfiguration', { ns: Namespaces.Breadcrumbs }),
						path: RootPaths.INTEGRATION_CONFIGURATION,
						customRouteBreadcrumb: {
							queryParameterName: 'name',
							queryType: 'getIntegration',
							query: GetIntegrationAndCollectorBreadcrumb,
							breadcrumbPrefix: i18n.t('integrations.viewIntegration', { ns: Namespaces.Breadcrumbs }),
						},
					},
				],
			},
			{
				key: 'integrations',
				private: true,
				displayName: i18n.t('integrations.main', { ns: Namespaces.Breadcrumbs }),
				path: RootPaths.TELEMETRY_INTEGRATIONS,
				childRoutes: [
					{
						key: 'deleteIntegration',
						private: true,
						displayName: i18n.t('integrations.deleteIntegration', { ns: Namespaces.Breadcrumbs }),
						path: RootPaths.INTEGRATION_DELETE,
						customRouteBreadcrumb: {
							queryParameterName: 'name',
							queryType: 'getIntegration',
							query: GetIntegrationAndCollectorBreadcrumb,
							breadcrumbPrefix: i18n.t('integrations.deleteIntegration', { ns: Namespaces.Breadcrumbs }),
							computeBreadcrumb: getTelemetryIntegrationDisplayName,
						},
					},
					{
						key: 'createTelemetryIntegration',
						private: true,
						displayName: i18n.t('integrations.createIntegration', { ns: Namespaces.Breadcrumbs }),
						path: RootPaths.CREATE_TELEMETRY_INTEGRATION,
					},
					{
						key: 'viewIntegration',
						private: true,
						displayName: i18n.t('integrations.viewIntegration', { ns: Namespaces.Breadcrumbs }),
						path: RootPaths.TELEMETRY_INTEGRATION_DETAILS,
						customRouteBreadcrumb: {
							queryParameterName: 'name',
							queryType: 'getIntegration',
							query: GetIntegrationViewBreadcrumb,
							breadcrumbPrefix: i18n.t('integrations.viewIntegration', { ns: Namespaces.Breadcrumbs }),
						},
					},
				],
			},
		],
	},
	{
		key: 'response',
		private: true,
		displayName: i18n.t('response.main', { ns: Namespaces.Breadcrumbs }),
		path: RootPaths.RESPONSE,
		childRoutes: [
			{
				key: 'responseIntegrations',
				private: true,
				displayName: i18n.t('response.integrations', { ns: Namespaces.Breadcrumbs }),
				path: RootPaths.RESPONSE_INTEGRATIONS,
				childRoutes: [
					{
						key: 'createResponseIntegration',
						private: true,
						displayName: i18n.t('integrations.createIntegration', { ns: Namespaces.Breadcrumbs }),
						path: RootPaths.CREATE_RESPONSE_INTEGRATION,
					},
					{
						key: 'responseIntegrationDetails',
						private: true,
						displayName: i18n.t('integrations.viewIntegration', { ns: Namespaces.Breadcrumbs }),
						path: RootPaths.RESPONSE_INTEGRATION_DETAILS,
						customRouteBreadcrumb: {
							queryParameterName: 'name',
							queryType: 'getIntegration',
							query: GetIntegrationViewBreadcrumb,
							breadcrumbPrefix: i18n.t('integrations.viewIntegration', { ns: Namespaces.Breadcrumbs }),
						},
					},
				],
			},
			{
				key: 'responseEndpoints',
				private: true,
				displayName: i18n.t('response.endpoints', { ns: Namespaces.Breadcrumbs }),
				path: RootPaths.RESPONSE_ENDPOINTS,
			},
		],
	},
	{
		key: 'subscription',
		private: true,
		displayName: i18n.t('subscription.main', { ns: Namespaces.Breadcrumbs }),
		path: RootPaths.SUBSCRIPTION,
	},
	{
		key: 'management',
		private: true,
		displayName: i18n.t('management.main', { ns: Namespaces.Breadcrumbs }),
		path: RootPaths.MANAGEMENT,
		childRoutes: [
			{
				key: 'users',
				private: true,
				displayName: i18n.t('management.users', { ns: Namespaces.Breadcrumbs }),
				path: RootPaths.MANAGEMENT_USERS,
			},
			{
				key: 'actionsHistory',
				private: true,
				displayName: i18n.t('management.actionHistory', { ns: Namespaces.Breadcrumbs }),
				path: RootPaths.MANAGEMENT_ACTION_HISTORY,
			},
		],
	},
	{
		key: 'tools',
		private: true,
		displayName: i18n.t('tools.main', { ns: Namespaces.Breadcrumbs }),
		path: RootPaths.TOOLS,
	},
];
