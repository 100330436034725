import { action, makeObservable } from 'mobx';
import { DocumentNode } from 'graphql';
import { gql } from '@apollo/client';

import { Mutation, MutationSetAlertsStateArgs, SetAlertsStateAction } from '@/generated/graphql';
import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { SetAlertsStateUpdateCacheFn } from '@/app/_features/alerts-actions/_common/stores/_common/types';

export class DismissAlertsDataStore extends GraphqlBaseDataStore<Mutation, MutationSetAlertsStateArgs> {
	constructor() {
		super();

		makeObservable(this, {
			dismiss: action,
		});
	}

	/**
	 * @param {string} fragment - IMPORTANT: fragment name should equal DismissAlertsFragment. Should be fragment of Alert.
	 */
	dismiss = async (dismissedAlertIds: string[], fragment: DocumentNode, updateCache?: SetAlertsStateUpdateCacheFn) => {
		const variables = {
			action: SetAlertsStateAction.Dismiss,
			alertIds: dismissedAlertIds,
			tenantId: this.authStore.currentTenantId,
		};

		const mutation = gql`
			mutation DismissAlerts($tenantId: ID!, $alertIds: [String!]!, $action: SetAlertsStateAction!) {
				setAlertsState(tenantId: $tenantId, alertIds: $alertIds, action: $action) {
					... on Alerts {
						alerts {
							...DismissAlertsFragment
						}
					}
					... on Error {
						message
						code
						correlationId
					}
				}
			}
			${fragment}
		`;

		const response = await this.mutate<Mutation, MutationSetAlertsStateArgs>({
			mutation,
			variables,
			update: updateCache,
		});

		return response;
	};
}
