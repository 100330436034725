import { MitreBadgeIcon } from '@/app/_common/_components/mitre-attack-badge/mitre-badge-icon';
import { MitreAttackIconType } from '@/app/_common/_components/mitre-attack-badge/_constants/mitre-attack-icon-type';
import { getMitreAttackColor } from '@/app/_common/_components/mitre-attack-badge/get-mitre-attack-color';
import { getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';

export const MitreAttackSelectOption = ({ tactic }: { tactic: string }) => {
	const mitreText = getMitreAttackBadgeText(tactic);
	const color = getMitreAttackColor(mitreText);

	return (
		<>
			<MitreBadgeIcon icon={MitreAttackIconType.TACTIC} color={color} />
			<div>{mitreText}</div>
		</>
	);
};
