import moment from 'moment';

import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

import { AlertStateOptions, DATE_TIME_FORMAT } from '@/app/_common/constants';
import { AlertStateInfoDetails, AlertStateData } from '@/app/_common/types';

export const getAssignedAlertStateInfo = (state: AlertStateData, label?: string, investigationSummaryId?: string): AlertStateInfoDetails => {
	return {
		state: AlertStateOptions.Assigned,
		label: label ?? i18n.t('summary.states.noInvestigationName', { ns: Namespaces.DetailsView }),
		investigationId: investigationSummaryId,
		tooltipInfo: i18n.t('summary.states.tooltip.assigned', {
			ns: Namespaces.DetailsView,
			user: state?.requesterUser.upn,
			timestamp: moment(state?.timestamp).parseZone().format(DATE_TIME_FORMAT),
		}),
	};
};
