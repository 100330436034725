import { DetectedByTypes } from '@/app/_common/constants';

import { ReactComponent as AIEngineIcon } from '@/assets/images/ai-engine.svg';
import { ReactComponent as BatchEngineIcon } from '@/assets/images/batch-engine.svg';
import { ReactComponent as VendorIcon } from '@/assets/images/vendor.svg';

export const getDetectedByIcon = (eventSource?: string | null): JSX.Element | null => {
	if (eventSource === DetectedByTypes.AIEngine) {
		return <AIEngineIcon />;
	}

	if (eventSource === DetectedByTypes.BatchEngine) {
		return <BatchEngineIcon />;
	}

	if (eventSource && eventSource !== DetectedByTypes.AIEngine && eventSource !== DetectedByTypes.BatchEngine) {
		return <VendorIcon />;
	}

	return null;
};
