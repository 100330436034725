import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { InfoTooltip } from '@/app/_common/_components';

import styles from './tile.module.scss';

interface TileProps {
	Icon: React.ElementType;
	title: string;
	path: string;
	display?: boolean;
	inactive?: boolean;
	tooltipMessage?: string;
	infoTooltipIconText?: string;
}

export const Tile: FC<TileProps> = ({ title, Icon, path, display = true, inactive = false, tooltipMessage = '', infoTooltipIconText }) => {
	const { search } = useLocation();

	if (!display) {
		return null;
	}

	return (
		<Link to={{ pathname: path, search }} className={styles.link}>
			<div title={tooltipMessage} className={classNames(styles.container, { [styles.inactive]: inactive })}>
				{infoTooltipIconText && (
					<InfoTooltip id={title} description={<p>{infoTooltipIconText}</p>} className={styles.infoTooltipIcon} place="right" />
				)}
				<Icon className={styles.icon} />
				<p className={styles.title}>{title}</p>
			</div>
		</Link>
	);
};
