import { FC, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { provider, useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { Breadcrumb, BreadcrumbLinkMouseEvent } from '@progress/kendo-react-layout';

import { Route, Routes } from '@/app/_common/navigation/Routes';
import { getCorrespondingSubpath, matchRoutes } from '@/app/_common/utils';
import { BreadcrumbListItem } from './breadcrumb-list-item';
import styles from './breadcrumbs.module.scss';
import { BreadcrumbsViewStore } from './breadcrumbs.view-store';
import { BreadcrumbsDataStore } from './breadcrumbs.data-store';
import { generateQueryIDs } from './_utils';

export const Breadcrumbs: FC = provider(
	BreadcrumbsDataStore,
	BreadcrumbsViewStore,
)(
	observer(() => {
		const location = useLocation();
		const navigate = useNavigate();
		const breadcrumbsViewStore = useInstance(BreadcrumbsViewStore);
		const currentRoutes = matchRoutes(location.pathname, Routes);

		useEffect(() => {
			fetchRouteIDs();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [breadcrumbsViewStore, location]);

		const fetchRouteIDs = () => {
			if (currentRoutes?.length > 0) {
				const lastBreadcrumbRoute = currentRoutes[currentRoutes.length - 1];
				if (lastBreadcrumbRoute.customRouteBreadcrumb) {
					const queryIDs = generateQueryIDs(lastBreadcrumbRoute.path, location?.pathname);
					breadcrumbsViewStore.read(lastBreadcrumbRoute?.customRouteBreadcrumb.query, queryIDs);
				}
			}
		};

		const handleItemClick = useCallback(
			(clicked: BreadcrumbLinkMouseEvent) => {
				const currentRoute = currentRoutes?.find((route) => route.key === clicked.id);

				if (currentRoute) {
					let correspondingSubpath = getCorrespondingSubpath(currentRoute.path, location.pathname);

					if (!correspondingSubpath.length) {
						return;
					}

					if (correspondingSubpath.length > 1 && !correspondingSubpath.startsWith('/')) {
						correspondingSubpath = `/${correspondingSubpath}`;
					}

					if (correspondingSubpath !== location.pathname) {
						navigate(correspondingSubpath);
					}
				}
			},
			[currentRoutes, location, navigate],
		);

		const createBreadcrumb = useCallback(
			({ customRouteBreadcrumb, ...route }: Route) => {
				let customBreadcrumbText = undefined;
				if (customRouteBreadcrumb) {
					customBreadcrumbText = breadcrumbsViewStore.getBreadcrumbName(customRouteBreadcrumb);
				}
				return {
					id: route.key,
					text: customBreadcrumbText || route.displayName,
				};
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[breadcrumbsViewStore.loading, breadcrumbsViewStore.data],
		);

		const getBreadcrumbsForMatch = (currentRoutes?: Route[]) => {
			if (!currentRoutes || currentRoutes.length === 0) {
				return [];
			}

			return currentRoutes.map(createBreadcrumb);
		};

		const breadcrumbs = getBreadcrumbsForMatch(currentRoutes);

		return <Breadcrumb data={breadcrumbs} className={styles.breadcrumbs} breadcrumbListItem={BreadcrumbListItem} onItemSelect={handleItemClick} />;
	}),
);
