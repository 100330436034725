export enum QUERY_OPERATOR {
	PROJECT = 'project',
	WHERE = 'where',
	FILTER = 'filter',
	LIMIT = 'limit',
	SUMMARIZE = 'summarize',
	MAKE_SERIES = 'make-series',
	MV_EXPAND = 'mv-expand',
	TO = 'to',
	FROM = 'from',
	STEP = 'step',
	ON = 'on',
	SORT_BY = 'sort by',
	ORDER_BY = 'order by',
	PROJECT_AWAY = 'project-away',
}

export enum QUERY_FUNCTION {
	BETWEEN = 'between',
	DATETIME = 'datetime',
	TODATETIME = 'todatetime',
	AGO = 'ago',
	COUNT = 'count',
	NOW = 'now',
	TYPEOF = 'typeof',
	DOUBLE = 'double',
}

export enum QUERY_PUNCTUATION {
	PIPELINE = '|',
	MORE_OR_EQUAL = '>=',
	MORE = '>',
	LESS_OR_EQUAL = '<=',
	LESS = '<',
	ASSIGN = '=',
	ROUND_BRACKET_OPEN = '(',
	ROUND_BRACKET_CLOSE = ')',
	SQUARE_BRACKET_OPEN = '[',
	SQUARE_BRACKET_CLOSE = ']',
	RANGE = '..',
	LINE_BREAK = '\n',
	COMMENT_START = '//',
	COMMA = ',',
	DOUBLE_QUOTES = '"',
}

export enum QUERY_LITERAL {
	STRING_OPEN = '"',
	STRING_CLOSE = '"',
}
