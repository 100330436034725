import { useRef } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { QueryResultResultsTableViewStore, QueryResultsTableInvestigationDetails } from '@/app/investigation-details/_common/stores';
import { QueryResultsTableModule } from '@/app/_common/_components/query-results/query-results-table/query-results-table-module';
import { QueryResultsTableHeader } from '@/app/_common/_components/query-results/query-results-table/components';
import { AlertsDetails } from '@/app/_common/_components/details-view/alerts-details';
import { QueryResultsDetailsViewStore } from '@/app/_common/_components/details-view/stores';
import { LoadingSpinner } from '@/app/_common/_components';
import { ContextMenuContentKey } from '@/app/_common/_components/query-results/query-results-table/types';

import styles from './query-result-table.module.scss';

export const QueryResultTableContainer = observer(() => {
	const resultsTableViewStore = useInstance(QueryResultResultsTableViewStore);
	const queryResultsStore = useInstance(QueryResultsTableInvestigationDetails);
	const detailsStore = useInstance(QueryResultsDetailsViewStore);
	const alertsDetailsRef = useRef<HTMLDivElement | null>(null);
	const isAlertSelected = Boolean(detailsStore.selectedAlert);

	const { results, loading } = resultsTableViewStore;

	if (loading) {
		return (
			<div className={styles.loading}>
				<LoadingSpinner />;
			</div>
		);
	}

	return (
		<>
			<div className={styles.table}>
				<QueryResultsTableHeader className={styles.header} hideEmptyButtonVisible={false} store={queryResultsStore} />
				<QueryResultsTableModule
					store={queryResultsStore}
					totalQueryResultsCount={results.length}
					contextMenuContentKey={ContextMenuContentKey.InvestigationQueryResults}
					hideRowActions
					disableLookup
					dataGridClassName={styles.tableBody}
					itemDetailsId={detailsStore.selectedAlertId}
				/>
			</div>
			{isAlertSelected && (
				<div className={styles.alertDetailsContainer} ref={alertsDetailsRef}>
					<AlertsDetails alertDetailsViewStore={detailsStore} />
				</div>
			)}
		</>
	);
});

QueryResultTableContainer.displayName = 'QueryResultTableContainer';
