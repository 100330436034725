import { EditorAction, CustomEditor } from '@/app/_common/_components/rich-text-editor';
import { FormattingType } from '@/app/_common/_components/rich-text-editor/_common/constant';
import { toggleMarkActionButton } from '@/app/_common/_components/rich-text-editor/_common/utils/toggle-mark-action-button';
import { toggleCodeActionButton } from '@/app/_common/_components/rich-text-editor/_common/utils/toggle-code-action-button';
import { toggleBlockActionButton } from '@/app/_common/_components/rich-text-editor/_common/utils/toggle-block-action-button';

export const toggleActionButton = (editor: CustomEditor, format: FormattingType, action: EditorAction) => {
	switch (action) {
		case EditorAction.Block: {
			return toggleBlockActionButton(editor, format);
		}
		case EditorAction.Code: {
			return toggleCodeActionButton(editor, format);
		}
		default:
			return toggleMarkActionButton(editor, format);
	}
};
