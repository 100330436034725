export enum AlertsHeaderFilter {
	Selected = 'Selected',
	Unassigned = 'Unassigned',
	Assigned = 'Assigned',
	Dismissed = 'Dismissed',
}

export enum AlertsDetailsActions {
	AssignOrCreateInvestigation = 'assignOrCreateInvestigation',
	AssignOrCreateSelectedInvestigation = 'assignOrCreateSelectedInvestigation',
	Dismiss = 'dismiss',
	DismissSelected = 'dismissSelected',
	Undismiss = 'undismiss',
	UndismissSelected = 'undismissSelected',
}

export type ACTIONS_STRINGS = keyof typeof AlertsDetailsActions;
