import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { ReactComponent as AssignmentAddIcon } from '@/assets/images/assign-alert-icon.svg';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { Namespaces } from '@/translations/namespaces';
import { buttonize } from '@/app/_common/utils';
import { AlertsKeyPrefix } from '@/app/_common/constants';
import { AdvancedQueryResultsTableViewStore } from '@/app/advanced-query/_common/stores';
import { AssignQueryResultsToInvestigationDialogViewStore } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/assign-query-results-to-investigation-dialog/assign-query-results-to-investigation-dialog.view-store';
import { getDetailsViewRowId } from '@/app/_common/_components/details-view/utils';

import styles from './assign-to-investigation-action.module.scss';

interface AssignToInvestigationActionProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any; // as context menu is reused on many pages, technically data of any type can be passed here
	closeContextMenu?: () => void;
	className?: string;
}

export const AssignToInvestigationAction = observer(({ closeContextMenu, className = '', item }: AssignToInvestigationActionProps) => {
	const assignToInvestigationDialogViewStore = useInstance(AssignQueryResultsToInvestigationDialogViewStore);
	const { selectedResultsIds = [], selectedItems, setQueryResults } = useInstance(AdvancedQueryResultsTableViewStore);
	const { setItems } = assignToInvestigationDialogViewStore;
	const { t } = useTranslation(Namespaces.AlertsDashboard, { keyPrefix: AlertsKeyPrefix.GridContextMenu });

	if (!item) {
		return null;
	}

	const itemId = getDetailsViewRowId(item);
	const selectedCount = selectedResultsIds.length;
	const isCurrentIdSelected = selectedResultsIds.includes(itemId);

	const openAssignToInvestigationDialog = useCallback(() => {
		const results = isCurrentIdSelected ? selectedItems : item;
		setQueryResults(results);
		setItems(results);
		closeContextMenu?.();
	}, [setItems, setQueryResults, isCurrentIdSelected, selectedItems, item, closeContextMenu]);

	const count = isCurrentIdSelected ? selectedCount : 0;

	return (
		<div className={classNames(styles.container, className)} {...buttonize(openAssignToInvestigationDialog)}>
			<AssignmentAddIcon className={styles.icon} />
			<div>
				{t('assignToInvestigation')} {Boolean(count) && `(${count})`}
			</div>
		</div>
	);
});
