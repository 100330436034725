import { DetailsViewItem, DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { getDetailsViewTypename, getEventDetailsViewItemTitle } from '@/app/_common/_components/details-view/utils';
import { DetailsViewItemPropertiesPaths } from '@/app/_common/_components/details-view/types/details-view-item-properties-paths';

const TITLE_FIELD = DetailsViewItemPropertiesPaths.Title;
const NAME_FIELD = DetailsViewItemPropertiesPaths.Name;
const SIGNATURE_FIELD = DetailsViewItemPropertiesPaths.Signature;

export const getDetailsViewItemTitle = (item: DetailsViewItem): string => {
	if (!item) {
		return '';
	}

	try {
		const typename = getDetailsViewTypename(item);
		if (typename === DetailsViewTypename.EVENT) {
			return getEventDetailsViewItemTitle(item);
		}

		if (SIGNATURE_FIELD in item) {
			return item[SIGNATURE_FIELD];
		}

		if (NAME_FIELD in item) {
			return item[NAME_FIELD];
		}

		return item[TITLE_FIELD];
	} catch (error) {
		return '';
	}
};
