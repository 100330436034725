import { useLocation } from 'react-router-dom';

import { IntegrationType } from '@/generated/graphql';

import { RootPaths } from '../../navigation';

export const useIntegrationTypeFromLocationPathname = () => {
	const { pathname } = useLocation();

	let integrationType: IntegrationType | undefined;

	if (pathname.includes(RootPaths.RESPONSE_INTEGRATIONS)) {
		integrationType = IntegrationType.Response;
	}

	if (pathname.includes(RootPaths.TELEMETRY_INTEGRATIONS)) {
		integrationType = IntegrationType.Telemetry;
	}

	return integrationType;
};
