import { ComponentType } from 'react';

import { FilterDescriptor } from '@progress/kendo-data-query';
import { NotificationProps as KendoNotificationProps } from '@progress/kendo-react-notification/dist/npm/Notification';

import { DateTimePickerOptionKeys, FilterLogic } from '@/app/_common/constants';
import { IntegrationEdge, Integration } from '@/generated/graphql';
import { RootPaths } from '@/app/_common/navigation';
import { FeaturesFlag } from '@/app/_common/interfaces';
import { TelemetryIntegrationType } from '@/app/_features/integration/_common/types';
import { ResultsDataItem } from '@/app/_common/types/adx';

export interface KeyValue<ValueType> {
	[name: string]: ValueType;
}

export type DataGridSelectedValue = boolean | number[];

export type DataGridExpendedValue = boolean | undefined;

export type DataGridDisabledValue = boolean;

export interface DataGridSelected {
	[id: string]: DataGridSelectedValue;
}

export interface DataGridDisabled {
	[id: string]: DataGridDisabledValue;
}

export type DataGridDataItem<T> = T & {
	selected?: DataGridSelectedValue;
	expended?: DataGridExpendedValue;
	disabled?: DataGridDisabledValue;
	loading?: boolean;
};
export interface DataGridPage {
	skip: number;
	take: number;
}

export type TimeRangeFilterOptionValue = {
	from?: string;
	to?: string;
};

export type TimeRangeFilterOption = {
	key: DateTimePickerOptionKeys;
	value?: TimeRangeFilterOptionValue;
	isTemporal?: boolean;
};

export interface TimeRangeHistoryFilterOption extends Required<TimeRangeFilterOption> {
	value: Required<TimeRangeFilterOptionValue>;
}

/* FILTERS */
export interface FilterDescriptorWithId extends FilterDescriptor {
	id?: string;
}

export interface CompositeFilterDescriptorWithId {
	id: string;
	logic: FilterLogic;
	filters: Array<FilterDescriptorWithId | CompositeFilterDescriptorWithId>;
}

export type FilterValue = string | number | boolean | null | undefined;

export type Filters = Array<FilterValue>;

export type FilterOptionItem = {
	label: string;
	value: FilterValue;
	counter?: number;
	special?: boolean;
	bulletsConfig?: string[];
	icon?: JSX.Element;
	field?: string;
};

export type FilterOption = FilterOptionItem | string;

/* ------- */

export interface CheckboxTarget extends EventTarget {
	checked: boolean;
}
export interface WidthsState {
	[key: string]: string;
}

/* ------ */

/* Expanded */
export const enum ExpandField {
	Expanded = 'expanded',
}

/* Integrations */
export type ExtendedIntegration = Integration & { telemetryIntegrationType?: TelemetryIntegrationType };
export type ExtendedIntegrationEdge = IntegrationEdge & { node: ExtendedIntegration };
export type IntegrationsGridEdge = DataGridDataItem<ExtendedIntegrationEdge>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ArbitraryFunction = (...args: any[]) => any;

/* Advanced Query Results */
export type ResultItemGridEdge = DataGridDataItem<ResultsDataItem>;

export interface ContextMenuComponentProps {
	closeContextMenu?: () => void;
	customValue?: string | number;
}

// Popup Offset
export interface PopupOffset {
	left: number;
	top: number;
}

// Telemetry Dashboard
export interface TelemetryDashboardDataGridItem {
	last_timestamp: string;
	origin: string;
	product: string;
	vendor: string;
}

/* Threat Intelligence */
export interface ThreatIntelligenceHit {
	saas_references?: string[];
	identifier?: string;
	saas_title?: string;
	saas_tags?: string[];
	updated_at?: string;
	created_at?: string;
	saas_comment?: string;
	misp_hit?: boolean;
	protocol_restriction?: string;
	identifier_type?: string;
	port_restriction?: string;
	killchain_state?: string;
}

export interface ThreatIntelligenceHitWithId extends ThreatIntelligenceHit {
	id: string;
}

export interface IOCResponseFile {
	result_karte: {
		version: string;
		labels: Record<string, string>;
	};
	sha?: string;
	misp_result: {
		misp_metadata: {
			unique_tags: string[];
		};
		misp_checks: Record<string, ThreatIntelligenceHit[]>;
	};
}

export type ThreatIntelligenceMatches = Record<string, ThreatIntelligenceHitWithId[]>;

export interface ThreatIntelligenceDescription {
	title: string;
	description: string;
}

export interface TelemetryHealthPieChartUnformattedResult {
	list_: string;
	count_: number;
}

export interface TelemetryHealthPieChartFormattedResult {
	status: string;
	percentage: number;
}

/* NOTIFICATIONS */
export enum NotificationLook {
	Standard = 'standard',
	Filled = 'filled',
	Outlined = 'outlined',
}

interface NotificationUrl {
	url: string;
	text: string;
}

export interface Notification extends KendoNotificationProps {
	id: string;
	look: NotificationLook;
	title?: string;
	content?: string;
	link?: NotificationUrl;
	onClose?: () => void;
}

export type NotificationMessage = Pick<Notification, 'title' | 'content' | 'link'>;

/* FETCH CLIENT */
export enum FetchBaseErrorKey {
	Default = 'default',
	AvailableFields = 'availableFields',
	AdvancedQueryResults = 'advancedQueryResults',
	AdvancedQueryChart = 'advancedQueryChart',
	AssignQueryResult = 'assignQueryResult',
}

export enum AdvancedQueryMainTabKeyPrefix {
	Options = 'tabs.advancedQuery.options',
}

/* TILES */

export interface Tile {
	title: string;
	Icon: ComponentType;
	path: RootPaths | '';
	feature?: FeaturesFlag;
	display: boolean;
	inactive?: boolean;
	tooltipMessage?: string;
	infoTooltipIconText?: string;
}

/* STORAGE VERSIONING/MIGRATION */
export type StorageType = 'localStorage' | 'sessionStorage';
export type StorageVersion = `v${number}`;
