import { useTranslation } from 'react-i18next';

import { stringValidator } from '@/app/_common/utils/validators/string-validator';
import { Namespaces } from '@/translations/namespaces';
import { NameValidationRegEx } from '@/app/_common/constants';
import { lengthValidator } from '@/app/_common/utils';
import {
	DescriptionField,
	IntegrationDetailsFormData,
	IntegrationDetailsFormValidator,
	NameField,
} from '@/app/_common/_components/integration-details-form/types';
import { NAME_MAX_LENGTH, NAME_MIN_LENGTH, TEXT_MAX_LENGTH } from '@/app/_common/_components/integration-details-form/constants';

export const useIntegrationDetailsFormValidator = (): IntegrationDetailsFormValidator => {
	const { t } = useTranslation();

	return (data: IntegrationDetailsFormData) => {
		const nameValue = data[NameField.Name];
		const descriptionValue = data[DescriptionField.Name];
		const hasNameValue = nameValue && stringValidator(nameValue);

		const messages: { [key: string]: string } = {};

		if (!hasNameValue) {
			messages[NameField.Name] = t('validateMessage', { ns: Namespaces.CreateIntegration, fieldName: 'Name' });
		}

		if (hasNameValue && !NameValidationRegEx.test(nameValue)) {
			messages[NameField.Name] = t('invalidName', { ns: Namespaces.CreateIntegration });
		}

		const isNameTooLong = hasNameValue && nameValue.length > NAME_MAX_LENGTH;
		const isNameTooShort = hasNameValue && nameValue.length < NAME_MIN_LENGTH;

		if (isNameTooLong || isNameTooShort) {
			messages[NameField.Name] = t('validateNameMessage', {
				ns: Namespaces.CreateIntegration,
				minLength: NAME_MIN_LENGTH,
				maxLength: NAME_MAX_LENGTH,
			});
		}

		if (descriptionValue && !lengthValidator(descriptionValue, TEXT_MAX_LENGTH)) {
			messages[DescriptionField.Name] = t('validateDescriptionMessage', { ns: Namespaces.CreateIntegration, maxLength: TEXT_MAX_LENGTH });
		}

		if (Object.keys(messages)?.length === 0) {
			return;
		}

		return messages;
	};
};
