import { reaction } from 'mobx';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { QueryResultsTableViewStore } from '@/app/_common/_components/query-results/query-results-table/stores';
import { QueryResultResultsTableViewStore } from '@/app/investigation-details/_common/stores';

export class QueryResultsTableInvestigationDetails extends QueryResultsTableViewStore {
	private queryResultsResultsTableViewStore = injectInterface(this, QueryResultResultsTableViewStore);

	queryResultsDisposer = reaction(
		() => this.queryResultsResultsTableViewStore.results,
		(data) => {
			this.setQueryResults(data);
		},
	);

	dispose() {
		this.queryResultsDisposer();
	}
}
