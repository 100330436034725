/* eslint-disable @typescript-eslint/no-explicit-any */
import { Editor, Element, Transforms, Range, Location } from 'slate';
import { EditorType, EditorAction, UnknownObject, getMatchNode, CustomEditor } from '@/app/_common/_components/rich-text-editor';

export const useTable = (editor: CustomEditor) => {
	const createRow = (cellText: string) => {
		const newRow = Array.from(cellText, (value) => createTableCell(value));
		return {
			type: EditorType.TableRow,
			children: newRow,
		};
	};

	const createTableCell = (text: string) => {
		return {
			type: EditorType.TableCell,
			children: [{ type: EditorType.Paragraph, children: [{ text }] }],
		};
	};

	const createTableNode = (cellText: any) => {
		const tableChildren = Array.from(cellText, (value: string) => createRow(value));
		return { type: EditorType.Table, children: tableChildren };
	};

	const getExistingText = (children: any) => {
		return Array.from(children, (rows: UnknownObject) => Array.from(rows.children, (arr: UnknownObject) => arr.children[0]?.children[0]?.text || ''));
	};

	const insertTable = (rows: number, columns: number) => {
		const [tableNode] = getMatchNode(editor, EditorType.Table, undefined, 'highest');

		if (tableNode || !rows || !columns) {
			return;
		}

		const cellText = Array.from({ length: rows }, () => Array.from({ length: columns }, () => ''));
		const newTable = createTableNode(cellText);

		Transforms.insertNodes(editor, newTable, {
			mode: 'highest',
		});
		Transforms.insertNodes(editor, { type: EditorType.Paragraph, children: [{ text: '' }] }, { mode: 'highest' });
	};

	const insertCells = (tableNode: UnknownObject, path: Location, action: string) => {
		let existingText = getExistingText(tableNode.children);
		const columns = existingText[0].length;

		if (action === EditorAction.Row) {
			existingText.push(Array(columns).fill(''));
		} else {
			existingText = Array.from(existingText, (item) => {
				item.push('');
				return item;
			});
		}

		const newTable = createTableNode(existingText);
		Transforms.insertNodes(editor, newTable, {
			at: path,
			select: true,
		});
	};

	const removeTable = () => {
		Transforms.removeNodes(editor, {
			match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === EditorType.Table,
			mode: 'highest',
		});
	};

	const removeCells = (tableNode: UnknownObject, path: Location, action: string) => {
		let existingText = getExistingText(tableNode.children);

		if (action === EditorAction.Row) {
			existingText.pop();
		}
		if (action === EditorAction.Column) {
			existingText = Array.from(existingText, (item) => {
				if (item.length > 1) {
					item.pop();
				}
				return item;
			});
		}
		const newTable = createTableNode(existingText);
		Transforms.insertNodes(editor, newTable, {
			at: path,
			select: true,
		});
	};

	const modifyTable = (editor: CustomEditor, action: (oldTable: UnknownObject, path: Location) => void, nodeHasChildren = false) => {
		const { selection } = editor;
		if (!!selection && Range.isCollapsed(selection)) {
			const [tableNode] = getMatchNode(editor, EditorType.Table) as any;

			const shouldPerformAction = nodeHasChildren ? tableNode && tableNode[0].children.length > 1 : tableNode;

			if (shouldPerformAction) {
				const [oldTable, path] = tableNode;
				removeTable();
				action(oldTable, path);
			}
		}
	};

	const insertRow = () => {
		modifyTable(editor, (oldTable, path) => {
			insertCells(oldTable, path, EditorAction.Row);
		});
	};

	const insertColumn = () => {
		modifyTable(editor, (oldTable, path) => {
			insertCells(oldTable, path, EditorAction.Column);
		});
	};

	const removeColumn = () => {
		modifyTable(editor, (oldTable, path) => {
			removeCells(oldTable, path, EditorAction.Column);
		});
	};

	const removeRow = () => {
		modifyTable(
			editor,
			(oldTable, path) => {
				removeCells(oldTable, path, EditorAction.Row);
			},
			true,
		);
	};

	return { insertTable, removeTable, insertRow, insertColumn, removeCells, removeRow, removeColumn };
};
