import { FC, MutableRefObject, useCallback, useRef, useState } from 'react';
import styles from './alerts-details-button.module.scss';
import { Popup } from '@progress/kendo-react-popup';
import classNames from 'classnames';
import { useOutsideClick } from '@/app/_common/hooks';
import { Button } from '@/app/_common/_components';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '@/translations/namespaces';
import { AlertConfidence, AlertDetailsKeyPrefix } from '@/app/_common/constants';
import { Severity } from '@/generated/graphql';
import { Description } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components';
import { AlertDetailsConfidence } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alert-details-confidence';
import { AlertDetailsSeverity } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/alert-details-severity';
import { buttonize } from '@/app/_common/utils';

export interface AlertsDetailsButtonProps {
	severity?: Severity;
	confidence?: AlertConfidence;
	description?: string;
	link?: string;
	className?: string;
}

export const AlertsDetailsButton: FC<AlertsDetailsButtonProps> = ({ severity, confidence, description, link, className }) => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	const { t } = useTranslation([Namespaces.DetailsView], { keyPrefix: AlertDetailsKeyPrefix.DetailsButton });

	const buttonContainerRef = useRef<HTMLDivElement | null>(null);
	const { popupRef, anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		showDropdown,
		useCallback(() => setShowDropdown(false), []),
	);

	const toggleDropdown = () => {
		setShowDropdown((showDropdown) => !showDropdown);
	};

	if (!severity && !confidence && !description && !link) {
		return null;
	}

	return (
		<div className={classNames(styles.alertsDetailsButton, className)} ref={buttonContainerRef}>
			<div
				className={classNames(styles.button, { [styles.openedPopup]: showDropdown })}
				ref={anchorRef}
				{...buttonize<HTMLDivElement>(toggleDropdown)}
			>
				<Button fillMode="outline">{t('details')}</Button>
			</div>

			<Popup
				anchor={anchorRef.current}
				show={showDropdown}
				popupClass={classNames(styles.popup)}
				appendTo={(buttonContainerRef as MutableRefObject<HTMLDivElement>)?.current}
			>
				<div className={styles.popupContent} ref={popupRef}>
					{(severity || confidence) && (
						<div className={styles.levels}>
							{severity && <AlertDetailsSeverity severity={severity} />}
							{confidence && <AlertDetailsConfidence confidence={confidence} />}
						</div>
					)}
					{description && <Description description={description} />}
					{link ? (
						<div className={styles.reference}>
							<h5>{t('reference')}</h5>
							<a href={link}>{link}</a>
						</div>
					) : null}
				</div>
			</Popup>
		</div>
	);
};
