import { useTranslation } from 'react-i18next';
import { AlertVendorKey } from '@/app/_common/_components/details-view/types';
import { ExpansionPanelWrapper } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components';
import { Namespaces } from '@/translations/namespaces';

import styles from './alert-recommendation.module.scss';

interface AlertRecommendationProps {
	recommendation?: string | null;
	vendorKey: AlertVendorKey;
}

const ALERT_RECOMMENDATION_SUPPORTED_VENDOR_KEYS = [AlertVendorKey.MICROSOFT_DEFENDER];

export const AlertRecommendation = ({ recommendation, vendorKey }: AlertRecommendationProps) => {
	const { t } = useTranslation(Namespaces.DetailsView);

	if (!recommendation || !ALERT_RECOMMENDATION_SUPPORTED_VENDOR_KEYS.includes(vendorKey)) {
		return null;
	}

	return (
		<div className={styles.container}>
			<ExpansionPanelWrapper title={t('alertRecommendation.expansionPanel.title')} expanded={true}>
				<div className={styles.recommendationWrapper}>
					{recommendation.split('\n').map((str) => (
						<p key={str} className={styles.text}>
							{str}
						</p>
					))}
				</div>
			</ExpansionPanelWrapper>
		</div>
	);
};
