import { useId } from 'react';

import classNames from 'classnames';

import ContentCopy from '@mui/icons-material/ContentCopy';

import { Tooltip } from '@/app/_common/_components';

import styles from './copyable-field.module.scss';

const TOOLTIP_DELAY_HIDE = 1000;

interface CopyableFieldProps {
	className?: string;
	value?: string | null;
	onClick?: (value?: string) => void;
	successTooltipText?: string;
}

export const CopyableField = ({ className, value, onClick, successTooltipText }: CopyableFieldProps) => {
	const tooltipId = useId();

	const handleFieldClick = () => {
		const clipboardValue = value ?? '';

		navigator.clipboard.writeText(clipboardValue).then(() => {
			if (onClick) {
				onClick();
			}
		});
	};

	if (!value) {
		return null;
	}

	return (
		<>
			<div
				className={classNames(className, styles.field)}
				data-for={tooltipId}
				data-tip={successTooltipText}
				data-event="click"
				data-event-off="click"
			>
				<div>{value}</div>
				<ContentCopy fontSize="small" className={styles.icon} />
			</div>
			<Tooltip
				tooltipId={tooltipId}
				place={'right'}
				effect={'solid'}
				afterShow={handleFieldClick}
				tooltipContent={successTooltipText}
				globalEventOff={'click'}
				delayHide={TOOLTIP_DELAY_HIDE}
			/>
		</>
	);
};
