import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ShareIcon from '@mui/icons-material/Share';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import { Namespaces } from '@/translations/namespaces';
import { Button, Tooltip } from '@/app/_common/_components';
import { buttonize } from '@/app/_common/utils';

import styles from './inline-actions.module.scss';

const TOOLTIP_ID = 'sharedWindowNoteTooltipId';

interface InlineActionsProps {
	deleteNote: () => void;
	closeNoteWindow: () => void;
	activateEditNoteMode: () => void;
	shareNoteWindow: () => void;
}

export const InlineActions: FC<InlineActionsProps> = ({ deleteNote, closeNoteWindow, shareNoteWindow, activateEditNoteMode }) => {
	const { t } = useTranslation(Namespaces.Notes);

	return (
		<div className={styles.inlineActions}>
			<div className={styles.icons}>
				<Tooltip
					tooltipId={TOOLTIP_ID}
					tooltipContent={t('shareNoteTooltip')}
					effect={'solid'}
					className={styles.tooltip}
					afterShow={shareNoteWindow}
				/>
				<div className={styles.icon} data-tip="tooltip" data-for={TOOLTIP_ID} data-event="click" data-event-off="click" data-delay-hide="1500">
					<ShareIcon />
				</div>
				<div className={styles.icon} {...buttonize(activateEditNoteMode)}>
					<EditIcon />
				</div>
				<div className={styles.icon} {...buttonize(deleteNote)}>
					<DeleteIcon />
				</div>
			</div>
			<Button fillMode="outline" onClick={closeNoteWindow}>
				{t('noteActions.close')} <CloseIcon />
			</Button>
		</div>
	);
};
