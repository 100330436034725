import { FC, useCallback, useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import _uniq from 'lodash/uniq';
import classNames from 'classnames';

import { Skeleton } from '@progress/kendo-react-indicators';

import { Namespaces } from '@/translations/namespaces';
import { Button, Dialog } from '@/app/_common/_components';
import { invokeConditionally } from '@/app/_common/utils';

import {
	AlertsActionDialogViewStore,
	AlertsDataGridViewStore,
	AlertActions,
	AlertExtended,
} from '@/app/_common/_components/alerts-action-dialog/_common';
import { DetailsViewItemData } from '@/app/_common/_components/details-view/types';
import { AlertsDataGrid } from '@/app/_features/alerts-actions/_components/alerts-data-grid/alerts-data-grid';

import styles from '@/app/_common/_components/alerts-action-dialog/alerts-action-dialog.module.scss';

export interface AlertsActionDialogProps {
	action: AlertActions;
	title?: string | ((alerts: AlertExtended[]) => string);
	description?: string | ((alerts: AlertExtended[]) => string);
	className?: string;
	isOpen: boolean;
	onClose: () => void;
	onSubmit?: () => void;
	alerts: DetailsViewItemData[];
	submitButtonText?: string;
	cancelButtonText?: string;
}

const DIALOG_WIDTH = 1256;

export const AlertsActionDialogComponent: FC<AlertsActionDialogProps> = observer(
	({ className, title, description, alerts, isOpen, onClose, onSubmit, cancelButtonText, submitButtonText, action }) => {
		const { t } = useTranslation([Namespaces.Common], { keyPrefix: 'components.alertsActionDialog' });
		const store = useInstance(AlertsActionDialogViewStore);
		const gridStore = useInstance(AlertsDataGridViewStore);

		useEffect(() => {
			const alertIds = _uniq(alerts.map((alert) => alert.id));
			const investigationIds = _uniq(alerts.map((alert) => alert.investigationSummary?.id).filter((id): id is string => typeof id === 'string'));

			store.setAction(action);
			store.read(alertIds, investigationIds);
		}, [store, alerts, action]);

		const handleClose = useCallback(() => {
			if (typeof onClose === 'function') {
				onClose();
			}
		}, [onClose]);

		const handleSubmit = useCallback(async () => {
			await store.submitAction(gridStore.selectedElementsIds);

			if (typeof onSubmit === 'function') {
				onSubmit();
			}

			handleClose();
		}, [handleClose, onSubmit, gridStore.selectedElementsIds, store]);

		if (!isOpen) {
			return null;
		}

		return (
			<Dialog
				className={classNames(className, styles.dialog)}
				title={invokeConditionally(title, gridStore.data) || t('title')}
				width={DIALOG_WIDTH}
				footerDivider={false}
				onClose={handleClose}
				onSubmit={handleSubmit}
				actions={
					<>
						<Button className={styles.button} onClick={handleClose} fillMode="outline">
							{cancelButtonText || t('buttons.cancel')}
						</Button>
						<Button className={styles.button} onClick={handleSubmit} disabled={gridStore.noneSelected} loading={store.submitLoading}>
							{submitButtonText || t('buttons.submit')}
						</Button>
					</>
				}
			>
				{description && (
					<div className={styles.description}>
						<p>{invokeConditionally(description, gridStore.data)}</p>
					</div>
				)}
				{store.loading ? <Skeleton shape="rectangle" className={styles.loading} /> : <AlertsDataGrid store={gridStore} />}
			</Dialog>
		);
	},
);
