import { PlaceholderColumnCell } from './_components/placeholder-column-cell';
import { PlaceholderColumnHeaderCell } from './_components/placeholder-column-header-cell';

export const PLACEHOLDER_COLUMN = {
	width: 'auto',
	cell: PlaceholderColumnCell,
	headerCell: PlaceholderColumnHeaderCell,
	resizable: false,
	sortable: false,
	filterable: false,
	reorderable: false,
	id: 'PLACEHOLDER_COLUMN',
};
