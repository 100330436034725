import { Severity } from '@/generated/graphql';
import { AlertConfidence } from '@/app/_common/constants';
import { MitreTacticsValue } from '@/app/_common/types';

export const SEVERITY_PRIORITY = [Severity.Critical, Severity.High, Severity.Medium, Severity.Low, Severity.Unknown];

export const CONFIDENCE_PRIORITY = [AlertConfidence.Maximum, AlertConfidence.High, AlertConfidence.Medium, AlertConfidence.Low];

export const CONFIDENCE_PRIORITY_WITH_UNKNOWN = [...CONFIDENCE_PRIORITY, AlertConfidence.Unknown]; // todo: temporary array, to be changed after XDR-8792 (changes of Unknown)

export const MITRE_TACTICS_PRIORITY = [
	MitreTacticsValue.IMPACT,
	MitreTacticsValue.EXFILTRATION,
	MitreTacticsValue.COMMAND_AND_CONTROL,
	MitreTacticsValue.COLLECTION,
	MitreTacticsValue.LATERAL_MOVEMENT,
	MitreTacticsValue.DISCOVERY,
	MitreTacticsValue.CREDENTIAL_ACCESS,
	MitreTacticsValue.DEFENSE_EVASION,
	MitreTacticsValue.PRIVILEGE_ESCALATION,
	MitreTacticsValue.PERSISTENCE,
	MitreTacticsValue.EXECUTION,
	MitreTacticsValue.INITIAL_ACCESS,
	MitreTacticsValue.RESOURCE_DEVELOPMENT,
	MitreTacticsValue.RECONNAISSANCE,
	MitreTacticsValue.PREATTACK,
	MitreTacticsValue.UNKNOWN,
];
