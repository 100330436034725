import { FC, ReactNode } from 'react';

import classNames from 'classnames';
import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Align } from '@progress/kendo-react-popup';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CellProps } from '@progress/kendo-react-data-tools';

import { HeaderCell } from '@/app/_common/_components/data-grid/_components/header-cell';
import { ActionsPopupContent } from '@/app/_common/_components/data-grid/_components/actions-header-cell/actions-popup-content';
import { dataHeaderCellZIndex } from '@/app/_common/constants';

import styles from './actions-header-cell.module.scss';

const DROPDOWN_ANCHOR_ALIGN: Align = {
	horizontal: 'right',
	vertical: 'bottom',
};

const DROPDOWN_POPUP_ALIGN: Align = {
	horizontal: 'right',
	vertical: 'top',
};

export interface ActionsHeaderItem {
	id: string;
	label: string;
	itemComponent?: ReactNode;
	onClick?: () => void;

	show?: boolean | ((dataItem?: CellProps['dataItem']) => boolean);
}

interface ActionsHeaderCellProps extends GridHeaderCellProps {
	className?: string;
	onClick?: () => void;
	items?: ActionsHeaderItem[];
	columnOptions?: Array<{ label: string; id: string; checked: boolean }>;
	onColumnOptionsChange?: (field: string) => void;
	onColumnsReset?: () => void;
	hideColumnsDisabled?: boolean;
}

export const ActionsHeaderCell: FC<ActionsHeaderCellProps> = ({
	items = [],
	className,
	columnOptions = [],
	onColumnsReset,
	onColumnOptionsChange,
	hideColumnsDisabled,
}) => {
	const cellClassName = classNames(styles.cell, className, styles.actionsHeaderContainer);
	const hasPopup = Boolean(items?.length) || Boolean(columnOptions?.length) || Boolean(onColumnsReset);

	return (
		<HeaderCell
			className={cellClassName}
			popupClassName={styles.popup}
			header={<MoreVertIcon />}
			dropdownAnchorAlign={DROPDOWN_ANCHOR_ALIGN}
			dropdownPopupAlign={DROPDOWN_POPUP_ALIGN}
			hasPopup={hasPopup}
			zIndex={dataHeaderCellZIndex}
		>
			<ActionsPopupContent
				items={items}
				columnOptions={columnOptions}
				onColumnOptionsChange={onColumnOptionsChange}
				onColumnsReset={onColumnsReset}
				hideColumnsDisabled={hideColumnsDisabled}
			/>
		</HeaderCell>
	);
};
