import { useCallback } from 'react';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Namespaces } from '@/translations/namespaces';
import { AlertsActionDialog } from '@/app/_features/alerts-actions/_components';
import { AlertsActionsViewStore, AlertsDataGridViewStore, filterSelectedAlerts } from '@/app/_features/alerts-actions/_common';
import { AlertsDataGrid } from '@/app/_features/alerts-actions/_components/alerts-data-grid/alerts-data-grid';

export const AlertsDismissDialog = observer(() => {
	const alertsActionsViewStore = useInstance(AlertsActionsViewStore);
	const alertsDataGridViewStore = useInstance(AlertsDataGridViewStore);
	const { data, isRowSelected } = alertsDataGridViewStore;

	const { t } = useTranslation([Namespaces.AlertsActionDialog]);

	const handleDialogClose = useCallback(() => alertsActionsViewStore.closeDialog(), [alertsActionsViewStore]);
	const handleSubmitButtonClick = useCallback(() => {
		alertsActionsViewStore.executeDismissAlerts(filterSelectedAlerts(data, isRowSelected));
	}, [alertsActionsViewStore, data, isRowSelected]);

	return (
		<AlertsActionDialog
			title={t('action.dismiss.title', { count: alertsDataGridViewStore.selectedCount })}
			description={t('action.dismiss.description', { count: alertsDataGridViewStore.selectedCount })}
			isOpen={true}
			onClose={handleDialogClose}
			onSubmit={handleSubmitButtonClick}
			submitButtonText={t('action.dismiss.buttons.submit')}
			cancelButtonText={t('action.dismiss.buttons.cancel')}
			disabled={alertsDataGridViewStore.selectedCount === 0}
			loading={alertsActionsViewStore.loading}
		>
			<AlertsDataGrid store={alertsDataGridViewStore} />
		</AlertsActionDialog>
	);
});
