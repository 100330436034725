import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import _get from 'lodash/get';

import { GridSortChangeEvent } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';

import { Column, DataGrid } from '@/app/_common/_components/data-grid/data-grid';
import { WidgetContainer } from '@/app/_common/_components/widget-container/widget-container';
import { HeaderSelectCell } from '@/app/_common/_components/data-grid/_components/header-select-cell';
import {
	DateTimeCell,
	ConfidenceCell,
	TrimTextCell,
	MultiValueCell,
	AlertStateCell,
	DataHeaderCell,
	SeverityCell,
} from '@/app/_common/_components/data-grid/_components';
import { DetectedByCell, MitreAttackCell } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/_components';
import { InvestigationSelectCell } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/_components/investigation-select-cell/investigation-select-cell';
import { AlertsAssignListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/alerts-assign-list.view-store';
import { ListHeader } from '@/app/_common/_components/list-header/list-header';
import { sortAlertFilters } from '@/app/_common/utils';
import { AlertPropertiesPaths, AlertHeadersTranslationKeys, AlertsKeyPrefix } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';
import { useRowRender } from '@/app/_common/_components/data-grid/hooks';

import styles from './alerts-assign-list.module.scss';

const DATA_ITEM_KEY = AlertPropertiesPaths.Id;
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

interface AlertsAssignListProps {
	store: AlertsAssignListViewStore;
}

export const AlertsAssignList = ({ store }: AlertsAssignListProps) => {
	const { isRowSelected } = store;
	const { t } = useTranslation([Namespaces.AlertsDashboard]);

	const handleSortChange = useCallback(
		(event: GridSortChangeEvent) => {
			store.setSort(event.sort);
		},
		[store],
	);

	const COLUMNS = useMemo<Column[]>(
		() => [
			{
				id: SELECTED_FIELD,
				width: '50px',
				// Wrapping with observer ensures proper rerendering!
				headerCell: observer((props) => {
					return <HeaderSelectCell mode={store.selectionHeaderMode} onChange={props.selectionChange} {...props} />;
				}),
				cell: InvestigationSelectCell,
			},
			{
				field: AlertPropertiesPaths.Timestamp,
				title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Timestamp]),
				cell: DateTimeCell,
				width: '170px',
				headerCell: (props) => <DataHeaderCell {...props} sortable={true} sort={store.sort} onSort={store.setSort} />,
				headerClassName: styles.headerCell,
			},
			{
				id: AlertPropertiesPaths.State,
				field: AlertPropertiesPaths.State,
				title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.State]),
				cell: observer((props) => {
					const alertState = _get(props.dataItem, AlertPropertiesPaths.State, '');
					return <AlertStateCell {...props} alertState={alertState} statePropertyPath={AlertPropertiesPaths.StateData} />;
				}),
				width: '110px',
				headerCell: (props) => (
					<DataHeaderCell
						{...props}
						sortable={true}
						sort={store.sort}
						onSort={store.setSort}
						onFilter={store.setGridHeaderFilter}
						filterOptions={store.getFilterOptions(AlertPropertiesPaths.State)}
						clearFilter={store.resetGridHeaderFilter}
						sortFilterOptions={sortAlertFilters}
						counter={store.getCounter(AlertPropertiesPaths.State)}
						filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.State)}
					/>
				),
				headerClassName: styles.headerCell,
				show: store.getFieldColumnState(AlertPropertiesPaths.State),
			},
			{
				field: AlertPropertiesPaths.Severity,
				title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Severity]),
				cell: SeverityCell,
				headerCell: observer((props) => (
					<DataHeaderCell
						{...props}
						counter={store.getCounter(AlertPropertiesPaths.Severity)}
						sortable={true}
						sort={store.sort}
						onSort={store.setSort}
						onFilter={store.setGridHeaderFilter}
						filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Severity)}
						filterOptions={store.getFilterOptions(AlertPropertiesPaths.Severity)}
						clearFilter={store.resetGridHeaderFilter}
						sortFilterOptions={sortAlertFilters}
					/>
				)),
				headerClassName: styles.headerCell,
			},
			{
				field: AlertPropertiesPaths.Confidence,
				title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Confidence]),
				cell: ConfidenceCell,
				headerCell: observer((props) => (
					<DataHeaderCell
						{...props}
						counter={store.getCounter(AlertPropertiesPaths.Confidence)}
						sortable={true}
						sort={store.sort}
						onSort={store.setSort}
						onFilter={store.setGridHeaderFilter}
						filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Confidence)}
						filterOptions={store.getFilterOptions(AlertPropertiesPaths.Confidence)}
						clearFilter={store.resetGridHeaderFilter}
						sortFilterOptions={sortAlertFilters}
					/>
				)),
				headerClassName: styles.headerCell,
			},
			{
				field: AlertPropertiesPaths.Title,
				title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Title]),
				cell: TrimTextCell,
				headerCell: observer((props) => (
					<DataHeaderCell
						{...props}
						counter={store.getCounter(AlertPropertiesPaths.Title)}
						sortable={true}
						sort={store.sort}
						onSort={store.setSort}
						onFilter={store.setGridHeaderFilter}
						filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Title)}
						filterOptions={store.getFilterOptions(AlertPropertiesPaths.Title)}
						clearFilter={store.resetGridHeaderFilter}
						sortFilterOptions={sortAlertFilters}
					/>
				)),
				headerClassName: styles.headerCell,
			},
			{
				field: AlertPropertiesPaths.Source,
				title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Source]),
				cell: MultiValueCell,
				headerCell: observer((props) => (
					<DataHeaderCell
						{...props}
						counter={store.getCounter(AlertPropertiesPaths.Source)}
						sortable={true}
						sort={store.sort}
						onSort={store.setSort}
						onFilter={store.setGridHeaderFilter}
						filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Source)}
						filterOptions={store.getFilterOptions(AlertPropertiesPaths.Source)}
						clearFilter={store.resetGridHeaderFilter}
						sortFilterOptions={sortAlertFilters}
					/>
				)),
				headerClassName: styles.headerCell,
			},
			{
				field: AlertPropertiesPaths.Destination,
				title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Destination]),
				cell: MultiValueCell,
				headerCell: observer((props) => (
					<DataHeaderCell
						{...props}
						counter={store.getCounter(AlertPropertiesPaths.Destination)}
						sortable={true}
						sort={store.sort}
						onSort={store.setSort}
						onFilter={store.setGridHeaderFilter}
						filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Destination)}
						filterOptions={store.getFilterOptions(AlertPropertiesPaths.Destination)}
						clearFilter={store.resetGridHeaderFilter}
						sortFilterOptions={sortAlertFilters}
					/>
				)),
				headerClassName: styles.headerCell,
			},
			{
				field: AlertPropertiesPaths.Mitre,
				title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.Mitre]),
				cell: MitreAttackCell,
				headerCell: observer((props) => (
					<DataHeaderCell
						{...props}
						counter={store.getCounter(AlertPropertiesPaths.Mitre)}
						sortable={true}
						sort={store.sort}
						onSort={store.setSort}
						onFilter={store.setGridHeaderFilter}
						filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.Mitre)}
						filterOptions={store.getFilterOptions(AlertPropertiesPaths.Mitre)}
						clearFilter={store.resetGridHeaderFilter}
						sortFilterOptions={sortAlertFilters}
					/>
				)),
				headerClassName: styles.headerCell,
			},
			{
				field: AlertPropertiesPaths.DetectedBy,
				title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.DetectedBy]),
				cell: DetectedByCell,
				headerCell: observer((props) => {
					return (
						<DataHeaderCell
							{...props}
							counter={store.getCounter(AlertPropertiesPaths.DetectedBy)}
							sortable={true}
							sort={store.sort}
							onSort={store.setSort}
							onFilter={store.setGridHeaderFilter}
							filter={store.getGridHeaderFilterValues(AlertPropertiesPaths.DetectedBy)}
							filterOptions={store.getAlertsDetectedByMainFilters(AlertPropertiesPaths.DetectedBy)}
							filterOptionsSubgroups={[store.getDetectedByVendorFilters(AlertPropertiesPaths.DetectedBy)].filter((subgroup) => subgroup.options)}
							clearFilter={store.resetGridHeaderFilter}
							sortFilterOptions={sortAlertFilters}
						/>
					);
				}),
				headerClassName: styles.headerCell,
			},
		],
		[store, t],
	);

	const rowRender = useRowRender({ isRowSelected, idGetter });

	return (
		<WidgetContainer
			className={styles.alerts}
			header={
				<ListHeader countLabel={t(`${AlertsKeyPrefix.AlertsGrid}.totalLabel`, { total: store.totalCount })} showFilters={false} title={'Alerts'} />
			}
		>
			<DataGrid
				gridProps={{
					data: store.data,
					dataItemKey: DATA_ITEM_KEY,
					fixedScroll: true,
					sort: store.sort,
					sortable: true,
					onHeaderSelectionChange: store.toggleSelectAllRows,
					onSortChange: handleSortChange,
					rowRender,
				}}
				columns={COLUMNS}
			/>
		</WidgetContainer>
	);
};
