import { action, makeObservable } from 'mobx';
import { WatchQueryFetchPolicy } from '@apollo/client';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { FiltersInput, Query } from '@/generated/graphql';
import { QueryInOrderDefinition, QueryInOrderDefinitionArgument } from '@/app/_common/types';

type QueryVariables = {
	tenantId: string;
	filtersInput?: FiltersInput;
};

export class MenuDataStore<QUERY_RESULT extends Query> extends GraphqlBaseDataStore<QUERY_RESULT, QueryVariables> {
	constructor() {
		super();
		makeObservable(this, {
			read: action,
		});
	}

	read(queryDefinitions: QueryInOrderDefinitionArgument[]) {
		const queries = queryDefinitions.map((queryDefinition) => {
			if (typeof queryDefinition === 'function') {
				return (data?: Query) => {
					const queryDef = queryDefinition(data);

					if (!queryDef) {
						return;
					}

					return this.getQueryOptions(queryDef, this.authStore.currentTenantId);
				};
			}

			return this.getQueryOptions(queryDefinition, this.authStore.currentTenantId);
		});

		this.queryInOrder(queries);
	}

	private getQueryOptions = (queryDefinition: QueryInOrderDefinition, tenantId: string) => {
		const fetchPolicies: { fetchPolicy: WatchQueryFetchPolicy; nextFetchPolicy?: WatchQueryFetchPolicy } = {
			fetchPolicy: 'no-cache',
		};

		const { query, filtersInput } = queryDefinition;

		const variables = {
			tenantId,
			filtersInput,
		};

		return {
			query,
			variables,
			...fetchPolicies,
		};
	};
}
