import { FC, ReactNode, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { Dialog as KendoDialog, DialogProps as KendoDialogProps, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { NotificationsGroup } from '@/app/_common/_components/notifications/notifications-group';
import { isFormElement } from '@/app/_common/utils/is-form-element/is-form-element';
import { Notification } from '@/app/_common/types';

import styles from './dialog.module.scss';

const DEFAULT_WIDTH = 704;

export interface DialogProps extends KendoDialogProps {
	headerDivider?: boolean;
	footerDivider?: boolean;
	actions?: ReactNode;
	errors?: Notification[];
	submitDisabled?: boolean;
	onSubmit?: () => void;
}

export const Dialog: FC<DialogProps> = ({
	children,
	className,
	headerDivider = true,
	footerDivider = true,
	errors,
	actions,
	width = DEFAULT_WIDTH,
	onSubmit,
	submitDisabled = false,
	...rest
}) => {
	const dialogClassNames = classNames(
		styles.dialog,
		{
			[styles.hideHeaderDivider]: !headerDivider,
			[styles.hideFooterDivider]: !footerDivider,
		},
		className,
	);

	const dialogRef = useRef<KendoDialog>(null);

	const handleEnterKeyPress = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Enter' && !event.shiftKey && onSubmit && !submitDisabled && !isFormElement(event.target as Element)) {
				onSubmit();
			}
		},
		[onSubmit, submitDisabled],
	);

	useEffect(() => {
		const dialogRefElement = dialogRef?.current?.element;

		if (dialogRefElement) {
			dialogRefElement.addEventListener('keyup', handleEnterKeyPress);
		}

		return () => dialogRefElement?.removeEventListener('keyup', handleEnterKeyPress);
	}, [handleEnterKeyPress]);

	const parentElement = document.querySelector('main');

	return (
		// eslint-disable-next-line jsx-a11y/no-autofocus
		<KendoDialog ref={dialogRef} autoFocus={true} appendTo={parentElement} className={dialogClassNames} width={width} {...rest}>
			{children}
			{actions && <DialogActionsBar>{actions}</DialogActionsBar>}
			{errors && <NotificationsGroup notifications={errors} />}
		</KendoDialog>
	);
};
