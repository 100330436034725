import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { QueryResultsTableInvestigationDetails } from '@/app/investigation-details/_common/stores';
import { BaseResultsDetailsViewStore } from '@/app/_common/_components/details-view/stores/base-results-details.view-store';

export class QueryResultsDetailsViewStore extends BaseResultsDetailsViewStore {
	protected resultsViewStore = injectInterface(this, QueryResultsTableInvestigationDetails);

	constructor() {
		super();
	}
}
