import _omit from 'lodash/omit';
import { AxiosResponse } from 'axios';

import { AuthDetails } from '@/app/_common/interfaces/auth-details';
import { Tenant } from '@/generated/graphql';

const INVITED_BY_KEY = 'invitedBy';

export const getAuthDetails = (token: string | null, response: AxiosResponse): AuthDetails => {
	const { id, upn, displayName, firstName, lastName, isTenantUser, franchise } = response.data;
	const tenants = response.data.tenants?.map((tenant: Tenant) => _omit(tenant, INVITED_BY_KEY));

	return {
		token,
		user: {
			id,
			upn,
			tenants,
			displayName,
			firstName,
			lastName,
			isTenantUser,
			franchise,
		},
	};
};
