import { AlertState, Investigation, InvestigationStatus } from '@/generated/graphql';
import { DetailsViewItemData } from '@/app/_common/_components/details-view/types';

export const isAlertUnassignable = (alert?: DetailsViewItemData, investigations?: Investigation[]) => {
	if (!alert) {
		return false;
	}

	const investigation = investigations?.find((investigation) => investigation.id === alert?.investigationSummary?.id);

	if (investigation) {
		return investigation.status !== InvestigationStatus.Closed && alert?.state?.alertState === AlertState.Assigned;
	}

	return alert?.state?.alertState === AlertState.Assigned;
};
