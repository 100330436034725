import { FormElements, Options } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components';

import styles from './header.module.scss';

export const Header = () => {
	return (
		<div className={styles.advancedQueryHeader}>
			<FormElements />
			<Options className={styles.options} />
		</div>
	);
};
