import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';
import { Button } from '@/app/_common/_components';

import styles from './filter-panel-footer.module.scss';

interface FilterPanelFooterProps {
	onCancel?: () => void;
	onApply?: () => void;
}

export const FilterPanelFooter: FC<FilterPanelFooterProps> = ({ onCancel, onApply }) => {
	const { t } = useTranslation([Namespaces.Common], { keyPrefix: 'filterPanel.footer.buttons' });

	const handleCancelClick = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleApplyClick = () => {
		if (onApply) {
			onApply();
		}
	};

	return (
		<div className={styles.footer}>
			<Button onClick={handleCancelClick} className={styles.cancel} fillMode="flat">
				{t('cancel')}
			</Button>
			<Button onClick={handleApplyClick} className={styles.apply} fillMode="outline">
				{t('apply')}
			</Button>
		</div>
	);
};
