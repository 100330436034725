import { observer } from 'mobx-react-lite';

import { ConfidenceCell } from '@/app/_common/_components/data-grid/_components';
import { QueryResultsTableCellWithStoreProps } from '@/app/_common/_components/query-results/query-results-table/types';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';

export const QueryResultsTableConfidenceCell = observer((props: QueryResultsTableCellWithStoreProps) => {
	const { searchValue } = useQueryResultsTableStore();

	return <ConfidenceCell {...props} searchValue={searchValue} showLabel={true} ignoreUnknownValue={true} oldContextMenu={false} />;
});

QueryResultsTableConfidenceCell.displayName = 'QueryResultsTableConfidenceCell';
