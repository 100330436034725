import axios, { AxiosRequestConfig } from 'axios';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { FetchTraceName, ResponseStatus } from '@/app/_common/interfaces';
import { LOG_FETCH_REQUESTS } from '@/app/_common/fetch/log-fetch-requests';
import { InsightsTraceBuilder } from '@/app/_common/utils';

const SUBSCRIPTION_TOKEN = process.env.REACT_APP_SUBSCRIPTION_TOKEN;
const API_URL = process.env.REACT_APP_FILE_URL;

export class FetchClient {
	private authStore = injectInterface({}, AuthStore);

	async fetchData<T>(endpoint: string, options?: AxiosRequestConfig) {
		const { ...customConfig } = options ?? {};

		const headers = {
			'Content-Type': 'application/json',
			'Ocp-Apim-Subscription-Key': SUBSCRIPTION_TOKEN as string,
			Authorization: this.authStore.token,
		};

		const config: AxiosRequestConfig = {
			url: API_URL + endpoint,
			headers: {
				...headers,
				...customConfig.headers,
			},
			...customConfig,
		};

		try {
			const response = await axios(config);

			LOG_FETCH_REQUESTS(response, options);

			return response.data as T;
			//eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			this.handleFetchError(error);

			return Promise.reject(error);
		}
	}

	async fetchMultipleData<T>(customConfigs: AxiosRequestConfig[], endpoint?: string) {
		const baseHeaders = {
			'Content-Type': 'application/json',
			'Ocp-Apim-Subscription-Key': SUBSCRIPTION_TOKEN as string,
			Authorization: this.authStore.token,
		};

		const configs: AxiosRequestConfig[] = customConfigs.map((config) => {
			const { headers = {}, ...customConfig } = config ?? {};

			return {
				url: (API_URL as string) + endpoint,
				headers: {
					...baseHeaders,
					...headers,
				},
				...(customConfig || {}),
			};
		});

		const promises = configs.map((config) => axios(config));

		try {
			const response = await Promise.all(promises);

			return response.map((data) => data.data) as T[];
			//eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			this.handleFetchError(error);

			return Promise.reject(error);
		}
	}

	async fetchImage(endpoint: string, urlSearchParams: URLSearchParams) {
		const headers = {
			'Ocp-Apim-Subscription-Key': SUBSCRIPTION_TOKEN as string,
			Authorization: this.authStore.token,
		};

		const config: RequestInit = {
			headers: {
				...headers,
			},
		};

		const url = API_URL + endpoint + '?' + urlSearchParams;

		try {
			const response = await window.fetch(url, config);
			const res = await response.blob();

			if (urlSearchParams.get('key')?.endsWith('.svg')) {
				return new Blob([res], { type: 'image/svg+xml' });
			} else if (urlSearchParams.get('key')?.endsWith('.png')) {
				return new Blob([res], { type: 'image/png' });
			}
			//eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			this.handleFetchError(error);

			return Promise.reject(error);
		}
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleFetchError(error: any) {
		if (error.response?.status === ResponseStatus.Unauthorized) {
			InsightsTraceBuilder.createTrackTraceWarning(FetchTraceName.Axios, error, this.authStore.userEmail);

			this.authStore.logout();

			return;
		}

		InsightsTraceBuilder.createTrackTraceError(FetchTraceName.Axios, error, this.authStore.userEmail);
	}
}
