import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import _max from 'lodash/max';
import _debounce from 'lodash/debounce';

import classNames from 'classnames';

import ParentSize from '@visx/responsive/lib/components/ParentSize';
import BaseBrush from '@visx/brush/lib/BaseBrush';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';

import { AdvancedQueryChartViewStore, AdvancedQueryViewStore } from '@/app/advanced-query/_common/stores';
import {
	ChartActionBlock,
	ChartEmptyData,
	ChartGraph,
	ChartTooltip,
	ChartXAxis,
} from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/chart/_components';
import { prepareTimeRangeForQuery } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/chart/utils';
import { LoadingSpinner } from '@/app/_common/_components';
import {
	CHART_VERTICAL_MARGINS,
	DEFAULT_CHART_MARGIN,
	ONE_DIGIT_WIDTH,
	TOOLTIP_STYLES,
	X_AXIS_HEIGHT,
	Y_AXIS_RIGHT_MARGIN,
} from '@/app/_common/constants';
import { ChartBarData, ChartStackBarKeys } from '@/app/_common/types';
import { useResizeObservation } from '@/app/_common/hooks';

import styles from './chart.module.scss';

const { right: marginRight, left: marginLeft } = DEFAULT_CHART_MARGIN;

export const Chart = observer(() => {
	const [selectedBrushRange, setSelectedBrushRange] = useState<string[]>([]);
	const [isArrowToRight, setIsArrowToRight] = useState<boolean>(false);
	const { loading, error, chartData, countTotals, isStackedBarChart, isChartCollapsed } = useInstance(AdvancedQueryChartViewStore);
	const { persistentTimeRange } = useInstance(AdvancedQueryViewStore);

	const [setRef, { height: actionBlockHeight }] = useResizeObservation();
	const brushRef = useRef<BaseBrush | null>(null);
	const { containerRef: chartContainerRef, TooltipInPortal } = useTooltipInPortal({
		// TooltipInPortal is rendered in a separate child of <body /> and positioned
		// with page coordinates which should be updated on scroll. consider using
		// Tooltip or TooltipWithBounds if you don't need to render inside a Portal
		detectBounds: false,
	});
	const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<ChartBarData>();
	const shouldShowTooltip = tooltipOpen && tooltipData;

	const setSelectedBrushRangeOnChart = (value: string[]) => _debounce(() => setSelectedBrushRange(value), 100)();

	const resetBrush = () => {
		brushRef?.current?.reset();
	};

	const resetBrushArea = useCallback(() => {
		setSelectedBrushRangeOnChart([]);
		resetBrush();
	}, []);

	useEffect(() => {
		if (loading) {
			resetBrushArea();
		}
	}, [resetBrushArea, loading]);

	const getTooltipToDate = () => {
		if (!shouldShowTooltip) {
			return '';
		}
		const persistentTimeRangeToValue = persistentTimeRange?.value?.to || '';
		return prepareTimeRangeForQuery([tooltipData?.bar.data[ChartStackBarKeys.Date] || ''], chartData, persistentTimeRangeToValue).to;
	};

	if (loading) {
		return <LoadingSpinner />;
	}

	if (isChartCollapsed) {
		return null;
	}

	if (!chartData.length) {
		return (
			<div className={classNames(styles.chartContainer, styles.empty, { error: error })} data-testid="results-graph">
				<ChartEmptyData error={error} />
			</div>
		);
	}

	return (
		<div className={styles.chartContainer} data-testid="results-graph">
			<ChartActionBlock selectedBrushRange={selectedBrushRange} resetBrushArea={resetBrushArea} actionBlockRef={setRef} />

			<ParentSize>
				{({ width, height }) => {
					if (!height || !width) {
						return null;
					}

					const isXAxisVisible = height - actionBlockHeight - CHART_VERTICAL_MARGINS > X_AXIS_HEIGHT;

					if (!isXAxisVisible) {
						return null;
					}

					const yAxisLabelMaxLength = String(_max(countTotals)).length;
					const yAxisComponentNetWidth = yAxisLabelMaxLength * ONE_DIGIT_WIDTH;
					const yAxisComponentGrossWidth = yAxisComponentNetWidth + Y_AXIS_RIGHT_MARGIN;
					const barGroupLeft = yAxisComponentGrossWidth + marginLeft;
					const xMax = width - barGroupLeft - marginRight;

					return (
						<>
							<ChartGraph
								width={Math.ceil(width)}
								height={Math.ceil(height)}
								selectedBrushRange={selectedBrushRange}
								containerRef={chartContainerRef}
								hideTooltip={hideTooltip}
								showTooltip={showTooltip}
								tooltipWidth={TOOLTIP_STYLES.width}
								brushRef={brushRef}
								resetBrush={resetBrush}
								setSelectedBrushRange={setSelectedBrushRangeOnChart}
								actionBlockHeight={actionBlockHeight}
								xMax={xMax}
								barGroupLeft={barGroupLeft}
								setIsArrowToRight={setIsArrowToRight}
							/>
							{isXAxisVisible && <ChartXAxis xMax={xMax} barGroupLeft={barGroupLeft} />}
						</>
					);
				}}
			</ParentSize>

			{shouldShowTooltip && (
				<TooltipInPortal top={tooltipTop} left={tooltipLeft} style={TOOLTIP_STYLES}>
					<ChartTooltip
						isArrowToRight={isArrowToRight}
						data={tooltipData.bar.data}
						toDate={getTooltipToDate()}
						isStackedBarChart={isStackedBarChart}
					/>
				</TooltipInPortal>
			)}
		</div>
	);
});
