import { ResponseStatus } from '@/app/_common/interfaces';
import { AxiosResponse } from 'axios';

import { AuthDetailsTokens } from '@/app/_common/interfaces/auth-details';

export const getTokens = (response: AxiosResponse): AuthDetailsTokens => {
	if (response.status !== ResponseStatus.OK) {
		throw new Error('Could not get tokens');
	}

	return {
		id_token: response.data.id_token,
		id_token_expires_in: response.data.id_token_expires_in,
		refresh_token: response.data.refresh_token,
		access_token: response.data.access_token,
		expires_on: response.data.expires_on,
		refresh_token_expires_in: response.data.refresh_token_expires_in,
	};
};
