import { ReactElement, cloneElement } from 'react';

import classNames from 'classnames';

import { ListItemProps } from '@progress/kendo-react-dropdowns';

import { FormDropdownOption } from '@/app/_common/_components/form-components/form-dropdown';
import { InvestigationStatus } from '@/generated/graphql';

import { Tooltip } from '@/app/_common/_components';

import styles from '@/app/investigation-details/_components/investigation-details/investigation-details-page.module.scss';

export const iconItemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
	const itemChildren = (
		<span className={styles.dropdownItem}>
			<span className={styles.dropdownIcon}>{itemProps.dataItem.icon}</span>
			<span className={styles.dropdownLabel}>{itemProps.dataItem.label}</span>
		</span>
	);

	return cloneElement(li, { ...li.props }, itemChildren);
};

const appendCustomOnClickBehavior = (props: HTMLElement, onClick: () => void) => {
	return {
		...props,
		onClick: (event: React.MouseEvent<HTMLLIElement>) => {
			//@ts-ignore
			props['onClick'](event);
			onClick();
		},
	};
};

export const statusItemRender = (li: ReactElement<HTMLElement>, itemProps: ListItemProps, onSleep: () => void) => {
	const itemChildren = (
		<span className={styles.dropdownItem}>
			<span className={styles.dropdownIcon}>{itemProps.dataItem.icon}</span>
			<span className={styles.dropdownLabel}>{itemProps.dataItem.label}</span>
		</span>
	);

	let elementProps = li.props;

	if (itemProps.dataItem.value === InvestigationStatus.Sleep) {
		elementProps = appendCustomOnClickBehavior(elementProps, onSleep);
	}

	return cloneElement(li, { ...elementProps }, itemChildren);
};

export const iconValueRender = (element: React.ReactElement<HTMLSpanElement>, value: FormDropdownOption) => {
	if (!value) {
		return element;
	}
	const children = [
		<span className={styles.dropdownIcon} key={1}>
			{value.icon}
		</span>,
		<span key={2}>&nbsp; {value.label}</span>,
	];

	return cloneElement(element, { ...element.props }, children);
};

export const tooltipValueRender = (element: React.ReactElement<HTMLSpanElement>, value: FormDropdownOption) => {
	if (!value) {
		return element;
	}

	const children = [
		<span key={1} data-tip={true} data-for={value.label}>
			{value.label}
		</span>,
		<Tooltip tooltipId={value.label} place={'right'} key={2} tooltipContent={value.label} />,
	];

	return cloneElement(element, { ...element.props }, children);
};

export const assigneeItemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps, darkTheme: boolean) => {
	const itemChildren = <span>{itemProps.dataItem.label}</span>;
	const customLiProps = {
		...li.props,
		...{ className: classNames(li.props.className, { [styles.unassignItemOption]: itemProps.dataItem.label === 'Unassigned', dark: darkTheme }) },
	};
	return cloneElement(li, customLiProps, itemChildren);
};

export const assigneeValueRender = (element: React.ReactElement<HTMLSpanElement>, value: FormDropdownOption) => {
	if (!value) {
		return element;
	}
	const label = value.label === 'Unassign' ? 'Select' : value.label;
	const children = <span>{label}</span>;
	const customElementProps = {
		...element.props,
		...{ className: classNames(element.props.className, { [styles.defaultItemOption]: value.label === 'Unassign' }) },
	};
	return cloneElement(element, customElementProps, children);
};
