import { FC, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance, provider } from 'react-ioc';
import { v4 as uuidv4 } from 'uuid';

import _debounce from 'lodash/debounce';
import classNames from 'classnames';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';

import { sortIndicatorsFilters } from '@/app/_common/utils/sort';
import { createStorageDetails } from '@/app/_common/utils';
import { useDataGridWidth } from '@/app/_common/hooks';
import { ThreatIntelligenceDescription, ThreatIntelligenceMatches, WidthsState } from '@/app/_common/types';
import { DEFAULT_COLUMN_WIDTH } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_common/constants';
import { Button } from '@/app/_common/_components';
import { SessionItem } from '@/app/_common/constants';
import { SessionList } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/session-list/session-list';
import { SingleAlertContentViewStore } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/single-alert-content/single-alert-content.view-store';
import { getColumnsWithoutEmptyValues } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_common/utils';
import { ThemeStore } from '@/app/_common/stores';
import { AlertsDetailsSessionsPropertiesPaths } from '@/app/_common/constants/alert-details-sessions.constants';
import { createSessionColumns } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_common/utils/create-session-columns';
import { setDefaultWidthToVisibleColumns } from '@/app/_common/_components/data-grid/utils';

import styles from './single-alert-content.module.scss';

const INITIAL_COLUMNS_WIDTHS_VERSION = 'v0'; // change it if you change the content of `initialWidths` constant

export interface SingleAlertContentProps {
	sessions: SessionItem[];
	importantColumns?: string[];
	alertDetailsId: string;
	className?: string;
	threatIntelligenceMatches: ThreatIntelligenceMatches;
	threatIntelligenceDescriptions: ThreatIntelligenceDescription[];
}

export const SingleAlertContent: FC<SingleAlertContentProps> = provider(SingleAlertContentViewStore)(
	observer(({ sessions, importantColumns, alertDetailsId, className, threatIntelligenceMatches, threatIntelligenceDescriptions }) => {
		const store = useInstance(SingleAlertContentViewStore);
		const themeStore = useInstance(ThemeStore);

		const columnsWithoutEmptyValues = getColumnsWithoutEmptyValues(sessions, importantColumns);

		const initialWidths: WidthsState = Object.keys(sessions[0] || []).reduce((acc, curr) => ({ ...acc, [curr]: DEFAULT_COLUMN_WIDTH }), {});

		const sessionName = `${store.getTenantId()}/dashboards/alerts/details/summary-main-content/${alertDetailsId}`;

		const tableId = uuidv4();

		useEffect(() => {
			store.setDataInSessionStorage(`ss/${sessionName}`);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const { widths, setWidths } = useDataGridWidth<WidthsState>(
			{ ...createStorageDetails('sessionStorage', `${sessionName}/columns-width`, INITIAL_COLUMNS_WIDTHS_VERSION), nestedObjectKey: 'columns-width' },
			setDefaultWidthToVisibleColumns(initialWidths, store.columns),
			store.columns,
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
		const handleColumnResize = useCallback(_debounce(setWidths, 500), [setWidths]);

		useEffect(() => {
			store.setSourceData(sessions);
			store.setColumns(columnsWithoutEmptyValues);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [store]);

		const importantColumnsNames: string[] = Object.keys(columnsWithoutEmptyValues).filter((key) => columnsWithoutEmptyValues[key]);

		const notImportantColumnsNames: string[] = Object.keys(columnsWithoutEmptyValues).filter((key) => !columnsWithoutEmptyValues[key]);

		const { getCounter, sort, setSort, setGridHeaderFilter, getGridHeaderFilterValues, getFilterOptions, resetGridHeaderFilter } = store;

		const IMPORTANT_COLUMNS = createSessionColumns({
			columnsNames: importantColumnsNames,
			widths,
			sortFilters: sortIndicatorsFilters,
			storeUtilsFunctions: {
				getCounter,
				sort,
				setSort,
				setGridHeaderFilter,
				getGridHeaderFilterValues,
				getFilterOptions,
				resetGridHeaderFilter,
			},
			dateTimePropertyName: AlertsDetailsSessionsPropertiesPaths.DateTime,
			singleAlertStore: store,
			tableId: tableId,
			threatIntelligenceMatches,
			threatIntelligenceDescriptions,
		});

		const NOT_IMPORTANT_COLUMNS = createSessionColumns({
			columnsNames: notImportantColumnsNames,
			widths,
			sortFilters: sortIndicatorsFilters,
			storeUtilsFunctions: {
				getCounter,
				sort,
				setSort,
				setGridHeaderFilter,
				getGridHeaderFilterValues,
				getFilterOptions,
				resetGridHeaderFilter,
			},
			dateTimePropertyName: AlertsDetailsSessionsPropertiesPaths.DateTime,
			singleAlertStore: store,
			tableId: tableId,
			threatIntelligenceMatches,
			threatIntelligenceDescriptions,
		});

		const handleOnlyImportantToggle = () => {
			store.setRenderOnlyImportantColumns(!store.renderOnlyImportantColumns);
		};

		const columnsPrepared = IMPORTANT_COLUMNS.length > 0 && IMPORTANT_COLUMNS[0]['show'] !== undefined;

		if (!columnsPrepared) {
			return null;
		}

		return (
			<section id={tableId} className={classNames(styles.container, className, [styles[themeStore.theme]])}>
				<div className={styles.scrollContainer}>
					<div className={classNames(styles.tableContainer)}>
						<SessionList
							data={store.data}
							sort={store.sort}
							columns={IMPORTANT_COLUMNS}
							tableClassName={styles.importantColumnsTable}
							onColumnResize={handleColumnResize}
							widths={widths}
						/>
						<Button
							fillMode="outline"
							className={classNames({
								[styles.expandTableButton]: store.renderOnlyImportantColumns,
								[styles.hideTableButton]: !store.renderOnlyImportantColumns,
							})}
							onClick={handleOnlyImportantToggle}
						>
							{store.renderOnlyImportantColumns ? <ArrowRight /> : <ArrowLeft />}
						</Button>
						{!store.renderOnlyImportantColumns && (
							<SessionList
								data={store.data}
								sort={store.sort}
								columns={NOT_IMPORTANT_COLUMNS}
								tableClassName={styles.notImportantColumnsTable}
								onColumnResize={handleColumnResize}
								widths={widths}
							/>
						)}
					</div>
				</div>
			</section>
		);
	}),
);
