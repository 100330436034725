/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

interface ItemExpandedProps {
	expanded: boolean;
	onClick: () => void;
}

export const ItemExpanded: FC<ItemExpandedProps> = ({ expanded, onClick }) => {
	return <div onClick={onClick}>{expanded ? <ArrowDropDown /> : <ArrowDropUp />}</div>;
};
