import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';

import { SortDirection } from '@/app/_common/constants';

export function sortByOrder<T>(data: T[], path: string, order: Array<string | number | boolean | null>, direction?: SortDirection) {
	if (!direction) {
		return data;
	}

	return _sortBy(data, (item) => {
		const value = path ? _get(item, path) : item;
		const index = order.indexOf(value);
		return direction === SortDirection.Desc ? index : -index;
	});
}
