import { useTranslation } from 'react-i18next';

import { DateTimePickerOptionKeys } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';

export const useTimeRangeOptions = (keys: DateTimePickerOptionKeys[]) => {
	const { t } = useTranslation(Namespaces.TimeRange, { keyPrefix: 'options' });

	return keys.map((key) => ({
		label: t(key),
		value: key,
	}));
};
