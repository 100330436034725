import { IntegrationStaticFields } from '@/app/_common/constants';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

export const displayValidateMessage = (fieldName: string, errorType: string, max: number, min?: number) => {
	if (fieldName === IntegrationStaticFields.description) {
		return i18n.t('validateDescriptionMessage', { ns: Namespaces.CreateIntegration, maxLength: max });
	}

	if (errorType === 'pattern') {
		return i18n.t('patternMessage', { ns: Namespaces.CreateIntegration, name: 'Name', maxLength: max });
	} else if (errorType === 'required') {
		return i18n.t('validateMessage', { ns: Namespaces.CreateIntegration, fieldName: 'Name' });
	}
	return i18n.t('validateNameMessage', { ns: Namespaces.CreateIntegration, minLength: min, maxLength: max });
};
