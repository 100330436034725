import { action, makeObservable } from 'mobx';

import {
	InvestigationQueryResultSummary,
	Mutation,
	MutationUnassignQueryResultFromInvestigationArgs,
	QueryResultConnection,
} from '@/generated/graphql';

import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { UnassignQueryResultFromInvestigation } from '@/app/_common/graphql/queries';
import { InvestigationTypename } from '@/app/_common/constants';

export class QueryResultsUnassignDataStore extends GraphqlBaseDataStore<Mutation, MutationUnassignQueryResultFromInvestigationArgs> {
	constructor() {
		super();

		makeObservable(this, {
			unassign: action,
		});
	}

	async unassign(args: Omit<MutationUnassignQueryResultFromInvestigationArgs, 'tenantId'>) {
		const variables: MutationUnassignQueryResultFromInvestigationArgs = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationUnassignQueryResultFromInvestigationArgs>({
			mutation: UnassignQueryResultFromInvestigation,
			variables,
			update: (cache) => {
				cache.modify({
					fields: {
						listQueryResults(existingQueryResultsRefs: QueryResultConnection, { readField }): QueryResultConnection {
							return {
								...existingQueryResultsRefs,
								edges: existingQueryResultsRefs.edges.filter((edge) => readField('id', edge.node) !== variables.queryResultId),
							};
						},
					},
				});

				const identifier = cache.identify({
					__typename: InvestigationTypename.Investigation,
					id: variables.investigationId,
				});

				cache.modify({
					id: identifier,
					fields: {
						queryResults(current: InvestigationQueryResultSummary[] = []) {
							return current.filter((summary) => summary.id !== variables.queryResultId);
						},
					},
				});
			},
		});

		return response;
	}
}
