import { action, makeObservable, computed } from 'mobx';

import { Query, QueryListQueryResultsArgs, ValueOperator } from '@/generated/graphql';

import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { ListQueryResults } from '@/app/_common/graphql/queries';
import { DEFAULT_QUERY_RESULT_PAGE_SIZE, READ_MORE_QUERY_RESULT_PAGE_SIZE } from '@/app/_common/constants';
import { getQueryInputVariables } from '@/app/_common/utils';

export type ListQueryResults = Pick<Query, 'listQueryResults'>;

export class QueryResultsDataStore extends GraphqlBaseDataStore<ListQueryResults> {
	constructor() {
		super();

		makeObservable(this, {
			queryResults: computed,
			read: action,
			readMore: action,
			hasNextPage: computed,
		});
	}

	get queryResults() {
		return this.data?.listQueryResults?.edges ?? [];
	}

	get pageInfo() {
		return this.data?.listQueryResults?.pageInfo;
	}

	get hasNextPage() {
		const { hasNextPage } = this.pageInfo ?? {};
		return hasNextPage;
	}

	read(investigationId?: string) {
		if (!investigationId) {
			return;
		}

		const variables: QueryListQueryResultsArgs = {
			tenantId: this.authStore.currentTenantId,
			filtersInput: {
				valueFilters: [
					{
						field: 'investigationId',
						operator: ValueOperator.Is,
						value: investigationId,
					},
				],
			},
			input: getQueryInputVariables({ first: DEFAULT_QUERY_RESULT_PAGE_SIZE }),
		};

		this.query({
			query: ListQueryResults,
			variables,
			fetchPolicy: 'cache-and-network',
		});
	}

	readMore(investigationId?: string) {
		const { hasNextPage, endCursor } = this.pageInfo ?? {};

		if (hasNextPage) {
			const variables: QueryListQueryResultsArgs = {
				tenantId: this.authStore.currentTenantId,
				input: getQueryInputVariables({ after: endCursor, first: READ_MORE_QUERY_RESULT_PAGE_SIZE }),
				filtersInput: {
					valueFilters: [
						{
							field: 'investigationId',
							operator: ValueOperator.Is,
							value: investigationId,
						},
					],
				},
			};

			return this.fetchMore({
				query: ListQueryResults,
				variables,
			});
		}
	}
}
