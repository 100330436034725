import { useMemo } from 'react';
import _get from 'lodash/get';

import { Alert, Investigation } from '@/generated/graphql';
import { ALERT_ACTIONS, AlertActionProps, ResultsDataItem } from '@/app/_common/types';
import { isAlertDismissable, isAlertUnassignable, isAlertUndismissable } from '@/app/dashboards/alerts-dashboard/_common/utils';
import { ActionLabel } from '@/app/_features/alerts-actions-menu/_components';
import { AdxTables, TableColumn } from '@/app/_common/constants';

interface BulkActionsConfig {
	selectedAlerts: Alert[];
	selectedResults: ResultsDataItem[];
	onViewDetails: () => void;
	onAssignOrCreateSelectedInvestigationClick: (alerts: Alert[]) => void;
	onUnassignSelectedClick: (alerts: Alert[]) => void;
	onDismissSelectedClick: (alerts: Alert[]) => void;
	onUndismissSelectedClick: (alerts: Alert[]) => void;
}

export const useResultsBulkActions = ({
	selectedResults,
	selectedAlerts,
	onViewDetails,
	onAssignOrCreateSelectedInvestigationClick,
	onUnassignSelectedClick,
	onDismissSelectedClick,
	onUndismissSelectedClick,
}: BulkActionsConfig): AlertActionProps[] => {
	return useMemo(() => {
		const assignableResults = selectedResults.filter((result) => {
			const table = result[TableColumn];

			if (table && table !== AdxTables.Alerts) {
				return true;
			}

			const alert = selectedAlerts.find((alert) => alert.id === result.alert_id);

			return !alert?.investigationSummary?.id;
		});

		const undismissableAlerts = selectedAlerts.filter((alert) => isAlertUndismissable(alert));

		if (selectedResults && selectedResults?.length <= 1) {
			return [];
		}

		const selectedResultsIds = Array.from(new Set(selectedResults.map((result) => _get(result, '_id'))));
		const selectedResultsCount = selectedResultsIds.length;

		return [
			{
				id: ALERT_ACTIONS.ViewDetails,
				show: selectedResultsCount > 0,
				label: () => <ActionLabel action={ALERT_ACTIONS.ViewDetails} count={selectedResultsCount} />,
				onClick: onViewDetails,
			},
			{
				id: ALERT_ACTIONS.AssignOrCreateInvestigation,
				show: assignableResults && assignableResults?.length > 0,
				label: () => <ActionLabel action={ALERT_ACTIONS.AssignOrCreateInvestigation} count={assignableResults.length} />,
				alerts: selectedResults as Alert[],
				onClick: onAssignOrCreateSelectedInvestigationClick,
			},
			{
				id: ALERT_ACTIONS.Unassign,
				show: (investigations: Investigation[]) => selectedAlerts.filter((alert) => isAlertUnassignable(alert, investigations)).length > 0,
				label: (count: number) => <ActionLabel action={ALERT_ACTIONS.Unassign} count={count} />,
				alerts: (investigations: Investigation[]) => selectedAlerts.filter((alert) => isAlertUnassignable(alert, investigations)),
				onClick: onUnassignSelectedClick,
			},
			{
				id: ALERT_ACTIONS.Dismiss,
				show: (investigations: Investigation[]) => selectedAlerts.filter((alert) => isAlertDismissable(alert, investigations)).length > 0,
				label: (count: number) => <ActionLabel action={ALERT_ACTIONS.Dismiss} count={count} />,
				alerts: (investigations: Investigation[]) => selectedAlerts.filter((alert) => isAlertDismissable(alert, investigations)),
				onClick: onDismissSelectedClick,
			},
			{
				id: ALERT_ACTIONS.Undismiss,
				show: undismissableAlerts.length > 0,
				label: () => <ActionLabel action={ALERT_ACTIONS.Undismiss} count={undismissableAlerts.length} />,
				alerts: undismissableAlerts,
				onClick: onUndismissSelectedClick,
			},
		];
	}, [
		selectedResults,
		selectedAlerts,
		onAssignOrCreateSelectedInvestigationClick,
		onUnassignSelectedClick,
		onDismissSelectedClick,
		onUndismissSelectedClick,
		onViewDetails,
	]);
};
