import { gql } from '@apollo/client';
import { CoreQueryResultFragment, CoreGetInvestigationFragment } from '../fragments.constants';

export const ListQueryResults = gql`
	query ListQueryResults($tenantId: ID!, $input: Paging, $filtersInput: FiltersInput) {
		listQueryResults(tenantId: $tenantId, input: $input, filtersInput: $filtersInput) {
			... on QueryResultConnection {
				edges {
					node {
						...CoreQueryResultFragment
					}
					cursor
				}
				pageInfo {
					endCursor
					hasNextPage
				}
			}
		}
	}
	${CoreQueryResultFragment}
`;

export const UpdateQueryResult = gql`
	mutation UpdateQueryResult($tenantId: ID!, $updateQueryResultInput: UpdateQueryResultInput!) {
		updateQueryResult(tenantId: $tenantId, updateQueryResultInput: $updateQueryResultInput) {
			... on QueryResult {
				...CoreQueryResultFragment
			}
		}
	}
	${CoreQueryResultFragment}
`;

export const QueryResultUpdatedSubscription = gql`
	subscription QueryResultUpdatedSubscription($tenantId: ID!, $investigationId: ID!) {
		queryResultUpdated(tenantId: $tenantId, investigationId: $investigationId) {
			id
			investigationId
			last_updated
			updatedBy {
				requesterUser {
					id
				}
			}
		}
	}
`;

export const QueryResultCreatedSubscription = gql`
	subscription QueryResultCreatedSubscription($tenantId: ID!, $investigationId: ID!) {
		queryResultCreated(tenantId: $tenantId, investigationId: $investigationId) {
			id
			investigationId
		}
	}
`;

export const QueryResultDeletedSubscription = gql`
	subscription QueryResultDeletedSubscription($tenantId: ID!) {
		resourceDeleted(tenantId: $tenantId) {
			... on QueryResult {
				id
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const UnassignQueryResultFromInvestigation = gql`
	mutation UnassignQueryResultFromInvestigation($tenantId: ID!, $investigationId: ID!, $queryResultId: ID!) {
		unassignQueryResultFromInvestigation(tenantId: $tenantId, investigationId: $investigationId, queryResultId: $queryResultId) {
			... on Investigation {
				...CoreGetInvestigationFragment
			}
			... on Error {
				message
				code
				correlationId
				additionalInformation {
					name
					value
				}
			}
		}
	}
	${CoreGetInvestigationFragment}
`;

export const AssignQueryResultToInvestigation = gql`
	mutation AssignQueryResultToInvestigation($tenantId: ID!, $investigationId: ID!, $queryResultInput: QueryResultInput!, $alertIds: [ID!]) {
		assignQueryResultToInvestigation(
			tenantId: $tenantId
			investigationId: $investigationId
			queryResultInput: $queryResultInput
			alertIds: $alertIds
		) {
			... on Investigation {
				...CoreGetInvestigationFragment
			}
			... on Error {
				message
				code
				correlationId
				additionalInformation {
					name
					value
				}
			}
		}
	}
	${CoreGetInvestigationFragment}
`;
