import { FC, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';
import { RichTextEditor, NoteEditDialog, getRichTextEditorTranslations } from '@/app/_common/_components';

import styles from './create-note.module.scss';

interface CreateNoteProps {
	loading?: boolean;
	maxHeight?: number;
	onSubmit: (noteContent: string) => Promise<void>;
}

const initialValue = '[{"type":"paragraph","children":[{"text":""}]}]';

export const CreateNote: FC<CreateNoteProps> = ({ maxHeight, onSubmit, loading }) => {
	const [maximized, setMaximized] = useState(false);
	const [noteContent, setNoteContent] = useState<string | undefined>(initialValue);

	const { t } = useTranslation(Namespaces.Notes);
	const translations = useMemo(() => getRichTextEditorTranslations(Namespaces.Notes), []);

	const handleChange = useCallback((noteContent?: string) => {
		setNoteContent(noteContent);
	}, []);

	const handleMaximizeClick = useCallback(() => {
		setMaximized(true);
	}, []);

	const handleMinimizeClick = useCallback(() => {
		setMaximized(false);
	}, []);

	const handleCancelClick = useCallback(() => {
		setMaximized(false);
		setNoteContent(undefined);
	}, []);

	const handleSubmit = useCallback(
		async (noteContent: string) => {
			await onSubmit(noteContent);
			setMaximized(false);
			setNoteContent(undefined);
		},
		[onSubmit],
	);

	return (
		<div className={styles.createNote}>
			{maximized && (
				<NoteEditDialog
					title={t('note.headers.create')}
					noteContent={noteContent}
					loading={loading}
					onSubmit={handleSubmit}
					onCancel={handleCancelClick}
					onMinimize={handleMinimizeClick}
					onChange={handleChange}
					confirmTitle={t('confirm.create.title')}
					confirmMessage={t('confirm.create.message')}
				/>
			)}
			<RichTextEditor
				editorContent={noteContent}
				className={styles.editor}
				maxHeight={maxHeight}
				isEditModeActive={true}
				loading={!maximized && loading}
				onSubmit={onSubmit}
				onChange={handleChange}
				onMaximize={handleMaximizeClick}
				createMode={true}
				translations={translations}
				data-testid="create-note-rich-text-editor"
			/>
		</div>
	);
};
