import { ApolloCache } from '@apollo/client';
import {
	Alert,
	AlertCount,
	AlertState,
	AlertsConnection,
	InvestigationAlertSummary,
	MutationCreateInvestigationArgs,
	MutationSetAlertsOnInvestigationArgs,
	User,
} from '@/generated/graphql';
import { AlertStateTypename, AlertTypename, InvestigationSummaryTypename, RequesterUserTypename } from '@/app/_common/constants';
import { readAlertStateFragmentById, writeAlertStateUpdateFragment } from '@/app/_common/utils';

export const updateCommonAssignAlertsDashboard = (
	cache: ApolloCache<unknown>,
	alerts: InvestigationAlertSummary[],
	investigationId: string,
	user: Pick<User, 'id' | 'upn'>,
	variables?: MutationCreateInvestigationArgs | MutationSetAlertsOnInvestigationArgs,
) => {
	const alertsIds = variables?.alertIds ?? [];

	if (!investigationId || !alertsIds.length) {
		return;
	}

	const oldAlerts = alertsIds.reduce<Alert[]>((result, id) => {
		const alert = readAlertStateFragmentById(cache, id);

		if (alert) {
			result.push(alert);
		}

		return result;
	}, []);

	const dismissedCount = oldAlerts.filter(({ state }) => state?.alertState === AlertState.Dismissed).length;
	const unassignedCount = oldAlerts.filter(({ state }) => !state?.alertState).length;

	cache.modify({
		fields: {
			getAlertCount(existingAlertCount: AlertCount) {
				return {
					...(existingAlertCount || {}),
					assignment: {
						...existingAlertCount.assignment,
						assigned: existingAlertCount.assignment.assigned + alertsIds.length,
						unassigned: existingAlertCount.assignment.unassigned - unassignedCount,
						dismissed: existingAlertCount.assignment.dismissed - dismissedCount,
					},
				};
			},
			listAlerts(existingAlertRefs: AlertsConnection, { readField }) {
				return {
					...(existingAlertRefs ?? {}),
					edges: existingAlertRefs?.edges.map((edgeRef) => {
						const alertId = readField('id', edgeRef.node);

						if (typeof alertId === 'string' && alertsIds.includes(alertId)) {
							const updatedAlert = alerts.find(({ id }) => id === alertId);

							if (!updatedAlert) {
								return edgeRef;
							}

							const updatedAlertRef = writeAlertStateUpdateFragment(cache, alertId, {
								id: alertId,
								state: {
									alertState: AlertState.Assigned,
									requesterUser: {
										...user,
										__typename: RequesterUserTypename,
									},
									timestamp: updatedAlert?.timestamp,
									__typename: AlertStateTypename,
								},
								investigationSummary: {
									id: investigationId,
									__typename: InvestigationSummaryTypename,
								},
								__typename: AlertTypename.Alert,
							});

							return {
								...edgeRef,
								node: updatedAlertRef,
							};
						}

						return edgeRef;
					}),
				};
			},
		},
	});
};
