import { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Hint, Label } from '@progress/kendo-react-labels';

import { InfoTooltip } from '@/app/_common/_components';
import { FormFieldError } from '@/app/_common/_components/form-components/form-field-error';

import styles from './form-input.module.scss';

export const FormInput = forwardRef<HTMLInputElement, FieldRenderProps & { renderAfter?: ReactNode }>((fieldRenderProps, ref) => {
	const { validationMessage, touched, label, id, valid, disabled, hint, type, optional, message, tooltipMessage, className, renderAfter, ...others } =
		fieldRenderProps;

	const showValidationMessage: string | false | null = touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : '';
	const errorId: string = showValidationMessage ? `${id}_error` : '';

	return (
		<FieldWrapper className={styles.fieldWrapper}>
			{label && (
				<Label
					className={classNames(styles.formLabel, { [styles.disabled]: disabled })}
					editorId={id}
					editorValid={valid}
					editorDisabled={disabled}
					optional={optional}
				>
					{label}
					{tooltipMessage && <InfoTooltip id={id} description={tooltipMessage} />}
				</Label>
			)}
			<div className="k-form-field-wrap">
				<div className={styles.inputFlexWrapper}>
					<Input
						ref={ref}
						className={classNames(styles.formInput, className, { [styles.error]: message })}
						valid={valid}
						type={type}
						id={id}
						disabled={disabled}
						ariaDescribedBy={`${hintId} ${errorId}`}
						{...others}
					/>
					{renderAfter}
				</div>
				{showHint && <Hint id={hintId}>{hint}</Hint>}
				{showValidationMessage && <FormFieldError>{validationMessage}</FormFieldError>}
				{message && <FormFieldError>{message}</FormFieldError>}
			</div>
		</FieldWrapper>
	);
});

FormInput.displayName = 'FormInput';
