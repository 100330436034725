import _groupBy from 'lodash/groupBy';
import _uniq from 'lodash/uniq';

import { FieldFilterGroup } from '@/app/_common/types';
import { FIELD_FILTER_GROUPS } from '@/app/_common/constants';
import { isFilterByOperator } from '@/app/_common/utils';

export const getFilterGroups = (filters: FieldFilterGroup[]) => {
	const fields = _groupBy(filters, 'field');

	return Object.keys(fields).reduce((result: { [key: string]: FIELD_FILTER_GROUPS }, field) => {
		const operators = _uniq(fields[field].map((filter) => filter.operator));

		let group: FIELD_FILTER_GROUPS;

		if (operators.length === 2) {
			group = FIELD_FILTER_GROUPS.MIXED;
		} else {
			group = isFilterByOperator(operators[0]) ? FIELD_FILTER_GROUPS.EQUALS : FIELD_FILTER_GROUPS.NOT_EQUALS;
		}

		result[field] = group;

		return result;
	}, {});
};
