import { useTranslation } from 'react-i18next';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarOutlineOutlined from '@mui/icons-material/StarOutlineOutlined';

import { Namespaces } from '@/translations/namespaces';
import { buttonize } from '@/app/_common/utils';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { UserPreferenceStore } from '@/app/_common/stores';
import { AVAILABLE_FIELDS_BLOCK_NAME } from '@/app/_common/types';

interface AddToFavoritesActionProps {
	blockName: AVAILABLE_FIELDS_BLOCK_NAME | null;
	fieldName: string;
	closeContextMenu: () => void;
	className?: string;
}

export const AddToFavoritesAction = observer(({ closeContextMenu, fieldName, blockName, className = '' }: AddToFavoritesActionProps) => {
	const { t } = useTranslation([Namespaces.DetailsView], { keyPrefix: 'expansionPanel' });
	const userPreferenceStore = useInstance(UserPreferenceStore);
	const { favoriteFields, toggleFavoriteField } = userPreferenceStore;

	if (!(fieldName && blockName)) {
		return null;
	}

	const isFavorite = favoriteFields[blockName].includes(fieldName);
	const Icon = isFavorite ? StarOutlineOutlined : StarOutlinedIcon;
	const translationKey = isFavorite ? 'removeFromFavorites' : 'addToFavorites';

	const addToFavorites = () => {
		toggleFavoriteField(blockName, fieldName);
		closeContextMenu();
	};

	return (
		<div className={className} {...buttonize(addToFavorites)}>
			<Icon />
			<div>{t(`contextMenu.${translationKey}`)}</div>
		</div>
	);
});
