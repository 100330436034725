import { Alert, InvestigationAlertSummary } from '@/generated/graphql';
import { DataGridDataItem } from '@/app/_common/types';

export const convertAlertSummaryToAlert = (
	summary: DataGridDataItem<InvestigationAlertSummary>,
	investigationId?: string,
): DataGridDataItem<Alert> => {
	const investigationSummary = investigationId ? { id: investigationId } : undefined;
	return {
		...summary,
		investigationSummary,
	} as unknown as DataGridDataItem<Alert>;
};
