interface RequiredValue {
	node: {
		id: number | string;
	};
}

export const DEFAULT_SKELETON_SIZE = 50;

export function getDataGridInfiniteScrollData<T extends RequiredValue>(
	data: T[],
	defaultValue: Omit<T, 'node'>,
	count: number,
	showSkeleton: boolean,
	skeletonSize = DEFAULT_SKELETON_SIZE,
) {
	const remainingCount = count - data.length > 0 ? count - data.length : 0;
	const skeletonLength = remainingCount > skeletonSize ? skeletonSize : remainingCount;

	return showSkeleton
		? data.concat(
				new Array(skeletonLength).fill({}).map(
					(_, index) =>
						({
							...defaultValue,
							node: {
								id: data.length + index + 1,
							},
						} as T),
				),
		  )
		: data;
}
