import { KeyboardEvent } from 'react';
import { Path } from 'slate';

import { EditorType, getCurrentItem, CustomEditor, ChildrenProps } from '@/app/_common/_components/rich-text-editor';
import { onKeyDown } from '@prezly/slate-lists';

const MAX_DEPTH = 5;

export const keyDownAction = (e: KeyboardEvent<HTMLElement>, editor: CustomEditor) => {
	const { selection, insertText } = editor;

	if (!selection) {
		return;
	}
	const [currentItem, currentItemPath] = getCurrentItem(editor) as [ChildrenProps, Path];

	const isCodeType = currentItem.type === EditorType.Code;
	const isListType = currentItem.type === EditorType.LIST_ITEM_TEXT;

	const currentItemDepth = currentItemPath.length - 1;

	if (isListType && e.shiftKey) {
		onKeyDown(editor, e);
		return;
	}
	if (isListType && currentItemDepth <= MAX_DEPTH) {
		onKeyDown(editor, e);
		return;
	}
	if (e.key === 'Enter' && e.shiftKey && !isCodeType) {
		e.preventDefault();
		insertText('\n');
	}

	if (e.key === 'Enter' && isCodeType) {
		e.preventDefault();
		insertText('\n');
	}

	if (e.key === 'Tab') {
		e.preventDefault();
		insertText('    ');
	}
};
