import React, { ReactNode } from 'react';

import styles from './available-fields-container.module.scss';

interface AvailableFieldsContainerProps {
	children: ReactNode;
}

export const AvailableFieldsContainer = ({ children }: AvailableFieldsContainerProps) => {
	return <div className={styles.availableFieldsContainer}>{children}</div>;
};
