import { DataTable, TableColumn, TableRow } from '@/app/_common/types';
import { ResultsTablePropertiesPaths } from '@/app/_common/constants';
import { generateItemId } from '@/app/advanced-query/_common/utils/generate-item-id';

export type StringBool = 'true' | 'false';

export interface PieChartContent {
	count_: number;
	list_: StringBool;
}

export const formatQueryResults = <T>(dataTable: DataTable, generateUniqueId = false): T[] => {
	try {
		const columns: string[] = dataTable.Columns.map((column: TableColumn) => {
			return column.ColumnName;
		});

		return dataTable.Rows.map((row: TableRow) => {
			const result: Partial<T> = {};
			columns.forEach((column, index) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				result[column as keyof T] = row[index] as any;
			});

			/*
			 * Grid table needs unique values as keys for its rows, otherwise it fails with lots of issues.
			 * Backend sends data either without unique ids at all as in the 'events' table,
			 * or with duplicated ids, as in the case of 'alert_id' in 'alerts' table.
			 * As 'alert_id' is also needed to fetch alert details data for the <AlertsDetails /> component,
			 * it is encoded here into a unique token to later on be decoded and used in queries.
			 * */
			if (generateUniqueId) {
				const id = generateItemId(result);
				result[ResultsTablePropertiesPaths.Id as keyof T] = id as T[keyof T];
			}

			return result as T;
		});
	} catch (error) {
		return [];
	}
};

export const formatPieChartResults = (data: TableRow[]): PieChartContent[] => {
	return data.reduce((acc: PieChartContent[], curr: TableRow) => {
		if (curr.length < 2 || typeof curr[1] !== 'number') {
			return acc; // skip when different shape than needed
		}

		const result: PieChartContent = {
			count_: curr[1],
			list_: curr[0] === 'true' ? 'true' : 'false',
		};
		return [...acc, result];
	}, []);
};
