import React, { MouseEvent, KeyboardEvent, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { v4 as uuid } from 'uuid';

import { FilterDescriptorWithId, FilterOption, FilterValue, Filters } from '@/app/_common/types';
import { Divider } from '@/app/_common/_components';
import { ArrayFilterOperators } from '@/app/_common/constants';
import { SelectMode } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/constants';

import { OptionGroup } from './option-group';

const hasNextGroup = (groups: FilterOption[][], index: number) => groups.find((group, currentIndex) => currentIndex > index && group.length > 0);

interface GroupsListProps {
	optionGroups: FilterOption[][];
	onFilterChange: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>, value: FilterValue[]) => void;
	filter?: Filters | FilterDescriptorWithId[];
	selectMode?: SelectMode;
	onButtonClick?: (value: FilterValue, operator: ArrayFilterOperators) => void;
}

export const OptionGroupsList = observer<GroupsListProps>(({ optionGroups, onFilterChange, filter, selectMode, onButtonClick }) => {
	const optionGroupsWithIds = useMemo(
		() =>
			optionGroups.map((group) => ({
				id: uuid(),
				group,
			})),
		[optionGroups],
	);

	return (
		<>
			{optionGroupsWithIds.map(({ id, group }, index) => {
				return (
					group?.length > 0 && (
						<React.Fragment key={id}>
							<OptionGroup group={group} onFilterChange={onFilterChange} filter={filter} selectMode={selectMode} onButtonClick={onButtonClick} />
							{hasNextGroup(optionGroups, index) && <Divider />}
						</React.Fragment>
					)
				);
			})}
		</>
	);
});

OptionGroupsList.displayName = 'OptionGroupsList';
