import { Skeleton } from '@progress/kendo-react-indicators';

import styles from './data-header-cell-loader.module.scss';

export const DataHeaderCellLoader = () => {
	return (
		<div className={styles.dataHeaderCellLoader}>
			<Skeleton shape={'text'} className={styles.skeleton} />
			<Skeleton shape={'text'} className={styles.skeleton} />
			<Skeleton shape={'text'} className={styles.skeleton} />
			<Skeleton shape={'text'} className={styles.skeleton} />
			<Skeleton shape={'text'} className={styles.skeleton} />
		</div>
	);
};
