import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import { GridCellProps } from '@progress/kendo-react-grid';

import { ContextMenuComponentProps } from '@/app/_common/types';
import { DataCell } from '@/app/_common/_components/data-grid/_components';

import styles from './icon-cell.module.scss';

interface IconCellProps extends GridCellProps {
	title?: string;
	className?: string;
	onClick?: () => void;
	ContextMenuComponent?: React.FC<ContextMenuComponentProps>;
	children: ReactNode;
}

export const IconCell: FC<IconCellProps> = ({ children, className, ...rest }) => {
	return (
		<DataCell {...rest} className={classNames(styles.iconCell, className)}>
			{children}
		</DataCell>
	);
};
