import { FC } from 'react';
import classNames from 'classnames';

import { Loader } from '@progress/kendo-react-indicators';
import { Button as KendoButton, ButtonProps as KendoButtonProps } from '@progress/kendo-react-buttons';

import styles from './button.module.scss';

export enum ButtonSize {
	Large = 'large',
	Medium = 'medium',
	Small = 'small',
}

interface ButtonProps extends KendoButtonProps {
	size?: ButtonSize;
	loading?: boolean;
}

export const Button: FC<ButtonProps> = ({ children, size = ButtonSize.Medium, themeColor = 'primary', loading = false, className, ...rest }) => {
	const buttonClassNames = classNames(styles.button, className, styles[size], { [styles.loading]: loading });

	return (
		<KendoButton className={buttonClassNames} themeColor={themeColor} {...rest}>
			{loading ? <Loader type="pulsing" /> : children}
		</KendoButton>
	);
};
