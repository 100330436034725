export const EDITOR_TEXT_LIMIT = 5000;
export const RICH_TEXT_EDITOR_LS = 'ls/investigation-details/rich-text-editor';
export const RICH_TEXT_EDITOR_POPUP_ID = 'rich_text_editor_popup';

export enum FormattingType {
	Code = 'code',
	Link = 'link',
	Bold = 'bold',
	Italic = 'italic',
	Underline = 'underline',
	Table = 'table',
	// we must use such a record to match the library variables
	ORDERED_LIST = 'ordered-list',
	UNORDERED_LIST = 'unordered-list',
}
