import { ReactNode, forwardRef, useCallback } from 'react';

import { DateTimePicker, DateTimePickerProps } from '@progress/kendo-react-dateinputs';
import { Popup, PopupProps } from '@progress/kendo-react-popup';

import styles from './form-date-time-picker.module.scss';

type FormDateTimePickerPopupRenderProps = Pick<PopupProps, 'popupClass' | 'style'> & {
	children?: ReactNode;
};

export const FormDateTimePicker = forwardRef<HTMLDivElement, DateTimePickerProps>((props, popupRef) => {
	const FormDateTimePickerPopupRender = useCallback(
		({ children, ...rest }: FormDateTimePickerPopupRenderProps) => (
			<Popup {...rest} popupClass={styles.popup}>
				<div ref={popupRef} data-testid="date-time-picker">
					{children}
				</div>
			</Popup>
		),
		[popupRef],
	);
	return <DateTimePicker {...props} popup={FormDateTimePickerPopupRender} />;
});

FormDateTimePicker.displayName = 'FormDateTimePicker';
