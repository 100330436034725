import i18n from '@/translations/i18n';

import { Namespaces } from '@/translations/namespaces';
import { AlertsGridResetFiltersButton } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/_components';

export interface GetResetFiltersMenuItemProps {
	disabled: boolean;
	resetAllFilters: () => void;
	buttonLabel?: string;
	id?: string;
}

export const RESET_FILTERS_KEY = 'resetFilters';

export const getResetFiltersMenuItem = ({ disabled, resetAllFilters, buttonLabel, id = RESET_FILTERS_KEY }: GetResetFiltersMenuItemProps) => {
	const label = buttonLabel ?? i18n.t(RESET_FILTERS_KEY, { ns: Namespaces.Common });

	return {
		id,
		label: label,
		onClick: resetAllFilters,
		itemComponent: <AlertsGridResetFiltersButton text={label} disabled={disabled} />,
	};
};
