import { FC } from 'react';
import classNames from 'classnames';

import styles from './divider.module.scss';

interface DividerProps {
	className?: string;
}

export const Divider: FC<DividerProps> = ({ className }) => {
	return <div className={classNames(styles.divider, className)} />;
};
