import { FC, ReactNode, useMemo } from 'react';

import { v4 as uuid } from 'uuid';
import classNames from 'classnames';

import { IconButton } from '@mui/material';

import { Tooltip } from '@/app/_common/_components';

import styles from './filter-button.module.scss';

interface FilterButtonProps {
	value: string | number;
	active: boolean;
	tooltip?: string;
	activeIcon: ReactNode;
	notActiveIcon: ReactNode;
	onClick: (value: string | number) => void;
	className?: string;
	'data-testid'?: string;
}

export const FilterButton: FC<FilterButtonProps> = ({
	value,
	active,
	activeIcon,
	notActiveIcon,
	tooltip,
	onClick,
	className,
	'data-testid': dataTestId,
}) => {
	const handleClick = () => {
		onClick(value);
	};

	const tooltipId = useMemo(() => uuid(), []);

	return (
		<>
			<IconButton
				className={classNames(styles.button, className)}
				onClick={handleClick}
				data-tip={true}
				data-for={tooltipId}
				data-testid={dataTestId}
			>
				{active ? activeIcon : notActiveIcon}
			</IconButton>
			{tooltip && <Tooltip tooltipId={tooltipId} tooltipContent={tooltip} />}
		</>
	);
};
