import { Editor, Element, Location } from 'slate';
import { CustomEditor } from '@/app/_common/_components/rich-text-editor';

type SelectionMode = 'all' | 'highest' | 'lowest';

export const getMatchNode = (editor: CustomEditor, format: string, at?: Location, mode?: SelectionMode) => {
	return Array.from(
		Editor.nodes(editor, {
			at: at,
			match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === format,
			mode: mode,
		}),
	);
};
