import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { AVAILABLE_FIELDS_BLOCK_NAME } from '@/app/_common/types';

export const detailsViewTypenameToFieldsBlockNameMapping: Record<DetailsViewTypename, AVAILABLE_FIELDS_BLOCK_NAME | null> = {
	[DetailsViewTypename.ALERT]: AVAILABLE_FIELDS_BLOCK_NAME.ALERTS,
	[DetailsViewTypename.INVESTIGATION_ALERT_SUMMARY]: AVAILABLE_FIELDS_BLOCK_NAME.ALERTS,
	[DetailsViewTypename.EVENT]: AVAILABLE_FIELDS_BLOCK_NAME.EVENTS,
	[DetailsViewTypename.EVIDENCE]: AVAILABLE_FIELDS_BLOCK_NAME.EVIDENCE,
	[DetailsViewTypename.UNKNOWN]: null,
};
