import { makeAutoObservable } from 'mobx';

import { injectInterface } from '@/app/_common/ioc/inject-interface';

import { AuthStore } from '@/app/_common/stores';

import { UserflowSignatureDataStore } from './userflow-signature.data-store';

export class UserflowSignatureViewStore {
	private userflowSignatureDataStore = injectInterface(this, UserflowSignatureDataStore);
	private authStore = injectInterface(this, AuthStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get userSignature() {
		return this.userflowSignatureDataStore.data?.userSignature;
	}

	get tenantSignature() {
		return this.userflowSignatureDataStore.data?.tenantSignature;
	}

	readSignature = () => {
		this.userflowSignatureDataStore.read(this.authStore.currentTenantId);
	};
}
