export const formatDataForSyntaxHighlighter = <T>(value: T): string => {
	try {
		if (typeof value !== 'string') {
			return JSON.stringify(value, null, 2);
		}

		return JSON.stringify(JSON.parse(value), null, 2);
	} catch (e) {
		return String(value);
	}
};
