import { EvidenceApiDTO } from '@/app/_common/constants/alert-details.constants';
import { FetchBaseDataStore } from '@/app/_common/fetch/fetch-base.data-store';

export class AlertsDetailsEvidenceNavigationDataStore extends FetchBaseDataStore<EvidenceApiDTO[]> {
	get details() {
		return this.data;
	}

	read(container?: string, key?: string) {
		this.clear();
		const alertDetailsEndpoint = process.env.REACT_APP_FILE_ENDPOINT;

		if (container && key && alertDetailsEndpoint) {
			this.fetchApiByAxios(alertDetailsEndpoint, { params: { containerName: container, key } });
		}
	}
}
