import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { Button } from '@/app/_common/_components';

import styles from './icon-button.module.scss';

interface IconButtonProps {
	className?: string;
	loading?: boolean;
	disabled?: boolean;
	onClick: () => void;
	children: ReactNode;
}

export const IconButton: FC<IconButtonProps> = ({ loading, disabled, className, children, onClick }) => {
	return (
		<Button
			disabled={disabled}
			loading={loading}
			className={classNames(styles.button, className, { [styles.disabled]: disabled, [styles.loading]: loading })}
			onClick={onClick}
			fillMode="flat"
		>
			{children}
		</Button>
	);
};
