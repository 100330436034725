import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';
import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { detailsViewTypenameToIconMapping, detailsViewTypenameToTranslationKeyMapping } from '@/app/_common/_components/details-view/constants';

import styles from './header-title-prefix.module.scss';

interface HeaderTitlePrefixProps {
	typename: DetailsViewTypename;
}

export const HeaderTitlePrefix = ({ typename }: HeaderTitlePrefixProps) => {
	const { t } = useTranslation([Namespaces.DetailsView]);
	const itemTypeTranslationKey = detailsViewTypenameToTranslationKeyMapping[typename];

	if (!itemTypeTranslationKey) {
		return null;
	}

	const Icon = detailsViewTypenameToIconMapping[typename] ?? null;

	return (
		<div className={styles.container}>
			{Icon}
			<div>{t(`header.itemType.${itemTypeTranslationKey}`)}</div>
			<div>|</div>
		</div>
	);
};
