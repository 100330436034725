import { makeObservable, computed, action } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { getQueryInputVariables } from '@/app/_common/utils';
import { Query, QueryListEndpointsArgs, FiltersInput, SortersInput } from '@/generated/graphql';
import { ListEndpoints } from '@/app/_common/graphql/queries';

type ListEndpoints = Pick<Query, 'listEndpoints'>;

export class EndpointsListDataStore extends GraphqlBaseDataStore<ListEndpoints, QueryListEndpointsArgs> {
	constructor() {
		super();

		makeObservable(this, {
			endpoints: computed,
			pageInfo: computed,
			readMore: action,
			read: action,
		});
	}

	get endpoints() {
		return this.data;
	}

	get pageInfo() {
		return this.data?.listEndpoints?.pageInfo;
	}

	async readMore(filtersInput?: FiltersInput, sortersInput?: SortersInput): Promise<void> {
		const { hasNextPage, endCursor } = this.pageInfo || {};

		if (!hasNextPage) {
			return;
		}

		const variables = {
			tenantId: this.authStore.currentTenantId,
			input: getQueryInputVariables({ after: endCursor }),
			filtersInput,
			sortersInput,
		};

		await this.fetchMore({
			query: ListEndpoints,
			variables,
		});
	}

	read(filtersInput?: FiltersInput, sortersInput?: SortersInput) {
		const variables = {
			tenantId: this.authStore.currentTenantId,
			input: getQueryInputVariables(),
			filtersInput,
			sortersInput,
		};

		this.query({
			query: ListEndpoints,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}
}
