import { action, makeObservable } from 'mobx';
import { DocumentNode } from 'graphql';
import { gql } from '@apollo/client';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { Mutation, MutationCreateInvestigationArgs } from '@/generated/graphql';
import { CreateAndAssignAlertsUpdateCacheFn } from '@/app/_features/alerts-actions/_common/stores/_common/types';

export class CreateInvestigationAndAssignAlertsDataStore extends GraphqlBaseDataStore<Mutation, MutationCreateInvestigationArgs> {
	constructor() {
		super();
		makeObservable(this, {
			createAndAssign: action,
		});
	}

	/**
	 * @param {string} fragment - IMPORTANT: fragment name should equal CreateAndAssignAlertsFragment. Should be fragment of Investigation.
	 */
	createAndAssign = async (
		mutationVariables: Omit<MutationCreateInvestigationArgs, 'tenantId'>,
		fragment: DocumentNode,
		updateCache?: CreateAndAssignAlertsUpdateCacheFn,
	) => {
		const variables = {
			...mutationVariables,
			tenantId: this.authStore.currentTenantId,
		};

		const mutation = gql`
			mutation CreateInvestigation(
				$tenantId: ID!
				$name: String!
				$priority: InvestigationPriority!
				$type: InvestigationType!
				$assigneeId: ID
				$alertIds: [ID!]
			) {
				createInvestigation(tenantId: $tenantId, name: $name, priority: $priority, type: $type, assigneeId: $assigneeId, alertIds: $alertIds) {
					...CreateAndAssignAlertsFragment
					... on Error {
						message
						code
						correlationId
					}
				}
			}
			${fragment}
		`;

		const response = await this.mutate<Mutation, MutationCreateInvestigationArgs>({
			mutation,
			variables,
			update: (...args) =>
				updateCache?.(
					{
						upn: this.authStore.userEmail,
						id: this.authStore.userId,
					},
					...args,
				),
		});

		return response;
	};
}
