import { useCallback, useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Form, Field, FormProps, FormElement, KeyValue } from '@progress/kendo-react-form';

import { FormInput } from '@/app/_common/_components/form-components/form-input';
import { FormDropdown } from '@/app/_common/_components/form-components/form-dropdown';
import { InvestigationPriority } from '@/generated/graphql';
import { DropdownOption } from '../_common/dropdown-option.interface';

import { getInvestigationPriorityOptions, getInvestigationTypeOptions, InvestigationType } from '../_common/investigation-dropdown-options';
import { assigneeItemRender } from '@/app/investigation-details/_common/utils/dropdown-render-utils';
import { lengthValidator } from '@/app/_common/utils';
import { MAX_CHARACTERS_COUNT } from '@/app/_common/constants/input-length.constants';
import { stringValidator } from '@/app/_common/utils/validators/string-validator';
import { Namespaces } from '@/translations/namespaces';
import { AssignToInvestigationTabs } from '@/app/_common/constants';
import { CreateInvestigationFormValues } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/assign-query-results-to-investigation-dialog/types';
import { AssigneeSelectViewStore } from '@/app/_common/_components/forms/create-investigation-form/assignee-select.view-store';

import styles from './create-investigation-form.module.scss';

export interface CreateInvestigationFormData {
	name: string;
	priority: InvestigationPriority;
	type: DropdownOption;
	assigneeId: DropdownOption;
}

export interface CreateInvestigationFormProps {
	disabled?: boolean;
	onSubmit?: FormProps['onSubmit'];
	onErrorsChange: (errors: { [key: string]: string }, tab: AssignToInvestigationTabs) => void;
	onChange?: (values: CreateInvestigationFormValues) => void;
}

export type CreateInvestigationFormValidator = (data: CreateInvestigationFormData) => KeyValue<string> | undefined;

const useFormValidator = () => {
	const { t } = useTranslation([Namespaces.CreateInvestigationDialog]);

	const formValidator: CreateInvestigationFormValidator = (data) => {
		const messages: { [key: string]: string } = {};

		if (!data.name || !stringValidator(data.name)) {
			messages.name = t('form.name.messages.required');
		}

		if (!lengthValidator(data.name, MAX_CHARACTERS_COUNT)) {
			messages.name = t('validators.length.invalid', { ns: Namespaces.Common, length: MAX_CHARACTERS_COUNT });
		}

		if (!data.priority) {
			messages.priority = t('form.priority.messages.required');
		}

		if (!data.type) {
			messages.type = t('form.type.messages.required');
		}

		if (!data.assigneeId) {
			messages.assigneeId = t('form.assignee.messages.required');
		}

		if (Object.keys(messages).length === 0) {
			return;
		}

		return messages;
	};

	return formValidator;
};

const getAlertInvestigationType = () => {
	return getInvestigationTypeOptions.filter((option) => option.label === InvestigationType.Alert)[0];
};

export const CreateInvestigationForm = observer<CreateInvestigationFormProps, Form>(
	({ disabled = false, onSubmit, onErrorsChange, onChange }, ref) => {
		const { initialAssignee: assigneeIdInitial, loading: assigneeIdLoading, data: assigneeIdOptions } = useInstance(AssigneeSelectViewStore);
		const { t } = useTranslation([Namespaces.CreateInvestigationDialog]);
		const formValidator = useFormValidator();

		const handleChange = useCallback(() => {
			if (ref && 'current' in ref && onChange) {
				onChange(ref?.current?.values as CreateInvestigationFormValues);
			}
		}, [ref, onChange]);

		// Set default value (currently logged in user) for "assigneeId"
		useEffect(() => {
			if (ref && 'current' in ref && assigneeIdInitial) {
				ref?.current?.onChange('assigneeId', { value: assigneeIdInitial });
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [assigneeIdInitial]);

		return (
			<Form
				ref={ref}
				validator={formValidator as FormProps['validator']}
				onSubmit={onSubmit}
				initialValues={{ type: getAlertInvestigationType() }}
				render={({ errors }) => {
					/* temporar solution until we change Kendo Forms into React Hook Forms
						Kendo Forms doesn't expose any easy way to observe the form
						As the component is defined inline, we cannot use there any React hooks
					 */
					onErrorsChange(errors, AssignToInvestigationTabs.createInvestigation);

					return (
						<FormElement>
							<fieldset className={styles.fields}>
								<Field
									id={'name'}
									name={'name'}
									label={t('form.name.label')}
									component={FormInput}
									placeholder={t('form.name.placeholder')}
									disabled={disabled}
									onChange={handleChange}
								/>
								<div className={styles.formRow}>
									<Field
										id={'form.priority'}
										name={'priority'}
										label={t('form.priority.label')}
										component={FormDropdown}
										defaultItem={t('form.priority.placeholder')}
										options={getInvestigationPriorityOptions}
										disabled={disabled}
										onChange={handleChange}
									/>
									<Field
										id={'type'}
										name={'type'}
										label={t('form.type.label')}
										component={FormDropdown}
										dataItemKey="value"
										textField="label"
										options={getInvestigationTypeOptions}
										disabled={disabled}
										onChange={handleChange}
									/>
								</div>
								<Field
									id={'assigneeId'}
									name={'assigneeId'}
									label={t('form.assignee.label')}
									component={FormDropdown}
									defaultItem={{ label: t('form.assignee.placeholder') }}
									dataItemKey="value"
									textField="label"
									itemRender={assigneeItemRender}
									options={assigneeIdOptions}
									disabled={disabled}
									filterable={true}
									loading={assigneeIdLoading}
									onChange={handleChange}
								/>
							</fieldset>
						</FormElement>
					);
				}}
			/>
		);
	},
	{ forwardRef: true },
);
