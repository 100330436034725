import { FC } from 'react';
import classNames from 'classnames';

import { AlertStateInfo, Description, TotalScore, MitreAttackBox, Origin, VendorLink } from './_components';
import { AlertStateInfoDetails, ThreatIntelligenceDescription, ThreatIntelligenceMatches } from '@/app/_common/types';
import { Severity } from '@/generated/graphql';
import { AlertConfidence, AlertTypes } from '@/app/_common/constants';
import { Divider } from '@/app/_common/_components/divider';
import { MitreAttackBadgeText } from '@/app/_common/_components/mitre-attack-badge/_constants/mitre-attack-badge-text';
import { SummaryMainContent } from './_components/summary-main-content/summary-main-content';
import { AlertDetailsSeverity } from './_components/alert-details-severity';
import { AlertDetailsConfidence } from './_components/alert-details-confidence';
import { AlertsDetailsTimeline } from '../alerts-details-timeline/alerts-details-timeline';
import { AlertHostUserBox } from './_components/alert-host-user-box';
import { getAlertVendorKey } from '@/app/_common/_components/details-view/alerts-details-view-navigation';
import { AlertVendorKey } from '@/app/_common/_components/details-view/types';

import styles from './summary-section.module.scss';

interface SummarySectionProps {
	severity: Severity;
	confidence: AlertConfidence;
	description: string;
	recommendation?: string | null;
	alertType?: AlertTypes;
	totalScore: number;
	mitreCategory?: MitreAttackBadgeText;
	origin?: string;
	loadingAssignedInvestigation: boolean;
	alertStateInfoDetails: AlertStateInfoDetails;
	alertExternalReferenceUrl: string;
	alertEventSource: string;
	threatIntelligenceMatches: ThreatIntelligenceMatches;
	threatIntelligenceDescriptions: ThreatIntelligenceDescription[];
}

export const SummarySection: FC<SummarySectionProps> = ({
	severity,
	confidence,
	description,
	recommendation,
	alertType,
	totalScore,
	mitreCategory,
	origin,
	loadingAssignedInvestigation,
	alertStateInfoDetails,
	alertExternalReferenceUrl,
	alertEventSource,
	threatIntelligenceMatches,
	threatIntelligenceDescriptions,
}) => {
	const vendorKey = getAlertVendorKey(alertEventSource);
	const isBoostAlert = alertType === AlertTypes.BOOST;
	const shouldShowAlertHostUserBox = [AlertVendorKey.CROWDSTRIKE_FALCON, AlertVendorKey.MICROSOFT_DEFENDER, AlertVendorKey.OKTA_WORKFORCE].includes(
		vendorKey as AlertVendorKey,
	);

	return (
		<div className={styles.container}>
			<div className={styles.sidebar}>
				<div className={styles.severityConfidenceContainer}>
					<AlertDetailsSeverity severity={severity} />
					<AlertDetailsConfidence confidence={confidence} />
				</div>
				<Description className={styles.sidebarElement} description={description} />
				<Origin className={styles.sidebarElement} origin={origin} />
				<TotalScore className={styles.sidebarElement} score={totalScore} />
				{alertExternalReferenceUrl && (
					<VendorLink className={styles.sidebarElement} link={alertExternalReferenceUrl} eventSource={alertEventSource} />
				)}
				<AlertStateInfo
					className={styles.sidebarElement}
					loadingAssignedInvestigation={loadingAssignedInvestigation}
					alertStateInfoDetails={alertStateInfoDetails}
				/>
				<Divider className={styles.divider} />
				<MitreAttackBox className={styles.sidebarElement} text={mitreCategory} alertEventSource={alertEventSource} />
			</div>
			<div className={classNames(styles.mainContent)}>
				{isBoostAlert && <AlertsDetailsTimeline />}
				{shouldShowAlertHostUserBox && <AlertHostUserBox />}
				<SummaryMainContent
					alertEventSource={alertEventSource}
					recommendation={recommendation}
					threatIntelligenceMatches={threatIntelligenceMatches}
					threatIntelligenceDescriptions={threatIntelligenceDescriptions}
				/>
			</div>
		</div>
	);
};
