import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import { Namespaces } from '@/translations/namespaces';
import { Tooltip } from '@/app/_common/_components';
import { buttonize } from '@/app/_common/utils';

import styles from './copy-id.module.scss';

const HIDE_COPIED_TEXT_TIMEOUT = 2500;

interface CopyIdProps {
	id: string;
}

export const CopyId: FC<CopyIdProps> = ({ id }) => {
	const [active, setActive] = useState(false);
	const { t } = useTranslation([Namespaces.DetailsView]);

	useEffect(() => {
		let timeout: ReturnType<typeof setTimeout>;
		if (active) {
			timeout = setTimeout(() => {
				setActive(false);
			}, HIDE_COPIED_TEXT_TIMEOUT);
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [active]);

	const handleCopyId = async () => {
		setActive(true);
		await navigator.clipboard.writeText(id);
	};

	return (
		<>
			<div className={styles.copyId} {...buttonize<HTMLDivElement>(handleCopyId)}>
				<div className={styles.icon} data-tip data-for={id}>
					{t('header.id')}
				</div>
				<div className={classNames(styles.copy, { [styles.active]: active })}>{active ? t('header.copied') : t('header.copy')}</div>
			</div>
			<Tooltip tooltipId={id} effect="solid" tooltipContent={id} className={styles.tooltip} />
		</>
	);
};
