import { isStackedChartDataItem } from '@/app/advanced-query/_common/types';
import { TOOLTIP_STYLES } from '@/app/_common/constants';
import { TooltipData } from '@/app/_common/types';

export const heightMap = {
	resultsOneRow: TOOLTIP_STYLES.minHeight,
	resultsTwoRows: 74,
	resultsThreeRows: 90,
	resultsFourRows: 106,
};

interface GetTooltipHeight {
	barData: TooltipData['bar']['data'];
	isStackedBarChart?: boolean;
}

export const getTooltipHeight = ({ barData, isStackedBarChart = true }: GetTooltipHeight) => {
	if (!isStackedChartDataItem(barData)) {
		return heightMap.resultsOneRow;
	}

	if (isStackedBarChart) {
		const { alertsCount, eventsCount, evidenceCount } = barData;
		const countsGreaterThanZero = [alertsCount, eventsCount, evidenceCount].filter((count) => count > 0);

		if (countsGreaterThanZero.length === 1) {
			return heightMap.resultsTwoRows;
		}

		if (countsGreaterThanZero.length === 2) {
			return heightMap.resultsThreeRows;
		}

		if (countsGreaterThanZero.length === 3) {
			return heightMap.resultsFourRows;
		}
	}

	return heightMap.resultsOneRow;
};
