import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded';
import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';
import { buttonize } from '@/app/_common/utils';
import { AlertsKeyPrefix } from '@/app/_common/constants';
import { AdvancedQueryAlertDetailsViewStore } from '@/app/_common/_components/details-view/stores';

import styles from './open-results-table-alert-details-action.module.scss';

interface OpenResultsTableAlertDetailsActionProps {
	itemId: string;
	closeContextMenu?: () => void;
	className?: string;
}

export const OpenResultsTableAlertDetailsAction = observer(
	({ closeContextMenu, className = '', itemId = '' }: OpenResultsTableAlertDetailsActionProps) => {
		const alertDetailsViewStore = useInstance(AdvancedQueryAlertDetailsViewStore);
		const { selectedResultsIds = [] } = alertDetailsViewStore;
		const { t } = useTranslation(Namespaces.AlertsDashboard, { keyPrefix: AlertsKeyPrefix.GridContextMenu });

		const showResultsTableAlertDetails = () => {
			alertDetailsViewStore.setSelectedAlertId(itemId);
			closeContextMenu?.();
		};

		if (!itemId) {
			return null;
		}

		const selectedCount = selectedResultsIds.length;
		const isCurrentIdSelected = selectedResultsIds.includes(itemId);
		const count = isCurrentIdSelected ? selectedCount : 0;

		return (
			<div className={classNames(styles.container, className)} {...buttonize(showResultsTableAlertDetails)}>
				<NotificationImportantRoundedIcon />
				<div>
					{t('openResultsTableAlertDetails')} {Boolean(count) && `(${count})`}
				</div>
			</div>
		);
	},
);
