import { QUERY_OPERATOR } from '@/app/_common/constants';
import { findLastOperatorIndex } from './find-last-operator-index';

export const shouldFetchChartData = (query: string): boolean => {
	if (query.includes(QUERY_OPERATOR.PROJECT)) {
		const { queryParts, lastOperatorIndex } = findLastOperatorIndex(query);
		const pattern = /\btimestamp\b/;
		const isTimestampColumnIncluded = pattern.test(queryParts[lastOperatorIndex]);

		if (queryParts[lastOperatorIndex].startsWith(QUERY_OPERATOR.PROJECT_AWAY) && isTimestampColumnIncluded) {
			return false;
		}

		return isTimestampColumnIncluded;
	}

	return true;
};
