import { MitreAttackBadge } from '@/app/_common/_components/mitre-attack-badge';
import { DefaultDropdownItemContainer } from '@/app/_common/_components/data-grid/_components/multi-value-cell/components/default-dropdown-item-container';
import { DropdownItemComponentProps } from '../types';
import { getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';

export const DropdownMitreTacticsItem = ({ value, searchValue, isCellHighlighted, isContextMenuOpen }: DropdownItemComponentProps) => {
	const mitreText = getMitreAttackBadgeText(String(value));

	return (
		<DefaultDropdownItemContainer value={value} isCellHighlighted={isCellHighlighted} isContextMenuOpen={isContextMenuOpen}>
			<MitreAttackBadge text={mitreText} searchValue={searchValue} />
		</DefaultDropdownItemContainer>
	);
};
