import { gql } from '@apollo/client';
import { CoreResponseIntegrationFragment } from '@/app/_common/graphql';

export const GetAllResponseIntegrations = gql`
	query ListIntegrations($tenantId: ID!, $input: Paging, $filtersInput: FiltersInput) {
		listIntegrations(tenantId: $tenantId, input: $input, filtersInput: $filtersInput) {
			edges {
				node {
					...CoreResponseIntegrationFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}
	${CoreResponseIntegrationFragment}
`;
