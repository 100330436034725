import { useInstance } from 'react-ioc';

import { useSubscription } from '@apollo/client';

import {
	Endpoint,
	Subscription,
	SubscriptionEndpointUpdatedArgs,
	EndpointsConnection,
	SubscriptionEndpointCreatedArgs,
	EndpointEdge,
} from '@/generated/graphql';

import { AuthStore } from '@/app/_common/stores';
import { GraphqlClient, CoreEndpointFragment } from '@/app/_common/graphql';
import { EndpointTypename } from '@/app/_common/constants';
import { CompositeFilterDescriptorWithId } from '@/app/_common/types';
import { extendFilterDescriptor } from '@/app/_common/utils';
import { EndpointUpdatedSubscription, EndpointCreatedSubscription } from '@/app/_common/graphql/queries';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { EndpointsListViewStore } from '@/app/response/endpoints/_common/stores';
import { filterBy } from '@progress/kendo-data-query';

export const listEndpointsUpdater = (
	existingEndpointRefs: EndpointsConnection,
	readField: ReadFieldFunction,
	client: GraphqlClient,
	data: Endpoint,
) => {
	const node = client.cache.writeFragment({
		fragment: CoreEndpointFragment,
		data,
	});

	const newEdge = {
		node,
		__typename: EndpointTypename.EndpointEdge,
		cursor: '',
	};

	const exists = existingEndpointRefs?.edges?.some((ref) => data.__typename === EndpointTypename.Endpoint && readField('id', ref.node) === data.id);

	if (exists) {
		return existingEndpointRefs;
	}

	return {
		...(existingEndpointRefs || {}),
		edges: [...(existingEndpointRefs.edges || []), newEdge],
	};
};

export const mergeCreateEndpointSubscription = (client: GraphqlClient, data?: Endpoint, filter?: CompositeFilterDescriptorWithId): void => {
	if (!data) {
		return;
	}

	if (filter) {
		const filterDescriptor = extendFilterDescriptor(filter);
		const filteredData = filterBy([{ node: data } as EndpointEdge], filterDescriptor);

		if (filteredData.length === 0) {
			return;
		}
	}

	client.cache.modify({
		fields: {
			listEndpoints(existingEndpointRefs: EndpointsConnection, { readField }) {
				listEndpointsUpdater(existingEndpointRefs, readField, client, data);
			},
		},
	});
};

export const mergeUpdateEndpointSubscription = (client: GraphqlClient, data?: Endpoint, filter?: CompositeFilterDescriptorWithId): void => {
	if (!data) {
		return;
	}

	if (filter) {
		const filterDescriptor = extendFilterDescriptor(filter);
		const filteredData = filterBy([{ node: data } as EndpointEdge], filterDescriptor);

		if (filteredData.length === 0) {
			return;
		}
	}

	const identifier = client.cache.identify(data);

	client.cache.modify({
		id: identifier,
		fields: {
			action() {
				return data.action;
			},
			currentStatus() {
				return data.currentStatus;
			},
			hostname() {
				return data.hostname;
			},
			id() {
				return data.id;
			},
			last_updated() {
				return data.last_updated;
			},
			networkInformation() {
				return data.networkInformation;
			},
			operatingSystem() {
				return data.operatingSystem;
			},
			osVendor() {
				return data.osVendor;
			},
			responseIntegration() {
				return data.responseIntegration;
			},
			statistics() {
				return data.statistics;
			},
			tenantId() {
				return data.tenantId;
			},
			timestamp() {
				return data.timestamp;
			},
			externalReference() {
				return data.externalReference;
			},
		},
	});
};

export const useEndpointsSubscription = () => {
	const authStore = useInstance(AuthStore);
	const graphqlClient = useInstance(GraphqlClient);
	const endpointsStore = useInstance(EndpointsListViewStore);

	useSubscription<Subscription, SubscriptionEndpointUpdatedArgs>(EndpointUpdatedSubscription, {
		client: graphqlClient,
		variables: {
			tenantId: authStore.currentTenantId,
		},
		onSubscriptionData: ({ subscriptionData }) => {
			mergeUpdateEndpointSubscription(graphqlClient, subscriptionData?.data?.endpointUpdated, endpointsStore.filter);
		},
	});

	useSubscription<Subscription, SubscriptionEndpointCreatedArgs>(EndpointCreatedSubscription, {
		client: graphqlClient,
		variables: {
			tenantId: authStore.currentTenantId,
		},
		onSubscriptionData: ({ subscriptionData }) => {
			mergeCreateEndpointSubscription(graphqlClient, subscriptionData?.data?.endpointCreated, endpointsStore.filter);
		},
	});
};
