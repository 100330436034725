import { FC } from 'react';
import _isNil from 'lodash/isNil';

import styles from './data-header-counter.module.scss';

interface DataHeaderCounter {
	count?: number;
}

export const DataHeaderCounter: FC<DataHeaderCounter> = ({ count }) => {
	if (_isNil(count)) {
		return null;
	}

	return <div className={styles.counter}>({count})</div>;
};
