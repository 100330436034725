import { makeAutoObservable } from 'mobx';

import { UsersDataStore } from '@/app/_common/stores/users-store/users.data-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { DropdownOption } from '@/app/_common/_components/forms/_common/dropdown-option.interface';

export class AssigneeSelectViewStore {
	private assigneeDataStore = injectInterface(this, UsersDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get data() {
		const unassignedAssignee: DropdownOption = { value: null, label: 'Unassigned' };

		let assignees: DropdownOption[] =
			this.assigneeDataStore.users?.map(({ node }) => ({
				value: node.id as string | null,
				label: node.displayName || '',
			})) || [];

		assignees = [unassignedAssignee, ...assignees];

		return assignees;
	}

	get loading(): boolean {
		return this.assigneeDataStore.loading;
	}
}
