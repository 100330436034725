import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDetailsKeyPrefix } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';
import { ThreatIntelligenceTag } from '../_common/_components';

import styles from './threat-intelligence-panel-content-tags-section.module.scss';

interface ThreatIntelligencePanelContentTagsSectionProps {
	tags: string[];
	className?: string;
}

export const ThreatIntelligencePanelContentTagsSection: FC<ThreatIntelligencePanelContentTagsSectionProps> = ({ tags, className = '' }) => {
	const { t } = useTranslation(Namespaces.DetailsView, { keyPrefix: AlertDetailsKeyPrefix.ThreatIntelligencePanel });

	if (!tags.length) {
		return null;
	}

	return (
		<div className={className}>
			<h4>{t('tags')}</h4>
			<div className={styles.tagsContainer}>
				{tags.map((tag) => (
					<ThreatIntelligenceTag key={tag} tag={tag} />
				))}
			</div>
		</div>
	);
};
