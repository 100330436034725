import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';
import _isNil from 'lodash/isNil';

import { FilterDescriptor } from '@progress/kendo-data-query';

import { ADX_DEFAULT_PAGE_SIZE, FilterGroupId, FilterLogic, SortDirection } from '@/app/_common/constants';
import {
	DataGridDataItem,
	FilterOption,
	Filters,
	FilterValue,
	ResultsDataItem,
	ResultsFieldValue,
	AlertSpecialFieldNames,
	CompositeFilterDescriptorWithId,
} from '@/app/_common/types';

import { ResultsTablePropertiesPaths } from '@/app/_common/constants/advanced-query-results-table.constants';
import { sortResults } from '@/app/_common/utils/sort/sort-results';
import {
	getNullishFilterOption,
	removeIrrelevantObjectProperties,
	isInternalColumn,
	isTableColumn,
	extractResultsTableItemId,
} from '@/app/_common/utils';
import { Columns } from '@/app/_common/_components/data-grid/types';
import {
	mapFilterOption,
	getAdvancedQueryConfidenceOption,
	getSeverityOption,
	getMitreAttackOption,
} from '@/app/_common/_components/data-grid/utils';
import { getDetailsViewRowId, getDetailsViewTypename } from '@/app/_common/_components/details-view/utils';
import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { getColumnsWithoutEmptyValues } from '@/app/_common/_components/query-results/query-results-table/utils';
import { DataGridViewStore } from '@/app/_common/_components/data-grid/data-grid.view-store';

const INITIAL_STATE: State = {
	hideEmpty: true,
	searchValue: '',
	columnsForSelectionOptions: {}, // Initial state of columns
	userToggledColumns: {}, // State of columns toggled by user
	showInternalColumns: false,
	queryResults: [],
};

interface State {
	hideEmpty: boolean;
	searchValue: string;
	columnsForSelectionOptions: Record<string, boolean>;
	userToggledColumns: Record<string, boolean>;
	showInternalColumns: boolean;
	queryResults: ResultsDataItem[];
}

const INITIAL_COLUMNS = { [ResultsTablePropertiesPaths.Actions]: false };

const INITIAL_SORT = [{ field: ResultsTablePropertiesPaths.Timestamp, dir: SortDirection.Desc }];

const ADVANCED_QUERY_RESULTS_VERSION = 'v0'; // change it when you're changing columns

const CUSTOM_COLUMNS_TO_HIDE = [
	ResultsTablePropertiesPaths.Raw,
	ResultsTablePropertiesPaths.Id,
	ResultsTablePropertiesPaths.Expanded,
	ResultsTablePropertiesPaths.Selected,
	ResultsTablePropertiesPaths.Disabled,
];

const DATE_TIME_PROPERTY_NAME = ResultsTablePropertiesPaths.Timestamp;
const TYPE_PROPERTY_NAME = ResultsTablePropertiesPaths.Type;
const TABLE_PROPERTY_NAME = ResultsTablePropertiesPaths.Table;

const EMPTY_FIELD_VALUE = 'empty';

export abstract class QueryResultsTableViewStore extends DataGridViewStore<ResultsDataItem> {
	private state: State = INITIAL_STATE;

	constructor() {
		super(
			ResultsTablePropertiesPaths.Id,
			INITIAL_COLUMNS,
			INITIAL_SORT,
			sortResults,
			false,
			ADVANCED_QUERY_RESULTS_VERSION,
			undefined,
			undefined,
			ADX_DEFAULT_PAGE_SIZE,
		);

		makeObservable(this, {
			//@ts-ignore
			state: observable,
			hideEmpty: computed,
			results: computed,
			allColumns: computed,
			page: computed,
			totalResults: computed,
			totalResultsCount: computed,
			allItemsIds: computed,
			selectedItems: computed,
			selectedResultsIds: computed,
			setPageSize: action,
			toggleHideEmpty: action,
			setSearchValue: action,
			getFilterOptions: action,
			getGridHeaderFilterValues: action,
			setGridHeaderFilter: action,
			resetGridHeaderFilter: action,
			dispose: action,
			setQueryResults: action,
			toggleExpandedRow: action,
			toggleDataItemSelection: action,
		});
	}

	get hideEmpty() {
		return this.state.hideEmpty;
	}

	get selected() {
		return this.gridState.selectedRows;
	}

	get allColumns() {
		const { columnsData } = this.generateColumnsData(this.data, true);
		return columnsData;
	}

	get totalResults(): DataGridDataItem<ResultsDataItem>[] {
		if (!this.state.searchValue) {
			return this.data;
		}

		const searchValueLowerCase = this.state.searchValue.toLowerCase();

		return this.data.reduce<DataGridDataItem<ResultsDataItem>[]>((acc, item) => {
			const clearedItem = removeIrrelevantObjectProperties(item) as DataGridDataItem<ResultsDataItem>;
			const searchMatched = Object.values(clearedItem).some((value) => String(value).toLowerCase().includes(searchValueLowerCase));

			if (searchMatched) {
				acc.push(clearedItem);
			}

			return acc;
		}, []);
	}

	get results() {
		return this.totalResults.slice(this.page.skip, this.page.skip + this.page.take);
	}

	get resultsColumnNames() {
		return this.getAllColumnNamesForResultsTable();
	}

	get allItemsIds(): string[] {
		return this.data.map((item: DataGridDataItem<ResultsDataItem>) => getDetailsViewRowId(item));
	}

	/*
	 * As 'alert_id' may not be unique and is needed to fetch alert details data for the <AlertsDetails /> component,
	 * and thus is encoded into a unique token to be used as a unique data grid key, extracting it here from data grid 'selected' items.
	 * */
	get selectedResultsIds(): string[] {
		return Object.keys(this.selectedRows).filter((itemId: string) => this.selectedRows[itemId] && this.allItemsIds.includes(itemId));
	}

	get selectedItems(): DataGridDataItem<ResultsDataItem>[] {
		return this.data.filter((item: DataGridDataItem<ResultsDataItem>) => {
			const itemId = getDetailsViewRowId(item);
			return this.selectedResultsIds.includes(itemId);
		});
	}

	getSelectedResultsIds = (typename: DetailsViewTypename) => {
		const itemsSet = new Set(
			this.data.reduce((acc: string[], item) => {
				if (getDetailsViewTypename(item) === typename) {
					acc.push(getDetailsViewRowId(item));
				}
				return acc;
			}, []),
		);

		return Object.keys(this.selected)
			.filter((encodedResultId) => this.selected[encodedResultId])
			.map(extractResultsTableItemId)
			.filter((resultId) => itemsSet.has(resultId));
	};

	getAllColumnNamesForResultsTable = computedFn(() => {
		const removedFixedColumnNames = this.removeFixedColumnNames(Object.keys(this.resultsColumns));
		const columnNames = removedFixedColumnNames.columnNames;
		columnNames.unshift(...removedFixedColumnNames.fixedColumns);

		return columnNames.filter(this.filterByInternalColumns);
	});

	get resultsColumnNamesForCsvExport() {
		return this.getAllColumnNamesForResultsTable().filter((columnName: string) => this.columns[columnName]);
	}

	get resultsColumns() {
		return getColumnsWithoutEmptyValues(this.rawData, CUSTOM_COLUMNS_TO_HIDE);
	}

	get resultsColumnOptions() {
		return this.getColumnNamesForOptions().map((key: string) => ({
			id: key,
			label: key,
			checked: this.columns[key],
		}));
	}

	getColumnNamesForOptions = computedFn(() => {
		return this.removeFixedColumnNames(Object.keys(this.resultsColumns)).columnNames;
	});

	get columnsForSelectionOptions() {
		return this.state.columnsForSelectionOptions;
	}

	get totalResultsCount() {
		return this.totalResults.length;
	}

	get searchValue() {
		return this.state.searchValue;
	}

	get showInternalColumns(): State['showInternalColumns'] {
		return this.state.showInternalColumns;
	}

	setPageSize = (pageSize: number) => {
		this.gridState.page = {
			...this.gridState.page,
			take: pageSize,
		};
	};

	setSearchValue = (newValue: string) => {
		this.state.searchValue = newValue;
	};

	toggleHideEmpty = () => {
		this.state.hideEmpty = !this.state.hideEmpty;
	};

	setResultsTableColumns = (columns: Record<string, boolean>) => {
		const columnsWithCurrentVisibilityState = this.getColumnsWithCurrentVisibilityState(columns);
		this.setColumns(columnsWithCurrentVisibilityState);
	};

	toggleExpandedRow = (itemId: string) => {
		this.gridState.expandedRows = {
			...this.gridState.expandedRows,
			[itemId]: !this.gridState.expandedRows[itemId],
		};
	};

	isSelected = (id: string) => this.gridState.selectedRows[id] === true;

	toggleDataItemSelection = (dataItem: { _id: string }) => {
		const id = dataItem[ResultsTablePropertiesPaths.Id];
		if (!this.isSelected(id)) {
			this.toggleSelectedRow(id);
		}
	};

	getFilterOptions = runInAction(() => {
		return (field: string) => {
			const counters = this.getCountedValues(field);

			const options: FilterOption[] = this.getUniqValues(field)
				.map((value) => {
					return mapFilterOption(
						field,
						{ value: value as FilterValue, counter: counters[value as string], field },
						{
							[AlertSpecialFieldNames.Confidence]: getAdvancedQueryConfidenceOption,
							[AlertSpecialFieldNames.Severity]: getSeverityOption,
							[AlertSpecialFieldNames.MitreTactics]: getMitreAttackOption,
							[AlertSpecialFieldNames.MitreTechniques]: getMitreAttackOption,
						},
					);
				})
				.filter(({ value }) => value);

			const emptyValuesCount = counters[EMPTY_FIELD_VALUE];

			if (emptyValuesCount > 0) {
				options.unshift(getNullishFilterOption('-', EMPTY_FIELD_VALUE, counters[EMPTY_FIELD_VALUE]));
			}

			return options;
		};
	});

	getGridHeaderFilterValues = computedFn((field: string) => {
		const filter = this.getFilter(FilterGroupId.gridHeader, field);

		if (!filter || !filter.filters) {
			return [];
		}

		return filter.filters.reduce<string[]>((result, filterDescriptor) => {
			if ('value' in filterDescriptor) {
				result.push(filterDescriptor.value);
			}
			return result;
		}, []);
	});

	setGridHeaderFilter = (field: string, values: Filters) => {
		const filters = values.map<FilterDescriptor>((value) => {
			// eslint-disable-next-line @typescript-eslint/ban-types
			const operator: Function = this.gridHeaderFilterFunction;
			return { value, field, operator, ignoreCase: false };
		});

		this.setFilter({
			id: FilterGroupId.gridHeader,
			filters,
			logic: FilterLogic.And,
			nestedId: field,
			nestedLogic: FilterLogic.Or,
		});
	};

	resetGridHeaderFilter = (field: string) => {
		this.resetFilter(FilterGroupId.gridHeader, field);
	};

	resetColumnsWithConsideringEmptyValues = () => {
		this.state.userToggledColumns = {};
		this.setColumns({ ...this.columnsForSelectionOptions });
	};

	toggleOptionsColumn = (field: string): void => {
		this.state.userToggledColumns[field] = !this.columns[field];
		this.toggleColumn(field);
	};

	private gridHeaderFilterFunction = (fieldValue: ResultsFieldValue, filterValue: FilterValue): boolean => {
		if (filterValue && Array.isArray(fieldValue)) {
			// 'never' below is because typescript complains of both string[] and number[] as possible fieldValue
			return fieldValue.includes(filterValue as never);
		}
		return fieldValue === filterValue;
	};

	private generateColumnsData = (
		results: ResultsDataItem[],
		hideEmpty: boolean = this.hideEmpty,
	): { columnsData: Record<string, boolean>; sourceData: ResultsDataItem[] } => {
		const sourceData: ResultsDataItem[] = [];
		const columnsData: Record<string, boolean> = {};

		results.forEach((row: ResultsDataItem) => {
			const newRow: ResultsDataItem = {};

			Object.keys(row).forEach((column) => {
				const value = row[column];
				const isEmpty = _isNil(value) || value === '';

				if (isEmpty) {
					newRow[column] = null;
				} else {
					newRow[column] = value;
				}

				if (!columnsData[column]) {
					columnsData[column] = hideEmpty ? !isEmpty : true;
				}
			});

			sourceData.push(newRow);
		});

		return {
			sourceData,
			columnsData,
		};
	};

	private removeFixedColumnNames(columnNames: string[]): { columnNames: string[]; fixedColumns: string[] } {
		const dateTimeColumnIndex = columnNames.findIndex((item) => item === DATE_TIME_PROPERTY_NAME);
		const fixedColumns = [];

		if (dateTimeColumnIndex !== -1) {
			// ResultsTablePropertiesPaths.Timestamp column is always displayed in the results table. Thus, removing it from toggle options.
			fixedColumns.push(...columnNames.splice(dateTimeColumnIndex, 1));
		}

		const typeColumnIndex = columnNames.findIndex((item) => item === TYPE_PROPERTY_NAME);

		if (typeColumnIndex !== -1) {
			// ResultsTablePropertiesPaths.Type column is always displayed in the results table. Thus, removing it from toggle options.
			fixedColumns.push(...columnNames.splice(typeColumnIndex, 1));
		}

		const tableColumnIndex = columnNames.findIndex((item) => item === TABLE_PROPERTY_NAME);

		if (tableColumnIndex !== -1) {
			// ResultsTablePropertiesPaths.Type column is always displayed in the results table. Thus, removing it from toggle options.
			fixedColumns.push(...columnNames.splice(tableColumnIndex, 1));
		}

		const filterByEmptyValues = (columnName: string) => (this.hideEmpty ? this.columnsForSelectionOptions[columnName] : true);

		return { columnNames: columnNames.filter(this.filterByInternalColumns).filter(filterByEmptyValues), fixedColumns };
	}

	setQueryResults = (queryResults: ResultsDataItem[]) => {
		this.state.queryResults = queryResults;
	};

	resultsDisposer = reaction(
		() => this.state.queryResults,
		(results: ResultsDataItem[]): void => {
			const { sourceData, columnsData } = this.generateColumnsData(results);

			this.sourceData = sourceData;
			this.state.userToggledColumns = {};
			this.setResultsTableColumns(columnsData);
			this.setPage({ ...this.page, skip: 0 });
		},
	);

	hideEmptyDisposer = reaction(
		() => this.hideEmpty,
		(hideEmpty) => {
			const { columnsData } = this.generateColumnsData(this.data, hideEmpty);

			this.setResultsTableColumns(columnsData);
		},
	);

	private getColumnsWithCurrentVisibilityState = (columnsData: Columns): Columns => {
		this.state.columnsForSelectionOptions = { ...columnsData };

		const columns = { ...columnsData };

		this.getColumnNamesForOptions().forEach((columnName: string) => {
			if (columnName in this.state.userToggledColumns) {
				columns[columnName] = this.state.userToggledColumns[columnName];
			}
		});

		return columns;
	};

	dataDisposer = reaction(
		() => this.totalResults,
		(results) => {
			if (results.length - this.page.take < 0) {
				this.setPage({ ...this.page, skip: 0 });
				return;
			}

			const pageData = results.slice(this.page.skip, this.page.skip + this.page.take);

			if (pageData.length === 0) {
				const skip = Math.floor(results.length / this.page.take) * this.page.take;
				this.setPage({ ...this.page, skip });
			}
		},
	);

	dispose() {
		this.dataDisposer();
		this.resultsDisposer();
		this.hideEmptyDisposer();
	}

	protected findFilter = (id: string) => (filter: CompositeFilterDescriptorWithId | FilterDescriptor) => {
		if ('id' in filter) {
			return filter.id === id;
		}

		return false;
	};

	private filterByInternalColumns = (columnName: string) => {
		const isInternal = !isTableColumn(columnName) && isInternalColumn(columnName);

		return this.showInternalColumns ? true : !isInternal;
	};
}
