import { TableColumn as ITableColumn, Schema, TimeRangeFilterOption, FieldFilterGroup } from '@/app/_common/types';
import { AdxTables } from '@/app/_common/constants';
import { getUnionQuery } from '@/app/_common/utils/adx/prepare-available-fields-query/get-union-query';
import { getColumnQuery } from '@/app/_common/utils/adx/prepare-available-fields-query/get-column-query';
import { getAvailableFieldsBaseQuery } from '@/app/_common/utils/adx/prepare-available-fields-query/get-available-fields-base-query';
import { DATA_VARIABLE_NAME } from '@/app/_common/utils/adx/prepare-available-fields-query/prepare-available-fields-query.constants';

interface PrepareAvailableFieldsQueryProps {
	table: AdxTables;
	query: string;
	columns: ITableColumn[];
	schema: Schema;
	database: string;
	timeRange: TimeRangeFilterOption;
	filtersList: FieldFilterGroup[];
}

export const prepareAvailableFieldsQuery = ({
	table,
	query,
	columns,
	schema,
	database,
	timeRange,
	filtersList = [],
}: PrepareAvailableFieldsQueryProps): string => {
	if (columns.length === 0) {
		return '';
	}

	const tableQuery = getAvailableFieldsBaseQuery({
		table,
		query,
		columns,
		schema,
		database,
		timeRange,
		filtersList,
	});
	const tableQueryVariable = `let ${DATA_VARIABLE_NAME} = ${tableQuery};`;
	const columnsQueryList = columns.map((column) => getColumnQuery(column)).join('\n');
	const unionQuery = getUnionQuery(columns);

	return [tableQueryVariable, columnsQueryList, unionQuery].join(`\n`) + ';';
};
