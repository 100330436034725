import { makeAutoObservable, reaction } from 'mobx';

import { ChartData, ChartStackBarKeys, FieldFilterGroup, NotificationMessage, Schema, TimeRangeFilterOption } from '@/app/_common/types';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AdxSchemaDataStore, AuthStore } from '@/app/_common/stores';
import { AdvancedQueryChartDataStore } from './advanced-query-chart.data-store';
import { AdvancedQueryViewStore } from '@/app/advanced-query/_common/stores/advanced-query.view-store';
import { prepareAdvancedQueryPageChartQuery } from '@/app/_common/utils';
import {
	AUTO_INTERVALS_RANGES,
	AUTO_INTERVAL_UNIQUE_PRIVATE_KEY,
	DateTimePickerOptionKeys,
	MAXIMUM_BARS_NUMBER,
	RELATIVE_DATE_TIME_RANGES_AS_SECONDS,
} from '@/app/_common/constants';
import moment from 'moment';
import { isStackedChartDataItemArray } from '../types';
import { AdvancedQueryChartSettingsViewStore } from './advanced-query-chart-settings.view-store';
import { shouldFetchChartData } from '@/app/_common/utils/adx/should-fetch-chart-data';

interface State {
	interval: { selected?: string; value?: number; error: boolean };
	isChartCollapsed: boolean;
}

const INITIAL_STATE: State = {
	interval: { selected: AUTO_INTERVAL_UNIQUE_PRIVATE_KEY, value: undefined, error: false },
	isChartCollapsed: false,
};

export class AdvancedQueryChartViewStore {
	dataStore = injectInterface(this, AdvancedQueryChartDataStore);
	private advancedQueryViewStore = injectInterface(this, AdvancedQueryViewStore);
	private schemaDataStore = injectInterface(this, AdxSchemaDataStore);
	private advancedQueryChartSettingsViewStore = injectInterface(this, AdvancedQueryChartSettingsViewStore);
	private authStore = injectInterface({}, AuthStore);
	private state: State = INITIAL_STATE;
	private stackedBarsDefaultValue = true;

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get error(): NotificationMessage | undefined {
		return this.dataStore.error;
	}

	get data(): ChartData | undefined {
		return this.dataStore.data;
	}

	get loading(): boolean {
		return this.dataStore.loading;
	}

	get query(): string {
		return this.advancedQueryViewStore.query;
	}

	get timeRange(): TimeRangeFilterOption {
		return this.advancedQueryViewStore.timeRange;
	}

	get filters(): FieldFilterGroup[] {
		return this.advancedQueryViewStore.filters;
	}

	get hasQuery(): boolean {
		return Boolean(this.query);
	}

	get schema(): Schema {
		return this.schemaDataStore.schema;
	}

	get hasSchema(): boolean {
		return this.schema !== undefined;
	}

	get currentTenantId(): string {
		return this.authStore.currentTenantId;
	}

	get chartData(): ChartData {
		return this.data ?? [];
	}

	get countTotals(): number[] {
		if (isStackedChartDataItemArray(this.chartData)) {
			return this.chartData.map((item) => item[ChartStackBarKeys.TotalCount]);
		}

		return this.chartData.map((item) => item[ChartStackBarKeys.EventsCount]);
	}

	get totalEvents(): number {
		const COUNT_FALLBACK = 0;
		let totalEventsCount;

		try {
			if (isStackedChartDataItemArray(this.chartData)) {
				totalEventsCount = this.chartData.reduce((total: number, item) => {
					const itemCount = parseInt(String(item[ChartStackBarKeys.TotalCount]));

					return total + itemCount;
				}, 0);
			} else {
				totalEventsCount = this.chartData.reduce((total: number, item) => {
					const itemCount = parseInt(String(item[ChartStackBarKeys.EventsCount]));

					return total + itemCount;
				}, 0);
			}

			if (typeof totalEventsCount === 'number') {
				return totalEventsCount;
			} else {
				return COUNT_FALLBACK;
			}
		} catch (error) {
			return COUNT_FALLBACK;
		}
	}

	get interval(): number | undefined {
		return this.state.interval?.value;
	}

	get isIntervalError(): boolean {
		return this.state.interval?.error;
	}

	get intervalSelected(): string | undefined {
		return this.state.interval.selected;
	}

	get isAutoInterval(): boolean {
		return this.state.interval.selected === AUTO_INTERVAL_UNIQUE_PRIVATE_KEY;
	}

	get isStackedBarChart(): boolean {
		return this.advancedQueryChartSettingsViewStore.isStackedBarChart ?? this.stackedBarsDefaultValue;
	}

	get isChartCollapsed(): boolean {
		return this.state.isChartCollapsed;
	}

	onSelectInterval = (selected?: string, value?: number): void => {
		const selectedTimeRangeInSeconds = this.getSelectedTimeRangeInSeconds();
		const isAutoIntervalSelected = selected === AUTO_INTERVAL_UNIQUE_PRIVATE_KEY;
		const exceedsMaximumBarsNumber = value && selectedTimeRangeInSeconds / value > MAXIMUM_BARS_NUMBER;

		if (isAutoIntervalSelected) {
			this.fetchChartDataWithAutoInterval();
		} else if (exceedsMaximumBarsNumber) {
			const correctedInterval = selectedTimeRangeInSeconds / MAXIMUM_BARS_NUMBER;

			this.setInterval(correctedInterval, AUTO_INTERVAL_UNIQUE_PRIVATE_KEY, true);
			this.fetchChartData();
		} else {
			this.setInterval(value, selected, false);
			this.fetchChartData();
		}
	};

	onStackedBarChartChange = () => {
		this.advancedQueryChartSettingsViewStore.setIsStackedBarChart(
			!(this.advancedQueryChartSettingsViewStore.isStackedBarChart ?? this.stackedBarsDefaultValue),
		);

		if (!isStackedChartDataItemArray(this.chartData)) {
			this.fetchChartData();
		}
	};

	setInterval(value: number | undefined, selected: string | undefined, error: boolean) {
		this.state.interval.value = value;
		this.state.interval.selected = selected;
		this.state.interval.error = error;
	}

	fetchChartDataWithAutoInterval = () => {
		this.calculateAutoInterval();
		this.fetchChartData();
	};

	public cancelRequest = () => this.dataStore.cancelRequest();

	// fetch data on first render only
	initialQueryDisposer = reaction(
		() => this.hasQuery,
		(hasQuery) => {
			if (hasQuery) {
				this.fetchChartDataWithAutoInterval();
				this.initialQueryDisposer();
			}
		},
	);

	dispose = () => {
		this.initialQueryDisposer();
	};

	private calculateAutoInterval(): void {
		const selectedTimeRangeInSeconds = this.getSelectedTimeRangeInSeconds();

		const autoInterval = AUTO_INTERVALS_RANGES.find(
			(range) => selectedTimeRangeInSeconds >= range.min && selectedTimeRangeInSeconds < range.max,
		)?.interval;

		this.setInterval(autoInterval, AUTO_INTERVAL_UNIQUE_PRIVATE_KEY, false);
	}

	private getSelectedTimeRangeInSeconds(): number {
		if (this.timeRange.key === DateTimePickerOptionKeys.CUSTOM) {
			const dateTo = moment(this.timeRange.value?.to);
			const dateFrom = moment(this.timeRange.value?.from);
			return dateTo.diff(dateFrom, 'seconds');
		}

		return RELATIVE_DATE_TIME_RANGES_AS_SECONDS[this.timeRange.key];
	}

	private fetchChartData = (): void => {
		if (this.hasSchema && this.hasQuery) {
			const query = prepareAdvancedQueryPageChartQuery({
				query: this.query,
				schema: this.schema,
				database: this.currentTenantId,
				timeRange: this.timeRange,
				interval: this.interval,
				filters: this.filters,
				isStackedBarChartQuery: this.isStackedBarChart,
			});

			const shouldFetch = shouldFetchChartData(this.query);

			this.state.isChartCollapsed = !shouldFetch;

			shouldFetch ? this.dataStore.fetchChartData(query) : this.dataStore.clear();
		}
	};
}
