import { FC, useCallback } from 'react';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Dialog } from '@/app/_common/_components/dialog/dialog';

import { Button } from '@/app/_common/_components/button';
import { AlertsKeyPrefix } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';
import { AlertsStateActionsViewStore } from '@/app/dashboards/alerts-dashboard/_common/stores';
import { AlertsDismissList } from '@/app/_common/_components/alerts-dismiss-list/alerts-dismiss-list';
import { AlertsDismissListViewStore } from './alerts-dismiss-list.view-store';

import styles from './alerts-dismiss-dialog.module.scss';

const DIALOG_WIDTH = 1256;

export const AlertsDismissDialog: FC = observer(() => {
	const store = useInstance(AlertsStateActionsViewStore);
	const {
		alertIds,
		selectedAssignCount,
		selectedRows,
		data,
		sort,
		selectedCount,
		totalCount,
		selectionHeaderMode,
		toggleSelectAllRows,
		setSort,
		setGridHeaderFilter,
		getStateFilterOptions,
		resetGridHeaderFilter,
		getCounter,
		getGridHeaderFilterValues,
		getFieldColumnState,
		getFilterOptions,
		getAlertsDetectedByMainFilters,
		getDetectedByVendorFilters,
		toggleSelectedRow,
		isRowSelected,
	} = useInstance(AlertsDismissListViewStore);
	const { t } = useTranslation([Namespaces.AlertsDashboard], { keyPrefix: AlertsKeyPrefix.DismissAlertDialog });

	const handleDialogClose = useCallback(() => {
		store.close();
	}, [store]);

	const handleSubmitButtonClick = useCallback(() => {
		store.dismissAlerts(alertIds);
		store.close();
	}, [store, alertIds]);

	if (!store.isOpen) {
		return null;
	}

	return (
		<Dialog
			className={styles.dialog}
			title={t('title', { total: selectedCount })}
			onClose={handleDialogClose}
			onSubmit={handleSubmitButtonClick}
			width={DIALOG_WIDTH}
			headerDivider={false}
			footerDivider={false}
			actions={
				<>
					<Button className={styles.button} onClick={handleDialogClose} fillMode="outline">
						{t('buttons.cancel')}
					</Button>
					<Button className={styles.button} onClick={handleSubmitButtonClick} disabled={alertIds.length === 0}>
						{t('buttons.dismiss')}
					</Button>
				</>
			}
		>
			<p>{t('message', { count: selectedAssignCount })}</p>
			<AlertsDismissList
				selected={selectedRows}
				data={data}
				sort={sort}
				totalCount={totalCount}
				selectionHeaderMode={selectionHeaderMode}
				toggleSelectAll={toggleSelectAllRows}
				setSort={setSort}
				setGridHeaderFilter={setGridHeaderFilter}
				getStateFilterOptions={getStateFilterOptions}
				resetGridHeaderFilter={resetGridHeaderFilter}
				getCounter={getCounter}
				getGridHeaderFilterValues={getGridHeaderFilterValues}
				getFieldColumnState={getFieldColumnState}
				getFilterOptions={getFilterOptions}
				getAlertsDetectedByMainFilters={getAlertsDetectedByMainFilters}
				getDetectedByVendorFilters={getDetectedByVendorFilters}
				isRowSelected={isRowSelected}
				toggleSelectedRow={toggleSelectedRow}
			/>
		</Dialog>
	);
});
