import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AdvancedQueryPageKeyPrefix } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';
import { ErrorDetailsContent, ErrorDetailsIcon, ErrorDetailsTitle } from '@/app/_common/_components';
import { NotificationMessage } from '@/app/_common/types';

import styles from './chart-empty-data.module.scss';

interface ChartEmptyDataProps {
	error?: NotificationMessage;
}

export const ChartEmptyData: FC<ChartEmptyDataProps> = ({ error }) => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });

	return (
		<div className={classNames(styles.content, { error: error })}>
			{error && <ErrorDetailsIcon />}
			<ErrorDetailsTitle title={t('chart.emptyData')} />
			{error && <ErrorDetailsContent error={error.content} buttonTitle={t('error.details')} />}
		</div>
	);
};
