import { TimeRangeFilterStore } from '@/app/_common/stores/time-range-filter-store';

const TIME_RANGE_FILTER_STORAGE_KEY = 'ss/dashboards/alerts/filters';
const STATE_OBJECT_NAME = 'alerts-widget-time-range';

export class AlertsTimeRangeFilterStore extends TimeRangeFilterStore {
	constructor() {
		super(TIME_RANGE_FILTER_STORAGE_KEY, STATE_OBJECT_NAME);
	}
}
