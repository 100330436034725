import moment from 'moment/moment';

import { TimeRangeFilterOptionValue } from '@/app/_common/types';
import { DATE_TIME_FORMAT } from '@/app/_common/constants';

export const getTimeRangeString = (timeRangeValue: Required<TimeRangeFilterOptionValue>, format = DATE_TIME_FORMAT): string => {
	const from = timeRangeValue.from;
	const to = timeRangeValue.to;
	return `${moment.utc(from).format(format)} - ${moment.utc(to).format(format)}`;
};
