import { makeAutoObservable } from 'mobx';

import _uniqueId from 'lodash/uniqueId';

import { AlertsDetailsNavigationDataStore } from '@/app/_common/_components/details-view/stores/alerts-details-navigation.data-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AlertTypes, SessionItem, Signature } from '@/app/_common/constants';
import { AlertsDetailsEvidenceNavigationDataStore } from '@/app/_common/_components/details-view/alerts-details-view-navigation';

interface State {
	activeBoostedAlert: string;
	zoom: number;
}

export class AlertsDetailsNavigationViewStore {
	private alertsFileApiDataStore = injectInterface(this, AlertsDetailsNavigationDataStore);
	private alertsEvidenceApiDataStore = injectInterface(this, AlertsDetailsEvidenceNavigationDataStore);

	private state: State = {
		activeBoostedAlert: '',
		zoom: 0.8,
	};

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get loading(): boolean {
		return this.alertsFileApiDataStore.loading || this.alertsEvidenceApiDataStore.loading;
	}

	get error() {
		return this.alertsFileApiDataStore.error || this.alertsEvidenceApiDataStore.error;
	}

	get fileApiDetails() {
		return this.alertsFileApiDataStore?.details;
	}

	get evidenceApiDetails() {
		return this.alertsEvidenceApiDataStore?.details;
	}

	get alertDetailsId(): string {
		return this.fileApiDetails?.event_id || '';
	}

	get activeBoostedAlert(): string {
		return this.state.activeBoostedAlert;
	}

	get zoom(): number {
		return this.state.zoom;
	}

	get alertType(): AlertTypes | undefined {
		return this.fileApiDetails?.type;
	}

	get signatures(): Signature[] | undefined {
		return this.fileApiDetails?.report?.details?.signatures
			?.map((signature) => {
				return {
					...signature,
					key: _uniqueId('boosted_alert_'),
				};
			})
			?.sort((a, b) => Date.parse(a.sessions[0].datetime) - Date.parse(b.sessions[0].datetime));
	}

	get triggeredAlertName(): string | undefined {
		return this.fileApiDetails?.name;
	}

	get triggeredAlertDate(): string | undefined {
		return this.fileApiDetails?.stopdate;
	}

	get singleAlertSessions(): SessionItem[] {
		return this.fileApiDetails?.report?.details?.signatures[0]?.sessions || [];
	}

	get singleAlertImportantFields(): string[] | undefined {
		return this.fileApiDetails?.report?.details?.signatures[0]?.important_fields || [];
	}

	get totalScore() {
		const signatures = this.signatures || [];

		return signatures.reduce((acc, signature) => acc + parseInt(signature.boost_score, 10), 0);
	}

	get origin() {
		return this.fileApiDetails?.origins;
	}

	setActiveBoostedAlert(key: string) {
		this.state.activeBoostedAlert = key;
	}

	setZoom(value: number) {
		this.state.zoom = value;
	}

	readFileApi(container?: string, key?: string) {
		this.alertsFileApiDataStore.read(container, key);
	}

	readEvidenceApi(container?: string, key?: string) {
		this.alertsEvidenceApiDataStore.read(container, key);
	}
}
