import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

import { InvestigationPriority } from '@/generated/graphql';

import { InvestigationGridEdge } from '@/app/dashboards/alerts-dashboard/_common/types/types';
import { InvestigationPropertiesPaths, SortDirection } from '@/app/_common/constants';
import { sortByOrder } from '@/app/_common/utils';

export const sortInvestigations = (data: InvestigationGridEdge[], sort: SortDescriptor[]) => {
	const sortOptions = sort[0];

	if (sortOptions?.field === InvestigationPropertiesPaths.Priority) {
		return sortByOrder<InvestigationGridEdge>(
			data,
			InvestigationPropertiesPaths.Priority,
			[InvestigationPriority.Critical, InvestigationPriority.High, InvestigationPriority.Medium, InvestigationPriority.Low],
			sortOptions?.dir as SortDirection,
		);
	}

	return orderBy<InvestigationGridEdge>(data, sort);
};
