import { makeAutoObservable } from 'mobx';

import { DocumentNode } from '@apollo/client';
import { FiltersInput } from '@/generated/graphql';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { ContextMenuDataStore } from './context-menu.data-store';

export class ContextMenuViewStore {
	private dataStore = injectInterface(this, ContextMenuDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get data() {
		return this.dataStore.data;
	}

	get error() {
		return this.dataStore.error;
	}

	get loading() {
		return this.dataStore.loading;
	}

	read(query: DocumentNode, filtersInput: FiltersInput | undefined) {
		this.dataStore.read(query, filtersInput);
	}
}
