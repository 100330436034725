import { FC } from 'react';

import { Evidence } from '@/app/_common/types';

import { AlertEvidence } from '../_components';

interface PaloAltoNgfwAlertEvidenceProps {
	evidence: Evidence;
}

export const PaloAltoNgfwAlertEvidence: FC<PaloAltoNgfwAlertEvidenceProps> = ({ evidence }) => {
	return <AlertEvidence evidence={evidence} />;
};
