import { AdxTables, QUERY_SEPARATOR, TableColumn as TableColumnString } from '@/app/_common/constants';
import { FieldFilterGroup, Schema, TableColumn, TimeRangeFilterOption } from '@/app/_common/types';
import {
	getDateTimeColumns,
	getDateTimeFilter,
	getFilters,
	getTablesFromQuery,
	getTimestampColumn,
	removeQueryComments,
	retrieveQuery,
} from '@/app/_common/utils';

interface GetAvailableFieldsBaseQueryProps {
	table: AdxTables;
	query: string;
	columns: TableColumn[];
	schema: Schema;
	database: string;
	timeRange: TimeRangeFilterOption;
	filtersList: FieldFilterGroup[];
}

export const getAvailableFieldsBaseQuery = ({
	table,
	query,
	columns,
	schema,
	database,
	timeRange,
	filtersList = [],
}: GetAvailableFieldsBaseQueryProps): string => {
	if (columns.length === 0) {
		return '';
	}

	const clearedQuery = removeQueryComments(query);
	const { query: _query } = retrieveQuery(clearedQuery);
	const tableNames = getTablesFromQuery(_query);
	const dateTimeColumns = getDateTimeColumns([table], schema, database);
	const timestampColumn = getTimestampColumn(dateTimeColumns);
	const [, ...restQuery] = _query.split('|');

	const tableUnion = `union withsource=${TableColumnString} ${tableNames.join(', ')} | where ${TableColumnString} == '${table}'`;
	const queryTable = tableNames.length > 1 ? tableUnion : table;
	const timeRangeFilter = getDateTimeFilter(_query, dateTimeColumns, timestampColumn, timeRange);
	const filters = getFilters(filtersList);

	return [queryTable, timeRangeFilter, filters, restQuery.join(QUERY_SEPARATOR)].filter((value) => value).join(` ${QUERY_SEPARATOR} `);
};
