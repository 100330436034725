import { useCallback, useMemo } from 'react';

import { sortByOrder } from '@/app/_common/utils';
import { MITRE_TACTICS_PRIORITY } from '@/app/_features/alerts/_common/constants';
import { SortDirection } from '@/app/_common/constants';
import { MitreTacticsValue } from '@/app/_common/types';
import { ChipsSelect } from '@/app/_common/_components/chips-select';
import { MitreAttackSelectOption } from '@/app/_common/_components/mitre-attack-select/_componenets/mitre-attack-select-option';

interface MitreAttackSelectProps {
	title?: string;
	values?: string[];
	onChange: (value: string[]) => void;
	disabled?: boolean;
}

const AVAILABLE_TACTICS = sortByOrder(
	Object.values(MitreTacticsValue).map((tactic) => {
		return {
			value: tactic,
			label: <MitreAttackSelectOption tactic={tactic} />,
			disabled: false,
		};
	}),
	'value',
	MITRE_TACTICS_PRIORITY,
	SortDirection.Desc,
);

export const MitreAttackSelect = ({ title, values = [], onChange, disabled = false }: MitreAttackSelectProps) => {
	const options = useMemo(() => AVAILABLE_TACTICS.map((tactic) => ({ ...tactic, disabled: values.includes(tactic.value) })), [values]);

	const handleChipChange = useCallback(
		(value: string[]) => {
			const sortedValue = sortByOrder(value, '', MITRE_TACTICS_PRIORITY, SortDirection.Desc);
			onChange(sortedValue);
		},
		[onChange],
	);

	return <ChipsSelect title={title} options={options} values={values} onChange={handleChipChange} disabled={disabled} />;
};
