import { PropsWithChildren, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import _get from 'lodash/get';
import { getter } from '@progress/kendo-react-common';

import { GridCellProps, GridHeaderCellProps, GridSortChangeEvent } from '@progress/kendo-react-grid';

import { Namespaces } from '@/translations/namespaces';
import { RootPaths } from '@/app/_common/navigation';
import { Column, DataGrid, ListHeader } from '@/app/_common/_components';

import { AlertsDataGridViewStore } from '@/app/_features/alerts-actions/_common';
import { AlertsDataGridViewStore as AlertsDataGridOldViewStore } from '@/app/_common/_components/alerts-action-dialog/_common/stores/alerts-data-grid.view-store';
import { AlertPropertiesPaths } from '@/app/_common/_components/alerts-action-dialog/_common';
import { SelectCell } from '@/app/_common/_components/data-grid/_components/select-cell';
import { HeaderSelectCell } from '@/app/_common/_components/data-grid/_components/header-select-cell';
import {
	AlertStateCell,
	DataHeaderCell,
	DateTimeCell,
	LinkCell,
	MultiValueCell,
	TrimTextCell,
	ConfidenceCell,
	SeverityCell,
} from '@/app/_common/_components/data-grid/_components';
import { AlertsDataGridHeaderCell } from '@/app/_features/alerts-actions/_components/alerts-data-grid/_components';
import { DetectedByCell, MitreAttackCell } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/_components';
import { useRowRender } from '@/app/_common/_components/data-grid/hooks';

import styles from '@/app/_features/alerts-actions/_components/alerts-data-grid/alerts-data-grid.module.scss';

const SELECTED_FIELD = 'selected';
const DATA_ITEM_KEY = AlertPropertiesPaths.Id;
const idGetter = getter(DATA_ITEM_KEY);

// Passing store as props is a temporary solution to workaround code duplication.
// Shall be replaced by direct usage of store instance when we switch to this component in all places.
interface AlertsDataGridProps {
	store: AlertsDataGridViewStore | AlertsDataGridOldViewStore;
}

// Wrapping with observer ensures proper rerendering on sort and filter!
export const AlertsDataGrid = observer(({ store }: AlertsDataGridProps) => {
	const { t } = useTranslation([Namespaces.AlertsActionDialog], { keyPrefix: 'grid' });
	const { pathname } = useLocation();
	const { isRowSelected, toggleSelectedRow } = store;

	const handleSortChange = useCallback(
		(event: GridSortChangeEvent) => {
			store.setSort(event.sort);
		},
		[store],
	);

	const columns = useMemo<Column[]>(
		() => [
			{
				id: SELECTED_FIELD,
				width: '50px',
				// Wrapping with observer ensures proper rerendering!
				headerCell: observer((props) => <HeaderSelectCell mode={store.selectionHeaderMode} onChange={props.selectionChange} {...props} />),
				cell: (props: GridCellProps) => (
					<SelectCell {...props} isRowSelected={isRowSelected} toggleSelectedRow={toggleSelectedRow} idPropertyPath={DATA_ITEM_KEY} />
				),
				resizable: false,
				locked: true,
			},
			{
				id: AlertPropertiesPaths.Timestamp,
				field: AlertPropertiesPaths.Timestamp,
				title: t(`headers.${AlertPropertiesPaths.Timestamp}`),
				cell: DateTimeCell,
				width: '170px',
				headerCell: (props) => <DataHeaderCell {...props} sortable={true} sort={store.sort} onSort={store.setSort} />,
				headerClassName: styles.headerCell,
			},
			{
				id: AlertPropertiesPaths.State,
				field: AlertPropertiesPaths.State,
				title: t(`headers.${AlertPropertiesPaths.State}`),
				cell: observer((props) => {
					const alertState = _get(props.dataItem, AlertPropertiesPaths.State, '');

					return <AlertStateCell {...props} statePropertyPath={AlertPropertiesPaths.StateData} alertState={alertState} />;
				}),
				width: '110px',
				headerCell: (props) => <AlertsDataGridHeaderCell {...props} store={store} propertyPath={AlertPropertiesPaths.State} />,
				headerClassName: styles.headerCell,
				show: store.getFieldColumnState(AlertPropertiesPaths.State),
			},
			{
				id: AlertPropertiesPaths.InvestigationSummaryName,
				field: AlertPropertiesPaths.InvestigationSummaryName,
				title: t(`headers.${AlertPropertiesPaths.InvestigationSummaryName}`),
				width: '110px',
				cell: observer((props: PropsWithChildren<GridCellProps>) => {
					const investigationId = _get(props.dataItem, AlertPropertiesPaths.InvestigationSummaryId);
					const investigationName = _get(props.dataItem, AlertPropertiesPaths.InvestigationSummaryName);

					return <LinkCell {...props} title={investigationName} path={`${RootPaths.INVESTIGATIONS}/${investigationId}`} from={pathname} />;
				}),
				headerCell: (props: PropsWithChildren<GridHeaderCellProps>) => (
					<AlertsDataGridHeaderCell {...props} store={store} propertyPath={AlertPropertiesPaths.InvestigationSummaryName} />
				),
				headerClassName: styles.headerCell,
				show: store.getFieldColumnState(AlertPropertiesPaths.InvestigationSummaryName),
			},
			{
				id: AlertPropertiesPaths.Severity,
				field: AlertPropertiesPaths.Severity,
				title: t(`headers.${AlertPropertiesPaths.Severity}`),
				cell: SeverityCell,
				headerCell: observer((props) => <AlertsDataGridHeaderCell {...props} store={store} propertyPath={AlertPropertiesPaths.Severity} />),
				headerClassName: styles.headerCell,
			},
			{
				id: AlertPropertiesPaths.Confidence,
				field: AlertPropertiesPaths.Confidence,
				title: t(`headers.${AlertPropertiesPaths.Confidence}`),
				cell: ConfidenceCell,
				headerCell: observer((props) => <AlertsDataGridHeaderCell {...props} store={store} propertyPath={AlertPropertiesPaths.Confidence} />),
				headerClassName: styles.headerCell,
			},
			{
				id: AlertPropertiesPaths.Title,
				field: AlertPropertiesPaths.Title,
				title: t(`headers.${AlertPropertiesPaths.Title}`),
				cell: TrimTextCell,
				headerCell: observer((props) => <AlertsDataGridHeaderCell {...props} store={store} propertyPath={AlertPropertiesPaths.Title} />),
				headerClassName: styles.headerCell,
			},
			{
				id: AlertPropertiesPaths.Source,
				field: AlertPropertiesPaths.Source,
				title: t(`headers.${AlertPropertiesPaths.Source}`),
				cell: MultiValueCell,
				headerCell: observer((props) => <AlertsDataGridHeaderCell {...props} store={store} propertyPath={AlertPropertiesPaths.Source} />),
				headerClassName: styles.headerCell,
			},
			{
				id: AlertPropertiesPaths.Destination,
				field: AlertPropertiesPaths.Destination,
				title: t(`headers.${AlertPropertiesPaths.Destination}`),
				cell: MultiValueCell,
				headerCell: observer((props) => <AlertsDataGridHeaderCell {...props} store={store} propertyPath={AlertPropertiesPaths.Destination} />),
				headerClassName: styles.headerCell,
			},
			{
				id: AlertPropertiesPaths.Mitre,
				field: AlertPropertiesPaths.Mitre,
				title: t(`headers.${AlertPropertiesPaths.Mitre}`),
				cell: MitreAttackCell,
				headerCell: observer((props) => <AlertsDataGridHeaderCell {...props} store={store} propertyPath={AlertPropertiesPaths.Mitre} />),
				headerClassName: styles.headerCell,
			},
			{
				id: AlertPropertiesPaths.DetectedBy,
				field: AlertPropertiesPaths.DetectedBy,
				title: t(`headers.${AlertPropertiesPaths.DetectedBy}`),
				cell: DetectedByCell,
				headerCell: observer((props) => {
					return (
						<AlertsDataGridHeaderCell
							{...props}
							store={store}
							propertyPath={AlertPropertiesPaths.DetectedBy}
							filterOptions={store.getAlertsDetectedByMainFilters(AlertPropertiesPaths.DetectedBy)}
							filterOptionsSubgroups={[store.getDetectedByVendorFilters(AlertPropertiesPaths.DetectedBy)].filter((subgroup) => subgroup.options)}
						/>
					);
				}),
				headerClassName: styles.headerCell,
			},
		],
		[t, store, isRowSelected, toggleSelectedRow, pathname],
	);

	const rowRender = useRowRender({ isRowSelected, idGetter });

	return (
		<div className={styles.container}>
			<ListHeader title="Selected Alerts" showFilters={false} />
			<DataGrid
				gridProps={{
					data: store.data,
					dataItemKey: DATA_ITEM_KEY,
					fixedScroll: true,
					sort: store.sort,
					sortable: true,
					onHeaderSelectionChange: store.toggleSelectAllRows,
					onSortChange: handleSortChange,
					rowRender,
				}}
				columns={columns}
			/>
		</div>
	);
});

AlertsDataGrid.displayName = 'AlertsDataGrid';
