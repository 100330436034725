import { KUSTO_TIME_RANGE_KEYS, TableColumn } from '@/app/_common/constants/adx.constants';
import { ChartStackBarKeys } from '@/app/_common/types/advanced-query-chart';

export interface GetMakeSeriesStackedBarChartQuery {
	fromTime: string;
	toTime: string;
	step?: number;
}

export const getMakeSeriesStackedBarChartQuery = ({ fromTime, toTime, step }: GetMakeSeriesStackedBarChartQuery): string => {
	const dataPreparation = `make-series count=count() on ${ChartStackBarKeys.Date} from ${fromTime} to ${toTime} step ${step}${KUSTO_TIME_RANGE_KEYS.SECOND} by ${TableColumn}`;

	const dataTransformation = `| mv-expand ['count'] to typeof(double), ${ChartStackBarKeys.Date} to typeof(datetime)`;

	const dataAggregation = `| summarize ${ChartStackBarKeys.EventsCount} = sumif(['count'], ${TableColumn} == 'events'), ${ChartStackBarKeys.AlertsCount} = sumif(['count'], ${TableColumn} == 'alerts'), ${ChartStackBarKeys.EvidenceCount} = sumif(['count'], ${TableColumn} == 'evidence'), ${ChartStackBarKeys.TotalCount} = sum(['count']) by ${ChartStackBarKeys.Date}`;

	const finalProjection = `| project ${ChartStackBarKeys.Date}, ${ChartStackBarKeys.AlertsCount}, ${ChartStackBarKeys.EventsCount}, ${ChartStackBarKeys.EvidenceCount}, ${ChartStackBarKeys.TotalCount}`;

	return [dataPreparation, dataTransformation, dataAggregation, finalProjection].join(' ');
};
