import { FC, forwardRef, ForwardedRef, Ref, memo } from 'react';

import { Grid } from '@progress/kendo-react-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridColumnResizeEvent } from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import { Column, DataGrid } from '@/app/_common/_components/data-grid/data-grid';
import { DataGridDataItem, WidthsState } from '@/app/_common/types';
import { deepEqual } from '@/app/_common/utils';
import { SessionItem } from '@/app/_common/constants/alert-details.constants';
import { GridProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridProps';

export interface SessionListProps {
	data: DataGridDataItem<SessionItem>[];
	sort: SortDescriptor[];
	columns: Column[];
	measuredRef?: Ref<HTMLDivElement>;
	onColumnResize: (event: GridColumnResizeEvent) => void;
	tableClassName?: string;
	widths: WidthsState;
}

export const SessionList: FC<SessionListProps> = memo(
	forwardRef(({ data, sort, columns, measuredRef, tableClassName, onColumnResize }, ref: ForwardedRef<Grid> | undefined) => {
		const gridProps: GridProps = {
			data: data,
			sort: sort,
			fixedScroll: true,
			scrollable: 'none',
			resizable: true,
			onColumnResize: onColumnResize,
		};

		return (
			<div className={tableClassName}>
				<DataGrid gridProps={gridProps} columns={columns} gridRef={ref} measuredRef={measuredRef} />
			</div>
		);
	}),
	(prev, next) => deepEqual(prev, next, ['data', 'widths', 'columns']),
);

SessionList.displayName = 'SessionList';
