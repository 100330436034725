import { gql } from '@apollo/client';

export const ListActionHistory = gql`
	query ListActionsHistory($tenantId: ID!, $filtersInput: FiltersInput, $input: Paging, $sortersInput: SortersInput) {
		listActionsHistory(tenantId: $tenantId, filtersInput: $filtersInput, input: $input, sortersInput: $sortersInput) {
			edges {
				cursor
				node {
					action
					message
					id
					timestamp
					resourceType
					correlationId
					requesterUser {
						upn
						id
					}
				}
			}
			pageInfo {
				hasNextPage
				startCursor
				endCursor
			}
		}
	}
`;

export const ListActionHistoryForFranchise = gql`
	query ListActionsHistoryForFranchise($franchiseId: ID!, $filtersInput: FiltersInput, $input: Paging, $sortersInput: SortersInput) {
		listActionsHistoryForFranchise(franchiseId: $franchiseId, filtersInput: $filtersInput, input: $input, sortersInput: $sortersInput) {
			edges {
				cursor
				node {
					action
					message
					id
					timestamp
					resourceType
					correlationId
					requesterUser {
						upn
						id
					}
				}
			}
			pageInfo {
				hasNextPage
				startCursor
				endCursor
			}
		}
	}
`;
