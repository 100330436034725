import { CSSProperties } from 'react';

export const samuraiLight: { [key: string]: CSSProperties } = {
	hljs: {
		background: '#eff1f6',
		color: 'black',
		display: 'block',
		overflowX: 'auto',
		padding: '0.5em',
	},
	'hljs-name': {
		color: '#00f',
	},
	'hljs-keyword': {
		color: '#00f',
	},
	'hljs-tag': {
		color: '#00f',
	},
	'hljs-built_in': {
		color: '#00f',
	},
	'hljs-selector-tag': {
		color: '#00f',
	},
	'hljs-string': {
		color: '#a31515',
	},
	'hljs-title': {
		color: '#a31515',
	},
	'hljs-section': {
		color: '#a31515',
	},
	'hljs-attribute': {
		color: '#a31515',
	},
	'hljs-literal': {
		color: '#0000ff',
	},
	'hljs-template-tag': {
		color: '#a31515',
	},
	'hljs-template-variable': {
		color: '#a31515',
	},
	'hljs-type': {
		color: '#a31515',
	},
	'hljs-addition': {
		color: '#a31515',
	},
	'hljs-quote': {
		color: '#008000',
	},
	'hljs-comment': {
		color: '#008000',
	},
	'hljs-variable': {
		color: '#008000',
	},
	'hljs-deletion': {
		color: '#2b91af',
	},
	'hljs-selector-attr': {
		color: '#2b91af',
	},
	'hljs-selector-pseudo': {
		color: '#2b91af',
	},
	'hljs-meta': {
		color: '#2b91af',
	},
	'hljs-doctag': {
		color: '#808080',
	},
	'hljs-attr': {
		color: '#f00',
	},
	'hljs-symbol': {
		color: '#00b0e8',
	},
	'hljs-bullet': {
		color: '#00b0e8',
	},
	'hljs-link': {
		color: '#00b0e8',
	},
	'hljs-number': {
		color: '#098658',
	},
	'hljs-emphasis': {
		fontStyle: 'italic',
	},
	'hljs-strong': {
		fontWeight: 'bold',
	},
};
