import { DetailsViewItem, DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { queryTypenameToDetailsViewTypenameMapping } from '@/app/_common/_components/details-view/constants';

export const getGraphqlQueryDetailsViewTypename = (item: DetailsViewItem): DetailsViewTypename => {
	if (!item?.__typename) {
		return DetailsViewTypename.UNKNOWN;
	}

	try {
		const typename = item.__typename;
		return queryTypenameToDetailsViewTypenameMapping[typename] || queryTypenameToDetailsViewTypenameMapping.Default;
	} catch (error) {
		return DetailsViewTypename.UNKNOWN;
	}
};
