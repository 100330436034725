import { FieldFilterGroup } from '@/app/_common/types';
import { ADX_FILTER_OPERATORS, KUSTO_LOGICAL_OPERATORS } from '@/app/_common/constants';
import { fixEscapeCharacters } from './fix-escape-characters';

export const getFilters = (filters: FieldFilterGroup[]): string => {
	if (!filters || filters.length === 0) {
		return '';
	}

	const filtersQuery = filters.map((filter) => {
		const values = filter.values.map((value) => {
			let _value = value;

			if (typeof value === 'string') {
				_value = value.replaceAll('"', '\\"');
				_value = fixEscapeCharacters(_value);
			}

			return _value;
		});

		if (filter.operator === ADX_FILTER_OPERATORS.NOT_HAS_ANY) {
			const _filter = `["${filter.field}"] ${ADX_FILTER_OPERATORS.HAS_ANY} (${values.map((value) => '"' + value + '"').join(', ')})`;
			return `not(${_filter})`;
		}

		return `["${filter.field}"] ${filter.operator} (${values.map((value) => '"' + value + '"').join(', ')})`;
	});

	return `where ${filtersQuery.join(' ' + KUSTO_LOGICAL_OPERATORS.AND + ' ')}`;
};
