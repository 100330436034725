import { observer } from 'mobx-react-lite';
import { memo } from 'react';

import { DataHeaderCell } from '@/app/_common/_components/data-grid/_components';

import { isTimestampColumn, getSortFilterOptions } from '@/app/_common/_components/query-results/query-results-table/utils';
import { QueryResultsTableHeaderCellWithStoreProps } from '@/app/_common/_components/query-results/query-results-table/types';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';
import { shallowEqual } from '@/app/_common/utils';

const QueryResultsTableStandardHeaderCellComponent = observer((props: QueryResultsTableHeaderCellWithStoreProps) => {
	const { sort, setSort, getCounter, getFilterOptions, setGridHeaderFilter, getGridHeaderFilterValues, resetGridHeaderFilter } =
		useQueryResultsTableStore();
	const { field } = props;
	const sanitizedField = field ?? '';

	const isCurrentColumnTimestamp = isTimestampColumn(sanitizedField);

	return (
		<DataHeaderCell
			{...props}
			sortable={true}
			onSort={setSort}
			sort={sort}
			counter={isCurrentColumnTimestamp ? undefined : getCounter?.(sanitizedField)}
			onFilter={setGridHeaderFilter}
			filterable={!isCurrentColumnTimestamp}
			filter={getGridHeaderFilterValues ? getGridHeaderFilterValues(sanitizedField) : undefined}
			filterOptions={isCurrentColumnTimestamp ? undefined : getFilterOptions && getFilterOptions(sanitizedField)}
			clearFilter={resetGridHeaderFilter}
			sortFilterOptions={getSortFilterOptions(sanitizedField)}
		/>
	);
});

QueryResultsTableStandardHeaderCellComponent.displayName = 'QueryResultsTableStandardHeaderCellComponent';

/*
 * 'columnMenuWrapperProps' leads to rerendering of the header cell component.
 * As this prop does not seem to be used by custom header cells, it is ignored when comparing.
 */
export const QueryResultsTableStandardHeaderCell = memo(
	QueryResultsTableStandardHeaderCellComponent,
	(prevProps: QueryResultsTableHeaderCellWithStoreProps, nextProps: QueryResultsTableHeaderCellWithStoreProps) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { columnMenuWrapperProps: prevColumnMenuWrapperProps, ..._prevProps } = prevProps;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { columnMenuWrapperProps: nextColumnMenuWrapperProps, ..._nextProps } = nextProps;

		return shallowEqual(_prevProps, _nextProps);
	},
);

QueryResultsTableStandardHeaderCell.displayName = 'QueryResultsTableStandardHeaderCell';
