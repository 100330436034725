import { FieldFilterGroup, FieldFilterValue } from '@/app/_common/types';

export const pushFilterValue = (value: FieldFilterValue, groupIndex: number, groups: FieldFilterGroup[]) => {
	const group = groups[groupIndex];

	const newGroup = {
		...group,
		values: [...group.values, value],
	};

	return [...groups.slice(0, groupIndex), newGroup, ...groups.slice(groupIndex + 1)];
};
