import { FC } from 'react';

import { TreeView } from '@progress/kendo-react-treeview';

import { TreeStyle } from './constants';
import { MitreAttackTactic } from './types';
import { MitreAttackTreeItem } from '.';

import styles from './mitre-attack-tree.module.scss';

interface MiterAttackTreeProps {
	tactics: MitreAttackTactic[];
	treeStyle: TreeStyle;
}

export const MitreAttackTree: FC<MiterAttackTreeProps> = ({ tactics, treeStyle }) => {
	return (
		<TreeView
			data={tactics}
			className={treeStyle === TreeStyle.REGULAR ? styles.treeView : styles.compressedTreeView}
			item={MitreAttackTreeItem(tactics)}
		/>
	);
};
