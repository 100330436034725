import { QUERY_SEPARATOR } from '@/app/_common/constants';
import { SchemaColumn } from '@/app/_common/types';
import {
	BASE_DATA_VARIABLE_NAME,
	RARE_DATA_VARIABLE_NAME,
} from '@/app/_common/utils/adx/prepare-available-fields-values-query/prepare-available-fields-values-query.constants';

export const getRareQuery = (column: SchemaColumn): string => {
	const baseSection = `let ${RARE_DATA_VARIABLE_NAME} = ${BASE_DATA_VARIABLE_NAME}`;
	const rareSection = `top 10 by ${column.Name}_count asc`;
	const sortSection = `sort by ${column.Name}_count desc`;

	return [baseSection, rareSection, sortSection].join(` ${QUERY_SEPARATOR} `) + ';';
};
