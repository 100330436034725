import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import { createEditor, Descendant, Editor, Transforms, BaseElement } from 'slate';
import { Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import classNames from 'classnames';
import { useOutsideClick } from '@/app/_common/hooks';
import {
	CustomText,
	CustomElement,
	withTable,
	withLinks,
	withDeleteEmptyBlock,
	countCharacters,
	getInitialValue,
	RICH_TEXT_EDITOR_LS,
	RichTextHeader,
	RichTextContent,
	RichTextToolbar,
	RICH_TEXT_EDITOR_POPUP_ID,
	withListsPlugin,
	CustomEditor,
} from '@/app/_common/_components/rich-text-editor';
import { withListsReact } from '@prezly/slate-lists';
import { RichTextEditorTranslations } from '@/app/_common/_components/rich-text-editor/types';

import styles from './rich-text-editor.module.scss';

declare module 'slate' {
	export interface CustomTypes {
		Editor: CustomEditor;
		Element: CustomElement & BaseElement;
		Text: CustomText;
	}
}

const TOOLBAR_HIGHT_AND_HEADER_HEIGHT = 80;

interface RichTextEditorProps {
	translations: RichTextEditorTranslations;
	className?: string;
	contentClassName?: string;
	toolbarClassName?: string;
	maxHeight?: number;
	onSubmit?: (editorContent: string) => void;
	onChange?: (editorContent?: string) => void;
	onUpdate?: (updatedEditorContent: string) => void;
	onCancel?: () => void;
	onMaximize?: () => void;
	onFocus?: () => void;
	editorContent?: string;
	isEditModeActive: boolean;
	isItemWindowOpen?: boolean;
	highlightEdit?: boolean;
	loading?: boolean;
	readOnly?: boolean;
	createMode?: boolean; // Temporary flag - the RTE shouldn't care if it's create or update
	showSaveCancelButtons?: boolean;
	isSaveCancelButtonsFullTextView?: boolean;
	allowEmptyContent?: boolean;
}

export const RichTextEditor: FC<RichTextEditorProps> = ({
	translations,
	className,
	contentClassName,
	toolbarClassName,
	maxHeight,
	onSubmit,
	onUpdate,
	onChange,
	onCancel,
	onMaximize,
	onFocus,
	editorContent,
	isEditModeActive = false,
	isItemWindowOpen,
	highlightEdit = false,
	loading,
	readOnly = false,
	createMode = false,
	showSaveCancelButtons = false,
	isSaveCancelButtonsFullTextView = false,
	allowEmptyContent = false,
}) => {
	const initialValue: Descendant[] = useMemo(() => getInitialValue(), []);

	const [value, setValue] = useState<Descendant[]>(editorContent ? JSON.parse(editorContent) : initialValue);
	const [focused, setFocused] = useState(false);
	const count = countCharacters(value);

	const editor = useMemo(
		() => withListsReact(withListsPlugin(withHistory(withDeleteEmptyBlock(withLinks(withTable(withReact(createEditor()))))))),
		[],
	);

	const isReadOnlyEnabled = readOnly && !isEditModeActive;

	const handleSetFocused = useCallback(() => {
		if (onFocus) {
			onFocus();
		}
		if (!focused) {
			setFocused(true);
		}
	}, [onFocus, focused]);

	const { anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		focused,
		useCallback(() => {
			const richEditorPopup = document.getElementById(RICH_TEXT_EDITOR_POPUP_ID);
			if (richEditorPopup) {
				return;
			}
			setFocused(false);
		}, []),
	);

	const activeHeader = focused || Boolean(count) || editor.children.length > 1;

	const handleAddNewValue = _debounce((newValue: Descendant[]) => {
		const isValuesEqual = _isEqual(value, newValue);

		if (isValuesEqual || isReadOnlyEnabled) {
			return;
		}

		const editorContent = JSON.stringify(newValue);
		localStorage.setItem(RICH_TEXT_EDITOR_LS, editorContent);
		setValue(newValue);

		if (typeof onChange === 'function') {
			onChange(editorContent);
		}
	}, 300);

	const resetEditor = useCallback(() => {
		Transforms.select(editor, {
			anchor: { path: [0, 0], offset: 0 },
			focus: { path: [0, 0], offset: 0 },
		});

		Transforms.delete(editor, {
			at: {
				anchor: Editor.start(editor, []),
				focus: Editor.end(editor, []),
			},
		});

		editor.children = [
			{
				type: 'paragraph',
				children: [{ text: '' }],
			},
		];
	}, [editor]);

	const getSubmitHandler = useMemo((): (() => Promise<void> | void) | undefined => {
		if (!readOnly && onSubmit) {
			return async () => {
				await onSubmit(JSON.stringify(value));
				resetEditor();
				setFocused(false);
			};
		} else if (onUpdate) {
			return async () => {
				await onUpdate(JSON.stringify(value));
				setFocused(false);
			};
		} else {
			return undefined;
		}
	}, [onSubmit, onUpdate, value, readOnly, resetEditor]);

	const handleCancel = useCallback(() => {
		if (onCancel) {
			onCancel();
			setFocused(false);
		}
	}, [onCancel]);

	//Required to update content on <Component>UpdatedSubscription
	useEffect(() => {
		if (typeof editorContent === 'string') {
			try {
				const newValue = JSON.parse(editorContent);

				/*
				 * This if block prevents from Slate related app crashes.
				 * The root problem is that Slate 'value' property is uncontrolled and basically works as initial value.
				 * Changing it by syncing two editors leads to rerenders and reinitializations, that crash the app.
				 * */
				if (_isEqual(value, newValue)) {
					return;
				}

				/* Editor reset is also needed for the above to work */
				resetEditor();

				if (Array.isArray(newValue) && newValue.length > 0) {
					editor.children = newValue;
					setValue(newValue);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		} else if (typeof editorContent === 'undefined') {
			resetEditor();
			setFocused(false);
		}
	}, [editorContent, editor, resetEditor, value]);

	useEffect(() => {
		return () => {
			localStorage.removeItem(RICH_TEXT_EDITOR_LS);
		};
	}, []);

	const editableMaxHeight = maxHeight ? maxHeight - TOOLBAR_HIGHT_AND_HEADER_HEIGHT : 'none';

	const showToolbar = (!readOnly && focused) || (isEditModeActive && !isItemWindowOpen && !createMode) || (createMode && focused);

	return (
		<div
			style={{ maxHeight: maxHeight || 'none' }}
			className={classNames(styles.richTextEditor, className, {
				[styles.highlightEdit]: highlightEdit && focused && !readOnly,
				[styles.editorContent]: !createMode,
			})}
			ref={anchorRef}
		>
			<Slate editor={editor} value={value} onChange={handleAddNewValue}>
				{createMode && <RichTextHeader active={activeHeader} title={translations.createNewItem} />}

				<RichTextContent
					className={contentClassName}
					isRenderingEditorContent={readOnly}
					editor={editor}
					handleSetFocused={handleSetFocused}
					maxHeight={editableMaxHeight}
					isEditModeActive={isEditModeActive}
					isReadOnlyEnabled={isReadOnlyEnabled}
					collapsed={createMode && !focused}
					onMaximize={onMaximize}
					createMode={createMode}
					translations={translations}
				/>

				{showToolbar && (
					<RichTextToolbar
						className={toolbarClassName}
						count={count}
						onSubmit={getSubmitHandler}
						onCancel={handleCancel}
						loading={loading}
						editMode={isEditModeActive && isItemWindowOpen === false} // Deprecated due to logic complexity. Use 'showSaveCancelButtons' instead.
						showSaveCancelButtons={showSaveCancelButtons}
						isSaveCancelButtonsFullTextView={isSaveCancelButtonsFullTextView}
						allowEmptyContent={allowEmptyContent}
						translations={translations}
					/>
				)}
			</Slate>
		</div>
	);
};
