import { StorageType } from '@/app/_common/types';

type StorageMigrationInfo = {
	key: string;
	version: string;
	storage: StorageType;
};

const STORAGE_TYPE_PREFIX_MAP: Record<StorageType, string> = {
	localStorage: 'ls',
	sessionStorage: 'ss',
};

export const createStorageDetails = (storageType: StorageType, key: string, version: string, tenantId?: string): StorageMigrationInfo => {
	const fullKey = `${tenantId ?? ''}/${STORAGE_TYPE_PREFIX_MAP[storageType]}/${key}`.trim().replace(/^\//, '');

	return {
		storage: storageType,
		key: fullKey,
		version,
	};
};
