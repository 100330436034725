import { FC } from 'react';
import { getSeverityColorIndicator } from '@/app/_common/utils/colors';
import { ColorIndicator } from '@/app/_common/_components/color-indicator';
import { Namespaces } from '@/translations/namespaces';
import { useTranslation } from 'react-i18next';
import { Severity } from '@/generated/graphql';
import { SummarySectionItemTitle } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-section-item-title';

import styles from './alert-details-severity.module.scss';

interface AlertSeverityProps {
	severity: Severity;
}

export const AlertDetailsSeverity: FC<AlertSeverityProps> = ({ severity }) => {
	const { t } = useTranslation(Namespaces.DetailsView);

	return (
		<div>
			<SummarySectionItemTitle title={t('summary.severity', { ns: Namespaces.DetailsView })} />
			<div className={styles.value}>
				<ColorIndicator bulletsConfig={getSeverityColorIndicator(severity)} />
				<p>{severity}</p>
			</div>
		</div>
	);
};
