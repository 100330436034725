import { QUERY_OPERATOR } from '@/app/_common/constants';

export const getQueryWithNormalizedProjectOperator = (query = '', selectedField = ''): string => {
	if (!(query && selectedField)) {
		return query;
	}

	const projectPart = `| ${QUERY_OPERATOR.PROJECT} ${selectedField}`;

	return `${query} ${projectPart}`;
};
