import { ThreatIntelligenceHit } from '@/app/_common/types';

export const getHitsTags = (hits: ThreatIntelligenceHit[]) => {
	if (!Array.isArray(hits)) {
		return [];
	}

	return hits.reduce((acc, curr) => {
		if (!curr) {
			return [...acc];
		}
		const currentTags = curr.saas_tags?.filter((tag) => tag) || [];

		if (currentTags.length) {
			return [...acc, ...currentTags];
		} else {
			return [...acc];
		}
	}, [] as string[]);
};
