import { makeAutoObservable } from 'mobx';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AdxSchemaDataStore } from './adx-schema.data-store';
import { AuthStore } from '@/app/_common/stores';

export class AdxSchemaViewStore {
	dataStore = injectInterface(this, AdxSchemaDataStore);
	authStore = injectInterface(this, AuthStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get error() {
		return this.dataStore.error;
	}

	get schema() {
		return this.dataStore.schema;
	}

	get schemaTables() {
		return Object.keys(this.schema?.Databases?.[this.authStore.currentTenantId]?.Tables ?? {});
	}

	get loading(): boolean {
		return this.dataStore.loading;
	}

	fetchSchema() {
		this.dataStore.fetchSchema();
	}
}
