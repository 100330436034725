import { ReactNode } from 'react';

import styles from './color-indicator-with-value-wrapper.module.scss';

interface ConfidenceValueWrapperProps {
	children: ReactNode;
}

export const ColorIndicatorWithValueWrapper = ({ children }: ConfidenceValueWrapperProps) => {
	return <div className={styles.colorIndicatorWithValueWrapper}>{children}</div>;
};
