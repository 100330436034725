import { action, computed, makeObservable } from 'mobx';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { ActionHistoryTimeRangeFilterStore } from './action-history-time-range-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { FiltersInput, Query, SortersInput, TimestampFilterField } from '@/generated/graphql';
import { ListActionHistory, ListActionHistoryForFranchise } from '@/app/_common/graphql/queries/action-history.queries';
import { getQueryInputVariables } from '@/app/_common/utils';
import { DocumentNode } from 'graphql';

export type ListAction = Pick<Query, 'listActionsHistory' | 'listActionsHistoryForFranchise'>;

export class ActionHistoryDataGridDataStore extends GraphqlBaseDataStore<ListAction> {
	private filtersStore = injectInterface(this, ActionHistoryTimeRangeFilterStore);
	private gqlQuery: DocumentNode;
	private isFranchise: boolean;

	constructor(isFranchise: boolean) {
		super();
		this.gqlQuery = isFranchise ? ListActionHistoryForFranchise : ListActionHistory;
		this.isFranchise = isFranchise;

		makeObservable(this, {
			actions: computed,
			read: action,
			readMore: action,
			pageInfo: computed,
			hasNextPage: computed,
			actionFilters: computed,
		});
	}

	get actions() {
		return this.isFranchise ? this.data?.listActionsHistoryForFranchise : this.data?.listActionsHistory;
	}

	get pageInfo() {
		return this.actions?.pageInfo;
	}

	get hasNextPage() {
		return this.pageInfo?.hasNextPage;
	}

	get actionFilters(): FiltersInput {
		const filters: FiltersInput = {
			timestampFilters: [],
		};

		if (this.filtersStore.filters?.timeRange?.from && this.filtersStore.filters?.timeRange?.to) {
			filters.timestampFilters?.push({
				field: TimestampFilterField.Timestamp,
				range: this.filtersStore.filters.timeRange,
			});
		}

		return filters;
	}

	readMore(filtersInput?: FiltersInput, sortersInput?: SortersInput) {
		const { hasNextPage, endCursor } = this.pageInfo ?? {};

		if (hasNextPage) {
			const variables = {
				...(this.isFranchise ? { franchiseId: this.authStore.user.franchise.id } : { tenantId: this.authStore.currentTenantId }),
				input: getQueryInputVariables({ after: endCursor }),
				filtersInput: { ...this.actionFilters, ...filtersInput },
				sortersInput,
			};

			return this.fetchMore({
				query: this.gqlQuery,
				variables,
			});
		}
	}

	read(filtersInput?: FiltersInput, sortersInput?: SortersInput) {
		const variables = {
			...(this.isFranchise ? { franchiseId: this.authStore.user.franchise.id } : { tenantId: this.authStore.currentTenantId }),
			input: getQueryInputVariables(),
			filtersInput: { ...this.actionFilters, ...filtersInput },
			sortersInput,
		};

		this.query({
			query: this.gqlQuery,
			fetchPolicy: 'cache-and-network',
			nextFetchPolicy: 'cache-first',
			variables,
		});
	}
}
