import { useCallback, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';
import classNames from 'classnames';

import { ViewList } from '@mui/icons-material';
import { InputChangeEvent } from '@progress/kendo-react-inputs';

import { CsvExport, FieldSearch, Switch, Button } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';
import { AdvancedQueryPageKeyPrefix } from '@/app/_common/constants';
import { getFileNameWithDateTime } from '@/app/_common/utils';
import { DataGridDataItem, ResultsDataItem } from '@/app/_common/types';
import { CsvExportData } from '@/app/_common/_components/csv-export/types';
import { QueryResultsTableViewStore } from '@/app/_common/_components/query-results/query-results-table/stores';

import styles from './query-results-table-header.module.scss';

interface QueryResultsTableHeaderProps {
	store: QueryResultsTableViewStore;
	className?: string;
	searchBoxClassName?: string;
	hideEmptyButtonVisible?: boolean;
}

export const QueryResultsTableHeader = observer(
	({ store, className, searchBoxClassName, hideEmptyButtonVisible = true }: QueryResultsTableHeaderProps) => {
		const {
			toggleHideEmpty,
			hideEmpty,
			searchValue = '',
			setSearchValue,
			totalResults,
			totalResultsCount,
			resultsColumnNamesForCsvExport,
			selectedRows,
		} = store;
		const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });
		const [search, setSearch] = useState(searchValue);

		// eslint-disable-next-line react-hooks/exhaustive-deps
		const handleSearchValueChange = useCallback(
			_debounce((event: InputChangeEvent) => {
				setSearchValue(event.value);
			}, 500),
			[],
		);

		const selectedCount = store.selectedCount;
		const selectedResults = useMemo(() => {
			if (selectedCount === 0) {
				return totalResults;
			}

			return totalResults.filter((item: DataGridDataItem<ResultsDataItem>) => item._id && selectedRows[item._id]);
		}, [totalResults, selectedRows, selectedCount]);

		const handleSearch = (event: InputChangeEvent) => {
			setSearch(event.value);
			handleSearchValueChange(event);
		};

		const exportToCSVFileName = getFileNameWithDateTime(t('exportToCSVFileName'));

		return (
			<header className={classNames(styles.container, className)}>
				<FieldSearch
					id="advancedQueryResultsSearch"
					value={search}
					onChange={handleSearch}
					showLabel={false}
					className={searchBoxClassName}
					placeholder={t('results.searchResults')}
				/>
				<div className={styles.actions}>
					{hideEmptyButtonVisible && (
						<Switch
							className={classNames(styles.switch, styles.hideEmptySwitch, {
								[styles.hideEmptySwitchActive]: totalResults.length ? hideEmpty : false,
							})}
							disabled={!totalResultsCount}
							checked={totalResultsCount ? hideEmpty : false}
							onChange={toggleHideEmpty}
							text={t('results.hideEmpty')}
							textClassName={styles.switchText}
						/>
					)}
					<CsvExport data={selectedResults as CsvExportData} headers={resultsColumnNamesForCsvExport} fileName={exportToCSVFileName}>
						<Button fillMode="outline" className={styles.button} disabled={!selectedResults.length}>
							<ViewList />
							<span className={styles.linkText}>
								{t('results.exportToCSV')} {`(${selectedResults.length})`}
							</span>
						</Button>
					</CsvExport>
				</div>
			</header>
		);
	},
);

QueryResultsTableHeader.displayName = 'QueryResultsTableHeader';
