import { Editor } from 'slate';

import { isMarkActive, CustomEditor, FormattingType } from '@/app/_common/_components/rich-text-editor';

export const toggleMarkActionButton = (editor: CustomEditor, format: FormattingType) => {
	const isActive = isMarkActive(editor, format);
	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};
