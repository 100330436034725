import _difference from 'lodash/difference';

import { DEFAULT_COLUMN_WIDTH } from '@/app/_common/_components/data-grid/constants';

export const setDefaultWidthToVisibleColumns = (initialValues: Record<string, string>, visibleColumns: Record<string, boolean>) => {
	const initialValuesKeys = Object.keys(initialValues);
	const visibleColumnsKeys = Object.keys(visibleColumns);

	const missingWidthColumnsKeys = _difference(visibleColumnsKeys, initialValuesKeys);

	const missingWidths: Record<string, string> = missingWidthColumnsKeys.reduce((acc, curr) => {
		if (!visibleColumns[curr]) {
			return { ...acc };
		}

		return { ...acc, [curr]: DEFAULT_COLUMN_WIDTH };
	}, {});

	return { ...initialValues, ...missingWidths };
};
