import { computedFn } from 'mobx-utils';
import { FilterOption } from '@/app/_common/types';

import { capitalizeFirstLetter, getNullishFilterOption } from '@/app/_common/utils';
import { AlertPropertiesPaths, AlertStateOptions, alertTypeTranslation, DetectedByTypes } from '@/app/_common/constants';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';
import { DataGridViewStore } from '@/app/_common/_components/data-grid/data-grid.view-store';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Columns, SortFunction } from '../_components/data-grid/types';

const EMPTY_FIELD_VALUE = 'empty';

export class AlertsUtilsViewStore<T> extends DataGridViewStore<T> {
	getStateFilterOptions = computedFn((): FilterOption[] => {
		const { empty: unassignedCount } = this.getCountedValues(AlertPropertiesPaths.StateData);
		const counters = this.getCountedValues(AlertPropertiesPaths.State);
		const values = this.getUniqValues(AlertPropertiesPaths.State);

		const options: FilterOption[] = values.map((value) => {
			if (value === AlertStateOptions.Dismissed) {
				return {
					value: AlertStateOptions.Dismissed,
					label: 'Dismissed',
					counter: counters[AlertStateOptions.Dismissed],
				};
			} else if (value === AlertStateOptions.Unassigned) {
				return {
					value: AlertStateOptions.Unassigned,
					label: 'Unassigned',
					counter: counters[AlertStateOptions.Unassigned],
				} as FilterOption;
			} else if (value === AlertStateOptions.Assigned) {
				return {
					value: AlertStateOptions.Assigned,
					label: 'Assigned',
					counter: counters[AlertStateOptions.Assigned],
				};
			} else {
				return {} as FilterOption;
			}
		});

		if (unassignedCount) {
			options.unshift(
				getNullishFilterOption(
					i18n.t('alertsGrid.cells.investigation.unassigned', { ns: Namespaces.AlertsDashboard }),
					EMPTY_FIELD_VALUE,
					unassignedCount,
				),
			);
		}

		return options;
	});

	getAlertsDetectedByMainFilters = computedFn((field: string): FilterOption[] => {
		const counters = this.getCountedValues(field);

		const mainFilters: FilterOption[] = this.getUniqValues(field)
			.map((value) => {
				const option: FilterOption = {
					value: value as string,
					label: alertTypeTranslation.get(value as string) ?? '',
					counter: counters[value as string],
				};

				return option;
			})
			.filter((filter) => filter.value === DetectedByTypes.AIEngine || filter.value === DetectedByTypes.BatchEngine);

		if (counters[DetectedByTypes.Unknown]) {
			mainFilters.unshift(getNullishFilterOption(i18n.t('unknown'), DetectedByTypes.Unknown, counters[DetectedByTypes.Unknown]));
		}

		return mainFilters;
	});

	getDetectedByVendorFilters = computedFn((field: string) => {
		const counters = this.getCountedValues(field);

		const vendorFiltersOptions = this.getUniqValues(field)
			.map((value) => ({
				value: value as string,
				label: alertTypeTranslation.get(value as string) ?? capitalizeFirstLetter(value as string),
				counter: counters[value as string],
			}))
			.filter((filter) => filter.value !== DetectedByTypes.AIEngine && filter.value !== DetectedByTypes.BatchEngine);

		const vendorFiltersTotalCount = vendorFiltersOptions.reduce((prevCount, currentEl) => {
			if (!currentEl.counter) {
				return prevCount;
			}
			return prevCount + currentEl.counter;
		}, 0);

		return {
			name: i18n.t('alertsGrid.cells.type.vendor', { ns: Namespaces.AlertsDashboard }),
			count: vendorFiltersTotalCount,
			options: vendorFiltersOptions,
		};
	});

	constructor(
		idPath: string,
		initialColumns: Columns,
		initialSort?: SortDescriptor[],
		sortFunction?: SortFunction<T>,
		apiFiltering?: boolean,
		version?: string,
		expanded?: boolean,
		unsortableRow?: string,
	) {
		super(idPath, initialColumns, initialSort, sortFunction, apiFiltering, version, expanded, unsortableRow);
	}
}
