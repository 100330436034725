import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

import { AlertStateOptions } from '@/app/_common/constants';
import { AlertStateInfoDetails } from '@/app/_common/types';

export const getUnknownAlertStateInfoDetails = (): AlertStateInfoDetails => {
	return {
		state: AlertStateOptions.Unknown,
		label: i18n.t('summary.states.unknown', { ns: Namespaces.DetailsView }),
	};
};
