import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import _get from 'lodash/get';

import { Investigation } from '@/generated/graphql';
import { ALERT_ACTIONS, AlertActionProps, AlertWithInvestigationSummaryExtended, Filters, ResultsDataItem } from '@/app/_common/types';
import { extendAlertsWithInvestigationDetails } from '@/app/_common/utils';
import { Namespaces } from '@/translations/namespaces';
import { ACTIONS_COLUMN_NAME, AdxTables, ResultsTablePropertiesPaths } from '@/app/_common/constants';
import { ContextMenuSections } from '@/app/_common/_components/new-context-menu/_components';
import { CopyValueAction, FiltersAction } from '@/app/_common/_components/data-grid/_components/alerts-widget-context-menu/_components';
import { CommonContextMenuProps } from '@/app/_common/_components/new-context-menu/_common/types';
import { useDynamicMenu } from '@/app/_common/_components/new-context-menu/_common/hooks/use-dynamic-menu';
import { useResultsBulkActions, useResultsSingleActions } from '@/app/_features/alerts-actions-menu/_common/hooks';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';
import { useAlertsActions } from '@/app/_features/alerts-actions/_common/hooks/use-alerts-actions';

import styles from './query-results-table-context-menu-content.module.scss';

const FIELDS_WITH_HIDDEN_COPY_ACTION: string[] = [ResultsTablePropertiesPaths.Selected, ACTIONS_COLUMN_NAME];
const FIELDS_WITH_HIDDEN_FILTER_ACTION: string[] = [ResultsTablePropertiesPaths.Selected, ResultsTablePropertiesPaths.Timestamp, ACTIONS_COLUMN_NAME];

interface QueryResultsTableContextMenuContentProps extends CommonContextMenuProps<ResultsDataItem> {
	onResetGridPage?: () => void;
	onViewDetailsClick?: (item: ResultsDataItem) => void;
	onAssignToInvestigation?: (items: ResultsDataItem[]) => void;
	hiddenValueActions?: ALERT_ACTIONS[];
	hiddenSingleActions?: ALERT_ACTIONS[];
	hiddenBulkActions?: ALERT_ACTIONS[];
}

export const QueryResultsTableContextMenuContent = observer(
	({
		field,
		dataItem,
		gridRef,
		hiddenValueActions = [],
		hiddenSingleActions = [],
		hiddenBulkActions = [],
		onViewDetailsClick,
		onResetGridPage,
		onClose,
		onAssignToInvestigation,
	}: QueryResultsTableContextMenuContentProps) => {
		const { unassignAlerts, dismissAlerts, undismissAlerts } = useAlertsActions();
		const { selectedItems, setGridHeaderFilter, getGridHeaderFilterValues } = useQueryResultsTableStore();

		const { data, loading } = useDynamicMenu();
		const { t } = useTranslation([Namespaces.AlertsActions], { keyPrefix: 'menu.actions' });
		const alerts = useMemo(() => data?.listAlerts?.edges?.map(({ node }) => node) ?? [], [data?.listAlerts?.edges]);
		const investigations = useMemo(() => data?.listInvestigations?.edges?.map(({ node }) => node) ?? [], [data?.listInvestigations?.edges]);

		const focusedAlertNode = useMemo(() => {
			const isAlert = dataItem?.$table === AdxTables.Alerts;
			const hasAlertId = typeof dataItem?.alert_id === 'string';

			if (isAlert && hasAlertId) {
				return alerts.find((alert) => alert.id === dataItem.alert_id);
			}
		}, [dataItem, alerts]);

		const handleAssignOrCreateInvestigationClick = useCallback(() => {
			if (dataItem) {
				onAssignToInvestigation?.([dataItem]);
			}
		}, [onAssignToInvestigation, dataItem]);

		const handleAssignOrCreateSelectedInvestigationClick = useCallback(() => {
			if (selectedItems?.length > 0) {
				onAssignToInvestigation?.(selectedItems);
			}
		}, [onAssignToInvestigation, selectedItems]);

		const executeSingleAlertAction = useCallback(
			(action: (alerts: AlertWithInvestigationSummaryExtended[], investigations: Investigation[]) => void) => {
				if (focusedAlertNode) {
					action(extendAlertsWithInvestigationDetails([focusedAlertNode], investigations), investigations);
				}
			},
			[focusedAlertNode, investigations],
		);

		const executeBulkAlertsAction = useCallback(
			(action: (alerts: AlertWithInvestigationSummaryExtended[], investigations: Investigation[]) => void) => {
				if (alerts.length > 0) {
					action(extendAlertsWithInvestigationDetails(alerts, investigations), investigations);
				}
			},
			[alerts, investigations],
		);

		const handleUnassignClick = useCallback(() => {
			executeSingleAlertAction(unassignAlerts);
		}, [unassignAlerts, executeSingleAlertAction]);

		const handleUnassignSelectedClick = useCallback(() => {
			executeBulkAlertsAction(unassignAlerts);
		}, [unassignAlerts, executeBulkAlertsAction]);

		const handleDismissClick = useCallback(() => {
			executeSingleAlertAction(dismissAlerts);
		}, [dismissAlerts, executeSingleAlertAction]);

		const handleDismissSelectedClick = useCallback(() => {
			executeBulkAlertsAction(dismissAlerts);
		}, [dismissAlerts, executeBulkAlertsAction]);

		const handleUndismissClick = useCallback(() => {
			executeSingleAlertAction(undismissAlerts);
		}, [undismissAlerts, executeSingleAlertAction]);

		const handleUndismissSelectedClick = useCallback(() => {
			executeBulkAlertsAction(undismissAlerts);
		}, [undismissAlerts, executeBulkAlertsAction]);

		const resetGridPosition = useCallback(() => {
			// @ts-ignore - workaround that uses private properties of the gird
			const vs = gridRef?.current?.vs;
			if (vs) {
				vs.fixedScroll = false;
				vs.reset();
			}
			onResetGridPage?.();
		}, [gridRef, onResetGridPage]);

		const onHandleFilterClick = useCallback(
			(field: string, values: Filters) => {
				// Workaround to avoid scrolling lag when we have a lot of rows in table
				// @ts-ignore
				const dataGridElement: HTMLDivElement = gridRef?.current?.element;

				if (dataGridElement) {
					const dataGridContentElement = dataGridElement.querySelector('.k-grid-content');

					if (dataGridContentElement) {
						dataGridContentElement.scrollTop = 0;
					}
				}

				setGridHeaderFilter(field, values);
			},
			[setGridHeaderFilter, gridRef],
		);

		const handleFilterClick = useCallback(
			(field: string, values: Filters) => {
				resetGridPosition();
				onHandleFilterClick(field, values);
				onClose?.();
			},
			[onClose, onHandleFilterClick, resetGridPosition],
		);

		const handleViewDetailsClick = useCallback(() => {
			if (dataItem) {
				onViewDetailsClick?.(dataItem);
				onClose?.();
			}
		}, [onClose, onViewDetailsClick, dataItem]);

		const valueActions = useMemo<AlertActionProps[]>(() => {
			if (!dataItem || !field) {
				return [];
			}

			const sanitizedField = field ?? '';

			const value = _get(dataItem, sanitizedField);
			const activeEqualFilters = getGridHeaderFilterValues(sanitizedField);

			return [
				{
					id: ALERT_ACTIONS.FilterByValue,
					show: !FIELDS_WITH_HIDDEN_FILTER_ACTION.includes(field),
					label: () => (
						<FiltersAction
							value={value}
							field={field}
							handleAddEqualFilter={handleFilterClick}
							closeContextMenu={onClose}
							activeEqualFilters={activeEqualFilters}
						/>
					),
				},
				{
					id: ALERT_ACTIONS.CopyValue,
					show: !FIELDS_WITH_HIDDEN_COPY_ACTION.includes(field),
					label: () => <CopyValueAction closeContextMenu={onClose} value={value} />,
					className: styles.copyAction,
				},
			];
		}, [field, dataItem, getGridHeaderFilterValues, handleFilterClick, onClose]);

		const singleActions = useResultsSingleActions({
			selectedResults: selectedItems,
			focusedNode: focusedAlertNode ?? dataItem,
			onViewDetails: handleViewDetailsClick,
			onAssignOrCreateInvestigationClick: handleAssignOrCreateInvestigationClick,
			onUnassignClick: handleUnassignClick,
			onDismissClick: handleDismissClick,
			onUndismissClick: handleUndismissClick,
		});

		const bulkActions = useResultsBulkActions({
			selectedResults: selectedItems,
			selectedAlerts: alerts,
			onViewDetails: handleViewDetailsClick,
			onAssignOrCreateSelectedInvestigationClick: handleAssignOrCreateSelectedInvestigationClick,
			onUnassignSelectedClick: handleUnassignSelectedClick,
			onDismissSelectedClick: handleDismissSelectedClick,
			onUndismissSelectedClick: handleUndismissSelectedClick,
		});

		const sections = useMemo(() => {
			return [
				{
					title: t('value.title'),
					actions: valueActions.filter((action) => !hiddenValueActions.includes(action.id)),
					showLoading: false,
				},
				{
					title: t('single.title'),
					actions: singleActions.filter((action) => !hiddenSingleActions.includes(action.id)),
					showLoading: true,
				},
				{
					title: t('bulk.title'),
					subtitle: t('bulk.subtitle'),
					actions: bulkActions.filter((action) => !hiddenBulkActions.includes(action.id)),
					showLoading: true,
				},
			];
		}, [t, valueActions, singleActions, bulkActions, hiddenValueActions, hiddenSingleActions, hiddenBulkActions]);

		return <ContextMenuSections sections={sections} investigations={investigations} loading={loading} onClose={onClose} />;
	},
);

QueryResultsTableContextMenuContent.displayName = 'QueryResultsTableContextMenuContent';
