import { IntegrationType, IntegrationSourceType, CollectorType } from '@/generated/graphql';

import { TelemetryIntegrationType } from '@/app/_features/integration/_common/types';

export const getTelemetryIntegrationType = (
	integrationType?: IntegrationType,
	integrationSourceType?: IntegrationSourceType,
	collectorType?: CollectorType,
): TelemetryIntegrationType => {
	if (integrationType === IntegrationType.Telemetry) {
		if (integrationSourceType === IntegrationSourceType.UserCreated && collectorType === CollectorType.Cloud) {
			return TelemetryIntegrationType.Cloud;
		}

		if (integrationSourceType === IntegrationSourceType.AutoDetected && collectorType === CollectorType.Cloud) {
			return TelemetryIntegrationType.SecureSyslog;
		}

		if (integrationSourceType === IntegrationSourceType.AutoDetected && collectorType === CollectorType.SecureSyslog) {
			return TelemetryIntegrationType.SecureSyslog;
		}
	}

	return TelemetryIntegrationType.Unknown;
};
