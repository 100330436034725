import { matchPath } from 'react-router-dom';
import { MenuItem } from '@/app/_common/_components/sidebar/_common';

export const getSelectedSidebarMenuItem = (pathname: string, menuItems: MenuItem[]): MenuItem | undefined => {
	let selectedItem: MenuItem | undefined;

	for (const menuItem of menuItems) {
		if (menuItem.route === pathname) {
			selectedItem = menuItem;
			break;
		}

		if (menuItem.hasNestedDynamicSubroutes) {
			const match = matchPath(
				{
					path: menuItem.route,
					end: false,
				},
				pathname,
			);

			if (match) {
				selectedItem = menuItem;
			}
		}

		if (menuItem.subitems) {
			menuItem.subitems.forEach((subitem) => {
				const match = matchPath(
					{
						path: subitem.route,
						end: false,
					},
					pathname,
				);

				if (match) {
					selectedItem = subitem;
				}
			});
		}
	}

	return selectedItem;
};
