import { FC, useCallback } from 'react';
import _get from 'lodash/get';
import { GridCellProps } from '@progress/kendo-react-grid';
import { AlertEdge, InvestigationAlertSummary } from '@/generated/graphql';
import { IconCell } from '@/app/_common/_components/data-grid/_components/icon-cell/icon-cell';
import { getDetectedByIcon } from '@/app/_common/utils';
import { ContextMenuComponentProps } from '@/app/_common/types';

export interface EventTypeCellProps extends GridCellProps {
	onClick?: (dataItem: AlertEdge | InvestigationAlertSummary) => void;
	ContextMenuComponent?: React.FC<ContextMenuComponentProps>;
	oldContextMenu?: boolean;
}

export const DetectedByCell: FC<EventTypeCellProps> = ({ field, dataItem, onClick = () => null, ContextMenuComponent, ...rest }) => {
	const value = _get(dataItem, field || '');

	const handleCellClick = useCallback(() => {
		if (typeof onClick === 'function') {
			onClick(dataItem);
		}
	}, [dataItem, onClick]);

	const DetectedByIcon = getDetectedByIcon(value);

	return (
		<IconCell ContextMenuComponent={ContextMenuComponent} onClick={handleCellClick} title={value} field={field} dataItem={dataItem} {...rest}>
			{DetectedByIcon}
		</IconCell>
	);
};
