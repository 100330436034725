import { makeAutoObservable } from 'mobx';

import { AuthStore } from '@/app/_common/stores';
import { UsersDataStore } from '@/app/_common/stores/users-store/users.data-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { DropdownOption } from '@/app/_common/_components/forms/_common/dropdown-option.interface';

export class AssigneeSelectViewStore {
	private authStore = injectInterface(this, AuthStore);
	private assigneeDataStore = injectInterface(this, UsersDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });

		this.assigneeDataStore.read();
	}

	get initialAssignee() {
		const currentUser = this.assigneeDataStore.users?.find(({ node }) => this.authStore.user.id === node.id);

		if (currentUser?.node?.id) {
			return { value: currentUser?.node?.id, label: currentUser?.node?.displayName ?? '' };
		}

		return undefined;
	}

	get data() {
		const unassignedAssignee: DropdownOption = { value: null, label: 'Unassigned' };

		let assignees: DropdownOption[] =
			this.assigneeDataStore.users?.map(({ node }) => ({
				value: node.id as string | null,
				label: node.displayName ?? '',
			})) || [];

		assignees = [unassignedAssignee, ...assignees];

		return assignees;
	}

	get loading(): boolean {
		return this.assigneeDataStore.loading;
	}
}
