import { action, makeObservable } from 'mobx';
import { AxiosRequestConfig } from 'axios';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { FetchBaseDataStore } from '@/app/_common/fetch';
import { FetchBaseErrorKey, ResultsDataItem } from '@/app/_common/types';
import { QUERY_RESULTS_CONTAINER } from '@/app/_common/constants';

const ERROR_KEY = FetchBaseErrorKey.AssignQueryResult;

export class SaveQueryResultFileDataStore extends FetchBaseDataStore<ResultsDataItem[]> {
	private authStore = injectInterface({}, AuthStore);

	constructor() {
		super(ERROR_KEY);

		makeObservable(this, {
			saveFile: action,
		});
	}

	public saveFile(resourceId: string, fileBody: string) {
		const config: AxiosRequestConfig = {
			method: 'POST',
			data: { tenantId: this.authStore.currentTenantId, containerName: QUERY_RESULTS_CONTAINER, resourceId, fileBody },
		};

		return this.fetchApiByAxios(process.env.REACT_APP_SAVE_FILE_ENDPOINT as string, config);
	}
}
