import _sortBy from 'lodash/sortBy';

import { AlertsDetailsSessionsPropertiesPaths } from '@/app/_common/constants/alert-details-sessions.constants';
import { MITRE_TACTICS_PRIORITY } from '@/app/_features/alerts/_common/constants';
import { MitreTacticsValue } from '@/app/_common/types';

export const sortArrayFieldValue = <T>(values: T[], field = ''): T[] => {
	if (!Array.isArray(values)) {
		return values;
	}

	if (field === AlertsDetailsSessionsPropertiesPaths.MitreTactics) {
		return _sortBy(values, (value: T) => {
			return MITRE_TACTICS_PRIORITY.indexOf(value as MitreTacticsValue);
		});
	}

	return values;
};
