import { makeAutoObservable } from 'mobx';

import { DataGridDataItem, ResultsDataItem } from '@/app/_common/types';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { NotificationsStore } from '@/app/_common/stores';
import { AssignToInvestigationTabs, InvestigationTypename } from '@/app/_common/constants';
import {
	AssignInvestigationFormValues,
	CreateInvestigationFormValues,
} from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/assign-query-results-to-investigation-dialog/types';
import { AssignQueryResultsToInvestigationDialogDataStore } from './assign-query-results-to-investigation-dialog.data-store';
import { AssignQueryResultsToInvestigationDialogResultsTableViewStore } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/assign-query-results-to-investigation-dialog/stores/assign-query-results-to-investigation-dialog-results-table.view-store';
import { SaveQueryResultFileDataStore } from './save-query-result-file.data-store';
import { ResultsDataStore } from '@/app/advanced-query/_common/stores';
import {
	InvestigationResult,
	InvestigationType,
	MutationAssignQueryResultToInvestigationArgs,
	MutationCreateInvestigationArgs,
	QueryResultInput,
} from '@/generated/graphql';
import { getGraphQLError } from '@/app/_common/graphql/graphql-error-handler';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';
import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { getDetailsViewTypename, getDetailsViewRowId } from '@/app/_common/_components/details-view/utils';
import { InvestigationsCreateDataStore } from '@/app/investigations/_common/stores/investigations-create/investigations-create.data-store';
import { extractResultsTableItemId } from '@/app/_common/utils';
import { countCharacters, EDITOR_TEXT_LIMIT } from '@/app/_common/_components';

interface FormState {
	items: ResultsDataItem[];
	selectedMitreAttacks: string[];
	reason?: string;
	activeTab: AssignToInvestigationTabs;
	createInvestigationFormValues?: CreateInvestigationFormValues;
	assignToInvestigationFormValues?: AssignInvestigationFormValues;
}

const INITIAL_FORM_STATE: FormState = {
	items: [],
	selectedMitreAttacks: [],
	reason: undefined,
	activeTab: AssignToInvestigationTabs.createInvestigation,
	createInvestigationFormValues: undefined,
	assignToInvestigationFormValues: undefined,
};

export class AssignQueryResultsToInvestigationDialogViewStore {
	private formState: FormState = INITIAL_FORM_STATE;

	private investigationsCreateDataStore = injectInterface(this, InvestigationsCreateDataStore);
	private assignQueryResultsToInvestigationDialogDataStore = injectInterface(this, AssignQueryResultsToInvestigationDialogDataStore);
	private notificationsStore = injectInterface(this, NotificationsStore);
	private resultsTableViewStore = injectInterface(this, AssignQueryResultsToInvestigationDialogResultsTableViewStore);
	private saveQueryResultFileDataStore = injectInterface(this, SaveQueryResultFileDataStore);
	private resultsDataStore = injectInterface(this, ResultsDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get items(): ResultsDataItem[] {
		return this.formState.items;
	}

	get loading() {
		return (
			this.assignQueryResultsToInvestigationDialogDataStore.loading ||
			this.investigationsCreateDataStore.loading ||
			this.saveQueryResultFileDataStore.loading
		);
	}

	get reason() {
		return this.formState.reason;
	}

	get isReasonWithinLimit() {
		return this.reason === undefined ? true : countCharacters(JSON.parse(this.reason)) <= EDITOR_TEXT_LIMIT;
	}

	get isDialogOpen() {
		return Boolean(this.formState.items.length);
	}

	get selectedMitreAttacks(): string[] {
		return this.formState.selectedMitreAttacks;
	}

	get activeTab(): AssignToInvestigationTabs {
		return this.formState.activeTab;
	}

	get createInvestigationFormValues() {
		return this.formState.createInvestigationFormValues;
	}

	get assignToInvestigationFormValues() {
		return this.formState.assignToInvestigationFormValues;
	}

	get selectedAlertsIds() {
		const selectedAlertsIds = this.getSelectedResultsIds(DetailsViewTypename.ALERT);

		return selectedAlertsIds.length > 0 ? selectedAlertsIds : undefined;
	}

	get selectedAlertsIdsForQuery() {
		const selectedAlertsIdsForQuery = this.selectedAlertsIds?.map((id: string) => extractResultsTableItemId(id)).filter(Boolean);
		return selectedAlertsIdsForQuery && selectedAlertsIdsForQuery.length > 0 ? selectedAlertsIdsForQuery : undefined;
	}

	get selectedAlertsCount() {
		return this.getSelectedResultsIds(DetailsViewTypename.ALERT).length;
	}

	get selectedEventsCount() {
		return this.getSelectedResultsIds(DetailsViewTypename.EVENT).length;
	}

	get selectedEvidenceCount() {
		return this.getSelectedResultsIds(DetailsViewTypename.EVIDENCE).length;
	}

	get areAllRowsDisabled() {
		return this.selectedAlertsCount === 0 && this.selectedEventsCount === 0 && this.selectedEvidenceCount === 0;
	}

	getSelectedResultsIds = (typename: DetailsViewTypename) => {
		return this.items
			.filter((item: DataGridDataItem<ResultsDataItem>) => {
				const detailsViewTypename = getDetailsViewTypename(item);

				return detailsViewTypename === typename;
			})
			.map((item: DataGridDataItem<ResultsDataItem>) => getDetailsViewRowId(item))
			.filter((id: string) => {
				return this.resultsTableViewStore.selectedRows[id] && !this.resultsTableViewStore.disabledRows[id];
			});
	};

	setMitreAttacks(mitre: string[]) {
		this.formState.selectedMitreAttacks = mitre;
	}

	setItems(items: ResultsDataItem[]): void {
		if (!items) {
			return;
		}

		this.formState.items = Array.isArray(items) ? items : [items];
	}

	setReason(reason?: string) {
		this.formState.reason = reason;
	}

	setActiveTab(tab: AssignToInvestigationTabs) {
		this.formState.activeTab = tab;
	}

	setAssignToInvestigationFormValues = (values: AssignInvestigationFormValues) => {
		this.formState.assignToInvestigationFormValues = values;
	};

	setCreateInvestigationFormValues = (values: CreateInvestigationFormValues) => {
		this.formState.createInvestigationFormValues = values;
	};

	closeDialog(): void {
		this.formState = INITIAL_FORM_STATE;
	}

	createAndAssign = async (query: string) => {
		if (this.activeTab === AssignToInvestigationTabs.assignToInvestigation) {
			await this.assignQueryResultsToInvestigation(query);
		} else {
			await this.createInvestigationAndAssignQueryResult(query);
		}
	};

	assignQueryResultsToInvestigation = async (query: string) => {
		if (!this.assignToInvestigationFormValues) {
			return;
		}

		const assignQueryResultToInvestigationArgs: Omit<MutationAssignQueryResultToInvestigationArgs, 'tenantId'> = {
			investigationId: this.assignToInvestigationFormValues.investigation.value.id,
			queryResultInput: this.getQueryResultInput(query),
			alertIds: this.selectedAlertsIdsForQuery,
		};

		const response = await this.assignQueryResultsToInvestigationDialogDataStore.assign(assignQueryResultToInvestigationArgs);
		const responseData = response?.data?.assignQueryResultToInvestigation;

		await this.createQueryResultFile(responseData);
	};

	createInvestigationAndAssignQueryResult = async (query: string) => {
		if (!this.createInvestigationFormValues) {
			return;
		}

		const createInvestigationArgs: Omit<MutationCreateInvestigationArgs, 'tenantId'> = {
			alertIds: this.selectedAlertsIdsForQuery,
			assigneeId: this.createInvestigationFormValues.assigneeId.value,
			name: this.createInvestigationFormValues.name,
			priority: this.createInvestigationFormValues.priority,
			type: this.createInvestigationFormValues.type.value as InvestigationType,
			queryResultInput: this.getQueryResultInput(query),
		};

		const response = await this.investigationsCreateDataStore.createWithQueryResults(createInvestigationArgs);
		const responseData = response?.data?.createInvestigation;
		await this.createQueryResultFile(responseData);
	};

	private async createQueryResultFile(responseData: InvestigationResult | undefined) {
		if (responseData?.__typename === InvestigationTypename.Investigation) {
			const createdQueryResultId = responseData.queryResults?.[0].id;

			if (createdQueryResultId) {
				await this.saveQueryResultFileDataStore.saveFile(createdQueryResultId, this.getRawResults());

				if (!this.saveQueryResultFileDataStore.error) {
					this.notificationsStore.openSuccess({
						title: i18n.t('assignQueryResult.success.title', { ns: Namespaces.Notifications }),
						content: i18n.t('assignQueryResult.success.content', { ns: Namespaces.Notifications }),
						link: {
							text: i18n.t('assignQueryResult.success.urlText', { ns: Namespaces.Notifications, id: createdQueryResultId }),
							url: `/investigations/${responseData.id}`,
						},
					});
				}
			}
		}
		if (responseData?.__typename === InvestigationTypename.Error) {
			const error = getGraphQLError(i18n.t('assignQueryResult.error.title', { ns: Namespaces.Notifications }), responseData);
			this.notificationsStore.openError({
				title: error.title,
				content: i18n.t('assignQueryResult.error.content', { ns: Namespaces.Notifications, details: error.content }),
			});
		}
	}

	private getRawResults() {
		const columns = this.resultsDataStore.rawColumns.filter(
			(column: { ColumnName: string | number }) => this.resultsTableViewStore.allColumns[column.ColumnName],
		);

		const rows = this.items
			.filter((item) => {
				const id = getDetailsViewRowId(item);
				return this.resultsTableViewStore.selectedRows[id] && !this.resultsTableViewStore.disabledRows[id];
			})
			.map((item) => {
				return Object.values(columns).map((col) => item[col.ColumnName]);
			});

		return JSON.stringify([
			{
				Columns: columns,
				Rows: rows,
			},
		]);
	}

	private getQueryResultInput(query: string): QueryResultInput {
		return {
			alertCount: this.selectedAlertsCount,
			eventCount: this.selectedEventsCount,
			evidenceCount: this.selectedEvidenceCount,
			query,
			mitre: this.selectedMitreAttacks,
			reason: this.reason,
		};
	}
}
