import { FC, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';

import { AlertsGridNoRecordsTranslationProperties, AlertsGridNoRecordsTranslationPath } from './constants';
import { AlertsListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/alerts-list.view-store';
import { Button } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';

import styles from './alerts-grid-no-records-content.module.scss';

export const AlertsGridNoRecordsContent: FC = observer(() => {
	const store = useInstance(AlertsListViewStore);
	const { t } = useTranslation([Namespaces.AlertsDashboard]);

	const hasActiveFilters = store.areFiltersActive;

	const gridNoRecordsForTimePeriodTextKeyProp = hasActiveFilters
		? AlertsGridNoRecordsTranslationProperties.NoAlertsForFilterAndTimeConfiguration
		: AlertsGridNoRecordsTranslationProperties.NoAlertsForTimePeriod;

	const gridNoRecordsTextKeyProp =
		store.totalCount && hasActiveFilters
			? AlertsGridNoRecordsTranslationProperties.NoAlertsForFilterConfiguration
			: gridNoRecordsForTimePeriodTextKeyProp;

	const gridNoRecordsTextKey = `${AlertsGridNoRecordsTranslationPath}.${gridNoRecordsTextKeyProp}`;

	const resetFilters = useCallback(() => {
		store.resetAllFilters();
	}, [store]);

	return (
		<div className={styles.container}>
			<div>{t(gridNoRecordsTextKey)}</div>
			{hasActiveFilters && (
				<Button className={styles.resetFiltersButton} fillMode="outline" onClick={resetFilters}>
					{t('resetFilters', { ns: Namespaces.Common })}
				</Button>
			)}
		</div>
	);
});
