import { FC, useState } from 'react';
import classNames from 'classnames';

import { TabStrip, TabStripProps, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';

import { TabMenuTabTitle } from './_components/tab-menu-tab-title';
import { Tab } from './_common/types';

import styles from './tab-navigation.module.scss';

interface TabNavigationProps extends TabStripProps {
	tabs: Tab[];
	noContentTab?: JSX.Element;
}

export const TabNavigation: FC<TabNavigationProps> = ({ tabs, noContentTab, className, selected, ...props }) => {
	const [selectedTab, setSelectedTab] = useState<number>(0);

	const handleSelect = (event: TabStripSelectEventArguments) => {
		setSelectedTab(event.selected);
	};

	return (
		<>
			<TabStrip selected={selected ?? selectedTab} onSelect={handleSelect} className={classNames(styles.tabNavigation, className)} {...props}>
				{tabs
					.filter(({ display = true }) => display)
					.map(({ title, content, tooltip, disabled = false }) => {
						return (
							<TabStripTab key={title} title={<TabMenuTabTitle disabled={disabled} title={title} tooltip={tooltip} />} disabled={disabled}>
								{content}
							</TabStripTab>
						);
					})}
			</TabStrip>
			{(selected === -1 || selectedTab === -1) && noContentTab}
		</>
	);
};
