import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { browserHistory } from '@/app-insights';
import { AppRoutes } from '@/app/routes';
import { ActiveTenantStore, RouterStore, UserflowStore } from '@/app/_common/stores';
import { FranchiseTenantsDataStore } from '@/app/_common/stores/franchise-tenants-store';
import { useCallback, useEffect } from 'react';
import { syncHistoryWithStore } from './_common/utils';

export const Router = observer(() => {
	const store = useInstance(RouterStore);
	useInstance(UserflowStore);
	const history = syncHistoryWithStore(browserHistory, store);

	history.unsubscribe = useCallback(() => {
		history.listen(({ location }) => {
			store.updateLocation(location);
		});
	}, [history, store]);

	useInstance(ActiveTenantStore);
	useInstance(FranchiseTenantsDataStore);

	useEffect(() => {
		store.updateHistory(history);
		store.updateLocation(history.location);

		return () => {
			if (typeof history.unsubscribe === 'function') {
				history.unsubscribe();
			}
		};
	}, [store, history]);

	return (
		//@ts-ignore
		<HistoryRouter history={history}>
			<AppRoutes />
		</HistoryRouter>
	);
});
