import { memo } from 'react';

import type { GridCellProps } from '@progress/kendo-react-grid';

import { MultiValueCell } from '@/app/_common/_components/data-grid/_components';

export const AlertSourceCell = memo((props: GridCellProps) => {
	return <MultiValueCell {...props} oldContextMenu={false} />;
});

AlertSourceCell.displayName = 'AlertSourceCell';
