import { FC } from 'react';
import classNames from 'classnames';

import CancelIcon from '@mui/icons-material/Cancel';

import { IconButton } from '@/app/_common/_components/rich-text-editor/_components/buttons';

import styles from './cancel-edit-button.module.scss';

interface CancelEditButtonProps {
	onClick: () => void;
	disabled?: boolean;
	text?: string;
}

export const CancelEditButton: FC<CancelEditButtonProps> = ({ onClick, text, disabled = false }) => {
	return (
		<IconButton className={classNames(styles.button, { hasText: Boolean(text) })} onClick={onClick} disabled={disabled}>
			{text && <span className={styles.text}>{text}</span>}
			<CancelIcon className={styles.icon} />
		</IconButton>
	);
};
