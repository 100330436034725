import { Editor, Element, Node, Path } from 'slate';

export const getCurrentItem = (editor: Editor, path?: Path) => {
	if (!path) {
		if (!editor.selection) return null;
		[, path] = Editor.first(editor, editor.selection);
	}

	const nodeOnPath = Node.get(editor, path);
	if (nodeOnPath && Element.isElement(nodeOnPath)) {
		return [nodeOnPath, path];
	}

	return (
		Editor.above(editor, {
			at: path,
			match: (node: Node) => Element.isElement(node),
			mode: 'lowest',
		}) || null
	);
};
