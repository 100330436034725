import { CoreIntegrationFragment, CoreListIntegrationFragment } from '@/app/_common/graphql';
import { gql } from '@apollo/client';

export const CreateIntegration = gql`
	mutation CreateIntegration($tenantId: ID!, $createIntegrationRequest: CreateIntegrationInput!) {
		createIntegration(tenantId: $tenantId, createIntegrationRequest: $createIntegrationRequest) {
			... on Error {
				message
				code
				correlationId
			}
			... on Integration {
				id
				name
				collector {
					id
				}
			}
		}
	}
`;

export const GetIntegration = gql`
	query GetIntegration($tenantId: ID!, $id: ID!) {
		getIntegration(tenantId: $tenantId, id: $id) {
			... on Integration {
				...CoreIntegrationFragment
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
	${CoreIntegrationFragment}
`;

export const GetIntegrationForDelete = gql`
	query GetIntegrationForDelete($tenantId: ID!, $id: ID!) {
		getIntegration(tenantId: $tenantId, id: $id) {
			... on Integration {
				id
				name
				type
				hostname
				collector {
					id
					name
					type
				}
				description
				lastEventTimestamp
				timestamp
				vendor
				productName
				definitionId
				sourceType
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const GetIntegrationAndCollectorBreadcrumb = gql`
	query GetIntegrationAndCollectorBreadcrumb($tenantId: ID!, $integrationId: ID!, $collectorId: ID!) {
		getIntegration(tenantId: $tenantId, id: $integrationId) {
			... on Integration {
				id
				name
				type
				hostname
				productName
				sourceType
				collector {
					id
					type
				}
				vendor
			}
			... on Error {
				message
				code
				correlationId
			}
		}
		getCollector(tenantId: $tenantId, id: $collectorId) {
			... on Collector {
				id
				name
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const GetIntegrationViewBreadcrumb = gql`
	query GetIntegrationViewBreadcrumb($tenantId: ID!, $integrationId: ID!) {
		getIntegration(tenantId: $tenantId, id: $integrationId) {
			... on Integration {
				id
				name
				type
				productName
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const GetAllIntegrations = gql`
	query ListIntegrations($tenantId: ID!, $input: Paging, $filtersInput: FiltersInput) {
		listIntegrations(tenantId: $tenantId, input: $input, filtersInput: $filtersInput) {
			edges {
				node {
					...CoreListIntegrationFragment
				}
				cursor
			}
			pageInfo {
				hasNextPage
				endCursor
			}
		}
	}

	${CoreListIntegrationFragment}
`;

export const IntegrationDeletedSubscription = gql`
	subscription IntegrationDeletedSubscription($tenantId: ID!) {
		resourceDeleted(tenantId: $tenantId) {
			... on Integration {
				id
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const CollectorIntegrationDeletedSubscription = gql`
	subscription CollectorIntegrationDeletedSubscription($tenantId: ID!) {
		resourceDeleted(tenantId: $tenantId) {
			... on Integration {
				id
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const DeleteIntegrationDeletedSubscription = gql`
	subscription CollectorIntegrationDeletedSubscription($tenantId: ID!) {
		resourceDeleted(tenantId: $tenantId) {
			... on Integration {
				id
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const DeleteIntegration = gql`
	mutation deleteIntegration($tenantId: ID!, $id: ID!) {
		deleteIntegration(tenantId: $tenantId, id: $id) {
			... on Integration {
				name
				vendor
				productName
				hostname
				type
				sourceType
				collector {
					id
					type
				}
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;
