import { Node, Path, Range } from 'slate';
import { toggleActionButton, EditorAction, FormattingType, ChildrenProps, CustomEditor } from '@/app/_common/_components/rich-text-editor';

export const withDeleteEmptyBlock = (editor: CustomEditor) => {
	const { deleteBackward } = editor;

	editor.deleteBackward = (unit) => {
		const { selection } = editor;

		if (selection && selection.focus.offset === 0 && selection.anchor.offset === 0 && Range.isCollapsed(selection)) {
			const selectedNodePath = Path.parent(selection.anchor.path);
			const node = Node.parent(editor, selectedNodePath) as Node & { type: string; children: ChildrenProps[] };

			if ((node?.type === FormattingType.ORDERED_LIST || node?.type === FormattingType.UNORDERED_LIST) && node.children.length === 1) {
				toggleActionButton(editor, node?.type, EditorAction.Block);
			}
			if (node?.type === FormattingType.Code && node.children.length === 1) {
				toggleActionButton(editor, FormattingType.Code, EditorAction.Code);
			}
			deleteBackward(unit);
		} else {
			deleteBackward(unit);
		}
	};

	return editor;
};
