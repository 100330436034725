import { FC } from 'react';
import classNames from 'classnames';

import { MitreAttackBadgeContent } from '@/app/_common/_components/mitre-attack-badge/mitre-attack-badge-content';

import styles from '@/app/_common/_components/mitre-attack-badge/mitre-attack-badge.module.scss';

interface MitreAttackBadgeProps {
	text: string;
	count?: number;
	searchValue?: string;
	className?: string;
	hideTooltip?: boolean;
	tactic?: string;
}

export const MitreAttackBadge: FC<MitreAttackBadgeProps> = ({ text, count, searchValue, className, hideTooltip, tactic }) => {
	return (
		<div className={classNames(styles.badge, className)}>
			<MitreAttackBadgeContent text={text} count={count} searchValue={searchValue} hideTooltip={hideTooltip} tactic={tactic} />
		</div>
	);
};
