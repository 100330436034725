import { AlertConfidence, AlertStateOptions, DetectedByTypes } from '@/app/_common/constants';
import { AlertEdge } from '@/generated/graphql';

export const getQualifiedAlertNodes = (alerts: AlertEdge[]) => {
	return alerts.map((alert) => {
		//Group AI Alerts
		if (alert.node.event_source === DetectedByTypes.RTCE_AI) {
			alert.node.event_source = DetectedByTypes.AIEngine;
		}
		//Add Confidence if missing
		if (alert.node.event_details) {
			alert.node.event_details.confidence = alert.node.event_details.confidence || AlertConfidence.Unknown;
		}
		//Add state if missing
		if (!alert.node.state) {
			alert.node.state = {
				//@ts-ignore
				alertState: AlertStateOptions.Unassigned,
				requesterUser: {
					id: '',
					upn: '',
				},
				timestamp: '',
			};
		}

		return alert;
	});
};
