import { useCallback, useEffect, useState } from 'react';

export const useInDocument = (callback?: (value: boolean) => void) => {
	const [inDocument, setInDocument] = useState(false);

	const handleMouseover = useCallback(() => {
		setInDocument(true);

		if (callback) {
			callback(true);
		}
	}, [callback]);

	const handleMouseleave = useCallback(() => {
		setInDocument(false);

		if (callback) {
			callback(false);
		}
	}, [callback]);

	useEffect(() => {
		document.addEventListener('mouseover', handleMouseover);
		document.addEventListener('mouseleave', handleMouseleave);

		return () => {
			document.removeEventListener('mouseover', handleMouseover);
			document.removeEventListener('mouseleave', handleMouseleave);
		};
	}, [handleMouseover, handleMouseleave]);

	return inDocument;
};
