import { FC, useCallback, useState } from 'react';
import i18n from '@/translations/i18n';
import { Popup } from '@progress/kendo-react-popup';
import { useOutsideClick } from '@/app/_common/hooks';
import { buttonize } from '@/app/_common/utils';
import { Info } from '@mui/icons-material';
import { TimelineExplanationItem } from './timeline-explanation-item';
import styles from './timeline-explanation.module.scss';
import ErrorIcon from '@/assets/images/alerts-icon-error.svg';
import InfoIcon from '@/assets/images/alerts-icon-info.svg';
import WarningIcon from '@/assets/images/alerts-icon-warning.svg';
import AlertScoreLevel from '@/assets/images/alert-score-level.svg';
import {
	ExplanationItems,
	ExplanationItemWithId,
} from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/alerts-details-timeline/_common/interface';
import { Namespaces } from '@/translations/namespaces';

//i18n.t('investigationStatusDialog.closeStatus.closePeriodCustom', { ns: Namespaces.InvestigationDetails }),
const explanationItems: ExplanationItemWithId[] = [
	{
		id: ExplanationItems.Enrichment,
		title: i18n.t(`explanation.${ExplanationItems.Enrichment}`, { ns: Namespaces.DetailsView }),
		icon: InfoIcon,
		silenced: 'Yes',
		boostScore: '>= 1',
	},
	{
		id: ExplanationItems.Suspicious,
		title: i18n.t(`explanation.${ExplanationItems.Suspicious}`, { ns: Namespaces.DetailsView }),
		icon: WarningIcon,
		silenced: 'No',
		boostScore: '< 100',
	},
	{
		id: ExplanationItems.Threat,
		title: i18n.t(`explanation.${ExplanationItems.Threat}`, { ns: Namespaces.DetailsView }),
		icon: ErrorIcon,
		silenced: 'No',
		boostScore: '>= 100',
	},
	{
		id: ExplanationItems.BoostAlert,
		title: i18n.t(`explanation.${ExplanationItems.BoostAlert}`, { ns: Namespaces.DetailsView }),
		icon: AlertScoreLevel,
		description: i18n.t('explanation.description', { ns: Namespaces.DetailsView }),
	},
];

export const TimeLineExplanation: FC = () => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const { popupRef, anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		showDropdown,
		useCallback(() => setShowDropdown(false), []),
	);

	const handleToggleDropDown = () => {
		setShowDropdown((show) => !show);
	};

	const displayExplanationItem = explanationItems.map(({ id, ...item }) => <TimelineExplanationItem key={id} {...item} />);

	return (
		<div>
			<div ref={anchorRef} className={styles.iconBox} {...buttonize<HTMLDivElement>(handleToggleDropDown)}>
				<Info className={styles.icon} />
			</div>
			<Popup anchor={anchorRef.current} show={showDropdown} popupClass={styles.popup}>
				<div ref={popupRef}>{displayExplanationItem}</div>
			</Popup>
		</div>
	);
};
