import { FC, useId } from 'react';

import { Maybe } from '@/generated/graphql';

import { Tooltip } from '@/app/_common/_components';

import styles from './status-label.module.scss';

interface StatusLabelProps {
	text: string;
	color: string;
	tooltipText?: Maybe<string>;
}

export const StatusLabel: FC<StatusLabelProps> = ({ text, color, tooltipText }) => {
	const tooltipId = useId();

	if (!text) {
		return null;
	}

	return (
		<>
			<div className={styles.container} style={{ color }} data-for={tooltipId} data-tip="tooltip">
				<span className={styles.colorBar} />
				<span className={styles.text}>{text}</span>
			</div>
			<Tooltip tooltipId={tooltipId} tooltipContent={tooltipText} effect="solid" />
		</>
	);
};
