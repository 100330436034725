import { observer } from 'mobx-react-lite';

import { SeverityCell } from '@/app/_common/_components/data-grid/_components';
import { QueryResultsTableCellWithStoreProps } from '@/app/_common/_components/query-results/query-results-table/types';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';

export const QueryResultsTableSeverityCell = observer((props: QueryResultsTableCellWithStoreProps) => {
	const { searchValue } = useQueryResultsTableStore();

	return <SeverityCell {...props} searchValue={searchValue} showLabel={true} ignoreUnknownValue={true} oldContextMenu={false} />;
});

QueryResultsTableSeverityCell.displayName = 'QueryResultsTableSeverityCell';
