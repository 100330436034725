import { useEffect, useState, useLayoutEffect } from 'react';

import _debounce from 'lodash/debounce';

export interface ResizeParameters {
	width: number;
	height: number;
	isVertical: boolean;
}

export const useResizeObservation = (): [(node: HTMLElement | null) => void, ResizeParameters] => {
	const [ref, setRef] = useState<HTMLElement | null>(null);
	const [parameters, setParameters] = useState<ResizeParameters>({
		width: 0,
		height: 0,
		isVertical: false,
	});

	useLayoutEffect(() => {
		setParameters({
			width: ref?.offsetWidth || 0,
			height: ref?.offsetHeight || 0,
			isVertical: ref?.offsetWidth ? ref?.offsetHeight > ref?.offsetWidth : false,
		});
	}, [ref?.offsetWidth, ref?.offsetHeight]);

	useEffect(() => {
		const resizeObserver = new ResizeObserver(
			_debounce((entries) => {
				const { width, height } = entries[0].contentRect;

				setParameters({
					width,
					height,
					isVertical: width < height,
				});
			}, 50),
		);

		if (ref) {
			resizeObserver.observe(ref);
		}

		return () => {
			if (ref) {
				resizeObserver.unobserve(ref);
			}
		};
	}, [ref]);

	return [setRef, parameters];
};
