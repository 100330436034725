import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import ShareIcon from '@mui/icons-material/Share';
import Fullscreen from '@mui/icons-material/Fullscreen';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Namespaces } from '@/translations/namespaces';
import { PopupMenu, PopupMenuItem, Tooltip, TooltipController } from '@/app/_common/_components';

import styles from './note-actions.module.scss';

const SHARE_NOTE_COPIED_TOOLTIP_ID = 'share-note-copied-tooltip-id';

interface NoteActionsProps {
	disabled?: boolean;
	deleteNote: () => void;
	activateEditNoteMode: () => void;
	openNoteWindow: () => void;
	shareNote: () => void;
}

export const NoteActions = observer<NoteActionsProps>(({ disabled, deleteNote, openNoteWindow, shareNote, activateEditNoteMode }) => {
	const { t } = useTranslation(Namespaces.Notes);

	const moreButtonRef = useRef<SVGSVGElement>(null);

	const hideCopyTooltip = () => {
		setTimeout(() => {
			if (moreButtonRef.current) {
				TooltipController.hide(moreButtonRef.current);
			}
		}, 1500);
	};

	const actions: PopupMenuItem[] = useMemo(
		() => [
			{
				icon: <ShareIcon />,
				label: t('noteActions.share'),
				action: () => {
					shareNote();
					if (moreButtonRef.current) {
						TooltipController.show(moreButtonRef.current);
					}
				},
			},
			{
				icon: <Fullscreen className={styles.openInWindow} />,
				label: t('noteActions.openInWindow'),
				action: () => openNoteWindow(),
			},
			{
				icon: <EditIcon />,
				label: t('noteActions.edit'),
				action: () => activateEditNoteMode(),
			},
			{
				icon: <DeleteIcon />,
				label: t('noteActions.delete'),
				action: () => deleteNote(),
			},
		],
		[t, shareNote, openNoteWindow, activateEditNoteMode, deleteNote],
	);

	return (
		<PopupMenu items={actions} disabled={disabled}>
			<MoreVertIcon ref={moreButtonRef} data-tip="tooltip" data-event="none" data-for={SHARE_NOTE_COPIED_TOOLTIP_ID} />
			<Tooltip tooltipId={SHARE_NOTE_COPIED_TOOLTIP_ID} tooltipContent={t('shareNoteTooltip')} effect={'solid'} afterShow={hideCopyTooltip} />
		</PopupMenu>
	);
});

NoteActions.displayName = 'NoteActions';
