export enum AVAILABLE_FIELDS_BLOCK_NAME {
	EVENTS = 'events',
	ALERTS = 'alerts',
	EVIDENCE = 'evidence',
}

export enum AVAILABLE_FIELDS_EXPANDED_STATE_KEY {
	EVENTS_HEADER = 'EVENTS_HEADER',
	EVENTS_OTHER_FIELDS = 'EVENTS_OTHER_FIELDS',
	ALERTS_HEADER = 'ALERTS_HEADER',
	ALERTS_OTHER_FIELDS = 'ALERTS_OTHER_FIELDS',
	EVIDENCE_HEADER = 'EVIDENCE_HEADER',
	EVIDENCE_OTHER_FIELDS = 'EVIDENCE_OTHER_FIELDS',
}

export type AVAILABLE_FIELDS_EXPANDED_STATE = Record<AVAILABLE_FIELDS_EXPANDED_STATE_KEY, boolean>;
