import { GridHeaderCellProps } from '@progress/kendo-react-grid';
import { observer } from 'mobx-react-lite';

import { Checkbox } from '@/app/_common/_components/checkbox';
import { HeaderCheckboxMode } from '@/app/_common/_components/data-grid/constants';

export interface HeaderSelectCellProps extends GridHeaderCellProps {
	mode: HeaderCheckboxMode;
	onChange: () => void;
}

const modeToValueMap: Record<HeaderCheckboxMode, true | false | null> = {
	[HeaderCheckboxMode.Checked]: true,
	[HeaderCheckboxMode.Unchecked]: false,
	[HeaderCheckboxMode.Indeterminated]: null,
};

export const HeaderSelectCell = observer(({ mode, onChange }: HeaderSelectCellProps) => {
	const value = modeToValueMap[mode];
	return <Checkbox value={value} onChange={onChange} />;
});
