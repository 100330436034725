import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

export enum TelemetryDashboardDataGridPropertyPaths {
	LastUpdated = 'last_timestamp',
	Hostname = 'origin',
	Product = 'product',
	Vendor = 'vendor',
	Status = 'status',
}

export enum TelemetryDashboardReceivedStatus {
	NotHealthy = 'NOT_HEALTHY',
	NotAvailable = 'NOT_AVAILABLE',
}

export enum TelemetryDashboardKeyPrefixes {
	HealthDataGrid = 'healthGrid',
	HealthPieChart = 'healthPieChart',
}

export const TelemetryDashboardStatuses = {
	ActiveSources: i18n.t('healthPieChart.sources.active', { ns: Namespaces.TelemetryDashboard }),
	InactiveSources: i18n.t('healthPieChart.sources.inactive', { ns: Namespaces.TelemetryDashboard }),
};
