import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _isEqual from 'lodash/isEqual';

import { getRichTextEditorTranslations, RichTextEditor } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';
import { ReasonDialog } from '@/app/_common/_components/query-results/reason-dialog';
import { PopupMenu, PopupMenuItem } from '@/app/_common/_components/popup-menu';
import { QueryResultsReasonPlacement } from '@/app/_common/types';
import OpenInNew from '@mui/icons-material/OpenInNew';

import styles from './query-results-reason.module.scss';
import classNames from 'classnames';

interface QueryResultsReasonProps {
	reasonId?: string;
	editedReasonId?: string;
	savedReason?: string;
	loading: boolean;
	content?: string;
	addedDate?: string | Date;
	isWindowOpen?: boolean;
	isError?: boolean;
	readOnly?: boolean;
	onUpdate: (updatedContent: string) => void;
	onCancel?: () => void;
	onSubmit?: () => void;
	onEdit?: (id: string) => void;
	openWindow?: () => void;
	closeWindow?: () => void;
	disabled?: boolean;
	placement: string;
}

const MAX_HEIGHT = 360;

export const QueryResultsReason = ({
	reasonId,
	placement,
	addedDate,
	content,
	isWindowOpen,
	loading,
	isError,
	onCancel,
	onUpdate,
	onEdit,
	openWindow,
	closeWindow,
	editedReasonId,
	savedReason,
	readOnly = false,
	disabled = false,
}: QueryResultsReasonProps) => {
	const [draftContent, setDraftContent] = useState<string | undefined>(content);
	const translations = useMemo(() => getRichTextEditorTranslations(Namespaces.QueryResults), []);
	const { t } = useTranslation(Namespaces.QueryResults);
	const isEditModeActive = editedReasonId === reasonId && !_isEqual(savedReason, draftContent);

	const handleActivateEditReasonMode = useCallback(() => {
		if (typeof onEdit === 'function' && reasonId) {
			onEdit(reasonId);
		}
	}, [reasonId, onEdit]);

	const handleCancelClick = useCallback(() => {
		setDraftContent(content);
		onCancel && onCancel();
	}, [content, onCancel]);

	useEffect(() => {
		setDraftContent(content);

		return () => {
			onCancel && onCancel();
		};
	}, [content, onCancel]);

	const baseSharedProps = useMemo(
		() => ({
			className: !disabled ? styles.textEditor : '',
			maxHeight: MAX_HEIGHT,
			translations: translations,
			editorContent: content,
			isItemWindowOpen: false,
			isEditModeActive: false,
			allowEmptyContent: true,
			highlightEdit: true,
			readOnly,
		}),
		[content, readOnly, translations, disabled],
	);

	const handleOnChange = useCallback(
		(editorContent?: string) => {
			setDraftContent(editorContent);
			onUpdate(editorContent ?? '');
		},
		[onUpdate],
	);

	const queryResultsViewProps = useMemo(
		() => ({
			...baseSharedProps,
			editorContent: draftContent,
			loading,
			onCancel: handleCancelClick,
			onChange: setDraftContent,
			onUpdate: onUpdate,
			onFocus: handleActivateEditReasonMode,
			isEditModeActive: disabled ? false : !isWindowOpen && isEditModeActive,
			readOnly: disabled,
			showSaveCancelButtons: true,
			isSaveCancelButtonsFullTextView: true,
			forceEditFocus: true,
		}),
		[baseSharedProps, draftContent, isEditModeActive, handleCancelClick, onUpdate, loading, isWindowOpen, disabled, handleActivateEditReasonMode],
	);

	const assignQueryResultsProps = useMemo(
		() => ({
			...baseSharedProps,
			editorContent: draftContent,
			loading,
			onChange: handleOnChange,
			isEditModeActive: disabled ? false : !isWindowOpen && isEditModeActive,
			readOnly: disabled,
		}),
		[baseSharedProps, draftContent, handleOnChange, isEditModeActive, loading, isWindowOpen, disabled],
	);

	const headerActions: PopupMenuItem[] = useMemo(
		() => [
			{
				icon: <OpenInNew />,
				label: t('reason.actions.openInWindow'),
				action: () => typeof openWindow === 'function' && openWindow(),
			},
		],
		[openWindow, t],
	);

	return (
		<>
			<div className={classNames(styles.reason, { [styles.reasonError]: isError })}>
				<div className={styles.reasonHeader}>
					<div className={styles.title}>{t('reason.title')}</div>
					{typeof openWindow === 'function' && <PopupMenu items={headerActions} disabled={!content && disabled} />}
				</div>
				{disabled && !content ? (
					<span>{t('none')}</span>
				) : (
					<>
						{placement === QueryResultsReasonPlacement.QueryResultsView ? (
							<RichTextEditor {...queryResultsViewProps} toolbarClassName={styles.toolbar} />
						) : (
							<RichTextEditor {...assignQueryResultsProps} toolbarClassName={styles.toolbar} />
						)}
					</>
				)}
			</div>

			{isWindowOpen && typeof closeWindow === 'function' && (
				<ReasonDialog
					addedDate={addedDate}
					content={draftContent}
					closeWindow={closeWindow}
					handleUpdate={onUpdate}
					activateEditMode={handleActivateEditReasonMode}
					onCancelEdit={handleCancelClick}
					isEditModeActive={isEditModeActive}
					loading={loading}
					disabled={disabled}
				/>
			)}
		</>
	);
};
