import { BOOST_SCORE_ERROR_THRESHOLD } from '@/app/_common/constants';
import { BoostAlertType } from '../boost-alert-type';

export const getBoostAlertType = (boostScore: string, silenced?: boolean) => {
	if (silenced) {
		return BoostAlertType.Enrichment;
	} else if (Number(boostScore) >= BOOST_SCORE_ERROR_THRESHOLD) {
		return BoostAlertType.Threat;
	}

	return BoostAlertType.Suspicious;
};
