import { AllowedAdxTables } from '@/app/_common/constants';

export const getTablesFromQuery = (query: string) => {
	if (!query) {
		return [];
	}

	const [tableQuerySection] = query.split('|');

	if (!tableQuerySection) {
		return [];
	}

	return AllowedAdxTables.filter((allowedTable) => tableQuerySection.includes(allowedTable));
};
