import { Fingerprint, List, NotificationImportantRounded } from '@mui/icons-material';
import { AdxTables } from '../../constants';

type AdxIcons = {
	[K in AdxTables]: JSX.Element;
};

interface AdxIconProps {
	type?: AdxTables;
}

const icons: AdxIcons = {
	alerts: <NotificationImportantRounded />,
	events: <List />,
	evidence: <Fingerprint />,
};

export const AdxIcon: React.FC<AdxIconProps> = ({ type }) => {
	if (!type) return null;
	return icons[type] || null;
};
