import { gql } from '@apollo/client';

export const CoreGetInvestigationQueryResultsFragment = gql`
	fragment CoreGetInvestigationQueryResultsFragment on Investigation {
		queryResults {
			id
			alertCount
			eventCount
			evidenceCount
		}
	}
`;

export const CoreInvestigationAlertSummaryFragment = gql`
	fragment CoreInvestigationAlertSummaryFragment on InvestigationAlertSummary {
		id
		timestamp
		type
		name
		severity
		src
		dst
		confidence
		mitre_category
		addedTimestamp
		event_source
		state {
			alertState
			requesterUser {
				upn
				id
			}
			timestamp
		}
	}
`;

export const CoreGetBasicInvestigationFieldsFragment = gql`
	fragment CoreGetBasicInvestigationFieldsFragment on Investigation {
		id
		tenantId
		timestamp
		last_updated
		closedDetails {
			timestamp
			reason
			description
		}
		priority
		status
		name
		type
		assignee {
			id
			displayName
		}
		alerts {
			...CoreInvestigationAlertSummaryFragment
		}
		sleepingDetails {
			startTimestamp
			endTimestamp
			description
		}
	}
	${CoreInvestigationAlertSummaryFragment}
`;

export const CoreGetInvestigationFragment = gql`
	fragment CoreGetInvestigationFragment on Investigation {
		...CoreGetBasicInvestigationFieldsFragment
		...CoreGetInvestigationQueryResultsFragment
	}
	${CoreGetBasicInvestigationFieldsFragment}
	${CoreGetInvestigationQueryResultsFragment}
`;

export const CoreAlertFragment = gql`
	fragment CoreAlertFragment on Alert {
		id
		timestamp
		severity
		title
		data_objects {
			data_path {
				container
				key
			}
			object_type
		}
		event_details {
			confidence
			mitre_category
			src
			dst
		}
		investigationSummary {
			id
		}
		event_source
		state {
			alertState
			requesterUser {
				upn
				id
			}
			timestamp
		}
		externalReferenceUrl
		summary
		recommendation
	}
`;

export const CoreNoteFragment = gql`
	fragment CoreNoteFragment on Note {
		id
		tenantId
		timestamp
		last_updated
		artifactType
		artifactId
		requesterUser {
			upn
			id
		}
		updatedBy {
			requesterUser {
				id
				upn
			}
			timestamp
		}
		noteContent
	}
`;

export const CoreQueryResultFragment = gql`
	fragment CoreQueryResultFragment on QueryResult {
		id
		tenantId
		investigationId
		last_updated
		timestamp
		mitre
		query
		reason
		requesterUser {
			upn
			id
		}
		updatedBy {
			requesterUser {
				id
				upn
			}
			timestamp
		}
	}
`;

export const CoreInvestigationFragment = gql`
	fragment CoreInvestigationFragment on Investigation {
		id
		alerts {
			id
			timestamp
			type
			name
			src
			severity
			dst
			confidence
			mitre_category
			event_source
			addedTimestamp
		}
		timestamp
		last_updated
		tenantId
		type
		priority
		status
		name
		assignee {
			displayName
			id
		}
		closedDetails {
			timestamp
			reason
			description
		}
	}
`;

export const CoreIntegrationFragment = gql`
	fragment CoreIntegrationFragment on Integration {
		id
		name
		description
		vendor
		productName
		completedTimestamp
		lastEventTimestamp
		collector {
			id
			type
			name
			hostname
			location
		}
		status
		statusReason
		timestamp
		last_updated
		sourceType
		type
		hostname
		components {
			configuration {
				key
				value
			}
		}
		definitionId
	}
`;

export const CoreListIntegrationFragment = gql`
	fragment CoreListIntegrationFragment on Integration {
		collector {
			id
			type
			name
		}
		id
		name
		description
		vendor
		productName
		timestamp
		lastEventTimestamp
		status
		statusReason
		last_updated
		hostname
		sourceType
		type
	}
`;

export const CoreUpdateNoteFragment = gql`
	fragment CoreUpdateNoteFragment on Note {
		id
		tenantId
		timestamp
		last_updated
		artifactType
		artifactId
		requesterUser {
			upn
			id
		}
		updatedBy {
			requesterUser {
				upn
				id
			}
			timestamp
		}
		noteContent
	}
`;

const TelemetryIntegrationDefinitionComponents = gql`
	fragment TelemetryIntegrationDefinitionComponents on IntegrationDefinition {
		components {
			type
			description
			environments {
				name
				type
				displayName
				description
				required {
					mandatory
					optionally {
						name
						value
					}
				}
				enumValues
				defaultValue
				minValue
				maxValue
				minLength
				maxLength
			}
		}
	}
`;

const ResponseIntegrationDefinitionComponents = gql`
	fragment ResponseIntegrationDefinitionComponents on IntegrationDefinition {
		responseComponents {
			type
			description
			environments {
				name
				type
				displayName
				description
				required {
					mandatory
					optionally {
						name
						value
					}
				}
				enumValues
				defaultValue
				minValue
				maxValue
				minLength
				maxLength
			}
		}
	}
`;

export const CoreIntegrationDefinitionComponentsFragment = gql`
	fragment CoreIntegrationDefinitionComponentsFragment on IntegrationDefinition {
		...TelemetryIntegrationDefinitionComponents
		...ResponseIntegrationDefinitionComponents
	}
	${TelemetryIntegrationDefinitionComponents}
	${ResponseIntegrationDefinitionComponents}
`;

export const CoreResponseIntegrationFragment = gql`
	fragment CoreResponseIntegrationFragment on Integration {
		id
		name
		description
		vendor
		productName
		status
		statusReason
	}
`;

export const CoreEndpointFragment = gql`
	fragment CoreEndpointFragment on Endpoint {
		id
		hostname
		operatingSystem
		responseIntegration {
			id
			name
			productName
			vendor
		}
		action {
			type
			status
			requestor {
				upn
				id
			}
			comment
			timestamp
			last_updated
		}
		networkInformation {
			lastIpAddress
		}
		currentStatus
	}
`;

export const CoreCloudCollectorIntegrationFragment = gql`
	fragment CoreCloudCollectorIntegrationFragment on Integration {
		id
		status
		statusReason
		vendor
		lastEventTimestamp
		timestamp
		collector {
			id
			type
		}
		name
		description
		productName
		type
		sourceType
	}
`;

export const CoreSyslogCollectorIntegrationFragment = gql`
	fragment CoreSyslogCollectorIntegrationFragment on Integration {
		id
		status
		statusReason
		vendor
		productName
		hostname
		lastEventTimestamp
		timestamp
		type
		collector {
			id
		}
	}
`;

export const UserFragment = gql`
	fragment UserFragment on User {
		id
		displayName
		firstName
		lastName
		upn
		lastLogin
		inviteExpirationTimestamp
		hasCompletedRegistration
		tenants {
			id
			invitedBy
			invitedTimestamp
			registrationTimestamp
			isBillingUser
		}
		last_updated
		role
		timestamp
	}
`;

export const FranchiseUserFragment = gql`
	fragment FranchiseUserFragment on FranchiseUser {
		displayName
		firstName
		franchise {
			id
			isOwner
		}
		hasCompletedRegistration
		id
		last_updated
		lastLogin
		lastName
		timestamp
		upn
		invitedBy
		registrationTimestamp
	}
`;
