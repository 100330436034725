import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { getAdvancedQueryDetailsViewTypename, getGraphqlQueryDetailsViewTypename } from '@/app/_common/_components/details-view/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDetailsViewTypename = (item?: any): DetailsViewTypename => {
	if (!item) {
		return DetailsViewTypename.UNKNOWN;
	}

	try {
		const advancedQueryResultsTypename = getAdvancedQueryDetailsViewTypename(item);
		if (advancedQueryResultsTypename !== DetailsViewTypename.UNKNOWN) {
			return advancedQueryResultsTypename;
		}

		const graphqlQueryTypename = getGraphqlQueryDetailsViewTypename(item);
		if (graphqlQueryTypename !== DetailsViewTypename.UNKNOWN) {
			return graphqlQueryTypename;
		}

		return DetailsViewTypename.UNKNOWN;
	} catch (error) {
		return DetailsViewTypename.UNKNOWN;
	}
};
