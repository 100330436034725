import { v4 as uuid } from 'uuid';

type SubstringDetails = {
	id: string;
	substr: string;
};

export const separateSearchTerm = (value: string | number, searchLine: string): SubstringDetails[] => {
	if (!searchLine) {
		return [];
	}

	const sanitizedSearchLine = searchLine.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

	const splittedStr = `${value}`.split(new RegExp(`(${sanitizedSearchLine})`, 'gi'));

	return splittedStr
		.filter((substr) => /\S/.test(substr))
		.map((substr) => ({
			id: uuid(),
			substr,
		}));
};
