import { SwitchTenantStore } from '@/app/_common/stores/switch-tenant.store';
import { Component } from 'react';
import { provider } from 'react-ioc';

import { FetchClient } from '@/app/_common/fetch/fetch-client';
import { GraphqlClient } from '@/app/_common/graphql/graphql-client';
import { NotificationsStore } from '@/app/_common/stores/notifications.store';

import { AppConfigurationDataStore } from '@/app/_common/stores/app-configuration-store/app-configuration.data-store';
import { AppConfigurationViewStore } from '@/app/_common/stores/app-configuration-store/app-configuration.view-store';
import {
	ThemeStore,
	RouterStore,
	AuthStore,
	UrlStore,
	ActiveTenantStore,
	UserflowSignatureViewStore,
	UserflowSignatureDataStore,
	UserflowStore,
	UserPreferenceStore,
} from '@/app/_common/stores';
import { Theme } from '@/app/_common/_components/theme/theme';
import { Router } from '@/app/router';
import { withAITracking, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '@/app-insights';
import { TelemetryProvider } from '@/app-insights/telemetry-provider';
import '@/app/_common/_components/syntax-highlighters/register-syntaxes';
import { NotificationStickyBannerStore } from './investigation-details/_common/stores/notification-sticky-banner-store';
import { FranchiseTenantsDataStore } from '@/app/_common/stores/franchise-tenants-store';
import { FranchiseLogoDataStore } from './_common/_components/navbar/_components/franchise-logo.data-store';
import { PageLogoViewStore } from './_common/_components/navbar/_components/page-logo.view-store';

const onError = () => null;

export const AppModule = provider(
	GraphqlClient,
	FetchClient,
	ThemeStore,
	AuthStore,
	NotificationsStore,
	SwitchTenantStore,
	RouterStore,
	UrlStore,
	ActiveTenantStore,
	AppConfigurationDataStore,
	AppConfigurationViewStore,
	UserflowSignatureViewStore,
	UserflowSignatureDataStore,
	UserflowStore,
	UserPreferenceStore,
	NotificationStickyBannerStore,
	FranchiseTenantsDataStore,
	PageLogoViewStore,
	FranchiseLogoDataStore,
)(
	// When we want to register services from descendant modules in RootModule - it must be a class :(
	class AppModuleComponent extends Component {
		render() {
			return (
				<Theme>
					<TelemetryProvider connectionString={process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING}>
						<AppInsightsErrorBoundary onError={onError} appInsights={reactPlugin}>
							<Router />
						</AppInsightsErrorBoundary>
					</TelemetryProvider>
				</Theme>
			);
		}
	},
);

export default withAITracking(reactPlugin, AppModule);
