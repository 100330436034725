import { Alert, Investigation } from '@/generated/graphql';
import { AlertWithInvestigationSummaryExtended } from '@/app/_common/types';

export const extendAlertsWithInvestigationDetails = (alerts: Alert[], investigations: Investigation[]): AlertWithInvestigationSummaryExtended[] => {
	const investigationMap = investigations.reduce<Map<string, Investigation>>((map, investigation) => {
		map.set(investigation.id, investigation);
		return map;
	}, new Map());

	return alerts.map((alert) => {
		if (!alert.investigationSummary) {
			return alert;
		}

		const investigation = investigationMap.get(alert.investigationSummary.id);

		if (!investigation) {
			return alert;
		}

		return {
			...alert,
			investigationSummary: {
				...alert.investigationSummary,
				status: investigation.status,
				name: investigation.name,
			},
		};
	});
};
