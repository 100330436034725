import { TimelineItemData } from '../interface/timeline-item-data';

export type SetTimelineItemsZindexParam = Pick<TimelineItemData, 'order' | 'zindex'>;

/** \returns Zindex which would be higher than any of created indexes. */
export const setTimelineItemsZindex = (items: SetTimelineItemsZindexParam[], baseZindex: number): number => {
	const unorderedOrders = new Set<number>();
	items.forEach((curr) => {
		unorderedOrders.add(curr.order);
	});
	const orders = Array.from(unorderedOrders).sort((a, b) => a - b);

	let nextZindex = baseZindex + 1;
	orders.forEach((currOrder) => {
		items
			.filter((item) => item.order === currOrder)
			.forEach((item) => {
				item.zindex = nextZindex;
				nextZindex += 1;
			});
	});

	return nextZindex;
};
