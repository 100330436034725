import { ReactNode } from 'react';
import classNames from 'classnames';
import _union from 'lodash/union';
import _difference from 'lodash/difference';

import { FilterList } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { FilterValue, Filters } from '@/app/_common/types';
import { useTranslation } from 'react-i18next';
import { Namespaces } from '@/translations/namespaces';

import styles from './filters-actions.module.scss';

interface FiltersContextMenuFilterButtonProps {
	className?: string;
	field?: string;
	closeContextMenu?: () => void;
	value: FilterValue | string[] | number[];
	activeEqualFilters?: Filters;
	handleAddEqualFilter?: (field: string, values: Filters) => void;
	handleAddNotEqualFilter?: (field: string, values: Filters) => void;
	filterLabel?: ReactNode;
}

export const FiltersAction = ({
	className = '',
	activeEqualFilters = [],
	field = '',
	value,
	filterLabel,
	handleAddEqualFilter,
	handleAddNotEqualFilter,
	closeContextMenu,
}: FiltersContextMenuFilterButtonProps) => {
	const { t } = useTranslation(Namespaces.AlertsActions, { keyPrefix: 'menu.actions.value.items.filter' });

	const isAddEqualFilterButtonActive = Array.isArray(value)
		? !value.some((item) => !activeEqualFilters.includes(item))
		: Boolean(activeEqualFilters.includes(value));

	const equalFilterIcon = isAddEqualFilterButtonActive ? <AddCircleIcon /> : <AddCircleOutlineIcon />;

	const onAddEqualFilter = () => {
		if (!handleAddEqualFilter) {
			return;
		}

		// Item values are filtered. Thus removing them from filters (clearing these filter values).
		if (isAddEqualFilterButtonActive) {
			const filterValues = Array.isArray(value) ? value : [value];
			const newFilters = _difference(activeEqualFilters, filterValues);
			handleAddEqualFilter(field, newFilters);
			// Add array values to filters
		} else if (Array.isArray(value)) {
			handleAddEqualFilter(field, _union(activeEqualFilters, value));
			// Add string or number value to filters
		} else if (field && value) {
			handleAddEqualFilter(field, _union(activeEqualFilters, [value]));
			// Add 'null' value to filters
		} else if (value === null) {
			handleAddEqualFilter(field, [...activeEqualFilters, null]);
		}

		if (closeContextMenu) {
			closeContextMenu();
		}
	};

	if (!handleAddEqualFilter && !handleAddNotEqualFilter) {
		return null;
	}

	return (
		<div className={classNames(styles.container, className)}>
			<span className={styles.filterLabel}>
				<FilterList /> {filterLabel ?? t('label')}
			</span>
			{handleAddEqualFilter && (
				<IconButton
					onClick={onAddEqualFilter}
					className={classNames(styles.equalFilterIcon, { [styles.active]: isAddEqualFilterButtonActive })}
					size="small"
				>
					{equalFilterIcon}
				</IconButton>
			)}
		</div>
	);
};
