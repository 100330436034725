import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AdvancedQueryResultsTableViewStore } from '@/app/advanced-query/_common/stores';
import { BaseResultsDetailsViewStore } from '@/app/_common/_components/details-view/stores/base-results-details.view-store';

export class AdvancedQueryAlertDetailsViewStore extends BaseResultsDetailsViewStore {
	protected resultsViewStore = injectInterface(this, AdvancedQueryResultsTableViewStore);

	constructor() {
		super();
	}
}
