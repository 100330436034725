import { TimelineItemData } from '@/app/_common/_components/details-view/alerts-details-view-navigation';
import AlertScoreLevel from '@/assets/images/alert-score-level.svg';

export const addTriggeredAlert = (
	items: TimelineItemData[],
	className: string,
	triggeredAlertDate?: string,
	triggeredAlertName?: string,
): TimelineItemData[] => {
	if (!(triggeredAlertDate && triggeredAlertName)) {
		return items;
	}
	const triggeredItem = {
		index: items.length,
		time: triggeredAlertDate,
		showDetails: false,
		icon: AlertScoreLevel,
		name: triggeredAlertName,
		left: 0,
		order: 0,
		className: className,
		triggered: true,
	};
	return [...items, triggeredItem];
};
