import { Operation } from '@apollo/client';

import { LOGGING_TAG } from '@/app/_common/constants';

export const getCorrelationId = (operation: Operation) => {
	return operation?.getContext().response?.headers?.get('correlationId');
};

export const getOperationName = (operation: Operation) => {
	return operation?.operationName;
};

export const LOG_GRAPHQL_REQUEST = (operation: Operation) => {
	if (process.env.REACT_APP_LOG_GRAPHQL_OPERATIONS === 'true') {
		// eslint-disable-next-line
		console.debug(`${LOGGING_TAG}\n`, `${getOperationName(operation)} (${getCorrelationId(operation)})\n`, 'VARIABLES:', operation.variables);
	}
};
