import { useCallback, useState, ReactNode } from 'react';
import classNames from 'classnames';
import { Popup } from '@progress/kendo-react-popup';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useOutsideClick } from '@/app/_common/hooks';
import { buttonize } from '@/app/_common/utils';

import styles from './custom-content-dropdown.module.scss';

export interface CustomContentDropdownProps {
	children: ReactNode;
	button?: JSX.Element;
	buttonContainerClassName?: string;
}

export const CustomContentDropdown = ({ button, buttonContainerClassName, children }: CustomContentDropdownProps) => {
	const [showDropdown, setShowDropdown] = useState<boolean>(false);

	const { popupRef, anchorRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(
		showDropdown,
		useCallback(() => setShowDropdown(false), []),
	);

	const toggleDropdown = useCallback(() => {
		setShowDropdown((showDropdown) => !showDropdown);
	}, []);

	return (
		<div>
			<div
				className={classNames(styles.buttonContainer, buttonContainerClassName, { [styles.openedPopup]: showDropdown })}
				ref={anchorRef}
				{...buttonize<HTMLDivElement>(toggleDropdown)}
			>
				{button ?? <MoreVertIcon />}
			</div>

			<Popup anchor={anchorRef.current} show={showDropdown} popupClass={classNames(styles.popup)} animate={false}>
				<div className={styles.popupContent} ref={popupRef} {...buttonize<HTMLDivElement>(toggleDropdown)}>
					{children}
				</div>
			</Popup>
		</div>
	);
};
