import { useMemo } from 'react';
import moment from 'moment';

import { EXPIRY_BAR_LIFETIME_IN_MILLISECONDS } from '@/app/_common/_components/token-expiration-bar/use-token-expiry';

export const useShowExpiryBar = (remainingTimeInMilliseconds: number) => {
	const showExpiryBar = useMemo(() => {
		return remainingTimeInMilliseconds > 0 && remainingTimeInMilliseconds < EXPIRY_BAR_LIFETIME_IN_MILLISECONDS;
	}, [remainingTimeInMilliseconds]);

	const timeToExpire = useMemo(() => moment.utc(remainingTimeInMilliseconds).format('mm:ss'), [remainingTimeInMilliseconds]);

	return { showExpiryBar, timeToExpire };
};
