import { FC } from 'react';

import classNames from 'classnames';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { FilterButton } from '@/app/_common/_components/filter-panel';

import styles from './option-group-item-buttons.module.scss';
import { ArrayFilterOperators, FilterOperators } from '@/app/_common/constants';

interface OptionGroupItemButtonsProps {
	onClick: (operator: ArrayFilterOperators) => void;
	selectedOperator?: FilterOperators;
}

export const OptionGroupItemButtons: FC<OptionGroupItemButtonsProps> = ({ onClick, selectedOperator }) => {
	const handleEqualButtonClick = () => {
		onClick(FilterOperators.IsIn);
	};

	const handleNotEqualButtonClick = () => {
		onClick(FilterOperators.IsNotIn);
	};

	return (
		<div className={styles.optionGroupButtons}>
			<FilterButton
				value={FilterOperators.IsIn}
				active={selectedOperator === FilterOperators.IsIn}
				activeIcon={<AddCircleIcon className={classNames(styles.active, styles.equal)} data-testid="active" />}
				notActiveIcon={<AddCircleOutlineIcon data-testid="not-active" />}
				onClick={handleEqualButtonClick}
				className={styles.optionGroupButton}
				data-testid="add-positive-filter-button"
			/>
			<FilterButton
				value={FilterOperators.IsNotIn}
				active={selectedOperator === FilterOperators.IsNotIn}
				activeIcon={<RemoveCircleIcon className={classNames(styles.active, styles.notEqual)} data-testid="active" />}
				notActiveIcon={<RemoveCircleOutlineIcon data-testid="not-active" />}
				onClick={handleNotEqualButtonClick}
				className={styles.optionGroupButton}
				data-testid="add-negative-filter-button"
			/>
		</div>
	);
};
