import { makeAutoObservable } from 'mobx';

import { prepareAvailableFieldsValuesQuery } from '@/app/_common/utils/adx';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import {
	AdvancedQueryViewStore,
	AvailableFieldsValuesDataStore,
	AvailableFieldsViewStore,
	TotalCountDataStore,
} from '@/app/advanced-query/_common/stores';
import { AdxTables } from '@/app/_common/constants';
import { AVAILABLE_FIELDS_BLOCK_NAME, AvailableField } from '@/app/_common/types';
import { AdxSchemaDataStore, AuthStore } from '@/app/_common/stores';

export class AvailableFieldsValuesViewStore {
	private authStore = injectInterface(this, AuthStore);
	private schemaDataStore = injectInterface(this, AdxSchemaDataStore);
	private advancedQueryViewStore = injectInterface(this, AdvancedQueryViewStore);
	private availableFieldsViewStore = injectInterface(this, AvailableFieldsViewStore);
	private availableFieldsValuesDataStore = injectInterface(this, AvailableFieldsValuesDataStore);
	private totalCountDataStore = injectInterface(this, TotalCountDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get title(): string {
		if (this.availableFieldsViewStore.selectedField) {
			return this.availableFieldsViewStore.selectedField;
		}

		return '';
	}

	get totalItems(): number {
		return this.totalCountDataStore.totalCount;
	}

	get rareValues(): AvailableField[] {
		return this.availableFieldsValuesDataStore?.values?.rare;
	}

	get topValues(): AvailableField[] {
		return this.availableFieldsValuesDataStore?.values?.top;
	}

	get rareCount(): number {
		return this.makeCount(this.rareValues);
	}

	get topCount(): number {
		return this.makeCount(this.topValues);
	}

	get valuesTotalCount(): number {
		return this.availableFieldsValuesDataStore?.values?.valuesTotalCount ?? 0;
	}

	get loading(): boolean {
		return this.availableFieldsValuesDataStore.loading;
	}

	get uniqValuesCount(): number {
		return this.availableFieldsValuesDataStore?.values?.uniqValuesCount ?? 0;
	}

	private makeCount = (values: AvailableField[]): number => values.reduce((result: number, item: AvailableField) => result + Number(item.count), 0);

	getTotalCount = (table: AVAILABLE_FIELDS_BLOCK_NAME): number => {
		if (this.totalItems === 0) {
			return 0;
		}

		return this.totalCountDataStore.data?.find(({ $table }) => $table === table)?.count_ ?? 0;
	};

	public getPercentage = (table: AVAILABLE_FIELDS_BLOCK_NAME): string => {
		const totalItems = this.getTotalCount(table);
		const visibleValuesCount = this.topCount + this.rareCount;

		if (visibleValuesCount > totalItems) {
			return '100';
		}

		return ((visibleValuesCount / totalItems) * 100).toFixed(2);
	};

	public read = (table: AdxTables): void => {
		const schemaColumns = this.schemaDataStore.getTableSchema(table)?.OrderedColumns ?? [];
		const schemaColumnsNames = schemaColumns.map(({ Name }) => Name) ?? [];
		const isMultipleTablesSelected = this.advancedQueryViewStore.isMultipleTablesSelected;
		const duplicatedDynamicNames = isMultipleTablesSelected
			? this.schemaDataStore.getDuplicatedTableColumnsDynamicNames(table, this.advancedQueryViewStore.tables)
			: [];
		const selectedField = this.availableFieldsViewStore.selectedField ?? '';
		const column = schemaColumns.find(({ Name, CslType }) => {
			if (duplicatedDynamicNames.includes(selectedField)) {
				return selectedField === `${Name}_${CslType}`;
			}

			return selectedField === Name;
		});
		const filters = this.advancedQueryViewStore.activeQueryFilters.filters
			.filter((filter) => [...schemaColumnsNames, ...duplicatedDynamicNames].includes(filter.field))
			.map((filter) => {
				const column = schemaColumns.find((column) => filter.field === `${column.Name}_${column.CslType}`);

				if (column) {
					return {
						field: column.Name,
						operator: filter.operator,
						values: filter.values,
					};
				}

				return filter;
			});

		const query = prepareAvailableFieldsValuesQuery(
			table,
			this.advancedQueryViewStore.query,
			column,
			this.schemaDataStore.schema,
			this.authStore.currentTenantId,
			this.advancedQueryViewStore.activeQueryFilters.timeRange,
			filters,
		);

		this.availableFieldsValuesDataStore.fetch(query);
	};
}
