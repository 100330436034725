import moment from 'moment';

import { isTimestampFilterInQuery } from '@/app/_common/utils';
import { KUSTO_QUERY_DATE_TIME_FORMAT, DateTimePickerOptionKeys, RELATIVE_DATE_TIME_RANGES } from '@/app/_common/constants';
import { TimeRangeFilterOption } from '@/app/_common/types';

export const getDateTimeFilter = (query: string, dateTimeColumns: string[], timestampColumn: string, timeRange?: TimeRangeFilterOption) => {
	if (isTimestampFilterInQuery(query, dateTimeColumns) || !timestampColumn || !timeRange) {
		return '';
	}

	if (timeRange.value?.from && timeRange.value?.to) {
		const valueFrom = moment.utc(timeRange.value.from).format(KUSTO_QUERY_DATE_TIME_FORMAT);
		const valueTo = moment.utc(timeRange.value.to).format(KUSTO_QUERY_DATE_TIME_FORMAT);
		return `where ["${timestampColumn}"] between (datetime(${valueFrom}) .. datetime(${valueTo}))`;
	} else if (timeRange.key !== DateTimePickerOptionKeys.CUSTOM) {
		return `where ["${timestampColumn}"] >= ago(${RELATIVE_DATE_TIME_RANGES[timeRange.key]})`;
	} else {
		return '';
	}
};
