import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorDetailsTitle } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';

export const AvailableFieldsEmpty: FC = () => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: 'tabs.advancedQuery' });
	return <ErrorDetailsTitle title={t('availableFields.empty')} />;
};
