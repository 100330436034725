import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import TableChartIcon from '@mui/icons-material/TableChart';

import { Checkbox } from '@/app/_common/_components/checkbox';
import { buttonize } from '@/app/_common/utils';
import { Divider } from '@/app/_common/_components/divider';
import { Button } from '@/app/_common/_components/button';

import styles from './actions-header-cell.module.scss';

interface ActionsItem {
	id: string;
	label: string;
	onClick?: () => void;
	itemComponent?: ReactNode;
}

interface ActionsPopupContentProps {
	items?: ActionsItem[];
	columnOptions?: Array<{ label: string; id: string; checked: boolean }>;
	onColumnOptionsChange?: (field: string) => void;
	onColumnsReset?: () => void;
	hideColumnsDisabled?: boolean;
	closePopup?: () => void;
}

export const ActionsPopupContent: FC<ActionsPopupContentProps> = ({
	items = [],
	closePopup,
	columnOptions = [],
	onColumnsReset,
	hideColumnsDisabled,
	onColumnOptionsChange,
}) => {
	const hasPopup = Boolean(items?.length) || Boolean(columnOptions?.length) || Boolean(onColumnsReset);
	const showDivider = Boolean(items?.length) && Boolean(columnOptions?.length);

	return (
		<>
			{hasPopup && (
				<div className="k-list-scroller">
					{items?.length > 0 && (
						<ul className="k-list k-reset">
							{items?.map(({ id, label, onClick, itemComponent = null }) => {
								const handleClick = () => {
									if (typeof onClick === 'function') {
										onClick();
									}

									if (typeof closePopup === 'function') {
										closePopup();
									}
								};
								return (
									<li key={id} title={label} className={classNames(styles.action, 'k-item')} {...buttonize<HTMLElement>(handleClick)}>
										{itemComponent || label}
									</li>
								);
							})}
						</ul>
					)}
					{(Boolean(columnOptions?.length) || Boolean(onColumnsReset)) && (
						<div>
							{showDivider && <Divider />}
							<PanelBar className={styles.columns} expandMode="single">
								<PanelBarItem
									className={styles.columnsContainer}
									title={
										<div className={styles.columnsTitle}>
											<TableChartIcon className={styles.columnsIcon} />
											Columns
										</div>
									}
								>
									<div>
										<Button className={styles.clearButton} fillMode="outline" onClick={onColumnsReset}>
											Reset Columns
										</Button>
										{Boolean(columnOptions?.length) && (
											<>
												<Divider />
												<ul className={styles.columnsList}>
													{columnOptions.map((option) => (
														<PanelBarItem
															key={option.id}
															className={classNames(styles.columnsItem, { [styles.columnsItemActive]: option.checked })}
															title={
																<Checkbox
																	className={styles.checkbox}
																	disabled={hideColumnsDisabled && option.checked}
																	value={option.checked}
																	onChange={() => onColumnOptionsChange && onColumnOptionsChange(option.id)}
																	label={option.label}
																/>
															}
														/>
													))}
												</ul>
											</>
										)}
									</div>
								</PanelBarItem>
							</PanelBar>
						</div>
					)}
				</div>
			)}
		</>
	);
};
