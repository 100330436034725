export enum AlertsDetailsSessionsPropertiesPaths {
	Type = 'type',
	ShortDescription = 'shortdesc',
	DetectTech = 'detect_tech',
	Platform = 'platform',
	Protocol = 'protocol',
	DestIP = 'dest_ip',
	DestCountry = 'dest_country',
	Action = 'action',
	DestIsp = 'dest_isp',
	SrcIp = 'src_ip',
	Src = 'src',
	DateTime = 'datetime',
	SrcPort = 'src_port',
	SrcCountry = 'src_country',
	DestPort = 'dest_port',
	Device = 'device',
	DestURL = 'dest_url',
	Status = 'status',
	Method = 'method',
	ContentType = 'content_type',
	UserAgent = 'user_agent',
	User = 'user',
	Signature = 'signature',
	DestRecord = 'dest_record',
	Severity = 'severity',
	RecordType = 'record_type',
	DomainTld = 'domain_tld',
	Dest = 'dest',
	Service = 'service',
	Category = 'category',
	AppPath = 'app_path',
	FilePath = 'file_path',
	PerformedAction = 'performed_action',
	PerformedActionRaw = 'performed_action_raw',
	RequestedAction = 'requested_action',
	RequestedActionRaw = 'requested_action_raw',
	ActualAction = 'actual_action',
	TimeDetected = 'time_detected',
	TimeReported = 'time_reported',
	FilehashMd5 = 'filehash_md5',
	FilehashSha1 = 'filehash_sha1',
	FilehashSha256 = 'filehash_sha256',
	Interval = 'interval',
	ICMPType = 'icmp_type',
	ICMPCode = 'icmp_code',
	Referer = 'referer',
	AppDest = 'app_dest',
	URLHost = 'url_host',
	Command = 'command',
	SrcObjectType = 'src_object_type',
	SrcObjectName = 'src_object_name',
	DestObjectType = 'dest_object_type',
	DestObjectName = 'dest_object_name',
	ChangeType = 'change_type',
	AlertId = 'alert_id',
	Kcstate = 'kcstate',
	VendorDescription = 'vendordescription',
	VendorIdentifier = 'vendoridentifier',
	OseventCategory = 'oseventcategory',
	SrcOsversion = 'src_osversion',
	Subject = 'subject',
	KChainState = 'kchain_state',
	Details = 'details',
	MitreTactics = 'mitre_tactics',
	MitreTechniques = 'mitre_techniques',
}
