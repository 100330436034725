import { FC, useMemo } from 'react';
import classNames from 'classnames';

import { AlertHostBox, AlertNetworkBox, AlertUserBox } from './_components';
import { useInstance } from 'react-ioc';
import { AlertsDetailsNavigationViewStore } from '@/app/_common/_components/details-view/alerts-details-view-navigation';
import { EvidenceApiDTO, HOST_USER_EVIDENCE_CATEGORY_VALUE } from '@/app/_common/constants/alert-details.constants';

import styles from './alert-host-user-box.module.scss';

export const AlertHostUserBox: FC = () => {
	const alertDetailsNavigationStore = useInstance(AlertsDetailsNavigationViewStore);
	const { evidenceApiDetails } = alertDetailsNavigationStore;

	const hostNetworkUserDetails: EvidenceApiDTO | undefined = useMemo(() => {
		const hostNetworkUserInfo: EvidenceApiDTO[] = Array.isArray(evidenceApiDetails) ? evidenceApiDetails : [];
		return hostNetworkUserInfo.find((evidence) => {
			// data.evidence_category === 'Host' is the evidence category value that marks data used in the alert host/user info component
			return evidence.evidence_category === HOST_USER_EVIDENCE_CATEGORY_VALUE;
		});
	}, [evidenceApiDetails]);

	if (!hostNetworkUserDetails) {
		return null;
	}

	const usernames = hostNetworkUserDetails?.users ?? [];

	return (
		<div className={classNames(styles.container)}>
			<div className={classNames(styles.content)}>
				<AlertHostBox
					hostName={hostNetworkUserDetails.hostname}
					hostType={hostNetworkUserDetails.hosttype}
					operatingSystem={hostNetworkUserDetails.os}
				/>
				<AlertNetworkBox externalIP={hostNetworkUserDetails.external_ip} internalIP={hostNetworkUserDetails.local_ip} />
				<AlertUserBox usernames={usernames} />
			</div>
		</div>
	);
};
