import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';
import { PanelBarItemProps } from '@progress/kendo-react-layout';
import { AutoIntervalsBreakpointsKeys, AutoIntervalsKeys, IntervalsKeys } from '../types';

export const AUTO_INTERVAL_UNIQUE_PRIVATE_KEY = '.0.0';

export const MAXIMUM_BARS_NUMBER = 500;

export const AUTO_INTERVALS_AS_SECONDS: Record<AutoIntervalsKeys, number> = {
	[AutoIntervalsKeys.ONE_SECOND]: 1,
	[AutoIntervalsKeys.FIFTEEN_SECONDS]: 15,
	[AutoIntervalsKeys.THIRTY_SECONDS]: 30,
	[AutoIntervalsKeys.ONE_MINUTE]: 60,
	[AutoIntervalsKeys.TEN_MINUTES]: 10 * 60,
	[AutoIntervalsKeys.THIRTY_MINUTES]: 30 * 60,
	[AutoIntervalsKeys.THREE_HOURS]: 3 * 60 * 60,
	[AutoIntervalsKeys.TWELEVE_HOURS]: 12 * 60 * 60,
	[AutoIntervalsKeys.ONE_DAY]: 24 * 60 * 60,
};

export const AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS: Record<AutoIntervalsBreakpointsKeys, number> = {
	[AutoIntervalsBreakpointsKeys.FIVE_MINUTES]: 5 * 60,
	[AutoIntervalsBreakpointsKeys.TWENTY_MINUTES]: 20 * 60,
	[AutoIntervalsBreakpointsKeys.FORTY_FIVE_MINUTES]: 45 * 60,
	[AutoIntervalsBreakpointsKeys.FOUR_HOURS]: 4 * 60 * 60,
	[AutoIntervalsBreakpointsKeys.SIXTEEN_HOURS]: 16 * 60 * 60,
	[AutoIntervalsBreakpointsKeys.THREE_DAYS]: 3 * 24 * 60 * 60,
	[AutoIntervalsBreakpointsKeys.FOURTEEN_DAYS]: 14 * 24 * 60 * 60,
	[AutoIntervalsBreakpointsKeys.SIXTY_DAYS]: 60 * 24 * 60 * 60,
	[AutoIntervalsBreakpointsKeys.INFINITY]: Number.MAX_SAFE_INTEGER,
};

export const AUTO_INTERVALS_RANGES: { min: number; max: number; interval: number }[] = [
	{
		min: 0,
		max: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.FIVE_MINUTES],
		interval: AUTO_INTERVALS_AS_SECONDS[AutoIntervalsKeys.ONE_SECOND],
	},
	{
		min: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.FIVE_MINUTES],
		max: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.TWENTY_MINUTES],
		interval: AUTO_INTERVALS_AS_SECONDS[AutoIntervalsKeys.FIFTEEN_SECONDS],
	},
	{
		min: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.TWENTY_MINUTES],
		max: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.FORTY_FIVE_MINUTES],
		interval: AUTO_INTERVALS_AS_SECONDS[AutoIntervalsKeys.THIRTY_SECONDS],
	},
	{
		min: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.FORTY_FIVE_MINUTES],
		max: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.FOUR_HOURS],
		interval: AUTO_INTERVALS_AS_SECONDS[AutoIntervalsKeys.ONE_MINUTE],
	},
	{
		min: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.FOUR_HOURS],
		max: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.SIXTEEN_HOURS],
		interval: AUTO_INTERVALS_AS_SECONDS[AutoIntervalsKeys.TEN_MINUTES],
	},
	{
		min: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.SIXTEEN_HOURS],
		max: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.THREE_DAYS],
		interval: AUTO_INTERVALS_AS_SECONDS[AutoIntervalsKeys.THIRTY_MINUTES],
	},
	{
		min: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.THREE_DAYS],
		max: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.FOURTEEN_DAYS],
		interval: AUTO_INTERVALS_AS_SECONDS[AutoIntervalsKeys.THREE_HOURS],
	},
	{
		min: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.FOURTEEN_DAYS],
		max: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.SIXTY_DAYS],
		interval: AUTO_INTERVALS_AS_SECONDS[AutoIntervalsKeys.TWELEVE_HOURS],
	},
	{
		min: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.SIXTY_DAYS],
		max: AUTO_INTERVALS_BREAKPOINTS_AS_SECONDS[AutoIntervalsBreakpointsKeys.INFINITY],
		interval: AUTO_INTERVALS_AS_SECONDS[AutoIntervalsKeys.ONE_DAY],
	},
];

export const INTERVALS_AS_SECONDS: Record<IntervalsKeys, number> = {
	[IntervalsKeys.MILLISECOND]: 0.001,
	[IntervalsKeys.SECOND]: 1,
	[IntervalsKeys.MINUTE]: 60,
	[IntervalsKeys.HOUR]: 60 * 60,
	[IntervalsKeys.DAY]: 24 * 60 * 60,
	[IntervalsKeys.WEEK]: 7 * 24 * 60 * 60,
	[IntervalsKeys.MONTH]: 30 * 24 * 60 * 60,
};

export const INTERVAL_UNITS: { value: number; unit: string; minPredefinedValue?: number }[] = [
	{ value: INTERVALS_AS_SECONDS[IntervalsKeys.MILLISECOND], unit: 'ms', minPredefinedValue: 0.01 }, // 10 ms
	{ value: INTERVALS_AS_SECONDS[IntervalsKeys.SECOND], unit: 'sec' },
	{ value: INTERVALS_AS_SECONDS[IntervalsKeys.MINUTE], unit: 'min' },
	{ value: INTERVALS_AS_SECONDS[IntervalsKeys.HOUR], unit: 'h' },
	{ value: INTERVALS_AS_SECONDS[IntervalsKeys.DAY], unit: 'day' },
	{ value: INTERVALS_AS_SECONDS[IntervalsKeys.WEEK], unit: 'week' },
	{ value: INTERVALS_AS_SECONDS[IntervalsKeys.MONTH], unit: 'mth' },
];

const translationPrefix = 'tabs.advancedQuery.chart.actionBlock.intervals';

export const INTERVAL_OPTIONS: PanelBarItemProps[] = [
	{
		title: i18n.t(`${translationPrefix}.automatic`, { ns: Namespaces.AdvancedQuery }),
		valueInSeconds: undefined,
	},
	{
		title: i18n.t(`${translationPrefix}.milliseconds`, { ns: Namespaces.AdvancedQuery }),
		valueInSeconds: INTERVALS_AS_SECONDS[IntervalsKeys.MILLISECOND],
	},
	{
		title: i18n.t(`${translationPrefix}.seconds`, { ns: Namespaces.AdvancedQuery }),
		valueInSeconds: INTERVALS_AS_SECONDS[IntervalsKeys.SECOND],
	},
	{
		title: i18n.t(`${translationPrefix}.minutes`, { ns: Namespaces.AdvancedQuery }),
		valueInSeconds: INTERVALS_AS_SECONDS[IntervalsKeys.MINUTE],
	},
	{
		title: i18n.t(`${translationPrefix}.hours`, { ns: Namespaces.AdvancedQuery }),
		valueInSeconds: INTERVALS_AS_SECONDS[IntervalsKeys.HOUR],
	},
	{
		title: i18n.t(`${translationPrefix}.days`, { ns: Namespaces.AdvancedQuery }),
		valueInSeconds: INTERVALS_AS_SECONDS[IntervalsKeys.DAY],
	},
	{
		title: i18n.t(`${translationPrefix}.weeks`, { ns: Namespaces.AdvancedQuery }),
		valueInSeconds: INTERVALS_AS_SECONDS[IntervalsKeys.WEEK],
	},
	{
		title: i18n.t(`${translationPrefix}.months`, { ns: Namespaces.AdvancedQuery }),
		valueInSeconds: INTERVALS_AS_SECONDS[IntervalsKeys.MONTH],
	},
];
