import { FC } from 'react';

import { useSlate } from 'slate-react';

import classNames from 'classnames';

import InsertLinkIcon from '@mui/icons-material/InsertLink';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SendIcon from '@mui/icons-material/Send';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import CodeIcon from '@mui/icons-material/Code';

import {
	EditorAction,
	EDITOR_TEXT_LIMIT,
	Toolbar,
	EditorButton,
	LinkButton,
	TableButton,
	EditorCounter,
	SendButton,
	SubmitEditButton,
	CancelEditButton,
	IsCurrentFormatActive,
	EditorType,
} from '@/app/_common/_components/rich-text-editor';
import { FormattingType } from '@/app/_common/_components/rich-text-editor/_common/constant';
import { RichTextEditorTranslations } from '@/app/_common/_components/rich-text-editor/types';

import styles from './rich-text-toolbar.module.scss';

interface RichTextToolbarProps {
	className?: string;
	count: number;
	loading?: boolean;
	editMode?: boolean;
	onSubmit: (() => Promise<void> | void) | undefined;
	onCancel?: () => void;
	showSaveCancelButtons?: boolean;
	isSaveCancelButtonsFullTextView?: boolean;
	allowEmptyContent: boolean;
	translations: RichTextEditorTranslations;
}

export const RichTextToolbar: FC<RichTextToolbarProps> = ({
	count,
	loading,
	editMode,
	showSaveCancelButtons,
	isSaveCancelButtonsFullTextView,
	className,
	onCancel = () => null,
	onSubmit,
	translations,
	allowEmptyContent,
}) => {
	const editor = useSlate();
	const isCodeBlockActive = IsCurrentFormatActive(editor, EditorType.Code);
	const isSubmitButtonDisabled = allowEmptyContent ? count > EDITOR_TEXT_LIMIT : count > EDITOR_TEXT_LIMIT || count === 0;

	let buttonsCancelSubmit = null;

	if (editMode || showSaveCancelButtons) {
		buttonsCancelSubmit = (
			<>
				<CancelEditButton onClick={onCancel} text={isSaveCancelButtonsFullTextView ? translations.cancel : ''} disabled={loading} />
				{isSaveCancelButtonsFullTextView && <span className={styles.buttonDivider}>|</span>}
				{onSubmit && (
					<SubmitEditButton
						onClick={onSubmit}
						text={isSaveCancelButtonsFullTextView ? translations.save : ''}
						disabled={isSubmitButtonDisabled || loading}
					/>
				)}
			</>
		);
	} else if (onSubmit) {
		buttonsCancelSubmit = <SendButton loading={loading} disabled={isSubmitButtonDisabled} icon={<SendIcon />} handleSubmit={onSubmit} />;
	}

	return (
		<div className={classNames(styles.toolbar, className)}>
			<Toolbar>
				<EditorButton format={FormattingType.Code} action={EditorAction.Code} icon={<CodeIcon />} />
				<LinkButton format={FormattingType.Link} icon={<InsertLinkIcon />} disabled={isCodeBlockActive} translations={translations} />
				<EditorButton format={FormattingType.Bold} action={EditorAction.Mark} icon={<FormatBoldIcon />} disabled={isCodeBlockActive} />
				<EditorButton format={FormattingType.Italic} action={EditorAction.Mark} icon={<FormatItalicIcon />} disabled={isCodeBlockActive} />
				<EditorButton format={FormattingType.Underline} action={EditorAction.Mark} icon={<FormatUnderlinedIcon />} disabled={isCodeBlockActive} />
				<EditorButton
					format={FormattingType.UNORDERED_LIST}
					action={EditorAction.Block}
					icon={<FormatListBulletedIcon />}
					disabled={isCodeBlockActive}
				/>
				<EditorButton
					format={FormattingType.ORDERED_LIST}
					action={EditorAction.Block}
					icon={<FormatListNumberedIcon />}
					disabled={isCodeBlockActive}
				/>
				<TableButton format={FormattingType.Table} icon={<BorderAllIcon />} disabled={isCodeBlockActive} />
			</Toolbar>
			<Toolbar>
				<EditorCounter count={count} limit={EDITOR_TEXT_LIMIT} />
				{buttonsCancelSubmit}
			</Toolbar>
		</div>
	);
};
