import { FC, ChangeEvent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { TimelineHeader, Timeline } from './_components';
import { addTriggeredAlert, prepareTimelineItems } from './_common/utils';
import { AlertsDetailsNavigationViewStore } from '@/app/_common/_components/details-view/alerts-details-view-navigation';

import styles from './alerts-details-timeline.module.scss';

const RANGE_MIN = 0.8;
const SPACE_PER_ITEM = 160;

export const AlertsDetailsTimeline: FC = observer(() => {
	const store = useInstance(AlertsDetailsNavigationViewStore);

	const handleSetZoom = (e: ChangeEvent<HTMLInputElement>) => {
		store.setZoom(Number(e.currentTarget.value));
	};

	useEffect(() => {
		return () => {
			store.setZoom(RANGE_MIN);
		};
	}, [store]);

	if (!store.signatures) {
		return null;
	}

	const items = prepareTimelineItems(store.signatures);

	const timelineItems = addTriggeredAlert(items, styles.triggeredItem, store.triggeredAlertDate, store.triggeredAlertName);

	return (
		<div className={styles.alertsDetailsTimeline}>
			<TimelineHeader value={store.zoom} onChange={handleSetZoom} />
			<Timeline items={timelineItems} itemWidth={SPACE_PER_ITEM} zoom={store.zoom} />
		</div>
	);
});
