import { KeyboardEvent, MouseEvent, ReactNode, useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import classNames from 'classnames';
import { Tooltip } from '@/app/_common/_components';
import styles from './avatar.module.scss';
import { buttonize } from '../../utils';

export interface AvatarProps {
	name: string;
	active?: boolean;
	overlap?: boolean;
	tooltipEnabled?: boolean;
	tooltipContent?: ReactNode;
	onClick?: (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => void;
}

export const Avatar = ({ name, active = false, overlap = false, tooltipEnabled = true, tooltipContent = name, onClick }: AvatarProps) => {
	const tooltipId = useMemo(() => `avatar.${uniqueId()}`, []);

	const tooltipAttributes = tooltipEnabled
		? {
				'data-tip': tooltipId,
				'data-for': tooltipId,
		  }
		: {};
	const clickEventListeners = useMemo(() => (onClick ? buttonize(onClick) : {}), [onClick]);
	return (
		<div className={styles.container}>
			<div
				data-testid="avatar"
				{...tooltipAttributes}
				{...clickEventListeners}
				className={classNames(styles.avatar, { [styles.active]: active, [styles.overlap]: overlap, [styles.clickable]: !!onClick })}
			>
				{name.slice(0, 2)}
				{/*Temporary solution until we have only emails*/}
			</div>
			{tooltipEnabled && <Tooltip tooltipId={tooltipId} tooltipContent={tooltipContent} />}
		</div>
	);
};
