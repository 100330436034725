import { ActionsHeaderItem, ActionsItem } from '@/app/_common/_components/data-grid/_components';
import { CellProps } from '@progress/kendo-react-data-tools';

type ActionItem = ActionsItem | ActionsHeaderItem;

export const filterActionsByShow = (item: ActionItem, dataItem?: CellProps['dataItem']): boolean => {
	const { show } = item;

	if (typeof show === 'undefined') {
		return false;
	}

	if (typeof show === 'boolean') {
		return show;
	}

	return show(dataItem);
};
