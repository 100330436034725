import { useInstance } from 'react-ioc';
import { useSubscription } from '@apollo/client';

import { CoreNoteFragment, GraphqlClient } from '@/app/_common/graphql';
import { AuthStore } from '@/app/_common/stores';
import { Note, NotesConnection, Subscription, SubscriptionNoteCreatedArgs, SubscriptionNoteUpdatedArgs } from '@/generated/graphql';
import { NoteTypename } from '@/app/_common/constants';
import { NoteCreatedSubscription, NoteUpdatedSubscription } from '@/app/_common/graphql/queries/notes.queries';
import { InvestigationDetailsViewStore } from '@/app/investigation-details/_common/stores';

const mergeInvestigationNoteCreateWithCache = (client: GraphqlClient, data?: Note) => {
	if (data) {
		client.cache.modify({
			fields: {
				listNotes(existingInvestigationNotesRefs: NotesConnection, { readField }) {
					const node = client.cache.writeFragment({
						fragment: CoreNoteFragment,
						data,
					});

					const exists = existingInvestigationNotesRefs?.edges?.some(
						(ref) => data.__typename === NoteTypename.Note && readField('id', ref.node) === data.id,
					);

					if (!node || exists) {
						return existingInvestigationNotesRefs;
					}

					const newEdge = {
						node,
						__typename: NoteTypename.NoteEdge,
						cursor: '',
					};

					return {
						...(existingInvestigationNotesRefs || {}),
						edges: [...(existingInvestigationNotesRefs.edges || []), newEdge],
					};
				},
			},
		});
	}
};

const mergeInvestigationNoteUpdateWithCache = (client: GraphqlClient, data?: Note) => {
	if (data) {
		const identifier = client.cache.identify(data);

		client.cache.modify({
			id: identifier,
			fields: {
				id() {
					return data.id;
				},
				tenantId() {
					return data.tenantId;
				},
				timestamp() {
					return data.timestamp;
				},
				last_updated() {
					return data.last_updated;
				},
				artifactType() {
					return data.artifactType;
				},
				artifactId() {
					return data.artifactId;
				},
				requesterUser() {
					return data.requesterUser;
				},
				updatedBy() {
					return data.updatedBy;
				},
				noteContent() {
					return data.noteContent;
				},
			},
		});
	}
};

export const useInvestigationNotesSubscription = () => {
	const authStore = useInstance(AuthStore);
	const graphqlClient = useInstance(GraphqlClient);
	const { investigationId } = useInstance(InvestigationDetailsViewStore);

	useSubscription<Subscription, SubscriptionNoteCreatedArgs>(NoteCreatedSubscription, {
		client: graphqlClient,
		variables: {
			tenantId: authStore.currentTenantId,
		},
		onSubscriptionData: ({ subscriptionData }) => {
			const artifactId = subscriptionData?.data?.noteCreated?.artifactId;
			if (artifactId === investigationId) {
				mergeInvestigationNoteCreateWithCache(graphqlClient, subscriptionData?.data?.noteCreated);
			}
		},
	});

	useSubscription<Subscription, SubscriptionNoteUpdatedArgs>(NoteUpdatedSubscription, {
		client: graphqlClient,
		variables: {
			tenantId: authStore.currentTenantId,
		},
		onSubscriptionData: ({ subscriptionData }) => {
			mergeInvestigationNoteUpdateWithCache(graphqlClient, subscriptionData?.data?.noteUpdated);
		},
	});
};
