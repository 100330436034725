import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ThreatIntelligenceDescription } from '@/app/_common/types';
import { AlertDetailsKeyPrefix } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';
import { ThreatIntelligenceTag } from '../_common/_components';

import styles from './threat-intelligence-panel-content-descriptions-section.module.scss';

interface ThreatIntelligencePanelContentDescriptionsSectionProps {
	descriptions: ThreatIntelligenceDescription[];
	className?: string;
}

export const ThreatIntelligencePanelContentDescriptionsSection: FC<ThreatIntelligencePanelContentDescriptionsSectionProps> = ({
	descriptions,
	className = '',
}) => {
	const { t } = useTranslation(Namespaces.DetailsView, { keyPrefix: AlertDetailsKeyPrefix.ThreatIntelligencePanel });

	if (!descriptions.length) {
		return null;
	}

	return (
		<div className={className}>
			<h4>{t('description')}</h4>
			{descriptions.map((item) => (
				<div key={item.title} className={styles.descriptionsContainer}>
					<ThreatIntelligenceTag tag={item.title} />
					<p className={styles.description}>{item.description}</p>
				</div>
			))}
		</div>
	);
};
