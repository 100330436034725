import { IOCResponseFile, ThreatIntelligenceHit, ThreatIntelligenceMatches } from '@/app/_common/types';
import { v4 as uuidd } from 'uuid';

export const getThreatIntelligenceMatches = (data: IOCResponseFile[]): ThreatIntelligenceMatches => {
	if (!Array.isArray(data) || !data.length) {
		return {};
	}

	const allHitsCollection: ThreatIntelligenceHit[] = data
		.map((iocFile) => {
			const values = Object.keys(iocFile.misp_result.misp_checks);
			const hitsArr: ThreatIntelligenceHit[] = [];

			values.forEach((value) => {
				const hits: ThreatIntelligenceHit[] = iocFile.misp_result.misp_checks[value];

				if (!Array.isArray(hits)) {
					return [];
				}

				const trueHits = hits.filter((hit) => hit.misp_hit === true);

				hitsArr.push(...trueHits);
			});

			return hitsArr;
		})
		.flat();

	const matches = allHitsCollection.reduce((acc, curr) => {
		if (!curr.identifier) {
			return acc;
		}

		const currentValue = curr.identifier;

		if (Object.prototype.hasOwnProperty.call(acc, currentValue)) {
			acc[currentValue] = [...acc[currentValue], { ...curr, id: uuidd() }];
			return acc;
		}

		acc[currentValue] = [{ ...curr, id: uuidd() }];
		return acc;
	}, {} as ThreatIntelligenceMatches);

	return matches;
};
