import { action, computed, makeObservable } from 'mobx';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';

import { SortDescriptor } from '@progress/kendo-data-query';
import { DataGridViewStore } from './data-grid.view-store';
import { Columns, DataGridViewStoreState, SortFunction } from './types';
import { FieldValue, FiltersInput } from '@/generated/graphql';
import { adaptKendoFiltersToQueryValueFilters, callOrReturnValue } from '@/app/_common/utils';
import { FilterGroupId, FilterLogic, FilterOperators } from '@/app/_common/constants';
import { FilterDescriptorWithId, CompositeFilterDescriptorWithId } from '@/app/_common/types';
import { SwitchTenantStore } from '@/app/_common/stores';

interface ExtendedGridState extends DataGridViewStoreState {
	queryFilters: FiltersInput;
}

export class DataGridWithAPIViewStore<T> extends DataGridViewStore<T> {
	private switchTenantStore: SwitchTenantStore | null = null;

	public gridState: ExtendedGridState = {
		...this.gridState,
		queryFilters: {},
	};

	constructor(
		idPath: string,
		initialColumns: Columns,
		initialSort?: SortDescriptor[],
		sortFunction?: SortFunction<T>,
		apiFiltering?: boolean,
		version?: string,
		expanded?: boolean,
		unsortableRow?: string,
	) {
		super(idPath, initialColumns, initialSort, sortFunction, apiFiltering, version, expanded, unsortableRow);

		makeObservable(this, {
			queryValueFilters: computed,
			hasActiveQueryValueFilters: computed,
			setQueryValueFilters: action,
			submitQueryValueFilters: action,
		});
	}

	get queryValueFilters() {
		return this.gridState.queryFilters.valueFilters ?? [];
	}

	get hasActiveQueryValueFilters(): boolean {
		return this.queryValueFilters.length !== 0;
	}

	setQueryValueFilters = (filters: FieldValue[]) => {
		this.gridState.queryFilters.valueFilters = _cloneDeep(filters);
	};

	submitQueryValueFilters = (): void => {
		const newValueFilters = adaptKendoFiltersToQueryValueFilters(this.filter) ?? [];
		const lastValueFilters = this.queryValueFilters;

		if (newValueFilters.length !== lastValueFilters.length) {
			this.setQueryValueFilters(newValueFilters);
			return;
		}

		const sameValues = newValueFilters.every((filter) => {
			const correspondingLastSentFilter = lastValueFilters.find((lastSentFilter) => _isEqual(lastSentFilter, filter));
			return correspondingLastSentFilter !== undefined;
		});

		if (sameValues) {
			return;
		}

		this.setQueryValueFilters(newValueFilters);
	};

	toggleGridHeaderArrayFilterValue = ({ field, operator, value }: FilterDescriptorWithId) => {
		const { filter: compositeFilterDescriptor, filterIndex: groupIndex } = this.findOrCreateFilter(this.filter, FilterGroupId.gridHeader, {
			id: FilterGroupId.gridHeader,
			logic: FilterLogic.And,
			filters: [],
		});

		if (!compositeFilterDescriptor) {
			return;
		}

		const fieldKey = callOrReturnValue(field);

		if (!fieldKey) {
			return;
		}

		const { filter: filterGroup } = this.findOrCreateFilter(
			compositeFilterDescriptor,
			field,
			{
				id: fieldKey,
				logic: FilterLogic.Or,
				filters: [],
			},
			groupIndex,
		);

		if (!filterGroup || !('filters' in filterGroup)) {
			return;
		}

		this.toggleArrayFilterValue(filterGroup, { field, operator, value });
	};

	private toggleArrayFilterValue = (compositeFilterDescriptor: CompositeFilterDescriptorWithId, filterDescriptor: FilterDescriptorWithId) => {
		const { field, operator, value } = filterDescriptor;
		const fieldKey = callOrReturnValue(field);

		if (!fieldKey) {
			return;
		}

		const filterIndex = compositeFilterDescriptor.filters.findIndex(this.findFilter(fieldKey, operator));

		if (filterIndex === -1) {
			compositeFilterDescriptor.filters.push({ field, operator, value: [value], ignoreCase: false });

			this.removeOppositeArrayFilterValue(compositeFilterDescriptor.filters, fieldKey, operator, value);

			return;
		}

		const filter = compositeFilterDescriptor.filters[filterIndex];

		if (!('value' in filter)) {
			return;
		}

		const valueIndex = filter.value.findIndex((item: string | number) => item === value);

		if (valueIndex === -1) {
			filter.value = [...filter.value, value];

			this.removeOppositeArrayFilterValue(compositeFilterDescriptor.filters, fieldKey, operator, value);
		} else {
			filter.value.splice(valueIndex, 1);

			if (filter.value.length === 0) {
				compositeFilterDescriptor.filters.splice(filterIndex, 1);
			}
		}
	};

	private removeOppositeArrayFilterValue = (
		filters: (FilterDescriptorWithId | CompositeFilterDescriptorWithId)[],
		field: string,
		operator: FilterDescriptorWithId['operator'],
		value: FilterDescriptorWithId['value'],
	): void => {
		const oppositeFilterIndex = filters.findIndex(
			this.findFilter(field, operator === FilterOperators.IsIn ? FilterOperators.IsNotIn : FilterOperators.IsIn),
		);

		if (oppositeFilterIndex < 0) {
			return;
		}

		const oppositeFilter = filters[oppositeFilterIndex];

		if (!('value' in oppositeFilter)) {
			return;
		}

		const oppositeValueIndex = oppositeFilter.value.findIndex((item: string | number) => item === value);

		if (oppositeValueIndex > -1) {
			oppositeFilter.value.splice(oppositeValueIndex, 1);

			if (oppositeFilter.value.length === 0) {
				filters.splice(oppositeFilterIndex, 1);
			}
		}
	};

	private findOrCreateFilter = (
		filter: CompositeFilterDescriptorWithId | FilterDescriptorWithId,
		field: FilterDescriptorWithId['field'],
		defaultFilter: CompositeFilterDescriptorWithId,
		defaultFilterParentIndex?: number,
	): { filter: CompositeFilterDescriptorWithId | FilterDescriptorWithId | null; filterIndex?: number } => {
		if (!field || !('filters' in filter)) {
			return {
				filter: null,
			};
		}

		const fieldKey = callOrReturnValue(field);

		let filterIndex = filter.filters.findIndex(this.findFilter(fieldKey));

		if (filterIndex === -1) {
			this.pushFilter(defaultFilter, defaultFilterParentIndex);

			filterIndex = filter.filters.length - 1;
		}

		return { filter: filter.filters[filterIndex], filterIndex };
	};

	protected initializeSessionStorage = (storageEntryId: string) => {
		if (this.switchTenantStore === null) {
			this.switchTenantStore = new SwitchTenantStore(storageEntryId);
		}

		return this.switchTenantStore.init(this.gridState, {
			name: storageEntryId,
			properties: ['sort', 'filter', 'queryFilters'],
			storage: window.sessionStorage,
		});
	};
}
