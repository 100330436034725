import { reaction, makeObservable, observable, computed } from 'mobx';

import { ValueOperator, FieldValue } from '@/generated/graphql';

export interface State {
	showSystemActions: boolean;
	filter: FieldValue | null;
}

const REQUESTER_USER_UPN_DB_FIELD = 'requesterUser.upn';

const SYSTEM_USER_FILTER: FieldValue = {
	field: REQUESTER_USER_UPN_DB_FIELD,
	operator: ValueOperator.IsNot,
	value: process.env.REACT_APP_SYSTEM_USER_UPN,
};

const INITIAL_STATE = {
	showSystemActions: false,
	filter: SYSTEM_USER_FILTER,
};

const SHOW_SYSTEM_ACTIONS_SESSION_STORAGE_KEY = 'ss/management/action-history/show-system-actions';

export class ActionHistorySystemActionsSwitchStore {
	protected state!: State;

	constructor() {
		makeObservable(
			this,
			{
				//@ts-ignore
				state: observable,
				showSystemActions: computed,
				filter: computed,
			},
			{ autoBind: true },
		);

		this.initState();
	}

	get showSystemActions() {
		return this.state.showSystemActions;
	}

	get filter() {
		return this.state.filter;
	}

	toggleShowSystemActions = () => {
		this.state.showSystemActions = !this.state.showSystemActions;
	};

	private initState = () => {
		const sessionState = this.readStateFromSessionStorage();
		if (sessionState) {
			this.state = sessionState;
		} else {
			this.state = INITIAL_STATE;
		}
	};

	private readStateFromSessionStorage = (): State | null => {
		let sessionState = null;

		try {
			sessionState = JSON.parse(sessionStorage.getItem(SHOW_SYSTEM_ACTIONS_SESSION_STORAGE_KEY) ?? 'null');
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Failed to read state from session storage. Error:', error);
		}

		return sessionState;
	};

	private syncState = () => {
		try {
			const { showSystemActions } = this.state;
			const state: State = {
				showSystemActions: Boolean(showSystemActions),
				filter: showSystemActions ? null : SYSTEM_USER_FILTER,
			};
			this.state = state;
			sessionStorage.setItem(SHOW_SYSTEM_ACTIONS_SESSION_STORAGE_KEY, JSON.stringify(state));
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Failed to save state in session storage. Error:', error);
		}
	};

	showSystemActionsDisposer = reaction(
		() => this.state.showSystemActions,
		() => {
			this.syncState();
		},
	);

	dispose = () => {
		this.showSystemActionsDisposer();
	};
}
