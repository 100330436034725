import moment from 'moment';

import { DATE_TIME_FORMAT } from '@/app/_common/constants';

export const formatXAxisDate = (date: string): string => {
	if (moment(date).isValid()) {
		return moment.utc(date).format(DATE_TIME_FORMAT);
	}
	return date;
};
