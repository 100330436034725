import { cloneElement, ReactElement, useMemo, memo, ReactNode } from 'react';
import { GridRowProps } from '@progress/kendo-react-grid';
import { deepEqual } from '@/app/_common/utils';

import { Column } from '@/app/_common/_components';
import { useIsVisible } from '@/app/_common/hooks';

interface QueryResultsTableListRowProps extends GridRowProps {
	trElement: ReactElement<HTMLTableRowElement>;
	selected: boolean;
	expanded: boolean;
	columns: Column[];
	className: string;
	disableVirtualRows?: boolean;
}

export const QueryResultsTableListRow = memo(
	(props: QueryResultsTableListRowProps) => {
		const { trElement, className, disableVirtualRows } = props;
		const { ref: rowRef, isVisible } = useIsVisible({ disabled: disableVirtualRows });

		const currentRow = useMemo(() => {
			// @ts-ignore
			return cloneElement(trElement, { className, ref: rowRef }, isVisible ? (trElement.props.children as ReactNode) : null);
		}, [trElement, className, rowRef, isVisible]);

		return currentRow;
	},
	(prev, next) => deepEqual(prev, next, ['columns', 'selected', 'dataItem', 'expanded']),
);

QueryResultsTableListRow.displayName = 'QueryResultsTableListRow';
