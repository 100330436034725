export const parseMultilineJsonInput = (value?: string | null): string[] => {
	if (!value) {
		return [''];
	}
	try {
		const parsedValue = JSON.parse(value);
		if (Array.isArray(parsedValue)) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return parsedValue.map((item: any) => `${item}`);
		} else {
			return [value];
		}
	} catch (e) {
		return [value];
	}
};
