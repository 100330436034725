import { gql } from '@apollo/client';

export const GetAppConfiguration = gql`
	query GetAppConfiguration($tenantId: ID!) {
		getAppConfiguration(tenantId: $tenantId) {
			... on AppConfiguration {
				featureFlags {
					key
					enabled
				}
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;

export const GetConfiguration = gql`
	query GetConfiguration($configurationType: ConfigurationType!) {
		getConfiguration(configurationType: $configurationType) {
			... on Configuration {
				configuration
				id
			}
			... on Error {
				message
				code
				correlationId
				additionalInformation {
					name
					value
				}
			}
		}
	}
`;
