import { reaction } from 'mobx';
import moment from 'moment';

import { FileApiDTO } from '@/app/_common/constants/alert-details.constants';
import { FetchBaseDataStore } from '@/app/_common/fetch/fetch-base.data-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';

const POST_CURRENT_TENANT_INTERVAL = moment.duration(15, 'minutes').asMilliseconds();

export class ActiveTenantStore extends FetchBaseDataStore<FileApiDTO> {
	private postActiveTenantInterval: number | null = null;
	private authStore = injectInterface({}, AuthStore);

	constructor() {
		super();
	}

	postActiveTenant() {
		const requestUrl = `${process.env.REACT_APP_API_MANAGEMENT_SERVICE}${this.authStore.currentTenantId}/active`;

		const activeTenant = this.fetchApiByAxios('', { method: 'POST', url: requestUrl });
		if (activeTenant) {
			this.setPostActiveTenantInterval();
		}
	}

	setPostActiveTenantInterval() {
		this.clearPostActiveTenantInterval();

		this.postActiveTenantInterval = window.setInterval(this.postActiveTenant.bind(this), POST_CURRENT_TENANT_INTERVAL);
	}

	clearPostActiveTenantInterval() {
		if (this.postActiveTenantInterval) {
			clearInterval(this.postActiveTenantInterval);
		}
	}

	private postActiveTenantDisposer = reaction(
		() => this.authStore.currentTenantId,
		(id: string) => {
			if (id && this.authStore.success) {
				this.postActiveTenant();
			}
		},
	);

	dispose() {
		this.clearPostActiveTenantInterval();
		this.postActiveTenantDisposer();
	}
}
