import { FC } from 'react';

import styles from './colored-bullet.module.scss';

const DEFAULT_SIZE = 12;
const DEFAULT_COLOR = 'var(--textColor)';

interface ColoredBulletProps {
	size?: number;
	color?: string;
}

export const ColoredBullet: FC<ColoredBulletProps> = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }) => {
	const sizeString = `${size}px`;

	return <span className={styles.container} style={{ width: sizeString, height: sizeString, backgroundColor: color }} />;
};
