import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';
import { AlertsDetailsSessionsPropertiesPaths } from '@/app/_common/constants/alert-details-sessions.constants';
import { AssignedAlertPropertiesPaths, ResultsTablePropertiesPaths } from '@/app/_common/constants';

export const detailsViewTypenameToIdKeyMapping: Record<DetailsViewTypename, string> = {
	[DetailsViewTypename.ALERT]: AlertsDetailsSessionsPropertiesPaths.AlertId,
	[DetailsViewTypename.INVESTIGATION_ALERT_SUMMARY]: AssignedAlertPropertiesPaths.Id,
	[DetailsViewTypename.EVENT]: ResultsTablePropertiesPaths.LogId,
	[DetailsViewTypename.EVIDENCE]: ResultsTablePropertiesPaths.EvidenceId,
	[DetailsViewTypename.UNKNOWN]: '',
};
