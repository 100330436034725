import _pickBy from 'lodash/pickBy';

import { SessionItem } from '@/app/_common/constants';
import { Columns } from '@/app/_common/_components/data-grid/types';
import { getColumnsToRemove } from '../get-columns-to-remove';
import { getSessionColumnsForDataGrid } from '../get-session-column-for-data-grid';

export const getColumnsWithoutEmptyValues = (sessions: SessionItem[], importantColumns?: string[]): Columns => {
	const sessionColumns = sessions[0] ? Object.keys(sessions[0]) : [];
	const allColumns = getSessionColumnsForDataGrid(sessionColumns, importantColumns || []);
	const columnsToRemove = getColumnsToRemove(allColumns, sessions);

	return _pickBy(allColumns, (_, key) => !columnsToRemove.includes(key)) as unknown as Columns;
};
