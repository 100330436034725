export enum AlertVendorKey {
	CROWDSTRIKE_FALCON = 'crowdstrike_falcon',
	GOOGLE_WORKSPACE = 'google_workspace',
	MICROSOFT_DEFENDER = 'microsoft_defender',
	CISCO_MERAKI = 'cisco_meraki',
	PALO_ALTO_NGFW = 'palo_alto',
	FORTINET_FORTIGATE = 'fortinet_fortigate',
	OKTA_WORKFORCE = 'okta_workforce',
	SECIOSS_SECIOSSLINK = 'secioss_seciosslink',
}
