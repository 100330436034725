import { SvgIconComponent } from '@mui/icons-material';

import styles from './rounded-info-box.module.scss';

interface RoundedInfoBoxProps {
	text: string;
	Icon: SvgIconComponent;
}

export const RoundedInfoBox = ({ text, Icon }: RoundedInfoBoxProps) => {
	return (
		<div className={styles.container}>
			<Icon />
			<span>{text}</span>
		</div>
	);
};
