import { DetailsViewItemDataPropertyPaths } from '@/app/_common/_components/details-view/types';

const FIELDS_TO_FILTER_OUT = [
	DetailsViewItemDataPropertyPaths.Title,
	DetailsViewItemDataPropertyPaths.Raw,
	DetailsViewItemDataPropertyPaths.AlertRaw,
	DetailsViewItemDataPropertyPaths.Id,
];

export const isDetailsViewItemIgnoreField = (key: DetailsViewItemDataPropertyPaths) => {
	return FIELDS_TO_FILTER_OUT.includes(key);
};
