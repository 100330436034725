import { FC } from 'react';
import classNames from 'classnames';

import styles from './color-indicator.module.scss';

interface SingleBulletProps {
	color: string | undefined;
}

export const SingleBullet: FC<SingleBulletProps> = ({ color }) => {
	const isEmpty = !color;

	if (isEmpty) {
		return <div className={classNames(styles.singleBullet, styles.empty)}></div>;
	}

	return <div className={styles.singleBullet} style={{ background: color }}></div>;
};
