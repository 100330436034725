import { action, computed, makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _set from 'lodash/set';
import _orderBy from 'lodash/orderBy';
import _uniq from 'lodash/uniq';
import _countBy from 'lodash/countBy';
import _pull from 'lodash/pull';

import { filterBy, FilterDescriptor, orderBy, SortDescriptor } from '@progress/kendo-data-query';

import {
	CompositeFilterDescriptorWithId,
	DataGridDataItem,
	DataGridDisabled,
	DataGridPage,
	DataGridSelected,
	ExpandField,
	FilterDescriptorWithId,
	Filters,
} from '@/app/_common/types';
import { FilterGroupId, FilterLogic, FilterOperators, SortDirection, INITIAL_PAGE, RootFilterId } from '@/app/_common/constants';
import { extendFilterDescriptor } from '@/app/_common/utils';

import { Columns, DataGridViewStoreState, SortFunction } from './types';
import { HeaderCheckboxMode } from './constants';

const MIN_COLUMNS = 2;
const EMPTY_FIELD_VALUE = 'empty';

interface FilterOptions {
	id: string;
	filters: FilterDescriptorWithId[];
	logic: FilterLogic;
	nested?: boolean;
	nestedId?: string;
	nestedLogic?: FilterLogic;
}

const INITIAL_GRID_FILTER: CompositeFilterDescriptorWithId = {
	id: RootFilterId,
	logic: FilterLogic.And,
	filters: [],
};

const INITIAL_GRID_STATE: DataGridViewStoreState = {
	expandedRows: {},
	selectedRows: {},
	sort: [],
	expandedId: '',
	expandedIds: [],
	filter: INITIAL_GRID_FILTER,
	isSelectedRowsFilterActive: false,
	columns: {},
	page: INITIAL_PAGE,
	queryFilters: {},
	disabledRows: {},
};

export class DataGridViewStore<T> {
	private sortFunction: SortFunction<T> = orderBy;
	private initialColumns: Columns = {};
	private idPath = '';
	private apiFiltering = false;
	private expanded = false;
	private unsortableRow = '';
	protected gridState: DataGridViewStoreState = INITIAL_GRID_STATE;
	public sourceData: T[] | undefined = [];

	constructor(
		idPath: string,
		initialColumns: Columns,
		initialSort?: SortDescriptor[],
		sortFunction?: SortFunction<T>,
		apiFiltering?: boolean,
		version?: string,
		expanded?: boolean,
		unsortableRow?: string,
		pageSize?: number,
	) {
		makeObservable(this, {
			// @ts-ignore - for protected/private fields
			gridState: observable,
			// @ts-ignore - for protected/private fields
			sourceData: observable,
			sort: computed,
			filter: computed,
			isSelectedRowsFilterActive: computed,
			areFiltersActive: computed,
			data: computed,
			rawData: computed,
			selectedCount: computed,
			disabledRows: computed,
			columns: computed,
			hideColumnsDisabled: computed,
			expandedId: computed,
			expandedIds: computed,
			filtersLength: computed,
			selectRows: action,
			clearSelection: action,
			selectAllRows: action,
			toggleSelectAllRows: action,
			disableRows: action,
			setSort: action,
			setFilter: action,
			setNestedFilter: action,
			pushFilter: action,
			assignFilters: action,
			removeFilter: action,
			resetFilter: action,
			toggleColumn: action,
			setColumns: action,
			resetColumns: action,
			resetAllFilters: action,
			resetGridState: action,
			setExpandedId: action,
			clearExpandedId: action,
			toggleExpandedIds: action,
			setPage: action,
			resetPage: action,
			toggleIsSelectedRowsFilterActive: action,
			toggleSelectedRow: action,
		});

		if (sortFunction) {
			this.sortFunction = sortFunction;
		}

		if (idPath) {
			this.idPath = idPath;
		}

		if (initialSort) {
			this.setSort(initialSort);
		}

		if (initialColumns) {
			this.initialColumns = initialColumns;
			this.setColumns(initialColumns);
		}

		if (apiFiltering) {
			this.apiFiltering = apiFiltering;
		}

		if (version) {
			this.gridState.version = version;
		}

		if (expanded) {
			this.expanded = expanded;
		}

		if (unsortableRow) {
			this.unsortableRow = unsortableRow;
			this.gridState.sort = [];
		}

		if (pageSize) {
			this.gridState.page.take = pageSize;
		}
	}

	get getIdPath() {
		return this.idPath;
	}

	get rawData() {
		const expandedIds = [this.gridState.expandedId, ...this.gridState.expandedIds];

		return (
			this.sourceData?.map((item: T) => {
				const id = _get(item, this.idPath);
				const clonedItem = { ...item } as DataGridDataItem<T>;

				_set(clonedItem, 'disabled', this.disabledRows[id]);

				if (this.expanded) {
					const itemId = _get(item, this.idPath);
					const expanded = expandedIds.includes(itemId);

					_set(clonedItem, ExpandField.Expanded, expanded);
				}

				return clonedItem;
			}) || []
		);
	}

	get data() {
		if (this.apiFiltering) {
			return this.rawData;
		}

		const filteredSelectedData: DataGridDataItem<T>[] = this.filterSelectedData(this.rawData, this.isSelectedRowsFilterActive);
		const filterDescriptor = extendFilterDescriptor(this.gridState.filter);
		const filteredData = filterBy(filteredSelectedData, filterDescriptor);

		if (this.unsortableRow) {
			const FIELD_NAME = 'node.name';
			const unsortableObject = filteredData.find((object) => _get(object, FIELD_NAME) === this.unsortableRow);
			const sortedArray = this.sortFunction(filteredData, this.gridState.sort).filter((object) => _get(object, FIELD_NAME) !== this.unsortableRow);
			// @ts-ignore
			sortedArray.unshift(unsortableObject);
			return sortedArray;
		}

		return this.sortFunction(filteredData, this.gridState.sort) || [];
	}

	get selectedRows() {
		return this.gridState.selectedRows;
	}

	get isSelectedRowsFilterActive() {
		return this.gridState.isSelectedRowsFilterActive;
	}

	get disabledRows() {
		return this.gridState.disabledRows;
	}

	get selectedElements() {
		return this.rawData?.filter((item: DataGridDataItem<T>) => {
			const id = _get(item, this.idPath);
			return this.isRowSelected(id);
		});
	}

	get selectedElementsIds() {
		return this.selectedElements.map((item) => _get(item, this.idPath));
	}

	get selectedCount() {
		return this.data.filter((item) => {
			const id = _get(item, this.idPath);

			return this.selectedRows[id];
		}).length;
	}

	get notDisabledVisibleElements() {
		return this.data?.filter(({ disabled }) => !disabled);
	}

	get notDisabledVisibleElementsIds() {
		return this.notDisabledVisibleElements.map((item) => _get(item, this.idPath));
	}

	get selectionHeaderMode(): HeaderCheckboxMode {
		const selectedCount = this.selectedCount;
		const visibleCount = this.notDisabledVisibleElementsIds.length;
		if (selectedCount === 0) {
			return HeaderCheckboxMode.Unchecked;
		}
		if (selectedCount === visibleCount) {
			return HeaderCheckboxMode.Checked;
		}
		return HeaderCheckboxMode.Indeterminated;
	}

	get sort() {
		return this.gridState.sort;
	}

	get filter() {
		return this.gridState.filter;
	}

	get areFiltersActive(): boolean {
		return Boolean(this.filter?.filters?.length) || this.isSelectedRowsFilterActive;
	}

	get totalCount() {
		return this.rawData?.length || 0;
	}

	get columns() {
		return this.gridState.columns || {};
	}

	get hideColumnsDisabled() {
		return Object.values(this.columns).filter((value) => value).length === MIN_COLUMNS;
	}

	get expandedId(): string {
		return this.gridState.expandedId;
	}

	get expandedIds(): string[] {
		return this.gridState.expandedIds;
	}

	get filtersLength() {
		return this.gridState.filter?.filters?.length || 0;
	}

	get pageSkip(): DataGridPage['skip'] {
		return this.gridState.page.skip;
	}

	get pageSize(): DataGridPage['take'] {
		return this.gridState.page.take;
	}

	get page(): DataGridPage {
		return this.gridState.page;
	}

	get expandedRows(): Record<string, boolean> {
		return this.gridState.expandedRows;
	}

	filterSelectedData = (data: DataGridDataItem<T>[], isSelectedRowsFilterEnabled: boolean): DataGridDataItem<T>[] => {
		if (!isSelectedRowsFilterEnabled) {
			return data;
		}

		return data.filter((item: DataGridDataItem<T>) => {
			const id = _get(item, this.idPath);
			return this.selectedRows[id];
		});
	};

	isRowSelected = (id: string): boolean => {
		return Boolean(this.gridState.selectedRows[id]);
	};

	toggleSelectedRow = (id: string): void => {
		/**
		 * 'this.gridState.selectedRows[id] = !this.gridState.selectedRows[id];' would prevent some dialogs from tracking state change (e.g. Assign Alerts to Investigation dialog),
		 * thus creating new object and setting selectedRows with this.selectRows.
		 */
		const updatedRows = {
			...this.gridState.selectedRows,
			[id]: !this.gridState.selectedRows[id],
		};
		this.selectRows(updatedRows);
	};

	toggleIsSelectedRowsFilterActive = () => {
		this.gridState.isSelectedRowsFilterActive = !this.gridState.isSelectedRowsFilterActive;
	};

	setPage = (page: DataGridPage): void => {
		this.gridState.page = page;
	};

	resetPage = (): void => {
		this.gridState.page = INITIAL_PAGE;
	};

	getAllValues = computedFn((field: string) => {
		const values = this.rawData.reduce((result: string[], item) => {
			const value = _get(item, field);

			if (Array.isArray(value)) {
				result.push(...value);
			} else if (!_isNil(value)) {
				result.push(value);
			}

			return result;
		}, []);

		return _orderBy(values, [(value) => value.toString().toLowerCase(), [SortDirection.Asc]]);
	});

	getValues = computedFn((field: string) => {
		const values = this.data.reduce((result: string[], item) => {
			const value = _get(item, field);
			if (value === undefined) {
				return result;
			}
			if (Array.isArray(value)) {
				result.push(...value);
			} else if (_isNil(value) || value === '') {
				result.push(EMPTY_FIELD_VALUE);
			} else if (!_isNil(value)) {
				result.push(value);
			}

			return result;
		}, []);

		return _orderBy(values, [(value) => value.toString().toLowerCase(), [SortDirection.Asc]]);
	});

	getUniqValues = computedFn((field: string) => {
		return _uniq(this.getAllValues(field));
	});

	getCounter = computedFn((field: string) => {
		return _uniq(this.getValues(field)).length;
	});

	getCountedValues = computedFn((field: string) => {
		const countedBy = this.getValues(field);
		return _countBy(countedBy);
	});

	getFieldColumnState = computedFn((field: string) => {
		return this.gridState.columns[field];
	});

	getFilter = (id: string, field?: string): CompositeFilterDescriptorWithId | undefined => {
		const filterGroup = this.gridState.filter.filters.find(this.findFilter(id)) as CompositeFilterDescriptorWithId;

		if (!filterGroup) {
			return;
		}

		if (!field) {
			return filterGroup;
		}

		return filterGroup.filters.find(this.findFilter(field)) as CompositeFilterDescriptorWithId;
	};

	selectRows = (selectedRows: DataGridSelected) => {
		this.gridState.selectedRows = selectedRows;
	};

	clearSelection = () => {
		this.selectRows({});
	};

	selectAllRows = () => {
		this.selectRows(Object.fromEntries(this.notDisabledVisibleElementsIds.map((id) => [id, true])));
	};

	toggleSelectAllRows = () => {
		if (this.selectedCount > 0) {
			this.clearSelection();
		} else {
			this.selectAllRows();
		}
	};

	disableRows = (disabledRows: DataGridDisabled) => {
		this.gridState.disabledRows = disabledRows;
	};

	setSort = (sort: SortDescriptor[]) => {
		this.gridState.sort = sort;
	};

	setFilter = ({ id, filters, logic, nested = true, nestedId, nestedLogic }: FilterOptions) => {
		let groupIndex = this.gridState.filter.filters.findIndex(this.findFilter(id));

		if (groupIndex === -1) {
			this.pushFilter({
				id,
				logic,
				filters: [],
			});

			groupIndex = this.gridState.filter.filters.length - 1;
		}

		if (nested && nestedId) {
			this.setNestedFilter(nestedId, filters, groupIndex, nestedLogic);
		} else {
			if (filters && filters.length > 0) {
				this.assignFilters(filters, groupIndex);
			} else {
				this.removeFilter(groupIndex);
			}
		}
	};

	resetFilter = (id: string, field: string) => {
		const filterGroup = this.gridState.filter.filters.find(this.findFilter(id)) as CompositeFilterDescriptorWithId;

		if (filterGroup) {
			const index = filterGroup.filters.findIndex(this.findFilter(field));

			if (index > -1) {
				filterGroup.filters.splice(index, 1);
			}

			if (filterGroup.filters.length === 0) {
				this.gridState.filter.filters.splice(index, 1);
			}
		}
	};

	resetAllFilters = () => {
		this.gridState.filter = INITIAL_GRID_FILTER;
		this.gridState.isSelectedRowsFilterActive = false;
	};

	resetGridState = () => {
		this.gridState = INITIAL_GRID_STATE;
	};

	toggleColumn = (field: string) => {
		this.gridState.columns = {
			...this.gridState.columns,
			[field]: !this.gridState.columns[field],
		};
	};

	resetColumns = () => {
		this.gridState.columns = this.initialColumns;
	};

	setNestedFilter = (id: string, filters: FilterDescriptorWithId[], index: number, logic?: FilterLogic) => {
		const filterGroup = this.gridState.filter.filters[index] as CompositeFilterDescriptorWithId;
		let fieldGroupIndex = filterGroup.filters.findIndex(this.findFilter(id));

		if (fieldGroupIndex === -1 && logic) {
			this.pushFilter(
				{
					id,
					logic,
					filters: [],
				},
				index,
			);

			const compositeFilterDescriptor = this.gridState.filter.filters[index];

			if ('filters' in compositeFilterDescriptor) {
				fieldGroupIndex = compositeFilterDescriptor.filters.length - 1;
			}
		}

		if (filters && filters.length > 0) {
			this.assignFilters(filters, index, fieldGroupIndex);
		} else {
			this.removeFilter(index, fieldGroupIndex);

			const compositeFilterDescriptor = this.gridState.filter.filters[index];

			if ('filters' in compositeFilterDescriptor && compositeFilterDescriptor.filters.length === 0) {
				this.removeFilter(index);
			}
		}
	};

	pushFilter = (compositeFilterDescriptor: CompositeFilterDescriptorWithId, index?: number) => {
		if (_isNil(index)) {
			this.gridState.filter.filters.push(compositeFilterDescriptor);
		} else {
			const nestedCompositeFilterDescriptor = this.gridState.filter.filters[index];

			if ('filters' in nestedCompositeFilterDescriptor) {
				nestedCompositeFilterDescriptor.filters.push(compositeFilterDescriptor);
			}
		}
	};

	assignFilters = (filters: Array<CompositeFilterDescriptorWithId | FilterDescriptorWithId>, index: number, nestedIndex?: number) => {
		if ('filters' in this.gridState.filter) {
			const compositeFilterDescriptor = this.gridState.filter.filters[index];

			if ('filters' in compositeFilterDescriptor) {
				if (!_isNil(nestedIndex)) {
					const nestedCompositeFilterDescriptor = compositeFilterDescriptor.filters[nestedIndex];

					if ('filters' in nestedCompositeFilterDescriptor) {
						nestedCompositeFilterDescriptor.filters = filters;
					}
				} else {
					compositeFilterDescriptor.filters = filters;
				}
			}
		}
	};

	removeFilter = (index: number, nestedIndex?: number) => {
		if ('filters' in this.gridState.filter) {
			if (!_isNil(nestedIndex)) {
				const compositeFilterDescriptor = this.gridState.filter.filters[index];

				if ('filters' in compositeFilterDescriptor) {
					compositeFilterDescriptor.filters.splice(nestedIndex, 1);
				}
			} else {
				this.gridState.filter.filters.splice(index, 1);
			}
		}
	};

	setColumns = (columns: Columns) => {
		this.gridState.columns = columns;
	};

	setExpandedId = (itemId: string) => {
		this.gridState.expandedId = itemId;
	};

	clearExpandedId = () => {
		this.setExpandedId('');
	};

	toggleExpandedIds = (itemId: string) => {
		if (this.gridState.expandedIds.includes(itemId)) {
			_pull(this.gridState.expandedIds, itemId);
		} else {
			this.gridState.expandedIds.push(itemId);
		}
	};

	protected findFilter =
		(id: string, operator?: FilterDescriptor['operator'] | FilterOperators) => (filter: CompositeFilterDescriptorWithId | FilterDescriptorWithId) => {
			if ('id' in filter) {
				return filter.id === id;
			} else if ('field' in filter && 'operator' in filter && operator) {
				return filter.field === id && filter.operator === operator;
			}

			return false;
		};

	getGridHeaderFiltersByField = computedFn((filters: (CompositeFilterDescriptorWithId | FilterDescriptorWithId)[], field: string) => {
		const filter = this.getFilter(FilterGroupId.gridHeader, field);

		if (!filter?.filters) {
			return [];
		}

		return filter.filters.filter((filterDescriptor): filterDescriptor is FilterDescriptorWithId => 'value' in filterDescriptor);
	});

	getGridHeaderFilterValues = computedFn((field: string) => {
		const filter = this.getFilter(FilterGroupId.gridHeader, field);

		if (!filter?.filters) {
			return [];
		}

		return filter.filters.reduce<string[]>((result, filterDescriptor) => {
			if ('value' in filterDescriptor) {
				result.push(filterDescriptor.value);
			}
			return result;
		}, []);
	});

	setGridHeaderFilter = (field: string, values: Filters) => {
		const filters = values.map((value) => ({
			value,
			field,
			operator: FilterOperators.Eq,
			ignoreCase: false,
		}));

		this.setFilter({
			id: FilterGroupId.gridHeader,
			filters,
			logic: FilterLogic.And,
			nestedId: field,
			nestedLogic: FilterLogic.Or,
		});
	};

	resetGridHeaderFilter = (field: string) => {
		this.resetFilter(FilterGroupId.gridHeader, field);
	};
}
