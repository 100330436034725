import { makeAutoObservable } from 'mobx';
import _uniqueId from 'lodash/uniqueId';
import { KeyValue } from '@progress/kendo-react-form';

import { AlertEdge } from '@/generated/graphql';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { CLOSE_NOTIFICATION_TIMEOUT, NotificationsStore } from '@/app/_common/stores/notifications.store';
import { AlertsAssignToInvestigationDataStore } from '@/app/dashboards/alerts-dashboard/_common/stores/alerts-assign-to-investigation.data-store';
import { AlertsCreateInvestigationDataStore } from '@/app/dashboards/alerts-dashboard/_common/stores/alerts-create-investigation.data-store';
import { AssignToInvestigationFormData, CreateInvestigationFormData } from '@/app/dashboards/alerts-dashboard/_common/types/types';
import { DataGridDataItem } from '@/app/_common/types/types';
import { AlertsAssignListViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/alerts-assign-list.view-store';
import { InvestigationTypename } from '@/app/_common/constants/graphql.constants';
import { getGraphQLError } from '@/app/_common/graphql/graphql-error-handler';
import { Notification, NotificationLook } from '@/app/_common/types';
import { AssignToInvestigationTabs } from '@/app/_common/constants';
import { InvestigationSelectDataStore } from '@/app/_common/_components/forms/assign-to-investigation-form/_common/stores';

interface State {
	alerts: DataGridDataItem<AlertEdge>[];
	activeTab: AssignToInvestigationTabs;
	open: boolean;
	loading: boolean;
	errors: Notification[];

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createInvestigationFormValues: KeyValue<any> | undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	assignToInvestigationFormValues: KeyValue<any> | undefined;
}

const initialState: State = {
	alerts: [],
	activeTab: AssignToInvestigationTabs.createInvestigation,
	open: false,
	createInvestigationFormValues: undefined,
	assignToInvestigationFormValues: undefined,
	loading: false,
	errors: [],
};

export class AlertsAssignDialogViewStore {
	private alertsInvestigationDataStore = injectInterface(this, InvestigationSelectDataStore);
	private alertsAssignToInvestigationDataStore = injectInterface(this, AlertsAssignToInvestigationDataStore);
	private alertsCreateInvestigationDataStore = injectInterface(this, AlertsCreateInvestigationDataStore);
	private notificationsStore = injectInterface(this, NotificationsStore);
	private alertsAssignListViewStore = injectInterface(this, AlertsAssignListViewStore);

	private state: State = initialState;

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	assignToInvestigationFormState = {
		investigationId: undefined as undefined | string,
	};

	get isOpen() {
		return this.state.open;
	}

	get loading(): boolean {
		// currently we leave that solution as there two data stores
		return this.state.loading;
	}

	get activeTab() {
		return this.state.activeTab;
	}

	get createInvestigationFormValues() {
		return this.state.createInvestigationFormValues;
	}

	get assignToInvestigationFormValues() {
		return this.state.assignToInvestigationFormValues;
	}

	setActiveTab = (tab: AssignToInvestigationTabs) => {
		this.state.activeTab = tab;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setAssignToInvestigationFormValues = (values: KeyValue<any> | undefined) => {
		this.state.assignToInvestigationFormValues = values;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setCreateInvestigationFormValues = (values: KeyValue<any> | undefined) => {
		this.state.createInvestigationFormValues = values;
	};

	setLoading = (loading: boolean) => {
		this.state.loading = loading;
	};

	open = (alertsIds: string[]) => {
		if (alertsIds?.length > 0) {
			this.alertsAssignListViewStore.setInitialAlertIds(alertsIds);
			this.alertsInvestigationDataStore.read();
			this.state.open = true;
		}
	};

	close = () => {
		this.state = initialState;
	};

	get errors(): Notification[] {
		return this.state.errors;
	}

	setError = (error: Notification) => {
		this.state.errors = [...this.state.errors, { onClose: () => this.clearError(error.id), ...error }];

		setTimeout(() => {
			this.clearError(error.id);
		}, CLOSE_NOTIFICATION_TIMEOUT);
	};

	clearError = (id: string) => {
		this.state.errors = this.state.errors.filter((error) => error.id !== id);
	};

	submitCreateInvestigationForm = async (data: CreateInvestigationFormData) => {
		this.setLoading(true);
		const response = await this.alertsCreateInvestigationDataStore.create(data, this.alertsAssignListViewStore.alertIds);
		this.setLoading(false);
		const responseData = response?.data?.createInvestigation;

		if (responseData?.__typename === InvestigationTypename.Investigation) {
			this.notificationsStore.openSuccess({
				title: i18n.t('createInvestigation.success.title', { ns: Namespaces.Notifications, name: responseData?.name }),
				link: {
					text: i18n.t('createInvestigation.success.content', { ns: Namespaces.Notifications, id: responseData?.id }),
					url: `/investigations/${responseData.id}`,
				},
			});

			this.close();
		} else if (responseData?.__typename === InvestigationTypename.Error) {
			const error = getGraphQLError(i18n.t('createInvestigation.error.assignError.title', { ns: Namespaces.Notifications }), responseData);

			this.setError({
				...error,
				look: NotificationLook.Outlined,
				id: _uniqueId(),
				type: { style: 'error', icon: true },
				closable: true,
			});
		}
	};

	submitAssignToInvestigationForm = async (data: AssignToInvestigationFormData) => {
		this.setLoading(true);
		const response = await this.alertsAssignToInvestigationDataStore.assign(data, this.alertsAssignListViewStore.alertIds);
		this.setLoading(false);
		const responseData = response?.data?.setAlertsOnInvestigation;

		if (responseData?.__typename === InvestigationTypename.Investigation) {
			this.notificationsStore.openSuccess({
				title: i18n.t('setAlertsOnInvestigation.success.title', { ns: Namespaces.Notifications, name: responseData?.name }),
				link: {
					text: i18n.t('setAlertsOnInvestigation.success.content', { ns: Namespaces.Notifications, id: responseData.id }),
					url: `/investigations/${responseData.id}`,
				},
			});

			this.close();
		} else if (responseData?.__typename === InvestigationTypename.Error) {
			const error = getGraphQLError(i18n.t('setAlertsOnInvestigation.error.title', { ns: Namespaces.Notifications }), responseData);

			this.setError({
				...error,
				look: NotificationLook.Outlined,
				id: _uniqueId(),
				type: { style: 'error', icon: true },
			});
		}
	};
}
