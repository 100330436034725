import { routeIDRegex } from '@/app/_common/constants';
import { QueryIDs } from '@/app/_common/_components/breadcrumbs/breadcrumbs.data-store';
import { getIDsIndexes } from './get-ids-indexes';

export const generateQueryIDs = (path: string, locationPathname = ''): QueryIDs => {
	const locationUrlSegments = locationPathname.split('/');
	const routeUrlSegments = path.split('/');
	const indexesOfIDs = getIDsIndexes(routeUrlSegments, routeIDRegex);
	const queryIDs = {};
	for (const id of indexesOfIDs) {
		const idParameterName = routeUrlSegments[id].substring(1);
		//@ts-ignore
		queryIDs[`${idParameterName}`] = locationUrlSegments[id] ?? '';
	}
	return queryIDs;
};
