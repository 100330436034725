import { HighlightedText } from '@/app/_common/_components/data-grid/_components';
import { DefaultDropdownItemContainer } from '@/app/_common/_components/data-grid/_components/multi-value-cell/components/default-dropdown-item-container';
import { DropdownItemComponentProps } from '../types';

export const DefaultDropdownItem = ({ value, searchValue, isCellHighlighted, isContextMenuOpen }: DropdownItemComponentProps) => {
	return (
		<DefaultDropdownItemContainer value={value} isCellHighlighted={isCellHighlighted} isContextMenuOpen={isContextMenuOpen}>
			{isCellHighlighted ? <HighlightedText value={value} searchLine={searchValue} /> : <span>{value}</span>}
		</DefaultDropdownItemContainer>
	);
};
