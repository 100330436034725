import { AlertSpecialFieldNames } from '@/app/_common/types';
import { sortIndicatorsFilters } from '@/app/_common/utils';
import { sortConfidenceFilter, sortSeverityFilter, sortMitreTacticsFilter } from '@/app/_features/alerts/_common/utils';

export const getSortFilterOptions = (field: string) => {
	switch (field) {
		case AlertSpecialFieldNames.Confidence:
			return sortConfidenceFilter;
		case AlertSpecialFieldNames.Severity:
			return sortSeverityFilter;
		case AlertSpecialFieldNames.MitreTactics:
			return sortMitreTacticsFilter;
		default:
			return sortIndicatorsFilters;
	}
};
