import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { AvailableFieldsViewStore } from '@/app/advanced-query/_common/stores';
import { FieldFilterChip } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/field-filters-chip';
import { FieldFilterGroup } from '@/app/_common/types/adx';

import styles from './field-filters.module.scss';

export const FieldFilters = observer(() => {
	const store = useInstance(AvailableFieldsViewStore);

	const handleRemoveClick = (filter: FieldFilterGroup) => {
		store.removeFilter(filter);
	};

	return (
		<ul className={classNames(styles.filters, { [styles.empty]: store.filters.length === 0 })} data-testid="field-filters">
			{store.filters.map((filter) => {
				const key = `${filter.field}-${filter.values.join('-')}`;
				return <FieldFilterChip key={key} field={filter.field} values={filter.values} operator={filter.operator} onRemove={handleRemoveClick} />;
			})}
		</ul>
	);
});
