import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Namespaces } from '@/translations/namespaces';
import { Close, ChevronRight, ChevronLeft } from '@mui/icons-material';
import { Button } from '@/app/_common/_components';

import { WizardButtonsStepState, WizardButtonsHandlers } from '@/app/_common/_components/wizard/_common';

import styles from './wizard-buttons.module.scss';

interface WizardButtonsProps extends WizardButtonsHandlers, WizardButtonsStepState {}

export const WizardButtons: FC<WizardButtonsProps> = ({
	showCancel,
	showNext,
	showBack,
	showFinish,
	showSave,
	enableBack,
	enableNext,
	enableFinish,
	enableCancel,
	enableSave,
	onFinishClick,
	onNextClick,
	onCancelClick,
	onBackClick,
	onSaveClick,
}) => {
	const { t } = useTranslation([Namespaces.Wizard]);

	return (
		<div className={styles.wizardButtons}>
			{showCancel && (
				<Button className={styles.wizardButton} fillMode={'outline'} onClick={onCancelClick} disabled={!enableCancel}>
					<Close /> {t('buttons.cancel')}
				</Button>
			)}
			{showBack && (
				<Button className={styles.wizardButton} fillMode={'outline'} onClick={onBackClick} disabled={!enableBack}>
					<ChevronLeft /> {t('buttons.back')}
				</Button>
			)}
			{showNext && (
				<Button className={styles.wizardButton} onClick={onNextClick} disabled={!enableNext}>
					{t('buttons.next')} <ChevronRight />
				</Button>
			)}
			{showFinish && (
				<Button className={styles.wizardButton} onClick={onFinishClick} disabled={!enableFinish}>
					{t('buttons.finish')}
				</Button>
			)}
			{showSave && (
				<Button className={styles.wizardButton} onClick={onSaveClick} disabled={!enableSave}>
					{t('buttons.save')}
				</Button>
			)}
		</div>
	);
};
