import { observer } from 'mobx-react-lite';

import { DateTimeCell } from '@/app/_common/_components/data-grid/_components';
import { QueryResultsTableCellWithStoreProps } from '@/app/_common/_components/query-results/query-results-table/types';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';

export const QueryResultsTableDateTimeCell = observer((props: QueryResultsTableCellWithStoreProps) => {
	const { searchValue } = useQueryResultsTableStore();

	return <DateTimeCell {...props} searchValue={searchValue} oldContextMenu={false} />;
});

QueryResultsTableDateTimeCell.displayName = 'QueryResultsTableDateTimeCell';
