import { observer } from 'mobx-react-lite';

import { ALERT_ACTIONS, ResultsDataItem } from '@/app/_common/types';
import { CommonContextMenuProps } from '@/app/_common/_components/new-context-menu';
import { QueryResultsTableContextMenuContent } from '@/app/_common/_components/query-results/query-results-table/components';

const HIDDEN_ACTIONS = [
	ALERT_ACTIONS.ViewDetails,
	ALERT_ACTIONS.AssignOrCreateInvestigation,
	ALERT_ACTIONS.OpenInvestigation,
	ALERT_ACTIONS.Unassign,
	ALERT_ACTIONS.Dismiss,
	ALERT_ACTIONS.Undismiss,
];

interface ResultsContextMenuContextProps extends CommonContextMenuProps<ResultsDataItem> {
	onResetGridPage?: () => void;
}

export const AssignQueryResultsToInvestigationContextMenuContent = observer((props: ResultsContextMenuContextProps) => {
	return <QueryResultsTableContextMenuContent {...props} hiddenSingleActions={HIDDEN_ACTIONS} hiddenBulkActions={HIDDEN_ACTIONS} />;
});
