export const filterByIsSomeInOperator = (values: string[], filterValue: string, ignoreCase: boolean): boolean => {
	if (!Array.isArray(values)) {
		return false;
	}

	return values.some((item) => {
		if (ignoreCase) {
			return item.toLowerCase() === filterValue.toLowerCase();
		}

		return item === filterValue;
	});
};
