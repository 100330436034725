import { FC } from 'react';

import _isNil from 'lodash/isNil';

import { OptionItemDetails } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/types';

import styles from './option-group-item-counter.module.scss';

interface OptionGroupItemCounterProps {
	value: OptionItemDetails['counter'];
}

export const OptionGroupItemCounter: FC<OptionGroupItemCounterProps> = ({ value }) => {
	if (_isNil(value) || value === 0) {
		return null;
	}

	return <div className={styles.optionGroupItemCounter}>({value})</div>;
};
