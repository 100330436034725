import { useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { getter } from '@progress/kendo-data-query';

import { ResultsTablePropertiesPaths } from '@/app/_common/constants';
import { ExpandedCell } from '@/app/_common/_components/data-grid/_components';
import { QueryResultsTableCellWithStoreProps } from '@/app/_common/_components/query-results/query-results-table/types';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';

const idGetter = getter(ResultsTablePropertiesPaths.Id);

export const QueryResultsTableExpandedCell = observer((props: QueryResultsTableCellWithStoreProps) => {
	const { toggleExpandedRow } = useQueryResultsTableStore();

	const expandChange = useCallback(
		(dataItem: unknown) => {
			toggleExpandedRow(idGetter(dataItem));
		},
		[toggleExpandedRow],
	);

	return <ExpandedCell onClick={expandChange} {...props} />;
});

QueryResultsTableExpandedCell.displayName = 'QueryResultsTableExpandedCell';
