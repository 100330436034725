import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';

import { SortDirection } from '@/app/_common/constants';
import { DataGridViewStore } from '@/app/_common/_components/data-grid/data-grid.view-store';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { SessionItem } from '@/app/_common/constants/alert-details.constants';
import { ACTIONS_COLUMN_NAME } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_common/constants';
import { AuthStore } from '@/app/_common/stores';
import { AlertsDetailsSessionsPropertiesPaths } from '@/app/_common/constants/alert-details-sessions.constants';

const INITIAL_COLUMNS = { [ACTIONS_COLUMN_NAME]: true };

const INITIAL_SORT = [{ field: AlertsDetailsSessionsPropertiesPaths.DateTime, dir: SortDirection.Desc }];

interface State {
	currentThreatIntelligenceIconIndex: number | null;
	renderOnlyImportantColumns: boolean;
}
const INITIAL_STATE: State = {
	currentThreatIntelligenceIconIndex: null,
	renderOnlyImportantColumns: true,
};

export class SingleAlertContentViewStore extends DataGridViewStore<SessionItem> {
	private state = INITIAL_STATE;
	private authStore = injectInterface(this, AuthStore);

	constructor() {
		super(AlertsDetailsSessionsPropertiesPaths.DateTime, INITIAL_COLUMNS, INITIAL_SORT);

		makeObservable(this, {
			//@ts-ignore
			state: observable,
			currentThreatIntelligenceIconIndex: computed,
			renderOnlyImportantColumns: computed,
			setRenderOnlyImportantColumns: action,
			setCurrentThreatIntelligenceIconIndex: action,
			dispose: action,
			setSourceData: action,
		});
	}

	get currentThreatIntelligenceIconIndex() {
		return this.state.currentThreatIntelligenceIconIndex;
	}

	get renderOnlyImportantColumns() {
		return this.state.renderOnlyImportantColumns;
	}

	setCurrentThreatIntelligenceIconIndex = (currentMatch: number | null) => {
		this.state.currentThreatIntelligenceIconIndex = currentMatch;
	};

	setRenderOnlyImportantColumns = (renderOnlyImportantColumns: boolean) => {
		this.state.renderOnlyImportantColumns = renderOnlyImportantColumns;
	};

	getFilterOptions = runInAction(() => {
		return (field: string) => {
			const counters = this.getCountedValues(field);

			return this.getUniqValues(field)
				.map((value) => ({
					value: value as string,
					label: value as string,
					counter: counters[value as string],
				}))
				.filter(({ value }) => value);
		};
	});

	getTenantId = (): string => {
		return this.authStore.currentTenantId;
	};

	setDataInSessionStorage = (storageName: string) => {
		makePersistable(this.gridState, {
			name: `${storageName}/columns`,
			properties: ['columns'],
			storage: window.sessionStorage,
		});

		makePersistable(this.gridState, {
			name: storageName,
			properties: ['sort', 'filter'],
			storage: window.sessionStorage,
		});
	};

	setSourceData = (sessions: SessionItem[]) => {
		this.sourceData = sessions;
	};

	dispose = () => {
		stopPersisting(this.gridState);
	};
}
