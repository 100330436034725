import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import _get from 'lodash/get';

import { Alert, Investigation } from '@/generated/graphql';
import { ALERT_ACTIONS, AlertActionProps, AlertWithInvestigationSummaryExtended, Filters } from '@/app/_common/types';
import { extendAlertsWithInvestigationDetails } from '@/app/_common/utils';
import { Namespaces } from '@/translations/namespaces';
import { ACTIONS_COLUMN_NAME, AlertPropertiesPaths } from '@/app/_common/constants';
import { ContextMenuSections } from '@/app/_common/_components/new-context-menu/_components';
import { CopyValueAction, FiltersAction } from '@/app/_common/_components/data-grid/_components/alerts-widget-context-menu/_components';
import { CommonContextMenuProps } from '@/app/_common/_components/new-context-menu/_common/types';
import { useDynamicMenu } from '@/app/_common/_components/new-context-menu/_common/hooks/use-dynamic-menu';
import { useAlertsBulkActions, useAlertsSingleActions } from '@/app/_features/alerts-actions-menu/_common/hooks';

import styles from './alerts-context-menu-content.module.scss';

const FIELDS_WITH_HIDDEN_COPY_ACTION: string[] = [AlertPropertiesPaths.Selected, ACTIONS_COLUMN_NAME];
const FIELDS_WITH_HIDDEN_FILTER_ACTION: string[] = [AlertPropertiesPaths.Selected, AlertPropertiesPaths.Timestamp, ACTIONS_COLUMN_NAME];

interface AlertActions {
	assignAlerts: (alerts: AlertWithInvestigationSummaryExtended[]) => void;
	unassignAlerts: (alerts: AlertWithInvestigationSummaryExtended[], investigations: Investigation[]) => void;
	dismissAlerts: (alerts: AlertWithInvestigationSummaryExtended[]) => void;
	undismissAlerts: (alerts: AlertWithInvestigationSummaryExtended[]) => void;
}

interface AlertsContextMenuContentProps<T> extends CommonContextMenuProps<T> {
	actions: AlertActions;
	filters: Record<string, Filters>;
	alerts: Alert[];
	dataItemPath?: string;
	columnsWithHiddenValueFilterAction?: string[];
	columnsWithHiddenValueCopyAction?: string[];
	onSetFilter: (field: string, values: Filters) => void;
	onResetGridPage?: () => void;
	onViewDetailsClick: (alert: Alert) => void;
}

export const AlertsContextMenuContent = observer(
	<T,>({
		field,
		dataItem,
		gridRef,
		actions,
		filters,
		alerts,
		dataItemPath,
		columnsWithHiddenValueFilterAction = FIELDS_WITH_HIDDEN_FILTER_ACTION,
		columnsWithHiddenValueCopyAction = FIELDS_WITH_HIDDEN_COPY_ACTION,
		onResetGridPage,
		onClose,
		onSetFilter,
		onViewDetailsClick,
	}: AlertsContextMenuContentProps<T>) => {
		const { assignAlerts, unassignAlerts, dismissAlerts, undismissAlerts } = actions;
		const { data, loading } = useDynamicMenu();
		const { t } = useTranslation([Namespaces.AlertsActions], { keyPrefix: 'menu.actions' });
		const investigations = useMemo(() => data?.listInvestigations?.edges?.map(({ node }) => node) ?? [], [data?.listInvestigations?.edges]);

		const focusedAlertNode = dataItemPath ? _get(dataItem, dataItemPath) : dataItem;

		const handleAssignOrCreateInvestigationClick = useCallback(() => {
			if (focusedAlertNode) {
				assignAlerts(extendAlertsWithInvestigationDetails([focusedAlertNode], investigations));
			}
		}, [assignAlerts, focusedAlertNode, investigations]);

		const handleAssignOrCreateSelectedInvestigationClick = useCallback(
			(alerts: Alert[]) => {
				if (alerts?.length > 0) {
					assignAlerts(extendAlertsWithInvestigationDetails(alerts, investigations));
				}
			},
			[assignAlerts, investigations],
		);

		const handleUnassignClick = useCallback(() => {
			if (focusedAlertNode) {
				unassignAlerts(extendAlertsWithInvestigationDetails([focusedAlertNode], investigations), investigations);
			}
		}, [unassignAlerts, focusedAlertNode, investigations]);

		const handleUnassignSelectedClick = useCallback(
			(alerts: Alert[]) => {
				if (alerts.length > 0) {
					unassignAlerts(extendAlertsWithInvestigationDetails(alerts, investigations), investigations);
				}
			},
			[unassignAlerts, investigations],
		);

		const handleDismissClick = useCallback(() => {
			if (focusedAlertNode) {
				dismissAlerts(extendAlertsWithInvestigationDetails([focusedAlertNode], investigations));
			}
		}, [dismissAlerts, focusedAlertNode, investigations]);

		const handleDismissSelectedClick = useCallback(
			(alerts: Alert[]) => {
				if (alerts?.length > 0) {
					dismissAlerts(extendAlertsWithInvestigationDetails(alerts, investigations));
				}
			},
			[dismissAlerts, investigations],
		);

		const handleUndismissClick = useCallback(() => {
			if (focusedAlertNode) {
				undismissAlerts(extendAlertsWithInvestigationDetails([focusedAlertNode], investigations));
			}
		}, [undismissAlerts, focusedAlertNode, investigations]);

		const handleUndismissSelectedClick = useCallback(
			(alerts: Alert[]) => {
				if (alerts?.length > 0) {
					undismissAlerts(extendAlertsWithInvestigationDetails(alerts, investigations));
				}
			},
			[undismissAlerts, investigations],
		);

		const resetGridPosition = useCallback(() => {
			// @ts-ignore - workaround that uses private properties of the gird
			const vs = gridRef?.current?.vs;
			if (vs) {
				vs.fixedScroll = false;
				vs.reset();
			}
			onResetGridPage?.();
		}, [gridRef, onResetGridPage]);

		const onHandleFilterClick = useCallback(
			(field: string, values: Filters) => {
				// Workaround to avoid scrolling lag when we have a lot of rows in table
				// @ts-ignore
				const dataGridElement: HTMLDivElement = gridRef?.current?.element;

				if (dataGridElement) {
					const dataGridContentElement = dataGridElement.querySelector('.k-grid-content');

					if (dataGridContentElement) {
						dataGridContentElement.scrollTop = 0;
					}
				}

				onSetFilter(field, values);
			},
			[onSetFilter, gridRef],
		);

		const handleFilterClick = useCallback(
			(field: string, values: Filters) => {
				resetGridPosition();
				onHandleFilterClick(field, values);
				onClose?.();
			},
			[onClose, onHandleFilterClick, resetGridPosition],
		);

		const handleViewDetailsClick = useCallback(() => {
			if (focusedAlertNode) {
				onViewDetailsClick(focusedAlertNode);
				onClose?.();
			}
		}, [onViewDetailsClick, onClose, focusedAlertNode]);

		const valueActions = useMemo<AlertActionProps[]>(() => {
			if (!dataItem || !field) {
				return [];
			}

			const value = _get(dataItem, field);
			const activeEqualFilters = filters[field];

			return [
				{
					id: ALERT_ACTIONS.FilterByValue,
					show: !columnsWithHiddenValueFilterAction.includes(field),
					label: () => (
						<FiltersAction
							value={value}
							field={field}
							handleAddEqualFilter={handleFilterClick}
							closeContextMenu={onClose}
							activeEqualFilters={activeEqualFilters}
						/>
					),
				},
				{
					id: ALERT_ACTIONS.CopyValue,
					show: !columnsWithHiddenValueCopyAction.includes(field),
					label: () => <CopyValueAction closeContextMenu={onClose} value={value} />,
					className: styles.copyAction,
				},
			];
		}, [filters, field, dataItem, columnsWithHiddenValueCopyAction, columnsWithHiddenValueFilterAction, handleFilterClick, onClose]);

		const singleActions = useAlertsSingleActions({
			selectedAlerts: alerts,
			focusedAlertNode,
			onViewDetails: handleViewDetailsClick,
			onAssignOrCreateInvestigationClick: handleAssignOrCreateInvestigationClick,
			onUnassignClick: handleUnassignClick,
			onDismissClick: handleDismissClick,
			onUndismissClick: handleUndismissClick,
		});

		const bulkActions = useAlertsBulkActions({
			selectedAlerts: alerts,
			focusedAlertNode,
			onViewDetails: handleViewDetailsClick,
			onAssignOrCreateSelectedInvestigationClick: handleAssignOrCreateSelectedInvestigationClick,
			onUnassignSelectedClick: handleUnassignSelectedClick,
			onDismissSelectedClick: handleDismissSelectedClick,
			onUndismissSelectedClick: handleUndismissSelectedClick,
		});

		const sections = useMemo(() => {
			return [
				{
					title: t('value.title'),
					actions: valueActions,
					showLoading: false,
				},
				{
					title: t('single.title'),
					actions: singleActions,
					showLoading: true,
				},
				{
					title: t('bulk.title'),
					subtitle: t('bulk.subtitle'),
					actions: bulkActions,
					showLoading: true,
				},
			];
		}, [t, valueActions, singleActions, bulkActions]);

		return <ContextMenuSections sections={sections} investigations={investigations} loading={loading} onClose={onClose} />;
	},
);
