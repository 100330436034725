import { FC, useState, ReactElement, useCallback } from 'react';
import { DateInputProps, DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { ListItemProps } from '@progress/kendo-react-dropdowns';
import { useOutsideClick } from '@/app/_common/hooks';
import { useTranslation } from 'react-i18next';
import { DatePickerInput } from './date-picker-input';
import { FormDateTimePicker } from './form-date-time-picker';

import styles from './form-date-picker.module.scss';
import { datePickerItemRender } from '../date-picker/date-picker-render-utils';
import { FormDropdown, FormDropdownProps } from '../form-components';
import { CUSTOM_OPTION_KEY, DATE_TIME_PICKER_FORMAT, EMPTY_CUSTOM_OPTION_VALUE } from '@/app/_common/constants';
import { getNowUtcDate, padTo2Digits } from './utils';
import { Namespaces } from '@/translations/namespaces';

interface FormDatePickerProps extends FormDropdownProps {
	isMinimumDateNow: boolean;
}

export const FormDatePicker: FC<FormDatePickerProps> = (props) => {
	const { value, isMinimumDateNow, onChange, valid } = props;
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [datePickerValue, setDatePickerValue] = useState(value);
	const { t } = useTranslation([Namespaces.InvestigationDetails]);
	const CUSTOM_OPTION_LABEL = t('investigationStatusDialog.closeStatus.closePeriodCustom');

	const { popupRef } = useOutsideClick<HTMLTableCellElement, HTMLDivElement>(
		showDatePicker,
		useCallback(() => setShowDatePicker(false), []),
	);

	const minDate = isMinimumDateNow ? getNowUtcDate() : undefined;

	const toggleDatePicker = useCallback(() => {
		setShowDatePicker(!showDatePicker);
	}, [showDatePicker]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleDropdownChange = (event: any) => {
		setDatePickerValue(event.target.value);
		onChange(event);
	};

	const formatDate = (date?: Date | null) => {
		if (!date) {
			return '';
		}

		return (
			[date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') +
			'T' +
			[padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
		);
	};

	const handleDatePickerChange = (event: DateTimePickerChangeEvent) => {
		if (!event.value) return;

		const newCustomPeriodOption = {
			value: formatDate(event.value),
			label: CUSTOM_OPTION_LABEL,
			key: CUSTOM_OPTION_KEY,
		};

		setDatePickerValue(newCustomPeriodOption);
		onChange({ value: newCustomPeriodOption });
		toggleDatePicker();
	};

	const getDateTimePickerValue = () => {
		if (datePickerValue.value && datePickerValue.value !== EMPTY_CUSTOM_OPTION_VALUE) {
			return new Date(datePickerValue.value);
		}
		return undefined;
	};

	const DatePickerInputRender = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props?: DateInputProps<any>) => <DatePickerInput {...props} valid={valid} toggleDatePicker={toggleDatePicker} label={t('sleepEnd')} />,
		[valid, toggleDatePicker, t],
	);

	return (
		<div className={styles.datePickerContainer}>
			<FormDropdown
				{...props}
				onChange={handleDropdownChange}
				itemRender={(li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => datePickerItemRender(li, itemProps, toggleDatePicker)}
			/>
			{datePickerValue?.key === CUSTOM_OPTION_KEY && (
				<FormDateTimePicker
					value={getDateTimePickerValue()}
					dateInput={DatePickerInputRender}
					show={showDatePicker}
					min={minDate}
					format={{
						skeleton: DATE_TIME_PICKER_FORMAT,
					}}
					cancelButton={false}
					onChange={handleDatePickerChange}
					ref={popupRef}
				/>
			)}
		</div>
	);
};
