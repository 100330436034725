import { FC } from 'react';

import { OptionItemDetails } from '@/app/_common/_components/data-grid/_components/data-header-cell-filter/_common/types';

import styles from './option-group-item-icon.module.scss';

interface OptionGroupItemIconProps {
	icon: OptionItemDetails['icon'];
}
export const OptionGroupItemIcon: FC<OptionGroupItemIconProps> = ({ icon }) => {
	if (!icon) {
		return null;
	}

	return <span className={styles.optionGroupItemIcon}>{icon}</span>;
};
