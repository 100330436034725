import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Namespaces } from '@/translations/namespaces';
import { APP_ENTRY_ROUTE } from '@/app/_common/constants/app.constants';
import { Button, ButtonSize } from '@/app/_common/_components/button';
import pageNotFoundLogo from '@/assets/images/page-not-found-logo.svg';
import { AuthStore } from '@/app/_common/stores';
import { useInstance } from 'react-ioc';
import styles from './page-not-access.module.scss';

export const PageNotAccess: FC = observer(() => {
	const store = useInstance(AuthStore);
	const { t } = useTranslation([Namespaces.PageNotAccess]);

	const action = store.currentTenantId ? (
		<NavLink to={APP_ENTRY_ROUTE}>
			<Button className={styles.btn} size={ButtonSize.Large}>
				{t('goToAppEntryPage')}
			</Button>
		</NavLink>
	) : (
		<span className={styles.description}>{t('selectTenant')}</span>
	);

	return (
		<section className={styles.pageNotFound}>
			<div className={styles.wrapper}>
				<img src={pageNotFoundLogo} alt="page-no-found" className={styles.image} />
				<div className={styles.content}>
					<span className={styles.status}>{t('status')}</span>
					<span className={styles.title}>{t('title')}</span>
					<span className={styles.description}>{t('description')}</span>
					{action}
				</div>
			</div>
		</section>
	);
});

export default PageNotAccess;
