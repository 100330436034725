import { FC } from 'react';
import classNames from 'classnames';

import _isEmpty from 'lodash/isEmpty';

import { GridCellProps } from '@progress/kendo-react-grid';

import { DataCell } from '@/app/_common/_components/data-grid/_components';

import { ThreatIntelligenceIcon } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/';
import { ThreatIntelligenceDescription, ThreatIntelligenceMatches } from '@/app/_common/types';

interface ThreatIntelligenceCellProps extends GridCellProps {
	value: string;
	className?: string;
	onClick?: () => void;
	matches: ThreatIntelligenceMatches;
	descriptions: ThreatIntelligenceDescription[];
	currentThreatIntelligenceIconIndex: number | null;
	onThreatIntelligenceIconIndexChange: (newIndex: number | null) => void;
	id: string;
	expandTable: () => void;
}

export const ThreatIntelligenceCell: FC<ThreatIntelligenceCellProps> = ({
	field,
	dataItem,
	className,
	value,
	onClick,
	id,
	matches,
	descriptions,
	currentThreatIntelligenceIconIndex,
	onThreatIntelligenceIconIndexChange,
	expandTable,
	...rest
}) => {
	if (!_isEmpty(value)) {
		return (
			<DataCell {...rest} onClick={onClick} title={value.toString()} className={classNames(className)} dataItem={dataItem} field={field} id={id}>
				<ThreatIntelligenceIcon
					matches={matches}
					descriptions={descriptions}
					cellId={id}
					cellValue={value}
					currentThreatIntelligenceIconIndex={currentThreatIntelligenceIconIndex}
					onThreatIntelligenceIconIndexChange={onThreatIntelligenceIconIndexChange}
					expandTable={expandTable}
				/>
			</DataCell>
		);
	}

	return <DataCell {...rest} onClick={onClick} dataItem={dataItem} field={field} className={className} empty={true} id={id} />;
};
