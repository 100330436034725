import { action, computed, makeObservable } from 'mobx';
import { AxiosRequestConfig } from 'axios';
import _pick from 'lodash/pick';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { FetchBaseDataStore } from '@/app/_common/fetch';
import { Schema } from '@/app/_common/types';
import { AdxEndpoints, AdxTables } from '@/app/_common/constants';
import { getDuplicatedTableColumnsDynamicNames } from '@/app/advanced-query/_common/utils';

const API_URL = process.env.REACT_APP_ADVANCED_QUERY_URL;

export class AdxSchemaDataStore extends FetchBaseDataStore<unknown> {
	private authStore = injectInterface({}, AuthStore);

	constructor() {
		super();

		makeObservable(this, {
			schema: computed,
			fetchSchema: action,
		});

		this.fetchSchema();
	}

	get schema() {
		return this.data as Schema;
	}

	get database() {
		return this.schema?.Databases?.[this.authStore.currentTenantId];
	}

	get tables() {
		return this.database?.Tables;
	}

	public fetchSchema() {
		const config: AxiosRequestConfig = {
			url: `${API_URL}/${AdxEndpoints.ShowSchemaV2}`,
			params: { tenantId: this.authStore.currentTenantId },
		};

		this.fetchApiByAxios('', config);
	}

	public getTableSchema = (table: string) => {
		return this.tables?.[table];
	};

	/*
	 * When in two schema tables (e.g. 'alerts' and 'events') same field name (e.g. 'dest') is present, but has different types (e.g. 'string', 'dynamic'),
	 * Advanced Query results table fetches it as two different fields: (e.g. 'dest_dynamic' and 'dest_string').
	 * But query for available fields with these dynamic field names fails as these fields are not in schemas. The below method
	 * calculates 'duplicatedDynamicNames', which are used to create proper available fields queries to avoid schema validation issue.
	 * */
	public getDuplicatedTableColumnsDynamicNames = (table: AdxTables, selectedTables: string[]): string[] => {
		const tables = _pick(this.tables, selectedTables);

		return getDuplicatedTableColumnsDynamicNames(tables, table);
	};
}
