import { gql } from '@apollo/client';

import { UserFragment, FranchiseUserFragment } from '@/app/_common/graphql';

export const InviteUser = gql`
	mutation InviteUser($tenantId: ID!, $email: String!) {
		inviteUser(tenantId: $tenantId, email: $email) {
			...UserFragment
			... on Error {
				message
				code
				correlationId
				additionalInformation {
					name
					value
				}
			}
		}
	}
	${UserFragment}
`;

export const ListInvitedUsers = gql`
	query ListInvitedUsers($tenantId: ID!, $filtersInput: FiltersInput) {
		listUsers(tenantId: $tenantId, filtersInput: $filtersInput) {
			edges {
				node {
					id
					upn
					hasCompletedRegistration
				}
			}
		}
	}
`;

export const InviteFranchiseUser = gql`
	mutation InviteFranchiseUser($franchiseId: ID!, $email: String!) {
		inviteFranchiseUser(franchiseId: $franchiseId, email: $email) {
			...FranchiseUserFragment
			... on Error {
				message
				code
				correlationId
				additionalInformation {
					name
					value
				}
			}
		}
	}
	${FranchiseUserFragment}
`;

export const ListInvitedFranchiseUsers = gql`
	query ListInvitedFranchiseUsers($franchiseId: ID!, $input: Paging, $filtersInput: FiltersInput) {
		listFranchiseUsers(franchiseId: $franchiseId, input: $input, filtersInput: $filtersInput) {
			edges {
				node {
					id
					upn
					hasCompletedRegistration
				}
			}
		}
	}
`;
