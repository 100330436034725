import { UserPermissions } from './user-permissions';
import { UserRoles } from './user-roles';

export const userRolePermissions: Record<UserRoles, UserPermissions[]> = {
	[UserRoles.FRANCHISE_OWNER]: [UserPermissions.FRANCHISE_SIDEBAR, UserPermissions.FRANCHISE_CREATE_TENANT, UserPermissions.CLIENT_SIDEBAR],
	[UserRoles.FRANCHISE_MANAGER]: [UserPermissions.FRANCHISE_SIDEBAR, UserPermissions.CLIENT_SIDEBAR],
	[UserRoles.FRANCHISE_USER]: [UserPermissions.FRANCHISE_SIDEBAR, UserPermissions.CLIENT_SIDEBAR],
	[UserRoles.TENANT_OWNER]: [UserPermissions.INVITE_TENANT_USERS, UserPermissions.DELETE_TENANT_USERS, UserPermissions.CLIENT_SIDEBAR],
	[UserRoles.TENANT_USER]: [UserPermissions.CLIENT_SIDEBAR],
};
