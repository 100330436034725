import { FC } from 'react';

import { AlertSpecialFieldNames, FieldFilterGroup } from '@/app/_common/types/adx';
import { FilterPanelListItem } from '@/app/_common/_components/filter-panel/_components';
import { AvailableField } from '@/app/_common/types';

import styles from './filter-panel-list.module.scss';
import { getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';

interface FilterPanelListProps {
	total?: number;
	valueHeader?: string;
	countHeader?: string;
	actionsHeader?: string;
	data: AvailableField[];
	filters: FieldFilterGroup[];
	equalsFilterButtonTooltip?: string;
	notEqualsFilterButtonTooltip?: string;
	itemRenderer?: (item: AvailableField, fieldName?: string) => JSX.Element;
	onEqualsFilter: (value: string | number) => void;
	onNotEqualsFilter: (value: string | number) => void;
	fieldName: string;
}

const defaultItemRenderer = (item: AvailableField) => {
	return <span>{item.value}</span>;
};

export const FilterPanelList: FC<FilterPanelListProps> = ({
	data = [],
	total,
	valueHeader,
	countHeader,
	actionsHeader,
	filters,
	equalsFilterButtonTooltip,
	notEqualsFilterButtonTooltip,
	onEqualsFilter,
	onNotEqualsFilter,
	itemRenderer = defaultItemRenderer,
	fieldName,
}) => {
	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<div className={styles.valueHeader}>{valueHeader}</div>
				<div className={styles.countHeader}>{countHeader}</div>
				<div className={styles.actionsHeader}>{actionsHeader}</div>
			</div>
			<ul className={styles.list}>
				{data.map((item) => {
					const tooltip =
						fieldName === AlertSpecialFieldNames.MitreTactics || AlertSpecialFieldNames.MitreTechniques
							? getMitreAttackBadgeText(item.value)
							: item.value;

					return (
						<FilterPanelListItem
							key={item.value?.toString()}
							value={item.value}
							count={item.count}
							total={total}
							tooltip={tooltip}
							filters={filters}
							equalsFilterButtonTooltip={equalsFilterButtonTooltip}
							notEqualsFilterButtonTooltip={notEqualsFilterButtonTooltip}
							valueCell={itemRenderer(item, fieldName)}
							onEqualsFilter={onEqualsFilter}
							onNotEqualsFilter={onNotEqualsFilter}
						/>
					);
				})}
			</ul>
		</div>
	);
};
