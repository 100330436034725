import { Ref } from 'react';
import { provider } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { Form } from '@progress/kendo-react-form';

import { UsersDataStore } from '@/app/_common/stores/users-store';
import { CreateInvestigationForm, CreateInvestigationFormProps } from '@/app/_common/_components/forms';
import { AssigneeSelectViewStore } from '@/app/_common/_components/forms/create-investigation-form/assignee-select.view-store';

interface CreateInvestigationFormModuleProps extends CreateInvestigationFormProps {
	formRef: Ref<Form>;
}

const CreateInvestigationFormModule = provider(
	UsersDataStore,
	AssigneeSelectViewStore,
)(observer<CreateInvestigationFormModuleProps>(({ formRef, ...props }) => <CreateInvestigationForm {...props} ref={formRef} />));

CreateInvestigationFormModule.displayName = 'CreateInvestigationFormModule';

export default CreateInvestigationFormModule;
