import { CountBucket } from '@/app/_common/utils';

export const createBucket = (items: string[], initialValue = 1): CountBucket => {
	return items.reduce((bucket: CountBucket, item: string) => {
		if (bucket[item] !== undefined) {
			return bucket;
		}

		bucket[item] = initialValue;

		return bucket;
	}, {} as CountBucket);
};
