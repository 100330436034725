import i18n from '@/translations/i18n';
import { Severity } from '@/generated/graphql';

export const getSeverityLabel = (value: string | null | undefined): string => {
	switch (value) {
		case Severity.Critical:
			return i18n.t('severityLevels.critical');
		case Severity.High:
			return i18n.t('severityLevels.high');
		case Severity.Medium:
			return i18n.t('severityLevels.medium');
		case Severity.Low:
			return i18n.t('severityLevels.low');
		default:
			return i18n.t('severityLevels.unknown');
	}
};
