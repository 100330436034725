import { useCallback } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import _get from 'lodash/get';

import { getter } from '@progress/kendo-data-query';
import type { GridCellProps } from '@progress/kendo-react-grid';

import { AlertPropertiesPaths } from '@/app/_common/constants';
import { DashboardAlertDetailsViewStore } from '@/app/_common/_components/details-view/stores';
import { AlertStateCell as StateCell } from '@/app/_common/_components/data-grid/_components';

const idGetter = getter(AlertPropertiesPaths.Id);

export const AlertStateCell = observer((props: GridCellProps) => {
	const detailsStore = useInstance(DashboardAlertDetailsViewStore);

	const id = idGetter(props.dataItem);
	const alertState = _get(props.dataItem, AlertPropertiesPaths.State, '');

	const handleCellClick = useCallback(() => {
		detailsStore.setSelectedAlertId(id);
	}, [detailsStore, id]);

	return (
		<StateCell
			{...props}
			statePropertyPath={AlertPropertiesPaths.StateData}
			alertState={alertState}
			onClick={handleCellClick}
			oldContextMenu={false}
		/>
	);
});
