import moment from 'moment';

import { DATEPICKER_DATE_TIME_FORMAT, DateTimePickerOptionKeys, RELATIVE_DATE_TIME_RANGES_AS_SECONDS } from '@/app/_common/constants';
import { TimeRangeFilterOption, TimeRangeFilterOptionValue } from '@/app/_common/types';

const ZOOM_OUT_MULTIPLIER = 2;

export const getZoomOutTimeRange = (persistentTimeRange: TimeRangeFilterOption): Required<TimeRangeFilterOptionValue> => {
	const format = DATEPICKER_DATE_TIME_FORMAT;
	const { key } = persistentTimeRange;
	let fromTimeValue;
	let toTimeValue;

	const dateNow = Date.now();
	if (key === DateTimePickerOptionKeys.CUSTOM) {
		const persistentFromTime = persistentTimeRange.value?.from || '';
		const persistentToTime = persistentTimeRange.value?.to || '';
		const timeDifference = moment(persistentToTime).diff(moment(persistentFromTime), 'seconds');

		fromTimeValue = moment.utc(persistentFromTime).subtract(timeDifference, 'second');

		const toTimeMoment = moment.utc(persistentToTime).add(timeDifference, 'second');
		const dateNowMoment = moment.utc(dateNow);
		const isToValueNotBiggerThanNow = toTimeMoment.isSameOrBefore(dateNowMoment);
		toTimeValue = isToValueNotBiggerThanNow ? toTimeMoment : dateNowMoment;
	} else {
		const fromTimeAsSeconds = RELATIVE_DATE_TIME_RANGES_AS_SECONDS[key];
		fromTimeValue = moment.utc(dateNow).subtract(fromTimeAsSeconds * ZOOM_OUT_MULTIPLIER, 'second');
		toTimeValue = moment.utc(dateNow);
	}

	return {
		from: fromTimeValue.format(format),
		to: toTimeValue.format(format),
	};
};
