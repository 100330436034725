import { FC, ReactNode, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { provider, useInstance } from 'react-ioc';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { Notifications } from '@/app/_common/_components/notifications/notifications';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';

import { PageLayoutViewStore } from '@/app/_common/_components/page-layout/page-layout.view-store';
import { RootPaths } from '@/app/_common/navigation/root-paths';
import { useAuthBroadcastChannel } from '@/app/_common/auth/use-auth-broadcast-channel';

import { AuthStore } from '@/app/_common/stores';

import { Sidebar } from '../sidebar/sidebar';
import { Navbar } from '../navbar';
import { TokenExpirationBar } from '@/app/_common/_components/token-expiration-bar/token-expiration-bar';

import styles from './page-layout.module.scss';

interface PageLayoutProps {
	children: ReactNode;
}

export const PageLayout: FC<PageLayoutProps> = provider(PageLayoutViewStore)(
	observer(({ children }) => {
		const store = useInstance(PageLayoutViewStore);
		const authStore = useInstance(AuthStore);
		const navigate = useNavigate();

		useAuthBroadcastChannel();

		const [active, setActive] = useState(false);

		const navigateToLogIn = () => navigate(RootPaths.LOGIN);

		return (
			<>
				{authStore.loggedIn && <TokenExpirationBar onChange={setActive} />}
				<Navbar loggedIn={authStore.loggedIn} user={authStore.user} login={navigateToLogIn} />
				<Drawer expanded={store.drawerOpened} position={'start'} mode={'push'} mini={true} className={styles.drawer} miniWidth={56} width={200}>
					<Sidebar />
					<DrawerContent>
						<div className={styles.drawerContent}>
							<main className={classNames(styles.content, { [styles.expiration]: active })}>
								<Notifications />
								{children}
							</main>
						</div>
					</DrawerContent>
				</Drawer>
			</>
		);
	}),
);
