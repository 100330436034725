import axios from 'axios';

import { AuthDetailsTokens, defaultAuthDetailsTokens } from '@/app/_common/interfaces/auth-details';
import { getTokens } from '@/app/_common/utils/auth';
import { appendSearchParam } from '@/app/_common/utils';

export const refreshTokens = async (refreshToken: string | null, flow: string): Promise<AuthDetailsTokens> => {
	const requestPayload = {};
	const refreshTokenSearchParam = refreshToken
		? new URLSearchParams({
				refresh_token: refreshToken,
		  }).toString()
		: '';
	const basicUrl = (process.env.REACT_APP_LOGIN_REQUEST as string) + flow + (process.env.REACT_APP_REPLACE_TOKEN_REQUEST as string);
	const requestUrl = appendSearchParam(basicUrl, refreshTokenSearchParam);

	try {
		const responseFromTokenEndpoint = await axios.post(requestUrl, requestPayload);

		return getTokens(responseFromTokenEndpoint);
	} catch (error) {
		return defaultAuthDetailsTokens;
	}
};
