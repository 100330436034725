import { FieldFilter, FieldFilterGroup } from '@/app/_common/types';
import { removeFilterValue, pushFilterValue, appendFilterGroup } from '@/app/advanced-query/_common/utils';

export const addFilter = (filter: FieldFilter, filters: FieldFilterGroup[] = []) => {
	let _filters = [...filters];
	const { field, operator, value } = filter;

	const groupIndex = filters.findIndex((group) => group.field === field && group && group.operator === operator);

	if (groupIndex > -1) {
		const group = _filters[groupIndex];
		const filterValue = group.values.find((v) => v === value);

		if (filterValue !== undefined) {
			_filters = removeFilterValue(value, groupIndex, _filters);
		} else {
			_filters = pushFilterValue(value, groupIndex, _filters);
		}
	} else {
		_filters = appendFilterGroup(
			{
				field,
				operator,
				values: [value],
			},
			_filters,
		);
	}

	return _filters;
};
