import { arrays2csv, isArrayOfArrays, isArrayOfObjects, jsons2csv, string2csv } from '@/app/_common/_components/csv-export/utils';
import {
	CsvExportData,
	CsvExportDataItemValue,
	CsvExportEnclosingCharacter,
	CsvExportHeaders,
	CsvExportSeparator,
} from '@/app/_common/_components/csv-export/types';

export const toCSV = (
	data: string | CsvExportData | CsvExportDataItemValue[][],
	headers: CsvExportHeaders,
	separator: CsvExportSeparator,
	enclosingCharacter: CsvExportEnclosingCharacter,
) => {
	if (typeof data === 'string') {
		return string2csv(data, headers, separator);
	}

	if (isArrayOfObjects(data)) {
		return jsons2csv(data as CsvExportData, headers, separator, enclosingCharacter);
	}

	if (isArrayOfArrays(data)) {
		// @ts-ignore
		return arrays2csv(data, headers, separator, enclosingCharacter);
	}

	throw new TypeError(`Data should be a "String", "Array of arrays" OR "Array of objects" `);
};
