import { Severity } from '@/generated/graphql';

import { FilterOption, FilterOptionItem } from '@/app/_common/types';
import { getSeverityColorIndicator } from '@/app/_common/utils/colors';
import { getConfidenceFilterOptions, getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';
import { capitalizeFirstLetter } from '@/app/_common/utils';

// Alert helper functions
export const getSeverityOption = (option: FilterOptionItem, filterOptionItem: FilterOptionItem): FilterOption => {
	const { value } = filterOptionItem;

	return {
		...option,
		bulletsConfig: getSeverityColorIndicator(value as Severity),
		special: value === Severity.Unknown,
	};
};

export const getConfidenceOption = (option: FilterOptionItem, filterOptionItem: FilterOptionItem): FilterOption => {
	const { value } = filterOptionItem;

	return {
		...option,
		...getConfidenceFilterOptions(value),
	};
};

export const getStateOption = (option: FilterOptionItem, filterOptionItem: FilterOptionItem): FilterOption => {
	const { value } = filterOptionItem;

	return {
		...option,
		label: capitalizeFirstLetter(value as string),
	};
};

// Advanced Query helper functions

export const getAdvancedQueryConfidenceOption = (option: FilterOptionItem, filterOptionItem: FilterOptionItem): FilterOption => {
	const { value } = filterOptionItem;

	return {
		...option,
		...getConfidenceFilterOptions(value),
		special: undefined,
	};
};

export const getMitreAttackOption = (option: FilterOptionItem, filterOptionItem: FilterOptionItem): FilterOption => {
	const { value } = filterOptionItem;

	return {
		...option,
		label: getMitreAttackBadgeText(value as string),
	};
};

// ------------------

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapFilterOption = (field: string, filterOptionItem: Omit<FilterOptionItem, 'label'>, fieldMaps: Record<string, any>) => {
	const option: FilterOption = {
		...filterOptionItem,
		label: filterOptionItem.value as string,
	};

	const fieldMappedOption = fieldMaps[field]?.(option, filterOptionItem);

	return fieldMappedOption ?? option;
};
