import { CSSProperties } from 'react';

export const samuraiDark: { [key: string]: CSSProperties } = {
	hljs: {
		display: 'block',
		overflowX: 'auto',
		padding: '0.5em',
		background: '#1e1e1e',
		color: '#d4d4d4',
	},
	'hljs-comment': {
		color: '#608b4e',
	},
	'hljs-quote': {
		color: '#608b4e',
	},
	'hljs-variable': {
		color: '#608b4e',
	},
	'hljs-keyword': {
		color: '#569cd6',
	},
	'hljs-selector-tag': {
		color: '#569cd6',
	},
	'hljs-built_in': {
		color: '#569cd6',
	},
	'hljs-name': {
		color: '#569cd6',
	},
	'hljs-tag': {
		color: '#569cd6',
	},
	'hljs-string': {
		color: '#ce9178',
	},
	'hljs-title': {
		color: '#ce9178',
	},
	'hljs-section': {
		color: '#ce9178',
	},
	'hljs-attribute': {
		color: '#ce9178',
	},
	'hljs-literal': {
		color: '#569cd6',
	},
	'hljs-template-tag': {
		color: '#ce9178',
	},
	'hljs-template-variable': {
		color: '#ce9178',
	},
	'hljs-type': {
		color: '#ce9178',
	},
	'hljs-addition': {
		color: '#ce9178',
	},
	'hljs-deletion': {
		color: '#2b91af',
	},
	'hljs-selector-attr': {
		color: '#2b91af',
	},
	'hljs-selector-pseudo': {
		color: '#2b91af',
	},
	'hljs-meta': {
		color: '#2b91af',
	},
	'hljs-doctag': {
		color: '#808080',
	},
	'hljs-attr': {
		color: '#ce9178',
	},
	'hljs-symbol': {
		color: '#00b0e8',
	},
	'hljs-bullet': {
		color: '#00b0e8',
	},
	'hljs-link': {
		color: '#00b0e8',
	},
	'hljs-params': {
		color: '#f5ab35',
	},
	'hljs-number': {
		color: '#b5cea8',
	},
	'hljs-emphasis': {
		fontStyle: 'italic',
	},
	'hljs-strong': {
		fontWeight: 'bold',
	},
};
