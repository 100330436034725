import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';

import { Severity } from '@/generated/graphql';

import { sortByOrder } from '@/app/_common/utils';
import { AlertPropertiesPaths, SortDirection } from '@/app/_common/constants';
import { FilterOption } from '@/app/_common/types';
import { CONFIDENCE_PRIORITY_WITH_UNKNOWN } from '@/app/_features/alerts/_common/constants';

export const sortAlertFilters = (data: FilterOption[], path: string, field: string, paths: { [k: string]: string } = AlertPropertiesPaths) => {
	if (field === paths.Severity) {
		return sortByOrder<FilterOption>(
			data,
			path,
			[Severity.Critical, Severity.High, Severity.Medium, Severity.Low, Severity.Unknown],
			SortDirection.Desc,
		);
	} else if (field === paths.Confidence) {
		return sortByOrder<FilterOption>(data, path, CONFIDENCE_PRIORITY_WITH_UNKNOWN, SortDirection.Desc);
	}
	return _orderBy<FilterOption>(
		data,
		[
			(option) => {
				const value = typeof path === 'string' ? _get(option, path) : option;
				return value.toLowerCase();
			},
		],
		[SortDirection.Asc],
	);
};
