import _uniqBy from 'lodash/uniqBy';

import { IOCResponseFile, ThreatIntelligenceDescription } from '@/app/_common/types';

export const getThreatIntelligenceDescriptions = (data: IOCResponseFile[]): ThreatIntelligenceDescription[] => {
	if (!data || data.length === 0) {
		return [];
	}

	const descriptions = data
		?.filter((dataFile) => dataFile?.result_karte)
		.map((dataFile) => {
			if (!dataFile.result_karte?.labels) {
				return [];
			}
			return Object.keys(dataFile.result_karte?.labels).map((labelKey) => {
				if (!dataFile.result_karte?.labels) {
					return { title: '', description: '' };
				}
				return {
					title: labelKey,
					description: dataFile.result_karte.labels[labelKey],
				};
			});
		})
		.flat();

	return _uniqBy(descriptions, 'title');
};
