import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import _mapValues from 'lodash/mapValues';
import _keyBy from 'lodash/keyBy';

import { AddCircle } from '@mui/icons-material';
import { ButtonsPopupSettings, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';

import { Namespaces } from '@/translations/namespaces';
import { DropdownButton } from '@/app/_common/_components/dropdown-button';
import { Chip } from '@/app/_common/_components/chips-select/_components';

import styles from './chips-select.module.scss';

interface DropdownItem {
	label: string;
	disabled: boolean;
}

export interface ChipItem {
	label: string | ReactNode;
	value: string;
}

interface ChipsSelectProps {
	title?: string;
	options: ChipItem[];
	values?: string[];
	onChange: (value: string[]) => void;
	disabled?: boolean;
}

const POPUP_SETTINGS: ButtonsPopupSettings = {
	popupClass: styles.popup,
	anchorAlign: {
		horizontal: 'left',
		vertical: 'bottom',
	},
	popupAlign: {
		horizontal: 'left',
		vertical: 'top',
	},
};

export const ChipsSelect = ({ title, values = [], options, onChange, disabled }: ChipsSelectProps) => {
	const { t } = useTranslation([Namespaces.Common]);
	const optionsByVale = useMemo(() => _mapValues(_keyBy(options, 'value'), (o) => o.label), [options]);

	const handleRemoveChip = useCallback(
		(removedValue: string) => {
			onChange(values.filter((val) => val !== removedValue));
		},
		[values, onChange],
	);

	const handleOptionItemClick = useCallback(
		(clickEvent: DropDownButtonItemClickEvent) => {
			onChange([...values, clickEvent.item.value]);
		},
		[values, onChange],
	);

	const itemRender = ({ item }: { item: DropdownItem }) => {
		return <div className={styles.item}>{item.label}</div>;
	};

	return (
		<div className={styles.mainContainer}>
			{title && <div className={styles.title}>{title}</div>}
			<div className={styles.optionsContainer}>
				{values.map((val) => {
					return (
						<Chip key={val} value={val} onRemoveClick={handleRemoveChip} disabled={disabled}>
							{optionsByVale[val]}
						</Chip>
					);
				})}
				{disabled && values.length === 0 && <span className={styles.title}>{t('none')}</span>}
				{!disabled && (
					<DropdownButton
						text={
							<div className={styles.buttonContainer}>
								<AddCircle />
								<span>{t('buttons.add')}</span>
							</div>
						}
						fillMode="flat"
						className={styles.addButton}
						items={options}
						itemRender={itemRender}
						popupSettings={POPUP_SETTINGS}
						onItemClick={handleOptionItemClick}
					/>
				)}
			</div>
		</div>
	);
};
