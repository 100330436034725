import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import OpenInNew from '@mui/icons-material/OpenInNew';
import AssignmentIcon from '@mui/icons-material/Assignment';

import { Namespaces } from '@/translations/namespaces';
import { RootPaths } from '@/app/_common/navigation';
import { buttonize } from '@/app/_common/utils';
import { ContextMenuLabel } from '@/app/_common/_components/new-context-menu/_components';

import styles from './open-investigation-action.module.scss';

interface OpenInvestigationActionProps {
	className?: string;
	investigationId: string;
}

export const OpenInvestigationAction: FC<OpenInvestigationActionProps> = ({ className = '', investigationId }) => {
	const { t } = useTranslation(Namespaces.AlertsActions, { keyPrefix: 'menu.actions.single.items.openInvestigation' });

	const handleGoToInvestigation = useCallback(() => {
		const investigationDetailsPath = `${RootPaths.INVESTIGATIONS}/${investigationId}`;
		window.open(investigationDetailsPath, '_blank');
	}, [investigationId]);

	if (!investigationId) {
		return null;
	}

	return (
		<div className={classNames(styles.container, className)} {...buttonize(handleGoToInvestigation)}>
			<ContextMenuLabel prefixIcon={<AssignmentIcon />}>
				<p>{t('label')}</p> <OpenInNew />
			</ContextMenuLabel>
		</div>
	);
};
