import { ReactNode, memo } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

import classNames from 'classnames';
import _assignInWith from 'lodash/assignInWith';
import _cloneDeep from 'lodash/cloneDeep';
import _isUndefined from 'lodash/isUndefined';
import _isString from 'lodash/isString';

import { Portal } from '@/app/_common/portal';

import styles from './tooltip.module.scss';

const DEFAULT_TOOLTIP_SETTINGS: Pick<TooltipProps, 'type' | 'place' | 'effect'> = {
	type: 'dark',
	place: 'top',
	effect: 'float',
};

interface PortaledTooltipProps extends Partial<Pick<TooltipProps, 'place' | 'effect' | 'afterShow' | 'globalEventOff' | 'delayHide' | 'delayShow'>> {
	tooltipId: string;
	tooltipContent?: ReactNode | ((datatip: string) => ReactNode);
	offsetTop?: number;
	offsetRight?: number;
	className?: string;
}

/** Object on which tooltip works needs to have attributes: data-tip (any value), data-for (ID of object) */
export const Tooltip = memo(function Tooltip({
	tooltipId,
	offsetTop,
	offsetRight,
	tooltipContent,
	place,
	effect,
	afterShow,
	className,
	globalEventOff,
	delayHide,
	delayShow,
}: PortaledTooltipProps) {
	if (!tooltipContent && tooltipContent !== 0) {
		return null;
	}

	const tooltipSettings = _assignInWith(
		_cloneDeep(DEFAULT_TOOLTIP_SETTINGS),
		{ place, effect, afterShow, globalEventOff, delayHide, delayShow },
		(objValue, srcValue) => {
			const isEmptySrcValue = _isUndefined(srcValue) || (_isString(srcValue) && srcValue === '');

			if (isEmptySrcValue) {
				return objValue;
			}

			return srcValue;
		},
	);

	const getContent = tooltipContent instanceof Function ? tooltipContent : () => tooltipContent;

	return (
		<Portal>
			<ReactTooltip
				{...tooltipSettings}
				id={tooltipId}
				offset={{ top: offsetTop ?? 0, right: offsetRight ?? 0 }}
				className={classNames(styles.tooltip, className)}
				getContent={getContent}
			></ReactTooltip>
		</Portal>
	);
});

export { ReactTooltip as TooltipController };
