import { FC, PropsWithChildren } from 'react';
import _get from 'lodash/get';
import { GridCellProps } from '@progress/kendo-react-grid';
import { observer } from 'mobx-react-lite';
import { DataCell } from '@/app/_common/_components/data-grid/_components';
import { DataGridSelected } from '@/app/_common/types';

import styles from './radio-button-cell.module.scss';
import classNames from 'classnames';

interface RadioButtonCellProps extends GridCellProps {
	handleChange?: (props: PropsWithChildren<RadioButtonCellProps>) => void;
	onCellClick?: () => void;
	selectedRow: DataGridSelected;
	className?: string;
}

export const RadioButtonCell: FC<RadioButtonCellProps> = observer(({ selectedRow, handleChange, onCellClick, dataItem, className, ...rest }) => {
	const handleRadioButtonChange = () => {
		if (handleChange) {
			handleChange(dataItem);
		}
	};

	const isRowSelected = Object.keys(selectedRow)[0] === _get(dataItem, 'node.id');

	return (
		<DataCell {...rest} dataItem={dataItem} className={classNames(styles.container, className)} onClick={onCellClick}>
			<input checked={isRowSelected} className={styles.radioButton} onChange={handleRadioButtonChange} type="radio" />
		</DataCell>
	);
});
