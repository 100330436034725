import { FC } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { IconButton } from '@/app/_common/_components/rich-text-editor/_components/buttons';

import styles from './submit-edit-button.module.scss';
import classNames from 'classnames';

interface SubmitEditButtonProps {
	onClick: () => void;
	disabled?: boolean;
	text?: string;
}

export const SubmitEditButton: FC<SubmitEditButtonProps> = ({ onClick, text, disabled = false }) => {
	return (
		<div data-testid="rich-text-editor-submit-edit-btn">
			<IconButton
				className={classNames(styles.button, { [styles.disabled]: disabled, hasText: Boolean(text) })}
				onClick={onClick}
				disabled={disabled}
			>
				{text && <span className={styles.text}>{text}</span>}
				<CheckCircleIcon className={styles.icon} />
			</IconButton>
		</div>
	);
};
