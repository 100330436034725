import { TimelineItemData } from '@/app/_common/_components/details-view/alerts-details-view-navigation';

export const calculateTimespan = (items: TimelineItemData[]) => {
	const firstItemDate = Date.parse(items[0].time);
	const lastItemDate = Date.parse(items[items.length - 1].time);
	const timespan = lastItemDate - firstItemDate;
	return { firstItemDate, timespan };
};

export const countMiddleItemOffset = ({
	timespan,
	firstItemDate,
	currentItem,
	itemsExceptLastWidth,
	itemWidth,
}: {
	timespan: number;
	firstItemDate: number;
	currentItem: TimelineItemData;
	itemsExceptLastWidth: number;
	itemWidth: number;
}): number => {
	if (!timespan) {
		return 0; // When each alert has same timestamp, then each icon is in 1st place.
	}

	const currentItemDate = Date.parse(currentItem.time);
	const timeSinceStart = currentItemDate - firstItemDate;
	const proportion = timeSinceStart / timespan;

	const areaToDraw = itemsExceptLastWidth - itemWidth;
	return areaToDraw * proportion;
};
