import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { ResultsFieldValue } from '@/app/_common/types';

export const isFieldValueEmpty = (value: ResultsFieldValue) => {
	const isEmptyString = typeof value === 'string' && !value;
	const isEmptyObject = typeof value === 'object' && _isEmpty(value);

	return _isNil(value) || isEmptyString || isEmptyObject;
};
