import moment from 'moment';

import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

import { AlertStateOptions, DATE_TIME_FORMAT } from '@/app/_common/constants';
import { AlertStateData, AlertStateInfoDetails } from '@/app/_common/types';

export const getDismissedAlertStateInfo = (state: AlertStateData): AlertStateInfoDetails => {
	return {
		state: AlertStateOptions.Dismissed,
		label: i18n.t('summary.states.dismissed', { ns: Namespaces.DetailsView }),
		tooltipInfo: i18n.t('summary.states.tooltip.dismissed', {
			ns: Namespaces.DetailsView,
			user: state?.requesterUser.upn,
			timestamp: moment(state?.timestamp).parseZone().format(DATE_TIME_FORMAT),
		}),
	};
};
