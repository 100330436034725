export const improveMiddleItemOffset = ({
	currentItemOffset,
	prevItemOffset,
	itemsExceptLastWidth,
	imageWidth,
	itemWidth,
}: {
	currentItemOffset: number;
	prevItemOffset: number;
	itemsExceptLastWidth: number;
	imageWidth: number;
	itemWidth: number;
}): number => {
	// When items are nearly on the same position, then they should be overlapped by 1/3 of icon if possible.
	const distance = currentItemOffset - prevItemOffset;
	const partImageWidth = Math.floor((imageWidth * 2) / 3);
	if (distance >= partImageWidth) {
		return currentItemOffset;
	}

	const correctedOffset = prevItemOffset + partImageWidth;
	const maxOffset = itemsExceptLastWidth - itemWidth;
	if (correctedOffset <= maxOffset) {
		return correctedOffset;
	}

	// When cannot apply "half icon rule" or would overlap with last icon, then use uncorrected offset.
	return currentItemOffset;
};
