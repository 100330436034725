import { observer } from 'mobx-react-lite';
import { provider } from 'react-ioc';
import { ContextMenuComponent, ContextMenuProps } from './context-menu';
import { ContextMenuDataStore, ContextMenuViewStore } from './_common';

const ContextMenuModule = provider(
	ContextMenuDataStore,
	ContextMenuViewStore,
)(
	observer((props: ContextMenuProps) => {
		return <ContextMenuComponent {...props} />;
	}),
);

ContextMenuModule.displayName = 'ContextMenuModule';

export const ContextMenu = ContextMenuModule;
