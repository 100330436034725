import { useId, CSSProperties, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellProps } from '@progress/kendo-react-grid';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { getter } from '@progress/kendo-react-common';

import { Checkbox } from '@/app/_common/_components/checkbox';
import { Namespaces } from '@/translations/namespaces';
import { Tooltip } from '@/app/_common/_components';
import { ResultsTablePropertiesPaths } from '@/app/_common/constants';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';

import styles from './query-results-table-select-cell.module.scss';

interface QueryResultsTableSelectCellProps extends GridCellProps {
	style?: CSSProperties; // this applies styles that lock the column at a specific position
	className?: string; // this adds classes needed for locked columns
}

const idGetter = getter(ResultsTablePropertiesPaths.Id);

export const QueryResultsTableSelectCell = observer(({ dataItem, field, className, style, onContextMenu }: QueryResultsTableSelectCellProps) => {
	const { isRowSelected, toggleSelectedRow } = useQueryResultsTableStore();
	const id = idGetter(dataItem);
	const { t } = useTranslation([Namespaces.QueryResults]);
	const checked = isRowSelected(id);
	const disabled = Boolean(dataItem.disabled);
	const tooltipContent = disabled ? t('tooltips.alertAlreadyAssigned') : '';
	const tooltipId = useId();

	const onSelectionChange = useCallback(() => {
		toggleSelectedRow(id);
	}, [id, toggleSelectedRow]);

	const handleContextMenu = useCallback(
		(event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
			onContextMenu?.(event, dataItem, field);
		},
		[dataItem, field, onContextMenu],
	);

	return (
		<td className={classNames(styles.container, className)} style={style} onContextMenu={handleContextMenu}>
			{tooltipContent && <Tooltip tooltipId={tooltipId} tooltipContent={tooltipContent} className={styles.tooltip} />}
			<div data-tip={tooltipContent} data-for={tooltipId}>
				<Checkbox checked={checked} disabled={disabled} onChange={onSelectionChange} />
			</div>
		</td>
	);
});

QueryResultsTableSelectCell.displayName = 'QueryResultsTableSelectCell';
