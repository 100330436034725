import { memo } from 'react';

import type { GridCellProps } from '@progress/kendo-react-grid';

import { DateTimeCell } from '@/app/_common/_components/data-grid/_components';

export const AlertTimestampCell = memo((props: GridCellProps) => {
	return <DateTimeCell {...props} oldContextMenu={false} />;
});

AlertTimestampCell.displayName = 'AlertTimestampCell';
