import { ApolloCache, DocumentNode, Reference } from '@apollo/client';

export const writeFragmentById = <T>(
	cache: ApolloCache<unknown>,
	id: string | undefined,
	data: T,
	fragment: DocumentNode,
	typename: string,
): Reference | undefined | null => {
	if (!id) {
		return null;
	}

	const identifier = cache.identify({
		__typename: typename,
		id,
	});

	return cache.writeFragment({
		id: identifier,
		data,
		fragment,
	});
};
