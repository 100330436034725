import { Transforms } from 'slate';

import { IsCurrentFormatActive, EditorType, FormattingType, CustomEditor } from '@/app/_common/_components/rich-text-editor';

export const toggleCodeActionButton = (editor: CustomEditor, format: FormattingType) => {
	const { children } = editor;
	const isActive = IsCurrentFormatActive(editor, format);

	if (!isActive) {
		Transforms.insertNodes(editor, { type: EditorType.Paragraph, children: [{ text: '' }] }, { mode: 'highest', select: true, hanging: true });
		Transforms.setNodes(editor, {
			type: format,
			children: [{ text: '' }],
		});
		return;
	}

	Transforms.insertNodes(
		editor,
		{ type: EditorType.Paragraph, children: [{ text: '' }] },
		{ at: [children.length], mode: 'highest', hanging: true, select: true },
	);
};
