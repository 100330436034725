import { makeAutoObservable } from 'mobx';

import _uniqueId from 'lodash/uniqueId';

import { Notification, NotificationLook, NotificationMessage } from '@/app/_common/types';

export const CLOSE_NOTIFICATION_TIMEOUT = 10000;

interface State {
	notifications: Notification[];
}

export class NotificationsStore {
	private state: State = {
		notifications: [],
	};

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get notifications() {
		return this.state.notifications;
	}

	openNotification(notification: Partial<Pick<Notification, 'id'>> & Omit<Notification, 'onClose' | 'id'>) {
		const id = notification.id ?? _uniqueId('notification_');

		setTimeout(() => {
			this.closeNotification(id);
		}, CLOSE_NOTIFICATION_TIMEOUT);

		this.state.notifications = [...this.state.notifications, { id, onClose: () => this.closeNotification(id), ...notification }];
	}

	openSuccess(notification: NotificationMessage) {
		this.openNotification({
			...notification,
			look: NotificationLook.Outlined,
			closable: true,
			type: { style: 'success', icon: true },
		});
	}

	openInfo(notification: NotificationMessage) {
		this.openNotification({
			...notification,
			look: NotificationLook.Outlined,
			closable: true,
			type: { style: 'info', icon: true },
		});
	}

	openWarning(notification: NotificationMessage) {
		this.openNotification({
			...notification,
			look: NotificationLook.Outlined,
			closable: true,
			type: { style: 'warning', icon: true },
		});
	}

	openError(notification: NotificationMessage) {
		this.openNotification({
			...notification,
			look: NotificationLook.Outlined,
			closable: true,
			type: { style: 'error', icon: true },
		});
	}

	openPending(notification: NotificationMessage) {
		this.openNotification({
			...notification,
			look: NotificationLook.Filled,
			closable: true,
			type: { style: 'info', icon: true },
			className: 'pending',
		});
	}

	closeNotification(id: string) {
		this.state.notifications = this.state.notifications.filter((notification) => notification.id !== id);
	}

	closeAll() {
		this.state.notifications = [];
	}
}
