import { InvestigationTypename } from '@/app/_common/constants';
import { CreateAndAssignAlertsUpdateCacheFn } from '@/app/_features/alerts-actions/_common/stores/_common/types';
import { assignAlertListInvestigationUpdate, updateCommonAssignAlertsDashboard } from '@/app/dashboards/alerts-dashboard/_common/utils/cache-updates';

export const updateCacheCreateAndAssignAlertsDashboard: CreateAndAssignAlertsUpdateCacheFn = (user, cache, { data }, { variables }) => {
	if (!data?.createInvestigation || data?.createInvestigation.__typename !== InvestigationTypename.Investigation) {
		return;
	}

	const investigationId = data?.createInvestigation?.id;
	const alerts = data.createInvestigation.alerts ?? [];

	updateCommonAssignAlertsDashboard(cache, alerts, investigationId, user, variables);

	cache.modify({
		fields: {
			listInvestigations: assignAlertListInvestigationUpdate(data?.createInvestigation),
		},
	});
};
