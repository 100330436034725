import { elementOrEmpty } from '@/app/_common/_components/csv-export/utils';
import { CsvExportData } from '@/app/_common/_components/csv-export/types';

const DEFAULT_SEPARATOR = ',';
const DEFAULT_ENCLOSING_CHARACTER = '"';

export const joiner = (data: CsvExportData[], separator = DEFAULT_SEPARATOR, enclosingCharacter = DEFAULT_ENCLOSING_CHARACTER) => {
	return data
		.filter((row) => row)
		.map((row) =>
			row
				.map((element) => elementOrEmpty(element))
				.map((column) => `${enclosingCharacter}${column}${enclosingCharacter}`)
				.join(separator),
		)
		.join(`\n`);
};
