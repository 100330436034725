/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useRef, useState } from 'react';
import createActivityDetector from 'activity-detector';

export const MILLISECONDS_IN_SECOND = 1000;

export const useIdle = (timeToIdle: number = MILLISECONDS_IN_SECOND, onActive?: () => void, onIdle?: () => void) => {
	const [active, setActive] = useState(false);
	const activityDetectorRef = useRef<ReturnType<typeof createActivityDetector>>();

	const handleActiveChange = useCallback(() => {
		setActive(true);

		if (onActive) {
			onActive();
		}
	}, [onActive]);

	const handleIdleChange = useCallback(() => {
		setActive(false);

		if (onIdle) {
			onIdle();
		}
	}, [onIdle]);

	useEffect(() => {
		if (activityDetectorRef.current) {
			activityDetectorRef.current.stop();
		}

		activityDetectorRef.current = createActivityDetector({ timeToIdle });

		activityDetectorRef.current.on('active', handleActiveChange);
		activityDetectorRef.current.on('idle', handleIdleChange);

		return () => activityDetectorRef.current.stop();
	}, [handleActiveChange, handleIdleChange, timeToIdle]);

	return { active };
};
