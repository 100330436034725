import { FC, ReactNode, useState } from 'react';
import { useSelected, useSlate } from 'slate-react';
import classNames from 'classnames';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { useTable, DeleteTableDialog, UnknownObject } from '@/app/_common/_components/rich-text-editor';
import { buttonize } from '@/app/_common/utils';
import { RichTextEditorTranslations } from '@/app/_common/_components/rich-text-editor/types';

import styles from './table-element.module.scss';

interface TableElementProps {
	children: ReactNode;
	attributes: UnknownObject;
	element: UnknownObject;
	translations: RichTextEditorTranslations;
	isReadOnlyEnabled?: boolean;
}

export const TableElement: FC<TableElementProps> = ({ attributes, element, translations, isReadOnlyEnabled, children }) => {
	const [openDialog, setOpenDialog] = useState(false);
	const editor = useSlate();
	const selected = useSelected();
	const rows = children as Array<ReactNode>;
	const columns = element.children[0].children as Array<unknown>;
	const { insertColumn, insertRow, removeColumn, removeRow } = useTable(editor);

	const handleToggleDialog = () => {
		setOpenDialog((prev) => !prev);
	};

	return (
		<div className={classNames(styles.tableWrapper, { [styles.activeTools]: selected && !isReadOnlyEnabled })}>
			<div className={classNames(styles.tools, { [styles.active]: selected && !isReadOnlyEnabled })}>
				<div contentEditable="false" className={styles.bin}>
					<div className={styles.iconBox} {...buttonize<HTMLDivElement>(handleToggleDialog)}>
						<DeleteIcon className={styles.binIcon} />
					</div>
				</div>
				<div contentEditable="false" className={styles.row}>
					{rows.length > 1 && (
						<button className={styles.btn} onClick={removeRow}>
							<RemoveIcon className={styles.icon} />
						</button>
					)}
					<button className={styles.btn} onClick={insertRow}>
						<AddIcon className={styles.icon} />
					</button>
				</div>
				<div contentEditable="false" className={styles.column}>
					{columns.length > 1 && (
						<button className={styles.btn} onClick={removeColumn}>
							<RemoveIcon className={styles.icon} />
						</button>
					)}
					<button className={styles.btn} onClick={insertColumn}>
						<AddIcon className={styles.icon} />
					</button>
				</div>
				<DeleteTableDialog open={openDialog} handleToggleDialog={handleToggleDialog} translations={translations} />
			</div>
			<table className={styles.table}>
				<tbody {...attributes}>{children}</tbody>
			</table>
		</div>
	);
};
