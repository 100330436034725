import { FC } from 'react';

type Column = { id: string; fields: (JSX.Element | undefined | null)[] | null };

interface MultipleColumnsLayoutProps {
	columns: Column[];
}

export const MultipleColumnsLayout: FC<MultipleColumnsLayoutProps> = ({ columns }) => {
	return (
		<>
			{columns.map(({ id, fields }) => (
				<div key={id}>{fields}</div>
			))}
		</>
	);
};
