import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { RadioGroup } from '@progress/kendo-react-inputs';
import { Error, Hint, Label } from '@progress/kendo-react-labels';
import styles from './form-radio.module.scss';

export const FormRadioGroup = (fieldRenderProps: FieldRenderProps) => {
	const { validationMessage, touched, id, label, valid, disabled, hint, ...others } = fieldRenderProps;

	const showValidationMessage: string | false | null = touched && validationMessage;
	const showHint: boolean = !showValidationMessage && hint;
	const hintId: string = showHint ? `${id}_hint` : '';
	const errorId: string = showValidationMessage ? `${id}_error` : '';
	const labelId: string = label ? `${id}_label` : '';

	return (
		<FieldWrapper>
			<Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
				{label}
			</Label>
			<RadioGroup
				className={styles.radioButton}
				ariaDescribedBy={`${hintId} ${errorId}`}
				ariaLabelledBy={labelId}
				valid={valid}
				disabled={disabled}
				{...others}
			/>
			{showHint && <Hint id={hintId}>{hint}</Hint>}
			{showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
		</FieldWrapper>
	);
};
