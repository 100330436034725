import { FC, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';

import { FranchiseTenantBasicInfo, Tenant } from '@/app/_common/interfaces/auth-details';
import { AuthStore, UrlStore } from '@/app/_common/stores';
import { buttonize } from '@/app/_common/utils';
import { Namespaces } from '@/translations/namespaces';
import { APP_ENTRY_ROUTE } from '@/app/_common/constants/app.constants';
import { Tooltip } from '@/app/_common/_components';
import '@/assets/styles/index.scss';

import styles from './user-tenants.module.scss';

interface UserTenantsProps {
	toggleDropdown: () => void;
}

const URL_STATE_VARIABLES_NAME = 'tenantId';
const getTooltipId = (tenantId: string) => `userTenant-${tenantId}`;

const getShouldDisplayTenantTooltip = (isFranchiseUser: boolean, tenant: FranchiseTenantBasicInfo) => {
	return isFranchiseUser && !tenant.hasCompletedRegistration;
};

const shouldHandleTenantChange = (isFranchiseUser: boolean, tenant: FranchiseTenantBasicInfo) => {
	return !isFranchiseUser || tenant.hasCompletedRegistration;
};

export const UserTenants: FC<UserTenantsProps> = observer(({ toggleDropdown }) => {
	const authStore = useInstance(AuthStore);
	const urlStore = useInstance(UrlStore);

	const { t } = useTranslation([Namespaces.NavbarMenu]);

	const navigate = useNavigate();

	const handleTenantChange = (tenant: Tenant) => {
		if (!shouldHandleTenantChange(authStore.isFranchiseUser, tenant as FranchiseTenantBasicInfo)) {
			return;
		}

		authStore.setCurrentTenantId(tenant.id);
		urlStore.updateStore({ [URL_STATE_VARIABLES_NAME]: tenant.id });
		navigate(APP_ENTRY_ROUTE);
		toggleDropdown();
	};

	return (
		<div className={classNames(styles.userTenants, styles.paddedItem)}>
			<div className={styles.title}>{t('switchTo')}</div>
			{authStore.uniqueTenants.map((tenant) => {
				const shouldDisplayTenantTooltip = getShouldDisplayTenantTooltip(authStore.isFranchiseUser, tenant as FranchiseTenantBasicInfo);

				return (
					<Fragment key={tenant.id}>
						<div
							className={classNames(
								styles.link,
								{ [styles.selected]: authStore.currentTenantId === tenant.id },
								{ [styles.disabled]: shouldDisplayTenantTooltip },
							)}
							{...buttonize<HTMLDivElement>(() => handleTenantChange(tenant))}
							{...(shouldDisplayTenantTooltip && {
								'data-tip': true,
								'data-for': getTooltipId(tenant.id),
							})}
						>
							{tenant.name}
						</div>
						{shouldDisplayTenantTooltip && (
							<Tooltip tooltipId={getTooltipId(tenant.id)} tooltipContent={t('notRegistered')} place="top" effect="float" />
						)}
					</Fragment>
				);
			})}
		</div>
	);
});
