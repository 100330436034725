import { ReactNode } from 'react';

import styles from './context-menu-subtitle.module.scss';

interface ContextMenuTitleProps {
	children: ReactNode;
}

export const ContextMenuSubtitle = ({ children }: ContextMenuTitleProps) => {
	return <h6 className={styles.contextMenuSubtitle}>{children}</h6>;
};
