import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Place } from 'react-tooltip';
import InfoIcon from '@mui/icons-material/Info';

import { Tooltip } from '@/app/_common/_components';

import styles from './info-tooltip.module.scss';

interface InfoTooltipProps {
	id: string;
	description?: string | ReactElement;
	place?: Place;
	className?: string;
}

export const InfoTooltip: FC<InfoTooltipProps> = ({ id, description, place, className = '' }) => {
	return (
		<>
			<span data-tip data-for={id} className={classNames(styles.icon, className)}>
				<InfoIcon />
			</span>
			<Tooltip tooltipId={id} tooltipContent={description} place={place} effect={'solid'} className={styles.tooltip} />
		</>
	);
};
