import { FC, ReactNode, useMemo, useCallback } from 'react';

import { uniqueId } from 'lodash';
import classNames from 'classnames';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ProgressBar } from '@progress/kendo-react-progressbars';

import { Tooltip } from '@/app/_common/_components';
import { isFilterByOperator, isFilterOutOperator } from '@/app/_common/utils/';
import { getValuePercentage, isFilterActive } from '@/app/_common/_components/filter-panel/_components/filter-panel-list/utils';
import { FieldFilterGroup } from '@/app/_common/types';
import { CopyButton } from '@/app/_common/_components/copy-button';
import { FilterButton } from '@/app/_common/_components/filter-panel/_components';

import styles from './filter-panel-list-item.module.scss';

interface FilterPanelListItemProps {
	value: string | number;
	count: number | null;
	total?: number;
	filters: FieldFilterGroup[];
	equalsFilterButtonTooltip?: string;
	notEqualsFilterButtonTooltip?: string;
	valueCell: ReactNode;
	tooltip?: string;
	onEqualsFilter: (value: string | number) => void;
	onNotEqualsFilter: (value: string | number) => void;
}

export const FilterPanelListItem: FC<FilterPanelListItemProps> = ({
	value,
	count,
	total,
	filters,
	tooltip = value,
	equalsFilterButtonTooltip,
	notEqualsFilterButtonTooltip,
	valueCell,
	onEqualsFilter,
	onNotEqualsFilter,
}) => {
	const tooltipId = useMemo(() => uniqueId(), []);
	const countTooltipId = useMemo(() => uniqueId(), []);

	const FilterPanelListItemLabelRender = useCallback(
		() => (
			<div className={styles.progressBarContent}>
				<span className={styles.progressBarValue}>{count}</span>
				<span> ({getValuePercentage(count || 0, total)})</span>
			</div>
		),
		[count, total],
	);

	const isEqualFilterActive = isFilterActive(value, filters, isFilterByOperator);
	const isNotEqualFilterActive = isFilterActive(value, filters, isFilterOutOperator);

	const listItemClassNames = classNames(styles.listItem, {
		[styles.equals]: isEqualFilterActive,
		[styles.notEquals]: isNotEqualFilterActive,
	});

	return (
		<li className={listItemClassNames}>
			<div className={styles.value} data-tip={true} data-for={tooltipId}>
				{valueCell}
			</div>
			<Tooltip tooltipId={tooltipId} tooltipContent={tooltip} className={styles.valueTooltip} offsetTop={5} />
			<CopyButton className={styles.copy} value={String(value)} />
			{count !== null && (
				<div className={styles.count} data-tip={true} data-for={countTooltipId}>
					<ProgressBar className={styles.progressBar} value={count} max={total} labelPlacement="start" label={FilterPanelListItemLabelRender} />
					<Tooltip tooltipId={countTooltipId} tooltipContent={`${count} (${getValuePercentage(count, total)})`} offsetTop={5} />
				</div>
			)}
			<div className={styles.actions}>
				<FilterButton
					value={value}
					onClick={onEqualsFilter}
					active={isEqualFilterActive}
					activeIcon={<AddCircleIcon className={styles.activeEquals} data-testid="active" />}
					notActiveIcon={<AddCircleOutlineIcon data-testid="not-active" />}
					tooltip={equalsFilterButtonTooltip}
					data-testid="add-positive-filter-button"
				/>
				<FilterButton
					value={value}
					onClick={onNotEqualsFilter}
					active={isNotEqualFilterActive}
					activeIcon={<RemoveCircleIcon className={styles.activeNotEquals} data-testid="active" />}
					notActiveIcon={<RemoveCircleOutlineIcon data-testid="not-active" />}
					tooltip={notEqualsFilterButtonTooltip}
					data-testid="add-negative-filter-button"
				/>
			</div>
		</li>
	);
};
