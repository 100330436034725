import { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './box-container.module.scss';

interface BoxContainerProps {
	children: ReactNode;
	icon: JSX.Element;
	containerClassName?: string;
	contentClassName?: string;
}

export const BoxContainer = forwardRef<HTMLDivElement, BoxContainerProps>(({ containerClassName, contentClassName, children, icon }, ref = null) => {
	return (
		<div ref={ref} className={classNames(styles.container, containerClassName)}>
			<div className={styles.icon}>{icon}</div>
			<div className={classNames(styles.content, contentClassName)}>{children}</div>
		</div>
	);
});

BoxContainer.displayName = 'BoxContainer';
