import { MouseEvent, useCallback, useMemo } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import { observer } from 'mobx-react-lite';

import { Checkbox } from '@/app/_common/_components/checkbox';
import { DataCell } from '@/app/_common/_components/data-grid/_components';

interface SelectCellProps extends GridCellProps {
	idPropertyPath: string;
	isRowSelected: (id: string) => boolean;
	toggleSelectedRow: (id: string) => void;
}

// Wrapping with observer ensures proper rerendering!
export const SelectCell = observer(({ dataItem, field, isRowSelected, toggleSelectedRow, idPropertyPath, ...rest }: SelectCellProps) => {
	const disableRightClick = useCallback((event: MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();
		event.stopPropagation();
	}, []);

	const idGetter = useMemo(() => getter(idPropertyPath), [idPropertyPath]);
	const id = idGetter(dataItem);

	const disabled = Boolean(dataItem.disabled);
	const checked = isRowSelected(id);

	const onSelectionChange = useCallback(() => {
		toggleSelectedRow(id);
	}, [id, toggleSelectedRow]);

	return (
		<DataCell {...rest} dataItem={dataItem} field={field} empty={false}>
			<span onContextMenu={disableRightClick}>
				<Checkbox checked={checked} disabled={disabled} onChange={onSelectionChange} />
			</span>
		</DataCell>
	);
});
