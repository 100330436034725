import { Namespaces } from '@/translations/namespaces';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';

import { ExternalLink, MonacoInlineCode } from '@/app/_common/_components';
import { ThemeStore } from '@/app/_common/stores';

import { determineMonacoThemeUponAppTheme } from './_common';

import styles from './query-tips.module.scss';

export const QueryTips = observer(() => {
	const themeStore = useInstance(ThemeStore);
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: 'tabs.advancedQuery.tips' });

	const monacoInlineTheme = determineMonacoThemeUponAppTheme(themeStore.theme);

	return (
		<div className={styles.queryTips} data-testid="query-tips">
			<div className={styles.header}>{t('title')}</div>

			<div className={styles.chapterHeader}>{t('advancedQuery.title')}</div>
			<p>
				{t('advancedQuery.text')}{' '}
				<ExternalLink
					to={process.env.REACT_APP_ADVANCED_QUERY_TIPS_URL || ''}
					text={t('advancedQuery.externalLinkText')}
					isButton={false}
					hasIcon={false}
				/>{' '}
				{t('advancedQuery.textEnding')}
			</p>

			<div className={styles.chapterHeader}>{t('sampleQueries.title')}</div>
			<ul className={styles.sampleQueriesList}>
				<li>
					{t('sampleQueries.listItems.freeText')}
					<MonacoInlineCode theme={monacoInlineTheme}>
						<span className="Table">events</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">search</span>
						<span> </span>
						<span className="StringLiteral">&quot;google.com&quot;</span>
					</MonacoInlineCode>
				</li>
				<li>
					{t('sampleQueries.listItems.findEvents')}
					<MonacoInlineCode theme={monacoInlineTheme}>
						<span className="Table">events</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">where</span>
						<span> </span>
						<span className="Column">src_ip</span>
						<span> </span>
						<span className="MathOperator">==</span>
						<span> </span>
						<span className="StringLiteral">&quot;172.21.33.90&quot;</span>
						<span> </span>
						<span className="MathOperator">and</span>
						<span> </span>
						<span className="Column">dest_ip</span>
						<span> </span>
						<span className="MathOperator">==</span>
						<span> </span>
						<span className="StringLiteral">&quot;172.21.33.137&quot;</span>
					</MonacoInlineCode>
				</li>
				<li>
					{t('sampleQueries.listItems.searchForEventsAndDisplay')}
					<MonacoInlineCode theme={monacoInlineTheme}>
						<span className="Table">events</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">where</span>
						<span> </span>
						<span className="Column">src_ip</span>
						<span> </span>
						<span className="MathOperator">==</span>
						<span> </span>
						<span className="StringLiteral">&quot;172.21.33.90&quot;</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">order</span>
						<span> </span>
						<span className="Keyword">by</span>
						<span> </span>
						<span className="Column">timestamp</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">project</span>
						<span> </span>
						<span className="Column">timestamp</span>
						<span className="Punctuation">,</span>
						<span> </span>
						<span className="Column">action</span>
						<span className="Punctuation">,</span>
						<span> </span>
						<span className="Column">src_ip</span>
						<span className="Punctuation">,</span>
						<span> </span>
						<span className="Column">src_port</span>
						<span className="Punctuation">,</span>
						<span> </span>
						<span className="Column">dest_ip</span>
						<span className="Punctuation">,</span>
						<span> </span>
						<span className="Column">dest_port</span>
					</MonacoInlineCode>
				</li>
				<li>
					{t('sampleQueries.listItems.countNumberOfEvents')}
					<MonacoInlineCode theme={monacoInlineTheme}>
						<span className="Table">events</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">where</span>
						<span> </span>
						<span className="Column">src_ip</span>
						<span> </span>
						<span className="MathOperator">==</span>
						<span> </span>
						<span className="StringLiteral">&quot;172.21.33.90&quot;</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">summarize</span>
						<span> </span>
						<span className="Column">num_events</span>
						<span> </span>
						<span className="Punctuation">=</span>
						<span> </span>
						<span className="Function">count</span>
						<span className="Punctuation">()</span>
						<span> </span>
						<span className="Keyword">by</span>
						<span> </span>
						<span className="Column">action</span>
						<span className="Punctuation">,</span>
						<span> </span>
						<span className="Column">dest_ip</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">order</span>
						<span> </span>
						<span className="Keyword">by</span>
						<span> </span>
						<span className="Column">num_events</span>
					</MonacoInlineCode>
				</li>
				<li>
					{t('sampleQueries.listItems.listTopExternalHosts')}
					<MonacoInlineCode theme={monacoInlineTheme}>
						<span className="Table">events</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">where</span>
						<span> </span>
						<span className="Column">src_ip</span>
						<span> </span>
						<span className="MathOperator">==</span>
						<span> </span>
						<span className="StringLiteral">&quot;172.21.33.90&quot;</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">where</span>
						<span> </span>
						<span className="Function">not</span>
						<span className="Punctuation">(</span>
						<span className="Function">ipv4_is_private</span>
						<span className="Punctuation">(</span>
						<span className="Column">dest_ip</span>
						<span className="Punctuation">))</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">summarize</span>
						<span> </span>
						<span className="Column">num_events</span>
						<span> </span>
						<span className="MathOperator">=</span>
						<span> </span>
						<span className="Function">count</span>
						<span className="Punctuation">()</span>
						<span> </span>
						<span className="Keyword">by</span>
						<span> </span>
						<span className="Column">dest_ip</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">top</span>
						<span> </span>
						<span className="Literal">20</span>
						<span> </span>
						<span className="Keyword">by</span>
						<span> </span>
						<span className="Column">num_events</span>
					</MonacoInlineCode>
				</li>
				<li>
					{t('sampleQueries.listItems.findSubnetEvents')}
					<MonacoInlineCode theme={monacoInlineTheme}>
						<span className="Table">events</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">where</span>
						<span> </span>
						<span className="Function">ipv4_is_in_range</span>
						<span className="Punctuation">(</span>
						<span className="Column">src_ip</span>
						<span className="Punctuation">,</span>
						<span> </span>
						<span className="StringLiteral">&quot;172.21.33.0/24&quot;</span>
						<span className="Punctuation">)</span>
					</MonacoInlineCode>
				</li>
				<li>
					{t('sampleQueries.listItems.findMultipleValues')}
					<MonacoInlineCode theme={monacoInlineTheme}>
						<span className="Table">alerts</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">where</span>
						<span> </span>
						<span className="Column">mitre_tactics</span>
						<span> </span>
						<span className="ScalarOperator">has</span>
						<span> </span>
						<span className="StringLiteral">&apos;Execution&apos;</span>
					</MonacoInlineCode>
					<MonacoInlineCode theme={monacoInlineTheme}>
						<span className="Table">alerts</span>
						<span> </span>
						<span className="Punctuation">|</span>
						<span> </span>
						<span className="QueryOperator">where</span>
						<span> </span>
						<span className="Column">filename_uniq</span>
						<span> </span>
						<span className="ScalarOperator">has</span>
						<span> </span>
						<span className="StringLiteral">&apos;powershell&apos;</span>
					</MonacoInlineCode>
				</li>
			</ul>

			<div className={styles.chapterHeader}>{t('additionalReference.title')}</div>
			<p>
				{t('additionalReference.text')}{' '}
				<ExternalLink
					to={'https://learn.microsoft.com/en-us/azure/data-explorer/kusto/query/'}
					text={t('additionalReference.externalLinkText')}
					isButton={false}
					hasIcon={false}
				/>
				.
			</p>
		</div>
	);
});
