import { useMemo } from 'react';
import classNames from 'classnames';

import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { InfoTooltip } from '@/app/_common/_components';

import styles from './form-select.module.scss';

export const FormSelect = (fieldRenderProps: FieldRenderProps) => {
	const { label, id, valid, disabled, optional, defaultValue = '', values, register, tooltipMessage, name } = fieldRenderProps;
	const options = useMemo(
		() =>
			values.map((value: string) => (
				<option key={value} value={value}>
					{value}
				</option>
			)),
		[values],
	);

	return (
		<FieldWrapper className={styles.formFieldWrapper}>
			{label && (
				<Label
					className={classNames(styles.formLabel, { [styles.disabled]: disabled })}
					editorId={id}
					editorValid={valid}
					editorDisabled={disabled}
					optional={optional}
				>
					{label}
					{tooltipMessage && <InfoTooltip id={name} description={tooltipMessage} />}
				</Label>
			)}
			<div className={'k-form-field-wrap'}>
				<div className={classNames(styles.formSelect, { [styles.invalid]: !valid })}>
					<select className={classNames({ [styles.disabled]: disabled })} defaultValue={defaultValue} id={id} {...register} disabled={disabled}>
						{options}
					</select>
					<span className="focus" />
				</div>
			</div>
		</FieldWrapper>
	);
};
