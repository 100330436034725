import moment from 'moment';

import { EarliestCustomDateStart, EarliestCustomDateStartValues } from '@/app/_common/constants';

interface TimeRangeBoundaries {
	startMin?: Date;
	startMax?: Date;
	endMin?: Date;
	endMax?: Date;
}

export const getTimeRangeBoundaries = (
	start: string | undefined,
	end: string | undefined,
	isMaxNow = false,
	isUtc = false,
	earliestCustomDateStart?: EarliestCustomDateStart,
	diffValue: moment.DurationInputArg1 = 1,
	diffUnit: moment.DurationInputArg2 = 'second',
): TimeRangeBoundaries => {
	const momentNow = isUtc ? moment(moment.utc().format('YYYY-MM-DDTHH:mm:ss')) : moment();
	const nowMaxDate = momentNow.subtract(diffValue, diffUnit).toDate();

	const defaultStartMax = isMaxNow ? nowMaxDate : undefined;
	const startMin = earliestCustomDateStart ? EarliestCustomDateStartValues[earliestCustomDateStart] : undefined;
	const startMax = end ? moment(end).subtract(diffValue, diffUnit).toDate() : defaultStartMax;

	const endMin = start ? moment(start).add(diffValue, diffUnit).toDate() : undefined;
	const endMax = isMaxNow ? nowMaxDate : undefined;

	return {
		startMin,
		startMax,
		endMin,
		endMax,
	};
};
