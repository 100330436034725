import { action, makeObservable } from 'mobx';

import { Mutation, MutationUpdateQueryResultArgs } from '@/generated/graphql';

import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { UpdateQueryResult } from '@/app/_common/graphql/queries';

export class QueryResultsUpdateDataStore extends GraphqlBaseDataStore<Mutation, MutationUpdateQueryResultArgs> {
	constructor() {
		super();

		makeObservable(this, {
			update: action,
		});
	}

	async update(args: Omit<MutationUpdateQueryResultArgs, 'tenantId'>) {
		const variables: MutationUpdateQueryResultArgs = {
			...args,
			tenantId: this.authStore.currentTenantId,
		};

		const response = await this.mutate<Mutation, MutationUpdateQueryResultArgs>({
			mutation: UpdateQueryResult,
			variables,
		});

		return response;
	}
}
