import LZString from 'lz-string';

export const decodeQueryString = (compressedQueryString: string): { [key: string]: string } => {
	const queryString = LZString.decompressFromEncodedURIComponent(compressedQueryString);

	if (!queryString) {
		return {};
	}

	return JSON.parse(queryString);
};
