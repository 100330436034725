import { BOOST_SCORE_ERROR_THRESHOLD } from '@/app/_common/constants';
import ErrorIcon from '@/assets/images/alerts-icon-error.svg';
import InfoIcon from '@/assets/images/alerts-icon-info.svg';
import WarningIcon from '@/assets/images/alerts-icon-warning.svg';

export const getTimelineDetails = (boostScore: string, silenced?: boolean) => {
	if (silenced) {
		return { icon: InfoIcon, order: 0 };
	} else if (Number(boostScore) >= BOOST_SCORE_ERROR_THRESHOLD) {
		return { icon: ErrorIcon, order: 2 };
	}

	return { icon: WarningIcon, order: 1 };
};
