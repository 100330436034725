import { observer } from 'mobx-react-lite';
import { memo } from 'react';

import { GridHeaderCellProps } from '@progress/kendo-react-grid';

import { HeaderSelectCell } from '@/app/_common/_components/data-grid/_components/header-select-cell';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';
import { shallowEqual } from '@/app/_common/utils';

const QueryResultsTableSelectHeaderCellComponent = observer((props: GridHeaderCellProps) => {
	const { selectionHeaderMode } = useQueryResultsTableStore();
	return <HeaderSelectCell mode={selectionHeaderMode} onChange={props.selectionChange} {...props} />;
});

QueryResultsTableSelectHeaderCellComponent.displayName = 'QueryResultsTableSelectHeaderCellComponent';

/*
 * 'columnMenuWrapperProps' leads to rerendering of the header cell component.
 * As this prop does not seem to be used by custom header cells, it is ignored when comparing.
 */
export const QueryResultsTableSelectHeaderCell = memo(
	QueryResultsTableSelectHeaderCellComponent,
	(prevProps: GridHeaderCellProps, nextProps: GridHeaderCellProps) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { columnMenuWrapperProps: prevColumnMenuWrapperProps, ..._prevProps } = prevProps;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { columnMenuWrapperProps: nextColumnMenuWrapperProps, ..._nextProps } = nextProps;

		return shallowEqual(_prevProps, _nextProps);
	},
);

QueryResultsTableSelectHeaderCell.displayName = 'QueryResultsTableSelectHeaderCell';
