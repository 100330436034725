import { Schema, TimeRangeFilterOption, FieldFilterGroup, SchemaColumn } from '@/app/_common/types';
import {
	AVAILABLE_FIELDS_VALUES_COUNT_TABLE,
	AVAILABLE_FIELDS_VALUES_RARE_TABLE,
	AVAILABLE_FIELDS_VALUES_TOP_TABLE,
	AVAILABLE_FIELDS_VALUES_UNIQUE_COUNT_TABLE,
	AdxTables,
	DYNAMIC_COLUMN_TYPE,
} from '@/app/_common/constants';

import {
	DATA_VARIABLE_NAME,
	BASE_DATA_VARIABLE_NAME,
	TOP_DATA_VARIABLE_NAME,
	RARE_DATA_VARIABLE_NAME,
	TOTAL_COUNT_VARIABLE_NAME,
	VALUES_COUNT_VARIABLE_NAME,
} from '@/app/_common/utils/adx/prepare-available-fields-values-query/prepare-available-fields-values-query.constants';
import { getRareQuery } from '@/app/_common/utils/adx/prepare-available-fields-values-query/get-rare-query';
import { getTopQuery } from '@/app/_common/utils/adx/prepare-available-fields-values-query/get-top-query';
import { getBaseDataQuery } from '@/app/_common/utils/adx/prepare-available-fields-values-query/get-base-data-query';
import { getAvailableFieldsBaseQuery } from '@/app/_common/utils/adx/prepare-available-fields-values-query/get-available-fields-base-query';
import { getQueryWithNormalizedProjectOperator } from '../get-query-with-normalized-project-operator';

export const prepareAvailableFieldsValuesQuery = (
	table: AdxTables,
	query: string,
	column: SchemaColumn | undefined,
	schema: Schema,
	database: string,
	timeRange: TimeRangeFilterOption,
	filtersList: FieldFilterGroup[] = [],
): string => {
	if (!column) {
		return '';
	}

	const queryWithNormalizedProjectPart = getQueryWithNormalizedProjectOperator(query, column?.Name);

	const tableQuery = getAvailableFieldsBaseQuery(table, queryWithNormalizedProjectPart, schema, database, timeRange, filtersList);
	const tableQueryVariable = `let ${DATA_VARIABLE_NAME} = ${tableQuery};`;

	const baseQuery = getBaseDataQuery(column);
	const topQuery = getTopQuery(column);
	const rareQuery = getRareQuery(column);
	const countQuery = `let ${TOTAL_COUNT_VARIABLE_NAME} = ${BASE_DATA_VARIABLE_NAME} | summarize count_distinct(${column.Name});`;
	const valuesCountQuery =
		column.CslType === DYNAMIC_COLUMN_TYPE
			? `let ${VALUES_COUNT_VARIABLE_NAME} = ${DATA_VARIABLE_NAME} | project count = array_length(${column.Name}) | summarize sum(['count']);`
			: `let ${VALUES_COUNT_VARIABLE_NAME} = ${DATA_VARIABLE_NAME} | summarize count();`;
	const topName = `${TOP_DATA_VARIABLE_NAME} | as ['${AVAILABLE_FIELDS_VALUES_TOP_TABLE}'];`;
	const rareName = `${RARE_DATA_VARIABLE_NAME} | as ['${AVAILABLE_FIELDS_VALUES_RARE_TABLE}'];`;
	const countName = `${TOTAL_COUNT_VARIABLE_NAME} | as ['${AVAILABLE_FIELDS_VALUES_UNIQUE_COUNT_TABLE}'];`;
	const valuesCountName = `${VALUES_COUNT_VARIABLE_NAME} | as ['${AVAILABLE_FIELDS_VALUES_COUNT_TABLE}'];`;

	return [tableQueryVariable, baseQuery, topQuery, rareQuery, countQuery, valuesCountQuery, topName, rareName, countName, valuesCountName]
		.filter(Boolean)
		.join(`\n`);
};
