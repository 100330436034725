import { gql } from '@apollo/client';

import { MutationsConfig } from '@/app/_features/alerts-actions/_common/types';
import {
	updateCacheAssignAlertsDashboard,
	updateCacheCreateAndAssignAlertsDashboard,
	updateCacheUnassignAlertsDashboard,
	updateCacheDismissAlertsDashboard,
	updateCacheUndismissAlertsDashboard,
} from '@/app/dashboards/alerts-dashboard/_common/utils/cache-updates';

const AlertDashboardAssignAlertsFragment = gql`
	fragment AssignAlertsFragment on Investigation {
		id
		name
		timestamp
		last_updated
		alerts {
			id
			timestamp
		}
	}
`;

const AlertDashboardCreateAndAssignAlertsFragment = gql`
	fragment CreateAndAssignAlertsFragment on Investigation {
		id
		name
		timestamp
		last_updated
		alerts {
			id
			timestamp
		}
	}
`;

const AlertDashboardUnassignAlertsFragment = gql`
	fragment UnassignAlertsFragment on Investigation {
		id
		alerts {
			id
		}
		last_updated
	}
`;

const AlertDashboardDismissAlertsFragment = gql`
	fragment DismissAlertsFragment on Alert {
		id
		investigationSummary {
			id
		}
		state {
			alertState
			requesterUser {
				upn
				id
			}
			timestamp
		}
	}
`;

const AlertsDashboardUndismissAlertsFragment = gql`
	fragment UndismissAlertsFragment on Alert {
		id
		investigationSummary {
			id
		}
		state {
			alertState
			requesterUser {
				upn
				id
			}
			timestamp
		}
	}
`;

export const mutationConfig: MutationsConfig = {
	assignAlerts: {
		fragment: AlertDashboardAssignAlertsFragment,
		updateCache: updateCacheAssignAlertsDashboard,
	},
	createAndAssignAlerts: {
		fragment: AlertDashboardCreateAndAssignAlertsFragment,
		updateCache: updateCacheCreateAndAssignAlertsDashboard,
	},
	unassignAlerts: {
		fragment: AlertDashboardUnassignAlertsFragment,
		updateCache: updateCacheUnassignAlertsDashboard,
	},
	dismissAlerts: {
		fragment: AlertDashboardDismissAlertsFragment,
		updateCache: updateCacheDismissAlertsDashboard,
	},
	undismissAlerts: {
		fragment: AlertsDashboardUndismissAlertsFragment,
		updateCache: updateCacheUndismissAlertsDashboard,
	},
};
