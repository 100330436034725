import { FC, ReactNode, useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { RootPaths } from '@/app/_common/navigation/root-paths';
import { AuthStore, UrlStore } from '@/app/_common/stores';
import { RouterLoader } from '@/app/_common/_components';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

const URL_STATE_VARIABLES_NAME = 'tenantId';

interface PrivateRouteProps {
	access?: boolean;
	children: ReactNode;
}

export const PrivateRoute: FC<PrivateRouteProps> = observer(({ children, access = true }) => {
	const store = useInstance(AuthStore);
	const urlStore = useInstance(UrlStore);
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const isLoggedIn = store.loggedIn;

	useEffect(() => {
		if (!urlStore.tenantId && store.currentTenantId) {
			urlStore.updateStore({ [URL_STATE_VARIABLES_NAME]: store.currentTenantId });
		}
	}, [urlStore.tenantId, urlStore, store.currentTenantId]);

	if (!store.userId && store.pending) {
		return <RouterLoader pathname={typeof pathname === 'string' ? pathname : ''} />;
	}

	if (!access) {
		navigate(RootPaths.PAGE_NOT_ACCESS);
	}

	return isLoggedIn ? <>{children}</> : <Navigate to={RootPaths.LOGIN} state={{ from: pathname }} />;
});
