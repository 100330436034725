import { action, computed, makeObservable, reaction } from 'mobx';

import { InvestigationAlertSummary, Alert } from '@/generated/graphql';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { DataGridDataItem, DataGridSelectedValue } from '@/app/_common/types';
import { AssignedAlertsListViewStore } from '@/app/investigation-details/_common/stores/assigned-alerts-list.view-store';
import { AlertDataStore } from '@/app/investigation-details/_common/stores/alert.data-store';
import { BaseAlertsDetailsViewStore } from '@/app/_common/_components/details-view/stores/base-alerts-details.view-store';
import { DetailsViewItem, DetailsViewTypename } from '@/app/_common/_components/details-view/types';

export class InvestigationAlertDetailsViewStore extends BaseAlertsDetailsViewStore {
	private assignedAlertsListViewStore = injectInterface(this, AssignedAlertsListViewStore);
	private alertDataStore = injectInterface(this, AlertDataStore);

	constructor() {
		super();

		makeObservable(this, {
			isAlertSelected: computed,
			currentAlertIndex: computed,
			selectedAlertIndex: computed,
			selectedAlerts: computed,
			totalAlertsAmount: computed,
			onNextAlert: action,
			onPreviousAlert: action,
		});
	}

	get loading(): boolean {
		return this.isLoading || this.alertDataStore.loading;
	}

	get alertError() {
		return this.alertDataStore.error;
	}

	get selectedAlertIndex(): number {
		return this.selectedAlerts.findIndex((alert) => this.selectedAlert?.id === alert.id);
	}

	get selectedAlerts(): DataGridDataItem<InvestigationAlertSummary>[] {
		return this.assignedAlertsListViewStore.data.filter((alert) => this.assignedAlertsListViewStore.isRowSelected(alert.id));
	}

	get selectedItemTypename(): DetailsViewTypename {
		return DetailsViewTypename.INVESTIGATION_ALERT_SUMMARY;
	}

	get isAlertSelected(): DataGridSelectedValue {
		return this.selectedAlert ? this.assignedAlertsListViewStore.selectedRows[this.selectedAlert.id] : false;
	}

	get currentAlertIndex(): number {
		if (this.isAlertSelected) {
			return this.selectedAlerts.findIndex((alert) => alert.id === this.selectedAlert?.id) + 1;
		}
		return this.assignedAlertsListViewStore.data.findIndex((alert) => alert.id === this.selectedAlert?.id) + 1;
	}

	get totalAlertsAmount(): number {
		if (this.isAlertSelected) {
			return this.selectedAlerts.length;
		}
		return this.assignedAlertsListViewStore.totalCount;
	}

	get selectedAlert(): DetailsViewItem | undefined {
		const alertDataStoreAlertData: Alert = this.alertDataStore.alert;

		if (!this.state.selectedAlertId || !alertDataStoreAlertData) {
			return undefined;
		}

		const assignedAlertData: DataGridDataItem<InvestigationAlertSummary> | undefined = this.assignedAlertsListViewStore.getAlertById(
			this.state.selectedAlertId,
		);

		return {
			...(assignedAlertData ?? {}),
			...alertDataStoreAlertData,
		} as DetailsViewItem;
	}

	retryRead = (): void => {
		if (!this.selectedAlertId) {
			return;
		}

		this.clearDataObject();
		this.clearIocEventsDataObjects();

		this.alertDataStore.read(this.selectedAlertId);
	};

	onNextAlert = () => {
		if (this.selectedAlertIndex + 1 > this.totalAlertsAmount) {
			return;
		}

		const newSelectedAlertId = this.selectedAlerts[this.selectedAlertIndex + 1].id;

		this.setSelectedAlertId(newSelectedAlertId);
	};

	onPreviousAlert = () => {
		if (this.selectedAlertIndex - 1 < 0) {
			return;
		}

		const newSelectedAlertId = this.selectedAlerts[this.selectedAlertIndex - 1].id;

		this.setSelectedAlertId(newSelectedAlertId);
	};

	selectedAlertQueryDisposer = reaction(
		() => this.selectedAlertId,
		(alertId) => {
			if (alertId) {
				this.setStoredDataObject(undefined);
				this.clearIocEventsDataObjects();
				return this.alertDataStore.read(alertId);
			}
		},
	);

	dispose() {
		this.selectedAlertQueryDisposer();
	}
}
