import classNames from 'classnames';
import React, { FC } from 'react';

import { CopyButton } from '@/app/_common/_components';

import styles from './label-value.module.scss';

interface LabelValueProps {
	label: string;
	value?: string | null | React.ReactNode;
	className?: string;
	canCopy?: boolean;
}

export const LabelValue: FC<LabelValueProps> = ({ label, value, className, canCopy }) => {
	const displayedValue = value ?? '-';

	return (
		<div className={classNames(styles.labelValue, className)}>
			<span className={styles.label}>{label}</span>
			<span className={styles.value}>
				{displayedValue}
				{canCopy && <CopyButton value={String(displayedValue)} className={styles.copyIcon} />}
			</span>
		</div>
	);
};
