import classNames from 'classnames';

import { DataCell } from '@/app/_common/_components/data-grid/_components';
import { SingleItemCellProps } from '../../types';
import { getMitreAttackBadgeText } from '@/app/_common/_components/data-grid/utils';

export const MitreTechniqueSingleItemCell = (props: SingleItemCellProps) => {
	const { value, isCellHighlighted } = props;
	const mitreText = getMitreAttackBadgeText(String(value));

	return (
		<DataCell {...props} title={mitreText} className={classNames({ isCellHighlighted })}>
			{mitreText}
		</DataCell>
	);
};
