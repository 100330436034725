import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useInstance } from 'react-ioc';

import { MitreAttackBadge } from '@/app/_common/_components/mitre-attack-badge/mitre-attack-badge';
import { Namespaces } from '@/translations/namespaces';
import { SummarySectionItemTitle } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-section-item-title';
import { MitreAttackTreeBox } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components';
import { MitreAttackTactic } from '@/app/_common/_components/mitre-attack-tree/types';
import { TreeStyle } from '@/app/_common/_components/mitre-attack-tree/constants';
import { prepareMitreAttackTreeTactic } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_common/utils/prepare-mitre-attack-tree-tactics/prepare-mitre-attack-tree-tactic';
import { AlertsDetailsNavigationViewStore, getAlertVendorKey } from '@/app/_common/_components/details-view/alerts-details-view-navigation';
import { CROWDSTRIKE_EVIDENCE_CATEGORY_CORRELATED_ALERT_VALUE } from '@/app/_common/constants';
import { AlertVendorKey } from '@/app/_common/_components/details-view/types';
import { getMergedTacticsAndTechniques } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_common/utils/getMergedTacticsAndTechniques';

import styles from './mitre-attack-box.module.scss';

interface MitreAttackBoxProps {
	text?: string;
	className?: string;
	alertEventSource?: string;
}

export const MitreAttackBox: FC<MitreAttackBoxProps> = ({ text, alertEventSource, className }) => {
	const { t } = useTranslation(Namespaces.DetailsView);
	const alertDetailsNavigationStore = useInstance(AlertsDetailsNavigationViewStore);
	const { evidenceApiDetails } = alertDetailsNavigationStore;

	const vendorKey = getAlertVendorKey(alertEventSource);
	const isCrowdStrikeAlert = vendorKey === AlertVendorKey.CROWDSTRIKE_FALCON;

	const tacticsAndTechniques = useMemo(() => {
		const evidenceList = Array.isArray(evidenceApiDetails) ? evidenceApiDetails : [];
		return evidenceList
			.filter((evidence) => {
				return evidence?.evidence_category === CROWDSTRIKE_EVIDENCE_CATEGORY_CORRELATED_ALERT_VALUE;
			})
			.map((evidence) => prepareMitreAttackTreeTactic(evidence?.mitre_tactics, evidence?.mitre_techniques))
			.filter((tactic) => tactic);
	}, [evidenceApiDetails]);

	const mitreAttackTactics: MitreAttackTactic[] = getMergedTacticsAndTechniques(tacticsAndTechniques as MitreAttackTactic[]);

	if (!text && !isCrowdStrikeAlert) {
		return null;
	}

	if (isCrowdStrikeAlert) {
		return (
			<div className={classNames(styles.container, className)}>
				<MitreAttackTreeBox tactics={mitreAttackTactics} treeStyle={TreeStyle.REGULAR} />
			</div>
		);
	}

	return (
		<div className={classNames(styles.container, className)}>
			<SummarySectionItemTitle className={styles.title} title={t('summary.mitreAttackBox')} />
			{text && <MitreAttackBadge text={text} />}
		</div>
	);
};
