import { FC, ReactNode, useEffect } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { ThemeStore } from '@/app/_common/stores';

interface ThemeProps {
	children: ReactNode;
}

export const Theme: FC<ThemeProps> = observer(({ children }) => {
	const themeStore = useInstance(ThemeStore);

	useEffect(() => {
		document.body.setAttribute('data-theme', themeStore.theme);
	}, [themeStore.theme]);

	return <div>{children}</div>;
});
