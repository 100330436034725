import { makeAutoObservable } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';
import { Themes } from '@/app/_common/types';

export class ThemeStore {
	private state: State = {
		theme: Themes.Light,
	};

	constructor() {
		makeAutoObservable(this);
		makePersistable(this.state, {
			name: 'ThemeStore',
			properties: ['theme'],
			storage: window.localStorage,
		});
	}

	get theme() {
		return this.state.theme;
	}

	get isDarkTheme() {
		return this.state.theme === Themes.Dark;
	}

	setTheme(value: ThemeValue) {
		this.state.theme = value;
	}

	toggle() {
		this.setTheme(this.theme === Themes.Dark ? Themes.Light : Themes.Dark);
	}

	dispose() {
		stopPersisting(this.state);
	}
}

export type ThemeValue = Themes.Dark | Themes.Light;

interface State {
	theme: ThemeValue;
}
