import { FC, useMemo } from 'react';
import classNames from 'classnames';

import { HighlightedTextClassNames } from './constants';
import { isSearchStringEqual } from '@/app/_common/utils';
import { separateSearchTerm } from './utils';

import styles from './highlighted-text.module.scss';

interface HighlightTextInCellProps {
	value: string | number;
	searchLine?: string;
	className?: HighlightedTextClassNames;
}

const DEFAULT_HIGHLIGHTED_TEXT_CLASS = HighlightedTextClassNames.HighlightedText;

export const HighlightedText: FC<HighlightTextInCellProps> = ({ value, searchLine = '', className = DEFAULT_HIGHLIGHTED_TEXT_CLASS }) => {
	const valueSlices = useMemo(() => separateSearchTerm(value, searchLine), [searchLine, value]);

	if (!searchLine) {
		return <>{value}</>;
	}

	const highlightClassName = styles[className] || DEFAULT_HIGHLIGHTED_TEXT_CLASS;
	return (
		<>
			{valueSlices.map(({ id, substr }) => {
				const isMatch = isSearchStringEqual(substr, searchLine);
				return (
					<span key={id} className={classNames({ [highlightClassName]: isMatch })}>
						{substr}
					</span>
				);
			})}
		</>
	);
};
