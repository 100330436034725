import { FC, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './summary-tag.module.scss';

interface SummaryTagProps {
	children: ReactNode;
	className?: string;
}

export const SummaryTag: FC<SummaryTagProps> = ({ children, className }) => {
	return <div className={classNames(styles.summary, className)}>{children}</div>;
};
