import { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AVAILABLE_FIELDS_BLOCK_NAME, AvailableField, NotificationMessage } from '@/app/_common/types';
import { AdvancedQueryPageKeyPrefix } from '@/app/_common/constants';
import { ErrorDetailsContent, ErrorDetailsIcon } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';

import { FieldsList } from './_components';

import styles from './available-fields-list.module.scss';

interface AvailableFieldsListProps {
	blockName: AVAILABLE_FIELDS_BLOCK_NAME;
	header: string;
	fields: AvailableField[];
	isExpanded: boolean;
	toggleExpandedState?: () => void;
	loading?: boolean;
	loaders?: number;
	error?: NotificationMessage;
	'data-testid'?: string;
}

export const AvailableFieldsList: FC<AvailableFieldsListProps> = ({
	blockName,
	header,
	fields,
	loading,
	loaders = 2,
	error,
	isExpanded,
	toggleExpandedState,
	'data-testid': dataTestId,
}) => {
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });
	const ExpandCollapseIcon = isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />;
	const ExpandCollapseComponent = !toggleExpandedState ? null : ExpandCollapseIcon;
	const hasData = Boolean(fields?.length);
	const hasError = Boolean(error);
	const EmptyDataComponent = <span className={styles.noDataText}>{t('availableFields.emptyData')}</span>;
	const HeaderToggler = !hasData ? EmptyDataComponent : ExpandCollapseComponent;
	const ErrorIcon = <ErrorDetailsIcon className={styles.errorIcon} />;

	return (
		<div className={styles.container} data-testid={dataTestId}>
			<div
				className={classNames(styles.header, { notClickable: !hasData || !toggleExpandedState })}
				onClick={toggleExpandedState}
				onKeyDown={toggleExpandedState}
			>
				<span>{header}</span>
				{hasError ? ErrorIcon : HeaderToggler}
			</div>

			{error && (
				<ErrorDetailsContent
					buttonTitle={t('error.details')}
					className={classNames(styles.error, { [styles.errorWithIcon]: hasError })}
					error={error.content}
				/>
			)}

			{!error && isExpanded && <FieldsList loaders={loaders} fields={fields} loading={Boolean(loading)} blockName={blockName} />}
		</div>
	);
};
