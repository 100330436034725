import { IOCResponseFile } from '@/app/_common/types';
import { FetchBaseDataStore } from '@/app/_common/fetch/fetch-base.data-store';
import { DataObject } from '@/generated/graphql';
import { AxiosRequestConfig } from 'axios';

export class AlertDetailsIocEventsDataStore extends FetchBaseDataStore<IOCResponseFile[]> {
	constructor() {
		super();
	}

	get iocData() {
		return this.data;
	}

	async read(dataObjects: DataObject[]) {
		if (!dataObjects) {
			return;
		}

		const alertDetailsEndpoint = process.env.REACT_APP_FILE_ENDPOINT;

		const configs = dataObjects
			.map((dataObject) => {
				const { container, key } = dataObject.data_path;

				if (container && key) {
					return {
						params: {
							containerName: container,
							key,
						},
					};
				}
			})
			.filter((config) => config !== undefined);

		await this.fetchApiMultipleByAxios(alertDetailsEndpoint as string, configs as AxiosRequestConfig[]);
	}
}
