import { observer } from 'mobx-react-lite';

import { sortAlertFilters } from '@/app/_common/utils';
import { AlertsDataGridViewStore } from '@/app/_features/alerts-actions/_common/stores';
import { AlertsDataGridViewStore as AlertsDataGridOldViewStore } from '@/app/_common/_components/alerts-action-dialog/_common/stores/alerts-data-grid.view-store';
import { DataHeaderCell, DataHeaderCellProps } from '@/app/_common/_components/data-grid/_components';

// Passing store as an props is an temporary solution to workaround code duplication.
// Shall be replaced by direct usage of store instance when we switch to this component in all places.
interface AlertsDataGridProps extends DataHeaderCellProps {
	store: AlertsDataGridViewStore | AlertsDataGridOldViewStore;
	propertyPath: string;
}

export const AlertsDataGridHeaderCell = observer(({ store, propertyPath, ...props }: AlertsDataGridProps) => {
	return (
		<DataHeaderCell
			filterOptions={store.getFilterOptions(propertyPath)}
			counter={store.getCounter(propertyPath)}
			sortable={true}
			sort={store.sort}
			onSort={store.setSort}
			onFilter={store.setGridHeaderFilter}
			filter={store.getGridHeaderFilterValues(propertyPath)}
			clearFilter={store.resetGridHeaderFilter}
			sortFilterOptions={sortAlertFilters}
			{...props}
		/>
	);
});
