import { FC } from 'react';
import classNames from 'classnames';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import styles from './header-alert-actions-icon.module.scss';

interface HeaderAlertActionsIconProps {
	className?: string;
	active?: boolean;
}

export const HeaderAlertActionsIcon: FC<HeaderAlertActionsIconProps> = ({ active, className }) => {
	return <MoreVertIcon className={classNames(styles.icon, className, { [styles.active]: active })} />;
};
