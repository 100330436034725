import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { makePersistable, stopPersisting, clearPersistedStore } from 'mobx-persist-store';
import { BroadcastChannel, OnMessageHandler } from 'broadcast-channel';

import { AuthDetailsTokens, Franchise, Tenant, User, UserTenantInfo } from '@/app/_common/interfaces/auth-details';
import {
	getUniqueTenantsNames,
	verifyToken,
	verifyCode,
	refreshTokens,
	getEncodedLogoutRedirectURL,
	migrateToNewestVersion,
	determineUserRole,
} from '@/app/_common/utils';
import { AUTH_BROADCAST_CHANNEL_NAME, AUTH_BROADCAST_CHANNEL_MESSAGE, AUTH_DETAILS_VERSION, UserRoles } from '@/app/_common/constants';

export enum Status {
	PENDING = 'pending',
	SUCCESS = 'success',
	ERROR = 'error',
}

const AUTH_DETAILS_KEY = 'authDetails';
const INITIAL_DUMMY_TENANT: UserTenantInfo = {
	id: '',
	name: '',
	role: '',
	isBillingUser: false,
	franchiseId: '',
	invitedBy: '',
	invitedTimestamp: '',
	hasFreshdeskCompanyAssigned: false,
};
const INITIAL_DUMMY_FRANCHISE: Franchise = { id: '', isOwner: false, name: '' };
interface State {
	tokens: AuthDetailsTokens;
	user: User;
	currentTenantId: string;
	status: Status;
	flow: string;
	logOut: boolean;
	version: string;
	franchiseTenants: Tenant[];
}

const initialState: State = {
	tokens: {
		id_token: '',
		id_token_expires_in: 0,
		refresh_token: '',
		access_token: '',
		expires_on: '',
		refresh_token_expires_in: '',
	},
	user: {
		id: '',
		tenants: [INITIAL_DUMMY_TENANT],
		upn: '',
		displayName: '',
		firstName: '',
		lastName: '',
		isTenantUser: true,
		franchise: INITIAL_DUMMY_FRANCHISE,
	},
	currentTenantId: '',
	status: Status.PENDING,
	flow: '',
	logOut: false,
	version: AUTH_DETAILS_VERSION,
	franchiseTenants: [],
};

export class AuthStore {
	private state: State = initialState;
	public broadcastChannel = new BroadcastChannel<string>(AUTH_BROADCAST_CHANNEL_NAME);

	constructor() {
		makeObservable(
			this,
			{
				// @ts-ignore - for protected/private fields
				state: observable,
				loggedIn: computed,
				user: computed,
				userId: computed,
				franchise: computed,
				isCurrentUserTenantOwner: computed,
				tenants: computed,
				uniqueTenants: computed,
				pending: computed,
				success: computed,
				token: computed,
				expiresOn: computed,
				isFranchiseUser: computed,
				setInitialState: action,
				setCurrentTenantId: action,
				cleanUpState: action,
				verifyToken: action,
				replaceTokens: action,
				logout: action,
				expireToken: action,
				setFranchiseTenants: action,
			},
			{ autoBind: true },
		);

		migrateToNewestVersion(AUTH_DETAILS_KEY, AUTH_DETAILS_VERSION);

		makePersistable(
			this.state,
			{
				name: AUTH_DETAILS_KEY,
				properties: ['tokens', 'user', 'flow', 'version', 'currentTenantId'],
				storage: window.localStorage,
			},
			{ fireImmediately: true },
		).then(() => {
			this.state.status = Status.SUCCESS;
		});
	}

	get user(): User {
		return this.state.user;
	}

	get userRole(): UserRoles {
		return determineUserRole(this.user, this.currentTenantId);
	}

	get franchise(): Franchise | undefined {
		return this.state.user.franchise;
	}

	get userId(): string {
		return this.state.user?.id || '';
	}

	get userEmail(): string {
		return this.state.user.upn;
	}

	get loggedIn() {
		return Boolean(this.token) && Boolean(this.expiresOn) && Number(this.expiresOn) * 1000 > Date.now() && Boolean(this.user.id);
	}

	get currentTenant(): Tenant {
		return this.uniqueTenants.find(({ id }) => this.state.currentTenantId === id) || INITIAL_DUMMY_TENANT;
	}

	get currentTenantName(): string {
		return this.currentTenant?.name || '';
	}

	get currentTenantId(): string {
		return this.state.currentTenantId;
	}

	get isCurrentUserTenantOwner(): boolean {
		return this.userRole === UserRoles.TENANT_OWNER;
	}

	get tenants(): Tenant[] {
		return [...(this.state.user.tenants ?? []), ...(this.state.franchiseTenants ?? [])];
	}

	get uniqueTenants(): Tenant[] {
		return getUniqueTenantsNames(this.tenants ?? []);
	}

	get pending() {
		return this.state.status === Status.PENDING;
	}

	get success() {
		return this.state.status === Status.SUCCESS;
	}

	get token() {
		return this.state.tokens.id_token;
	}

	get idTokenExpiresIn() {
		return this.state.tokens.id_token_expires_in;
	}

	get expiresOn() {
		return this.state.tokens.expires_on;
	}

	get flow() {
		return this.state.flow;
	}

	get logOut() {
		return this.state.logOut;
	}

	get isFranchiseUser(): boolean {
		return !this.user.isTenantUser;
	}

	get franchiseId(): string {
		return this.user?.franchise.id;
	}

	get franchiseName(): string {
		return this.user?.franchise.name;
	}

	get isFranchiseOwner(): boolean {
		return this.user.franchise.isOwner;
	}

	setInitialState() {
		this.state = initialState;
		this.state.logOut = true;
	}

	setCurrentTenantId(id: string) {
		this.state.currentTenantId = id;
	}

	setNewTokens() {
		this.state = JSON.parse(localStorage.getItem(AUTH_DETAILS_KEY) || '') as State;
	}

	cleanUpState() {
		clearPersistedStore(this.state);
		stopPersisting(this.state);
		this.clearAuthDetailsFromLocalStorage();
		window.sessionStorage.clear();
	}

	logout() {
		this.cleanUpState();
		this.state.logOut = true;
		window.location.href = getEncodedLogoutRedirectURL();
		this.broadcastChannel.postMessage(AUTH_BROADCAST_CHANNEL_MESSAGE.LogoutMessage);
	}

	expireToken() {
		this.cleanUpState();
		this.state.logOut = true;
		window.location.href = getEncodedLogoutRedirectURL();
		this.broadcastChannel.postMessage(AUTH_BROADCAST_CHANNEL_MESSAGE.ExpiredToken);
	}

	async verifyToken(token: string, code: string) {
		this.clearAuthDetailsFromLocalStorage();

		try {
			this.state.status = Status.PENDING;
			const authDetails = await verifyToken(token);
			const { tfp } = JSON.parse(atob(token.split('.')[1]));
			this.state.flow = tfp;
			const tokenDetails = await verifyCode(code, tfp);
			const authData = { ...authDetails, tokens: tokenDetails, flow: tfp };

			runInAction(() => {
				Object.assign(this.state, authData);
				if (!this.currentTenantId) {
					this.setCurrentTenantId(authDetails.user?.tenants?.[0]?.id ?? '');
				}

				this.state.status = Status.SUCCESS;
			});
		} catch (error) {
			this.state.status = Status.ERROR;
		}
	}

	async replaceTokens() {
		try {
			this.state.status = Status.PENDING;

			const tokenDetails = await refreshTokens(this.state.tokens.refresh_token, this.state.flow);
			const currentFranchise: Franchise = { id: this.franchiseId, name: this.franchiseName, isOwner: this.isFranchiseOwner };
			const authData: State = { ...this.state, tokens: tokenDetails, user: { ...this.state.user, franchise: currentFranchise } };

			runInAction(() => {
				// "Object.assign" is used only when whole state is replaced, eg. when getting token data from B2C.
				Object.assign(this.state, authData);
			});

			this.broadcastChannel.postMessage(AUTH_BROADCAST_CHANNEL_MESSAGE.ReplaceTokens);
		} catch (error) {
			this.state.status = Status.ERROR;
		}
	}

	setFranchiseTenants(franchiseTenants: Tenant[]): void {
		this.state.franchiseTenants = franchiseTenants;
	}

	assignBroadcastChannelHandler(handler: OnMessageHandler<string>) {
		this.broadcastChannel.onmessage = handler;
	}

	dispose() {
		stopPersisting(this.state);
		this.broadcastChannel.close();
	}

	private handleStorageError = (error: unknown) => {
		// eslint-disable-next-line no-console
		console.error('Error occurred during storage operation', error);
	};

	private clearAuthDetailsFromLocalStorage() {
		window.localStorage.removeItem(AUTH_DETAILS_KEY);
	}
}
