import { ReactNode } from 'react';

import styles from './context-menu-title.module.scss';

interface ContextMenuTitleProps {
	children: ReactNode;
}

export const ContextMenuTitle = ({ children }: ContextMenuTitleProps) => {
	return <h5 className={styles.contextMenuTitle}>{children}</h5>;
};
