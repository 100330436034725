import { ArtifactType } from '@/generated/graphql';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

export const artifactTypeToString = new Map<string, string>([
	[ArtifactType.Investigation, i18n.t('note.types.investigation', { ns: Namespaces.Notes })],
]);

export const MAX_LENGTH_OF_TEXT = 107;

export const NOTE_DIALOG_WIDTH = 900;

export const URL_STATE_SHOW_NOTES_PANEL = 'notesPanel';
export const URL_STATE_NOTE_ID = 'noteId';

export const LIST_NOTES_QUERY_NAME = 'listNotes';
