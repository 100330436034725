import { action, makeObservable } from 'mobx';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { AuthStore } from '@/app/_common/stores';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { FetchBaseDataStore } from '@/app/_common/fetch';
import { ChartData, ChartDataItem, DataTable, FetchBaseErrorKey, StackedChartDataItem } from '@/app/_common/types';
import { AdxEndpoints } from '@/app/_common/constants';
import { formatQueryResults } from '@/app/advanced-query/_common/utils';

const API_URL = process.env.REACT_APP_ADVANCED_QUERY_URL;
const ERROR_KEY = FetchBaseErrorKey.AdvancedQueryChart;

export class AdvancedQueryChartDataStore extends FetchBaseDataStore<ChartData> {
	private cancelSource?: CancelTokenSource;
	private authStore = injectInterface({}, AuthStore);

	constructor() {
		super(ERROR_KEY);

		makeObservable(this, {
			fetchChartData: action,
			cancelRequest: action,
		});
	}

	public fetchChartData = (query: string) => {
		this.cancelRequest();
		this.cancelSource = axios.CancelToken.source();

		const config: AxiosRequestConfig = {
			method: 'POST',
			url: `${API_URL}/${AdxEndpoints.QueryV2}`,
			data: { tenantId: this.authStore.currentTenantId, query },
			cancelToken: this.cancelSource.token,
			transformResponse: [this.formatResults],
			params: { scope: 'chart' },
		};

		this.fetchApiByAxios('', config);
	};

	private formatResults = (response: string): ChartData => {
		try {
			const parsedResponse: DataTable[] = JSON.parse(response);
			const dataTable: DataTable = parsedResponse[0];

			return formatQueryResults<ChartDataItem | StackedChartDataItem>(dataTable);
		} catch (error) {
			return [];
		}
	};

	public cancelRequest = () => {
		this.cancelSource?.cancel();
	};

	dispose = () => {
		this.cancelRequest();
	};
}
