import { Loader, LoaderSize } from '@progress/kendo-react-indicators';
import classNames from 'classnames';
import { FC } from 'react';

import styles from './loading-spinner.module.scss';

interface LoadingSpinnerProps {
	className?: string;
	loaderClassName?: string;
	size?: LoaderSize;
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ className, loaderClassName, size = 'large' }) => {
	return (
		<div className={classNames(styles.loadingSpinnerContainer, className)}>
			<Loader type="converging-spinner" size={size} className={loaderClassName} />
		</div>
	);
};
