import { ReactNode } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { Popup, Collision, Align } from '@progress/kendo-react-popup';

import { AvailableFieldsValuesViewStore } from '@/app/advanced-query/_common/stores';

import styles from './available-fields-list-item-popup.module.scss';

const ANCHOR_ALIGN: Align = { horizontal: 'right', vertical: 'center' };
const POPUP_ALIGN: Align = { horizontal: 'left', vertical: 'center' };

const COLLISION: Collision = {
	horizontal: 'fit',
	vertical: 'fit',
};

export interface AvailableFieldsListItemPopupProps {
	children: ReactNode;
	isOpen: boolean;
	anchor: HTMLLIElement | null;
}

export const AvailableFieldsListItemPopup = observer(({ children, isOpen, anchor }: AvailableFieldsListItemPopupProps) => {
	const store = useInstance(AvailableFieldsValuesViewStore);

	return (
		<Popup
			className={styles.popup}
			anchor={anchor}
			show={isOpen && Boolean(anchor)}
			popupClass={styles.popup}
			anchorAlign={ANCHOR_ALIGN}
			popupAlign={POPUP_ALIGN}
			animate={false}
			collision={COLLISION}
			contentKey={store.loading}
		>
			{children}
		</Popup>
	);
});
