import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { ActionsCell, ActionsItem, ActionsItemRenderComponentData } from '@/app/_common/_components/data-grid/_components';
import {
	QueryResultsTableItemExportToCSVButton,
	useQueryResultsTableStore,
} from '@/app/_common/_components/query-results/query-results-table/components';
import { QueryResultsTableCellWithStoreProps } from '@/app/_common/_components/query-results/query-results-table/types';

interface ActionComponent {
	renderComponent?: (props: ActionsItemRenderComponentData) => JSX.Element;
	id?: string;
	label?: string;
}

type ActionComponents = {
	[ACTIONS.EXPORT_TO_CSV_BUTTON]: ActionComponent;
};

enum ACTIONS {
	EXPORT_TO_CSV_BUTTON = 'exportToCSVButton',
}

export const QueryResultsTableActionsCell = observer((props: QueryResultsTableCellWithStoreProps) => {
	const { resultsColumnNamesForCsvExport } = useQueryResultsTableStore();

	const actionComponents: ActionComponents = useMemo(
		() => ({
			[ACTIONS.EXPORT_TO_CSV_BUTTON]: {
				renderComponent: (props) => <QueryResultsTableItemExportToCSVButton data={[props.dataItem]} headers={resultsColumnNamesForCsvExport} />,
			},
		}),
		[resultsColumnNamesForCsvExport],
	);

	const rowActions = useMemo(() => {
		return Object.values(ACTIONS).map((id) => actionComponents[id]);
	}, [actionComponents]);

	return <ActionsCell {...props} items={rowActions as ActionsItem[]} />;
});

QueryResultsTableActionsCell.displayName = 'QueryResultsTableActionsCell';
