import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';

import { CheckCircle, RemoveCircleOutline } from '@mui/icons-material';

import { Namespaces } from '@/translations/namespaces';

import { LoadingSpinner, Tooltip } from '@/app/_common/_components';
import { RootPaths } from '@/app/_common/navigation';
import { AlertStateInfoDetails } from '@/app/_common/types';
import { AlertStateOptions } from '@/app/_common/constants';
import { SummarySectionItemTitle } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-section-item-title';

import styles from './alert-state-info.module.scss';

interface AlertStateInfoProps {
	className?: string;
	loadingAssignedInvestigation: boolean;
	alertStateInfoDetails: AlertStateInfoDetails;
}

export const AlertStateInfo: FC<AlertStateInfoProps> = ({ className, alertStateInfoDetails, loadingAssignedInvestigation }) => {
	const { t } = useTranslation();
	const { search } = useLocation();
	const { state, label, tooltipInfo, investigationId } = alertStateInfoDetails;

	return (
		<div className={classNames(styles.container, className)}>
			<SummarySectionItemTitle title={t('summary.state', { ns: Namespaces.DetailsView })} />
			{loadingAssignedInvestigation && <LoadingSpinner size={'small'} />}
			<p className={styles.stateInfo} data-tip="Alert State" data-for="alertState">
				{!loadingAssignedInvestigation && state === AlertStateOptions.Assigned && (
					<>
						<CheckCircle htmlColor="var(--assignedColor)" className={styles.icon} />
						{!investigationId && <span>{label}</span>}
						{investigationId && (
							<Link className={styles.link} to={{ pathname: `${RootPaths.INVESTIGATIONS}/${investigationId}`, search }}>
								<span className={styles.stateInfo}>{label}</span>
							</Link>
						)}
						<Tooltip tooltipId={'alertState'} tooltipContent={tooltipInfo} />
					</>
				)}

				{!loadingAssignedInvestigation && state === AlertStateOptions.Dismissed && (
					<>
						<RemoveCircleOutline htmlColor="var(--dismissedColor)" className={styles.icon} />
						<span>{label}</span>
						<Tooltip tooltipId={'alertState'} tooltipContent={tooltipInfo} />
					</>
				)}

				{!loadingAssignedInvestigation && state === AlertStateOptions.Unassigned && (
					<>
						<RemoveCircleOutline htmlColor="var(--unassignedColor)" className={styles.icon} />
						<span>{label}</span>
					</>
				)}

				{!loadingAssignedInvestigation && state === AlertStateOptions.Unknown && <span>{label}</span>}
			</p>
		</div>
	);
};
