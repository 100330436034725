import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import { Signature } from '@/app/_common/constants';

import { AlertsDetailsNavigationViewStore } from '@/app/_common/_components/details-view/stores/alerts-details-navigation.view-store';

import { BoostedAlert } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-main-content/boost-alert/boost-alert';
import { ThreatIntelligenceDescription, ThreatIntelligenceMatches } from '@/app/_common/types';

interface BoostAlertListProps {
	threatIntelligenceMatches: ThreatIntelligenceMatches;
	threatIntelligenceDescriptions: ThreatIntelligenceDescription[];
	signatures?: Signature[];
}

export const BoostAlertList: FC<BoostAlertListProps> = observer(({ signatures, threatIntelligenceMatches, threatIntelligenceDescriptions }) => {
	const store = useInstance(AlertsDetailsNavigationViewStore);

	return (
		<>
			{signatures?.map((signature: Signature, index: number) => (
				<BoostedAlert
					{...signature}
					key={signature.key}
					id={signature.key}
					enumerate={index + 1}
					activeBoostedAlert={store.activeBoostedAlert}
					threatIntelligenceMatches={threatIntelligenceMatches}
					threatIntelligenceDescriptions={threatIntelligenceDescriptions}
				/>
			))}
		</>
	);
});
