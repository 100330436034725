import { LabelValueRowValue } from '@/app/_common/_components/label-value-row/_common/types';

import styles from './value-pre.module.scss';

interface ValuePreProps {
	value?: LabelValueRowValue;
}

export const ValuePre = ({ value }: ValuePreProps) => {
	return (
		<span className={styles.valuePre} data-testid="value">
			{value}
		</span>
	);
};
