import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import classNames from 'classnames';

import { Namespaces } from '@/translations/namespaces';
import { TabNavigation } from '@/app/_common/_components';
import { AlertsDetailsNavigationViewStore } from '@/app/_common/_components/details-view/stores/alerts-details-navigation.view-store';
import { Tab } from '@/app/_common/_components/tab-navigation/_common/types';
import { SummarySection } from './_components/summary-section/summary-section';
import { DetailsViewNavigationProps } from '@/app/_common/_components/details-view/types';
import { AlertsDetailsLoadingIndicator } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components';
import { DetailsViewNavigationContainer } from '@/app/_common/_components/details-view/details-view-navigation-container';

import styles from './alerts-details-view-navigation.module.scss';

export const AlertsDetailsViewNavigation = observer(
	({
		selectedItemSeverity,
		selectedItemConfidence,
		selectedItemDescription,
		selectedItemRecommendation,
		mitreCategory,
		loadingAssignedInvestigation,
		itemStateInfoDetails,
		hasError,
		loading,
		retryRead,
		threatIntelligenceMatches,
		threatIntelligenceDescriptions,
		selectedItem,
	}: DetailsViewNavigationProps) => {
		const store = useInstance(AlertsDetailsNavigationViewStore);

		const { t } = useTranslation([Namespaces.DetailsView]);

		const showLoadingIndicator = hasError || loading;

		const tabs: Tab[] = useMemo(
			() => [
				{
					title: t('tabs.summary'),

					content: showLoadingIndicator ? (
						<AlertsDetailsLoadingIndicator loading={loading} hasError={hasError} hasSelectedAlert={Boolean(selectedItem)} retryRead={retryRead} />
					) : (
						<SummarySection
							severity={selectedItemSeverity}
							confidence={selectedItemConfidence}
							description={selectedItemDescription}
							recommendation={selectedItemRecommendation}
							alertType={store.alertType}
							totalScore={store.totalScore}
							mitreCategory={mitreCategory}
							origin={store.origin}
							loadingAssignedInvestigation={loadingAssignedInvestigation}
							alertStateInfoDetails={itemStateInfoDetails}
							threatIntelligenceMatches={threatIntelligenceMatches}
							threatIntelligenceDescriptions={threatIntelligenceDescriptions}
							alertExternalReferenceUrl={selectedItem?.externalReferenceUrl ?? ''}
							alertEventSource={selectedItem.event_source ?? ''}
						/>
					),
					disabled: false,
				},
				{
					title: t('tabs.threatIntelligence'),
					content: <div>Threat Intelligence Content</div>,
					disabled: true,
					display: false,
				},
				{
					title: t('tabs.activityOverview'),
					content: <div>Activity Overview Content</div>,
					disabled: true,
					display: false,
				},
				{
					title: t('tabs.insights'),
					content: <div>Insights & IOCs Content</div>,
					disabled: true,
					display: false,
				},
				{
					title: t('tabs.packedCapture'),
					content: <div>Packed Capture Content</div>,
					disabled: true,
					display: false,
				},
				{
					title: t('tabs.evidence'),
					content: <div>Evidence Content</div>,
					disabled: true,
					display: false,
				},
			],
			[
				t,
				showLoadingIndicator,
				loading,
				hasError,
				selectedItem,
				retryRead,
				selectedItemSeverity,
				selectedItemConfidence,
				selectedItemDescription,
				selectedItemRecommendation,
				store.alertType,
				store.totalScore,
				store.origin,
				mitreCategory,
				loadingAssignedInvestigation,
				itemStateInfoDetails,
				threatIntelligenceMatches,
				threatIntelligenceDescriptions,
			],
		);

		return (
			<DetailsViewNavigationContainer loading={store.loading}>
				<TabNavigation className={classNames({ [styles.alertNavigationError]: hasError })} tabs={tabs} />
			</DetailsViewNavigationContainer>
		);
	},
);

export default AlertsDetailsViewNavigation;
