import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import moment from 'moment/moment';

import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';

import { Namespaces } from '@/translations/namespaces';
import { useTimeRangeOptions } from '@/app/_common/hooks';
import { getSupportedTables, momentToUtcString } from '@/app/_common/utils';
import {
	AdvancedQueryPageKeyPrefix,
	ADX_DATE_TIME_PERIOD_OPTION_KEYS,
	AllowedAdxTables,
	DATEPICKER_DATE_TIME_FORMAT,
	DateTimePickerOptionKeys,
	ADX_TABLES_ORDER,
} from '@/app/_common/constants';
import { FormDateRangePicker, RangePeriodValue } from '@/app/_common/_components/date-picker/form-date-range-picker';

import { AdvancedQueryViewStore } from '@/app/advanced-query/_common/stores/advanced-query.view-store';
import { AdxSchemaViewStore } from '@/app/_common/stores';
import { RunQueryButton } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components';
import { MultiSelect } from '@/app/_common/_components/multiselect';

import styles from './form-elements.module.scss';

type DateRangePickerEvent = { target?: DropDownList | DateTimePicker; value?: RangePeriodValue };

export const FormElements = observer(() => {
	const store = useInstance(AdvancedQueryViewStore);
	const schemaStore = useInstance(AdxSchemaViewStore);
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: AdvancedQueryPageKeyPrefix.AdvancedQueryPage });

	const options = useTimeRangeOptions(ADX_DATE_TIME_PERIOD_OPTION_KEYS);

	const handleDateTimeRangePickerChange = (event: DateRangePickerEvent) => {
		const { value, key } = event?.value || {};
		const { start: from, end: to } = value || {};

		if (key === DateTimePickerOptionKeys.CUSTOM) {
			store.setTimeRange(key, from, to);
		} else {
			store.setTimeRange(key as DateTimePickerOptionKeys);
		}
	};

	const handleCustomTimePeriodChosen = (customSet: boolean) => {
		if (customSet) {
			store.setTimeRange(DateTimePickerOptionKeys.CUSTOM);
		}
	};

	const handleTableChange = (event: MultiSelectChangeEvent) => {
		const tables = event.value.map((item: { value: string; label: string }) => item.value);
		store.setTableInDraftQuery(tables);
	};

	const timeRangeFromValue = store.timeRange.value?.from;
	const timeRangeToValue = store.timeRange.value?.to;
	const fromValue = timeRangeFromValue ? moment.utc(timeRangeFromValue).format(DATEPICKER_DATE_TIME_FORMAT) : timeRangeFromValue;
	const toValue = timeRangeToValue ? moment.utc(timeRangeToValue).format(DATEPICKER_DATE_TIME_FORMAT) : timeRangeToValue;

	return (
		<div className={styles.queryForm}>
			{/* @ts-ignore*/}
			<FormDateRangePicker
				id="timePeriod"
				name="timePeriod"
				className={styles.timePeriod}
				label={t('timeRangePicker.label')}
				component={FormDateRangePicker}
				options={options}
				dataItemKey="key"
				textField="label"
				value={{
					key: store.timeRange.key,
					value: { start: fromValue, end: toValue },
					isTemporal: store.timeRange.isTemporal,
				}}
				onChange={handleDateTimeRangePickerChange}
				formatter={momentToUtcString}
				isMaximumDateNow={true}
				utc={true}
				onCustomChange={handleCustomTimePeriodChosen}
				disabledMessage={t('timeRangePicker.disabledMessage')}
				disabled={store.isTimeSetInQuery}
				popupSettings={{ height: 250 }}
				dropdownClassname={styles.timePeriodDropdown}
			/>
			<MultiSelect
				className={styles.multiselect}
				placeholder={store.isTableSelectorDisabled ? t('tableSelector.placeholderDisabled') : t('tableSelector.placeholder')}
				data={getSupportedTables(schemaStore.schemaTables, AllowedAdxTables, (table: string) => t(`tableSelector.tables.${table}`), ADX_TABLES_ORDER)}
				filterable={false}
				textField="label"
				onChange={handleTableChange}
				value={getSupportedTables(store.tables, AllowedAdxTables, (table: string) => t(`tableSelector.tables.${table}`), ADX_TABLES_ORDER)}
				dataItemKey="value"
				disabled={store.isTableSelectorDisabled}
			/>
			<RunQueryButton />
		</div>
	);
});
