import { ARBITRARY_STRING, QUERY_FUNCTION, QUERY_PUNCTUATION, WHITE_SPACES, DATETIME_FILTER_OPERATOR } from '@/app/_common/constants';

const COMPARISON = `(${QUERY_PUNCTUATION.MORE_OR_EQUAL}|${QUERY_PUNCTUATION.MORE}|${QUERY_PUNCTUATION.LESS_OR_EQUAL}|${QUERY_PUNCTUATION.LESS})`;

// checks case: where <COLUMNNAME> >= ago(...)
export const containsTimeRelativeInstruction = (query: string, dateTimeColumnNames: string[]): boolean => {
	for (const columnName of dateTimeColumnNames) {
		const timeRelativeRegex = new RegExp(
			`${WHITE_SPACES}${DATETIME_FILTER_OPERATOR}${WHITE_SPACES}${columnName}${WHITE_SPACES}${COMPARISON}${WHITE_SPACES}${QUERY_FUNCTION.AGO}\\${QUERY_PUNCTUATION.ROUND_BRACKET_OPEN}${ARBITRARY_STRING}\\${QUERY_PUNCTUATION.ROUND_BRACKET_CLOSE}`,
			'g',
		);

		if (timeRelativeRegex.test(query)) {
			return true;
		}
	}

	return false;
};
