import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { SubscriptionHookOptions, OperationVariables } from '@apollo/client';

import { SubscriptionInstance } from '@/app/_common/_components';

interface SubscriptionControllerProps<TData, TVariables = OperationVariables> {
	live: boolean;
	subscription: DocumentNode | TypedDocumentNode<TData, TVariables>;
	options?: SubscriptionHookOptions<TData, TVariables>;
}

export function SubscriptionController<TData, TVariables>({ live, subscription, options }: SubscriptionControllerProps<TData, TVariables>) {
	if (!live) {
		return null;
	}

	return <SubscriptionInstance subscription={subscription} options={options} />;
}
