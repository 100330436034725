import { useCallback, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { buttonize } from '@/app/_common/utils';
import { DetailsViewNavigationField } from '@/app/_common/_components/details-view/types';
import { DetailsViewNavigationFieldsRow } from '@/app/_common/_components/details-view/components/details-view-navigation-fields/components/details-view-navigation-fields-row';
import { AVAILABLE_FIELDS_BLOCK_NAME } from '@/app/_common/types';

import styles from './details-view-navigation-fields-block.module.scss';

interface DetailsViewNavigationFieldsBlockProps {
	title: string;
	fields: DetailsViewNavigationField[];
	blockName: AVAILABLE_FIELDS_BLOCK_NAME | null;
}

export const DetailsViewNavigationFieldsBlock = ({ title, fields, blockName }: DetailsViewNavigationFieldsBlockProps) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(true);

	const toggleExpanded = useCallback(() => setIsExpanded((state: boolean) => !state), []);

	return (
		<div className={styles.container}>
			<div className={styles.titleContainer} {...buttonize<HTMLDivElement>(toggleExpanded)}>
				<span className={styles.title}>{title}</span>
				{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</div>

			{isExpanded && (
				<div className={styles.fields}>
					{fields.map((fieldData) => (
						<DetailsViewNavigationFieldsRow key={`details-row-${fieldData.label}`} fieldData={fieldData} blockName={blockName} />
					))}
				</div>
			)}
		</div>
	);
};
