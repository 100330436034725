import { makeAutoObservable } from 'mobx';

import { QueryInOrderDefinitionArgument } from '@/app/_common/types';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { MenuDataStore } from '@/app/_common/_components/new-context-menu/_common/stores/menu.data-store';

export class MenuViewStore {
	private dataStore = injectInterface(this, MenuDataStore);

	constructor() {
		makeAutoObservable(this, undefined, { autoBind: true });
	}

	get data() {
		return this.dataStore.data;
	}

	get error() {
		return this.dataStore.error;
	}

	get loading() {
		return this.dataStore.loading;
	}

	read(queryDefinitions: QueryInOrderDefinitionArgument[]) {
		this.dataStore.read(queryDefinitions);
	}
}
