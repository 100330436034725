import { gql } from '@apollo/client';
import { CoreNoteFragment, CoreUpdateNoteFragment } from '@/app/_common/graphql/fragments.constants';

export const ListNotes = gql`
	query ListNotes($tenantId: ID!, $filtersInput: FiltersInput!) {
		listNotes(tenantId: $tenantId, filtersInput: $filtersInput) {
			... on NotesConnection {
				edges {
					node {
						...CoreNoteFragment
					}
					cursor
				}
			}
		}
	}
	${CoreNoteFragment}
`;

export const NoteCreatedSubscription = gql`
	subscription NoteCreatedSubscription($tenantId: ID!) {
		noteCreated(tenantId: $tenantId) {
			...CoreNoteFragment
		}
	}
	${CoreNoteFragment}
`;

export const NoteUpdatedSubscription = gql`
	subscription NoteUpdatedSubscription($tenantId: ID!) {
		noteUpdated(tenantId: $tenantId) {
			...CoreNoteFragment
		}
	}
	${CoreNoteFragment}
`;

export const DeleteNote = gql`
	mutation DeleteNote($tenantId: ID!, $deleteNoteRequest: DeleteNoteInput) {
		deleteNote(tenantId: $tenantId, deleteNoteRequest: $deleteNoteRequest) {
			... on Note {
				id
				artifactId
			}
		}
	}
`;

export const CreateNote = gql`
	mutation CreateNote($tenantId: ID!, $createNoteRequest: CreateNoteInput!) {
		createNote(tenantId: $tenantId, createNoteRequest: $createNoteRequest) {
			... on Error {
				message
				code
				correlationId
			}
			... on Note {
				...CoreNoteFragment
			}
		}
	}
	${CoreNoteFragment}
`;
export const UpdateNote = gql`
	mutation UpdateNote($tenantId: ID!, $updateNote: UpdateNoteInput!) {
		updateNote(tenantId: $tenantId, updateNote: $updateNote) {
			... on Note {
				...CoreUpdateNoteFragment
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
	${CoreUpdateNoteFragment}
`;

export const NoteDeletedSubscription = gql`
	subscription NoteDeletedSubscription($tenantId: ID!) {
		resourceDeleted(tenantId: $tenantId) {
			... on Note {
				id
			}
			... on Error {
				message
				code
				correlationId
			}
		}
	}
`;
