import { useInstance } from 'react-ioc';
import { AuthStore } from '@/app/_common/stores';
import { GraphqlClient } from '@/app/_common/graphql';
import { DocumentNode, useSubscription } from '@apollo/client';
import { DeletedResourceResult, Subscription, SubscriptionResourceDeletedArgs } from '@/generated/graphql';

interface BaseConnection {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	edges: any[];
}

function mergeResourceDeletedWithCache<T extends BaseConnection>(client: GraphqlClient, typename: string, key: string, data?: DeletedResourceResult) {
	if (data?.__typename === typename && 'id' in data) {
		client.cache.modify({
			fields: {
				[key](existingIntegrationsRefs: T, { readField }) {
					return {
						...(existingIntegrationsRefs || {}),
						edges: [...existingIntegrationsRefs.edges.filter((edge) => readField('id', edge.node) !== data.id)],
					};
				},
			},
		});
	}
}

export function useResourceDeletedSubscription<T extends BaseConnection>(query: DocumentNode, typename: string, key: string) {
	const authStore = useInstance(AuthStore);
	const graphqlClient = useInstance(GraphqlClient);

	useSubscription<Subscription, SubscriptionResourceDeletedArgs>(query, {
		client: graphqlClient,
		variables: {
			tenantId: authStore.currentTenantId,
		},
		onSubscriptionData: ({ subscriptionData }) => {
			mergeResourceDeletedWithCache<T>(graphqlClient, typename, key, subscriptionData?.data?.resourceDeleted);
		},
	});
}
