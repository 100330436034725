import { QUERY_SEPARATOR } from '@/app/_common/constants';
import { SchemaColumn } from '@/app/_common/types';
import {
	BASE_DATA_VARIABLE_NAME,
	TOP_DATA_VARIABLE_NAME,
} from '@/app/_common/utils/adx/prepare-available-fields-values-query/prepare-available-fields-values-query.constants';

export const getTopQuery = (column: SchemaColumn): string => {
	const baseSection = `let ${TOP_DATA_VARIABLE_NAME} = ${BASE_DATA_VARIABLE_NAME}`;
	const topSection = `top 10 by ${column.Name}_count desc`;

	return [baseSection, topSection].join(` ${QUERY_SEPARATOR} `) + ';';
};
