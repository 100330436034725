import { ListsEditor } from '@prezly/slate-lists';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

export enum EditorType {
	Paragraph = 'paragraph',
	Link = 'link',
	Code = 'code',
	Table = 'table',
	TableRow = 'table-row',
	TableCell = 'table-cell',
	// we must use such a record to match the library variables
	ORDERED_LIST = 'ordered-list',
	UNORDERED_LIST = 'unordered-list',
	LIST_ITEM = 'list-item',
	LIST_ITEM_TEXT = 'list-item-text',
}

export type CustomEditor = ReactEditor & HistoryEditor & ListsEditor;
