import { Children, FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import { ContextMenuTitle, ContextMenuItem, ContextMenuSubtitle } from '@/app/_common/_components/new-context-menu/_components';

import styles from './context-menu-section.module.scss';

interface ContextMenuSectionProps {
	children: ReactNode;
	title?: string;
	subtitle?: string;
	loading?: boolean;
	className?: string;
}

export const ContextMenuSection: FC<ContextMenuSectionProps> = ({ title, subtitle, loading, className, children }) => {
	const childrenLength = Children.toArray(children).length;
	const LoadingSkeletons = useMemo(() => {
		return Array.from({ length: childrenLength || 0 }).map(() => <ContextMenuItem key={uuid()} loading />);
	}, [childrenLength]);

	return (
		<div className={classNames(styles.contextMenuSection, className)}>
			<div className={styles.contextMenuSectionHeader}>
				{title && <ContextMenuTitle>{title}</ContextMenuTitle>}
				{subtitle && <ContextMenuSubtitle>{subtitle}</ContextMenuSubtitle>}
			</div>
			<ul className={styles.contextMenuSectionList}>{loading ? LoadingSkeletons : children}</ul>
		</div>
	);
};
