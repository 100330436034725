import { isActiveAlertTheOnlyOne, isAlertInSelected, isAlertUndismissable } from '@/app/dashboards/alerts-dashboard/_common/utils';
import { DetailsViewItemData } from '@/app/_common/_components/details-view/types';

export const areAlertsUndismissable = (activeAlert?: DetailsViewItemData, alerts?: DetailsViewItemData[]) => {
	if (!alerts || alerts.length === 0 || !activeAlert) {
		return false;
	}

	if (isActiveAlertTheOnlyOne(activeAlert, alerts) || !isAlertInSelected(activeAlert, alerts)) {
		return false;
	}

	return alerts.some(isAlertUndismissable);
};
