import { Editor } from 'slate';
import { ReactEditor } from 'slate-react';
import { getMatchNode } from '@/app/_common/_components/rich-text-editor';

export const IsCurrentFormatActive = (editor: Editor & ReactEditor, format: string) => {
	const { selection } = editor;

	if (!selection) {
		return false;
	}

	const [node] = getMatchNode(editor, format);

	return !!node;
};
