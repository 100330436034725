import { DYNAMIC_COLUMN_TYPE, QUERY_SEPARATOR } from '@/app/_common/constants';
import { TableColumn } from '@/app/_common/types';
import {
	DATA_VARIABLE_NAME,
	ALLOWED_EMPTY_COLUMNS,
} from '@/app/_common/utils/adx/prepare-available-fields-query/prepare-available-fields-query.constants';

export const getColumnQuery = (column: TableColumn): string => {
	const columnName = column.ColumnName;
	const columnType = column.ColumnType;
	/*
	 * When in two schema tables (e.g. 'alerts' and 'events') same field name (e.g. 'dest') is present but has different types (e.g. 'string', 'dynamic'),
	 * Advanced Query results table fetches it as two different fields: (e.g. 'dest_dynamic' and 'dest_string').
	 * These are also 'dynamic' fields, though the type is not always 'dynamic' there. To handle this case we calculate 'duplicatedDynamicNames',
	 * and also compare 'columnName' against that array.
	 * */
	const isDynamic = columnType === DYNAMIC_COLUMN_TYPE;
	const baseSection = `let summarized_${columnName} = ${DATA_VARIABLE_NAME}`;
	const projectSection = `project ${columnName}`;
	const filterNullSection = isDynamic ? `where isnotnull(${columnName})` : '';
	const mvExpandSection = isDynamic ? `mv-expand ${columnName} to typeof(string)` : '';
	const filterEmptySection = ALLOWED_EMPTY_COLUMNS.includes(columnName) ? '' : `where isnotempty(${columnName})`;
	const summarizeSection = `summarize count_distinct(${columnName}) by ColumnName = '${columnName}'`;
	const extendSection = `project ColumnName, Count = count_distinct_${columnName}`;

	return (
		[baseSection, projectSection, filterNullSection, mvExpandSection, filterEmptySection, summarizeSection, extendSection]
			.filter(Boolean)
			.join(` ${QUERY_SEPARATOR} `) + ';'
	);
};
