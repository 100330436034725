import { VENDOR_LINK_KEYS } from '@/app/_common/_components/details-view/alerts-details-view-navigation';

export function getAlertVendorKey(alertEventSource = ''): string {
	const [vendor = '', product = ''] = alertEventSource.split(' ');
	const vendorKey = `${vendor.toLowerCase()}_${product.toLowerCase()}`;

	if (!VENDOR_LINK_KEYS.includes(vendorKey)) {
		return '';
	}

	return vendorKey;
}
