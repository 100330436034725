import { provider } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { AlertsCreateInvestigationDataStore } from '@/app/dashboards/alerts-dashboard/_common/stores/alerts-create-investigation.data-store';
import { AlertsAssignToInvestigationDataStore } from '@/app/dashboards/alerts-dashboard/_common/stores/alerts-assign-to-investigation.data-store';
import { AlertsList } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/alerts-list';
import { AlertsAssignDialogViewStore } from '@/app/dashboards/alerts-dashboard/_components/alerts-assign-dialog/alerts-assign-dialog.view-store';
import { InvestigationDetailsViewStore } from '@/app/investigation-details/_common/stores';
import { InvestigationsListViewStore } from '@/app/dashboards/alerts-dashboard/_components/investigations/investigations-list.view-store';
import { InvestigationsDataStore } from '@/app/dashboards/alerts-dashboard/_common/stores';

export const AlertsListModule = provider(
	AlertsAssignToInvestigationDataStore,
	AlertsCreateInvestigationDataStore,
	AlertsAssignDialogViewStore,
	InvestigationDetailsViewStore,
	InvestigationsListViewStore,
	InvestigationsDataStore,
)(
	observer(() => {
		return <AlertsList />;
	}),
);
