import { FC, useCallback, MouseEvent, useState } from 'react';

import classNames from 'classnames';
import { GridCellProps } from '@progress/kendo-react-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { DropdownButton } from '@/app/_common/_components/dropdown-button/dropdown-button';
import { IconCell } from '@/app/_common/_components/data-grid/_components/icon-cell/icon-cell';
import { ActionsItem } from '@/app/_common/_components/data-grid/_components/actions-cell/types';
import { ActionCellItem } from '@/app/_common/_components/data-grid/_components/actions-cell/action-cell-item';
import { withDataItem } from '@/app/_common/_components/data-grid/_components/actions-cell/with-data-item';
import { DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { ContextMenuComponentProps } from '@/app/_common/types';
import { buttonize } from '@/app/_common/utils';

import styles from './actions-cell.module.scss';

interface ActionsCellProps extends GridCellProps {
	items?: ActionsItem[];
	hideIcon?: boolean;
	popupClassName?: string;
	ContextMenuComponent?: React.FC<ContextMenuComponentProps>;
	oldContextMenu?: boolean;
}

export const ActionsCell: FC<ActionsCellProps> = ({ items = [], className, popupClassName, hideIcon, ...rest }) => {
	const { dataItem } = rest;
	const [show, setShow] = useState(false);

	const handleItemClick = useCallback(
		({ item }: DropDownButtonItemClickEvent) => {
			if (typeof item?.onClick === 'function') {
				item.onClick(dataItem);
			}
		},
		[dataItem],
	);

	const disableRightClick = (e: MouseEvent<HTMLSpanElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleClick = () => {
		setShow(true);
	};

	const handleBlur = () => {
		setShow(false);
	};

	return (
		<IconCell className={classNames(styles.actionsCell, className)} {...rest}>
			{!hideIcon && (
				<span onContextMenu={disableRightClick} {...buttonize<HTMLSpanElement>(handleClick)}>
					<DropdownButton
						opened={show}
						popupSettings={{ popupClass: classNames(popupClassName, styles.popup) }}
						text={<MoreVertIcon />}
						items={items}
						itemRender={withDataItem(ActionCellItem, dataItem)}
						onItemClick={handleItemClick}
						onBlur={handleBlur}
					/>
				</span>
			)}
		</IconCell>
	);
};
