import { DEFAULT_SUBSCRIPTION_RECONNECT_ATTEMPTS } from '@/app/_common/constants';

export const getReconnectAttempts = (attempts?: string): number => {
	const attemptsNumber = Number(attempts);

	if (isNaN(attemptsNumber) || attemptsNumber <= 0) {
		return DEFAULT_SUBSCRIPTION_RECONNECT_ATTEMPTS;
	}

	return attemptsNumber;
};
