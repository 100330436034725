import { FC, useMemo } from 'react';
import { SingleBullet } from './single-bullet';
import { getBullets } from './utils';

import styles from './color-indicator.module.scss';

const DEFAULT_BULLETS_COUNT = 4;

interface ColorIndicatorProps {
	bulletsConfig: string[];
	bulletsCount?: number;
}

export const ColorIndicator: FC<ColorIndicatorProps> = ({ bulletsConfig, bulletsCount = DEFAULT_BULLETS_COUNT }) => {
	const bullets = useMemo(() => getBullets(bulletsConfig, bulletsCount), [bulletsConfig, bulletsCount]);

	return (
		<div className={styles.container}>
			{bullets.map(({ id, color }) => (
				<SingleBullet key={id} color={color} />
			))}
		</div>
	);
};
