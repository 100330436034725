import { FC } from 'react';
import Avatar from 'react-avatar';

import { User } from '@/app/_common/interfaces/auth-details';

import '@/assets/styles/index.scss';
import styles from './user-tile.module.scss';
import classNames from 'classnames';
import { AuthStore } from '@/app/_common/stores';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

interface UserInfoProps {
	user: User;
	opened: boolean;
}

export const UserInfo: FC<UserInfoProps> = observer(({ user, opened = false }) => {
	const authStore = useInstance(AuthStore);

	return (
		<div className={styles.userInfo}>
			<Avatar size="32" name={user.upn} round={true} />
			<div className={styles.userDetails}>
				<div className={styles.userId} data-testid="user-id">
					{user.upn}
				</div>
				{authStore?.currentTenantName && (
					<div className={styles.tenant} data-testid="current-tenant-name">
						{authStore.currentTenantName}
					</div>
				)}
			</div>
			<div className={classNames(styles.arrow, { [styles.arrowUp]: opened })} />
		</div>
	);
});
