import { FC, ReactNode, RefObject } from 'react';

import classNames from 'classnames';

import { Popup } from '@progress/kendo-react-popup';

import { PopupOffset } from '@/app/_common/types';

import styles from './context-menu-container.module.scss';

interface ContextMenuContainerProps {
	containerRef: RefObject<HTMLDivElement>;
	show: boolean;
	offset: PopupOffset;
	children: ReactNode;
	className?: string;
}

export const ContextMenuContainer: FC<ContextMenuContainerProps> = ({ containerRef, show, offset, children, className = '' }) => {
	return (
		<Popup className={classNames(styles.contextMenuContainer, className)} show={show} offset={offset} animate={false}>
			<div ref={containerRef} onContextMenu={(e) => e.preventDefault()}>
				{children}
			</div>
		</Popup>
	);
};
