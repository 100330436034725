import { FC, useEffect, useState, useRef } from 'react';
import { TextArea, TextAreaChangeEvent } from '@progress/kendo-react-inputs';
import { stringValidator } from '@/app/_common/utils/validators/string-validator';
import { MAX_CHARACTERS_COUNT } from '@/app/_common/constants/input-length.constants';

import _debounce from 'lodash/debounce';

import classNames from 'classnames';
import styles from './editable-text-area.module.scss';

interface EditableTextAreaProps {
	value?: string;
	saveNewValue: (value: string | undefined) => Promise<boolean | undefined> | undefined;
	className?: string;
	isEditable?: boolean;
	dataTestId?: string;
}

export const EditableTextArea: FC<EditableTextAreaProps> = ({ value, saveNewValue, className = '', isEditable = true, dataTestId = '' }) => {
	const [currentValue, setCurrentValue] = useState<string | undefined>(undefined);
	const [counter, setCounter] = useState<number>(0);
	const [inValid, setInValid] = useState<boolean>(false);
	const [edit, setEdit] = useState<boolean>(false);
	const [clickedOutSide, setClickedOutSide] = useState<boolean>(false);

	const wrapper = useRef<HTMLDivElement | null>(null);

	const handleActiveEdit = () => {
		setEdit(true);
	};

	useEffect(() => {
		if (value) {
			setCurrentValue(value);
			setCounter(value.length);
		}
	}, [value]);

	const handleSetNewValue = (event: TextAreaChangeEvent) => {
		setCounter(event.value.length);
		if (!event.value.length) {
			setInValid(false);
			return setCurrentValue(undefined);
		}
		if (!stringValidator(event.value) || event.value.length > MAX_CHARACTERS_COUNT) {
			setInValid(true);
		} else if (inValid && event.value.length < MAX_CHARACTERS_COUNT) {
			setInValid(false);
		}
		setCurrentValue(event.value);
	};

	const handleSaveNewValue = async () => {
		if (value === currentValue) {
			return;
		}

		const valid = await saveNewValue(currentValue);
		if (valid) {
			setEdit(false);
		}
	};

	const handleClickOutSide = (event: Event) => {
		if (wrapper.current && !wrapper.current.contains(event.target as Node)) {
			setClickedOutSide(true);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutSide, true);
		return () => {
			document.removeEventListener('click', handleClickOutSide, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (clickedOutSide && !inValid) {
			// using .catch() is workaround for SonarCloud's issue with Promises in useEffect hook
			// currently there is no possibility to use `async-await` in useEffect hook
			// we also don't have any value to process from the handleSaveNewValue()
			// eslint-disable-next-line no-console
			handleSaveNewValue().catch(console.error);
			setClickedOutSide(false);
			setEdit(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clickedOutSide, inValid]);

	const keyListener = async (event: KeyboardEvent) => {
		if (event.code === 'Escape') {
			await handleSaveNewValue();
			setEdit(false);
		}
	};

	if (!value) {
		return null;
	}

	return (
		<div ref={wrapper} className={styles.editableWrapper} data-testid={dataTestId}>
			<div className={classNames(styles.box, { [styles.disabled]: !edit || !isEditable })}>
				<div className={styles.contentBox}>
					{isEditable && edit ? (
						<>
							<TextArea
								className={classNames(styles.editableTextArea, {
									[className]: className,
									[styles.disabledInput]: !edit,
									[styles.inValidTextArea]: inValid,
								})}
								id={currentValue}
								disabled={!edit}
								value={currentValue}
								onChange={handleSetNewValue}
								onKeyDown={_debounce((e) => keyListener(e), 500)}
								maxLength={MAX_CHARACTERS_COUNT}
							/>
							<div className={classNames(styles.counterBox, { [styles.inValid]: inValid })}>
								{counter} / {MAX_CHARACTERS_COUNT}
							</div>
						</>
					) : (
						// eslint-disable-next-line jsx-a11y/click-events-have-key-events
						<div onClick={handleActiveEdit} className={classNames(styles.description, { [styles.active]: isEditable })}>
							<span className={className}>{currentValue} </span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
