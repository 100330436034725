import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';

import _get from 'lodash/get';

import { GridCellProps, GridHeaderCellProps, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';

import { AlertEdge, InvestigationAlertSummary } from '@/generated/graphql';
import { RootPaths } from '@/app/_common/navigation';
import { Column, DataGrid } from '@/app/_common/_components/data-grid/data-grid';
import { WidgetContainer } from '@/app/_common/_components/widget-container/widget-container';
import {
	AlertStateCell,
	DataHeaderCell,
	DateTimeCell,
	LinkCell,
	MultiValueCell,
	TrimTextCell,
	ConfidenceCell,
	SeverityCell,
} from '@/app/_common/_components/data-grid/_components';
import { DetectedByCell, MitreAttackCell } from '@/app/dashboards/alerts-dashboard/_components/alerts-list/_components/';
import { ListHeader } from '@/app/_common/_components/list-header/list-header';
import { DataGridSelected, FilterOption, Filters } from '@/app/_common/types';
import { sortAlertFilters } from '@/app/_common/utils';
import {
	AlertHeadersTranslationKeys,
	AlertPropertiesPaths,
	AlertsKeyPrefix,
	AssignedAlertHeadersTranslationKeys,
	AssignedAlertPropertiesPaths,
} from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';

import { InvestigationsListViewStore } from '@/app/dashboards/alerts-dashboard/_components/investigations/investigations-list.view-store';
import { HeaderSelectCell } from '@/app/_common/_components/data-grid/_components/header-select-cell';
import { HeaderCheckboxMode } from '@/app/_common/_components/data-grid/constants';
import { SelectCell } from '@/app/_common/_components/data-grid/_components/select-cell';
import { useRowRender } from '@/app/_common/_components/data-grid/hooks';

import styles from './alerts-dismiss-list.module.scss';

const SELECTED_FIELD = 'selected';

interface AlertsDismissListProps {
	isInvestigationDetails?: boolean;
	selected: DataGridSelected;
	data: AlertEdge[] | InvestigationAlertSummary[];
	sort: SortDescriptor[];
	selectionHeaderMode: HeaderCheckboxMode;
	totalCount: number;
	toggleSelectAll: () => void;
	setSort: (sort: SortDescriptor[]) => void;
	setGridHeaderFilter: (field: string, values: Filters) => void;
	getStateFilterOptions: () => FilterOption[];
	resetGridHeaderFilter: (field: string) => void;
	getCounter: (field: string) => number;
	getGridHeaderFilterValues: (field: string) => string[];
	getFieldColumnState: (field: string) => boolean;
	getFilterOptions: (field: string) => FilterOption[];
	getAlertsDetectedByMainFilters: (field: string) => FilterOption[];
	getDetectedByVendorFilters: (field: string) => {
		name: string;
		count: number;
		options: {
			value: string;
			label: string;
			counter: number;
		}[];
	};
	isRowSelected: (id: string) => boolean;
	toggleSelectedRow: (id: string) => void;
}

export const AlertsDismissList: FC<AlertsDismissListProps> = observer(
	({
		data,
		isInvestigationDetails = false,
		sort,
		selectionHeaderMode,
		totalCount,
		toggleSelectAll,
		setSort,
		setGridHeaderFilter,
		getStateFilterOptions,
		resetGridHeaderFilter,
		getCounter,
		getGridHeaderFilterValues,
		getFieldColumnState,
		getFilterOptions,
		getAlertsDetectedByMainFilters,
		getDetectedByVendorFilters,
		isRowSelected,
		toggleSelectedRow,
	}) => {
		let PropertiesPaths: typeof AlertPropertiesPaths | typeof AssignedAlertPropertiesPaths;
		let HeaderTranslationKeys: typeof AlertHeadersTranslationKeys | typeof AssignedAlertHeadersTranslationKeys;
		if (isInvestigationDetails) {
			PropertiesPaths = AssignedAlertPropertiesPaths;
			HeaderTranslationKeys = AssignedAlertHeadersTranslationKeys;
		} else {
			PropertiesPaths = AlertPropertiesPaths;
			HeaderTranslationKeys = AlertHeadersTranslationKeys;
		}

		const DATA_ITEM_KEY = PropertiesPaths.Id;
		const idGetter = getter(DATA_ITEM_KEY);

		const { pathname } = useLocation();
		const investigationsListViewStore = useInstance(InvestigationsListViewStore);

		const { t } = useTranslation([Namespaces.AlertsDashboard]);

		const handleSortChange = useCallback(
			(event: GridSortChangeEvent) => {
				setSort(event.sort);
			},
			[setSort],
		);

		const COLUMNS = useMemo<Column[]>(
			() => [
				{
					id: SELECTED_FIELD,
					width: '50px',
					// Wrapping with observer ensures proper rerendering!
					headerCell: observer((props) => <HeaderSelectCell mode={selectionHeaderMode} onChange={props.selectionChange} {...props} />),
					cell: (props: GridCellProps) => (
						<SelectCell {...props} isRowSelected={isRowSelected} toggleSelectedRow={toggleSelectedRow} idPropertyPath={DATA_ITEM_KEY} />
					),
					resizable: false,
					locked: true,
				},
				{
					field: PropertiesPaths.Timestamp,
					//@ts-ignore
					title: t(HeaderTranslationKeys[PropertiesPaths.Timestamp]),
					cell: DateTimeCell,
					width: '170px',
					headerCell: (props) => <DataHeaderCell {...props} sortable={true} sort={sort} onSort={setSort} />,
					headerClassName: styles.headerCell,
				},
				{
					id: PropertiesPaths.State,
					field: PropertiesPaths.State,
					//@ts-ignore
					title: t(HeaderTranslationKeys[PropertiesPaths.State]),
					cell: observer((props) => {
						const alertState = _get(props.dataItem, PropertiesPaths.State, '');
						return <AlertStateCell {...props} statePropertyPath={PropertiesPaths.StateData} alertState={alertState} />;
					}),
					width: '110px',
					headerCell: (props) => (
						<DataHeaderCell
							{...props}
							sortable={true}
							sort={sort}
							onSort={setSort}
							onFilter={setGridHeaderFilter}
							filterOptions={getStateFilterOptions()}
							clearFilter={resetGridHeaderFilter}
							sortFilterOptions={sortAlertFilters}
							counter={getCounter(PropertiesPaths.State)}
							filter={getGridHeaderFilterValues(PropertiesPaths.State)}
						/>
					),
					headerClassName: styles.headerCell,
					show: getFieldColumnState(PropertiesPaths.State),
				},
				...(isInvestigationDetails
					? []
					: [
							{
								field: AlertPropertiesPaths.InvestigationSummaryId,
								title: t(AlertHeadersTranslationKeys[AlertPropertiesPaths.InvestigationSummaryId]),
								width: '110px',
								cell: observer((props: PropsWithChildren<GridCellProps>) => {
									const investigationId = _get(props.dataItem, AlertPropertiesPaths.InvestigationSummaryId);
									return (
										<LinkCell
											{...props}
											title={investigationsListViewStore.getInvestigationNameById(investigationId)}
											path={`${RootPaths.INVESTIGATIONS}/${investigationId}`}
											from={pathname}
										/>
									);
								}),
								headerCell: (props: PropsWithChildren<GridHeaderCellProps>) => (
									<DataHeaderCell
										{...props}
										sortable={true}
										sort={sort}
										onSort={setSort}
										onFilter={setGridHeaderFilter}
										filterOptions={getFilterOptions(AlertPropertiesPaths.InvestigationSummaryId)}
										clearFilter={resetGridHeaderFilter}
										sortFilterOptions={sortAlertFilters}
										counter={getCounter(AlertPropertiesPaths.InvestigationSummaryId)}
										filter={getGridHeaderFilterValues(AlertPropertiesPaths.InvestigationSummaryId)}
									/>
								),
								headerClassName: styles.headerCell,
								show: getFieldColumnState(AlertPropertiesPaths.InvestigationSummaryId),
							},
					  ]),
				{
					field: PropertiesPaths.Severity,
					//@ts-ignore
					title: t(HeaderTranslationKeys[PropertiesPaths.Severity]),
					cell: SeverityCell,
					headerCell: observer((props) => (
						<DataHeaderCell
							{...props}
							counter={getCounter(PropertiesPaths.Severity)}
							sortable={true}
							sort={sort}
							onSort={setSort}
							onFilter={setGridHeaderFilter}
							filter={getGridHeaderFilterValues(PropertiesPaths.Severity)}
							filterOptions={getFilterOptions(PropertiesPaths.Severity)}
							clearFilter={resetGridHeaderFilter}
							sortFilterOptions={sortAlertFilters}
						/>
					)),
					headerClassName: styles.headerCell,
				},
				{
					field: PropertiesPaths.Confidence,
					//@ts-ignore
					title: t(HeaderTranslationKeys[PropertiesPaths.Confidence]),
					cell: ConfidenceCell,
					headerCell: observer((props) => (
						<DataHeaderCell
							{...props}
							counter={getCounter(PropertiesPaths.Confidence)}
							sortable={true}
							sort={sort}
							onSort={setSort}
							onFilter={setGridHeaderFilter}
							filter={getGridHeaderFilterValues(PropertiesPaths.Confidence)}
							filterOptions={getFilterOptions(PropertiesPaths.Confidence)}
							clearFilter={resetGridHeaderFilter}
							sortFilterOptions={sortAlertFilters}
						/>
					)),
					headerClassName: styles.headerCell,
				},
				{
					field: PropertiesPaths.Title,
					//@ts-ignore
					title: t(HeaderTranslationKeys[PropertiesPaths.Title]),
					cell: TrimTextCell,
					headerCell: observer((props) => (
						<DataHeaderCell
							{...props}
							counter={getCounter(PropertiesPaths.Title)}
							sortable={true}
							sort={sort}
							onSort={setSort}
							onFilter={setGridHeaderFilter}
							filter={getGridHeaderFilterValues(PropertiesPaths.Title)}
							filterOptions={getFilterOptions(PropertiesPaths.Title)}
							clearFilter={resetGridHeaderFilter}
							sortFilterOptions={sortAlertFilters}
						/>
					)),
					headerClassName: styles.headerCell,
				},
				{
					field: PropertiesPaths.Source,
					//@ts-ignore
					title: t(HeaderTranslationKeys[PropertiesPaths.Source]),
					cell: MultiValueCell,
					headerCell: observer((props) => (
						<DataHeaderCell
							{...props}
							counter={getCounter(PropertiesPaths.Source)}
							sortable={true}
							sort={sort}
							onSort={setSort}
							onFilter={setGridHeaderFilter}
							filter={getGridHeaderFilterValues(PropertiesPaths.Source)}
							filterOptions={getFilterOptions(PropertiesPaths.Source)}
							clearFilter={resetGridHeaderFilter}
							sortFilterOptions={sortAlertFilters}
						/>
					)),
					headerClassName: styles.headerCell,
				},
				{
					field: PropertiesPaths.Destination,
					//@ts-ignore
					title: t(HeaderTranslationKeys[PropertiesPaths.Destination]),
					cell: MultiValueCell,
					headerCell: observer((props) => (
						<DataHeaderCell
							{...props}
							counter={getCounter(PropertiesPaths.Destination)}
							sortable={true}
							sort={sort}
							onSort={setSort}
							onFilter={setGridHeaderFilter}
							filter={getGridHeaderFilterValues(PropertiesPaths.Destination)}
							filterOptions={getFilterOptions(PropertiesPaths.Destination)}
							clearFilter={resetGridHeaderFilter}
							sortFilterOptions={sortAlertFilters}
						/>
					)),
					headerClassName: styles.headerCell,
				},
				{
					field: PropertiesPaths.Mitre,
					//@ts-ignore
					title: t(HeaderTranslationKeys[PropertiesPaths.Mitre]),
					cell: MitreAttackCell,
					headerCell: observer((props) => (
						<DataHeaderCell
							{...props}
							counter={getCounter(PropertiesPaths.Mitre)}
							sortable={true}
							sort={sort}
							onSort={setSort}
							onFilter={setGridHeaderFilter}
							filter={getGridHeaderFilterValues(PropertiesPaths.Mitre)}
							filterOptions={getFilterOptions(PropertiesPaths.Mitre)}
							clearFilter={resetGridHeaderFilter}
							sortFilterOptions={sortAlertFilters}
						/>
					)),
					headerClassName: styles.headerCell,
				},
				{
					field: PropertiesPaths.DetectedBy,
					//@ts-ignore
					title: t(HeaderTranslationKeys[PropertiesPaths.DetectedBy]),
					cell: DetectedByCell,
					headerCell: observer((props) => {
						return (
							<DataHeaderCell
								{...props}
								counter={getCounter(PropertiesPaths.DetectedBy)}
								sortable={true}
								sort={sort}
								onSort={setSort}
								onFilter={setGridHeaderFilter}
								filter={getGridHeaderFilterValues(PropertiesPaths.DetectedBy)}
								filterOptions={getAlertsDetectedByMainFilters(PropertiesPaths.DetectedBy)}
								filterOptionsSubgroups={[getDetectedByVendorFilters(PropertiesPaths.DetectedBy)].filter((subgroup) => subgroup.options)}
								clearFilter={resetGridHeaderFilter}
								sortFilterOptions={sortAlertFilters}
							/>
						);
					}),
					headerClassName: styles.headerCell,
				},
			],
			[
				DATA_ITEM_KEY,
				HeaderTranslationKeys,
				PropertiesPaths,
				getAlertsDetectedByMainFilters,
				getCounter,
				getDetectedByVendorFilters,
				getFieldColumnState,
				getFilterOptions,
				getGridHeaderFilterValues,
				getStateFilterOptions,
				investigationsListViewStore,
				isInvestigationDetails,
				isRowSelected,
				pathname,
				resetGridHeaderFilter,
				selectionHeaderMode,
				setGridHeaderFilter,
				setSort,
				sort,
				t,
				toggleSelectedRow,
			],
		);

		const rowRender = useRowRender({ isRowSelected, idGetter });

		return (
			<WidgetContainer
				className={styles.alerts}
				header={<ListHeader countLabel={t(`${AlertsKeyPrefix.AlertsGrid}.totalLabel`, { total: totalCount })} showFilters={false} title={'Alerts'} />}
			>
				<DataGrid
					gridProps={{
						data: data,
						dataItemKey: DATA_ITEM_KEY,
						fixedScroll: true,
						sort: sort,
						sortable: true,
						onHeaderSelectionChange: toggleSelectAll,
						onSortChange: handleSortChange,
						rowRender,
					}}
					columns={COLUMNS}
				/>
			</WidgetContainer>
		);
	},
);
