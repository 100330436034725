import { action, makeObservable } from 'mobx';
import _isObject from 'lodash/isObject';

import { Investigation, InvestigationResult, Mutation, Scalars } from '@/generated/graphql';
import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { InvestigationTypename } from '@/app/_common/constants';
import { createUnassignAlertsFromInvestigationMutation } from '@/app/_common/graphql/queries';
import { cacheUpdateHelperUnassignDashboard } from '@/app/dashboards/alerts-dashboard/_common/utils/cache-updates';

interface MutationBaseArgs {
	tenantId: Scalars['ID'];
}

interface MutationBaseResult {
	[key: string]: InvestigationResult;
}

export class AlertsActionDataStore extends GraphqlBaseDataStore<MutationBaseResult, MutationBaseArgs> {
	constructor() {
		super();
		makeObservable(this, {
			unassign: action,
		});
	}

	async unassign(unassignedAlertIds: string[], investigations: Investigation[]) {
		const investigationsWithAlerts: Array<[investigationId: string, alertIds: string[]]> = investigations.map(({ id, alerts }) => {
			const alertIds = alerts?.filter((alert) => !unassignedAlertIds.includes(alert.id)).map((alert) => alert.id) || [];

			return [id, alertIds];
		});

		const variables = {
			tenantId: this.authStore.currentTenantId,
		};

		const mutation = createUnassignAlertsFromInvestigationMutation(investigationsWithAlerts);

		const response = await this.mutate<Mutation, MutationBaseArgs>({
			mutation,
			variables,
			update(cache, { data }) {
				const investigations = Object.values(data || {}).filter(
					(item): item is Investigation => _isObject(item) && item.__typename === InvestigationTypename.Investigation,
				);

				if (investigations.length === 0) {
					return;
				}

				cacheUpdateHelperUnassignDashboard(cache, unassignedAlertIds, investigations);
			},
		});

		return response;
	}
}
