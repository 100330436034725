import { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Namespaces } from '@/translations/namespaces';
import { Button, Dialog } from '@/app/_common/_components';
import { Notification } from '@/app/_common/types';

import styles from '@/app/_common/_components/alerts-action-dialog/alerts-action-dialog.module.scss';

export interface AlertsActionDialogProps {
	title?: string;
	description?: string;
	className?: string;
	isOpen: boolean;
	onClose: () => void;
	onSubmit?: () => void;
	submitButtonText?: string;
	cancelButtonText?: string;
	children: ReactNode;
	loading?: boolean;
	disabled?: boolean;
	errors?: Notification[];
}

const DIALOG_WIDTH = 1256;

export const AlertsActionDialog = ({
	children,
	className,
	title,
	description,
	isOpen,
	cancelButtonText,
	submitButtonText,
	loading = false,
	disabled = false,
	onClose,
	onSubmit,
	errors,
}: AlertsActionDialogProps) => {
	const { t } = useTranslation([Namespaces.Common], { keyPrefix: 'components.alertsActionDialog' });

	const handleClose = useCallback(() => {
		if (typeof onClose === 'function') {
			onClose();
		}
	}, [onClose]);

	const handleSubmit = useCallback(() => {
		if (typeof onSubmit === 'function') {
			onSubmit();
		}
	}, [onSubmit]);

	if (!isOpen) {
		return null;
	}

	return (
		<Dialog
			className={classNames(className, styles.dialog)}
			title={title ?? t('title')}
			width={DIALOG_WIDTH}
			footerDivider={false}
			onClose={handleClose}
			onSubmit={handleSubmit}
			errors={errors}
			actions={
				<>
					<Button className={styles.button} onClick={handleClose} fillMode="outline">
						{cancelButtonText ?? t('buttons.cancel')}
					</Button>
					<Button className={styles.button} onClick={handleSubmit} disabled={disabled} loading={loading}>
						{submitButtonText ?? t('buttons.submit')}
					</Button>
				</>
			}
		>
			{description && (
				<div className={styles.description}>
					<p>{description}</p>
				</div>
			)}
			{children}
		</Dialog>
	);
};
