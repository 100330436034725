import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AVAILABLE_FIELDS_BLOCK_NAME } from '@/app/_common/types';
import { availableFieldsBlockHeaderClassnameMap } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';

import styles from './available-fields-block-header.module.scss';
import { buttonize } from '@/app/_common/utils';

interface AvailableFieldsBlockHeaderProps {
	blockName: AVAILABLE_FIELDS_BLOCK_NAME;
	isExpanded: boolean;
	toggleExpandedState: () => void;
	stickyClassName?: string;
}

export const AvailableFieldsBlockHeader = ({ blockName, isExpanded, toggleExpandedState, stickyClassName }: AvailableFieldsBlockHeaderProps) => {
	const className = availableFieldsBlockHeaderClassnameMap[blockName];
	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: 'tabs.advancedQuery' });

	return (
		<div className={classNames(styles.container, stickyClassName)} {...buttonize<HTMLDivElement>(toggleExpandedState)}>
			<div className={classNames(styles.content, className)}>
				<span>{t(`availableFields.blockTitle.${blockName}`)}</span>
				{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
			</div>
		</div>
	);
};
