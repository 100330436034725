import LZString from 'lz-string';

interface QueryParams {
	tenantId?: string;
	from?: string;
	to?: string;
}

export const encodeQueryParameters = (params: QueryParams) => {
	return LZString.compressToEncodedURIComponent(JSON.stringify(params));
};
