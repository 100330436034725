import { FC } from 'react';
import { Namespaces } from '@/translations/namespaces';
import { useTranslation } from 'react-i18next';

import { SummarySectionItemTitle } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-section-item-title';

interface OriginProps {
	origin?: string;
	className?: string;
}

export const Origin: FC<OriginProps> = ({ className, origin }) => {
	const { t } = useTranslation(Namespaces.DetailsView);

	if (!origin) {
		return null;
	}

	return (
		<div className={className}>
			<SummarySectionItemTitle title={t('summary.origin')} />
			<p>{origin}</p>
		</div>
	);
};
