import { useCallback } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';

import { ResultsDataItem } from '@/app/_common/types';
import { CommonContextMenuProps } from '@/app/_common/_components/new-context-menu';
import { QueryResultsTableContextMenuContent } from '@/app/_common/_components/query-results/query-results-table/components';
import { RESULTS_ARTIFICIAL_ID_PATH } from '@/app/_common/constants';
import { AdvancedQueryAlertDetailsViewStore } from '@/app/_common/_components/details-view/stores';
import { AssignQueryResultsToInvestigationDialogViewStore } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/assign-query-results-to-investigation-dialog/assign-query-results-to-investigation-dialog.view-store';
import { AdvancedQueryResultsTableViewStore } from '@/app/advanced-query/_common/stores';
import { AssignQueryResultsToInvestigationDialogResultsTableViewStore } from '@/app/advanced-query/_components/advanced-query-page/advanced-query-main-tab/_components/assign-query-results-to-investigation-dialog/stores';

interface ResultsContextMenuContextProps extends CommonContextMenuProps<ResultsDataItem> {
	onResetGridPage?: () => void;
}

export const ResultsContextMenuContent = observer((props: ResultsContextMenuContextProps) => {
	const { setItems } = useInstance(AssignQueryResultsToInvestigationDialogViewStore);
	const { setSelectedAlertId } = useInstance(AdvancedQueryAlertDetailsViewStore);
	const { setQueryResults } = useInstance(AssignQueryResultsToInvestigationDialogResultsTableViewStore);
	const { selectedItems } = useInstance(AdvancedQueryResultsTableViewStore);
	const { dataItem } = props;
	const artificialId = dataItem?.[RESULTS_ARTIFICIAL_ID_PATH];

	const handleOpenDetailsClick = useCallback(() => {
		if (artificialId) {
			setSelectedAlertId(artificialId);
		}
	}, [artificialId, setSelectedAlertId]);

	const handleAssignClick = useCallback(() => {
		setItems(selectedItems);
		setQueryResults(selectedItems);
	}, [setItems, setQueryResults, selectedItems]);

	return <QueryResultsTableContextMenuContent {...props} onAssignToInvestigation={handleAssignClick} onViewDetailsClick={handleOpenDetailsClick} />;
});

ResultsContextMenuContent.displayName = 'ResultsContextMenuContent';
