import { FC, ReactNode, useCallback, useState, Children, isValidElement, cloneElement, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import classNames from 'classnames';
import _uniqueId from 'lodash/uniqueId';

import { Popup, Align } from '@progress/kendo-react-popup';

import { useOutsideClick } from '@/app/_common/hooks';
import { buttonize } from '@/app/_common/utils';
import { ThemeStore } from '@/app/_common/stores';
import { Tooltip } from '@/app/_common/_components';

import { ActiveType } from '@/app/_common/_components/data-grid/_components/data-header-cell/_common/constants';

import styles from './header-cell.module.scss';
import { dataGridHeaderCellPopupZIndex } from '@/app/_common/constants';

const POPUP_MIN_WIDTH = '250px';
const TOOLTIP_OFFSET_TOP = 5;
const TOOLTIP_DELAY_SHOW = 500;

interface HeaderCellProps {
	header: ReactNode;
	className?: string;
	headerClassName?: string;
	popupClassName?: string;
	active?: boolean;
	activeType?: ActiveType;
	dropdownPopupAlign?: Align;
	dropdownAnchorAlign?: Align;
	testOpened?: boolean;
	hasPopup: boolean;
	zIndex?: number;
	tooltip?: boolean;
	tooltipText?: string | ReactNode;
	onPopupVisibilityChange?: (opened: boolean) => void;
	onClick?: (callback?: () => void) => void;
	children: ReactNode;
}

export const HeaderCell: FC<HeaderCellProps> = observer(
	({
		children,
		className,
		headerClassName,
		popupClassName,
		header,
		active,
		activeType,
		dropdownPopupAlign,
		dropdownAnchorAlign,
		hasPopup,
		zIndex = dataGridHeaderCellPopupZIndex,
		tooltip = true,
		tooltipText,
		onPopupVisibilityChange,
		onClick,
	}) => {
		const [isDropdownOpen, setIsDropdownOpen] = useState(false);
		const [tooltipId] = useState(_uniqueId());

		const closePopup = useCallback(() => {
			if (onPopupVisibilityChange) {
				onPopupVisibilityChange(false);
			}
			setIsDropdownOpen(false);
		}, [onPopupVisibilityChange]);

		const { anchorRef, popupRef } = useOutsideClick<HTMLDivElement, HTMLDivElement>(isDropdownOpen, closePopup);

		const themeStore = useInstance(ThemeStore);

		const manuallyOpenDropdown = useCallback(() => {
			if (!hasPopup) {
				setIsDropdownOpen(true);
			}
		}, [hasPopup, setIsDropdownOpen]);

		const handleCellClick = useCallback(() => {
			if (onClick && typeof onClick === 'function') {
				onClick(manuallyOpenDropdown);
			}

			if (hasPopup) {
				setIsDropdownOpen((open) => !open);
			}
		}, [setIsDropdownOpen, hasPopup, onClick, manuallyOpenDropdown]);

		const cellClassName = classNames(styles.cell, className, {
			[styles.cellActive]: active,
			[styles.cellClickable]: hasPopup,
			[styles.cellOpen]: isDropdownOpen,
			[styles.onlyEquals]: active && activeType === ActiveType.onlyIsIn,
			[styles.onlyNotEquals]: active && activeType === ActiveType.onlyIsNotIn,
			[styles.mixedEquality]: active && activeType === ActiveType.mixed,
		});
		const headerClassNames = classNames(styles.cellHeader, headerClassName);
		const popupClassNames = classNames(styles.popup, popupClassName, { dark: themeStore.theme === 'dark' });

		const childrenWithClosePopup = Children.map(children, (child) => {
			if (isValidElement(child)) {
				return cloneElement(child as ReactElement, { closePopup });
			}

			return child;
		});

		return (
			<div className={cellClassName} ref={anchorRef}>
				<div className={headerClassNames} {...buttonize<HTMLDivElement>(handleCellClick)} data-tip={true} data-for={tooltipId}>
					{header}
				</div>
				{tooltip && tooltipText && (
					<Tooltip tooltipId={tooltipId} tooltipContent={tooltipText} offsetTop={TOOLTIP_OFFSET_TOP} delayShow={TOOLTIP_DELAY_SHOW} />
				)}
				{hasPopup && (
					<Popup
						style={{ zIndex: zIndex, minWidth: POPUP_MIN_WIDTH }}
						anchor={anchorRef.current}
						show={isDropdownOpen}
						popupClass={popupClassNames}
						anchorAlign={dropdownAnchorAlign}
						popupAlign={dropdownPopupAlign}
					>
						<div className={styles.popupContent} ref={popupRef}>
							{childrenWithClosePopup}
						</div>
					</Popup>
				)}
			</div>
		);
	},
);

HeaderCell.displayName = 'HeaderCell';
