/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, computed, observable, makeObservable, flow } from 'mobx';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import i18n from '@/translations/i18n';

import { NotificationsStore } from '@/app/_common/stores';
import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { FetchClient } from '@/app/_common/fetch/fetch-client';
import { FetchBaseErrorKey, NotificationMessage } from '@/app/_common/types';
import { FETCH_BASE_ERROR_CONTENT_KEYS, FETCH_BASE_ERROR_TITLE_KEYS } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';

const INITIAL_RESULTS = {
	loading: false,
	error: undefined,
	data: undefined,
};

export class FetchBaseDataStore<T> {
	private notificationsStore = injectInterface(this, NotificationsStore);
	private readonly client!: FetchClient;

	private result: Result<T> = INITIAL_RESULTS;
	private pendingRequests = 0;
	private readonly fetchBaseErrorKey: FetchBaseErrorKey = FetchBaseErrorKey.Default;

	constructor(fetchBaseErrorKey?: FetchBaseErrorKey) {
		if (fetchBaseErrorKey) {
			this.fetchBaseErrorKey = fetchBaseErrorKey;
		}

		Object.defineProperty(this, 'client', {
			value: injectInterface(this, FetchClient),
			enumerable: false,
		});

		makeObservable(
			this,
			{
				//@ts-ignore
				result: observable,
				loading: computed,
				error: computed,
				data: computed,
				onSuccess: action,
				onFailure: action,
				setInitialResults: action,
				setLoading: action,
				fetchApiByAxios: flow,
				fetchImageByFetch: flow,
			},
			{ autoBind: true },
		);
	}

	get loading(): boolean {
		return this.result.loading;
	}

	get error() {
		return this.result.error;
	}

	get data(): T | undefined {
		return this.result.data;
	}

	setInitialResults() {
		this.result = INITIAL_RESULTS;
	}

	setLoading(value: boolean) {
		this.result.loading = value;
	}

	setData(value: T | undefined): void {
		this.result.data = value;
	}

	clear() {
		this.result = INITIAL_RESULTS;
	}

	protected *fetchApiByAxios(endpoint: string, config?: AxiosRequestConfig): any {
		try {
			this.result.loading = true;
			this.pendingRequests += 1;
			const responseData = yield this.client.fetchData<T>(endpoint, config);
			this.onSuccess(responseData);
		} catch (error: any) {
			this.onFailure(error);
		}
	}

	protected async fetchApiMultipleByAxios(endpoint: string, configs: AxiosRequestConfig[]) {
		try {
			this.result.loading = true;
			this.pendingRequests += 1;
			const responseData: any = await this.client.fetchMultipleData<T>(configs, endpoint);
			this.onSuccess(responseData);
		} catch (error: any) {
			this.onFailure(error);
		}
	}

	protected *fetchImageByFetch(name: string, urlSearchParams: URLSearchParams): any {
		try {
			this.result.loading = true;
			this.pendingRequests += 1;
			const responseData = yield this.client.fetchImage(name, urlSearchParams);
			this.onSuccess(responseData);
		} catch (error: any) {
			this.onFailure(error);
		}
	}

	private onSuccess(data: T) {
		this.result.error = undefined;
		this.result.loading = false;
		this.pendingRequests -= 1;
		this.result.data = data;
	}

	private onFailure(error: AxiosError) {
		this.processError(error);
		this.pendingRequests -= 1;
		if (this.pendingRequests === 0) {
			this.result.loading = false;
		}
		this.result.data = undefined;
	}

	private processError = (error: AxiosError) => {
		try {
			const isCancelError = axios.isCancel(error);
			const errorResponse = error.response;

			if (isCancelError) {
				return;
			}

			if (!errorResponse) {
				return process.env.REACT_APP_INTEGRATION_TESTS !== 'true'
					? this.notificationsStore.openError({ title: 'Error', content: 'Network Error' })
					: undefined;
			}

			const errorData = this.getErrorTitleAndContent(error);

			if (errorData) {
				this.result.error = errorData;
				this.notificationsStore.openError(errorData);
			}
		} catch (error) {
			// eslint-disable-next-line
			console.error(error);
		}
	};

	private getErrorTitleAndContent = (error: AxiosError<any>): NotificationMessage => {
		const errorTitleKey = FETCH_BASE_ERROR_TITLE_KEYS[this.fetchBaseErrorKey];
		const errorTitle = i18n.t(errorTitleKey, { ns: Namespaces.Notifications });
		let defaultErrorContent = undefined;

		if (this.fetchBaseErrorKey === FetchBaseErrorKey.AssignQueryResult) {
			const errorContentKey = FETCH_BASE_ERROR_CONTENT_KEYS[this.fetchBaseErrorKey];
			defaultErrorContent = i18n.t(errorContentKey, { ns: Namespaces.Notifications });
		}

		const kustoErrorMessage = this.getKustoErrorMessage(error);
		const defaultErrorMessage = defaultErrorContent ?? error?.response?.statusText ?? error?.message ?? error?.response?.data.error;

		const errorMessage = kustoErrorMessage ?? defaultErrorMessage ?? '';

		const errorData: NotificationMessage = { title: errorTitle, content: errorMessage };

		return errorData;
	};

	private getKustoErrorMessage = (error: AxiosError<any>): string => {
		let kustoErrorMessage = '';

		if (error.response?.request) {
			const parsedResponse = JSON.parse(error.response.request.response);

			kustoErrorMessage = parsedResponse?.error?.['@message'] || parsedResponse.message;
		} else if (typeof error?.response?.data?.error === 'string') {
			kustoErrorMessage = error.response.data.error;
		}

		return kustoErrorMessage;
	};
}

interface Result<T> {
	loading: boolean;
	data?: T;
	error?: NotificationMessage;
}
