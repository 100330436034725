import { useMemo } from 'react';

import { GridPageChangeEvent } from '@progress/kendo-react-grid';
import { DEFAULT_SKELETON_SIZE, getDataGridInfiniteScrollData } from '@/app/_common/utils';
import { DataGridPage } from '../../types';

interface RequiredValue {
	node: {
		id: number | string;
	};
}

interface UseListInfiniteScrollProps<T> {
	isLoading: boolean;
	hasNextPage: boolean;
	data: T[];
	readMore: () => void;
	pageSize: number;
	setPage: (page: DataGridPage) => void;
	pageSkip: number;
}

export const useDataGridInfiniteScroll = <T extends RequiredValue>({
	isLoading,
	hasNextPage,
	data,
	pageSize,
	readMore,
	setPage,
	pageSkip,
}: UseListInfiniteScrollProps<T>) => {
	const handlePageChange = (event: GridPageChangeEvent) => {
		requestIfNeeded(event.page.skip);

		setPage(event.page);
	};

	const requestIfNeeded = (skip: number) => {
		if (isLoading || !hasNextPage) {
			return;
		}

		for (let i = skip; i < skip + pageSize; i++) {
			if (data[i + 1]?.node?.id === undefined) {
				readMore();
				return;
			}
		}
	};

	const infiniteScrollData = useMemo(() => {
		const count = hasNextPage ? data.length + DEFAULT_SKELETON_SIZE : data.length;
		const showSkeleton = data.length < count;

		if (!data.length) {
			return [];
		}

		//@ts-ignore
		const defaultValue: Omit<T, 'node'> = { loading: true, __typename: data[0].__typename, cursor: '' };

		return getDataGridInfiniteScrollData<T>(data, defaultValue, count, showSkeleton);
	}, [data, hasNextPage]);

	return {
		paginatedData: infiniteScrollData.slice(pageSkip, pageSkip + pageSize),
		handlePageChange,
		dataLength: infiniteScrollData.length,
	};
};
