import { GetAssignees } from '@/app/_common/graphql/queries';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { Query, QueryListUsersArgs } from '@/generated/graphql';
import { action, computed, makeObservable } from 'mobx';

export class UsersDataStore extends GraphqlBaseDataStore<Query, QueryListUsersArgs> {
	constructor() {
		super();
		makeObservable(this, {
			users: computed,
			read: action,
		});
	}

	get users() {
		return this.data?.listUsers?.edges;
	}

	read() {
		const variables = {
			tenantId: this.authStore.currentTenantId,
		};

		this.query({
			query: GetAssignees,
			variables,
			fetchPolicy: 'cache-and-network',
		});
	}
}
