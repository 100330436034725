import _remove from 'lodash/remove';

import { QUERY_SEPARATOR, SEPARATOR_WHITESPACE_REGEX } from '@/app/_common/constants';

export const removeLimitFromQueryString = (query: string): string => {
	const limitRegex = /limit\s+\d+/i;
	const limitMatches = query.match(limitRegex);

	if (!limitMatches) {
		return query;
	}
	const splitQuery = query.split(QUERY_SEPARATOR);
	_remove(splitQuery, (substring: string) => limitRegex.test(substring));
	return splitQuery.join(QUERY_SEPARATOR).replace(SEPARATOR_WHITESPACE_REGEX, '').trim();
};
