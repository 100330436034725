import { InvestigationPriority } from '@/generated/graphql';

export const getInvestigationPriorityColor = (value: string): string => {
	switch (value) {
		case InvestigationPriority.Critical:
			return '#d21a1a';
		case InvestigationPriority.High:
			return '#f0703a';
		case InvestigationPriority.Medium:
			return '#ffb802';
		case InvestigationPriority.Low:
			return '#71a20a';

		default:
			return '#3da7e4';
	}
};
