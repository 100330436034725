import { FieldFilter, FieldFilterGroup } from '@/app/_common/types';
import { removeFilterValue } from '@/app/advanced-query/_common/utils';

export const removeFilter = (filter: FieldFilter, filterGroups: FieldFilterGroup[]) => {
	let groups = [...filterGroups];
	const { field, operator, value } = filter;

	const oppositeGroupIndex = groups.findIndex((group) => group.field === field && group.operator !== operator);

	if (oppositeGroupIndex > -1) {
		const group = groups[oppositeGroupIndex];
		const filterValue = group.values.find((v) => v === value);

		if (filterValue !== undefined) {
			groups = removeFilterValue(value, oppositeGroupIndex, groups);
		}
	}

	return groups;
};
