import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import classNames from 'classnames';

import CloseIcon from '@mui/icons-material/Close';

import { Namespaces } from '@/translations/namespaces';
import { Dialog, Button, JsonSyntaxHighlighter } from '@/app/_common/_components';
import { ThemeStore } from '@/app/_common/stores';

import styles from './raw-field-dialog.module.scss';

const DIALOG_WIDTH = 'min(90%, 1600px)';

interface RawFieldDialogProps {
	title?: string;
	onMinimize: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	rawContent: any;
}

export const RawFieldDialog = observer(({ title, onMinimize, rawContent }: RawFieldDialogProps) => {
	const { t } = useTranslation(Namespaces.DetailsView, { keyPrefix: 'rawField' });
	const themeStore = useInstance(ThemeStore);

	return (
		<Dialog width={DIALOG_WIDTH} onClose={onMinimize} className={styles.container}>
			<div className={styles.header}>
				<div className={styles.headerTitleSection}>
					{title && <div className={styles.title}>{title}</div>}
					<div className={classNames(styles.subtitle, { [styles.subtitleCentered]: !title })}>{t('dialog.subtitle')}</div>
				</div>
				<div>
					<Button fillMode="outline" onClick={onMinimize}>
						{t('dialog.closeButton')} <CloseIcon />
					</Button>
				</div>
			</div>
			<div className={styles.body}>
				<JsonSyntaxHighlighter value={rawContent} isDarkTheme={themeStore.isDarkTheme} className={styles.syntaxHighlighter} />
			</div>
		</Dialog>
	);
});
