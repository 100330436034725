import { useCallback, useEffect, useState } from 'react';
import { useInstance } from 'react-ioc';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { AdxSchemaViewStore, AuthStore } from '@/app/_common/stores';
import { AdvancedQueryChartViewStore, AdvancedQueryViewStore, OptionsViewStore } from '@/app/advanced-query/_common/stores';

import { CodeEditor, CodeEditorAPI, setKustoSchema } from '@/app/_common/_components';

import { Namespaces } from '@/translations/namespaces';
import { buttonize } from '@/app/_common/utils';

import LockIcon from '@mui/icons-material/Lock';
import styles from './query-editor.module.scss';

export const QueryEditor = observer(() => {
	const authStore = useInstance(AuthStore);
	const advancedQuerySchemaViewStore = useInstance(AdxSchemaViewStore);
	const advancedQueryViewStore = useInstance(AdvancedQueryViewStore);
	const advancedQueryChartViewStore = useInstance(AdvancedQueryChartViewStore);
	const optionsViewStore = useInstance(OptionsViewStore);

	const { t } = useTranslation([Namespaces.AdvancedQuery], { keyPrefix: 'tabs.advancedQuery.queryEditor' });
	const [isEditorFocused, setIsEditorFocused] = useState(false);

	const [editor, setEditor] = useState<CodeEditorAPI>();

	useEffect(() => {
		if (!editor) return;
		// we're sharing a function for clearing Monaco Editor because there is no other
		// option to get the instance or control the value of the Editor
		optionsViewStore.setClearMonacoEditor(() => editor.getModel()?.setValue?.(''));
		advancedQueryViewStore.setChangeValueMonacoEditor((value: string) => editor.getModel()?.setValue(value));

		return () => {
			optionsViewStore.setClearMonacoEditor(null);
		};
	}, [editor, optionsViewStore, advancedQueryViewStore]);

	useEffect(() => {
		if (editor) {
			setKustoSchema(editor, advancedQuerySchemaViewStore.schema, authStore.currentTenantId);
		}
	}, [editor, advancedQuerySchemaViewStore.schema, authStore.currentTenantId]);

	const handleEditorMount = useCallback((editor: CodeEditorAPI) => {
		setEditor(editor);
	}, []);

	const handleQueryChange = useCallback(
		(value: string) => {
			advancedQueryViewStore.setDraftQuery(value);
		},
		[advancedQueryViewStore],
	);

	const handleQuerySubmit = useCallback(() => {
		advancedQueryViewStore.runPersistentQuery();
		advancedQueryChartViewStore.fetchChartDataWithAutoInterval();
	}, [advancedQueryViewStore, advancedQueryChartViewStore]);

	const handleEmptyClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			event.stopPropagation();
			setIsEditorFocused(true);
			editor?.focus();
		},
		[editor],
	);

	const empty = !isEditorFocused && advancedQueryViewStore.draftQuery === '' && advancedQueryViewStore.hasSchema;
	const noSchema = !advancedQueryViewStore.hasSchema;

	return (
		<div className={classNames(styles.queryEditor, { [styles.empty]: empty, [styles.noSchema]: noSchema })} data-testid="query-editor">
			<CodeEditor
				language="kusto"
				onMount={handleEditorMount}
				onSubmit={handleQuerySubmit}
				onChange={handleQueryChange}
				defaultValue={advancedQueryViewStore.draftQuery}
			/>
			{empty && (
				<>
					{/* @ts-ignore */}
					<div className={styles.emptyTextContainer} {...buttonize<HTMLDivElement>(handleEmptyClick)} data-testid="query-editor-empty">
						<h2 className={styles.emptyText}>{t('empty.title')}</h2>
					</div>
				</>
			)}
			{noSchema && (
				<div className={styles.noSchemaContainer} data-testid="query-editor-no-schema">
					<LockIcon className={styles.noSchemaIcon} />
					<h2 className={styles.noSchemaTitle}>{t('noSchema.title')}</h2>
					<span className={styles.noSchemaText}>{t('noSchema.message')}</span>
				</div>
			)}
		</div>
	);
});
