import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertDetailsKeyPrefix } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';

import styles from './threat-intelligence-panel-content-references-section.module.scss';

interface ThreatIntelligencePanelContentReferencesSectionProps {
	references: string[];
	className?: string;
}

export const ThreatIntelligencePanelContentReferencesSection: FC<ThreatIntelligencePanelContentReferencesSectionProps> = ({
	references,
	className,
}) => {
	const { t } = useTranslation(Namespaces.DetailsView, { keyPrefix: AlertDetailsKeyPrefix.ThreatIntelligencePanel });

	if (!references.length) {
		return null;
	}

	return (
		<div className={className}>
			<h4>{t('references')}</h4>
			<div className={styles.referencesContainer}>
				{references.map((reference) => (
					<a key={reference} href={reference} className={styles.reference} target="_blank" rel="noreferrer">
						{reference}
					</a>
				))}
			</div>
		</div>
	);
};
