import { observer } from 'mobx-react-lite';

import { MultiValueCell } from '@/app/_common/_components/data-grid/_components';
import { QueryResultsTableCellWithStoreProps } from '@/app/_common/_components/query-results/query-results-table/types';
import { useQueryResultsTableStore } from '@/app/_common/_components/query-results/query-results-table/components';

export const QueryResultsTableStandardCell = observer((props: QueryResultsTableCellWithStoreProps) => {
	const { searchValue } = useQueryResultsTableStore();

	return <MultiValueCell {...props} searchValue={searchValue} oldContextMenu={false} />;
});

QueryResultsTableStandardCell.displayName = 'QueryResultsTableStandardCell';
