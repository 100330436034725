import { FC, ReactElement } from 'react';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { AppConfigurationViewStore } from '@/app/_common/stores/app-configuration-store/app-configuration.view-store';
import { Tile } from '@/app/_common/types';
import { Tile as TileComponent } from '@/app/_common/_components';

import styles from './tiles-container.module.scss';

interface TilesContainerProps {
	className?: string;
	tiles: Tile[];
	noTilesFallbackComponent?: ReactElement;
}

export const TilesContainer: FC<TilesContainerProps> = observer(({ className = '', tiles = [], noTilesFallbackComponent = null }) => {
	const appConfigurationViewStore = useInstance(AppConfigurationViewStore);

	const availableTiles = tiles
		.filter(({ feature }) => appConfigurationViewStore.userHasAccess(feature))
		.map((tile) => <TileComponent key={tile.title} {...tile} />);

	if (!availableTiles.length) {
		return noTilesFallbackComponent;
	}

	return (
		<section className={classNames(styles.container, className)} data-testid="tiles-container">
			{availableTiles}
		</section>
	);
});
