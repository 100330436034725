import { forwardRef, PropsWithChildren } from 'react';
import classNames from 'classnames';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import { buttonize } from '@/app/_common/utils';
import { LoadingSpinner } from '@/app/_common/_components';
import { FilterPanelFooter } from '@/app/_common/_components/filter-panel';

import styles from './filter-panel.module.scss';

interface FilterPanelProps {
	className?: string;
	title: string;
	subTitle: string;
	isFavorite: boolean;
	hideFooter?: boolean;
	loading: boolean;
	onFavoriteClick?: () => void;
	onApplyClick?: () => void;
	onCancelClick?: () => void;
}

export const FilterPanel = forwardRef<HTMLDivElement, PropsWithChildren<FilterPanelProps>>(
	({ children, isFavorite, className, title, subTitle, hideFooter = true, loading = false, onCancelClick, onApplyClick, onFavoriteClick }, ref) => {
		const handleFavoriteClick = () => {
			if (onFavoriteClick) {
				onFavoriteClick();
			}
		};

		if (!children || loading) {
			return (
				<div className={classNames(styles.container, className, { [styles.loading]: loading })} ref={ref} data-testid="filter-panel">
					<LoadingSpinner />
				</div>
			);
		}

		return (
			<div className={classNames(styles.container, className)} ref={ref} data-testid="filter-panel">
				<div className={styles.header}>
					<div className={styles.title}>{title}</div>
					<div className={styles.subtitle}>{subTitle}</div>
					<div className={styles.favorite} {...buttonize<HTMLDivElement>(handleFavoriteClick)} data-testid="favorite-indicator">
						{isFavorite ? <StarIcon data-testid="favorite" /> : <StarBorderIcon data-testid="not-favorite" />}
					</div>
				</div>
				<div>{children}</div>
				{!hideFooter && <FilterPanelFooter onCancel={onCancelClick} onApply={onApplyClick} />}
			</div>
		);
	},
);

FilterPanel.displayName = 'FilterPanel';
