import { makeAutoObservable } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';

import { migrateToNewestVersion } from '@/app/_common/utils';
import { TILE_LAYOUT_ALERTS_ROW_SPAN, TILE_LAYOUT_COLUMNS_NUMBER, TILE_LAYOUT_INVESTIGATIONS_ROW_SPAN } from '@/app/_common/constants';
import { TileWidgetGraphType, WidgetKeys, Position } from '@/app/dashboards/alerts-dashboard/types';

const DASHBOARD_PERSISTABLE_KEY = 'dashboardGrid';
const DASHBOARD_VERSION = 'v5'; // change it when you're adding new widgets

export const positions = [
	{ col: 1, colSpan: 2, rowSpan: 2, order: 1, widgetKey: WidgetKeys.alertCounter, expanded: true },
	{ col: 3, colSpan: 2, rowSpan: 2, order: 2, widgetKey: WidgetKeys.severityCounter, expanded: true },
	{ col: 5, colSpan: 2, rowSpan: 2, order: 3, widgetKey: WidgetKeys.detectedByCounter, expanded: true },
	{ col: 1, colSpan: TILE_LAYOUT_COLUMNS_NUMBER, rowSpan: TILE_LAYOUT_ALERTS_ROW_SPAN, order: 4, widgetKey: WidgetKeys.alerts, expanded: true },
	{
		col: 1,
		colSpan: TILE_LAYOUT_COLUMNS_NUMBER,
		rowSpan: TILE_LAYOUT_INVESTIGATIONS_ROW_SPAN,
		order: 5,
		widgetKey: WidgetKeys.investigations,
		expanded: true,
	},
];

const initialTileWidgetGraphTypes = {
	[WidgetKeys.alertCounter]: TileWidgetGraphType.None,
	[WidgetKeys.severityCounter]: TileWidgetGraphType.None,
	[WidgetKeys.detectedByCounter]: TileWidgetGraphType.None,
};

interface State {
	currentPositions?: Position[];
	movingPositions: Position[];
	editable?: boolean;
	version: string;
	tileWidgetGraphTypes: Record<string, TileWidgetGraphType>;
}

export class DashboardPageViewStore {
	private state: State = {
		currentPositions: Object.values(positions),
		movingPositions: Object.values(positions),
		editable: false,
		version: DASHBOARD_VERSION,
		tileWidgetGraphTypes: initialTileWidgetGraphTypes,
	};

	constructor() {
		migrateToNewestVersion(DASHBOARD_PERSISTABLE_KEY, DASHBOARD_VERSION);

		makeAutoObservable(this, undefined, { autoBind: true });

		makePersistable(this.state, {
			name: DASHBOARD_PERSISTABLE_KEY,
			properties: ['currentPositions', 'editable', 'movingPositions', 'version', 'tileWidgetGraphTypes'],
			storage: window.localStorage,
		});
	}

	get editable(): boolean | undefined {
		return this.state.editable;
	}

	get currentPositions(): Position[] | undefined {
		return this.state.currentPositions;
	}

	get movingPositions(): Position[] | undefined {
		return this.state.movingPositions;
	}

	public isTileWidgetExpanded = (widgetKey: WidgetKeys): boolean => {
		const isExpanded = this.state.currentPositions?.some((position: Position) => {
			return position.widgetKey === widgetKey && position.expanded;
		});
		return Boolean(isExpanded);
	};

	getTileWidgetGraphType = (widgetKey: WidgetKeys): TileWidgetGraphType => {
		return this.state.tileWidgetGraphTypes[widgetKey] || TileWidgetGraphType.None;
	};

	setEditable = (editable: boolean) => {
		this.state.editable = editable;
	};

	toggleIsTileWidgetExpanded = (widgetKey: WidgetKeys): void => {
		const positions =
			this.state.currentPositions?.map((item: Position) => {
				const isItemSelected = item.widgetKey === widgetKey;
				if (!isItemSelected) {
					return item;
				}

				const newExpandedState = !item.expanded;

				return {
					...item,
					expanded: newExpandedState,
					rowSpan: newExpandedState ? 2 : 1,
				};
			}) || [];

		this.setCurrentPositions(positions);
		this.setMovingPositions(positions);
	};

	setTileWidgetGraphType = (widgetKey: WidgetKeys, type: TileWidgetGraphType): void => {
		this.state.tileWidgetGraphTypes[widgetKey] = type;
	};

	setCurrentPositions = (currentPositions: Position[]) => {
		this.state.currentPositions = currentPositions;
	};

	setMovingPositions = (currentPositions: Position[]) => {
		this.state.movingPositions = currentPositions;
	};

	resetMovingPositions = () => {
		this.state.movingPositions = this.state.currentPositions as Position[];
	};

	dispose = () => {
		stopPersisting(this.state);
	};
}
