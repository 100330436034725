import { action, makeObservable } from 'mobx';
import { DocumentNode } from 'graphql';

import { Query } from '@/generated/graphql';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';

export type GetBreadcrumbName = Pick<Query, 'getInvestigation' | 'getCollector' | 'getIntegration'>;

export type QueryIDs = { [key: string]: string };

export class BreadcrumbsDataStore extends GraphqlBaseDataStore<GetBreadcrumbName, QueryIDs> {
	constructor() {
		super();
		makeObservable(this, {
			read: action,
		});
	}

	read(query: DocumentNode, queryIDs: QueryIDs) {
		if (!this.authStore.loggedIn) {
			return;
		}

		const variables = {
			...queryIDs,
			tenantId: this.authStore.currentTenantId,
		};

		this.query({
			query: query,
			variables,
		});
	}
}
