import { action, computed, makeObservable } from 'mobx';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { AuthStore } from '@/app/_common/stores';
import { FetchBaseDataStore } from '@/app/_common/fetch';
import { AdxEndpoints } from '@/app/_common/constants';
import { AvailableFieldsResultsDataItem, DataTable, FetchBaseErrorKey } from '@/app/_common/types';

const API_URL = process.env.REACT_APP_ADVANCED_QUERY_URL;
const ERROR_KEY = FetchBaseErrorKey.AvailableFields;

export class AvailableFieldsDataStore extends FetchBaseDataStore<AvailableFieldsResultsDataItem[]> {
	private authStore = injectInterface({}, AuthStore);
	private cancelSource?: CancelTokenSource;

	constructor() {
		super(ERROR_KEY);

		makeObservable(this, {
			fields: computed,
			clear: action,
			fetch: action,
		});
	}

	get fields(): AvailableFieldsResultsDataItem[] | undefined {
		return this.data ?? undefined;
	}

	public setEmptyData(): void {
		this.setData([]);
	}

	public clear = () => {
		this.setInitialResults();
	};

	public fetch = (fieldsQuery: string, scopeName: string) => {
		this.cancelSource = axios.CancelToken.source();

		const fieldsConfig: AxiosRequestConfig = {
			method: 'POST',
			url: `${API_URL}/${AdxEndpoints.QueryV2}`,
			data: { tenantId: this.authStore.currentTenantId, query: fieldsQuery },
			cancelToken: this.cancelSource.token,
			transformResponse: [this.formatResults],
			params: { scope: scopeName },
		};

		return this.fetchApiByAxios('', fieldsConfig);
	};

	private formatResults = (response: string): AvailableFieldsResultsDataItem[] => {
		try {
			const parsedResponse: DataTable[] = JSON.parse(response);
			return parsedResponse[0].Rows.reduce<AvailableFieldsResultsDataItem[]>((result, [value, count]) => {
				if (typeof value === 'string' && typeof count === 'number') {
					result.push({
						value,
						count,
					});
				}

				return result;
			}, []);
		} catch (error) {
			return [];
		}
	};

	public cancelRequest() {
		this.cancelSource?.cancel();
		this.setLoading(false);
	}

	dispose = () => {
		this.cancelRequest();
	};
}
