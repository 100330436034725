import { AlertCount } from '@/generated/graphql';
import { AlertTypename } from '@/app/_common/constants';
import { SetAlertsStateUpdateCacheFn } from '@/app/_features/alerts-actions/_common/stores/_common/types';
import { setAlertStateListAlertsUpdate } from '@/app/dashboards/alerts-dashboard/_common/utils/cache-updates';

export const updateCacheUndismissAlertsDashboard: SetAlertsStateUpdateCacheFn = (cache, { data }) => {
	if (!data?.setAlertsState || data?.setAlertsState.__typename !== AlertTypename.Alerts) {
		return;
	}

	const alerts = data?.setAlertsState?.alerts || [];

	if (!alerts.length) {
		return;
	}

	cache.modify({
		fields: {
			getAlertCount(existingAlertCount: AlertCount) {
				return {
					...(existingAlertCount || {}),
					assignment: {
						...existingAlertCount.assignment,
						unassigned: existingAlertCount.assignment.unassigned + alerts.length,
						dismissed: existingAlertCount.assignment.dismissed - alerts.length,
					},
				};
			},
			listAlerts: setAlertStateListAlertsUpdate(cache, alerts),
		},
	});
};
