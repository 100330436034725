import NotificationIcon from '@mui/icons-material/NotificationImportant';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ListIcon from '@mui/icons-material/List';

import { DetailsViewTypename } from '@/app/_common/_components/details-view/types';

enum DataItemColor {
	Alert = 'var(--alertDataItemColor)',
	Evidence = 'var(--evidenceDataItemColor)',
	Event = 'var(--eventDataItemColor)',
}

export const detailsViewTypenameToIconMapping: Record<DetailsViewTypename, JSX.Element | null> = {
	[DetailsViewTypename.ALERT]: <NotificationIcon htmlColor={DataItemColor.Alert} />,
	[DetailsViewTypename.INVESTIGATION_ALERT_SUMMARY]: <NotificationIcon htmlColor={DataItemColor.Alert} />,
	[DetailsViewTypename.EVENT]: <ListIcon htmlColor={DataItemColor.Event} />,
	[DetailsViewTypename.EVIDENCE]: <FingerprintIcon htmlColor={DataItemColor.Evidence} />,
	[DetailsViewTypename.UNKNOWN]: null,
};
