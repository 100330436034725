import { FC } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Field, Form, FormElement, FormProps } from '@progress/kendo-react-form';

import { EnvironmentVariable } from '@/generated/graphql';
import { Namespaces } from '@/translations/namespaces';
import { NameValidationRegEx } from '@/app/_common/constants';
import { FormInput, MultipleColumnsLayout } from '@/app/_common/_components';
import { CreateFormFields } from './_utils';
import { DescriptionField, NameField } from '@/app/_common/_components/integration-details-form/types';
import { useIntegrationDetailsFormValidator } from '@/app/_common/_components/integration-details-form/hooks';
import { NAME_MAX_LENGTH, NAME_MIN_LENGTH, TEXT_MAX_LENGTH } from '@/app/_common/_components/integration-details-form/constants';

import styles from './integration-details-form.module.scss';

const enum ColumnName {
	Static = 'static',
	Dynamic = 'dynamic',
}

interface IntegrationDetailsFormProps {
	isFormDisabled?: boolean;
	initialValues?: Record<string, string>;
	register: UseFormRegister<FieldValues>;
	environments: Record<string, EnvironmentVariable>[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: { [key: string]: any };
}

export const IntegrationDetailsForm: FC<IntegrationDetailsFormProps> = ({ isFormDisabled, initialValues, register, environments, errors }) => {
	const { t } = useTranslation([Namespaces.CreateIntegration]);
	const formValidator = useIntegrationDetailsFormValidator();

	const integrationNameField = (
		<div className={styles.field} key={'integrationNameField'}>
			<Field
				id={NameField.Name}
				label={t(NameField.Label)}
				type={NameField.Type}
				component={FormInput}
				touched={true}
				placeholder={isFormDisabled ? undefined : t(NameField.Placeholder)}
				optional={false}
				valid={false}
				disabled={isFormDisabled}
				{...register(NameField.Name as string, {
					required: true,
					maxLength: NAME_MAX_LENGTH,
					minLength: NAME_MIN_LENGTH,
					pattern: NameValidationRegEx,
				})}
			/>
		</div>
	);

	const descriptionField = (
		<div className={styles.field} key={'descriptionField'}>
			<Field
				id={DescriptionField.Name}
				label={t(DescriptionField.Label)}
				type={DescriptionField.Type}
				component={FormInput}
				touched={true}
				placeholder={isFormDisabled ? undefined : t(DescriptionField.Placeholder)}
				optional={true}
				valid={false}
				disabled={isFormDisabled}
				{...register(DescriptionField.Name as string, { required: false, maxLength: TEXT_MAX_LENGTH })}
			/>
		</div>
	);

	const dynamicForm = CreateFormFields(environments, register, errors, isFormDisabled);
	const staticFields = [integrationNameField, descriptionField];
	const columns = [
		{ id: ColumnName.Static, fields: staticFields },
		{ id: ColumnName.Dynamic, fields: dynamicForm },
	];

	return (
		<div className={styles.integrationDetailsForm}>
			<Form
				initialValues={initialValues}
				key={JSON.stringify(initialValues)}
				validator={formValidator as FormProps['validator']}
				render={() => (
					<FormElement>
						<fieldset className={styles.fieldset}>
							<MultipleColumnsLayout columns={columns} />
						</fieldset>
					</FormElement>
				)}
			/>
		</div>
	);
};
