import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/app/_common/_components';
import { Namespaces } from '@/translations/namespaces';

import styles from './grid-no-records-content.module.scss';

interface GridNoRecordsContentProps {
	onFiltersReset: () => void;
}

export const GridNoRecordsContent: FC<GridNoRecordsContentProps> = ({ onFiltersReset }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.container}>
			<div>{t('tabs.advancedQuery.results.empty.noResultsForFilterConfigurations', { ns: Namespaces.AdvancedQuery })}</div>
			<Button fillMode="outline" onClick={onFiltersReset}>
				{t('resetFilters', { ns: Namespaces.Common })}
			</Button>
		</div>
	);
};
