import { action, computed, makeObservable } from 'mobx';

import { GetAlert } from '@/app/_common/graphql/queries';
import { GraphqlBaseDataStore } from '@/app/_common/graphql/graphql-base.data-store';
import { Alert, Query, QueryGetAlertArgs } from '@/generated/graphql';

export class AlertDataStore extends GraphqlBaseDataStore<Query, QueryGetAlertArgs> {
	constructor() {
		super();
		makeObservable(this, {
			alert: computed,
			read: action,
		});
	}

	get alert() {
		return this.data?.getAlert as Alert;
	}

	read(alertId: string) {
		const variables = {
			tenantId: this.authStore.currentTenantId,
			id: alertId,
			getAlertId: alertId,
		};

		this.query({
			query: GetAlert,
			variables,
			fetchPolicy: 'cache-and-network',
		});
	}
}
