import { TextArea as KendoTextArea, TextAreaProps as KendoTextAreaProps } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';

import classNames from 'classnames';

import styles from './text-area.module.scss';

interface TextAreaProps extends KendoTextAreaProps {
	label?: string;
}

export const TextArea = ({ label, ...textareaProps }: TextAreaProps) => {
	const { id, valid, disabled } = textareaProps;
	return (
		<>
			{label && (
				<Label className={classNames(styles.label)} editorId={id} editorValid={valid} editorDisabled={disabled}>
					{label}
				</Label>
			)}
			<KendoTextArea {...textareaProps} className={classNames(styles.textarea, textareaProps.className, { error: !valid })} />
		</>
	);
};
