import { AlertTypename, InvestigationTypename } from '@/app/_common/constants';
import { AlertCount, Investigation } from '@/generated/graphql';
import { ApolloCache } from '@apollo/client';

export const cacheUpdateHelperUnassignDashboard = (cache: ApolloCache<unknown>, unassignedAlertIds: string[], investigations: Investigation[]) => {
	for (const alertId of unassignedAlertIds) {
		const identifier = cache.identify({
			__typename: AlertTypename.Alert,
			id: alertId,
		});

		cache.modify({
			id: identifier,
			fields: {
				state() {
					return null;
				},
				investigationSummary() {
					return null;
				},
			},
		});
	}

	for (const investigation of investigations) {
		const { id, alerts, last_updated } = investigation;

		const identifier = cache.identify({
			__typename: InvestigationTypename.Investigation,
			id,
		});

		cache.modify({
			id: identifier,
			fields: {
				last_updated() {
					return last_updated;
				},
				alerts() {
					return alerts;
				},
			},
		});
	}

	cache.modify({
		fields: {
			getAlertCount(existingAlertCount: AlertCount) {
				return {
					...(existingAlertCount ?? {}),
					assignment: {
						...(existingAlertCount?.assignment ?? {}),
						assigned: existingAlertCount?.assignment?.assigned - unassignedAlertIds.length,
						unassigned: existingAlertCount?.assignment?.unassigned + unassignedAlertIds.length,
					},
				};
			},
		},
	});
};
