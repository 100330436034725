import { Editor, Transforms, Range, Element } from 'slate';
import { EditorType, CustomEditor } from '@/app/_common/_components/rich-text-editor';

export const useLink = () => {
	const createLinkNode = (href: string, text: string) => {
		return {
			type: EditorType.Link,
			href,
			children: [{ text }],
		};
	};

	const removeLink = (editor: CustomEditor) => {
		Transforms.unwrapNodes(editor, {
			match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === EditorType.Link,
		});
	};

	const insertLink = (editor: CustomEditor, url: string | null) => {
		if (!url) {
			return;
		}

		const { selection } = editor;

		const link = createLinkNode(url, EditorType.Link);

		if (!selection) {
			Transforms.insertNodes(editor, { type: EditorType.Paragraph, children: [link] });
			return;
		}

		if (Range.isCollapsed(selection)) {
			Transforms.insertNodes(editor, link, { select: true });
		} else {
			Transforms.wrapNodes(editor, link, { split: true });
		}
	};

	return { createLinkNode, insertLink, removeLink };
};
