import { FC, useCallback } from 'react';
import { TFunction } from 'i18next';

import { ALERT_ACTIONS, AlertActionProps } from '@/app/_common/types';
import { DetailsViewCloseButton } from '@/app/_common/_components/details-view/details-view-header/_components/header-actions/_components/details-view-close-button';
import { invokeConditionally } from '@/app/_common/utils';
import { Button, ButtonSize } from '@/app/_common/_components/button';
import { HeaderAlertActions } from '@/app/_common/_components/details-view/details-view-header/_components';
import { DetailsViewItemData, DetailsViewTypename } from '@/app/_common/_components/details-view/types';

import styles from './header-actions.module.scss';

interface HeaderActionsProps {
	t: TFunction;
	typename: DetailsViewTypename;
	handleCloseButtonClick: () => void;
	assignOrCreateInvestigation?: () => void;
	selectedItems?: DetailsViewItemData[];
	selectedItem?: DetailsViewItemData;
	singleActions?: AlertActionProps[];
	singleActionsTitle?: string;
	bulkActions?: AlertActionProps[];
	bulkActionsTitle?: string;
}

export const HeaderActions: FC<HeaderActionsProps> = ({
	t,
	typename,
	handleCloseButtonClick,
	assignOrCreateInvestigation,
	selectedItems,
	selectedItem,
	singleActions = [],
	singleActionsTitle,
	bulkActions = [],
	bulkActionsTitle,
}) => {
	const singleAssignIsAvailable = singleActions.some(
		(action) =>
			action.id === ALERT_ACTIONS.AssignOrCreateInvestigation && invokeConditionally(action.show, []) && !invokeConditionally(action.disabled, []),
	);
	const bulkAssignIsAvailable = bulkActions.some(
		(action) => action.id === ALERT_ACTIONS.AssignOrCreateSelectedInvestigation && invokeConditionally(action.show, []),
	);

	const isAlert = [DetailsViewTypename.ALERT, DetailsViewTypename.INVESTIGATION_ALERT_SUMMARY].includes(typename);
	const showAssignButton = isAlert && (singleAssignIsAvailable || bulkAssignIsAvailable);

	const handleAssignOrCreateInvestigation = useCallback(() => {
		if (assignOrCreateInvestigation) {
			assignOrCreateInvestigation();
		}
	}, [assignOrCreateInvestigation]);

	return (
		<div className={styles.buttonsContainer}>
			{showAssignButton && (
				<Button size={ButtonSize.Small} fillMode="outline" className={styles.actionButton} onClick={handleAssignOrCreateInvestigation}>
					{t('assignOrCreateInvestigation')}
				</Button>
			)}
			{typename === DetailsViewTypename.ALERT && (
				<HeaderAlertActions
					selectedItems={selectedItems}
					selectedItem={selectedItem}
					singleActions={singleActions}
					singleActionsTitle={singleActionsTitle}
					bulkActions={bulkActions}
					bulkActionsTitle={bulkActionsTitle}
				/>
			)}
			<DetailsViewCloseButton buttonClassName={styles.actionButton} handleCloseButtonClick={handleCloseButtonClick} />
		</div>
	);
};
