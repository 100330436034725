import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';

import { LoadingSpinner } from '@/app/_common/_components';
import {
	AdvancedQueryResultsTableViewStore,
	AdvancedQueryViewStore,
	ResultsDataStore,
	TotalCountDataStore,
} from '@/app/advanced-query/_common/stores';

import { QueryResultsTableEmptyResults, QueryResultsTableHeader } from '@/app/_common/_components/query-results/query-results-table/components';
import { QueryResultsTableModule } from '@/app/_common/_components/query-results/query-results-table/query-results-table-module';
import { ContextMenuContentKey } from '@/app/_common/_components/query-results/query-results-table/types';
import { AdvancedQueryAlertDetailsViewStore } from '@/app/_common/_components/details-view/stores';

import styles from './advanced-query-results-table.module.scss';

export const AdvancedQueryResultsTable = observer(() => {
	const queryStore = useInstance(AdvancedQueryViewStore);
	const queryResultsTableStore = useInstance(AdvancedQueryResultsTableViewStore);
	const dataStore = useInstance(ResultsDataStore);
	const { totalCount } = useInstance(TotalCountDataStore);
	const alertDetailsViewStore = useInstance(AdvancedQueryAlertDetailsViewStore);

	const { loading, error, isDataEmpty } = dataStore;

	if (loading) {
		return <LoadingSpinner />;
	}

	return (
		<div className={styles.resultsContainer} data-testid="results">
			<QueryResultsTableHeader className={styles.header} searchBoxClassName={styles.searchBox} store={queryResultsTableStore} />
			{isDataEmpty ? (
				<QueryResultsTableEmptyResults hasQueryBeenPerformed={Boolean(queryStore.query)} error={error} />
			) : (
				<QueryResultsTableModule
					store={queryResultsTableStore}
					totalQueryResultsCount={totalCount}
					contextMenuContentKey={ContextMenuContentKey.Results}
					itemDetailsId={alertDetailsViewStore.selectedAlertId}
				/>
			)}
		</div>
	);
});

AdvancedQueryResultsTable.displayName = 'AdvancedQueryResultsTable';
