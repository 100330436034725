import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { APP_ENTRY_ROUTE } from '@/app/_common/constants/app.constants';
import { Button, ButtonSize } from '@/app/_common/_components/button';
import pageNotFoundLogo from '@/assets/images/page-not-found-logo.svg';

import styles from './page-not-found.module.scss';
import { Namespaces } from '@/translations/namespaces';

export const PageNotFound: FC = () => {
	const { t } = useTranslation([Namespaces.PageNotFount]);

	return (
		<section className={styles.pageNotFound}>
			<div className={styles.wrapper}>
				<img src={pageNotFoundLogo} alt="page-no-found" className={styles.image} />
				<div className={styles.content}>
					<span className={styles.status}>{t('status')}</span>
					<span className={styles.title}>{t('title')}</span>
					<span className={styles.description}>{t('description')}</span>

					<NavLink to={APP_ENTRY_ROUTE}>
						<Button className={styles.btn} size={ButtonSize.Large}>
							{t('goToAppEntryPage')}
						</Button>
					</NavLink>
				</div>
			</div>
		</section>
	);
};
export default PageNotFound;
