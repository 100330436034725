import { RichTextEditorTranslations } from '@/app/_common/_components/rich-text-editor/types';
import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

const PATH = 'richTextEditor';

const translate = (key: string, ns: Namespaces) => i18n.t(`${PATH}.${key}`, { ns });

export const getRichTextEditorTranslations = (ns: Namespaces): RichTextEditorTranslations => ({
	placeholder: translate('placeholder', ns),
	createNewItem: translate('createNewItem', ns),
	link: translate('link', ns),
	addLink: translate('addLink', ns),
	errorLink: translate('errorLink', ns),
	cancel: translate('cancel', ns),
	save: translate('save', ns),
	confirm: translate('confirm', ns),
	delete: translate('delete', ns),
	deleteTable: translate('deleteTable', ns),
	deleteTableDescription: translate('deleteTableDescription', ns),
	externalLinkTitle: translate('externalLinkTitle', ns),
	externalLinkSubtitle: translate('externalLinkSubtitle', ns),
	externalLinkDescFirstParagraph: translate('externalLinkDescFirstParagraph', ns),
	externalLinkDescSecondParagraph: translate('externalLinkDescSecondParagraph', ns),
	continueButton: translate('continueButton', ns),
	maximizeIcon: translate('maximizeIcon', ns),
	minimizeIcon: translate('minimizeIcon', ns),
});
