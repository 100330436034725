import moment from 'moment';

import { DateTimePickerOptionKeys, KUSTO_QUERY_DATE_TIME_FORMAT, RELATIVE_DATE_TIME_RANGES_AS_SECONDS } from '@/app/_common/constants';
import { TimeRangeFilterOption, TimeRangeHistoryFilterOption } from '@/app/_common/types';

export const convertTimeRangeToCustom = (timeRange: TimeRangeFilterOption): TimeRangeHistoryFilterOption => {
	const format = KUSTO_QUERY_DATE_TIME_FORMAT;
	const { key } = timeRange;
	let fromTimeValue;
	let toTimeValue;

	const dateNow = Date.now();
	if (key === DateTimePickerOptionKeys.CUSTOM) {
		const fromTime = timeRange.value?.from || dateNow;
		const toTime = timeRange.value?.to || dateNow;
		fromTimeValue = moment.utc(fromTime);
		toTimeValue = moment.utc(toTime);
	} else {
		const fromTimeAsSeconds = RELATIVE_DATE_TIME_RANGES_AS_SECONDS[key];
		fromTimeValue = moment.utc(dateNow).subtract(fromTimeAsSeconds, 'second');
		toTimeValue = moment.utc(dateNow);
	}

	return {
		key: DateTimePickerOptionKeys.CUSTOM,
		value: {
			from: fromTimeValue.format(format),
			to: toTimeValue.format(format),
		},
		isTemporal: false,
	};
};
