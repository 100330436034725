import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Namespaces } from '@/translations/namespaces';
import { SummarySectionItemTitle } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-section-item-title';
import { getFormattedDate } from '@/app/_common/utils';

import styles from './alert-details-timestamp.module.scss';

interface AlertDetailsTimestampProps {
	timestamp: string;
	className?: string;
}

export const AlertDetailsTimestamp = ({ timestamp, className }: AlertDetailsTimestampProps) => {
	const { t } = useTranslation(Namespaces.DetailsView);

	const formattedTimestamp = getFormattedDate(timestamp);

	return (
		<div className={classNames(styles.container, className)}>
			<SummarySectionItemTitle title={t('summary.timestamp')} />
			<p className={styles.value}>{formattedTimestamp}</p>
		</div>
	);
};
