import { makeObservable, computed, action, observable } from 'mobx';
import i18n from '@/translations/i18n';
import _isObject from 'lodash/isObject';

import { injectInterface } from '@/app/_common/ioc/inject-interface';
import { Alert, AlertState, Investigation, InvestigationStatus } from '@/generated/graphql';
import { Namespaces } from '@/translations/namespaces';

import { NotificationsStore } from '@/app/_common/stores';
import { AlertsDataStore } from './alerts.data-store';
import { InvestigationsDataStore } from './investigations.data-store';
import { AlertActions, AlertExtended } from '../types';
import { AlertsActionDataStore } from './alerts-action.data-store';

export class AlertsActionDialogViewStore {
	private alertsDataStore = injectInterface(this, AlertsDataStore);
	private investigationsDataStore = injectInterface(this, InvestigationsDataStore);
	private alertsActionDataStore = injectInterface(this, AlertsActionDataStore);
	private notificationsStore = injectInterface(this, NotificationsStore);
	private action?: AlertActions;

	constructor() {
		makeObservable(this, {
			// @ts-ignore - for protected/private fields
			action: observable,
			alerts: computed,
			investigations: computed,
			loading: computed,
			submitLoading: computed,
			actionableAlerts: computed,
			read: action,
			setAction: action,
			submitAction: action,
		});
	}

	get investigations(): Investigation[] {
		return Object.values(this.investigationsDataStore.investigations) || [];
	}

	get alerts(): AlertExtended[] {
		const alerts: Alert[] = Object.values(this.alertsDataStore.alerts) || [];

		return alerts.map((alert) => {
			const alertInvestigationSummary = alert.investigationSummary;

			if (alertInvestigationSummary) {
				const investigation = this.investigations.find((investigation) => investigation.id === alertInvestigationSummary.id);
				const name = investigation?.name;
				const status = investigation?.status;

				if (name && status) {
					return {
						...alert,
						investigationSummary: {
							...alertInvestigationSummary,
							name,
							status,
						},
					};
				}
			}

			return alert;
		});
	}

	get actionableAlerts() {
		switch (this.action) {
			case AlertActions.Assign:
				return this.alerts.filter((alert) => !alert.investigationSummary);
			case AlertActions.Unassign:
				return this.alerts.filter((alert) => alert.investigationSummary && alert.investigationSummary.status !== InvestigationStatus.Closed);
			case AlertActions.Dismiss:
				return this.alerts.filter(
					(alert) => alert.state?.alertState !== AlertState.Dismissed && alert.investigationSummary?.status !== InvestigationStatus.Closed,
				);
			case AlertActions.Undismiss:
				return this.alerts.filter((alert) => alert.state?.alertState === AlertState.Dismissed);
			default:
				return this.alerts;
		}
	}

	get loading() {
		return this.alertsDataStore.loading || this.investigationsDataStore.loading;
	}

	get submitLoading() {
		return this.alertsActionDataStore.loading;
	}

	setAction(action: AlertActions) {
		this.action = action;
	}

	async submitAction(alertIds: string[]) {
		let includesError = false;

		if (this.action === AlertActions.Unassign) {
			const response = await this.alertsActionDataStore.unassign(alertIds, this.investigations);
			includesError = Object.values(response?.data || {}).every((data) => _isObject(data) && data.__typename === 'Error');
		}

		if (!includesError) {
			this.notificationsStore.openSuccess({
				title: i18n.t(`alertsStateChange.${this.action}.success.title`, { ns: Namespaces.Notifications }),
			});
		} else if (includesError) {
			this.notificationsStore.openError({
				title: i18n.t(`alertsStateChange.${this.action}.error.title`, { ns: Namespaces.Notifications }),
			});
		}
	}

	read(alertIds: string[], investigationIds: string[]) {
		this.alertsDataStore.read(alertIds);
		this.investigationsDataStore.read(investigationIds);
	}
}
