import { FC } from 'react';
import { getConfidenceColorIndicator } from '@/app/_common/utils/colors';
import { ColorIndicator } from '@/app/_common/_components/color-indicator';
import { AlertConfidence, AlertConfidenceLabels } from '@/app/_common/constants';
import { Namespaces } from '@/translations/namespaces';
import { useTranslation } from 'react-i18next';
import { getConfidenceLabel } from '@/app/_common/utils';
import { SummarySectionItemTitle } from '@/app/_common/_components/details-view/alerts-details-view-navigation/_components/summary-section/_components/summary-section-item-title';

import styles from './alert-details-confidence.module.scss';

interface AlertConfidenceProps {
	confidence: AlertConfidence;
}

export const AlertDetailsConfidence: FC<AlertConfidenceProps> = ({ confidence }) => {
	const { t } = useTranslation(Namespaces.DetailsView);
	const confidenceLabel: AlertConfidenceLabels = getConfidenceLabel(confidence);

	return (
		<div>
			<SummarySectionItemTitle title={t('summary.confidence', { ns: Namespaces.DetailsView })} />
			<div className={styles.value}>
				<ColorIndicator bulletsConfig={getConfidenceColorIndicator(confidence)} />
				<p>{confidenceLabel}</p>
			</div>
		</div>
	);
};
