import { useState, useLayoutEffect } from 'react';

export const DATA_GRID_HEIGHT_DIFF = 158;

export const useDataGridHeight = () => {
	const [height, setHeight] = useState(0);

	useLayoutEffect(() => {
		setHeight(window.innerHeight - DATA_GRID_HEIGHT_DIFF);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.innerHeight]);

	return { height };
};
