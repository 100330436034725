import i18n from '@/translations/i18n';
import { Namespaces } from '@/translations/namespaces';

export enum AlertConfidence {
	Maximum = 'Max',
	High = 'High',
	Medium = 'Medium',
	Low = 'Low',
	Unknown = 'Unknown',
}

export enum AlertConfidenceLabels {
	Maximum = 'Maximum',
	High = 'High',
	Medium = 'Medium',
	Low = 'Low',
	Unknown = 'Unknown',
}

export enum AlertPropertiesPaths {
	Id = 'node.id',
	Title = 'node.title',
	Severity = 'node.severity',
	Confidence = 'node.event_details.confidence',
	Source = 'node.event_details.src',
	Destination = 'node.event_details.dst',
	Timestamp = 'node.timestamp',
	InvestigationSummary = 'node.investigationSummary',
	InvestigationSummaryId = 'node.investigationSummary.id',
	Mitre = 'node.event_details.mitre_category',
	Selected = 'selected',
	DetectedBy = 'node.event_source',
	State = 'node.state.alertState',
	StateData = 'node.state',
}

export const ACTIONS_COLUMN_NAME = 'actions';
export const ASSIGNED_COLUMN_NAME = 'assigned';

export enum AlertInvestigationFiltering {
	Assigned = 'Assigned',
	Unassigned = 'Unassigned',
}

export const AlertHeadersTranslationKeys = {
	[AlertPropertiesPaths.Timestamp]: 'alertsGrid.headers.timestamp',
	[AlertPropertiesPaths.Severity]: 'alertsGrid.headers.severity',
	[AlertPropertiesPaths.Confidence]: 'alertsGrid.headers.confidence',
	[AlertPropertiesPaths.Title]: 'alertsGrid.headers.title',
	[AlertPropertiesPaths.Source]: 'alertsGrid.headers.src',
	[AlertPropertiesPaths.Destination]: 'alertsGrid.headers.dst',
	[AlertPropertiesPaths.Mitre]: 'alertsGrid.headers.mitre',
	[AlertPropertiesPaths.DetectedBy]: 'alertsGrid.headers.type',
	[AlertPropertiesPaths.InvestigationSummaryId]: 'alertsGrid.headers.investigation',
	[AlertPropertiesPaths.State]: 'alertsGrid.headers.state',
};

export enum AssignedAlertPropertiesPaths {
	Id = 'id',
	Title = 'name',
	Severity = 'severity',
	Confidence = 'confidence',
	Source = 'src',
	Destination = 'dst',
	Timestamp = 'timestamp',
	AddedTimestamp = 'addedTimestamp',
	Mitre = 'mitre_category',
	Type = 'type',
	Selected = 'selected',
	DetectedBy = 'event_source',
	State = 'state.alertState',
	StateData = 'state',
}

export const AssignedAlertHeadersTranslationKeys = {
	[AssignedAlertPropertiesPaths.Timestamp]: 'alertsGrid.headers.timestamp',
	[AssignedAlertPropertiesPaths.Severity]: 'alertsGrid.headers.severity',
	[AssignedAlertPropertiesPaths.Confidence]: 'alertsGrid.headers.confidence',
	[AssignedAlertPropertiesPaths.Title]: 'alertsGrid.headers.title',
	[AssignedAlertPropertiesPaths.Source]: 'alertsGrid.headers.src',
	[AssignedAlertPropertiesPaths.Destination]: 'alertsGrid.headers.dst',
	[AssignedAlertPropertiesPaths.Mitre]: 'alertsGrid.headers.mitre',
	[AssignedAlertPropertiesPaths.DetectedBy]: 'alertsGrid.headers.type',
	[AssignedAlertPropertiesPaths.State]: 'alertsGrid.headers.state',
};

export const alertTypeTranslation = new Map<string, string>([
	['rtce', i18n.t('alertsGrid.cells.type.rtce', { ns: Namespaces.AlertsDashboard })],
	['stick', i18n.t('alertsGrid.cells.type.batch', { ns: Namespaces.AlertsDashboard })],
]);

export const DEFAULT_PAGE_SIZE = 100;

export enum AlertTypename {
	Alert = 'Alert',
	Alerts = 'Alerts',
	AlertEdge = 'AlertEdge',
	AlertConnection = 'AlertConnection',
}

export enum AlertsKeyPrefix {
	Counters = 'counters',
	CounterWidgetDropdown = 'counterWidgetDropdown',
	AlertsGrid = 'alertsGrid',
	AssignAlertDialog = 'assignAlertDialog',
	InvestigationsGrid = 'investigationsGrid',
	GridContextMenu = 'alertsGrid.contextMenu',
	DismissAlertDialog = 'dismissAlertDialog',
}

export enum AlertDetailsSection {
	description = 'description',
	recommendation = 'recommendation',
	ioc = 'ioc',
}

export enum DetectedByTypes {
	BatchEngine = 'stick',
	AIEngine = 'rtce',
	RTCE_AI = 'rtce-ai',
	Unknown = 'unknown',
}

export enum AlertStateOptions {
	Assigned = 'ASSIGNED',
	Dismissed = 'DISMISSED',
	Unassigned = 'UNASSIGNED',
	Unknown = 'UNKNOWN',
}

export enum AssignmentActions {
	Assign = 'assign',
	Unassign = 'unassign',
}

export const AssignmentActionsTranslationKeys = {
	[AssignmentActions.Assign]: 'assignOrCreateInvestigation',
	[AssignmentActions.Unassign]: 'unassign',
};
