import { ApolloCache, DocumentNode } from '@apollo/client';

export const readFragmentById = <T>(cache: ApolloCache<unknown>, id: string | undefined, fragment: DocumentNode, typename: string): T | null => {
	if (!id) {
		return null;
	}

	const identifier = cache.identify({
		__typename: typename,
		id,
	});

	return cache.readFragment<T>({
		id: identifier,
		fragment,
	});
};
