import { toJS } from 'mobx';

import { FilterOperators } from '@/app/_common/constants';
import { CompositeFilterDescriptorWithId } from '@/app/_common/types';

import { isCompositeFilterDescriptorWithId } from './is-composite-filter-descriptor-with-id';
import { filterByIsInOperator } from './filter-by-is-in-operator';
import { isFilterDescriptorWithId } from './is-filter-descriptor-with-id';
import { filterByIsNotInOperator } from './filter-by-is-not-in-operator';
import { filterByIsSomeInOperator } from './filter-by-is-some-in-operator';

export const extendFilterDescriptor = (filterDescriptor: CompositeFilterDescriptorWithId): CompositeFilterDescriptorWithId => {
	if (!filterDescriptor) {
		return filterDescriptor;
	}

	const fd = toJS(filterDescriptor);

	if (fd.filters) {
		for (const filter of fd.filters) {
			if (isFilterDescriptorWithId(filter)) {
				if (filter.operator === FilterOperators.IsIn) {
					filter.operator = filterByIsInOperator;
				} else if (filter.operator === FilterOperators.IsNotIn) {
					filter.operator = filterByIsNotInOperator;
				} else if (filter.operator === FilterOperators.IsSomeIn) {
					filter.operator = filterByIsSomeInOperator;
				}
			} else if (isCompositeFilterDescriptorWithId(filter)) {
				extendFilterDescriptor(filter);
			}
		}
	}
	return fd;
};
