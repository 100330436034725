import { action, makeObservable } from 'mobx';
import { DocumentNode } from 'graphql';
import { gql } from '@apollo/client';

import { GraphqlBaseDataStore } from '@/app/_common/graphql';
import { Investigation, InvestigationResult, Mutation } from '@/generated/graphql';
import { UnassignAlertsDashboardUpdateCacheFn, UnassignAlertsMutationArgs } from '@/app/_features/alerts-actions/_common/stores/_common/types';

interface MutationBaseResult {
	[key: string]: InvestigationResult;
}

export class UnassignAlertsDataStore extends GraphqlBaseDataStore<MutationBaseResult, UnassignAlertsMutationArgs> {
	constructor() {
		super();
		makeObservable(this, {
			unassign: action,
		});
	}

	/**
	 * @param {string} fragment - IMPORTANT: fragment name should equal UnassignAlertsFragment. Should be fragment of Investigation.
	 */
	unassign = async (
		unassignedAlertIds: string[],
		investigations: Investigation[],
		fragment: DocumentNode,
		updateCache?: UnassignAlertsDashboardUpdateCacheFn,
	) => {
		const investigationsWithAlerts: Array<[investigationId: string, alertIds: string[]]> = investigations.map(({ id, alerts }) => {
			const alertIds = alerts?.filter((alert) => !unassignedAlertIds.includes(alert.id)).map((alert) => alert.id) || [];

			return [id, alertIds];
		});

		const variables = {
			tenantId: this.authStore.currentTenantId,
		};

		const createUnassignAlertsFromInvestigationMutation = (investigationsWithAlerts: Array<[investigationId: string, alertIds: string[]]>) => {
			const mutations = investigationsWithAlerts.map(([investigationId, alertIds], index) => {
				const alertIdsString = `[${alertIds.map((id) => '"' + id + '"')}]`;

				return `setAlertsOnInvestigation${index}: setAlertsOnInvestigation(tenantId: $tenantId, investigationId: "${investigationId}", alertIds: ${alertIdsString}) {
						... on Investigation {
							...UnassignAlertsFragment
						}
						... on Error {
							message
							code
							correlationId
						}
				}`;
			});

			const mutationContent = 'mutation UnassignAlertsFromInvestigation($tenantId: ID!) {' + mutations.join('\n') + '}';

			return gql`
				${mutationContent}
				${fragment}
			`;
		};

		const mutation = createUnassignAlertsFromInvestigationMutation(investigationsWithAlerts);

		const response = await this.mutate<Mutation, UnassignAlertsMutationArgs>({
			mutation,
			variables,
			update: updateCache,
			fetchPolicy: 'no-cache',
		});

		return response;
	};
}
